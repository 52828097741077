import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'
import { COLORS } from '../../constants/colors'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export const TemplateDeletePopup = (props) => {
    const {translations, language, selectedGiftCard, deleteDialogOpen, handleCloseDeleteDialog, deleteCard } = props
    const { width } = useWindowDimensions()

    return (
        <Dialog
            open={deleteDialogOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDeleteDialog}
            aria-describedby="alert-dialog-slide-descriptionnn"
            sx={{
                padding: 10,
            }}
            PaperProps={{
                style: {
                    width: width > 600 ? "500px" : "100%",
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontWeight: "bold",
                    fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                    paddingTop: 3,
                    textAlign: width < 600 ? "center" : "start",
                }}
            >
                {translations?.information}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-slide-descriptionnn"
                    sx={{
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                        color: "black",
                        textAlign: width < 600 ? "center" : "start",
                    }}
                >
                    {translations?.are_you_sure_delete_template}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    paddingBottom: 2,
                }}
            >
                <Button
                    onClick={() => {
                        handleCloseDeleteDialog()
                    }}
                    disableRipple
                    fullWidth={width < 600}
                    sx={{
                        backgroundColor: COLORS.BG_DARK,
                        color: "white",
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                        textTransform: "none",
                        borderRadius: 2,
                        padding: 1,
                        "&:hover": {
                            backgroundColor: COLORS.BG_DARK,
                            color: "white",
                        },
                    }}
                >
                    {translations?.cancel}
                </Button>
                <Button
                    onClick={() => {
                        deleteCard(selectedGiftCard)
                        handleCloseDeleteDialog()
                    }}
                    disableRipple
                    fullWidth={width < 600}
                    sx={{
                        backgroundColor: COLORS.BG_DARK,
                        color: "white",
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                        textTransform: "none",
                        borderRadius: 2,
                        padding: 1,
                        "&:hover": {
                            backgroundColor: COLORS.BG_DARK,
                            color: "white",
                        },
                    }}
                >
                    {translations?.delete}
                </Button>
            </DialogActions>
        </Dialog>
    )
}