import { Box, Slider } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router';
import { environment } from '../../environment/environment';
import { QueryOptions } from '../../helpers/query.options';
import { generateFiltersFromUrl, generateParamsFromUrl, generateParamsFromUrlBySet, generateUrlForBrand } from '../../helpers/utilities';
import { filterService } from '../../models/filter/service';
import { PriceRange } from '../../models/priceRange/PriceRange';
import { priceRangeService } from '../../models/priceRange/service';
import { FilterAccordionItem } from './FilterAccordionItem';
import { FilterAccordionItemColor } from './FilterAccordionItemColor';
import { FilterItem } from './FilterItem';
import { PriceSlider } from './PriceSlider';
import { debounceTime, Subject } from 'rxjs';
import { attributeSetService } from '../../models/attribute/service';
import { AttributeSet } from '../../models/attribute/attribute';
import { brandService } from '../../models/brand/service';
import { Brand } from '../../models/brand/brands';

export const loader$: any = new Subject().pipe(
    debounceTime(500)
)

export const FilterComponentForBrandsPage = (props: any) => {
    const {
        language,
        setFilters,
        queryParams,
        attrSet = "all",
        location,
        filterNames = [],
        init,
        page,
        onSetFilter,
        translations,
        filterItem } = props

    const history = useHistory()
    const [selectedFilters, setSelectedFilters] = useState<any[]>([])
    const [filterItems, setFilterItems] = useState<any>()
    const [brands, setBrands] = useState<Brand[]>([])
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(24)
    const [filtersLoaded, setFiltersLoaded] = useState(false)
    const [sort, setSort] = useState("uploaded")
    const [pageUrl] = useState('catalog')
    const [order, setOrder] = useState("desc")
    const [search, setSearch] = useState("")
    const [url, setUrl] = useState("")
    const [cat, setCat] = useState("")
    const [options, setOptions] = useState<any>(new QueryOptions())
    const [cachedObj, setCachedObject] = useState<any>()
    const [loaded, setLoaded] = useState(false)
    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(1000)
    const [minGlobal, setMinGlobal] = useState(0)
    const [maxGlobal, setMaxGlobal] = useState(1000)
    const [tag, setTag] = useState("")
    const [attributeSet, setAttributeSet] = useState<AttributeSet>()



    useEffect(() => {
        if (location.search?.length && init) {
            const options = generateParamsFromUrl(location.search)
            if (options.brand) {
                const [brand] = brands
                attributeSetService.read(attrSet, options).then(as => {
                    const options = generateParamsFromUrlBySet(location.search, as.attributes.map(r => r.id))
                    loadFilters(as, options)
                })
            }
        }
        if (location.pathname) {
            const [p] = location.pathname.split("/brands/").reverse()
            if (p){
                loadBrands(p)
            }
        }

    }, [location.search, init])

    useEffect(() => {
        if(brands.length) {
            const options = generateParamsFromUrl(location.search)
            options.brand = brands.map(b => b.id).toString()
            attributeSetService.read(attrSet, options).then(as => {
                options.brand = brands.map(b => b.id).toString()
                loadFilters(as, options)
            })
        }
    }, [brands])

    useEffect(() => {
        if (queryParams?.brand && init) {
            if (filterNames?.length) {
                const [brand] = brands
                loadFilters({ attributes: filterNames }, queryParams)
            } else {
                attributeSetService.read(attrSet, queryParams).then(as => {
                    loadFilters(as, queryParams)
                })
            }
        }
    }, [JSON.stringify(queryParams), init])

    useEffect(() => {
        loadAttributeSet()
    }, [])

    useEffect(() => {
        if (!location.search && !init) {
            setFilterItems([])
            setTimeout(() => {
                if (queryParams?.brand) {
                    if (filterNames?.length) {
                        loadFilters({ attributes: filterNames }, queryParams)
                    } else {
                        attributeSetService.read(attrSet, queryParams).then(as => {
                            queryParams.category = cat
                            loadFilters(as, queryParams)
                        })
                    }
                }
            }, 400)
        }
    }, [JSON.stringify(location), init, JSON.stringify(queryParams)])

    useEffect(() => {
        if (filterItem) {
            handleOnChange(filterItem)
        }
    }, [filterItem])

    const loadAttributeSet = () => {
        const options = new QueryOptions()
        options.lang = language
        attributeSetService.read(attrSet, options).then((r: any) => {
            setAttributeSet(r)
        })
    }
    const loadFilter = (id, options, as) => {
        const opts = new QueryOptions()
        opts.filter = id
        opts.lang = language
        opts.brand = options.brand
        as.forEach(p => {
            if (options[p.id] && options[p.id].length) {
                opts[p.id] = options[p.id]
            }
        })
        opts.min_price = options.min_price
        opts.max_price = options.max_price

        filterService.list(opts).then((r: any) => {
            setFiltersLoaded(true)
            if (cachedObj?.id !== id) {
                setFilterItems(filt => ({ ...filt, [id]: r.results }))
            }
        })
    }

    const loadBrands = async (tag: string) => {
        const options = new QueryOptions()
        options.pageSize = 100
        options.sort = "title_eng"
        options.order = "asc"
        options.tag = `${tag}`
        const brands = await brandService.listWithCounter(options)
        setBrands(brands.current)
      }

    const loadFilters = (attributeSet, options) => {
        if (filterNames?.length) {
            filterNames.forEach((attr, index) => {
                if (options.brand) {
                    loadFilter(attr.id, options, attributeSet.attributes)
                }
            })
        } else {
            attributeSet?.attributes.forEach((attr, index) => {
                if (options.brand) {
                    loadFilter(attr.id, options, attributeSet.attributes)
                }
            })
        }
        priceRangeService.listArray(options).then((r: PriceRange) => {
            setMinPrice(r.minPrice)
            setMaxPrice(r.maxPrice)
            setMinGlobal(r.minGlobal)
            setMaxGlobal(r.maxGlobal)
        })
    }



    const handleOnChange = (item: any) => {
        let arr: any = [];
        if (selectedFilters.map(i => String(i.id)).indexOf(item.id) > -1) {
            arr = [...selectedFilters.filter(i => i.id !== item.id)]
        } else {
            arr = [...selectedFilters.filter(i => i.id !== item.id), item]
        }
        if (filterItems && filterItems[item.name.id]?.length > 1) {
            setCachedObject({ id: item.name.id, items: filterItems[item.name.id] })
        }
        setPageIndex(1)
        setSelectedFilters(arr)
        if (!arr.length) {
            setCachedObject(null)
        }
        reloadQueryOptions(arr, 1, [0, 10000])
        onSetFilter(arr, brands)
    }

    useEffect(() => {
        getFilters()
    }, [location])

    const reloadQueryOptions = (selectedFilters: any, pageIndex, min_max_price) => {
        const min_price = min_max_price[0]
        const max_price = min_max_price[1]
        const options: any = generateParamsFromUrl(location.search)
        options.init = false
        setOptions(options)
        options.brand = brands.map(b => b.id).toString()
        const url = generateUrlForBrand(
            options,
            environment.params,
            selectedFilters,
            `${location.pathname}`,
            pageIndex,
            pageSize,
            sort,
            order,
            search,
            min_price, max_price, ""
        )
        if (url.length) {
            history.push(url);
            setUrl(url);
        } else {
            if (loaded) {
                history.push(`/${pageUrl}`);
            }
        }
    }

    const showFilter = (id, obj) => {
        if (!obj) {
            return true
        }
        if (!obj[id]) {
            return false
        }
        if (obj[id].length) {
            return true
        }
    }

    const getFilters = async () => {
        const prms = await generateFiltersFromUrl(location, language)
        if (prms) {
            setFilters(prms.current)
            setSelectedFilters(prms.current)
        } else {
            setFilters([])
        }
    }
    

    const getFilterList = () => {
        if (filterNames?.length) {
            return filterNames
        }
        return attributeSet?.attributes ? attributeSet?.attributes: []
    }

    return <Box sx={{ width: '100%' }}>
        
            <Fragment>
                {getFilterList().filter(e => showFilter(e.id, filterItems)).map((item, index) => {
                    return item.id !== 'color_family' ? environment.filterType === "accordion" ? <FilterAccordionItem
                        key={index}
                        selectedFilters={selectedFilters}
                        items={selectedFilters}

                        onClick={handleOnChange}
                        item={item}
                        filter={filterItems}
                        {...props} /> :

                        <FilterItem
                            selectedFilters={selectedFilters}
                            items={selectedFilters}
                            key={index}
                            onClick={handleOnChange}
                            item={item}
                            filter={filterItems}
                            {...props} />
                        :
                        <FilterAccordionItemColor
                            selectedFilters={selectedFilters}
                            key={index}
                            onClick={handleOnChange}
                            item={item} filter={filterItems} {...props}
                        />
                })}
            </Fragment>
        
        <Box style={{ padding: 10 }}>
            <PriceSlider
                min={minPrice}
                max={maxPrice}
                minGlobal={minGlobal}
                maxGlobal={maxGlobal}
                rebradQueryOptions={(arr, index, maxPrice) => {
                    reloadQueryOptions(arr, index, maxPrice)
                }}
            />

        </Box>
    </Box >


}
