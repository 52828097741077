import React, { Fragment, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Fade, Paper, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { setCart, setSnackBar, setConfirmation, setFavorites } from '../../models/application/applicationActions';
import { Transition, initCartAndFavs } from '../../helpers/utilities';
import { makeStyles } from '@mui/styles';
import { COLORS } from '../../constants/colors';
import { Title } from '../Typography/Title';
import '../styles.scss'
import { environment } from '../../environment/environment';
import EGiftCardForm from '../GiftCard/EGiftCardForm';
import { BlackButton } from '../Button/BlackButton';
import { useHistory } from 'react-router';
import LoginForm from '../Forms/LoginForm';

const useStyles = makeStyles({
    rootRow: {
        width: '100%',
        padding: 0,
        marginTop: 0,
        height: window.innerHeight,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    }
})

const DesktopEGiftCardFormDialog = (props) => {
    const {
        app,
        eGiftCard,
        createEGiftCard,
        blackLoaded,
        language,
        options,
        translations, 
        auth, 
        setFavorites, 
        setCart } = props
    const [open, setOpen] = useState(false);
    const history = useHistory()

    const [loginForm, setLoginForm] = useState(false)


    const classes = useStyles()
    const toggleLoginForm = () => {
        setLoginForm(!loginForm)
    }
    
    const handleClickOpen = () => {
        if(auth.loggedIn){
            history.push("/giftcard_checkout")
        } else {
            toggleLoginForm()
        }
    };

    const handleClose = () => {
        setOpen(false);
    };


    return <Fragment>
        <Box style={{width: '480px', paddingTop: 3, position: 'relative' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: 30, alignItems: 'center' }}>
                <Title text={`${translations.e_gift_card_purchase}`} fontSize={18} uppercase={true} fontWeight='600' color={"#444"} style={{ marginBottom: 20, width: '100%', textAlign: 'left' }} />
                <Fade
                    in={blackLoaded}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(blackLoaded ? { timeout: 2000 } : {})}
                >
                    {<img src={`${environment.basePath}/assets/catalog/product/black.jpeg`} style={{ width: 480, borderRadius: 10 }} />}
                </Fade>
                
                <Typography 
                        sx={{
                            fontSize:16, 
                            textTransform:'none',
                            fontWeight: 400,
                            color: '#444',
                            lineHeight: 1.2,
                            fontFamily: "FiraGo",
                            textAlign:'left',
                            marginTop: 2,
                            minHeight: language === 'geo' ? 130 : 100,
                            maxHeight: language === 'geo' ? 130 : 100,
                            height: language === 'geo' ? 130 : 100
                        }} >
                       {translations.e_gift_card_purchase_text}
                    </Typography>
                    <Button
                disableRipple
                onClick={handleClickOpen}
                sx={{
                    width: '100%',
                    height: 50,
                    minHeight: 50,
                    maxHeight: 50,
                    borderRadius: 2,
                    backgroundColor: COLORS.BG_DARK,
                    color: 'white',
                    fontWeight:'bold',
                    textTransform: 'none',
                    "&:hover": {
                    backgroundColor: COLORS.BG_DARK,

                    }
                }}>{translations?.add}</Button>
            </Box>
        </Box>

        <Dialog
            maxWidth='lg'
            style={{
                padding: 0, minWidth: window.innerWidth > 1200 ? 1200 : '100%',
                overflow: 'hidden',
                minHeight: window.innerHeight
            }}
            sx={{
                '&.MuiDialog-paperScrollPaper': {
                    maxHeight: 1200
                }
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer', zIndex: 1000 }} onClick={handleClose}>
                <CloseIcon />
            </div>
            <Box style={{
                width: window.innerWidth > 1200 ? 800 : '100%',
                padding: 30,
                backgroundColor: COLORS.BODY_BG, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
            }}>
                {eGiftCard && <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: 10 }}>
                        <Title text={`${translations.e_gift_card_purchase}`} fontSize={18} uppercase={true} fontWeight='600' color={"#444"} style={{ marginBottom: 20 }} />
                        <Fade
                            in={blackLoaded}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(blackLoaded ? { timeout: 2000 } : {})}
                        >
                            {<img src={window.innerWidth > 1200 ? `${environment.basePath}/assets/catalog/product/black.jpeg` : `${environment.basePath}/assets/catalog/product/gift_card_banner.jpg`} style={{ width: '100%' }} />}
                        </Fade>
                        <Title text={`${translations.e_gift_card_purchase_text}`} fontSize={16} uppercase={false} fontWeight='400' color={"#444"} style={{ marginTop: 20 }} />
                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'column', width: '50%', backgroundColor: 'transparent', paddingLeft: 20, paddingTop: 20 }}>
                        <EGiftCardForm
                            loc="Desktop"
                            translations={translations} {...props} options={options}
                            product={eGiftCard}
                            onAddToCart={(values) => {
                                    createEGiftCard(values, language)
                                    handleClose()
                            }}
                        />
                    </Box>
                </Box>}

            </Box>
        </Dialog>
        {loginForm &&
            <Dialog
            open={loginForm}
            onClose={toggleLoginForm}
           sx={{padding: 2, margin: '0 auto', display:'flex', justifyContent:'center', alignItems:'center'}}
           PaperProps={{
            elevation: 0
           }}
            >
                <Paper style={{ padding: '50px 20px 40px 20px', marginLeft:'47px' }} elevation={0}>
                <Typography sx={{marginBottom:3, fontSize: 18, fontWeight:'bold', fontFamily: language === "geo" ? "FiraGo" : "Roboto"}}>
                    {translations?.you_must_log_in_first_for_giftcard}
                </Typography>

                <LoginForm
                {...props}
                onLogin={() => {
                initCartAndFavs(app, { ...auth, loggedIn: true }, setCart, setFavorites)
                toggleLoginForm()
                history.push("/giftcard_checkout")
                }} />
                </Paper>
            </Dialog>
        }
    </Fragment>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, {
    setCart,
    setSnackBar,
    setConfirmation,
    setFavorites

})(DesktopEGiftCardFormDialog)
