import { Serializer } from '../serializer'
import { Country } from './country'

export class CountrySerializer implements Serializer {
    fromJson(json: any) {
        const country = new Country()
        country.id = json.id
        country.name = json.name
        return country
    }

    toJson(item: any) {
        const obj: any = {}
        return obj
    }
}

export const countrySerializer = new CountrySerializer()