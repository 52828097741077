import { MultiCarouselComponent } from "./MultiCarousel"
import React, { useState, useEffect } from 'react'
import { COLORS } from "../../constants/colors"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { QueryOptions } from "../../helpers/query.options"
import { MainPageSlide } from "../../models/MainPageBrandBanner/MainPage"
import { mainPageSliderService } from "../../models/MainPageBrandBanner/service"
import { useHistory } from "react-router"
import { Box, Fade } from "@mui/material"
import { Skeleton } from "@mui/material";

export const MainPageSliderItem = (props) => {
    const { item } = props
    const history = useHistory();
    return <div style={{ textAlign: 'center', width: 400, height: 400 }}>
        <div style={{ paddingLeft: '25%', width: '100%', height: "100%", margin: 'auto 0', position: 'relative' }}>
            <div style={{
                padding: '0%', width: '50%', margin: 'auto 0', backgroundColor: 'transparent', height: 300,
                backgroundImage: `url(${item.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>

            </div>
            <div style={{
                top: 20, zIndex: -10,
                right: 400 / 2 - 20,
                height: 300,

                width: '40%', backgroundColor: COLORS.BG_BEIGE_GRAY, position: 'absolute'
            }}>
            </div>
            <div style={{
                flexDirection: 'column',
                position: 'relative',
                top: 40,
                bottom: 10, zIndex: 200, alignSelf: 'flex-start', display: 'flex', width: '60%', textAlign: 'center'
            }}>
                <div style={{ alignSelf: 'center', textAlign: 'center', flexDirection: 'row' }}>
                    <div style={{ fontSize: 24, display: 'inline-block', fontWeight: 'bold' }} onClick={() => {
                        history.push(item.url)
                    }}>
                        {item.title}
                    </div>
                    <div style={{ top: 20, display: 'inline-block' }}>
                        <div style={{ top: 5, position: 'absolute' }}>
                            <ArrowForwardIosIcon fontSize={'large'} style={{ fontSize: 22 }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export const MobileMainPageSliderItem = (props) => {
    const { item } = props
    const history = useHistory();
    return <div style={{ textAlign: 'center', width: '100%', height: 400 }}>
        <div style={{ width: '100%', height: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <Box style={{
                width: '70%', margin: 'auto 0', backgroundColor: 'transparent', height: window.innerWidth > 1200 ? 300: 250,
                backgroundImage: `url(${item.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
            </Box>
            <Box style={{
                flexDirection: 'column',
                position: 'relative',
                zIndex: 200,
                display: 'flex', width: '60%', textAlign: 'center'
            }}>
                <div style={{ alignSelf: 'center', textAlign: 'center', flexDirection: 'row' }}>
                    <div style={{ fontSize: 24, display: 'inline-block', fontWeight: 'bold' }} onClick={() => {
                        history.push(item.url)
                    }}>
                        {item.title}
                    </div>
                    <div style={{ top: 20, display: 'inline-block' }}>
                        <div style={{ top: 5, position: 'absolute' }}>
                            <ArrowForwardIosIcon fontSize={'large'} style={{ fontSize: 22 }} />
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    </div>
}
export const MobileMainPageBannerItem = (props) => {
    const { item } = props
    const [loaded, setLoaded] = useState(false)
    const history = useHistory();
    
    useEffect(() => {
        if(item?.imageMobile) {
            const img = new Image()
            img.src =  item.imageMobile
            img.onload = () => {
                setLoaded(true)
            }
        }
    }, [item?.imageMobile])
    return <div style={{ textAlign: 'center', width: '100%', height: 280, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <div style={{ width: '100%', height: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <Fade
                in={loaded}
                style={{ transformOrigin: '0 0 0' }}
                {...(loaded ? { timeout: 1000 } : {})}
            >
                <Box
                    onClick={() => {
                        history.push(item.url)
                    }}
                    style={{
                        width: '84%', margin: 'auto 0', backgroundColor: 'transparent', height: window.innerWidth > 1200 ? 300: 250,
                        backgroundImage: `url(${item.imageMobile})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}>
                </Box>

            </Fade>
        </div>
    </div>
}

export const MainPageSlider = (props) => {
    const [slides, setSlides] = useState<MainPageSlide[]>([])
    const [loadStatus, setLoadStatus] = useState(true)
    const loadBrandCarousel = () => {
        const options = new QueryOptions()
        options.sort = "sort"
        options. order = "asc"
        mainPageSliderService.listWithCounter(options).then((resp: any) => {
            setSlides(resp.current)
            setLoadStatus(false)
        })
    }

    useEffect(() => {
        loadBrandCarousel()
    }, [])

    return loadStatus ? <Skeleton width={1200} height={400}/> :slides?.length ? <MultiCarouselComponent
        items={slides}
    /> : <></>
}


