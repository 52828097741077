import { Box, Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import { QueryOptions } from "../../helpers/query.options"
import { Review, ReviewSummary } from "../../models/review/Review"
import { reviewService, reviewSummaryService } from "../../models/review/service"
import { Title } from "../Typography/Title"
import { RateItem } from "./RateItem"
import { ReviewSummaryComponent } from "./ReviewSummaryComponent"
import { User as UserIcon } from '../../assets/icons/User'
import { COLORS } from "../../constants/colors"
import Divider from '@mui/material/Divider';

export const ReviewComponent = (props) => {
    const { id, translations, onLoaded } = props
    const [reviewItems, setReviewItems] = useState<Review[]>([])
    const [reviewSummary, setReviewSummary] = useState<ReviewSummary>()

    useEffect(() => {
        loadReview(id)
        loadReviewSummary(id)
    }, [id])

    const loadReview = (id) => {
        const options = new QueryOptions()
        options.id = id
        options.pageSize = 40
        reviewService.listWithCounter(options).then(r => {
            setReviewItems(r.current)
            onLoaded(r.current)
        })
    }
    const loadReviewSummary = (id) => {
        const options = new QueryOptions()
        options.id = id
        reviewSummaryService.read(id, options).then((r: any) => {
            setReviewSummary(r)
        })
    }

    return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 5 }}>
        <Title text={`${translations?.reviews}`}
            style={{ alignSelf: 'left', marginBottom: 20 }}

            fontSize={23} uppercase={true} fontWeight='500' color={"black"} />
        <Divider component="div" flexItem sx={{ color: 'red', marginBottom: 2 }} />
        {reviewSummary && <ReviewSummaryComponent summary={reviewSummary} translations={translations} />}
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}></Box>
        {reviewItems.map((item, index) => {
            return <><Grid key={index} container style={{ padding: 10 }}>


                {window.innerWidth < 1200 ? <Grid item sm={6} xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', paddingBottom: 10 }}>
                    <Box sx={{ width: 30, height: 30, backgroundColor: COLORS.BEIGE_I, borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <UserIcon width={15} height={15} />
                    </Box>
                    <Title text={`${item?.user.firstName} ${item?.user.lastName}`}

                        style={{ alignSelf: 'left', marginLeft: 10 }}
                        fontSize={14} uppercase={false} fontWeight='400' color={"black"} />
                </Grid> : ''}
                {window.innerWidth < 1200 ? <Grid sm={6} xs={12}><RateItem rate={item.value} /></Grid> : ''}
                {window.innerWidth < 1200 ? <Grid item sm={12} xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 20, paddingBottom: 20 }}>
                    <Title text={`${item.text}`}
                        style={{ alignSelf: 'left' }}
                        fontSize={15} uppercase={true} fontWeight='400' color={"black"} />
                </Grid> : ''}
                {window.innerWidth > 1200 ? <Grid item lg={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <RateItem rate={item.value} />
                    <Title text={`${item.created}`}
                        style={{ alignSelf: 'left' }}
                        fontSize={13} uppercase={true} fontWeight='400' color={"black"} />
                </Grid> : ''}
                {window.innerWidth > 1200 ? <Grid item lg={7} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 20 }}>
                    <Title text={`${item.text}`}
                        style={{ alignSelf: 'left' }}
                        fontSize={15} uppercase={true} fontWeight='400' color={"black"} />
                </Grid> : ''}
                {window.innerWidth > 1200 ? <Grid item lg={3} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <Box sx={{ width: 30, height: 30, backgroundColor: COLORS.BEIGE_I, borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <UserIcon width={15} height={15} />
                    </Box>
                    <Title text={`${item?.user.firstName} ${item?.user.lastName}`}

                        style={{ alignSelf: 'left', marginLeft: 10 }}
                        fontSize={14} uppercase={false} fontWeight='400' color={"black"} />
                </Grid> : ''}
                {window.innerWidth < 1200 ?
                    <Title text={`${item.created}`}
                        style={{ alignSelf: 'left' }}
                        fontSize={13} uppercase={true} fontWeight='400' color={"black"} />
                    : ''}

            </Grid>
                <Divider component="div" flexItem sx={{ color: 'red', marginBottom: 2 }} />
            </>
        })}

    </Box>
} 