import { Resource } from "../resource";


export class InfoPage extends Resource {
    link: string;
    title: string;
    text: string;
    sort: number;
    section: string;
}

export class InfoPageSection extends Resource{
    display: string;
    sort: number;
    filename: string;
    file: string;
    text: string;
    title: string;
    image: string;
}

export class InfoPageContent extends Resource {
    display: string;
    filename: string;
    title: string;
    file: string;
    text: string;
    page: string;
    image: string;
    sections: Array<InfoPageSection>;
}