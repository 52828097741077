import { ResourceProvider } from "../../providers/resource.provider"

import { ProductLine } from "./ProductLine"
import { ProductLineSerializer } from "./serializer"



export class ProductLineProvider extends ResourceProvider<ProductLine> {
    constructor() {
        super("rest_product_line", new ProductLineSerializer())
    }
}

export const productLineService = new ProductLineProvider()

