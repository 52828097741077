import React, { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@mui/material';
import "../../App.css"

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const TopHeaderBannerDialog = (props: any) => {
    const defaultText = "Default text"
    const { open = false, setOpen, content = defaultText} = props

    const handleClose = () => {
        setOpen(false);
    };

    return <Dialog
        open={open}
        style={{zIndex:10000}}
        TransitionComponent={Transition}
        keepMounted
        onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
        aria-describedby="alert-dialog-slide-description">
            <Box sx={{position: 'absolute', top: 10, right: 10, cursor: 'pointer'}} onClick={()=> {setOpen(false)}}>
            <CloseIcon />
            </Box>
        <DialogContent>
            <DialogContentText id="alert-dialog-slide-description" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div 
                className="topHeader"
                dangerouslySetInnerHTML={{ __html:content.replace(/\\n/g, "").replace(/\\r/g, "") }}></div>
            </DialogContentText>
        </DialogContent>
    </Dialog>
}