import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux'
import { register } from "../../../models/user/authActions";
import '../../../components/styles.scss'
import { useHistory } from "react-router";
import { QueryOptions } from "../../../helpers/query.options";
import { brandLetterService, brandService } from "../../../models/brand/service";
import { Brand, BrandLetter } from "../../../models/brand/brands";
import { makeStyles } from "@mui/styles";
import { Title } from "../../../components/Typography/Title";
import { PopularBrandsContentDesktop } from "./PopularBrandsContentDesktop";
import { PopularBrandsContentMobile } from "./PopularBrandsContentMobile";
import { BrandListContentMobile } from "./BrandListContentMobile";
import { BrandListContentDesktop } from "./BrandListContentDesktop";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center"
  },
  leftcolumn: {
    padding: 10
  }
})

const AllBrandsPage = (props: any) => {
  const { translations } = props
  const history = useHistory();
  const classes = useStyles();
  const [letters, setLetters] = useState<BrandLetter[]>([])
  const [brands, setBrands] = useState<Brand[]>([])
  const [scroll, setScroll] = useState(0)
  const lettersRef: any = useRef(null)
  const [showLetters, setShowLetters] = useState(false)
  const [showContent, setShowContent] = useState(true)

  const brandsListRef = useRef(null)

  useEffect(() => {
    window.addEventListener("scroll", (e) => { setScroll(window.scrollY) });
    return () => { 
      setShowContent(false)
      window.removeEventListener("scroll", (e) => console.log(e));
    };
  }, []);

  useEffect(() => {
    if (scroll > lettersRef?.current?.offsetTop + 220 - window.innerHeight / 3) {
      setShowLetters(true)
    } else {
      setShowLetters(false)
    }
  }, [scroll])

  const loadLetters = () => {
    const options = new QueryOptions()
    options.pageSize = 100
    options.sort = "_id"
    options.order = "asc"
    brandLetterService.list(options).then(r => {
      setLetters(r.results)
    })
  }

  const loadBrands = () => {
    const options = new QueryOptions()
    options.pageSize = 100
    options.sort = "title_eng"
    options.order = "asc"
    brandService.listWithCounter(options).then(r => {
      setBrands(r.current)
    })
  }

  useEffect(() => {
    loadLetters()
    loadBrands()
  }, [])

  return showContent ? <div className={"container"}>
    <Title
      text={`${translations?.popular_brands}`}
      fontSize={20}
      style={{ margin: 10, marginTop: 40 }}
      uppercase={true}
      fontWeight='500'
      color={"black"} />
    {window.innerWidth > 1200 ?
      <PopularBrandsContentDesktop
        brands={brands}
        history={history}
      /> : <PopularBrandsContentMobile
        brands={brands}
        history={history} />
    }
    <Title
      text={`${translations?.all_brands}`}
      fontSize={20}
      style={{ margin: 10, marginTop: 20, marginBottom: 30 }}
      uppercase={true}
      fontWeight='500'
      color={"black"} />
    {
      window.innerWidth > 1200 ?
        <BrandListContentDesktop
          history={history}
          brands={brands}
          letters={letters}
          brandsListRef={brandsListRef}
        />
        : <BrandListContentMobile
          letters={letters}
          brandsListRef={brandsListRef}
          lettersRef={lettersRef}
          brands={brands}
          history={history}
          showLetters={showLetters}
        />
    }
  </div >: <></>
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    translations: state.translations
  }
}

export default connect(mapStateToProps, { register })(AllBrandsPage)