import React, { useCallback, useEffect, useRef, useState } from "react"
import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
} from "@mui/material"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { GiftCardsCarousel } from "./GiftCardsCarousel"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import { SecondStepDesktopTabs } from "./SecondStepDesktopTabs"
import { SecondStepMobileTabs } from "./SecondStepMobileTabs"
import { GiftCardTemplate, GiftCardTemplateCategory } from "../../models/giftCard/GiftCard"
import { SliderLeftButton } from "./SliderLeftButton"
import { SliderRightButton } from "./SliderRightButton"
import { getToken } from "../../helpers/utilities"
import { environment } from "../../environment/environment"
import { giftCardTemplateSerializer } from "../../models/giftCard/serializer"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { giftCardTemplateService } from "../../models/giftCard/service"
import { TemplateDeletePopup } from "./TemplateDeletePopup"

export const GiftCardCheckoutSecondStep = (props) => {
    const { translations, language, categories, templates, setStep, onReset } = props

    const { width } = useWindowDimensions()
    const [value, setValue] = useState(0)
    const [selectedGiftCard, setSelectedGiftCard] = useState<GiftCardTemplate>()
    const [loadedTemplates, setLoadedTemplates] = useState<GiftCardTemplate[]>([])
    const [selectedGiftCardCategory, setSelectedGiftCardCategory] = useState<GiftCardTemplateCategory>()
    const [uploadedImg, setUploadedImg] = useState<string | null>()
    const [init, setInit] = useState(false)

    const [selectedFile, setSelectedFile] = useState(null)
    const fileInput = useRef(null)

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const deleteCard = (template) => {
        giftCardTemplateService.delete(template.id).then(() => {
            onReset(template)
        })
    }

    useEffect(() => {
        if (categories.length && templates.length && !init) {
            const [category] = categories
            setFirstTemplate(category, templates)
        }
    }, [categories, templates])

    useEffect(() => {
        if (selectedGiftCardCategory && templates?.length) {
            setFirstTemplate(selectedGiftCardCategory, templates)
        }
    }, [templates, selectedGiftCardCategory])

    const setFirstTemplate = (category, templates) => {
        if (category) {
            setSelectedGiftCardCategory(category)
            const [template] = templates.filter((t) => t.category?.id === category.id)
            if (template) {
                setSelectedGiftCard(template)
            }
        }
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const uploadImageXHR = async (blobUrl) => {
        const token = await getToken()
        const xhr = new XMLHttpRequest()

        fetch(blobUrl)
            .then((response) => response.blob())
            .then((blob) => {
                var formData = new FormData()
                formData.append("file", blob, "image.jpg")
                xhr.upload.addEventListener("progress", (event) => {
                    if (event.loaded === event.total) {
                    }
                })

                xhr.addEventListener("load", () => {
                    if (xhr.status === 200) {
                        const gCard = giftCardTemplateSerializer.fromJson(JSON.parse(xhr.responseText))
                        setLoadedTemplates([gCard, ...loadedTemplates])
                        setSelectedGiftCardCategory(gCard.category)
                        setSelectedGiftCard(gCard)
                        const index = categories.findIndex((cat) => cat.sort === 100)
                        setValue(index)
                    } else {
                        console.log("Upload error:", xhr.status, xhr.responseText)
                    }
                })

                xhr.onerror = (e) => {
                    console.log("Upload failed:", e)
                }

                xhr.open("POST", `${environment.basePathNoPort}/rest_gift_card_template`)

                // Set the Authorization header
                xhr.setRequestHeader("Authorization", "Bearer " + token)
                xhr.send(formData)
            })
            .catch((error) => console.error("Error fetching the blob:", error))
    }

    useEffect(() => {
        if (selectedFile) {
            const imageUrl = URL.createObjectURL(selectedFile)
            uploadImageXHR(imageUrl)
        }
    }, [selectedFile])

    const RenderCarousel = useCallback(() => {
        return (
            <GiftCardsCarousel
                templates={[...loadedTemplates, ...templates].filter(
                    (r: GiftCardTemplate) => selectedGiftCardCategory.id === r.category.id
                )}
                selectedGiftCard={selectedGiftCard}
                setSelectedGiftCard={(template) => {
                    setSelectedGiftCard(template)
                }}
                selectedTab={selectedGiftCardCategory.id}
                uploadedImg={uploadedImg}
            />
        )
    }, [selectedGiftCardCategory, uploadedImg, templates])

    return (
        <Box
            sx={{
                width: width > 600 ? "500px" : "80%",
                margin: "0 auto",
                border: "1.5px solid gray",
                borderRadius: 2,
                padding: 5,
                marginBottom: 7,
                position: "relative",
            }}
        >
            <SliderLeftButton templates={templates} />
            <SliderRightButton templates={templates} />

            <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"}>
                {translations?.choose_a_giftcard_design}
            </DialogTitle>
            <DialogContent>
                {width > 600 ? (
                    <SecondStepDesktopTabs
                        language={language}
                        tabs={categories}
                        value={value}
                        setSelected={(item) => {
                            setFirstTemplate(item, templates)
                            setSelectedGiftCardCategory(item)
                        }}
                        handleChange={handleChange}
                    />
                ) : (
                    <SecondStepMobileTabs
                        tabs={categories}
                        language={language}
                        setSelected={(item) => {
                            setFirstTemplate(item, templates)
                            setSelectedGiftCardCategory(item)
                        }}
                    />
                )}
                <input
                    type="file"
                    ref={fileInput}
                    accept=".jpg,.jpeg,.png,.heic,.heif"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
                <Button
                    onClick={() => fileInput.current.click()}
                    disableRipple
                    sx={{
                        textTransform: "none",
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                        backgroundColor: "transparent",
                        color: "black",
                        gap: 1,
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    }}
                >
                    {translations?.upload_your_own_photo}
                    <CameraAltIcon />
                </Button>
                {width > 600 && (
                    <Box sx={{ position: "relative" }}>
                        {selectedGiftCardCategory?.sort === 100 && (
                            <DeleteOutlineIcon
                                onClick={() => {
                                    setDeleteDialogOpen(true)
                                }}
                                sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }}
                            />
                        )}
                        {selectedGiftCard && width > 600 && (
                            <img src={selectedGiftCard.image} style={{ width: "100%", aspectRatio: "16/9" }} />
                        )}
                    </Box>
                )}
                {width < 600 && selectedGiftCardCategory?.sort === 100 && (
                    <Box
                        sx={{
                            position: "absolute",
                            right: "70px",
                            zIndex: 300,
                            backgroundColor: "white",
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <DeleteOutlineIcon
                            onClick={() => {
                                setDeleteDialogOpen(true)
                            }}
                        />
                    </Box>
                )}

                {selectedGiftCardCategory && <RenderCarousel />}

                <div
                    style={{
                        textAlign: "center",
                        marginTop: "20px",
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            localStorage.setItem("gift-card-checkout-second-step", JSON.stringify(selectedGiftCard))
                            setStep((c) => c + 1)
                        }}
                        sx={{
                            backgroundColor: "black",
                            color: "white",
                            fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                            textTransform: "none",
                            "&:hover": { backgroundColor: "black" },
                            "@media (max-width:600px)": {
                                width: "100%",
                                marginTop: 3,
                            },
                        }}
                    >
                        {translations?.how_much}
                    </Button>
                </div>
            </DialogContent>
            <TemplateDeletePopup
                translations={translations}
                language={language}
                selectedGiftCard={selectedGiftCard}
                deleteDialogOpen={deleteDialogOpen}
                handleCloseDeleteDialog={handleCloseDeleteDialog}
                deleteCard={deleteCard}
            />
        </Box>
    )
}
