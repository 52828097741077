import { ResourceProvider } from "../../providers/resource.provider"
import { Finder } from "./Finder"
import { FinderSerializer, FinderStepSerializer } from "./serializer"

export class FinderProvider extends ResourceProvider<Finder> {
    constructor() {
        super("rest_finder", new FinderSerializer())
    }
}

export const finderService = new FinderProvider()

