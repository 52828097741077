import { Box, Button, DialogTitle, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { COLORS } from "../../constants/colors"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { GiftCardOrder } from "../../models/giftcardCheckout/giftcardOrder"
import { orderGiftCardService } from "../../models/giftcardCheckout/service"
import { BOGPayment } from "../../models/payment/PaymentMethod"
import { bogPaymentService } from "../../models/payment/service"

export const GiftCardSummaryDetails = (props) => {
    const { language, translations, mode, setMode, details, setDetails } = props
    const { width } = useWindowDimensions()

    const getData = async () => {
        const first = localStorage.getItem("gift-card-checkout-first-step")
        const second = localStorage.getItem("gift-card-checkout-second-step")
        const third = localStorage.getItem("gift-card-checkout-third-step")
        const fourth = localStorage.getItem("gift-card-checkout-fourth-step")
        const fifth = localStorage.getItem("gift-card-checkout-fifth-step")
        const pFirst = JSON.parse(first)
        const template = JSON.parse(second)
        const pThird = JSON.parse(third)
        const pFourth = JSON.parse(fourth)
        const pFifth = JSON.parse(fifth)

        const obj = {
            forMe: !pFirst.friendName && !pFirst.yourName ? pFirst : undefined,
            friendName: pFirst.friendName,
            yourName: pFirst.yourName,
            message: pFourth.message,
            friendEmail: !pFifth.yourEmail ? pFifth.friendEmail : undefined,
            yourEmail: !pFifth.friendEmail ? pFifth.yourEmail : undefined,
            amount: pThird.amount,
            template: template,
        }
        return obj
    }

    useEffect(() => {
        getData().then((resp: any) => setDetails(resp))
    }, [mode])

    const createBOGPayment = (id) => {
        const payment = new BOGPayment()
        payment.id = id
        bogPaymentService.create(payment).then(r => {
            if (r.status === "CREATED") {
                const [link] = r.links.filter(l => l.method === "REDIRECT")
                if (link) {
                    localStorage.setItem("url", link.href)
                    window.location.href = link.href
                }
            }
        })
    }

    const handleOrderGiftCard = async () => {
        const order = new GiftCardOrder()
        order.amount = details.amount
        order.recipientEmail = details.friendEmail || details.yourEmail
        order.recipientName = details.friendName
        order.template = details.template.id
        order.message = details.message
        order.senderName = details.yourName
        order.lang = language
        orderGiftCardService.create(order).then((resp: any) => {
            if (resp.id) {
                createBOGPayment(resp.id)
            }
        })
    }

    return (
        <Box
            sx={{
                width: width > 600 ? "500px" : "80%",
                margin: "0 auto",
                border: "1.5px solid gray",
                borderRadius: 2,
                padding: 5,
                marginBottom: 7,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                "@media (max-width:600px)": {
                    padding: 3,
                    paddingTop: 5,
                    paddingBottom: 5,
                },
            }}
        >
            <Box
                sx={{
                    margin: "0 auto",
                }}
            >
                <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"}>
                    {translations?.who_is_the_gift_card_for}
                </DialogTitle>
                {details?.friendName ? (
                    <Typography
                        sx={{
                            fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                            fontSize: 18,
                            marginBottom: 0,
                        }}
                    >
                        {details?.friendName}
                    </Typography>
                ) : (
                    <Typography
                        sx={{
                            fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                            fontSize: 18,
                            marginBottom: 2,
                        }}
                    >
                        {details?.forMe}
                    </Typography>
                )}

                {details?.yourName && (
                    <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"}>
                        {translations?.card_sender}
                    </DialogTitle>
                )}
                <Typography
                    sx={{
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                        fontSize: 18,
                        marginBottom: 2,
                    }}
                >
                    {details?.yourName}
                </Typography>
                {details?.template && <img src={details.template?.image} style={{ width: "100%", aspectRatio: "16/9" }} />}
                    
                <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"}>{translations?.price}</DialogTitle>
                <Typography
                    sx={{
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                        fontSize: 18,
                    }}
                >
                    {details?.amount} ₾
                </Typography>
                <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"}>
                    {translations?.where_is_it_going}
                </DialogTitle>
                <Typography
                    sx={{
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                        fontSize: 18,
                    }}
                >
                    {details?.friendEmail ? details?.friendEmail : details?.yourEmail}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        "@media (max-width:600px)": {
                            flexDirection: "column",
                        },
                    }}
                >
                    <Button
                        onClick={() => setMode("edit")}
                        sx={{
                            width: "45%",
                            margin: "0 auto",
                            marginTop: 5,
                            backgroundColor: COLORS.BEIGE_I,
                            color: "black",
                            height: 50,
                            maxHeight: 50,
                            fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                            textTransform: "none",
                            "@media (max-width:600px)": {
                                width: "100%",
                            },
                            "&:hover": {
                                backgroundColor: COLORS.BEIGE_I,
                            },
                        }}
                    >
                        {translations?.edit_details}
                    </Button>
                    <Button
                        onClick={handleOrderGiftCard}
                        sx={{
                            width: "45%",
                            margin: "0 auto",
                            marginTop: 5,
                            backgroundColor: "black",
                            color: "white",
                            height: 50,
                            maxHeight: 50,
                            fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                            textTransform: "none",
                            "@media (max-width:600px)": {
                                width: "100%",
                                marginTop: 2,
                            },
                            "&:hover": {
                                backgroundColor: "black",
                            },
                        }}
                    >
                        {translations?.save_and_go_to_checkout}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
