import { environment } from "../../environment/environment"
import { Serializer } from "../serializer"
import { Category } from "./category"

export class CategorySerializer implements Serializer {
    fromJson(json: any) {
        const item = new Category()
        if (json.children) {
            const serializer = new CategorySerializer()
            item.children = json.children.map(e => serializer.fromJson(e))
        }
        item.id = json.uid
        if (json.image) {
            item.image = `${environment.basePath}/assets/cateogory/${json.image}.jpg` 
        } else {
            item.image = null
        }
        if (json.header_banner_filename) {
            item.banner = `${environment.basePath}/assets/cateogory/${json.header_banner_filename}.jpg` 
        } else {
            item.banner = null
        }
        item.level = json.level
        item.parentId = json.parent_id
        item.sort = json.sort
        item.title = json.title
        item.uid = json.uid
        item.url = json.url
        item.imageLink = json.image_link
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
