import { ResourceProvider } from "../../providers/resource.provider"
import { Faq, FaqSection } from "./faq"
import { FAQSerializer, FaqSectionSerializer } from "./serializer"

export class FAQProvider extends ResourceProvider<Faq> {
    constructor() {
        super("rest_faq", new FAQSerializer())
    }
}

export class FaqSectionProvider extends ResourceProvider<FaqSection> {
    constructor(){
        super("rest_faq_section", new FaqSectionSerializer())
    }
}

export const faqService = new FAQProvider()
export const faqSectionService = new FaqSectionProvider()

