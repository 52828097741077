import React from "react"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { IconButton } from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"

export const SliderRightButton = (props) => {
    const { templates } = props
    const { width } = useWindowDimensions()
    return (
        <div>
            {templates.length > 3 && width > 600 ? (
                <IconButton
                    className="giftcards-next"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        bottom: 135,
                        right: 15,
                        backgroundColor: "black",
                        "&:hover": { backgroundColor: "black" },
                        "@media (max-width:600px)": {
                            bottom: 200,
                        },
                    }}
                >
                    <ArrowForwardIosIcon sx={{ color: "white" }} />
                </IconButton>
            ) : (
                width < 600 && (
                    <IconButton
                        className="giftcards-next"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            bottom: 135,
                            right: 15,
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "black" },
                            "@media (max-width:600px)": {
                                bottom: 200,
                            },
                        }}
                    >
                        <ArrowForwardIosIcon sx={{ color: "white" }} />
                    </IconButton>
                )
            )}
        </div>
    )
}
