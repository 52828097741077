import { Serializer } from '../serializer';
import { InfoPage, InfoPageContent, InfoPageSection } from './InfoPage';
import { environment } from '../../environment/environment';
export class InfoPageSerializer implements Serializer {
    fromJson(json: any) {
        const ip = new InfoPage();
        ip.title = json.title;
        ip.link = json.link;
        ip.id = json.id;
        ip.sort = json.sort;
        ip.section = json.section;
        
        return ip;
    }

    toJson(list: any) {
        return {
            id: list.id,
            value: list.value
        };
    }
}

export class InfoPageSectionSerializer implements Serializer {
    fromJson(json: any) {
        const ips = new InfoPageSection();
        ips.display = json.display;
        ips.sort = json.sort;
        ips.text = json.text;
        ips.filename = json.filename;
        ips.title = json.title;
        if (json.filename) {
            ips.image = `${environment.imagePath}/assets/info_pages/${json.filename}.jpg`
        }
        return ips;
    }

    toJson(list: any) {
        return {
            id: list.id,
            value: list.value
        };
    }
}


export class InfoPageContentSerializer implements Serializer {
    fromJson(json: any) {
        const ipc = new InfoPageContent();
        ipc.filename = json.filename;
        ipc.display = json.display;
        const ipsSerializer = new InfoPageSectionSerializer();
        if (json.sections) {
            ipc.sections  = json.sections.map( (section: any) => ipsSerializer.fromJson(section));
        }
        if (json.filename) {
            ipc.image = `${environment.imagePath}/assets/info_pages/${json.filename}.jpg`
        }
        ipc.title = json.title;
        
        ipc.text = json.text;
        return ipc;
    }

    toJson(list: any) {
        return {
            id: list.id,
            value: list.value
        };
    }
}
