import { environment } from "../../environment/environment"
import { embeddedInventorySerializer } from "../EmbeddedInventory/serializer"
import { attributeSerializer, AttributeSerializer, attributeSetSerializer } from "../attribute/serializer"
import { brandSerializer } from "../brand/serialize"
import { CategorySerializer } from "../category/serializer"
import { Serializer } from "../serializer"
import { ProductPromoPage, ProductPromoPageBanner, ProductPromoPageCarousel, ProductPromoPageCardColumnSection, ProductPromoPageColumn, ProductPromoPageKeyNote, ProductPromoPageSectionImage, ProductPromoPageTextSection, ProductPromoPageTitleAndTextSection, ProductPromoPageImageVideo } from "./ProductPromoPage"
import {
        
        Product,
        ProductMedia,
        SimpleProduct
        } from "./product"

const BASE_PATH = environment.basePath
const VIDEOS_PATH = environment.videosPath
export class ProductMediaSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductMedia()
        item.filename = json.filename
        item.is_main = json.is_main
        item.name = json.name
        item.isCommon = json.is_common
        item.thumbnail = `${environment.imagePath}/assets/catalog/product/200/${json.filename}`
        item.image = `${environment.imagePath}/assets/catalog/product/600/${json.filename}`
        item.image2000 = `${environment.imagePath}/assets/catalog/product/2000/${json.filename}`
        item.sort = json.sort
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}

export class SimpleProductSerializer implements Serializer {
    fromJson(json: any) {
        const product = new SimpleProduct()
        product.name = json.name
        product.sku = json.sku
        product.specialPrice = json.special_price
        product.id = json.sku
        product.stock = json.stock
        product.price = json.price
        if (json.attributes) {
            product.attributes = json.attributes.map(a => attributeSerializer.fromJson(a))
        }
        return product
    }
    toJson(item: Product) {
        return {
        }
    }
}






export class ProductPromoPageTitleAndTextSectionSerializer implements Serializer {
    fromJson(json: any) {
        const keyNotes = new ProductPromoPageTitleAndTextSection()
        keyNotes.title = json.title
        keyNotes.text = json.text
        return keyNotes
    }

    toJson(item: any) {
        return {
        
        }
    }
}


export class ProductSerializer implements Serializer {
    fromJson(json: any) {
        const product = new Product()
        product.id = json.id
        product.uid = json.uid
        product.name = json.name
        if (json.created) {
            product.created = new Date(json.created *1000)
        }
        product.available = json.available
        product.price = json.price
        product.specialPrice = json.special_price
        product.description = json.description
        product.video = json.video_url
        product.howToUse = json.how_to_use
        product.shortDescription = json.short_description

        if (json.media) {
            product.media = json.media.map(a => mediaSerializer.fromJson(a))
            const [main] = product.media
            if (main) {
                product.mainImage = main.thumbnail
                product.image2000 = main.image2000
            }
        }
        if (json.embedded_inventory) {
            product.embeddedInventory = json.embedded_inventory.map(i => embeddedInventorySerializer.fromJson(i))
        } else {
            product.embeddedInventory = []
        }
        if (json.attributes) {
            product.attributes = json.attributes.map(a => attributeSerializer.fromJson(a))
        }
        if (json.also_use_products && json.also_use_products.length) {
            const [alsoUse] = json.also_use_products
            if (alsoUse) {
                product.alsoUseProduct = productSerializer.fromJson(alsoUse)
            }
        }
        if (json.facet) {
            product.facet = json.facet.map(a => attributeSerializer.fromJson(a))
        }
        if (json.simple_products) {
            const serializer = new SimpleProductSerializer()
            product.simpleProducts = json.simple_products.map(a => serializer.fromJson(a))
        }
        if (json.attribute_set) {
            product.attributeSet = attributeSetSerializer.fromJson(json.attribute_set)
        }
        if (json.brand) {
            product.brand = brandSerializer.fromJson(json.brand)
        }
        if (json.category) {
            const categorySerializer = new CategorySerializer()
            product.category = categorySerializer.fromJson(json.category)
        }
        if (json.configurables) {
            const attributeSerializer = new AttributeSerializer()
            product.configurables = json.configurables.map(a => attributeSerializer.fromJson(a))
        }
        if(json.firstBannerText) {
            product.firstBannerText = json.firstBannerText
        }
        if(json.secondBannerTitle) {
            product.secondBannerTitle = json.secondBannerTitle
        }
        if(json.secondBannerText) {
            product.secondBannerText = json.secondBannerText
        }
        if(json.bottomBannerTitle) {
            product.bottomBannerTitle = json.bottomBannerTitle
        }
        if(json.bottomBannerText) {
            product.bottomBannerText = json.bottomBannerText
        }
        return product
    }
    toJson(item: Product) {
        return {
        }
    }
}

export class ProductPromoPageSerializer implements Serializer {
    fromJson(json: any) {
        
        const item = new ProductPromoPage()
        item.type = json.type
        if (item.type === 'banner') {
            item.object = productPromoPageBannerserializer.fromJson(json.object)
        }
        if (item.type === 'text') {
            item.object = productPromoPageTextSectionserializer.fromJson(json.object)
        }
        if (item.type === 'key_notes') {
            item.object = productPromoPageKeyNoteSerializer.fromJson(json.object)
        }
        if (item.type === 'title_and_text_section') {
            item.object = productPromoPageTitleAndTextSectionSerializer.fromJson(json.object)
        }
        if (item.type === 'image_carousel') {
            item.object = productPromoPageCarouselSerializer.fromJson(json.object)
        }
        if (item.type === 'card_column_sections') {
            item.object = productPromoPageCardColumnSectionSerializer.fromJson(json.object)
        }
        if (item.type === 'image_video') {
            item.object = productPromoPageImageVideoSerializer.fromJson(json.object)
        }
        
        return item;
    }

    toJson(item: any) {
        return {
        
        }
    }
}
export class ProductPromoPageBannerSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductPromoPageBanner()
        item.image = `${BASE_PATH}/assets/product_promo_page_banners/${json.filename}.jpg`
        item.imageMobile = `${BASE_PATH}/assets/product_promo_page_banners/${json.filename_mobile}.jpg`
        item.type = json.type;
        item.video = `${VIDEOS_PATH}/videos/${json.video_filename}.mp4`
        return item;
    }
    toJson(item: any) {
        return {
        
        }
    }
}
export class ProductPromoPageImageVideoSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductPromoPageImageVideo()
        item.image = `${BASE_PATH}/assets/product_promo_page_banners/${json.filename}.jpg`
        item.video = `${VIDEOS_PATH}/videos/${json.video_filename}.mp4`
        return item;
    }
    toJson(item: any) {
        return {
        
        }
    }
}
export class ProductPromoPageCarouselSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductPromoPageCarousel()
        item.title = json.title
        item.text = json.text
        if(json.images && json.images.length) {
            item.images = json.images.map(i => productPromoPageSectionImageSerializer.fromJson(i))
        }
        item.buttonLink = json.button_link
        item.buttonText = json.button_text
        return item;
    }
    toJson(item: any) {
        return {
        
        }
    }
}
export class ProductPromoPageTextSectionSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductPromoPageTextSection()
        item.color = json.color;
        item.text = json.text;
        return item;
    }

    toJson(item: any) {
        return {        
        }
    }
}

export class ProductPromoPageSectionImageSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductPromoPageSectionImage()
        item.image = `${BASE_PATH}/assets/product_promo_page_carousel_images/${json.filename}.jpg`
        item.sort = json.sort
        return item;
    }

    toJson(item: any) {
        return {        
        }
    }
}
export class ProductPromoPageKeyNoteSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductPromoPageKeyNote()
        item.title = json.title
        item.text = json.text
        if(json.columns && json.columns.length) {
            item.columns = json.columns.map(i => keyNoteColumnSerializer.fromJson(i))
        }
        return item;
    }

    toJson(item: any) {
        return {
        }
    }
}
export class KeyNoteColumnSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductPromoPageColumn()
        item.image = `${BASE_PATH}/assets/product_promo_page_key_note_images/${json.filename}.jpg`
        item.text = json.text
        item.title = json.title
        return item;
    }

    toJson(item: any) {
        return {
        }
    }
}
export class ProductPromoPageCardColumnItemSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductPromoPageColumn()
        item.image = `${BASE_PATH}/assets/product_promo_page_cart_column_images/${json.filename}.jpg`
        item.text = json.text
        item.title = json.title
        return item;
    }

    toJson(item: any) {
        return {
        }
    }
}
export class ProductPromoPageCardColumnSectionSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductPromoPageCardColumnSection()
        if(json.columns && json.columns.length) {
            item.columns = json.columns.map(i => productPromoPageCardColumnItemSerializer.fromJson(i))
        }
        return item;
    }

    toJson(item: any) {
        return {
        }
    }
}


export const productSerializer = new ProductSerializer()
export const mediaSerializer = new ProductMediaSerializer()
export const productPromoPageKeyNoteSerializer = new ProductPromoPageKeyNoteSerializer()
export const productPromoPageBannerserializer = new ProductPromoPageBannerSerializer()
export const productPromoPageTextSectionserializer = new ProductPromoPageTextSectionSerializer()
export const productPromoPageCarouselSerializer = new ProductPromoPageCarouselSerializer()
export const productPromoPageTitleAndTextSectionSerializer = new ProductPromoPageTitleAndTextSectionSerializer()
export const productPromoPageSectionImageSerializer = new ProductPromoPageSectionImageSerializer()
export const productPromoPageCardColumnSectionSerializer = new ProductPromoPageCardColumnSectionSerializer()
export const productPromoPageCardColumnItemSerializer = new ProductPromoPageCardColumnItemSerializer()
export const productPromoPageImageVideoSerializer = new ProductPromoPageImageVideoSerializer()
export const keyNoteColumnSerializer = new KeyNoteColumnSerializer()