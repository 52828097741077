
import React, { useState, useEffect } from 'react';
import { Box, Button } from "@mui/material"

import { useHistory } from 'react-router';
import { Title } from '../../Typography/Title';

export const BrandPageBottomSectionComponent = (props: any) => {
    const { language, brand } = props
    const history = useHistory()

    useEffect(() => {

    }, [])

    return brand ? <Box sx={{
        width: window.innerWidth > 1200 ? 1200 : window.innerWidth,
        height: window.innerWidth > 1200 ? 400 : 300,
        alignItems: 'flex-start',
        flexDirection: window.innerWidth > 1200 ? 'row' : 'column',
        display: 'flex'
    }}>
        <div style={{ display: 'flex', width: window.innerWidth > 1200 ? '50%' : '100%', height: window.innerWidth > 1200 ? '100%' : 300, padding: window.innerWidth > 1200 ? 0 : 15 }}>
            <img src={brand.footerBanner} style={{width: '100%'}}/>
        </div>
        {window.innerWidth > 1200 && <div style={{
            display: 'flex', width: '50%', height: '95%', alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column', padding: 10
        }}>
            <Title text={`${brand?.footerBannerText}`} fontSize={16} uppercase={false} fontWeight='400' color={"#333"} />
            <Button
                onClick={() => {
                    history.push(brand.footerBannerUrl)
                }}
                sx={[{
                    marginTop: 3,
                    '&:hover': {
                        color: 'white',
                        backgroundColor: 'black',
                    },
                }, { backgroundColor: 'black', color: 'white', }]}>{brand.footerBannerTitle}</Button>


        </div>}
    </Box> : <></>
}