
import { connect } from 'react-redux'
import React, { useState } from "react"
import CloseIcon from '@mui/icons-material/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { Box, Button, TextField, Dialog } from "@mui/material";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarRateIcon from '@mui/icons-material/StarRate';

import { reviewService } from '../../models/review/service';
import { Review } from '../../models/review/Review';
import { Title } from '../Typography/Title';

const ReviewDialog = (props) => {
    const { open, onCloseDialog, translations, setSnackBar, app, product, onDone, language } = props
    const [text, setText] = useState("")
    const [rate, setRate] = useState(0)
    const [submitted, setSubmitted] = useState(false)

    const submitReview = () => {
        if (rate && text?.length) {
            const review = new Review()
            review.value = rate
            review.text = text
            review.product = product
            reviewService.create(review).then((resp: any) => {
                if (!resp.status) {
                    setSnackBar({ ...app.snackBar, open: true, text: `${translations.your_review_has_been_submitted}`, bColor: "rgb(83, 148, 96)" })
                    onCloseDialog(false)
                    setText("")
                    setRate(0)
                    onDone()
                }
            })
        }
    }

    return <div>

        <Dialog
            maxWidth='xl'
            style={{ padding: 10, minWidth: 300 }}
            open={open}
            onClose={onCloseDialog} aria-labelledby="form-dialog-title"
        >
            <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => {
                setRate(0)
                setSubmitted(false)
                onCloseDialog(false)
            }}>
                <CloseIcon />
            </div>

            <div style={{ paddingTop: 20, paddingLeft: 20, paddingBottom: 0 }}>


            </div>
            <Box sx={{ width: window.innerWidth > 400 ? window.innerWidth > 1200 ? 700 : 400 : 300 , marginTop: 4, height: window.innerWidth > 1200 ? 400 : 300, overflow: 'hidden', flexDirection: 'column', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <div style={{ width: '40%' }} >
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'space-around', justifyContent: 'center', height: 40 }}>
                        {[1, 2, 3, 4, 5].map((item, index) => {
                            return <Box
                                onClick={() => {
                                    setRate(item)
                                    setSubmitted(false)
                                }}
                                key={index}
                                sx={{ flexDirection: 'column', display: 'flex', width: window.innerWidth > 1200 ? '20%' : '60%' }}>
                                {(rate && rate >= item) ? <StarRateIcon sx={{ fontSize: 40 }} /> : <StarBorderIcon sx={{ fontSize: 40 }} />}
                            </Box>
                        })}
                    </Box>
                </div>
                <div style={{ width: '80%' }} >
                    <TextField
                        style={{ width: '100%', fontFamily: language === 'geo' ? 'FiraGo' : 'Roboto' }}
                        id="outlined-multiline-flexible"
                        label={translations?.your_opinion}
                        multiline
                        rows={5}
                        value={text}
                        onChange={(evt) => {
                            setSubmitted(false)
                            setText(evt.target.value)
                        }}
                    />
                </div>
                {submitted && text?.length === 0 && <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '80%', marginTop: -2 }}>
                    <Title
                        uppercase={false}
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        color={'red'}
                        fontSize={14}
                        fontWeight={'500'}
                        text={translations?.text_is_required} />

                </Box>}

                {submitted && rate === 0 && <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '80%', marginTop: -2 }}>
                    <Title
                        uppercase={false}
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        color={'red'}
                        fontSize={14}
                        fontWeight={'500'}
                        text={translations?.rate_is_required} />

                </Box>}

                <div style={{ width: '40%' , minWidth: '130px'}} >
                    <Button
                        disableRipple={true}
                        disableFocusRipple={false}
                        className={"dark-button"}
                        sx={{ color: rate ? "secondary" : 'gray' }}
                        type="submit" onClick={() => {
                            setSubmitted(true)
                            submitReview()
                        }}>
                        <Title
                            uppercase={true}
                            fontWeight={'500'}
                            fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                            color={'white'}
                            fontSize={13}
                            text={translations?.review_product} />

                    </Button>
                </div>
            </Box>
        </Dialog>
    </div>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations
    }
}

export default connect(mapStateToProps, { setSnackBar })(ReviewDialog)