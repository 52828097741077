import { ResourceProvider } from "../../providers/resource.provider"
import { Brand, BrandLetter } from "./brands";
import { BrandLetterSerializer, BrandSerializer } from "./serialize";

export class BrandProvider extends ResourceProvider<Brand> {
    constructor() {
        super("rest_brand", new BrandSerializer());
    }
}

export class BrandLetterProvider extends ResourceProvider<BrandLetter> {
    constructor() {
        super("rest_brand_letters", new BrandLetterSerializer());
    }
}

export const brandService = new BrandProvider()
export const brandLetterService = new BrandLetterProvider()

