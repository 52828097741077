import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import cookie from 'react-cookies'
import { User } from '../../models/user/user'
import Bar from './bar'
import { login, logout } from '../../models/user/authActions'
import { logout as logoutFromServer } from '../../models/user/service';
import { setAppLoaded, setCart, setDimensions, setFavorites } from '../../models/application/applicationActions';
import { initUserCartAndFavorites, onDimensionsChange$ } from '../../helpers/utilities';
import { translationsService } from '../../models/translation/translationReducer';
import { setTranslations } from '../../models/translation/translationActions';
import { setLanguage } from '../../models/language/languageActions';
import { DeliveryWarningComponent } from '../MainPage/DeliveryWarningComponent';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import HolidayPopup from '../Checkout/HolidayPopup';

const Header = (props) => {
    const {
        login,
        setDimensions,
        logout,
        setCart,
        setAppLoaded,
        setFavorites,
        language,
        setTranslations,
        setLanguage,
        translations
    } = props
    const { loggedIn, LOGOUT } = props?.auth

    const { width } = useWindowDimensions()
  const [open, setOpen] = useState(false)
  const [isWarningVisible, setIsWarningVisible] = useState(false)



    function handleResize() {
        onDimensionsChange$.next(window)
    }
    useEffect(() => {
        onDimensionsChange$.subscribe(
            debounced => {
                setDimensions({width: window.innerWidth, height: window.innerHeight})
            }
        );
    }, []);

    useEffect(() => {

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, [])

    useEffect(() => {
        const cookieInterval = setInterval(() => {
            const usr = getCookie()
            if (!usr) {
                if (loggedIn) {
                    LOGOUT()
                    const user = new User();
                    user.delete()
                    window.location.href = "/"
                    setCart([])
                    localStorage.removeItem("cart")
                }
            }
        }, 5000)
        getCookie()
        return () => {
            clearInterval(cookieInterval);
        }
    }, [])
    useEffect(() => {
        const lang = localStorage.getItem("language")
        if (lang) {
            setLanguage(lang)
        }
        if (language !== lang) {
            if (!lang) {
                setLanguage("geo")
                translationsService.getTranslations('geo').then(translations => {
                    setTranslations(translations)
                    localStorage.setItem('translations', JSON.stringify(translations))
                })
            } else {
                setLanguage(lang)
                translationsService.getTranslations(lang).then(translations => {
                    setTranslations(translations)
                    localStorage.setItem('translations', JSON.stringify(translations))
                })
            }
        } else {
            translationsService.getTranslations(language).then(translations => {
                setTranslations(translations)
                localStorage.setItem('translations', JSON.stringify(translations))
            })
        }
        setAppLoaded(true)
    }, [language])


    useEffect(() => {
        initUserCartAndFavorites(setCart, setFavorites, login)
    }, [])

    useEffect(() => {
        const currentDate = new Date();
        const targetDate = new Date('2024-01-03');
      
        const boolean = localStorage.getItem("holiday-popper");
      
        if (currentDate < targetDate && !boolean) {
          setTimeout(() => {
            setOpen(true)
            setIsWarningVisible(true)
          }, 1000)
        } 
        else if (currentDate < targetDate && boolean === "true") {
          setTimeout(() => {
            setOpen(false);
            setIsWarningVisible(true)
          },1000)
        }
        else if (currentDate > targetDate && !boolean) {
          setOpen(false)
          setIsWarningVisible(false)
        }
        else if (currentDate > targetDate && boolean === "true") {
            setOpen(false)
            setIsWarningVisible(false)
        } 
        
      }, []);

    return <header>
    {width > 600 && <HolidayPopup translations={translations} language={language} open={open} setOpen={setOpen}/>}

   {width > 600 && isWarningVisible && <DeliveryWarningComponent setOpen={setOpen} translations={translations}/>}

        <Bar {...props} language={language} logoutUser={() => {
            logoutFromServer().then(r => {
                logout()
                setCart([])
                setFavorites([])
                localStorage.removeItem("cart")
                localStorage.removeItem("favorites")
                const user = new User();
                user.delete()
            })
        }} />
    </header>

}

const getCookie = () => {
    return cookie.load('user')
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        loggedIn: state?.auth?.loggedIn,
        language: state.language,
        translations: state.translations
    }
}

export default connect(mapStateToProps, { login, logout, setCart, setFavorites, setTranslations, setLanguage, setAppLoaded, setDimensions })(Header)

