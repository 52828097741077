
import React, { useState, useEffect, Fragment } from "react";
import '../styles.scss'
import { makeStyles } from "@mui/styles";
import { Animated } from "react-animated-css";
import { SimpleProduct } from "../../models/product/product";
import { RateItem } from "../Review/RateItem";
import { QueryOptions } from "../../helpers/query.options";
import { reviewSummaryService } from "../../models/review/service";
import { ReviewSummary } from "../../models/review/Review";
import InnerImageZoom from 'react-inner-image-zoom';
import '../../App.css'
import { MobileProductDetailsRightColumn } from "./MobileProductDetailsRightColumn";

export const MobileProductPopupItem = (props) => {
    const { item } = props
    const classes = useStyles();
    const [price, setPrice] = useState(item?.specialPrice ? item.specialPrice : item?.price ? item.price : 0)
    const [simple, setSimple] = useState<SimpleProduct>()
    const [image, setImage] = useState<any>()
    const [qty, setQty] = useState(1)
    const [qtyOpen, setQtyOpen] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)
    const [reviewSummary, setReviewSummary] = useState<ReviewSummary>()

    useEffect(() => {
        if (item?.mainImage) {
            setImage(item.mainImage)
            loadReviewSummary(item.id)
        }
        if (item) {
            setPrice(item?.specialPrice ? item.specialPrice : item?.price ? item.price : 0)
        }
        if (item && !item?.configurables?.length) {
            const [simple] = item.simpleProducts
            setSimple(simple)
        }
    }, [item])

    useEffect(() => {
        if (simple) {
            setPrice(simple.price)
        }
    }, [simple])
    useEffect(() => {
        setQtyOpen(false)
    }, [qty])

    useEffect(() => {
        if (image) {
            const img = new Image()
            img.src = item.thumbnail
            img.onload = () => {
                setImageLoaded(true)
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }
    }, [image])

    const loadReviewSummary = (id) => {
        const options = new QueryOptions()
        options.id = id
        reviewSummaryService.read(id, options).then((r: any) => {
            setReviewSummary(r)
        })
    }



    return item ? <Animated animationIn="fadeInUp" animationOut="fadeOutUp"
        animationInDuration={600}
        isVisible={item}>
        <div className={classes.container} >
            <Animated animationIn="zoomIn" animationOut="fadeOutUp"
                className={classes.column} style={{ width: '50%', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', paddingTop: 0, position: 'relative' }}
                animationInDuration={800}
                isVisible={true}>
                <InnerImageZoom src={image} zoomSrc={image} width={window.innerWidth * .7} />
                {reviewSummary?.average > 0 && <div style={{ marginTop: 10 }}>
                    <RateItem rate={reviewSummary.average} />
                    
                </div>}
            </Animated>
            <div className={classes.row} style={{ width: '100%', justifyContent: 'space-between', paddingBottom: 10, height: 370 }}>
                <MobileProductDetailsRightColumn {...props} item={item} onSetSimple={setSimple} source={"popup"} />
            </div>
        </div>
    </Animated > : <></>
}

const useStyles = makeStyles({
    container: {
        marginTop: 0,
        flexDirection: 'column',
        position: "relative",
        width: '100%',
        flex: 1,
        alignItems: "center",
        height: '100%',
        display: 'flex',
        borderRadius: 5,
    },
    column: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        width: '100%',
        flexDirection: 'column',
    },
    leftColumn: {
        width: '50%',
        backgroundColor: 'blue'
    },
    tag: {
        alignItems: 'center',
        display: 'flex',
        verticalAlign: 'middle',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        position: "absolute",
        textAlign: 'center',
        fontSize: '13px',
        fontWeight: 'bold',
        left: 10,
        top: -10,
        color: 'white',
        borderRadius: 30,
        width: 40,
        height: 40
    },
    info: {
        marginTop: -10,
        flexDirection: 'row'
    },
    price: {
        color: 'red',
        textDecoration: 'line-through'
    },
    specialPrice: {
        marginLeft: 10
    },
    image: {
        marginBottom: -5,
        top: 0,
        zIndex: 100,
        left: 0,
    }
});
