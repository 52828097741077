import { Resource } from "../resource";

export class News extends Resource {
    created:     string;
    description: string;
    image: string;
    filename:    string;
    title:       string;
    name: string;
}

