import { Box } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { Category } from '../../models/category/category';
import { Title } from '../Typography/Title';
import { Animated } from "react-animated-css";

export const RenderCatText = ({ item, index, history, language, location, isButton=true }) => {
    const [isVisible, setIsVisible] = useState(false)
    const [showContent, setShowContent] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true)
        }, 50 * index)
        return () => {
            setShowContent(false)
        }
    }, [])
    return showContent ? <Animated
        animationIn={"fadeIn"}
        animationOut={"fadeOut"}
        animationInDuration={600}
        isVisible={isVisible}>
        <div
            key={index}
            style={{ cursor: 'pointer' }}
            onClick={() => {
                if (isButton && location.pathname !== item.url) {
                    history.push(item.url)
                }
            }}><Title
                uppercase={false}
                style={{ textAlign: 'left', opacity: isVisible ? 1 : 0, margin: 2 }}
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                color={'#333'}
                fontSize={15}
                fontWeight={'400'}
                text={item.title}
                key={index}
            />
        </div>
    </Animated>: <></>
}

export const CategoriesList = (props: any) => {
    const { history, child, cat, language, categories, parent, location, translations, breadCrumbs } = props
    const [categoriesMenu, setCategoriesMenu] = useState<Category[]>([])
    const [currentCat, setCurrentCat] = useState<any>()

    useEffect(() => {
        if (categories?.length) {
            const [cats] = categories.filter(c => c.id === cat)
            if (cats?.children?.length) {
                const [children] = cats?.children.filter(c => c.id === parent)
                if (children?.children?.length) {
                    setCategoriesMenu(children?.children)
                } else {
                    setCategoriesMenu(cats.children)
                }
            }
        }
    }, [categories, parent, cat, location])

    useEffect(() => {
        if (breadCrumbs?.length) {
            const [cat] = breadCrumbs.filter((e, ind) => ind > 0).reverse()
            setCurrentCat(cat)
        }
    }, [breadCrumbs])

    const RenderCategories = useCallback(() => {
        return <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', paddingTop: 1 }}>
            {currentCat && <Title
                uppercase={true}
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                color={'#333'}
                lineHeight={1}
                fontSize={19}
                style={{ marginBottom: 40, marginLeft:2 }}
                fontWeight={'600'}
                text={currentCat.name} />}
            {currentCat && categoriesMenu.filter(i => i.url !== currentCat.link).map((item, index) => {
                return <RenderCatText item={item} {...props} index={index} key={index}/>
            })}
        </Box>
    }, [child, cat, parent, categoriesMenu])

    return <Box sx={{overflow: 'hidden'}}>
        <RenderCategories />
    </Box>


}
