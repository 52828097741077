import React  from 'react';
import { Accordion, AccordionSummary, Box, Checkbox } from '@mui/material';
import { isEmpty } from 'lodash';
import { Title } from '../Typography/Title';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const FilterAccordionItem = (props) => {
    const { item, filter, language, onClick, items, translations } = props

    return <Accordion disableGutters sx={{
        padding: 0, width: '96%', "&.MuiAccordion-root": {
            boxShadow: '0px 0px rgba(0,0,0,0)',
        }
    }}>
        <AccordionSummary
            sx={{
                boxShadow: '0px 0px rgba(0,0,0,0)',
                backgroundColor: 'transparent',
                width: '100%',
                height: 40,
                minHeight: 40,
                maxHeight: 40,
                margin: 0,
                padding: 0,
                border: '0px solid black',
            }}
            expandIcon={ <ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Title text={item.value ? item.value : translations[item.id]} fontSize={14} uppercase={true} fontWeight='600' />
        </AccordionSummary>
        {!isEmpty(filter) && filter[item.id]?.map((fItem, index) => {
            return <Box
                key={index}
                onClick={() => {
                    onClick(fItem)
                }}
                sx={{
                    cursor: 'pointer',
                    paddingLeft: 1, 
                    flex: 1, 
                    display: 'flex',
                    flexDirection: 'row', justifyContent: 'space-between', alignItems: 'space-between', width: '90%'
                }}>
                <Checkbox style={{ padding: 0, color: '#444', top: -3 }} checked={items.map(e => String(e.id)).indexOf(fItem.id) > -1} />
                <Box sx={{
                    width: '100%',
                    height: 20,
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                    paddingLeft: 1,
                    alignSelf: 'flex-start',
                    alignItems: 'center',
                    textAlign: 'left',
                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                }}>
                    <div style={{
                        display: 'flex',
                        fontWeight: '400',
                        flexDirection: 'column',
                        fontSize: 14,
                        fontFamily: language === 'geo' ? 'FiraGo' : "Roboto",
                        color: 'black',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '95%',
                        alignSelf: 'flex-start'
                    }} >
                        {fItem.value}
                    </div>
                </Box>
                <Title text={`${fItem.count}`} fontSize={15} uppercase={true} fontWeight='400' color={"black"} />
            </Box>
        })}
    </Accordion>
}