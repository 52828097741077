import { environment } from "../../environment/environment"
import { attributeSerializer } from "../attribute/serializer"
import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { Finder, FinderStep } from "./Finder"


export class FinderSerializer implements Serializer {
    fromJson(json: any) {
        const finder = new Finder()
        finder.image = `${environment.basePath}/${environment.imagesPath}/finder/${json.image}.jpg`
        finder.title = json.title
        finder.steps = json.steps.map(step => finderStepSerializer.fromJson(step))
        return finder
    }
    toJson(item: any) {
        return {
        }
    }
}

export class FinderStepSerializer implements Serializer {
    fromJson(json: any) {
        const step = new FinderStep()
        if (json.choices) {
            step.choices = json.choices.map(choice => attributeSerializer.fromJson(choice))
        }
        step.question = json.question
        step.sort = json.sort
        return step
    }
    toJson(item: any) {
        return {
        }
    }
}

export const finderSerializer = new FinderSerializer()
export const finderStepSerializer = new FinderStepSerializer()