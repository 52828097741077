import React from 'react';
import { Accordion, AccordionSummary, Box, Checkbox } from '@mui/material';
import { isEmpty } from 'lodash';
import { Title } from '../Typography/Title';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const FilterAccordionItemColor = (props) => {
    const { item, filter, language, onClick, selectedFilters, translations } = props
    return <Accordion disableGutters sx={{
        padding: 0, width: '96%', "&.MuiAccordion-root": {
            boxShadow: '0px 0px rgba(0,0,0,0)'
        }
    }}>
        <AccordionSummary
            sx={{
                boxShadow: '0px 0px rgba(0,0,0,0)',
                backgroundColor: 'transparent',
                width: '100%',
                height: 40,
                minHeight: 40,
                maxHeight: 40,
                margin: 0,
                padding: 0,
                border: '0px solid black',
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Title text={item.value ? item.value : translations[item.id]} fontSize={13} uppercase={true} fontWeight='600' />
        </AccordionSummary>
        <Box sx={{
            padding: .4, 
            alignItems: 'center', 
            marginTop: 0, 
            flexWrap: 'wrap', 
            display: 'flex', 
            flexGrow: 1
        }}>
            {!isEmpty(filter) && filter[item.id]?.map((fItem, index) => {
                return <Box
                    key={index}
                    onClick={() => {
                        onClick(fItem)
                    }}
                    style={{
                        display: 'flex',
                        flexGrow: 1,
                        backgroundColor: `#${fItem.value}`, 
                        maxWidth: 30,
                        width: 30, 
                        height: 30, 
                        borderWidth: 1, 
                        border:
                            selectedFilters.map(e => e.id).indexOf(fItem.id) > -1 ? '1px solid #000' : '1px solid #ddd',
                        borderRadius: 3,
                        margin: 2, 
                        cursor: 'pointer'
                    }}></Box>

            })}

        </Box>
    </Accordion>
}