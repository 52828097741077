import { Box, List, ListItem, ListSubheader } from '@mui/material';
import React from 'react';
import { Title } from '../../../components/Typography/Title';
import { Animated } from "react-animated-css";
import { MobileLetterBrandSection } from '../../../components/Brand/MobileLetterBrandSection';


export const BrandListContentMobile = (props) => {
  const { letters, brandsListRef, lettersRef, brands, history, showLetters } = props
  return <Box sx={{ backgroundColor: 'blue', width: window.innerWidth, position: 'relative' }} ref={lettersRef}>

    <List
      ref={brandsListRef}
      sx={{
        paddingBottom: 4,
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        '& ul': { padding: 0 },
      }}
      subheader={<li />}
    >
      {letters.map((item, index) => (
        <li key={index} id={`ref_${item.id}`}>
          <ul>
            <ListSubheader sx={{ backgroundColor: '#444', padding: .5, paddingLeft: 1 }}>
              <Title
                text={`${item.id}`}
                fontSize={18}
                style={{ margin: 0, marginTop: 0 }}
                uppercase={true}
                fontWeight='500'
                color={"white"} />
            </ListSubheader>
            <ListItem key={`item-${item.id}-`} sx={{ padding: 0 }} >
              <MobileLetterBrandSection
                showLetter={false}
                length={letters.length}
                key={index}
                index={index}
                brands={brands}
                item={item}
                onClick={(brand) => {
                  history.push(`brand/${brand.id}`)
                }} />
            </ListItem>
          </ul>
        </li>
      ))}
    </List>

    <Animated animationIn="slideInRight" animationOut="slideOutRight"
      style={{
        width: 40,
        paddingTop: 50,
        height: window.innerHeight,
        backgroundColor: 'white',
        position: 'fixed',
        zIndex: 100,
        boxShadow: '0px 0px 4px rgba(0,0,0,.5)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-even',
        top: 50,
        right: 0
      }}
      animationInDuration={300}
      isVisible={showLetters} >
      <Box sx={{

      }}>
        {letters?.map((letter, index) => {
          return <Box
            style={{ height: (window.innerHeight - 160) / letters?.length }}
            onClick={() => {
              const el = document.getElementById(`ref_${letter.id}`);
              window.scrollTo({
                top: el.offsetTop + lettersRef.current.offsetTop - 80,
                behavior: 'smooth',
              });
            }}
            key={index}>{letter.id}</Box>
        })}
      </Box></Animated>
  </Box>
}