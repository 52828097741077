import { ResourceProvider } from "../../providers/resource.provider"
import { PriceRange } from "./PriceRange"
import { PriceRangeSerializer } from "./serializer"


export class PriceRangeProvider extends ResourceProvider<PriceRange> {
    constructor() {
        super("rest_price_range", new PriceRangeSerializer())
    }
}

export const priceRangeService = new PriceRangeProvider()

