import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import { CartItem } from "../../models/cart/cart";
import { Box } from "@mui/material";

export const CartItemComponent = (props) => {
    const { item, redirect, onRemove  } = props
    const classes = useStyles();

    const onRedirect = (item: CartItem) => {
        if (["e_gift_card_001", "gift_card_001"].indexOf(item.sku) < 0) {
            redirect(`/product/${item.product.id}`)
        }
    }
    return <li
        className={classes.listItem}>
        <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 20 }}>
            
            <div style={{ flexDirection: 'column', width: '17%', cursor: 'pointer' }} onClick={()=> onRedirect(item)}>
                <img src={item.product.mainImage} style={{ width: 30 }} />
            </div>
            <Box sx={{ 
                '&:hover': {
                    textDecoration: 'underline'
                },
                flexDirection: 'column', width: '55%', cursor: 'pointer', display: 'flex' }} onClick={()=> onRedirect(item)}>
                <span style={{fontWeight: 'bold'}}>{item.product.brand.title}</span>
                <span>{item.product.name}</span>
            </Box>
            <div style={{ flexDirection: 'column', width: '5%', cursor: 'pointer' }} onClick={()=> onRedirect(item)}>
                <span>{item.quantity}</span>
            </div>
            <div style={{ flexDirection: 'column', width: '20%', textAlign: 'right', position: 'relative', cursor: 'pointer' }}>
                <span>{item?.specialPrice && item.specialPrice * item.quantity}</span> ₾
                <div style={{}} onClick={()=> {
                    onRemove(item)
                }}>
                    <CloseIcon />
                </div>
            </div>
        </div>
    </li>
}


const useStyles = makeStyles({
    listItem: { color: 'black', fontFamily: 'FiraGo', fontSize: 12, fontWeight: '300' }

});
