import { Serializer } from "../serializer"
import { Settings } from "./settings"

export class SettingsSerializer implements Serializer {
    fromJson(json: any) {
        const item = new Settings()
        item.freeShippingMin = json.free_shipping_min
        return item
    }
    toJson(item: any) {
        return {
            
        }
    }
}

