import { environment } from "../../environment/environment"
import { productSerializer } from "../product/serializer"
import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { UserSerializer } from "../user/serializer"
import { Review, ReviewSummary, Summary } from "./Review"


export class ReviewSerializer implements Serializer {
    fromJson(json: any) {
        const item = new Review()
        item.created = json.created
        const useSerializer = new UserSerializer()
        item.user = useSerializer.fromJson(json.user)
        item.product = productSerializer.fromJson(json.product)
        item.text = json.text
        item.value = json.value
        return item
    }
    toJson(item: Review) {
        return {
            value: item.value, text: item.text, product: item.product.id
        }
    }
}
export class ReviewSummarySerializer implements Serializer {
    fromJson(json: any) {
        const item = new ReviewSummary()
        const summarySerializer = new ReviewSummaryItemsSerializer()
        item.summary = json.summary.map(i => summarySerializer.fromJson(i))
        item.average = json.average
        item.count = json.count
        return item
    }
    toJson(item: Review) {
        return {
            value: item.value, text: item.text, product: item.product.id
        }
    }
}
export class ReviewSummaryItemsSerializer implements Serializer {
    fromJson(json: any) {
        const item = new Summary()
        item.count = json.count
        item.id = json._id
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
