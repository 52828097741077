import {  Box, Typography } from "@mui/material"
import React from "react"
import { useHistory } from "react-router"

export const MobileMenuComponent = (props) => {
    const { accountMenuItems, title } = props
    const history = useHistory()

    return (
        <>
         <Typography variant="body1" sx={{ textAlign: "left", marginTop: 5, paddingLeft: 4,  fontWeight:'bold' }}>
                                    {title}
                                </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", paddingLeft: 2,  marginTop: 2 }}>
                {accountMenuItems?.map((item, index) => {
                    return (
                        <Box sx={{ width: "100%", }} key={item.id} onClick={() => {
                            history.push(item.url)
                        }}>
                            <Box
                                sx={{
                                    paddingTop: 0,
                                    width: "100%",
                                    "&.MuiAccordion-root": {
                                        boxShadow: "0px 0px rgba(0,0,0,0)",
                                    },
                                }}
                            >
                                <Typography variant="body1" sx={{ textAlign: "left", padding: 2,  }}>
                                    {item.title}
                                </Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </>
    )
}
