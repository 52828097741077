import { ResourceProvider } from '../../providers/resource.provider';
import { Subject } from 'rxjs';
import { TopHeaderBanner } from './TopHeaderBanner';
import { TopHeaderBannerSerializer } from './serializer';


export class TopHeaderBannerProvider extends ResourceProvider<TopHeaderBanner> {
  private setParamsEvent = new Subject<any>();
  setParamsEvent$ = this.setParamsEvent.asObservable();

  constructor() {
    super(
      'rest_top_header_banner',
      new TopHeaderBannerSerializer());
  }
}


export const topHeaderBannerService = new TopHeaderBannerProvider()
