import { ResourceProvider } from "../../providers/resource.provider"
import { Attribute, AttributeSet } from "./attribute"
import { AttributeSerializer, AttributeSetSerializer } from "./serializer"


export class AttributeSetProvider extends ResourceProvider<AttributeSet> {
    constructor() {
        super("rest_attribute_set", new AttributeSetSerializer())
    }
}
export class AttributeProvider extends ResourceProvider<Attribute> {
    constructor() {
        super("rest_attribute", new AttributeSerializer())
    }
}

export const attributeSetService = new AttributeSetProvider()
export const attributeService = new AttributeProvider()

