
import React from "react";
import '../../styles.scss'

import { Button } from "@mui/material";
import { addToFavorites } from "../../../helpers/utilities";
import HeartOutlined from '@mui/icons-material/Favorite';
import '../../../App.css'
import { COLORS } from "../../../constants/colors";
import { Title } from "../../Typography/Title";


export const AddToFavsButton = (props) => {
    const {simple, setSnackBar, setFavorites, item, app, auth, translations, onClose, setAnimate, language} = props;
    return <Button
        sx={{
            color: 'black',
            backgroundColor: COLORS.BEIGE_I,
            '&:hover': {
                color: 'black',
                backgroundColor: COLORS.BEIGE_I,
            },
            width: '100%',
            height: 40,
            borderRadius: 10,
        }}
        disableRipple={true}
        disableFocusRipple={false}
        color={"secondary"}
        onClick={() => {
            if (simple) {
                addToFavorites(item, simple.sku, app, auth).then((r: any) => {
                    localStorage.setItem("favorites", JSON.stringify(r))
                    setSnackBar({ ...app.snackBar, open: true, text: `${item.name} ${translations?.has_been_added_to_favs}`, bColor: "rgb(83, 148, 96)" })
                    setFavorites(r)
                    onClose()
                })
            } else {
                setAnimate(true)
                setTimeout(() => {
                    setAnimate(false)
                }, 2000)
            }
        }}
    >
        <HeartOutlined style={{ marginRight: 10, color: '#C21807' }} />
        <Title
            uppercase={true}
            fontWeight={'500'}
            fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
            color={'#444'}
            fontSize={12}
            text={translations?.add_to_favorites} />

    </Button>
}