import React, { useState, useEffect } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Navigation, FreeMode, Thumbs } from "swiper";
import Grid from "@mui/material/Grid";
import "./carousel.css"
export default function ProductCarousel(props: any) {
    const { items } = props
    const [thumbsSwiper, setThumbsSwiper] = useState(null);


    return <>
        <view style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flex: 1, display: 'flex' }}>

            {items.length > 0 &&
                <Grid item lg={2} sm={4} style={{
                    maxWidth: 100, minHeight: 240, position: 'relative', minWidth: 60,
                }}>

                    <Box
                        className="vertical-scroll"
                        sx={{
                            width: '90%',
                            height: 380,
                            maxHeight: 361
                        }}>
                        <Swiper
                            direction={"vertical"}
                            onSwiper={setThumbsSwiper}
                            spaceBetween={10}
                            slidesPerView={5}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Thumbs]}
                            className="mySwiper3"
                            style={{  height: 380,
                                maxHeight: 361 }}
                        >
                            {items.map((item, index) => {
                                return <SwiperSlide><div key={index} style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',

                                    position: 'relative',

                                }}>
                                    <img src={item.image} style={{ width: '90%', borderRadius: 10 }} />
                                </div>
                                </SwiperSlide>
                            })}
                        </Swiper>
                    </Box>

                </Grid>
            }
            <Swiper
                style={{ minWidth: 400 }}
                navigation={true}
                modules={[Navigation, FreeMode, Thumbs]}
                thumbs={{ swiper: thumbsSwiper }}
                className="mySwiper2"
            >
                {items?.map((item, index) => {
                    return <SwiperSlide><div key={index} style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',

                        position: 'relative',
                    }}>
                        <img src={item.image} style={{ width: '80%', borderRadius: 10 }} />
                    </div>
                    </SwiperSlide>
                })}
            </Swiper>
        </view>
    </>

}
