import React, { useState, useEffect } from "react";
import "./App.css";
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { makeStyles } from '@mui/styles';
import {
  BrowserRouter as Router
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Routing from './routing'
import Header from './components/Header/header'
import Footer from "./components/Footer/Footer";
import { getWindowDimensions } from "./helpers/utilities";
import { CookiesProvider } from 'react-cookie';
import ReactPixel from 'react-facebook-pixel';
import MessengerCustomerChat from 'react-messenger-customer-chat';

export const customHistory = createBrowserHistory();

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {

    textAlign: "center",
  },
  leftcolumn: {
    padding: 10
  }
})

function App() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const classes = useStyles();

  useEffect(() => {
    ReactPixel.init('958842232201007');
  }, []);

  return (
    <CookiesProvider>
      <Provider store={store}>
        <div className="App" style={{ padding: 0, margin: 0, overflow: 'hidden' }}>
          <Router forceRefresh={false}>
            <Header />
            <div style={{ padding: 0, minHeight: window.innerHeight - 220 }}>
              <Routing style={{ overflow: 'hidden' }} />
            </div>
            <div style={{position: 'absolute', bottom: '60px', left: '20px'}}>
            <MessengerCustomerChat
              pageId="847584281942497"
              appId="244256288160116"
              themeColor="#000000" />

            </div>
            <Footer />
          </Router>
        </div>
      </Provider>
    </CookiesProvider>
  );
}

export default App;
