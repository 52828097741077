import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Typography, Box, Link } from '@mui/material';
import React from "react";


export const PhoneNumber = (props) => {
    const {fontSize, iconSize, radius, height, left} = props
    return <Box style={{ display: 'inline-block', padding: 6, marginTop: -2, position: 'relative', width: 250, height: 20 }}>
        <Box style={{
            width: radius, height: radius,
            position: 'absolute',
            left: 0,
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: 30, border: `none`, display: 'inline-flex'
        }}>
            <LocalPhoneIcon color={props.color} sx={{
                color: props.color, fontSize: iconSize
            }} fontSize={'medium'} />
        </Box>
        <Box style={{
            left,
            height,
            position: 'absolute',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            display: 'inline-flex',
            width: '40%'
        }}>
            <Typography>
                <Link href={`tel:${props.phoneNumber}`}
                sx={{ display: 'inline-block', textDecoration: 'none', top: 0, fontSize, color: props.color}}
                >
                    {props.phoneNumber}
                </Link>
            </Typography>
        </Box>
    </Box>
}