import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { ProductCheckoutComponent } from '../Product/ProductCheckoutComponent'
import { cartService } from '../../models/cart/service'
import { addToFavorites, getCartTotalWithStock, loadCartItems, removeCartItem } from '../../helpers/utilities'
import { ProductCheckoutComponentMobile } from '../Product/ProductCheckoutComponentMobile'
import { BlackButton } from '../Button/BlackButton'
import { QueryOptions } from '../../helpers/query.options'


export const CheckoutFirstStepComponent = (props) => {
  const { app, setConfirmation, translations, auth, setSnackBar, setCart, setFavorites, setStep, onUpdateCart, language } = props


  useEffect(() => {
    setTimeout(() => {
      loadCartItems(setCart, language)
    }, 500)
  }, [])

  const onRemoveItem = (confirmation) => {
    if (auth.loggedIn) {
      cartService.delete(confirmation.item.sku).then(() => {
        loadCartItems(setCart, language)
        setConfirmation({ ...confirmation, open: false })
        setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted}`, bColor: "rgb(83, 148, 96)" })
      })
    } else {
      removeCartItem(confirmation.item.sku, app, setCart)
      setConfirmation({ ...confirmation, open: false })
      setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted}`, bColor: "rgb(83, 148, 96)" })
    }
  }

  const onMoveToFavorites = (confirmation) => {
    addToFavorites(confirmation.item.product, confirmation.item.sku, app, auth).then(r => {
      setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_added_to_favourites}`, bColor: "rgb(83, 148, 96)" })
      setFavorites(r)
      if (auth.loggedIn) {
        cartService.delete(confirmation.item.sku).then(() => {
          loadCartItems(setCart, language)
          setConfirmation({ ...confirmation, open: false })
        })
      } else {
        removeCartItem(confirmation.item.sku, app, setCart)
        setConfirmation({ ...confirmation, open: false })
      }
    })
  }


  return <Grid container style={{ padding: window.innerWidth > 1200 ? 30 : 10 }}>
    <Grid item lg={9} sm={12}>
      {app?.cart?.sort((n1, n2) => { if (n1.created > n2.created) { return -1; } if (n1.created < n2.created) { return 1; } return 0; }).map((item, index) => {
        return item.loaded ? <Box
          key={index} sx={{
            width: '100%',
            borderRadius: 2,
            marginTop: 2,
            boxShadow: '0px 0px 4px rgba(0,0,0,.5)',
          }}>
          {window.innerWidth > 1200 ? <ProductCheckoutComponent
            onUpdateCart={onUpdateCart}
            item={item}
            onMessage={(qty) => {
              setSnackBar({ ...app.snackBar, open: true, text: `${translations.only_available} ${qty} ${translations.items}`, bColor: "rgb(83, 148, 96)" })
            }}
            translations={translations}
            redirect={() => { }}
            onRemove={(item) => {
              setConfirmation({
                open: true,
                item,
                type: 'removeItemFromCart',
                textAccept: `${translations?.delete}`,
                text: `${translations?.do_you_really_want_to_delete_from_cart}`,
                title: item.product.name,
                onAgreed: onRemoveItem
              })
            }}
            onMoveToFavorites={(item) => {
              setConfirmation({
                open: true,
                item,
                type: 'removeItemFromCart',
                textAccept: `${translations?.move}`,
                text: `${translations?.do_you_want_to_move_to_favorites}`,
                title: item.product.name,
                onAgreed: onMoveToFavorites
              })
            }}
            key={index} /> :
            <ProductCheckoutComponentMobile
              {...props}
              language={language}
              onUpdateCart={onUpdateCart}
              item={item}
              translations={translations}
              redirect={() => { }}
              onRemove={(item) => {
                setConfirmation({
                  open: true,
                  item,
                  type: 'removeItemFromCart',
                  textAccept: `${translations?.delete}`,
                  text: `${translations?.do_you_really_want_to_delete_from_cart}`,
                  title: item.product.name,
                  onAgreed: onRemoveItem
                })
              }}
              onMoveToFavorites={(item) => {
                setConfirmation({
                  open: true,
                  item,
                  type: 'removeItemFromCart',
                  textAccept: `${translations?.move}`,
                  text: `${translations?.do_you_want_to_move_to_favorites}`,
                  title: item.product.name,
                  onAgreed: onMoveToFavorites
                })
              }}
              key={index} />
          }
        </Box> : <></>
      })}
    </Grid>
    <Grid item lg={3} xs={12} style={{ padding: window.innerWidth > 1200 ? 10 : 0, paddingTop: 0, paddingBottom: window.innerWidth > 1200 ? 0 : 50 }}>
      <Box sx={{
        width: '100%',
        borderRadius: 2,
        height: 130,
        marginTop: 2,
        paddingTop: 1.2,
        boxShadow: '0px 0px 4px rgba(0,0,0,.5)',
      }}>
        <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 20, paddingRight: 20 }}>
          <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
            <Typography
              component="h4"
              variant="subtitle2"
              className={'uppercase'}
              style={{ fontWeight: 'bold', fontSize: 17, textTransform: 'uppercase' }}>
              {translations?.sum}
            </Typography>
          </div>
          <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
            <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 17, }}>
              {getCartTotalWithStock(app.cart)} ₾
            </Typography>
          </div>
        </div>
        <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex', marginTop: 20, padding: 20 }}>
          <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
            <Typography
              component="h4"
              variant="subtitle2"
              className={'uppercase'}
              style={{ fontWeight: '500', fontSize: 15, textTransform: 'uppercase' }}>
              {translations?.you_have_items_in_your_cart ? translations?.you_have_items_in_your_cart.replace("%s", app.cart?.length) : ''}
            </Typography>
          </div>

        </div>
      </Box>
      <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex', padding: 20, paddingBottom: 20, marginTop: 20 }}>
        <BlackButton
          title={translations.next}
          onClick={() => { setStep(2) }}
        />
      </div>
    </Grid>
  </Grid >

}

