import React, { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Navigation } from "swiper"
import { Mousewheel } from "swiper"
import { Keyboard } from "swiper"
import { Autoplay } from "swiper"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { Box } from "@mui/material"

export const GiftCardsCarousel = (props) => {
    const { setSelectedGiftCard, templates = [], uploadedImg } = props
    const [activeIndex, setActiveIndex] = useState(0)
    const { width } = useWindowDimensions()

    const [filteredImages, setFilteredImages] = useState([])

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex)
        if (width < 600) {
            setSelectedGiftCard(templates[swiper.activeIndex])
        }
    }

    const selectGiftCard = (img) => {
        setSelectedGiftCard(img)
    }

    useEffect(() => {
        if (uploadedImg) {
            setFilteredImages((prev) => [uploadedImg, ...prev])
        }
    }, [uploadedImg])

    return (
        <div style={{ display: "flex" }}>
            {templates.length > 2 && width > 600 ? (
                <Swiper
                    onSlideChange={handleSlideChange}
                    cssMode={true}
                    navigation={{
                        prevEl: ".giftcards-prev",
                        nextEl: ".giftcards-next",
                    }}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Mousewheel, Keyboard, Autoplay]}
                    slidesPerView={width < 600 ? 1 : templates.length < 3 ? templates.length : 3}
                    centeredSlides={false}
                    className="mySwiper"
                >
                    {templates?.map((item, index) => (
                        <Box key={index}>
                            <SwiperSlide
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <img
                                    src={item.image}
                                    style={{ width: "95%", cursor: "pointer", aspectRatio: "16/9" }}
                                    onClick={() => selectGiftCard(item)}
                                />
                            </SwiperSlide>
                        </Box>
                    ))}
                </Swiper>
            ) : templates.length < 3 && width > 600 ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                    }}
                >
                    {templates?.map((item, index) => (
                        <img
                            key={index}
                            src={item.image}
                            style={{ width: "48%", cursor: "pointer", aspectRatio: "16/9", margin: 3 }}
                            onClick={() => selectGiftCard(item)}
                        />
                    ))}
                </Box>
            ) : (
                <Swiper
                    onSlideChange={handleSlideChange}
                    cssMode={true}
                    navigation={{
                        prevEl: ".giftcards-prev",
                        nextEl: ".giftcards-next",
                    }}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Mousewheel, Keyboard, Autoplay]}
                    slidesPerView={width < 600 ? 1 : templates.length < 3 ? templates.length : 3}
                    centeredSlides={false}
                    className="mySwiper"
                >
                    {templates?.map((item, index) => (
                        <Box key={index}>
                            <SwiperSlide
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <img
                                    src={item.image}
                                    style={{ width: "95%", cursor: "pointer", aspectRatio: "16/9" }}
                                    onClick={() => selectGiftCard(item)}
                                />
                            </SwiperSlide>
                        </Box>
                    ))}
                </Swiper>
            )}
        </div>
    )
}
