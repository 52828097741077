import { Resource } from "../resource";

export class Category extends Resource {
    children: Category[]
    level: number
    parentId: string
    sort: number
    title: string
    uid: string
    image: string | null
    banner: string | null
    url: string
    imageLink: string
}