import { Box, CircularProgress, Divider, Grid } from "@mui/material"
import React, { Fragment, useCallback, useEffect, useRef, useState, } from "react"
import { Product } from "../../models/product/product"
import { productService } from "../../models/product/service"
import Pagination from "../Pagination/Pagination"
import { Animated } from "react-animated-css";
import { Title } from "../Typography/Title"
import { SortPopover } from "../Popover/SortPopover"
import { generateParamsFromUrlWithoutPrice, Root } from "../../helpers/utilities"
import { ProductItem } from "./ProductItem"

export const ProductGrid = (props) => {
    const { queryParams, history, onSetPage, location, translations, language, onSetQueryParams=()=>{}, filters } = props
    const sortObj = localStorage.getItem("sort")
    const [sort, setSort] = useState(sortObj ? JSON.parse(sortObj) : { sort: "uploaded", order: "desc" })
    const [products, setProducts] = useState<Product[]>([])
    const [loading, setLoading] = useState(false)
    const listRef: any = useRef()
    const [productCount, setProductCount] = useState(0)
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if(!filters.length){
            onSetPage(0)
        }
    },[filters])

    const RenderProductList = useCallback(() => {
        return <Grid container
            spacing={1}
            style={{
                padding: 0, alignItems: 'center', marginTop: 40, margin: '0px auto', width: '100%',
                justifyContent: window.innerWidth > 1200 ? 'flex-start' : 'center'
            }}>

            {products.length > 0 ? products.map((item, index) => {
                return <Grid item lg={3} sm={6} xs={6} key={index} style={{ width: '100%', minHeight: 120 }}>
                    <ProductItem key={index} item={item} {...props} history={history} />
                </Grid>
            }): <></>}
        </Grid>
    }, [products, filters])

    useEffect(() => {
        if (queryParams?.a_category) {
            if (queryParams.pageIndex > 1) {
                
                
            }
            queryParams.order = sort.order
            queryParams.sort = sort.sort === 'created' ? 'uploaded' : sort.sort
            productService.listWithCounter(queryParams).then((r: any) => {
                setProducts(r.current)
                setProductCount(r.count)
            })
        }
    }, [queryParams])

    useEffect(() => {
        if (location?.pathname) {
            setIsVisible(false)
            setTimeout(() => {
                setIsVisible(true)
            }, 100)
        }
    }, [location])

    const RenderSort = useCallback(() => {
        return <Fragment>
            <Box
                ref={listRef}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'space-between',
                    width: '100%'
                }}>
                <Title
                    uppercase={false}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'#333'}
                    fontSize={14}
                    style={{ padding: 10 }}
                    fontWeight={'400'}
                    text={`${productCount} ${translations.items}`} />
                {Object.keys(translations).length && <SortPopover
                    {...props}
                    sort={sort}
                    translations={translations}
                    onSetSort={(s) => {
                        if (s) {
                            setSort(s)
                            localStorage.setItem("sort", JSON.stringify(s))
                            const options = generateParamsFromUrlWithoutPrice(location.search)
                            options.sort = sort.sort
                            options.order = sort.order
                            const url = `${location.pathname}`
                            history.push(options.toQueryStringWithUrl(url))
                            onSetQueryParams(options)
                            queryParams.order = s.order
                            queryParams.sort = s.sort
                            productService.listWithCounter(queryParams).then((r: any) => {
                                setProducts(r.current)
                                setProductCount(r.count)
                                
                            })
                        }
                    }}
                />}
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 20, maxWidth: 1200 }}>
                <Root>
                    <Divider />
                </Root>

            </Box>
        </Fragment>
    }, [sort, products, productCount, listRef, filters])

    const RenderContent = useCallback(() => {
        return <Animated animationIn={"fadeIn"} animationOut={"fadeOut"} isVisible={isVisible} style={{ width: '100%' }}>
            {loading ? <div style={{
                height: 500,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress color='inherit' />
            </div> : products?.length ?
                <Fragment>
                    <RenderSort />
                    <RenderProductList />

                </Fragment>

                : <></>}
            { true ? <div style={{ height: 120, marginTop: 40 }}>
                {Math.ceil(productCount / 24) > 1 && <Pagination
                    count={productCount}
                    pageSize={24}
                    setPageIndex={(index) => {
                        window.scrollTo({
                            behavior: 'smooth',
                            top: listRef?.current?.offsetTop + 220,
                        });
                        onSetPage(index)
                    }}
                    pageIndex={queryParams?.pageIndex ? queryParams?.pageIndex : 1}
                />}
            </div> : <h1>{
                !loading && translations?.no_products_found
            }</h1>}
        </Animated>
    }, [queryParams, products, productCount, listRef, filters])

    return <RenderContent />

}