import React, { Fragment } from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { ShoppingBag } from '../../assets/icons/ShoppingBag';
import { connect } from 'react-redux';
import { setCart, setFavorites, setSnackBar, setConfirmation } from '../../models/application/applicationActions';
import { addToFavorites, getCartTotal, loadCart, loadCartItems, removeCartItem, Transition } from '../../helpers/utilities';
import { cartService } from '../../models/cart/service';
import { MobileProductCheckoutComponent } from '../Product/MobileProductCheckoutComponent';
import { makeStyles } from '@mui/styles';
import { COLORS } from '../../constants/colors';
import { Title } from '../Typography/Title';
import '../styles.scss'
import { BlackButton } from '../Button/BlackButton';
import { useHistory } from 'react-router';
import { CartItem } from '../../models/cart/cart';

const useStyles = makeStyles({
    rootRow: {
        width: '100%',
        padding: 0,
        marginTop: 0,
        height: window.innerHeight,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    }
})

const CartDialog = (props) => {
    const {
        app,
        setCart,
        translations,
        setConfirmation,
        language,
        setFavorites,
        setSnackBar,
        auth } = props
    const [open, setOpen] = React.useState(false);
    const classes = useStyles()
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    const history = useHistory()
    
    const handleClose = () => {
        setOpen(false);
    };

    const onUpdateCart = (item: CartItem) => {
        setCart([...app.cart.filter(ci => ci.sku !== item.sku), item])
    }
    const onRemoveItem = (confirmation) => {

        if (auth.loggedIn) {
            cartService.delete(confirmation.item.sku).then(() => {
                loadCartItems(setCart, language)
                setConfirmation({ ...confirmation, open: false })
                setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted}`, bColor: "rgb(83, 148, 96)" })
            })
        } else {
            removeCartItem(confirmation.item.sku, app, setCart)
            setConfirmation({ ...confirmation, open: false })
            setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted}`, bColor: "rgb(83, 148, 96)" })
        }
    }

    const onMoveToFavorites = (confirmation) => {
        addToFavorites(confirmation.item.product, confirmation.item.sku, app, auth).then((r: any) => {
            setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations.has_been_added_to_favs}.`, bColor: "rgb(83, 148, 96)" })
            setFavorites(r)
            setTimeout(()=> {
                if (auth.loggedIn) {
                    cartService.delete(confirmation.item.sku).then(() => {
                        loadCartItems(setCart, language)
                        setConfirmation({ ...confirmation, open: false })
                    })
                } else {
                    removeCartItem(confirmation.item.sku, app, setCart)
                    setConfirmation({ ...confirmation, open: false })
                }
            }, 1200)
        })
    }

    return <Fragment>
        <Box onClick={handleClickOpen} style={{ paddingTop: 3, position: 'relative' }}>
            {app?.cart?.length > 0 && <div style={{ width: 17, height: 17, position: 'absolute', backgroundColor: COLORS.RED, borderRadius: 20, right: 0, top: 0 }}>
                <span style={{ fontSize: 12, top: 1, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>{app?.cart?.length}</span>
            </div>}
            <ShoppingBag width={25} height={35} />
        </Box>
        <Dialog
            maxWidth='md'
            style={{
                padding: 0, minWidth: window.innerWidth > 1200 ? 1200 : '100%',
                overflow: 'hidden',
                minHeight: window.innerHeight
            }}
            sx={{
                '&.MuiDialog-paperScrollPaper': {
                    maxHeight: 1200
                }
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{ position: 'absolute', right: window.innerWidth * .03, top: 10, cursor: 'pointer' }} onClick={handleClose}>
                <CloseIcon />
            </div>
            <Box style={{ padding: 10, backgroundColor: COLORS.BODY_BG, display: 'flex', flexDirection: 'row', height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <ShoppingBag width={25} height={35} />
                <Title
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    text={translations?.my_cart}
                    fontSize={17}
                    style={{ backgroundColor: COLORS.BODY_BG, marginLeft: 10 }}
                    uppercase={true}
                    className="roboto"
                    fontWeight={500} />
            </Box>
            <Box className={classes.rootRow} style={{ backgroundColor: COLORS.BODY_BG,overflowY: 'scroll' }}>
                {!app?.cart?.length && <Box style={{
                    width: window.innerWidth, paddingTop: 40,
                    justifyContent: 'center', alignItems: 'center', display: 'flex'
                }}>
                    {translations?.no_items_in_cart}
                </Box>}

                {app?.cart?.sort((n1, n2) => { if (n1.created > n2.created) { return -1; } if (n1.created < n2.created) { return 1; } return 0; }).map((item, index) => {
                    return <Box
                        key={index}
                        sx={{
                            paddingTop: 1,
                            width: '100%',
                            borderRadius: 2,
                            marginTop: 2,
                        }}>
                        <MobileProductCheckoutComponent
                            onUpdateCart={onUpdateCart}
                            item={item}
                            onMessage={(qty) => {
                                setSnackBar({ ...app.snackBar, open: true, text: `${translations.only_available} ${qty} ${translations.items}`, bColor: "rgb(83, 148, 96)" })
                            }}
                            translations={translations}
                            redirect={() => { }}
                            onRemove={(item) => {
                                setConfirmation({
                                    open: true,
                                    item,
                                    type: 'removeItemFromCart',
                                    textAccept: `${translations?.delete}`,
                                    text: `${translations?.do_you_really_want_to_delete_from_cart}`,
                                    title: item.product.name,
                                    onAgreed: onRemoveItem
                                })
                            }}
                            onMoveToFavorites={(item) => {
                                setConfirmation({
                                    open: true,
                                    item,
                                    type: 'removeItemFromCart',
                                    textAccept: `${translations?.move}`,
                                    text: `${translations?.do_you_want_to_move_to_favorites}`,
                                    title: item.product.name,
                                    onAgreed: onMoveToFavorites
                                })
                            }}
                            key={index} />

                    </Box>
                })}

            </Box>
            <Box style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex', padding: 20 }}>
                <BlackButton
                    title={
                        getCartTotal(app.cart) ? 
                        translations.checkout: translations?.close}
                    onClick={() => {
                        handleClose()
                        if(getCartTotal(app.cart)) {
                            history.push("/checkout")
                        }
                    }}
                />
            </Box>
        </Dialog>
    </Fragment>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, {
    setCart,
    setSnackBar,
    setConfirmation,
    setFavorites
})(CartDialog)
