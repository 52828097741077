import React from 'react'
import { connect } from 'react-redux'
import { MobileAccountComponent } from '../../components/account/MobileAccountComponent'

const MobileAccountPage = (props) => <MobileAccountComponent {...props}/>

const mapStateToProps = (state: any) => {
    return {
        app: state.app,
        translations: state.translations,
        language: state.language,
    }
}

export default connect(mapStateToProps, {})(MobileAccountPage)