import React, { useEffect } from 'react';
import { Route, Redirect } from "react-router-dom";
import LoginPage from './pages/LoginPage'
import MainPage from "./pages/MainPage";

const GuardedRoute = (props) => {
    const { component: Component, auth, access, ...rest } = props
    return  <Route {...rest} render={(props) => (
        auth === true
            ? access? <Component {...props} />: <MainPage {...props} />
            : <LoginPage {...props}/> 
    )} />
}

export default GuardedRoute;