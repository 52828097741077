import React, { useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ProductItem } from '../Product/ProductItem';
import { Title } from '../Typography/Title';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "../Carousel/carousel.css"
import { Skeleton } from "@mui/material";

export const MainPageProductSliderComponent = (props: any) => {
    const { slider, language, width = 1200, imageHeight = "70%", itemHeight = 280 } = props
    const [loadStatus, setLoadStatus] = useState(true)
    useEffect(() => {
        if (slider) {
            setLoadStatus(false)
        }

    }, [slider])


    return slider ? <div style={{
        position: 'relative',
        marginTop: 20,
        paddingTop: 20,
        height: 250,
        width,
        textAlign: 'center',
        border: '1px solid white',
        borderLeftColor: 'white',
        borderRightColor: 'white'
    }}>
        {slider?.title?.length > 0 && <div style={{ position: 'relative', right: 0, zIndex: 100, top: 0, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <Title text={` ${slider.title}`} fontSize={25} uppercase={true} fontWeight='500' fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'} color={"#222"} />
        </div>}

        {slider?.products?.length && <Swiper
            slidesPerView={window.innerWidth > 300 ? window.innerWidth > 550 ? window.innerWidth > 700 ? window.innerWidth > 1200 ? 5 : 4 : 3 : 2 : 1}
            spaceBetween={30}
            loop={window.innerWidth < 1200 ? true : slider?.products?.length >= 10 ? true : false}
            navigation={window.innerWidth > 1200 ? true : false}
            modules={[Navigation]}
            className="mySwiper"
        >
            {slider?.products?.map((item, index) => {
                return <SwiperSlide key={index}>
                    <ProductItem  item={item} {...props}
                    itemHeight={itemHeight}
                    imageHeight={imageHeight} />
                </SwiperSlide>
            })}
        </Swiper>}
    </div> : loadStatus ? <div style={{
        position: 'relative',
        height: 250,
        width,
        textAlign: 'center',
        border: '1px solid white',
        borderLeftColor: 'white',
        borderRightColor: 'white'
    }}>
        {slider?.title?.length > 0 && <div style={{ position: 'relative', right: 0, zIndex: 100, top: 0, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <Title text={` ${slider.title}`} fontSize={25} uppercase={true} fontWeight='500' fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'} color={"#222"} />
        </div>}
        <Skeleton
            width={1200}
            height={400}
        ></Skeleton>
    </div> : <></>
}