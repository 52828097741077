import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { COLORS } from "../../constants/colors";
import '../styles.scss'
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const onHover$: any = new Subject().pipe(
    debounceTime(500)
)

export const RenderMenu = (props) => {

    const { index, item, activeMenuItem, setHoverItemId, hoverItemId, letters, brands, language } = props
    const [on, setOn] = useState(false)
    const [currentItem, setCurrentItem] = useState<any>()
    const [items, setItems] = useState([])
    const [selectedLetter, setSelectedLetter] = useState("A")
    const history = useHistory();

    useEffect(() => {
        if (items?.length && on) {
            const [item] = items.reverse()
            setHoverItemId(item)
        }
    }, [items, on])

    return <li

        
        key={index}
        style={{
            backgroundColor: COLORS.BG_DARK,
            fontWeight: '400',
            fontSize: 14,
            fontFamily: 'FiraGO', color: 'white'
        }}
        className={item.id === activeMenuItem?.id ? 'li-menu-item-active' : 'li-menu-item'}>
        <span 
        onMouseEnter={e => {
            setOn(true)
            setItems([])
            setTimeout(() => {
                setItems([...items, item.id])
            }, 300)
        }}
        onMouseOut={e => {
            setOn(false)
            setItems([])
        }}
        onClick={() => {
            history.push(item.url)
        }}>
            {item.title}
        </span>
        <div style={{
            left: 0,
            marginTop: 10,
            width: window.innerWidth,
            height: 400,
            flexDirection: 'row',
            backgroundColor: 'white',
            position: 'absolute',
            justifyContent: 'space-between',
            display: hoverItemId === item.id ? 'block' : 'none'
        }}>
            <div style={{ maxWidth: 1200, margin: '0px auto', display: 'flex', flexDirection: 'row', position: 'relative' }}>
                <div style={{
                    flexDirection: 'column', width: 900,
                }}>
                    {item.id !== 'brandsno' && <div style={{
                        maxWidth: 1200,
                        minWidth: '100%',
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        margin: '0px auto', textAlign: 'left', height: 400
                    }}>
                        {item.children.sort((n1, n2) => { if (n1.sort > n2.sort) { return 1; } if (n1.sort < n2.sort) { return -1; } return 0; })?.map((child, index) => {
                            return <RenderMenuItems child={child} key={index} />
                        })}
                    </div>}
                </div>
                {item.image && <div style={{ flexDirection: 'column', width: 300, padding: 20, position: 'absolute', right: 0, top: 0 }}>

                    <img
                        onClick={() => {
                            history.push(item.imageLink)
                        }}
                        style={{ width: '100%' }}
                        src={item.image}></img>
                </div>}
                {item.image}
            </div>
        </div>
    </li>
}

export const RenderMenuItems = (props) => {
    const history = useHistory();
    const { index, child } = props
    return <div
        key={index}
        style={{ flexDirection: 'row', padding: 10, width: 200 }} className="column">
        <b style={{ color: 'black' }} onClick={() => {
            history.push(child.url)
        }}>{child.title}</b>
        {child.children?.map((gChild, index) => {
            return <div style={{ paddingTop: 5 }} key={index}>
                <span
                    style={{ color: 'black' }}
                    className="gChild"
                    onClick={() => {
                        history.push(`${gChild.url}`)
                    }}>{gChild.title}</span>
            </div>
        })}
    </div>
}