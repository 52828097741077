export const COLORS = {
    BLACK: '#333',
    BG_BEIGE: 'rgb(220, 211, 201)',
    BG_DARK: 'rgb(51,51,51)',
    BG_GRAY: 'rgb(230,230,230)',
    BG_GRAY_I: 'rgb(146,145,143)',
    BG_GRAY_PURPLE: 'rgb(165,153,152)',
    DARK_PURPLE: 'rgb(79,69,80)',
    RED: 'rgb(234,51,35)',
    BEIGE_I: 'rgb(219, 211, 201)',
    BEIGE: 'rgb(236,232,228)',
    BG_BEIGE_GRAY: 'rgb(204,200,196)',
    BG_BEIGE_LIGHT: 'rgb(246,243,240)',
    BLUE_LINK: 'rgb(83, 151, 177)',
    BODY_BG: 'rgb(242,242,242)',
    GREEN: 'rgb(83,148, 96)'
}