import { AddressSerializer, branchAddressSerializer } from "../address/serializer";
import { Serializer } from "../serializer";
import { UserSerializer } from "../user/serializer";
import { Branch } from "./branch";

export class BranchSerializer implements Serializer {
  fromJson(json: any) {
    const branch = new Branch();

    branch.id = json.id;
    branch.uid = json.uid;
    branch.openTime = json.open_time;
    branch.closeTime = json.close_time;
    branch.workingHours = json.working_hours;
    branch.phone = json.phone;
    branch.address = branchAddressSerializer.fromJson(json.address);
 
    return branch;
  }

  toJson(item: any) {
    const obj: any = {};
    return obj;
  }
}

export const branchSerializer = new BranchSerializer()
