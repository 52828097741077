import React, {useEffect} from 'react';
import { Box, Checkbox } from '@mui/material';
import { isEmpty } from 'lodash';
import { Title } from '../Typography/Title';

export const FilterItem = (props) => {
    const { item, filter, language, onClick, key, items, translations } = props

    const itemLength = () => {
        return !isEmpty(filter) && filter[item.id] ? filter[item.id].length : 0
    }

    const filteItems = (filter, item) => {
        if (item?.id && !isEmpty(filter) && filter[item.id])
        return filter[item.id]
    }

    return <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: 1 }} >
        <Title text={item.value ? item.value : translations[item.id]} fontSize={15} uppercase={true} fontWeight='700' color={"black"} />
        <Box
            sx={{
                paddingTop: 1,
                width: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                maxHeight: 200, 
                overflowY: itemLength() > 8 ? 'scroll': null, 
                alignItems: 'flex-start'
            }}>
            {filteItems(filter, item)?.map((fItem, index) => {
                return <Box
                    key={index}
                    onClick={() => {
                        onClick(fItem)
                    }}
                    sx={{
                        cursor: 'pointer',
                        paddingLeft: 1, flex: 1, display: 'flex',
                        flexDirection: 'row', justifyContent: 'space-between', alignItems: 'space-between', width: '90%'
                    }}>
                    <Checkbox style={{ padding: 0, color: '#444', top: -3 }} checked={items.map(e => String(e.id)).indexOf(fItem.id) > -1} />
                    <Box sx={{
                        width: '100%',
                        height: 20,
                        flexWrap: 'wrap',
                        overflow: 'hidden',
                        paddingLeft: 1,
                        alignSelf: 'flex-start',
                        alignItems: 'center',
                        textAlign: 'left',
                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                    }}>
                        {/* <Title text={`${fItem.value}`} fontSize={15} uppercase={true} fontWeight='400' color={"black"} /> */}
                        <div style={{
                            display: 'flex',
                            fontWeight: '400',
                            flexDirection: 'column',
                            fontSize: 14,
                            fontFamily: language === 'geo' ? 'FiraGo' : "Roboto",
                            color: 'black',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '95%',
                            alignSelf: 'flex-start'
                        }} >
                            {fItem.value}
                        </div>
                    </Box>
                    <Title text={`${fItem.count}`} fontSize={15} uppercase={true} fontWeight='400' color={"black"} />
                </Box>
            })}
        </Box>
    </Box >
}