import React from "react"
import { connect } from "react-redux"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { ProductDetailsComponent } from "../../components/Product/ProductDetailsComponent"
import { Box } from "@mui/material"
import { ProductPromoPageSectionsComponent } from "../../components/Product/ProductPromoPageSectionsComponent"

const ProductPromoPage = (props) => {
    const { auth, translations, app, language } = props
    const { width } = useWindowDimensions()

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: 1200,
                margin: "0 auto",
                "@media(max-width:1024px)": { width: width, maxWidth: width },
            }}
        >
            <ProductPromoPageSectionsComponent {...props} />
        </Box>
    )
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        translations: state.translations,
        language: state.language,
        app: state.app,
    }
}

export default connect(mapStateToProps, {})(ProductPromoPage)
