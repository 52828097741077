import { environment } from "../../environment/environment"
import { brandSerializer, BrandSerializer } from "../brand/serialize"
import { productSerializer, ProductSerializer } from "../product/serializer"
import { productLineSerializer } from "../product_line/serializer"
import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { MainPageNewProductSection, MainPageProductLineSection, MainPageProductSlider, MainPageSlide, MainPageProductSectionWithBG, EmbeddedSliderItem, TextPosition, MainPageProductSliderWithBanner, MainPageProductBanner } from "./MainPage"




export class MainPageSlideSerializer implements Serializer {
    fromJson(json: any) {
        const item = new MainPageSlide()
        item.url = json.url
        if (json.filename) {
            item.image = `${environment.imagePath}/assets/main_page_slider/${json.filename}.jpg`
        }
        if (json.filename_mobile) {
            item.imageMobile = `${environment.imagePath}/assets/main_page_slider/${json.filename_mobile}.jpg`
        }
        item.title = json.title
        item.subtitle = json.subtitle
        if (json.embedded_items) {
            item.embeddedItems = json.embedded_items.map(i => embeddedSliderItemSerializer.fromJson(i))
        }
        if (json.text_position) {
            item.textPosition = textPositionSerializer.fromJson(json.text_position)
        }
        item.sort = json.sort
        item.type = json.type
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
export class MainPageProductSliderWithBannerSerializer implements Serializer {
    fromJson(json: any) {
        const item = new MainPageProductSliderWithBanner()
        item.headerTitle = json.header_title
        item.buttonText = json.button_text
        item.url = json.url
        item.image = `${environment.imagePath}/assets/main_page_banners/${json.filename}.jpg`
        item.productsLower = json.products_lower.map(r => productSerializer.fromJson(r))
        item.productsUpper = json.products_upper.map(r => productSerializer.fromJson(r))
        item.text = json.text
        item.title = json.title
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
export class MainPageProductSectionWithBGSerializer implements Serializer {
    fromJson(json: any) {
        const item = new MainPageProductSectionWithBG()
        item.url = json.url
        item.image = `${environment.imagePath}/assets/main_page_product_section_with_bg/${json.filenname}.jpg`
        item.title = json.title
        item.text = json.text
        item.buttonText = json.button_text
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
export class MainPageProductLineSectionSerializer implements Serializer {
    fromJson(json: any) {
        const item = new MainPageProductLineSection()
        item.url = json.url
        if (json.product_lines) {
            item.productLines = json.product_lines.map(item => productLineSerializer.fromJson(item))
        }
        item.buttonText = json.button_text
        item.image = `${environment.imagePath}/assets/main_page_product_line/${json.filenname}.jpg`
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
export class MainPageNewProductSectionSerializer implements Serializer {
    fromJson(json: any) {
        const item = new MainPageNewProductSection()
        if (json.brand) {
            item.brand = brandSerializer.fromJson(json.brand)
        }
        item.buttonText = json.button_text
        item.text = json.text
        item.type = json.type
        item.videoLink = json.video_link
        item.url = json.url
        item.image = `${environment.imagePath}/assets/main_page_new_product_slide/${json.filename}.jpg`
        item.title = json.title
        return item
    }
    toJson(item: MainPageNewProductSection) {
        return {
        }
    }
}
export class MainPageProductSliderSerializer implements Serializer {
    fromJson(json: any) {
        const item = new MainPageProductSlider()
        
        if (json.products) {
            item.products = json.products.map(product => productSerializer.fromJson(product))
        }
        item.title = json.title
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
export class MainPageProductBannerSerializer implements Serializer {
    fromJson(json: any) {
        const item = new MainPageProductBanner()
        item.product = productSerializer.fromJson(json.product)
        item.image = `${environment.imagePath}/assets/main_page_banners/${json.filename}.jpg`
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}


export class EmbeddedSliderItemSerializer implements Serializer {
    fromJson(json: any) {
        const item = new EmbeddedSliderItem()
        item.title = json.title
        item.image = `${environment.imagePath}/assets/main_page_slider/${json.filename}.jpg`
        item.sort = json.sort
        item.url = json.url
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
export class TextPositionSerializer implements Serializer {
    fromJson(json: any) {
        const item = new TextPosition()
        item.subtitlePositionX = json.subtitle_position_x
        item.subtitlePositionY = json.subtitle_position_y
        item.subtitleSize = json.subtitle_size
        item.titlePositionX = json.title_position_x
        item.titlePositionY = json.title_position_y
        item.titleSize = json.title_size
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}


export const embeddedSliderItemSerializer = new EmbeddedSliderItemSerializer()
export const textPositionSerializer = new TextPositionSerializer()