import { Box, Button, DialogTitle, TextField, Typography } from "@mui/material"
import React, { useState } from "react"
import * as Yup from "yup"
import { Form, Formik } from "formik"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { environment } from "../../environment/environment"
import { COLORS } from "../../constants/colors"

export const GiftCardSummaryDetailsEditMode = (props) => {
    const { translations, language, setMode, details } = props

    const [options, setOptions] = useState<any[]>(environment.giftCardOptions)
    const [selectedOption, setSelectedOption] = useState()
    const [submitted, setSubmitted] = useState(false)

    const validation = Yup.object().shape({
        friendName: Yup.string().required(translations?.friend_name_is_required),
        yourName: Yup.string().required(translations?.your_name_is_required),
        amount: Yup.string().required(translations?.amount_is_required),
    })

    const getValues = (values) => {
        let first = { friendName: values.friendName, yourName: values.yourName }

        localStorage.setItem("gift-card-checkout-first-step", JSON.stringify(first))
        let third = { amount: values.amount }

        localStorage.setItem("gift-card-checkout-third-step", JSON.stringify(third))
        let fourth = { message: values.message }
        localStorage.setItem("gift-card-checkout-fourth-step", JSON.stringify(fourth))

        setMode("view")
    }

    const showError = (errors, field) => {
        return errors[field] && submitted
    }
    const { width } = useWindowDimensions()
    return (
        <Box
            sx={{
                width: width > 600 ? "500px" : "80%",
                margin: "0 auto",
                border: "1.5px solid gray",
                borderRadius: 2,
                padding: 5,
                marginBottom: 7,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                "@media (max-width:600px)": {
                    padding: 3,
                    paddingTop: 5,
                    paddingBottom: 5,
                },
            }}
        >
            <Formik
                validationSchema={validation}
                initialValues={{
                    friendName: details?.friendName,
                    yourName: details?.yourName,
                    amount: details?.amount,
                    message: details?.message,
                }}
                onSubmit={(values) => {
                    getValues(values)
                }}
            >
                {({ values, handleSubmit, setFieldValue, handleChange, handleBlur, errors }: any) => (
                    <Form
                        onSubmit={(values) => {
                            handleSubmit(values)
                        }}
                    >
                        <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"}>
                            {translations?.who_is_the_gift_card_for}
                        </DialogTitle>
                        <TextField
                            label={translations?.friends_name}
                            fullWidth
                            margin="normal"
                            value={values.friendName}
                            type="text"
                            name="friendName"
                            onChange={(event) => {
                                setSubmitted(false)
                                handleChange(event)
                            }}
                            onBlur={handleBlur}
                            sx={{ width: "95%" }}
                        />
                        {showError(errors, "friendName") && (
                            <Typography sx={{ color: "red", textAlign: "left", marginLeft: 2 }}>
                                {errors.friendName}
                            </Typography>
                        )}
                        <TextField
                            label={translations?.your_name}
                            fullWidth
                            margin="normal"
                            value={values.yourName}
                            type="text"
                            name="yourName"
                            onChange={(event) => {
                                setSubmitted(false)
                                handleChange(event)
                            }}
                            onBlur={handleBlur}
                            sx={{ width: "95%" }}
                        />
                        {showError(errors, "yourName") && (
                            <Typography sx={{ color: "red", textAlign: "left", marginLeft: 2 }}>
                                {errors.yourName}
                            </Typography>
                        )}
                        <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"}>
                            {translations?.how_much_is_it_for}
                        </DialogTitle>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: 2,
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                            }}
                        >
                            {options.map((option, index) => {
                                return (
                                    <Box
                                        key={index}
                                        onClick={() => {
                                            setSubmitted(false)
                                            setFieldValue("amount", Number(option))
                                            setSelectedOption(option)
                                        }}
                                        sx={{
                                            display: "flex",
                                            borderRadius: 1,
                                            border: "1px solid #aaa",
                                            width: 100,
                                            cursor: "pointer",
                                            height: 40,
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            margin: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: selectedOption === option ? COLORS.BEIGE_I : "transparent",
                                        }}
                                    >
                                        {option} ₾
                                    </Box>
                                )
                            })}
                        </Box>
                        <TextField
                            sx={{
                                marginTop: 3,
                                marginBottom: 3,
                                width: "95%",
                                "@media (max-width:600px)": { width: "75%" },
                            }}
                            id="outlined-basic"
                            label={translations["insert_quantity"]}
                            color="primary"
                            variant="outlined"
                            type="number"
                            name="amount"
                            value={values.amount}
                            onChange={(event) => {
                                setSubmitted(false)
                                handleChange(event)
                            }}
                        />
                        {showError(errors, "amount") && (
                            <Typography
                                sx={{
                                    color: "red",
                                    textAlign: "left",
                                    marginLeft: 2,
                                    marginBottom: 2,
                                    "@media (max-width:600px)": { marginLeft: 5, marginBottom: 3 },
                                }}
                            >
                                {errors.amount}
                            </Typography>
                        )}
                        <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"}>
                            {translations?.add_a_personalised_message}
                        </DialogTitle>
                        <TextField
                            id="outlined-multiline-flexible-textfieldd"
                            label={translations?.your_message_optional}
                            multiline
                            rows={4}
                            name="message"
                            value={values.message}
                            onChange={(event) => {
                                setSubmitted(false)
                                handleChange(event)
                            }}
                            sx={{
                                marginTop: 3,
                                marginBottom: 3,
                                width: "95%",
                                margin: "0 auto",
                                "@media (max-width:600px)": { width: "100%" },
                            }}
                        />
                        {showError(errors, "message") && (
                            <Typography
                                sx={{
                                    color: "red",
                                    textAlign: "left",
                                    marginLeft: 2,
                                    marginBottom: 2,
                                    "@media (max-width:600px)": { marginLeft: 5, marginBottom: 3 },
                                }}
                            >
                                {errors.message}
                            </Typography>
                        )}

                        <Box
                            sx={{
                                display: "flex",
                                "@media (max-width:600px)": {
                                    flexDirection: "column",
                                },
                            }}
                        >
                            <Button
                                onClick={() => setMode("view")}
                                sx={{
                                    width: "45%",
                                    margin: "0 auto",
                                    marginTop: 5,
                                    backgroundColor: COLORS.BEIGE_I,
                                    color: "black",
                                    height: 50,
                                    maxHeight: 50,
                                    fontFamily: props.language === "geo" ? "FiraGo" : "Roboto",
                                    textTransform: "none",
                                    "@media (max-width:600px)": {
                                        width: "100%",
                                    },
                                    "&:hover": {
                                        backgroundColor: COLORS.BEIGE_I,
                                    },
                                }}
                            >
                                {translations?.cancel}
                            </Button>
                            <Button
                                type="submit"
                                onClick={() => setSubmitted(true)}
                                sx={{
                                    width: "45%",
                                    margin: "0 auto",
                                    marginTop: 5,
                                    backgroundColor: "black",
                                    color: "white",
                                    height: 50,
                                    maxHeight: 50,
                                    fontFamily: props.language === "geo" ? "FiraGo" : "Roboto",
                                    textTransform: "none",
                                    "@media (max-width:600px)": {
                                        width: "100%",
                                        marginTop: 2,
                                    },
                                    "&:hover": {
                                        backgroundColor: "black",
                                    },
                                }}
                            >
                                {translations?.save_changes}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}
