import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from 'react-router';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';




export const GenericCarousel = (props: any) => {
    const { items, size="desktop", length } = props
    const history = useHistory()
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false })
    let carousel: any

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: length > 2? 2.5: 2
        }
    }

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest
        return <button />

    }

    return <div style={{ position: 'relative', width: '100%'}}>
        <div style={{ position: 'absolute', right: -30, zIndex: 100, top: '40%', cursor: 'pointer' }} onClick={() => {
            carousel.next()
        }}>
            <ChevronRight width={30} height={30} />

        </div>
        <div style={{ position: 'absolute', left: -30, zIndex: 100, top: '40%', cursor: 'pointer' }} onClick={() => {
            carousel.previous()
        }}>
            <ChevronLeft width={30} height={30} />

        </div>
        <Carousel
            ref={(el) => (carousel = el)}
            customRightArrow={<CustomRightArrow onClick={() => {
                carousel.next()
            }} />}
            customLeftArrow={<CustomRightArrow onClick={() => {
                carousel.next()
            }} />}
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={100000000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}

        >
            {items}
        </Carousel>

    </div>

}
