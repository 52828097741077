
import React from "react";
import '../../styles.scss'
import { Box, Button } from "@mui/material";
import '../../../App.css'
import { COLORS } from "../../../constants/colors";
import { Title } from "../../Typography/Title";

export const CustomButton = (props) => {
    const { onClick=()=>{}, translations,language, text} = props

    return <Button
        sx={{
            color: 'white',
            marginRight: 1,
            backgroundColor: COLORS.BG_DARK,
            '&:hover': {
                color: 'white',
                backgroundColor: COLORS.BG_DARK,
            },
            width: '100%',
            height: 40,
            borderRadius: 10,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center'
        }}
        onClick={onClick}
        disableRipple={true}
        disableFocusRipple={false}
        color={"secondary"}
    >
            <Box sx={{ display: 'flex', 
            
            justifyContent: 'center', alignItems: 'center', width: '100%', borderLeftColor: 'white' }}
                
            >
                <Title
                    uppercase={true}
                    fontWeight={'500'}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'white'}
                    fontSize={13}
                    text={text} />

            </Box>
        </Button>
}