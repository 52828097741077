import { Box, CircularProgress, Grid } from "@mui/material"
import React, { Fragment, useCallback, useEffect, useState, } from "react"
import { Product } from "../../models/product/product"
import { productService } from "../../models/product/service"
import { ProductItem } from "./ProductItem"
import { Animated } from "react-animated-css";
import { QueryOptions } from "../../helpers/query.options"
import { isBottom } from "../../helpers/utilities"

export const MobileProductGrid = (props) => {
    const { queryParams, history, location, app } = props
    const [products, setProducts] = useState<Product[]>([])
    const [loading, setLoading] = useState(false)
    const [loadedIndexes, setLoadedIndexes] = useState([])
    const [lockLoaded, setLockLoaded] = useState(false)
    const [queryOptions, setQueryOptions] = useState<QueryOptions>(new QueryOptions)
    const [productCount, setProductCount] = useState(0)
    const [isVisible, setIsVisible] = useState(false)
    const [pageIndex, setPageIndex] = useState(1)


    useEffect(() => {
        setPageIndex(1)
    }, [queryParams])

    useEffect(() => {
        const handleScroll = () => {
            const scrollHeight = document.documentElement.scrollHeight;
            const scrollTop = document.documentElement.scrollTop;
            const clientHeight = document.documentElement.clientHeight;
            const scrollPercent = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercent >= 0.8) {
                if (!lockLoaded) {
                    loadProducts(queryOptions);
                } else {
                    console.log('no response')
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [products, lockLoaded]);




    const RenderProductList = useCallback(() => {
        return <Box
            id="main"
            style={{ padding: 0, alignItems: 'center', marginTop: 40, flexWrap: 'wrap', display: 'flex', flexGrow: 1, width: window.innerWidth }}>
            {products.map((item, index) => {
                return <Box key={index} style={{
                    maxWidth: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    width: `${1 / (app?.dimensions?.width / 180) * 100}%`
                }}>
                    <ProductItem key={index} item={item} {...props} history={history} />
                </Box>
            })}
        </Box>
    }, [products, productCount, app?.dimensions])

    useEffect(() => {
        if (queryParams) {

            setQueryOptions(queryParams)
        }
    }, [queryParams])



    useEffect(() => {
        if (queryOptions?.a_category) {
            setProducts([])
            loadProducts(queryOptions)
        }
    }, [queryOptions])

    const loadProducts = async (queryOptions) => {
        setLockLoaded(true)
        setLoading(true)
        queryOptions.pageIndex = pageIndex
        const prods: any = await productService.listWithCounter(queryOptions)
        if (prods.current) {
            setPageIndex(pageIndex => {
                return pageIndex + 1
            })
            setTimeout(() => {
                setLockLoaded(false)
            }, 1000)
            setLoading(false)
            setProducts(products => [...products, ...prods.current])
            setProductCount(props.count)
        }
    }

    useEffect(() => {
        if (location?.pathname) {
            setIsVisible(false)
            setTimeout(() => {
                setIsVisible(true)
            }, 100)
        }
    }, [location])

    const RenderContent = useCallback(() => {
        return <Fragment>
            {false ? <div style={{
                height: 500,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress color='inherit' />
            </div> :
                <Box
                    id="main"
                    style={{ padding: 0, alignItems: 'center', marginTop: 40, paddingBottom: 20, flexWrap: 'wrap', display: 'flex', flexGrow: 1, width: window.innerWidth - 40, marginBottom: 50 }}>
                    {products.map((item, index) => {
                        return <Box key={index} style={{
                            maxWidth: '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            width: `${1 / (app?.dimensions?.width / 180) * 100}%`
                        }}>
                            <ProductItem key={index} item={item} {...props} history={history} />
                        </Box>
                    })}
                </Box>}
        </Fragment>
    }, [queryParams, products, productCount])

    return <RenderContent />

}