import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { PriceRange } from "./PriceRange"


export class PriceRangeSerializer implements Serializer {
    fromJson(json: any) {
        const item = new PriceRange()
        item.minPrice = json[0]
        item.maxPrice = json[1]
        item.minGlobal = json[2]
        item.maxGlobal = json[3]
        return item
    }
    toJson(item) {
        return {
        }
    }
}
