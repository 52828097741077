import React from "react";
import { Typography } from "@mui/material"
import '../../App.css'

export const Title = (props) => {
    const {
        text,
        fontSize,
        uppercase = false,
        lineHeight = 1.2,
        fontWeight = '400',
        textAlign = 'left',
        color = "black",
        fontFamily = 'FiraGo',
        className,
        style = {},
        onClick,
        underline = false
    } = props
    return <Typography
    
        className={uppercase ? "uppercase" : className}
        variant="subtitle1"
        component="span"
        style={{
            textDecoration: "none",
            ...style,
            justifyContent: "center",
            lineHeight,
            display: 'flex',
            textAlign,
            fontWeight,
            flexDirection: 'column',
            fontSize,
            fontFamily,
            color
        }}
        onClick={() => {
            if (onClick) {
                onClick(text)
            }
        }}
        sx={{ textDecorationLine: underline ? 'underline' : '' }}>
        {text}
    </Typography>
}