import { Box, Button, DialogTitle, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { environment } from "../../environment/environment"
import { COLORS } from "../../constants/colors"
import * as Yup from "yup"
import { Form, Formik } from "formik"

export const GiftCardCheckoutThirdStep = (props) => {
    const { auth, translations, language, app, setStep } = props

    const { width } = useWindowDimensions()
    const [options, setOptions] = useState<any[]>(environment.giftCardOptions)
    const [selectedOption, setSelectedOption] = useState()
    const [submitted, setSubmitted] = useState(false)


    const validation = Yup.object().shape({
        amount: Yup.string().required(translations?.amount_is_required),
    })

    const getValues = (values) => {
        localStorage.setItem("gift-card-checkout-third-step", JSON.stringify(values))
        setStep(c => c + 1)
    }

    const showError = (errors, field) => {
        return errors[field] && submitted
    }

    return (
        <Box
            sx={{
                width: width > 600 ? "500px" : "80%",
                margin: "0 auto",
                border: "1.5px solid gray",
                borderRadius: 2,
                padding: 5,
                position: "relative",
                "@media (max-width:600px)": { padding: 2, paddingBottom: 5, marginBottom: 7, paddingTop: 5 },
            }}
        >
            <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"}>
                {translations?.how_much_is_it_for}
            </DialogTitle>
            <Formik
                validationSchema={validation}
                initialValues={{ amount: "" }}
                onSubmit={(values) => {
                    getValues(values)
                }}
            >
                {({ values, handleSubmit, setFieldValue, handleChange, handleBlur, errors }: any) => (
                    <Form
                        onSubmit={(values) => {
                            handleSubmit(values)
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: 2,
                                flexWrap: "wrap",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                            }}
                        >
                            {options.map((option, index) => {
                                return (
                                    <Box
                                        key={index}
                                        onClick={() => {
                                            setSubmitted(false)
                                            setFieldValue("amount", Number(option))
                                            setSelectedOption(option)
                                        }}
                                        sx={{
                                            display: "flex",
                                            borderRadius: 1,
                                            border: "1px solid #aaa",
                                            width: 100,
                                            cursor: "pointer",
                                            height: 40,
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            margin: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: selectedOption === option ? COLORS.BEIGE_I : "transparent",
                                        }}
                                    >
                                        {option} ₾
                                    </Box>
                                )
                            })}
                        </Box>
                        <TextField
                            sx={{
                                marginTop: 3,
                                marginBottom: 3,
                                width: "94%",
                                "@media (max-width:600px)": { width: "75%" },
                            }}
                            id="outlined-basic"
                            label={translations["insert_quantity"]}
                            color="primary"
                            variant="outlined"
                            type="number"
                            name="amount"
                            value={values.amount}
                            onChange={(event) => {
                                setSubmitted(false)
                                handleChange(event)
                            }}
                        />
                        {showError(errors, "amount") && (
                            <Typography
                                sx={{
                                    color: "red",
                                    textAlign: "left",
                                    marginLeft: 2,
                                    marginBottom: 2,
                                    "@media (max-width:600px)": { marginLeft: 5, marginBottom: 3 },
                                }}
                            >
                                {errors.amount}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            onClick={() => setSubmitted(true)}
                            sx={{
                                backgroundColor: "black",
                                color: "white",
                                padding: "10px 50px 10px 50px",
                                fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                                textTransform: "none",
                                "@media (max-width:600px)": { width: "75%", padding: "8px 20px 8px 20px" },
                                "&:hover": { backgroundColor: "black" },
                            }}
                        >
                            {translations?.add_a_message}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}
