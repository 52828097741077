import React from "react"
import { Box, Link, Typography } from "@mui/material"
import EmailIcon from "@mui/icons-material/Email"
import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
import BusinessIcon from "@mui/icons-material/Business"

export const CompanyAddressComponent = (props) => {
    const { translations } = props

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                border: "1px solid lightgray",
                borderRadius: "10px",
                width: "91%",
                margin: "0 auto",
                marginBottom: 5,
                marginTop: 8,
                paddingTop: 5,
                "@media (max-width:464px)": {
                    marginTop: 3,
                    border: "none",
                    borderBottom: "2px solid lightgray",
                    borderRadius: 0,
                    alignItems: "flex-start",
                },
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    paddingBottom: 3,
                    fontSize: "26px",
                    "@media (max-width:464px)": {
                        paddingLeft: 1,
                    },
                }}
            >
                {translations?.address}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                        padding: 1,
                        marginBottom: 1,
                        "@media (max-width:464px)": {
                            alignItems: "flex-start",
                        },
                    }}
                >
                    <BusinessIcon
                        sx={{
                            color: "black",
                            fontSize: 20,
                        }}
                    />
                    <Typography
                        sx={{
                            letterSpacing: 2,
                            fontSize: 18,
                            textAlign: "left",
                            "@media (max-width:464px)": {
                                fontSize: 16,
                            },
                        }}
                    >
                        {(translations.company_address ??= translations.address)}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                        padding: 1,
                        marginBottom: 1,
                    }}
                >
                    <LocalPhoneIcon
                        sx={{
                            color: "black",
                            fontSize: 20,
                        }}
                    />
                    <Typography>
                        <Link
                            href={`tel:${translations?.company_phone}`}
                            sx={{
                                display: "inline-block",
                                textDecoration: "none",
                                top: 0,
                                fontSize: 18,
                                color: "black",
                                "&:hover": {
                                    textDecoration: "none",
                                },
                                "@media (max-width:464px)": {
                                    fontSize: 16,
                                },
                            }}
                        >
                            {(translations.company_phone ??= translations.phone)}
                        </Link>
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                        padding: 1,
                        marginBottom: 3,
                        "@media (max-width:464px)": {
                            marginBottom: 2,
                        },
                    }}
                >
                    <EmailIcon
                        sx={{
                            color: "black",
                            fontSize: 20,
                        }}
                    />
                    <Typography>
                        <Link
                            href={`mailto:${translations?.company_email}`}
                            sx={{
                                color: "black",
                                textDecoration: "none",
                                letterSpacing: 2,
                                "&:hover": {
                                    textDecoration: "none",
                                },
                                "@media (max-width:464px)": {
                                    fontSize: 16,
                                },
                            }}
                        >
                            {(translations.company_email ??= translations.email)}
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
