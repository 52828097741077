import React, { useCallback, useEffect, useState } from "react"
import { register } from "../../models/user/authActions"
import { connect } from 'react-redux'
import "../../App.css";
import { Grid, Box } from "@mui/material"
import { Brand } from "../../models/brand/brands"
import { Product, SimpleProduct } from "../../models/product/product"
import { useHistory, useParams } from "react-router"
import { productService, similarProductsService } from "../../models/product/service"
import { QueryOptions } from "../../helpers/query.options"
import { makeStyles } from "@mui/styles"
import { setCart, setFavorites, setSnackBar, setGoToCartDialog } from "../../models/application/applicationActions"
import { GalleryDialog } from "../../components/Dialogs/GalleryDialog"
import ProductCarousel from "../../components/Carousel/ProductCarousel"
import { Animated } from "react-animated-css";
import { COLORS } from "../../constants/colors"
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { MainPageProductSlider } from "../../models/MainPageBrandBanner/MainPage"
import { MainPageProductSliderComponent } from "../../components/MainPage/MainPagProductSliderComponent"
import { ReviewComponent } from "../../components/Review/ReviewComponent"
import { ChevronRight } from "../../assets/icons/ChevronRight"
import { Review, ReviewSummary } from "../../models/review/Review"
import { reviewSummaryService } from "../../models/review/service"

import ReviewDialog from "../../components/Dialogs/ReviewDialog"
import { ProductDetailsTab } from "../../components/Tab/ProductDetailsTab"
import { ProductDetailsRightColumn } from "../../components/Product/ProductDetailsRightColumn"
import { ProductVideoComponent } from "../../components/Product/ProductVideoComponent"

import { ProductDetailsLowerRow } from "../../components/Product/ProductDetailsLowerRow"
import { MobileProductDetailsRightColumn } from "../../components/Product/MobileProductDetailsRightColumn"
import { ProductDetailsBanner } from "../../components/Product/ProductDetailsBanner";


const useStyles = makeStyles({
  container: {
    overflow: 'hidden',
    marginTop: 10,
    flexDirection: 'row',
    position: "relative",
    width: '100%',
    flex: 1,
    height: '100%',
    display: 'flex',
    borderRadius: 5,
  },
  column: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  leftColumn: {
    width: '50%',
    backgroundColor: 'blue'
  },
  tag: {
    alignItems: 'center',
    display: 'flex',
    verticalAlign: 'middle',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    position: "absolute",
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: 'bold',
    left: 10,
    top: -10,
    color: 'white',
    borderRadius: 30,
    width: 40,
    height: 40
  },
  info: {
    marginTop: -10,
    flexDirection: 'row'
  },
  price: {
    color: 'red',
    textDecoration: 'line-through'
  },
  specialPrice: {
    marginLeft: 10
  },
  image: {
    marginBottom: -5,
    top: 0,
    zIndex: 100,
    left: 0,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
  },
  leftcolumn: {
    padding: 10
  }
})

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'block',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  border: '0px solid',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(240, 240, 240, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ProductPage = (props): any => {
  const { setCart, app, auth, setFavorites, setSnackBar, language, translations, setGoToCartDialog } = props
  const classes = useStyles();
  let { id }: any = useParams();
  const [currentImage, setCurrentImage] = useState(0)
  const [price, setPrice] = useState(0)
  const [reviewItems, setReviewItems] = useState<Review[]>([])
  const [brand, setBrand] = useState<Brand>()
  const [product, setProduct] = useState<Product>()
  const [item, setItem] = useState<Product>()
  const history = useHistory()
  const [galleryOpen, setGalleryOpen] = useState(false)
  const [init, setInit] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [animate, setAnimate] = useState(true)
  const [simple, setSimple] = useState<SimpleProduct>()
  const [similarProducts, setSimilarProducts] = useState<SimpleProduct[]>([])
  const [image, setImage] = useState<any>()
  const [showReviewDialog, setShowReviewDialog] = useState(false)
  const [expanded, setExpanded] = React.useState<string | false>('');
  const [sliders, setSliders] = useState<MainPageProductSlider[]>([])
  const [isSimilarVisible, setIsSimilarVisible] = useState(false)
  const [reviewSummary, setReviewSummary] = useState<ReviewSummary>()

  useEffect(() => {
    loadProduct(id, translations);
    const subscribe = history.listen((location, action) => {
      const id = location.pathname.split("/")[2]
      loadProduct(id, translations)
    });
    return () => subscribe()
  }, [translations])

  useEffect(() => {
    if (simple) {
      setAnimate(false)
      setTimeout(() => {
        setCurrentImage(0)
      }, 10)
    }
  }, [simple])



  const loadSimilarProducts = (productId, translations) => {
    const options = new QueryOptions()
    options.lang = language
    similarProductsService.readListWithCounter(options, productId).then((resp: any) => {
      if (!resp.status) {
        setIsSimilarVisible(true)

        setSliders([{ id: "none", title: translations?.similar_products, products: resp.current }])
      }
    })
  }



  useEffect(() => {
    if (simple?.id) {
      const [media] = product.media.filter(m => m.filename === `${simple.id}.jpg`)
      const [attr] = simple.attributes.filter(a => a.name.id === getConfigurableId())
      if (media) {
        setCurrentImage(product.media.map(m => m.filename).indexOf(media.filename))
      }
    }
  }, [simple, product?.media])


  useEffect(() => {
    if (product?.id) {
      setPrice(product?.specialPrice)
    }
    if (product?.media?.length) {
      const [item] = product.media
      if (item) {
        const img = new Image()
        img.src = item.thumbnail
        img.onload = () => {
          setLoaded(true)
          setInit(false)
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
        img.onerror = () => {
          img.src = "https://via.placeholder.com/250x250"
        }
      }
    }
  }, [product])



  const loadReviewSummary = (id) => {
    const options = new QueryOptions()
    options.id = id
    reviewSummaryService.read(id, options).then((r: any) => {
      setReviewSummary(r)
    })
  }

  const loadProduct = (id: string, translations) => {
    const options = new QueryOptions()
    options.lang = language
    productService.read(id, options).then((resp: Product) => {
      setProduct(resp)
      console.log(resp)
      document.title = resp.name === "AjaxError" ? "Voulez Vous" : resp.name
      setSimilarProducts([])
      setSliders([])
      if (resp?.id) {
        loadSimilarProducts(resp.id, translations)
        loadReviewSummary(resp?.id)
      }
    })
  }

  const getConfigurableId = () => {
    if (product.configurables?.length) {
      const [config]: any = product.configurables
      return config.name.id
    }
  }


  const RenderReviews = useCallback(() => {
    return <ReviewComponent id={id} {...props} onLoaded={setReviewItems} />
  }, [reviewSummary])

  const filteredImages = (simple) => {
    if (product) {
      if (simple) {
        return [...product.media.filter(m => m.filename.indexOf(simple.sku) > -1 && !m.isCommon), ...product.media.filter(r => r.isCommon)]
      }
    }
    return []
  }

  const RenderImageCarousel = () => {
    return <Box className="disable-text-selection">
      <ZoomInIcon sx={{
        position: 'absolute',
        top: 20,
        right: 30,
        fontSize: 36,
        zIndex: 10,
        cursor: 'pointer'
      }} onClick={() => {
        setGalleryOpen(true)
      }} />
      <ProductCarousel
        onSetCurrentImage={setCurrentImage}
        items={filteredImages(simple)}
        currentImage={currentImage} setLoaded={() => { }}
        doneScrolling={() => {
          setAnimate(true)
        }}
      />
    </Box>
  }


  return loaded ? window.innerWidth > 1200 ? <div className={"container"} style={{
    width: 1200,
    margin: '0px auto',
    position: 'relative',
    minHeight: 700, justifyContent: 'flex-start', paddingTop: 30
  }}>
    <Box sx={{
      flexDirection: 'column',
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      borderRadius: 20,
      position: 'absolute',
      bottom: 30,
      right: -60,
      width: 40,
      height: 40,
      backgroundColor: COLORS.BEIGE_I
    }}>
      <div
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
        style={{
          transform: 'rotate(270deg) translateX(0px) translateY(1px)',
        }}>
        <ChevronRight width={15} height={15} />
      </div>
    </Box>
    <GalleryDialog
      items={filteredImages(simple)}
      product={product}
      onReload={() => { }}
      item={item}
      {...props}
      open={galleryOpen}
      onCloseDialog={(resp) => {
        setGalleryOpen(resp)
      }} />
    <ReviewDialog
      onDone={() => {
        loadReviewSummary(product.id)
      }}
      product={product}
      onReload={() => { }}
      item={item}
      {...props}
      translations={translations}
      open={showReviewDialog}
      onCloseDialog={(resp) => {
        setShowReviewDialog(false)
      }} />
    <Grid container
      spacing={1}
      style={{ flexDirection: "row", alignSelf: 'flex-start', maxWidth: 1200, margin: '0px auto', paddingBottom: 50, justifyContent: 'center' }} >
      <Grid item lg={4} sm={4} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: 300, position: 'relative', padding: 0 }}>
        <RenderImageCarousel />
      </Grid>
      <Grid item lg={7} sm={4} style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 350,
        padding: 10,
        paddingLeft: 50
      }}>
        <ProductDetailsRightColumn
          {...props}
          setSnackBar={setSnackBar}
          item={product}
          simple={simple}
          showLink={false} onSetSimple={setSimple} />
      </Grid>
      <Grid item lg={12} >
        <ProductDetailsLowerRow {...props}
          setShowReviewDialog={setShowReviewDialog}
          reviewSummary={reviewSummary}
          product={product} />
      </Grid>
      <Grid item lg={12} style={{ marginTop: 50, padding: 20, width: '100%' }}>
        <ProductDetailsTab
          translations={translations}
          product={product}
          language={language}
        />
      </Grid>
      {window.innerWidth > 1200 && sliders.map((item, index) => {
        return isSimilarVisible ? <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isSimilarVisible}>
          <div style={{ marginBottom: 50 }}>
            <MainPageProductSliderComponent slider={item} {...props} />
          </div>
        </Animated> : <></>
      })}
      {product.video?.length > 0 ?
        <Box sx={{ width: '70%', height: 450, padding: 7, display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
          <ProductVideoComponent videoLink={product.video} title={product.name} />
        </Box> :
        <Box sx={{ padding: 10 }}>
          <ProductDetailsBanner product={product} {...props} productPage={true} />
        </Box>
      }
      {(reviewItems?.length > 0 || init) &&
        <RenderReviews />
      }
    </Grid>

  </div > :
    <Box>
      <Box style={{}}>


      </Box>
      <MobileProductDetailsRightColumn
        {...props}
        simple={simple}
        item={product}
        showLink={false}
        onSetSimple={setSimple} />
    </Box> : <></>
}


const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    language: state.language,
    translations: state.translations
  }
}

export default connect(mapStateToProps, { register, setCart, setFavorites, setSnackBar, setGoToCartDialog })(ProductPage)
