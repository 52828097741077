import { Box, Slider } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { onPriceMove$ } from '../../helpers/utilities';

export const PriceSlider = (props: any) => {
    const { reloadQueryOptions, minGlobal, maxGlobal, min, max } = props
    const [selectedFilters, setSelectedFilters] = useState<any[]>([])
    const [pageIndex, setPageIndex] = useState(1)
    const [minMaxPrice, setMinMaxPrice] = React.useState<number[]>([0, 1000]);



    
    
    useEffect(() => {
        setMinMaxPrice([min, max])
    }, [min, max]);

    const handlePriceRangeChange = (arr, minMaxPrice) => {
        setPageIndex(1)
        setSelectedFilters(arr)
        reloadQueryOptions(arr, 1, minMaxPrice)
    }

    const handleChange = (event: Event, newValue: number | number[]) => {
        setMinMaxPrice(newValue as number[]);
        onPriceMove$.next(newValue)
    };

    function valueText(value: number) {
        return `${value}`;
    }

    return <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2, justifyContent: 'flex-start'}}>
        
        {/* <Slider
            getAriaLabel={() => ''}
            value={minMaxPrice}
            onChange={handleChange}
            min={minGlobal}
            max={maxGlobal}
            sx={{ color: 'gray', marginTop: 2, width: '90%' }}
            valueLabelDisplay="auto"
            getAriaValueText={valueText}
        /> */}
    </Box>


}
