import React, { useState, useEffect, Fragment } from "react";
import InputBase from '@mui/material/InputBase';
import { Form, Formik, ErrorMessage } from "formik"
import * as Yup from "yup";
import '../../App.css'
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import { COLORS } from '../../constants/colors';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import { Title } from '../../components/Typography/Title'
import { SearchBrandComponent } from '../../components/Brand/SearchBrandComponent'
import { SearchProductComponent } from '../../components/Product/SearchProductComponent'
import { useHistory } from "react-router";
import { Box, Button, ClickAwayListener, InputAdornment, TextField, styled } from "@mui/material";
import { Animated } from "react-animated-css";
import { Brand } from "../../models/brand/brands";
import { Product } from "../../models/product/product";
import { QueryOptions } from "../../helpers/query.options";
import { brandService } from "../../models/brand/service";
import { productService } from "../../models/product/service";
import { onSearch$ } from "../Dialogs/SearchDialog";
import { Category } from "../../models/category/category";
import { categoryService } from "../../models/category/service";
import { SearchCategoryComponent } from "../Category/SearchCategoryComponent";
import { values } from "lodash";
import { emailSubscriptionService } from "../../models/emailSubscription/service";
import { EmailSubscription } from "../../models/emailSubscription/EmailSubscription";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

export const SearchInput = (props) => {
    const { onchangeValue, onRedirect, translations, language, hidden } = props
    const history = useHistory()
    const [search, setSearch] = useState("")
    const [loaded, setLoaded] = useState(false)
    const [shown, setShown] = useState(true)
    const [noResults, setNoResults] = useState(false)
    const [brands, setBrands] = useState<Brand[]>([])
    const [products, setProducts] = useState<Product[]>([])
    const [categories, setCategories] = useState<Category[]>([])

    useEffect(() => {
        onSearch$.next(search)
    }, [search])

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, 400)
        onSearch$.subscribe(
            debounced => {
                setNoResults(false)
                if (debounced.length > 2) {
                    getSearchResults(debounced, language)
                } else {
                    setBrands([])
                    setCategories([])
                    setProducts([])
                }
            }
        );


    }, []);

    const getSearchResults = async (search, language) => {
        const options = new QueryOptions()
        setNoResults(false)
        options.lang = language
        options.pageSize = 6
        options.sort = "level"
        options.order = "asc"
        options.search = search
        const results = await Promise.all([
            brandService.listWithCounter(options),
            productService.listWithCounter(options),
            categoryService.listWithCounter(options)
        ]);
        setBrands(results[0].current)
        setProducts(results[1].current)
        setCategories(results[2].current)

        if (!results[0].current.length && !results[1].current.length && !results[2].current.length) {
            setNoResults(true)
        }
    }


    return <ClickAwayListener onClickAway={() => {
        setShown(false)
    }}>
        <Paper
            component="form"
            sx={{
                p: '2px 4px',
                borderRadius: 20,
                height: 30,
                alignSelf: 'center',
                display: 'flex',
                alignItems: 'center',
                width: '90%',
                boxShadow: '0px 0px rgba(0,0,0,0)',
                backgroundColor: COLORS.BG_GRAY,
                position: 'relative',
            }}
        >
            <IconButton sx={{ p: '10px' }} aria-label="menu">
                <SearchIcon />
            </IconButton>
            <InputBase
                onFocus={() => setShown(true)}
                sx={{ ml: 1, flex: 1 }}
                value={search}
                onChange={(evt) => {
                    setNoResults(false)
                    setSearch(evt.target.value)
                }}
                placeholder={translations?.search}
            />

            <Animated animationIn="fadeIn" animationOut="fadeOut"
                animationInDuration={500}
                animationOutDuration={500}
                isVisible={search.length > 0} >
                <Box style={{ display: 'flex', alignItems: 'center', marginRight: 5 }} onClick={() => {
                    setSearch("")
                }}>
                    <CloseIcon />
                </Box>
            </Animated>
            {window.innerWidth > 1200 && (noResults || categories.length > 0 || products.length > 0 || brands.length > 0) &&
                <Animated animationIn="fadeIn" animationOut="fadeOut"
                    animationInDuration={500}
                    animationOutDuration={500}
                    isVisible={shown && search?.length > 0}
                    style={{
                        boxShadow: '0px 0px 7px rgba(0,0,0,.5)',
                        backgroundColor: 'white', height: 600, width: 400, position: 'absolute', top: 40, left: -30, zIndex: 120000
                    }}>
                    <div style={{ position: 'relative', height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column', paddingTop: 4, width: '100%', padding: 0 }}>
                        <div style={{ position: 'relative', width: '97%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', padding: 5, cursor: 'pointer' }} onClick={() => {

                            setShown(false)
                        }}>
                            <CloseIcon />
                        </div>
                        {!noResults ? <List
                            sx={{
                                paddingBottom: 0,
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'hidden',
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}
                        >
                            {[
                                { name: translations.brands, id: 'brands' },
                                { name: translations?.categories, id: 'categories' },
                                { name: translations?.products, id: 'products' }
                            ].map((item, index) => (
                                <li key={index}>
                                    <ul>
                                        {item.id === 'brands' && brands?.length > 0 && <ListSubheader sx={{ backgroundColor: '#444', padding: 1 }}>
                                            <Title text={`${item.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"white"} />
                                        </ListSubheader>}
                                        {item.id === 'categories' && categories?.length > 0 && <ListSubheader sx={{ backgroundColor: '#444', padding: 1 }}>
                                            <Title text={`${item.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"white"} />
                                        </ListSubheader>}
                                        {item.id === 'products' && products?.length > 0 && <ListSubheader sx={{ backgroundColor: '#444', padding: 1 }}>
                                            <Title text={`${item.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"white"} />
                                        </ListSubheader>}
                                        {item.id === 'brands' &&
                                            brands?.length > 0 && <Box sx={{}}>
                                                {brands.map((brand, ind) => {
                                                    return <ListItem key={`item-${brand.id}-`} sx={{ padding: 0 }}>
                                                        <SearchBrandComponent item={brand} key={index} onClick={() => {
                                                            history.push(`/brand/${brand.id}`)
                                                            setShown(false)
                                                        }} />
                                                    </ListItem>
                                                })}
                                            </Box>
                                        }
                                        {item.id === 'categories' &&
                                            categories.map((category, index) => {
                                                return <SearchCategoryComponent item={category} key={index} onClick={(item) => {
                                                    setShown(false)
                                                    onRedirect(`${category.url}`)
                                                }} />
                                            })
                                        }
                                        {item.id === 'products' &&
                                            products?.length > 0 &&
                                            products.map((item, index) => {
                                                return <SearchProductComponent
                                                    item={item}
                                                    key={index}
                                                    onClick={() => {
                                                        setShown(false)
                                                        onRedirect(`/product/${item.id}`)
                                                    }} />
                                            })}
                                    </ul>
                                </li>
                            ))}
                        </List> : <Box style={{ padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {translations.no_products_found}
                        </Box>}
                    </div>
                </Animated>}


        </Paper>
    </ClickAwayListener>

}

const SubscribeInputElement = styled(TextField)(
    ({ theme }) => `
  display: flex;
  justify-content: center;
  height: 35px;
  padding-left: 10px;
  font-family: FiraGo;
  font-weight: 400;
  line-height: 1.5;
  outline: none;
  border-radius: 6px;
  background-color: transparent;
`
)

export const GraySearchInput = (props) => {
    const { translations, language, setSnackBar, app } = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [loaded, setLoaded] = useState(true)

    const {width} = useWindowDimensions()

    const validation = Yup.object().shape({
        email: Yup.string().required(`${translations.email} ${translations.is_required}`).email(translations.email_format_error),
    })

    const getValues = (values) => {
        const es = new EmailSubscription()
        es.lang = language
        es.email = values.email
        setLoading(true)
        emailSubscriptionService.create(es).then((resp) => {
            if (resp.created) {
                setSnackBar({ ...app.snackBar, open: true, text: translations.form_sent_successfully, bColor: "rgb(83, 148, 96)" })
            }
            setLoading(false)
            setLoaded(false)
            setTimeout(()=> {
                setLoaded(true)
            }, 400)
        }).catch((error) => {
            setLoading(false)
            console.log(error)
        })
    }

    return loaded ? <Fragment>

        <Box
            component="form"
            
        >

            <Formik
                validationSchema={validation}
                initialValues={{ email: '' }}
                onSubmit={getValues}>
                {({ values, validateForm, handleSubmit, setFieldValue, handleChange, handleBlur, errors }: any) => (
                    <Form onSubmit={(values) => {
                        console.log(errors)
                        handleSubmit(values)
                    }}>

                        <SubscribeInputElement
                        variant="standard"
                            sx={{
                                background: 'transparent !important',
                                width: "90%",
                                border: "1px solid rgb(230,230,230);",
                                "@media (max-width:440px)": {
                                    width: "100%",
                                    border: "1px solid darkgray",
                                },
                                "@media (max-width:392px)": {
                                    width: "90%",
                                    border: "1px solid darkgray",
                                },
                            }}
                            onChange={(event) => {
                                setFieldValue("email", event.target.value)
                                setError('')
                            }}
                            name="email"
                            value={values.email}
                            placeholder={translations?.email}
                            InputProps={{
                                style: { color: width > 600 ? "white" : "black" },
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            onClick={() => {
                                                if (!loading) {
                                                    validateForm().then((errors) => {
                                                        if (errors?.email) {
                                                            setError(errors.email);
                                                        }
                                                        handleSubmit();
                                                    })
                
                                                }
                                            }}
                                            disableRipple
                                            sx={{
                                                textTransform: "none",
                                                color: "black",
                                                height: "35px",
                                                minWidth: "100px",
                                                textAlign: "center",
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0,
                                                fontSize: 13,
                                                backgroundColor: COLORS.BG_GRAY,
                                                cursor: "pointer",
                                                "&:hover": { backgroundColor: COLORS.BG_GRAY },
                                                "@media (max-width:464px)": {
                                                    minWidth: "100px",
                                                },
                                            }}
                                        >
                                            {translations.send}
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        
                    </Form>
                )}
            </Formik>

        </Box>
        {error.length ? <Box sx={{ color: width > 600 ? "white" : "black", padding: 1, fontSize: 12 }}>{error}</Box> : <></>}
    </Fragment>: <></>

}