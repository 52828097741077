import React, { useState, useEffect, useCallback } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Address } from '../../models/address/address'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { COLORS } from '../../constants/colors';


interface AddressItemProps {
    item: Address
    onRemove: (Address) => void
    onEdit: (Address) => void
}

export const AddressTableItem = (props: AddressItemProps) => {
    const { item, onRemove, onEdit } = props
    useEffect(() => {

    }, [])

    return <Box sx={{ width: '95%', alignItems: 'flex-start', display: 'flex', padding: 1,  borderBottom: '1px solid lightgray', height: 80, marginTop: 1 }}>
        <Grid container style={{ height: '100%' }}>
            <Grid item lg={11} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', flex: 1 }}>
                <Typography variant="subtitle1" component="h5" style={{ fontWeight: 'bold' }}>{item.street}, {item.city?.name}</Typography>
                <Typography variant="subtitle1" component="h5" style={{ fontWeight: 'bold' }}>{item.phone}</Typography>
                <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold' }}>{item.firstName} {item.lastName}</Typography>

            </Grid>
            <Grid item lg={1} style={{ position: 'relative', height: '100%' }}>
                <div
                    onClick={() => onRemove(item)}
                    style={{
                        position: 'absolute', top: 0, cursor: 'pointer',
                        flexDirection: 'column',
                        width: 30, 
                        right: 0,
                        height: 30, backgroundColor: COLORS.BEIGE, 
                        borderRadius: 20, 
                        display: 'flex', alignItems: 'center', 
                        justifyContent: 'center'
                    }}>
                    <DeleteOutlineIcon style={{fontSize: 18}}/>
                </div>
                <div
                    onClick={() => onEdit(item)}
                    style={{
                        position: 'absolute',
                        flexDirection: 'column', 
                        bottom: 5, 
                        right: 0,
                        cursor: 'pointer', 
                        width: 30, 
                        height: 30, 
                        backgroundColor: COLORS.BEIGE, 
                        borderRadius: 20, 
                        display: 'flex', 
                        alignItems: 'center', justifyContent: 'center'
                    }}>
                    <EditIcon style={{fontSize: 18}}/>
                </div>


            </Grid>
        </Grid>

    </Box>
}