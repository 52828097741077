import { Serializer } from '../serializer'
import { Location } from '../location/location'
import { Meta } from './Meta'

export class MetaSerializer implements Serializer {
    fromJson(json: any) {
        const meta = new Meta()
        meta.description = json.description
        meta.title = json.title
        meta.keywords = json.keywords
        return meta
    }

    toJson(item: any) {
        const obj: any = {}
        return obj
    }
}

export const metaSerializer = new MetaSerializer()