import React, { useState } from "react"
import { KeyNoteComponent } from "./KeyNoteComponent"
import { Box, Typography } from "@mui/material"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Navigation } from "swiper"
import { Mousewheel } from "swiper"
import { Keyboard } from "swiper"
import { Autoplay } from "swiper"

export const ProductPromoPageCardColumnSection = (props) => {
    const { item, language } = props
    const { width } = useWindowDimensions()
    const [activeIndex, setActiveIndex] = useState(0)

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex)
    }

    const renderIndicators = () => {
        return (
            <>
                {item?.columns?.map((item, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                                width: 25,
                                height: 3.5,
                                borderRadius: 2,
                                backgroundColor: index === activeIndex ? "black" : "lightgray",
                                marginRight: 1,
                                display: "inline-block",
                            }}
                        ></Box>
                    )
                })}
            </>
        )
    }

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: 1200,
                marginBottom: 10,
            }}
        >
            
            {width > 600 ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        "@media ( max-width:1200px)": {
                            flexWrap: "wrap",
                            justifyContent: "space-around",
                        },
                    }}
                >
                    {item?.columns.map((card, index) => (
                        <KeyNoteComponent key={index} card={card} {...props} />
                    ))}
                </Box>
            ) : (
                <Swiper
                    onSlideChange={handleSlideChange}
                    cssMode={true}
                    navigation={{
                        prevEl: ".key-notes-prev",
                        nextEl: ".key-notes-next",
                    }}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Mousewheel, Keyboard, Autoplay]}
                    slidesPerView={1}
                    centeredSlides={false}
                    className="mySwiper"
                    style={{
                        cursor: "pointer",
                        width: width > 600 ? "350px" : "100%",
                        height: width > 600 ? "350px" : "auto",
                    }}
                >
                    {item?.columns?.map((card, index) => (
                        <Box sx={{ position: "relative" }}>
                            <SwiperSlide
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <KeyNoteComponent  card={card} {...props}/>
                            </SwiperSlide>
                        </Box>
                    ))}
                    {renderIndicators()}
                </Swiper>
            )}
        </Box>
    )
}
