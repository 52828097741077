import { Branch } from '../branch/branch'
import { NameIdItem } from '../common/common'
import { Country } from '../country/country'
import { Resource } from '../resource'

export class UserRole extends Resource {
	name: string | any
	permissions: UserPermissions
}

export class UserPermissions extends Resource {
	read: boolean | any
	write:boolean | any
	delete: boolean | any
	update: boolean | any
	export : boolean | any
}
export class PersonalInfo extends Resource {
	birthDate: any
	citizenship: string 
	maritalStatus: string
}

export class EmbeddedPosition extends Resource  {
	department: NameIdItem
	name: string
}
export class PreviousPosition extends Resource  {
	caption: string
	dateFrom: Date
	dateTo: Date
	info: string
	position: EmbeddedPosition
}
export class Position extends Resource {
	currentPosition: EmbeddedPosition
	level: string
	branch: Branch
	previousPositions: PreviousPosition[]
}
export class User extends Resource {
    phone: string
	message: string
	activated: boolean
	birthDayYear: number
	code: string
	fullName: string
    personalId: string
    firstName: string
    lastName: string
    email: string
    password: string
    confirmPassword: string
	accessToken: string
	status: string
	setToken(token: string) {
		this.accessToken = token
		this.save()
	}

	update(accessToken: string) {
		this.accessToken = accessToken
	}
    
	delete() {
		localStorage.removeItem("currentUser")
		this.accessToken = undefined
	}

	save() {
		localStorage.setItem("currentUser", JSON.stringify(this))
	}

  	async load() {
		let parsedUser: any = await localStorage.getItem("currentUser")
		if (parsedUser) {
			parsedUser = JSON.parse(parsedUser)
		}
		if (parsedUser) {
			this.id = parsedUser.id
			this.phone = parsedUser.phone
			this.firstName = parsedUser.firstName
			this.fullName = parsedUser.fullName
			this.lastName = parsedUser.lastName
			this.accessToken = parsedUser.accessToken
			this.personalId = parsedUser.personalId
			this.email = parsedUser.email
		}
		return this
	}

	updateUser(user: User) {
		this.id = user?.id
		this.firstName = user.firstName
		this.accessToken = user.accessToken
		this.lastName = user.lastName
		this.fullName = user.fullName
		this.phone = user.phone
		this.fullName = user.fullName
		this.email = user.email
		this.save()
		return this
	}
}



export class EmbeddedAddress extends Resource{
    city:        string;
    country:     Country;
    postalCode: string;
    street:      string;
    street2:     string;
}

