import {  Box, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Title } from "../../components/Typography/Title"
import { useHistory } from "react-router"

export const SiteMapComponent = (props) => {
    const { menuItems, translations } = props
    const history = useHistory()
    const [uniqueSections, setUniqueSections] = useState<any>()
    const [sectionsData, setSectionsData] = useState<any>()

    useEffect(() => {
        if (menuItems) {
            setUniqueSections(
                [...(new Set(menuItems?.map((item) => item.section)) as any)].sort((a, b) => {
                    const order = ["about_us", "account", "my_profile", "help"]
                    return order.indexOf(a) - order.indexOf(b)
                })
            )
        }
    }, [menuItems])

    useEffect(() => {
        if (uniqueSections) {
            setSectionsData(
                uniqueSections?.map((section) => ({
                    section,
                    data: menuItems.filter((item) => item.section === section),
                }))
            )
        }
    }, [uniqueSections])



    const getTitle = (t) => {
        switch(t){
            case 'about_us':
                return translations?.about_us
            case 'account':
                return translations?.account
            case 'my_profile':
                return translations?.my_profile
            case 'help':
                return translations?.help
        }
    }

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%", padding: 1 }}>
                {sectionsData?.map((item, index) => {
                    console.log("item data", item.data)
                    return (
                        <Box sx={{ width: "100%" }} key={index}>
                            <Box
                                sx={{
                                    padding: 0,
                                    width: "100%",
                                    "&.MuiAccordion-root": {
                                        boxShadow: "0px 0px rgba(0,0,0,0)",
                                    },
                                }}
                            >
                                <Typography variant="body1" sx={{ textAlign: "left", padding: 2, fontWeight: "bold" }}>
                                    {getTitle(item.section)}
                                </Typography>
                                {item.data.map((sItem, index) => {
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                paddingLeft: 20,
                                                cursor: "pointer",
                                                paddingRight: 20,
                                                paddingTop: 10,
                                            }}
                                            key={index}
                                        >
                                            <div
                                                style={{
                                                    boxShadow: "0px 0px rgba(0,0,0,0)",
                                                    border: 0,
                                                    backgroundColor: "transparent",
                                                    width: "100%",
                                                    height: 30,
                                                    minHeight: 30,
                                                    maxHeight: 30,
                                                    margin: 0,
                                                }}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Title
                                                    text={sItem.title}
                                                    fontSize={14}
                                                    uppercase={true}
                                                    fontWeight="400"
                                                    onClick={() => {
                                                        history.push(sItem.link)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </>
    )
}
