import { productSerializer, ProductSerializer } from "../product/serializer"
import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { CartItem } from "./cart"


export class CartItemSerializer implements Serializer {
    fromJson(json: any) {
        const item = new CartItem()
        item.sku = json.sku
        if (json.old_price) {
            item.price = json.old_price
        } else {
            item.price = json.price
        }
        item.priceToPay = json.price_to_pay
        item.specialPrice = json.price
        item.discount = json.discount
        item.product = productSerializer.fromJson(json.product)
        item.quantity = json.quantity
        item.created = json.created
        return item
    }
    toJson(item: CartItem) {
        return {
            sku: item.sku,
            quantity: item.quantity,
            card_order_id: item.cardOrderId,
            product: item.product.id,
            price: item.price,
            lang: item.lang ? item.lang: 'geo'
        }
    }
}

export const cartItemSerializer = new CartItemSerializer()