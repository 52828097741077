
import React, { useState, useEffect, useCallback } from "react";
import '../styles.scss'

import { makeStyles } from "@mui/styles";
import ProductPopup from "../Dialogs/ProductPopup";
import { GalleryDialog } from "../Dialogs/GalleryDialog";
import { Product } from "../../models/product/product";
import { Title } from "../Typography/Title";
import { Fade } from "@mui/material";
import { limitString } from "../../helpers/utilities";
import Badge from '@mui/material/Badge';

export const ProductItem = (props) => {
    const defaultHeight = window.innerWidth > 1200 ? '70%' : '55%'
    const { item, translations, history, imageHeight = defaultHeight, itemHeight = 280 } = props
    const [product, setProduct] = useState<Product>()
    const classes = useStyles();
    const [image, setImage] = useState<any>()
    const [showContent, setShowContent] = useState(true)
    const [over, setOver] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [galleryOpen, setGalleryOpen] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [saleBadge, setSaleBadge] = useState({ invisible: true, data: null });
    const [newStatusBadge, setNewStatusBadge] = useState({ invisible: true, data: null });
    useEffect(() => {
        const img = new Image()
        img.src = image
        img.onload = () => {
            setLoaded(true)

        }
        return () => { }
    }, [image])

    useEffect(() => {
        if (item) {
            setImage(
                item.mainImage
            )
        }

    }, [item])

    useEffect(() => {
        return () => {
            setShowContent(false)
        }

    }, [])

    useEffect(() => {
        // special price
        if (item.specialPrice !== item.price && item.specialPrice) {
            setSaleBadge({ invisible: false, data: Math.round(100 - item.specialPrice * 100 / item.price) })
        }
        // newitem
        if (((Date.now() - item.created.getTime()) / (24 * 3600 * 1000)) < 90) {
            setNewStatusBadge({ invisible: false, data: 'new' });
        }
    }, [item])

    const RenderImage = useCallback(() => {
        return <img
            src={image}
            className={classes.image}
            style={{ height: imageHeight }}
            onLoad={() => {
                setLoaded(true)
            }}
            onClick={() => {
                if (history) {
                    history.push(`/product/${item.id}`)
                }
            }}
            onError={() => {
                setImage("https://via.placeholder.com/250x250")
            }} />
    }, [image, product])

    return true ?
        <div
            onMouseOver={() => {
                setOver(true)
            }}
            onMouseOut={() => {
                setOver(false)
            }}
            className={classes.container}
            style={{ cursor: "pointer", height: window.innerWidth < 600 ? itemHeight : 'auto' }}
            onClick={() => {

            }}>
            <ProductPopup
                product={product}
                setProduct={setProduct}
                onReload={() => { }}
                item={item}
                {...props}
                setGalleryOpen={() => {
                    setGalleryOpen(true)
                }}
                onOpen={dialogOpen && !galleryOpen}
                onCloseDialog={(resp) => {
                    setDialogOpen(resp)
                    setTimeout(() => {
                        setOver(false)
                    }, 500)
                }} />
            <GalleryDialog
                product={product}
                onReload={() => { }}
                item={item}
                {...props}
                open={galleryOpen}
                onCloseDialog={(resp) => {
                    setGalleryOpen(resp)
                }} />

            {window.innerWidth > 1200 && <div
                onClick={() => {
                    setDialogOpen(true)
                }}
                style={{
                    position: 'absolute',
                    top: over ? 70 : window.innerWidth > 1200 ? -120 : 70,
                    backgroundColor: 'rgba(0,0,0,.4)',
                    width: '70%',
                    left: '15%',
                    zIndex: 120,
                    borderRadius: 5,
                    height: 40,
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontWeight: '600',
                    cursor: 'pointer',
                }}>
                {translations?.see_details}

            </div>}
            <Badge color="error" badgeContent={newStatusBadge.data} invisible={newStatusBadge.invisible} style={{ zIndex: 1000, marginTop: 10, marginLeft: window.innerWidth > 1200 ? 155 : 95 }} />
            <Badge color="error" badgeContent={`-${saleBadge.data}%`} invisible={saleBadge.invisible} style={{ zIndex: 1000, marginLeft: !newStatusBadge.invisible ? window.innerWidth > 1200 ? 75 : 15 : 95 }} />
            <RenderImage />

            <div className={classes.info} style={{
                minHeight: 60,
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'flex-start',
                display: 'flex'
            }} onClick={() => {
                if (history) {
                    history.push(`/product/${item.id}`)
                }
            }}>
                <Title text={`${item.brand?.title}`}
                    textAlign={'center'}
                    style={{ marginTop: 5, marginBottom: 5 }}
                    fontSize={13} uppercase={true} fontWeight='600' color={"black"} />
                <Title
                    textAlign={'center'}
                    text={`${limitString(item.name, window.innerWidth > 1200 ? 49 : 30)}`} fontSize={13} uppercase={true} fontWeight='400' color={"black"} />

            </div>
            <div className={classes.info} style={{ marginTop: -2 }} onClick={() => {
                if (history) {
                    history.push(`/product/${item.id}`)
                }
            }}>
                {item.specialPrice < item.price ? <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <Title text={`${item.price}₾`} fontSize={16} uppercase={true} fontWeight='500' color={"black"} style={{ marginRight: 5, textDecorationLine: 'line-through' }} />
                    <Title text={`${item.specialPrice}₾`} fontSize={16} uppercase={true} fontWeight='500' color={"red"} />
                </div> :
                    <Title text={`${item.price}₾`} fontSize={16} uppercase={true} fontWeight='500' color={"black"} />
                }
            </div>
        </div >
        : <></>
}

const useStyles = makeStyles({
    container: {
        overflow: 'hidden',
        marginTop: 10,
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: "relative",
        width: '100%',
        borderRadius: 5,
        borderWidth: 1
    },
    tag: {
        alignItems: 'center',
        display: 'flex',
        verticalAlign: 'middle',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        position: "absolute",
        textAlign: 'center',
        fontSize: '13px',
        fontWeight: 'bold',
        left: 10,
        top: -10,
        color: 'white',
        borderRadius: 30,
        width: 40,
        height: 40
    },
    info: {
        marginTop: 0,
        width: '80%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    price: {
        color: 'red',
        textDecoration: 'line-through'
    },
    specialPrice: {
        marginLeft: 10
    },
    image: {
        marginBottom: -5,
        top: 0,
        zIndex: 100,
        left: 0,
    }
});
