
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';

function Pagination(props: any) {
    const { count = 400, pageSize = 20, setPageIndex, pageIndex } = props
    const [numberOfPages, setNumberOfPages] = useState([1])
    const [arrOfCurrButtons, setArrOfCurrButtons] = useState<any[]>([])
    const styleProps = { color: '#444' };
    const classes = useStyles(styleProps);

    useEffect(() => {
        if (count) {
            const n: any[] = []
            for (let i = 1; i <= Math.ceil(count / pageSize) ; i++) {
                n.push(i)
            }
            setNumberOfPages(n)
        }
    }, [count])



    const paginationLogic = () => {
        let tempNumberOfPages: any[] = [...arrOfCurrButtons]
        let dotsInitial = '...'
        let dotsLeft = '...'
        let dotsRight = '...'
        
        if (numberOfPages?.length < 8) {
            tempNumberOfPages = numberOfPages
        } else
        if (pageIndex >= 1 && pageIndex <= 3) {
            tempNumberOfPages = [1, 2, 3, 4, dotsInitial, numberOfPages?.length]
        }

        else if (pageIndex === 4) {
            const sliced = numberOfPages.slice(0, 5)
            tempNumberOfPages = [...sliced, dotsInitial, numberOfPages?.length]
        }

        else if (pageIndex > 4 && pageIndex < numberOfPages?.length - 3) {
            const sliced1 = numberOfPages.slice(pageIndex - 3, pageIndex)
            const sliced2 = numberOfPages.slice(pageIndex, pageIndex + 2)
            tempNumberOfPages = ([1, dotsLeft, ...sliced1, ...sliced2, dotsRight, numberOfPages?.length])
        }

        else if (pageIndex === numberOfPages?.length - 3) {
            const sliced = numberOfPages.slice(numberOfPages?.length - 5)
            tempNumberOfPages = ([1, dotsLeft, ...sliced])
        }

        else if (pageIndex > numberOfPages?.length - 3) {
            const sliced = numberOfPages.slice(numberOfPages?.length - 4, numberOfPages?.length)
            tempNumberOfPages = ([1, dotsLeft, ...sliced])
        } else {
            const sliced = numberOfPages.slice(0, 5)
            tempNumberOfPages = [...sliced, dotsInitial, numberOfPages?.length]
        }
        setArrOfCurrButtons(tempNumberOfPages)
    }

    useEffect(() => {
        paginationLogic();
    }, [pageIndex, numberOfPages])

    return (
        <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '550px', maxWidth: '680px' }}>
                    {(numberOfPages?.length > 1 && pageIndex > 1) ?<button
                        disabled={pageIndex === 1 || pageIndex === 0 ? true : false}
                        style={{ color: pageIndex === 1 || pageIndex === 0 ? 'lightgray' : '' }}
                        className={classes.periferals}
                        onClick={() => setPageIndex(1)}
                    >
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: -5, left: 52 }}>
                                <ChevronLeft width={15} height={15} />
                            </div>
                            <div style={{ position: 'absolute', top: -5, left: 46 }}>
                                <ChevronLeft width={15} height={15} />
                            </div>

                        </div>
                    </button>: <button
                        disabled={true}
                        style={{}}
                        className={classes.periferals}>
                        <div style={{ position: 'relative' }}></div>
                    </button>}
                    {(numberOfPages?.length > 1 && pageIndex > 1) ?<button
                        disabled={pageIndex === 1 || pageIndex === 0 ? true : false}
                        style={{ color: pageIndex === 1 || pageIndex === 0 ? 'lightgray' : '', marginLeft: '20px' }}
                        className={classes.periferals}
                        onClick={() => {
                            const value = pageIndex <= 1 ? pageIndex : pageIndex - 1
                            setPageIndex(value)
                        }}
                    >
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: -5, right: 0 }}>
                                <ChevronLeft width={15} height={15} />
                            </div>
                        </div>
                    </button>: <button
                        disabled={true}
                        style={{}}
                        className={classes.periferals}>
                        <div style={{ position: 'relative' }}></div>
                    </button>}
                    {arrOfCurrButtons?.length > 1 &&<div>
                        {arrOfCurrButtons.map(((item, index) => {
                            return <button
                                disabled={item === "..." ? true : false}
                                id="pages"
                                key={index}
                                className={item !== "..." ? item === pageIndex || (pageIndex === 0 && index === 0) ? classes.current : classes.pages : classes.dots}
                                onClick={() => setPageIndex(item)}
                                style={{ color: '#444' }}
                            >
                                {item}
                            </button>
                        }))}
                    </div>}
                    {(numberOfPages?.length > 1 && pageIndex < numberOfPages?.length) ?<button
                        disabled={pageIndex === numberOfPages?.length ? true : false}
                        style={{ color: pageIndex === numberOfPages?.length ? 'lightgray' : '', marginRight: '20px' }}
                        className={classes.periferals}
                        onClick={() => {
                            const value = pageIndex >= numberOfPages?.length ? pageIndex : pageIndex === 0 ? pageIndex + 2 : pageIndex + 1
                            setPageIndex(value)
                        }}
                    >
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: -5, right: 0 }}>
                                <ChevronRight width={15} height={15} />

                            </div>
                        </div>
                    </button>: <button
                        disabled={true}
                        style={{}}
                        className={classes.periferals}>
                        <div style={{ position: 'relative' }}></div>
                    </button>}
                    {(numberOfPages?.length > 1 && pageIndex < numberOfPages?.length) ? <button
                        disabled={pageIndex === numberOfPages?.length ? true : false}
                        style={{ color: pageIndex === numberOfPages?.length ? 'lightgray' : '' }}
                        className={classes.periferals}
                        onClick={() => setPageIndex(numberOfPages?.length)}
                    >
                        <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: -5, right: 44 }}>
                                <ChevronRight width={15} height={15} />
                            </div>
                            <div style={{ position: 'absolute', top: -5, right: 38 }}>
                                <ChevronRight width={15} height={15} />
                            </div>

                        </div>
                    </button>: <button
                        disabled={true}
                        style={{}}
                        className={classes.periferals}>
                        <div style={{ position: 'relative' }}></div>
                    </button>}
                </div>
            </div>
        </div>
    );
}


export default Pagination

const useStyles = makeStyles({
    periferals: (props: any) => ({
        background: "transparent",
        fontSize: '1.5rem',
        fontWeight: 'bolder',
        border: 'none',
        cursor: 'pointer',
        margin: '5px',
        color: props.color,
        width: '40px',
        height: '40px',
        lineHeight: '1px',
        textAlign: 'center'
    }),
    pages: {
        padding: '0px',
        marginTop: '8px',
        background: 'none',
        fontSize: '1rem',
        fontWeight: 'bolder',
        cursor: 'pointer',
        height: '40px',
        width: '40px',
        margin: '5px',
        border: 'none',
        borderRadius: '20px',
        "&:hover": {
            background: 'lightgrey',
            animationFillMode: 'forwards'
        },
    },
    dots: (props: any) => ({
        padding: '10px',
        background: 'transparent',
        fontSize: '1rem',
        fontWeight: 'bolder',
        height: '40px',
        width: '40px',
        margin: '5px',
        border: 'none',
        color: props.color
    }),
    current: {
        marginTop: '0px',
        fontSize: '1rem',
        fontWeight: 'bolder',
        cursor: 'pointer',
        height: '40px',
        width: '40px',
        margin: '5px',
        border: 'none',
        borderRadius: '20px',
        animationFillMode: 'forwards',
    },
    items: (props: any) => ({
        fontSize: '1rem',
        fontWeight: 'bolder',
        color: props.color,
        padding: '10px 20px 10px 20px',
    }),
    "@keyframes currEffect": {
        "0%": {
            background: 'none',
        },
        "100%": {
            background: 'rgba(128,128,128, 0.4)',
            color: 'white'
        }
    },
    "@keyframes hoverEffect": {
        "0%": {
            background: 'transparent',
        },
        "100%": {
            background: 'rgba(211,211,211, 0.4)',
        }
    },
})
