
import { environment } from "../../environment/environment";
import { citySerializer } from "../city/serializer";
import {Serializer} from "../serializer";
import { GiftCard, GiftCardTemplate, GiftCardTemplateCategory } from "./GiftCard";

const IMAGES_PATH = environment.imagePath


export class GiftCardSerializer implements Serializer {
  fromJson(json: any) {
    const item = new GiftCard();
    item.qty = json.qty;
    item.amount = json.amount;
    return item
  }
  toJson(item: GiftCard) {
    return {
      card_type: item.type,
      qty: item.qty,
      amount: item.amount,
    };
  }
}
export class GiftCardTemplateSerializer implements Serializer {
  fromJson(json: any) {
    const item = new GiftCardTemplate();
    item.id = json.id
    item.name = json.name
    if (json.category) {
      item.category = giftCardTemplateCategorySerializer.fromJson(json.category)
    }
    item.image = `${IMAGES_PATH}/assets/gift_card_images/${json.filename}.jpg`
    return item
  }
  toJson(item: GiftCard) {
    return {
      
    };
  }
}
export class GiftCardTemplateCategorySerializer implements Serializer {
  fromJson(json: any) {
    const item = new GiftCardTemplateCategory();
    item.id = json.id
    item.sort = json.sort
    item.name = json.name
    return item
  }
  toJson(item: GiftCard) {
    return {
      card_type: item.type,
      qty: item.qty,
      amount: item.amount,
    };
  }
}

export const giftCardSerializer = new GiftCardSerializer();
export const giftCardTemplateCategorySerializer = new GiftCardTemplateCategorySerializer();
export const giftCardTemplateSerializer = new GiftCardTemplateSerializer();
