import { SnackbarOrigin } from "@mui/material";
import {
  SET_CART,
  SET_SNACK_BAR,
  SET_CONFIRMATION,
  SET_FAVORITES,
  SET_GO_TO_CART_DIALOG,
  SET_APP_LOADED,
  SET_DIMENSIONS,
} from "./applicationActions";

export interface State extends SnackbarOrigin {
  open: boolean;
  text: string;
}

const initialState = {
  cart: [],
  loaded: false,
  dimensions: { height: window.innerHeight, width: window.innerWidth },
  goToCartDialog: false,
  favorites: [],
  confirmation: { item: null, open: false },
  snackBar: { open: false, type: "success", text: "", bColor: "rgb(83, 148, 96)" },
};

export const applicationReducer = (
  state = initialState,
  action: { type: any; content: any }
) => {
  switch (action.type) {
    case SET_SNACK_BAR: {
      const { snackBar } = action.content;
      return { ...state, snackBar };
    }
    case SET_APP_LOADED: {
      const { loaded } = action.content;
      return { ...state, loaded };
    }
    case SET_GO_TO_CART_DIALOG: {
      const { goToCartDialog } = action.content;
      return { ...state, goToCartDialog };
    }
    case SET_DIMENSIONS: {
      const { dimensions } = action.content;
      return { ...state, dimensions };
    }
    case SET_CART: {
      const { cart } = action.content;
      return { ...state, cart };
    }
    case SET_FAVORITES: {
      const { favorites } = action.content;
      return { ...state, favorites };
    }
    case SET_CONFIRMATION: {
      const { confirmation } = action.content;
      return { ...state, confirmation };
    }
    default:
      return state;
  }
};
