import React from "react"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { Box } from "@mui/material"

export const PictureWithVideoBanner = (props) => {
    const { item, app, translations, language } = props
    const { width } = useWindowDimensions()

    return (
        <Box
            sx={{
                width: width,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: width > 600 ? "row" : "column",
            }}
        >
            <img
                src={item?.image}
                style={{
                    width: width > 600 ? "50%" : "100%",
                    objectFit: "cover",
                    zIndex: 2,
                    aspectRatio: "1/1",
                }}
            />
            <video
                src={item?.video}
                autoPlay
                loop
                controls
                muted
                controlsList="nodownload"
                style={{
                    width: width > 600 ? "50%" : "100%",
                    objectFit: "cover",
                    zIndex: 2,
                    aspectRatio: "1/1",
                }}
            ></video>
        </Box>
    )
}
