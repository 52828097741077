import { Box, Button, DialogTitle, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import * as Yup from "yup"
import { Form, Formik } from "formik"

export const GiftCardCheckoutFifthStep = (props) => {
    const { language, translations, setStep } = props
    const { width } = useWindowDimensions()
    const [submitted, setSubmitted] = useState(false)
    const [cardType, setCardType] = useState(null)

    const validation = Yup.object().shape({
        friendEmail: Yup.string()
            .required(translations?.friend_email_is_required)
            .email(translations?.invalid_email_format),
        confirmFriendEmail: Yup.string()
            .required(translations?.friend_email_is_required)
            .email(translations?.invalid_email_format)
            .oneOf([Yup.ref('friendEmail')], translations?.emails_must_match),
    })
    
    const validationForMe = Yup.object().shape({
        yourEmail: Yup.string().required(translations?.email_is_required).email(translations?.invalid_email_format),
        confirmYourEmail: Yup.string()
            .required(translations?.email_is_required)
            .email(translations?.invalid_email_format)
            .oneOf([Yup.ref('yourEmail')], translations?.emails_must_match),
    })


    const getValues = (values) => {
        localStorage.setItem("gift-card-checkout-fifth-step", JSON.stringify(values))
        setStep(c => c + 1)
    }

    const showError = (errors, field) => {
        return errors[field] && submitted
    }

    useEffect(() => {
        const first = localStorage.getItem("gift-card-checkout-first-step")
        const pFirst = JSON.parse(first)
        if (typeof pFirst !== "object") {
            setCardType("forMe")
        }
    }, [])

    return (
        <Box
            sx={{
                width: width > 600 ? "500px" : "80%",
                margin: "0 auto",
                border: "1.5px solid gray",
                borderRadius: 2,
                padding: 5,
                marginBottom: 7,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                "@media (max-width:600px)": { padding: 3, paddingTop: 5, paddingBottom: 5 },
            }}
        >
            <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"} sx={{ marginBottom: 2 }}>
                {translations?.where_is_it_going}
            </DialogTitle>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                {cardType === "forMe" ? (
                    <Formik
                        validationSchema={validationForMe}
                        initialValues={{ yourEmail: "", confirmYourEmail: "" }}
                        onSubmit={(values) => {
                            getValues(values)
                        }}
                    >
                        {({ values, handleSubmit, handleChange, errors }: any) => (
                            <Form
                                onSubmit={(values) => {
                                    handleSubmit(values)
                                }}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: submitted ? "10px" : "30px",
                                }}
                            >
                                <TextField
                                    id="outlined-basic-2"
                                    label={translations?.your_email}
                                    variant="outlined"
                                    name="yourEmail"
                                    value={values.yourEmail}
                                    onChange={(event) => {
                                        setSubmitted(false)
                                        handleChange(event)
                                    }}
                                    sx={{
                                        marginTop: 3,
                                        marginBottom: 3,
                                        width: "90%",
                                        margin: "0 auto",
                                        "@media (max-width:600px)": { width: "100%" },
                                    }}
                                />
                                {showError(errors, "yourEmail") && (
                                    <Typography
                                        sx={{
                                            color: "red",
                                            textAlign: "left",
                                            marginLeft: 3,

                                            "@media (max-width:600px)": { marginLeft: 1 },
                                        }}
                                    >
                                        {errors.yourEmail}
                                    </Typography>
                                )}
                                <TextField
                                    id="outlined-basic-6"
                                    label={translations?.confirm_your_email}
                                    variant="outlined"
                                    name="confirmYourEmail"
                                    value={values.confirmYourEmail}
                                    onChange={(event) => {
                                        setSubmitted(false)
                                        handleChange(event)
                                    }}
                                    sx={{
                                        marginTop: 3,
                                        marginBottom: 3,
                                        width: "90%",
                                        margin: "0 auto",
                                        "@media (max-width:600px)": { width: "100%" },
                                    }}
                                />
                                {showError(errors, "confirmYourEmail") && (
                                    <Typography
                                        sx={{
                                            color: "red",
                                            textAlign: "left",
                                            marginLeft: 3,
                                            "@media (max-width:600px)": { marginLeft: 1 },
                                        }}
                                    >
                                        {errors.confirmYourEmail}
                                    </Typography>
                                )}
                                <Button
                                    type="submit"
                                    onClick={() => setSubmitted(true)}
                                    sx={{
                                        width: "90%",
                                        margin: "0 auto",
                                        backgroundColor: "black",
                                        color: "white",
                                        padding: "10px 50px 10px 50px",
                                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                                        textTransform: "none",
                                        "@media (max-width:600px)": { width: "100%" },
                                        "&:hover": { backgroundColor: "black" },
                                    }}
                                >
                                    {translations?.add_to_cart}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <Formik
                        validationSchema={validation}
                        initialValues={{ friendEmail: "", confirmFriendEmail: "" }}
                        onSubmit={(values) => {
                            getValues(values)
                        }}
                    >
                        {({ values, handleSubmit, handleChange, errors }: any) => (
                            <Form
                                onSubmit={(values) => {
                                    handleSubmit(values)
                                }}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: submitted ? "10px" : "30px",
                                }}
                            >
                                <TextField
                                    id="outlined-basic-1"
                                    label={translations?.friends_email}
                                    variant="outlined"
                                    name="friendEmail"
                                    value={values.friendEmail}
                                    onChange={(event) => {
                                        setSubmitted(false)
                                        handleChange(event)
                                    }}
                                    sx={{
                                        marginTop: 3,
                                        marginBottom: 3,
                                        width: "90%",
                                        margin: "0 auto",
                                        "@media (max-width:600px)": { width: "100%" },
                                    }}
                                />
                                {showError(errors, "friendEmail") && (
                                    <Typography
                                        sx={{
                                            color: "red",
                                            textAlign: "left",
                                            marginLeft: 3,

                                            "@media (max-width:600px)": { marginLeft: 1 },
                                        }}
                                    >
                                        {errors.friendEmail}
                                    </Typography>
                                )}
                                <TextField
                                    id="outlined-basic-2"
                                    label={translations?.confirm_friends_email}
                                    variant="outlined"
                                    name="confirmFriendEmail"
                                    value={values.confirmFriendEmail}
                                    onChange={(event) => {
                                        setSubmitted(false)
                                        handleChange(event)
                                    }}
                                    sx={{
                                        marginTop: 3,
                                        marginBottom: 3,
                                        width: "90%",
                                        margin: "0 auto",
                                        "@media (max-width:600px)": { width: "100%" },
                                    }}
                                />
                                {showError(errors, "confirmFriendEmail") && (
                                    <Typography
                                        sx={{
                                            color: "red",
                                            textAlign: "left",
                                            marginLeft: 3,

                                            "@media (max-width:600px)": { marginLeft: 1 },
                                        }}
                                    >
                                        {errors.confirmFriendEmail}
                                    </Typography>
                                )}
                                <Button
                                    type="submit"
                                    onClick={() => setSubmitted(true)}
                                    sx={{
                                        width: "90%",
                                        margin: "0 auto",
                                        backgroundColor: "black",
                                        color: "white",
                                        padding: "10px 50px 10px 50px",
                                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                                        textTransform: "none",
                                        "@media (max-width:600px)": { width: "100%" },
                                        "&:hover": { backgroundColor: "black" },
                                    }}
                                >
                                    {translations?.add_to_cart}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>
        </Box>
    )
}
