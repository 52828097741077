import { Box, Grid, Typography } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import { QueryOptions } from '../../helpers/query.options'
import { cartIncludesGiftCart, getCartTotalWithStock } from '../../helpers/utilities'
import { Address } from '../../models/address/address'
import { addressService } from '../../models/address/service'
import AddressForm from '../Address/AddressForm'
import { AddressSelector } from '../Address/AddressSelector'
import { BlackButton } from '../Button/BlackButton'
import { Title } from '../Typography/Title'
import { Animated } from "react-animated-css";
import { COLORS } from '../../constants/colors'

export const CheckoutSecondStepComponent = (props: any) => {
    const { app, language, translations, onSetAddress, setStep, globalAddress } = props
    const [addresses, setAddresses] = useState<Address[]>([])
    const [address, setAddress] = useState<Address | null>()
    const [animateError, setAnimateError] = useState(false)
    const [addressValues, setAddressValues] = useState<Address>()
    const [animateAddressError, setAnimateAddressError] = useState(false)


    useEffect(() => {
        loadAddresses()
    }, [])

    useEffect(() => {
        if (globalAddress) {
            setAddress(globalAddress)
        }
    }, [globalAddress])

    const loadAddresses = () => {
        const options = new QueryOptions()
        addressService.list(options).then((resp: any) => {
            setAddresses(resp.results)
        })
    }

    const RenderAddressForm = useCallback(() => {
        return <AddressForm
            {...props}
            language={language}
            cartIncludesGiftCart={cartIncludesGiftCart(app.cart)? true: false}
            onValuesChange={(values)=> {
                setAddressValues(values)
            }}
            address={address}
            translations={translations}
            mode={"checkout"}
            onClose={() => { }}
            onReload={(address) => {
                onSetAddress(address)
                setAddress(address)
                setStep(3)
            }} item={address} />
    }, [address, app.cart])

    const updateAddress = (addr) => {
        
        const address = new Address()
        address.city = addressValues.city
        address.firstName = addressValues.firstName
        address.lastName = addressValues.lastName
        address.personalId = addressValues.personalId
        address.phone = addressValues.phone
        address.street = addressValues.street
        address.comment = addressValues.comment

        address.id = addressValues.id
        addressService.update(address).then((resp) => {
            onSetAddress(addr)
            setAddress(addr)
        })

    }

    return <Grid container style={{ padding: window.innerWidth > 1200 ? 30 : 0 }}>
        <Grid item lg={9} style={{ flexDirection: 'column', display: 'flex', }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingLeft: 4, }}>
                <Animated animationIn="shake" animationOut="fadeIn"
                    animationInDuration={1200}
                    isVisible={animateError}>
                    <Title
                        uppercase={true}
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        color={'#333'}
                        fontSize={19}
                        fontWeight={'bold'}
                        text={translations?.choose_address} />

                </Animated>
            </Box>
            <Box sx={{ padding: window.innerWidth > 1200 ? 5 : 2.5, paddingTop: 1, marginBottom: 3  }}>
                <AddressSelector
                    {...props}
                    translations={translations}
                    addresses={addresses} address={address} setAddress={(address: Address) => {
                        setAddress(address)
                    }} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingLeft: 4}}>
                <Title
                    uppercase={true}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'#333'}
                    fontSize={19}
                    fontWeight={'bold'}
                    text={!address ? translations?.add_address : translations.selected_address} />

            </Box>

            <RenderAddressForm />

        </Grid>
        <Grid item lg={3} xs={12} style={{ padding: window.innerWidth > 1200 ? 10 : 20, paddingTop: 0, paddingBottom: window.innerWidth > 1200 ? 0 : 50 }}>
            <Box sx={{
                width: '100%',
                borderRadius: 2,
                height: 130,
                marginTop: 2,
                paddingTop: 1.2,
                boxShadow: '0px 0px 4px rgba(0,0,0,.5)',
            }}>
                <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 20, paddingRight: 20 }}>
                    <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                        <Typography
                            component="h4"
                            variant="subtitle2"
                            className={'uppercase'}
                            style={{ fontWeight: 'bold', fontSize: 17, textTransform: 'uppercase' }}>
                            {translations?.sum}
                        </Typography>
                    </div>
                    <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                        <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 17, }}>
                            {getCartTotalWithStock(app.cart)} ₾
                        </Typography>
                    </div>
                </div>
                <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex', marginTop: 20, padding: 20 }}>
                    <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                        <Typography
                            component="h4"
                            variant="subtitle2"
                            className={'uppercase'}
                            style={{ fontWeight: '500', fontSize: 15, textTransform: 'uppercase' }}>
                            {translations?.you_have_items_in_your_cart ? translations?.you_have_items_in_your_cart.replace("%s", app.cart?.length) : ''}
                        </Typography>
                    </div>

                </div>
            </Box>

            <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex', padding: 20, marginTop: 20 }}>
                <BlackButton
                    title={translations.next}
                    onClick={() => {
                        if (address) {
                            if (!address.city){
                                setAnimateAddressError(true)
                                return
                            }
                            if (addressValues) {
                                updateAddress(address)
                            } else {
                                onSetAddress(address)
                                setAddress(address)
                            }
                            setStep(3)
                        } else {
                            setAnimateError(true)
                            setTimeout(() => {
                                setAnimateError(false)
                            }, 5000)
                        }
                    }}
                />
            </div>
            {animateAddressError && <Title
                    uppercase={true}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={COLORS.RED}
                    fontSize={14}
                    fontWeight={'400'}
                    text={`${translations?.city} ${translations?.is_required}`} />}
            <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex', minHeight: 70 }}></div>
        </Grid>
    </Grid >

}