import { ResourceProvider } from "../../providers/resource.provider"
import { Branch } from "./branch"
import { BranchSerializer } from "./serializer"

export class BranchProvider extends ResourceProvider<Branch> {
    constructor() {
        super("rest_branch", new BranchSerializer())
    }
}

export const branchService = new BranchProvider()
