import React, { useState, useEffect } from "react";
import { Box } from "@mui/material"
import { Title } from "../Typography/Title";


export const SearchProductComponent = (props: any) => {
    const { item, onClick } = props
    const [image, setImage] = useState(null)

    useEffect(()=> {
        if (item.mainImage) {
            setImage(item.mainImage)
        }
    }, [item]) 


    return <Box sx={{ cursor: 'pointer', width: '100%', textAlign: 'left', padding: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} onClick={onClick}>
        <Box sx={{ width: '20%' }}>
            <img
                src={image}
                style={{ width: 60 }}
                onError={() => {
                    setImage("https://via.placeholder.com/250x250")
                }} />

        </Box>
        <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <Title text={`${item.brand.title}`} fontSize={15} uppercase={true} fontWeight='800' color={"black"} />
            <Title text={`${item.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"black"} />
        </Box>
    </Box>
}