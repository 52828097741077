import { ResourceProvider } from "../../providers/resource.provider"
import { ProductPromoPage } from "./ProductPromoPage";
import { Product } from "./product"
import { ProductPromoPageSerializer, ProductSerializer } from "./serializer";



export class ProductProvider extends ResourceProvider<Product> {
    constructor() {
        super("rest_product", new ProductSerializer());
    }
}
export class SimilarProductsProvider extends ResourceProvider<Product> {
    constructor() {
        super("rest_similar_products", new ProductSerializer());
    }
}
export class ProductPromoPageProvider extends ResourceProvider<ProductPromoPage> {
    constructor() {
        super("rest_product_promo_page", new ProductPromoPageSerializer());
    }
}

export const productService = new ProductProvider()
export const similarProductsService = new SimilarProductsProvider()
export const productPromoPageService = new ProductPromoPageProvider()

