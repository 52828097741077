import React, { useEffect, useState } from 'react'
import { MobileMenuComponent } from '../../pages/InfoPages/MobileMenuComponent'
import { QueryOptions } from '../../helpers/query.options'
import { infoPageService } from '../../models/InfoPage/service'

export const MobileAccountComponent = (props) => {
    const { app, translations, language } = props
    const [accountMenuItems, setAccountMenuItems] = useState<any>([{}])
    const loadInfoPages = async (): Promise<any> => {
        const options = new QueryOptions()
        options.sort = 'position'
        options.order = 'asc'
        options.lang = language
        
        const pages = await infoPageService.listWithCounter(options)
      
        setAccountMenuItems(pages.current.reduce((result, item) => {
          const { id, link, title, section } = item
      
          const transformedItem = { id, title, url: link }
          if (section === "about_us") {
            result.left.push(transformedItem)
          } else if (section === "my_profile") {
            result.middle.push(transformedItem)
          } else if (section === "help") {
            result.right.push(transformedItem)
          } else if (section === 'account') {
            result.account.push(transformedItem)
          }
          return result
        }, { left: [], middle: [], right: [], account: [] }))
      }

    useEffect(() => {
        loadInfoPages()
    },[translations])

    return <MobileMenuComponent accountMenuItems={accountMenuItems?.account} title={translations?.account}/>
}