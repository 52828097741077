import { useState, useEffect } from "react";


export const useWindowDimensions = () => {
const [windowDimensions, setWindowDimensions] = useState({
width: window.innerWidth,
height: window.innerHeight,
});


const onResize = () => {
setWindowDimensions({
width: window.innerWidth,
height: window.innerHeight,
});
};


useEffect(() => {
window.addEventListener("resize", onResize);
}, []);


return {
width: windowDimensions.width,
height: windowDimensions.height,
};
};
