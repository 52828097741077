import React, { Fragment, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { Transition } from '../../helpers/utilities';
import { COLORS } from '../../constants/colors';
import '../styles.scss'
import { InventoryItem } from '../Inventory/InventoryItem';
import { Title } from '../Typography/Title';
import { GoogleMapContainer } from '../map/GoogleMap';
import { Branch } from '../../models/branch/branch';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const FullWidthPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    height: '100%',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 2),
    },
}));


export const StockAvailabilityDialog = (props) => {
    const { translations, simple, setOpen = () => { }, open = false, items = [], item, selectedItems } = props
    
    const [activeBranch, setActiveBranch] = useState<Branch>()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (selectedItems.length) {
            const [bItem] = selectedItems
            setActiveBranch(bItem.branch)
        }
    }, [selectedItems])


    return <Fragment>

        <Dialog
            maxWidth='md'
            style={{
                padding: 0,
                minWidth: window.innerWidth > 1200 ? 400 : '100%',
                overflow: 'hidden',
                minHeight: window.innerHeight
            }}
            sx={{

                height: '100%',
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            PaperComponent={window.innerWidth > 1200 ? null : FullWidthPaper}
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer', zIndex: 1000 }} onClick={handleClose}>
                <CloseIcon />
            </div>
            <Box style={{
                width: window.innerWidth > 1200 ? 800 : '100%',
                height: '100%',
                overflow: 'hidden',
                padding: window.innerWidth > 1200 ? 40 : 15,
                paddingTop: 0,
                backgroundColor: COLORS.BODY_BG,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
            }}>
                <Box sx={{ height: 30, }}></Box>
                <Title text={item?.brand?.title} fontWeight="bold" fontSize={22} />
                <Title text={item?.name} fontWeight="500" fontSize={20} />
                <Title text={`${simple?.name} - ${simple?.sku}`} />
                <Box sx={{ height: 10, }}></Box>
                <Box sx={{
                    display: 'flex', flexDirection: window.innerWidth > 1200 ? 'row' : 'column',
                    height: '100%',
                    width: '100%'
                }}>
                    <Box sx={{
                        paddingTop: 3, maxHeight: 440, overflow: 'scroll',
                        height: window.innerWidth > 1200 ? '100%' : window.innerHeight / 2 - 100,
                        width: window.innerWidth > 1200 ? '50%' : window.innerWidth - 30
                    }}>
                        {selectedItems.map((item, index) => {
                            return <InventoryItem
                                {...props}
                                activeBranch={activeBranch}
                                item={item} key={index} onSelect={(branch) => {
                                    setActiveBranch(branch)
                                }} />
                        })}
                    </Box>
                    <Box sx={{
                        padding: 0,
                        height: window.innerWidth > 1200 ? 470 : window.innerHeight / 2 - 100,

                        width: window.innerWidth > 1200 ? '50%' : window.innerWidth - 30,
                        paddingTop: window.innerWidth < 1200 ? 8 : 0
                    }}>
                        {activeBranch && <GoogleMapContainer branches={[activeBranch]} translations={translations} activeBranch={activeBranch} />}
                    </Box>

                </Box>

            </Box>
        </Dialog>
    </Fragment>
}

