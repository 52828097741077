import { Serializer } from '../serializer'
import { City } from './city'

export class CitySerializer implements Serializer {
    fromJson(json: any) {
        const city = new City()
        city.uid = json.uid
        city.name = json.name
        return city
    }

    toJson(item: any) {
        const obj: any = {}        
        return obj
    }
}

export const citySerializer = new CitySerializer()