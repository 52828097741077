import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router';
import { QueryOptions } from '../../helpers/query.options';
import { topHeaderBannerService } from '../../models/HeaderRedBanner/service';
import { TopHeaderBanner } from '../../models/HeaderRedBanner/TopHeaderBanner';
import { TopHeaderBannerDialog } from './TopHeaderBannerDialog';

export const TopHeaderBannerComponent = (props) => {
    const { language, onShown } = props
    const [banner, setBanner] = useState<TopHeaderBanner>()
    const [shown, setShown] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const history = useHistory()

    useEffect(() => {
        loadBanners()
    }, [])

    const loadBanners = () => {
        const options = new QueryOptions()
        options.lang = language
        topHeaderBannerService.list(options).then(r => {
            const [bnr] = r.results
            if (bnr) {
                setBanner({ ...bnr, text: bnr.text.replace("<p>", "").replace("</p>", "") })
                onShown(true)
                setShown(true)
            } else {
                onShown(false)
                setShown(false)
            }
        })
    }
    return banner ? <Fragment>
        <TopHeaderBannerDialog
            setOpen={() => {
                setDialogOpen(false)
            }}
            open={dialogOpen}
            content={banner.content}
            />
        <div
            onClick={() => {
                if (banner.link !== "0") {
                    history.push(banner.link)
                } else {
                    setDialogOpen(true)
                }
            }}
            dangerouslySetInnerHTML={{ __html: banner.text.replace(/\\n/g, "").replace(/\\r/g, "") }
            } style={{
                height: 40,
                color: '#444',
                overflow: 'hidden',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                overflowY: 'scroll',
                fontSize: 14,
                width: window.innerWidth > 1200 ? '94%': '100%',
                paddingRight: 10,
                marginTop: 10,
                marginBottom: 10,
                textAlign: 'center'
            }}></div >
    </Fragment>

        : <></>
}