import { Brand } from "../brand/brands";
import { Product } from "../product/product";
import { ProductLine } from "../product_line/ProductLine";
import { Resource } from "../resource";

export class MainPageNewProductSection extends Resource{
    brand:       Brand;
    buttonText: string;
    text:        string;
    title:       string;
    image: string;
    type:        string;
    url:         string;
    videoLink:  string;
}
export class MainPageSlide extends Resource{
    image: string
    sort: number
    subtitle: string
    title: string
    type: string
    url: string
    imageMobile: string
    embeddedItems: EmbeddedSliderItem[]
    textPosition: TextPosition
}
export class MainPageProductBanner extends Resource{
    image: string
    product: Product
}

export class MainPageProductSlider extends Resource {
    products: Product[];
    title:    string;
}
export class MainPageProductSectionWithBG extends Resource {

    buttonText: string
    image: string
    text: string
    title: string
    url: string

}

export class MainPageProductLineSection extends Resource {
    buttonText:   string;
    image:     string;
    productLines: ProductLine[];
    text:          string;
    title:         string;
    url:           string;
}


export class EmbeddedSliderItem  extends Resource {
    image: string;
    sort:     number | null;
    title:    string;
    url:      string;
}

export class TextPosition extends Resource {
    subtitlePositionX: number | null;
    subtitlePositionY: number | null;
    subtitleSize:       number | null;
    titlePositionX:    number | null;
    titlePositionY:    number | null;
    titleSize:          number | null;
}


export class  MainPageProductSliderWithBanner extends Resource {
    buttonText:    string;
    headerTitle: string
    url: string
    image:       string;
    productsLower: Product[];
    productsUpper: Product[];
    text:           string;
    title:          string;
}

