import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from "@mui/material"
import { mainPageProductSliderWithBannerService } from "../../models/MainPageBrandBanner/service"
import { QueryOptions } from "../../helpers/query.options";
import { MainPageProductSliderWithBanner } from '../../models/MainPageBrandBanner/MainPage';
import { useHistory } from 'react-router';
import { MainPageProductSliderComponent } from './MainPagProductSliderComponent';
import { COLORS } from '../../constants/colors';
import { Title } from '../Typography/Title';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Skeleton from "@mui/material/Skeleton/Skeleton";
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const MainPageProductSliderWithBannerComponent = (props: any) => {
    const { language, translations } = props
    const history = useHistory()
    const [item, setItem] = useState<MainPageProductSliderWithBanner>()
    const [loadStatus, setLoadStatus] = useState(true)
    const { width } = useWindowDimensions()

    useEffect(() => {
        let isCancelled = false
        const loadItems = () => {
            const options = new QueryOptions()
            options.lang = language
            mainPageProductSliderWithBannerService.list(options).then((resp: any) => {
                if(!isCancelled){

                    const [slider] = resp.results
                    if (slider) {
                        setItem(slider)
                    }
                }
                setLoadStatus(false)
            })
        }
        loadItems()

        return () => {
            isCancelled = true
        }
    }, [])
    return item ? <Box sx={{ flex: 1, alignItems: 'flex-start', display: 'flex', padding: 0, flexDirection: 'column' }}>
        <div style={{ display: 'flex', width: '100%', paddingBottom:  window.innerWidth > 1200 ? 60 :  90, height: window.innerWidth > 1200 ? 340 : 300 }}>
            <MainPageProductSliderComponent
                {...props}
                imageHeight={"70%"}
                width={window.innerWidth > 1200 ? 1200 : window.innerWidth}
                language={language}
                translations={translations}
                slider={{ products: item.productsUpper, title: item.headerTitle }}
            />
        </div>
        {<div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', flexDirection: window.innerWidth > 1200 ? 'row' : 'column', padding: window.innerWidth > 1200 ? 20 : 0 }}>
            {item?.image && <img alt={item.headerTitle} src={item.image} style={{ width: window.innerWidth > 1200 ? '50%' : '100%' }} />}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                height: '380px',
                maxHeight: '380px',
                paddingLeft: '30px',
                '@media (max-width:464px)': {
                    height: '250px',
                    marginBottom: 11,
                    paddingTop: 2,
                    gap: 3
                }
            }}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 0 }}>
                    <Title
                        text={item?.title}
                        fontSize={20}
                        color={"#333"}
                        uppercase={true}
                        className="roboto"
                        fontWeight='600'
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'} />

                </Box>
                <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                   {width > 600 ?
                        <Title
                            text={item?.text}
                            fontSize={15}
                            language={language}
                            color={"#333"}
                            uppercase={true}
                            className="roboto"
                            fontWeight='400'
                            fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        />  :
                        <Typography 
                            variant='body1'
                            className='uppercase'
                            sx={{
                                textAlign: 'left',
                                maxWidth: width - 50,
                                fontFamily: language === 'geo' ? 'FiraGo' : 'Roboto',
                                textTransform: 'uppercase',
                                fontWeight: '400'
                            }}
                        >
                            {item?.text.length > 200 ? `${item?.text.substring(0, 200)}...` : item?.text}
                        </Typography>
                    }
                </Box>

                <Button
                    onClick={() => {
                        history.push(item.url)
                    }}
                    sx={[{
                        padding: 2,
                       
                        '&:hover': {
                            color: 'white',
                            backgroundColor: COLORS.BG_GRAY_PURPLE,
                        },
                        '@media (max-width:464px)': {
                            width: '90%'
                        },
                    }, { backgroundColor: COLORS.BG_GRAY_PURPLE, color: 'white' }]}>
                    <Title
                        text={item?.buttonText}
                        fontSize={window.innerWidth > 420 ? 18 : 14}
                        color={"white"}
                        uppercase={true}
                        className="roboto"
                        fontWeight='400'
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'} />
                    <ChevronRightIcon style={{ color: 'white' }} />
                </Button>

            </Box>
        </div>}
        <div style={{ display: 'flex', width: '100%', height: window.innerWidth > 1200 ? 340 : 300, marginTop: -30, }}>
            <MainPageProductSliderComponent
                width={window.innerWidth > 1200 ? 1200 : window.innerWidth}
                translations={translations}
                {...props}
                slider={{ products: item.productsLower, title: "" }}
            />
        </div>
    </Box> : loadStatus ? <Box sx={{ flex: 1, alignItems: 'flex-start', display: 'flex', padding: 0, flexDirection: 'column' }}>
        <div style={{ display: 'flex', width: '100%', paddingBottom: 60, height: window.innerWidth > 1200 ? 340 : 300 }}>
            <MainPageProductSliderComponent
                {...props}
                imageHeight={"70%"}
                width={window.innerWidth > 1200 ? 1200 : window.innerWidth}
                language={language}
                translations={translations}
            />
        </div>
        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', flexDirection: window.innerWidth > 1200 ? 'row' : 'column', padding: window.innerWidth > 1200 ? 20 : 0 }}>
            {window.innerWidth > 1200 && <Skeleton
                style={{ display: 'flex', width: window.innerWidth > 1200 ? '50%' : window.innerWidth - 30, height: window.innerWidth > 1200 ? 400 : 0, padding: window.innerWidth > 1200 ? 0 : 15 }}
            />}
            <Box sx={{ width: 300 }}>
                <Skeleton />
                <Skeleton style={{ marginTop: 100, width: 550 }} animation="wave" />
                <Skeleton style={{ width: 520 }} animation="wave" />
                <Skeleton style={{ width: 550 }} animation="wave" />
                <Skeleton animation={false} style={{ width: 510 }} />
            </Box>
        </div>
        <div style={{ display: 'flex', width: '100%', height: window.innerWidth > 1200 ? 340 : 300, marginTop: -30, }}>
            <MainPageProductSliderComponent
                width={window.innerWidth > 1200 ? 1200 : window.innerWidth}
                translations={translations}
                {...props}
            />
        </div>
    </Box>
        : <></>
}