

import React, { useEffect } from 'react';
import { Box } from "@mui/material"
import { Title } from '../Typography/Title';
import { useHistory } from 'react-router';
import "../../App.css"

export const MobileMainPageProductBannerComponent = (props: any) => {
    const { item } = props
    const history = useHistory()
    useEffect(() => {
    }, [])

    return item ? <Box sx={{ width:'auto', alignItems: 'center', display: 'flex', padding: 3, paddingTop: 10, flexDirection: 'column', cursor: 'pointer' }}
        onClick={() => {
            history.push(`/product/${item.product.id}`)
        }}>
        <Box style={{width:'100%' ,height: 'auto'}}>
            <img src={item.image} style={{ width: '96%', height: '96%'}} className="hoverZoom"/>

        </Box>
        <Box style={{ paddingTop: 20 }}>
            <Title text={`${item.product.brand.title}`} fontSize={15} uppercase={true} fontWeight='600' color={"black"} />
        </Box>
        <Box style={{ paddingTop: 10 }}>
            <Title text={`${item.product.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"black"} />
        </Box>
        <Box style={{ paddingTop: 10 }}>
        {item.product?.specialPrice && item.product.specialPrice < item.product.price ? <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <Title text={`${item.product ? item.product.price: item.product.price}₾`} fontSize={20} uppercase={true} fontWeight='400' color={"black"} style={{ marginRight: 5, textDecorationLine: 'line-through' }} />
                    <Title text={`${item.product ? item.product.specialPrice: item.product.specialPrice}₾`} fontSize={20} uppercase={true} fontWeight='400' color={"red"} />
                </div> : <Title text={`${item.product? item.product.price: item.product.price} ₾`} fontSize={20} uppercase={true} fontWeight='400' fontFamily='Roboto' color={"#000"} className="roboto" />}
        </Box>
    </Box> : <></>
}