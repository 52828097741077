import React from 'react'
import { connect } from 'react-redux'
import { MobileMyProfileComponent } from '../../components/myprofile/MobileMyProfileComponent'

const MobileMyProfilePage = (props) => <MobileMyProfileComponent {...props} />

const mapStateToProps = (state: any) => {
    return {
        app: state.app,
        translations: state.translations,
        language: state.language,
    }
}

export default connect(mapStateToProps, {})(MobileMyProfilePage)

