import { Box, Grid } from '@mui/material'
import React, { } from 'react'
import { connect } from 'react-redux'
import { ProductFavoritesComponent } from '../components/Product/ProductFavoritesComponent'
import { addToCart, loadFavorites, removeFavoriteItem } from '../helpers/utilities'
import { setCart, setConfirmation, setFavorites, setSnackBar } from '../models/application/applicationActions'
import { cartService } from '../models/cart/service'
import { favoritesService } from '../models/favorite/service'
import { useWindowDimensions } from '../hooks/useWindowDimensions'
import { useHistory } from 'react-router'
import ArrowBackIcon from "@mui/icons-material/ArrowBack"


export const FavoritesPage = (props) => {
    const { app, setSnackBar, translations, auth, setConfirmation, setFavorites, setCart } = props
    const {width} = useWindowDimensions()
    const history = useHistory()

    const onMoveToCart = (confirmation) => {
        const { item } = confirmation
        const [simple] = confirmation.item.product.simpleProducts.filter(i => i.sku === item.sku)
        addToCart(item.product, simple.sku, simple, app, auth, 1).then((r: any) => {
            setCart(r)
            localStorage.setItem("cart", JSON.stringify(r))
            setSnackBar({ ...app.snackBar, open: true, text: `${item?.name} ${translations?.has_been_added_to_cart}`, bColor: "rgb(83, 148, 96)" })
            setTimeout(() => {
                onRemoveFavorite(confirmation)
            }, 1200)
        })
    }

    const onRemoveFavorite = (confirmation) => {
        if (auth.loggedIn) {
            favoritesService.delete(confirmation.item.sku).then(() => {
                loadFavorites(setFavorites)
                setConfirmation({ ...confirmation, open: false })
                setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted_from_your_favorites}`, bColor: "rgb(83, 148, 96)" })
            })
        } else {
            removeFavoriteItem(confirmation.item.sku, app, setFavorites)
            setConfirmation({ ...confirmation, open: false })
            setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted_from_your_favorites}`, bColor: "rgb(83, 148, 96)" })
        }
    }

    const onRemoveItem = (confirmation) => {
        if (auth.loggedIn) {
            cartService.delete(confirmation.item.sku).then(() => {

                setConfirmation({ ...confirmation, open: false })
                setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted}`, bColor: "rgb(83, 148, 96)" })
            })
        } else {

            setConfirmation({ ...confirmation, open: false })
            setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted}`, bColor: "rgb(83, 148, 96)" })
        }
    }

    return <Grid container style={{ padding: window.innerWidth > 1200 ? 30 : 10 }}>
        {width < 600 && (
                <Box sx={{ display: "flex", justifyItems: "flex-start" }}>
                    <ArrowBackIcon
                        onClick={() => history.goBack()}
                        sx={{ color: "black", fontSize: 35, paddingTop: 4 }}
                    />
                </Box>
            )}
        <Grid item lg={2} sm={12}></Grid>
        <Grid item lg={8} sm={12}>
            {app?.favorites?.sort((n1, n2) => { if (n1.created > n2.created) { return -1; } if (n1.created < n2.created) { return 1; } return 0; }).map((item, index) => {
                return <Box key={index} sx={{
                    width: '100%',
                    borderRadius: 2,
                    marginTop: 2,
                    boxShadow: '0px 0px 4px rgba(0,0,0,.5)',
                }}>
                    {window.innerWidth > 1200 ?
                        <ProductFavoritesComponent
                            onUpdateCart={() => { }}
                            item={item}

                            onMessage={(qty) => {
                                setSnackBar({ ...app.snackBar, open: true, text: `${translations.only_available} ${qty} ${translations.items}`, bColor: "rgb(83, 148, 96)" })
                            }}
                            translations={translations}
                            redirect={() => { }}
                            onRemove={(item) => {
                                setConfirmation({
                                    open: true,
                                    item,
                                    type: 'removeItemFromFavorites',
                                    textAccept: `${translations?.delete}`,
                                    text: `${translations?.do_you_really_want_to_delete_from_favorites}`,
                                    title: item.product.name,
                                    onAgreed: onRemoveFavorite
                                })
                            }}
                            onMoveToCart={(item) => {
                                setConfirmation({
                                    open: true,
                                    item,
                                    type: 'modeItemToCart',
                                    textAccept: `${translations?.move}`,
                                    text: `${translations?.do_you_really_want_to_move_to_cart}`,
                                    title: item.product.name,
                                    onAgreed: onMoveToCart
                                })
                            }}
                            key={index} />
                        : <></>


                    }
                </Box>
            })}
        </Grid>

    </Grid >
    /* return <Box sx={{}}>
        <ul style={{listStyle: 'none', padding: 2}}>
        {app?.favorites?.map((item, index) => {
            return <FavoriteItemComponent item={item} redirect={() => {}} onRemove={() => {}} key={index} />
        })}
        </ul>
    </Box> */
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        loggedIn: state?.auth?.loggedIn,
        translations: state.translations
    }
}

export default connect(mapStateToProps, { setSnackBar, setConfirmation, setFavorites, setCart })(FavoritesPage)