import { SET_LANGAUGE } from "../language/languageActions";
import { TranslationService } from "./service";
import { SET_TRANSLATIONS } from "./translationActions";

export const translationsService = new TranslationService()

const initialState = {
    translations: {},
    language: 'geo'
}

export const translationsReducer = (state = initialState, action: { type: any; content: any }) => {
    switch(action.type) {
        case SET_TRANSLATIONS:
            const {translations} = action.content
            return translations
        case SET_LANGAUGE:
            return {...state, language: action.content}
        default: 
            return state;
    }
}