import React, { Fragment, useEffect, useState } from "react"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { infoPageContentService } from "../../models/InfoPage/service"
import { QueryOptions } from "../../helpers/query.options"
import { Box, Fade } from "@mui/material"
import { OurBranches } from "../InfoPages/OurBranches"
import ContactForm from "../Forms/ContactForm"

const Content = (props) => {
    switch (props.page) {
        case "branches":
            return (
                <OurBranches
                    {...props}
                    setSnackBar={props.setSnackBar}
                    app={props.app}
                    setConfirmation={props.setConfirmation}
                />
            )
        case "contact":
            return <ContactForm {...props} />
        case "news":
            return  <>
            {window.innerWidth < 1200 ? <h4>{props.content?.title}</h4> : <></>}
            {props.content?.text || props.content?.sections?.length > 0 ? (
                <Fragment>
                    <Box sx={{ padding: 2, textAlign: "left", display: 'flex', '@media (max-width:464px)': {flexDirection: 'column'} }}>
                        {props.content?.display !== "image" && (
                            <Box
                                dangerouslySetInnerHTML={{
                                    __html: props.content?.text.replace(/\\n/g, "").replace(/\\r/g, ""),
                                }}
                                style={{ fontSize: 14, padding: 20 }}
                            />
                        )}
                        {props.content?.display !== "text" && (
                            <img style={{ width: props.width > 600 ? "35%" : '100%', marginBottom: 70, marginTop: 30 }} src={props.content?.image} />
                        )}
                    </Box>

                    {props.content?.sections?.length > 0 && (
                        <Fragment>
                            {props.content?.sections.map((section, index) => {
                                return (
                                    <Box sx={{ padding: 2 }}>
                                        <h4>{section.title}</h4>
                                        {section.display !== "text" && (
                                            <img style={{ width: "100%" }} src={section.image} />
                                        )}
                                        {section.display !== "image" && (
                                            <Box
                                                key={index}
                                                dangerouslySetInnerHTML={{
                                                    __html: section?.text
                                                        .replace(/\\n/g, "")
                                                        .replace(/\\r/g, ""),
                                                }}
                                                style={{ fontSize: 14, padding: 10 }}
                                            />
                                        )}
                                    </Box>
                                )
                            })}
                        </Fragment>
                    )}
                </Fragment>
            ) : (
                <h4 style={{ textAlign: "center", width: props.width > 600 ? "auto" : props.width }}>
                    {props.translations?.content_not_found}
                </h4>
            )}
        </>

        default:
            return props.content ? (
                <>
                    {window.innerWidth < 1200 ? <h4>{props.content?.title}</h4> : <></>}
                    {props.content?.text || props.content?.sections?.length > 0 ? (
                        <Fragment>
                            <Box sx={{ padding: 2, textAlign: "left" }}>
                                {props.content?.display !== "image" && (
                                    <Box
                                        dangerouslySetInnerHTML={{
                                            __html: props.content?.text.replace(/\\n/g, "").replace(/\\r/g, ""),
                                        }}
                                        style={{ fontSize: 14, padding: 20 }}
                                    />
                                )}
                                {props.content?.display !== "text" && (
                                    <img style={{ width: "100%" }} src={props.content?.image} />
                                )}
                            </Box>

                            {props.content?.sections?.length > 0 && (
                                <Fragment>
                                    {props.content?.sections.map((section, index) => {
                                        return (
                                            <Box sx={{ padding: 2 }}>
                                                <h4>{section.title}</h4>
                                                {section.display !== "text" && (
                                                    <img style={{ width: "100%" }} src={section.image} />
                                                )}
                                                {section.display !== "image" && (
                                                    <Box
                                                        key={index}
                                                        dangerouslySetInnerHTML={{
                                                            __html: section?.text
                                                                .replace(/\\n/g, "")
                                                                .replace(/\\r/g, ""),
                                                        }}
                                                        style={{ fontSize: 14, padding: 10 }}
                                                    />
                                                )}
                                            </Box>
                                        )
                                    })}
                                </Fragment>
                            )}
                        </Fragment>
                    ) : (
                        <h4 style={{ textAlign: "center", width: props.width > 600 ? "auto" : props.width }}>
                            {props.translations?.content_not_found}
                        </h4>
                    )}
                </>
            ) : (
                <h4 style={{ textAlign: "center", width: props.width > 600 ? "auto" : props.width }}>
                    {props.translations?.content_not_found}
                </h4>
            )
    }
}

const RenderContent = React.forwardRef(function (props: any, ref) {
    return (
        <div ref={ref} {...props}>
            <Content {...props} />
        </div>
    )
})

export const AboutUsContent = (props) => {
    const { language, page } = props

    const [content, setContent] = useState<any>()
    const [init, setInit] = useState(false)

    const { width } = useWindowDimensions()

    const loadInfoPage = async (id): Promise<any> => {
        setInit(false)
        const options = new QueryOptions()
        options.lang = language
        const page = (await infoPageContentService.read(id, options)) as any
        setContent(page)
        setInit(true)
    }

    useEffect(() => {
        setContent(null)
        loadInfoPage(page)
    }, [page])

    return (
        <Box sx={{ width: width > 600 ? width : "auto" }}>
            <Fade in={init} timeout={700} style={{ width: "100%" }}>
                <RenderContent {...props} content={content} width={width} />
            </Fade>
        </Box>
    )
}
