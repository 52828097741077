import { NameIdItem } from "../common/common";
import { Resource } from "../resource";


export class Event extends Resource {
    attendanceCount: number | any
    created:          string | any
    title:          string | any
    date:             string | any
    description:      string | any
    image:            string | any
    name:             string | any
    refusalCount:    number | any
}
export class EventSubscription extends Resource {
    event: Event
    created: string | any
    status: NameIdItem
}

