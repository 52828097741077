import React from "react"
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constants/colors";
import CloseIcon from '@mui/icons-material/Close';
import { CartItem } from "../../models/cart/cart";
import { Heart } from "../../assets/icons/Heart";
import { Box } from "@mui/material";

export const FavoriteItemComponent = (props) => {
    const { item, redirect, onRemove, translations } = props
    const classes = useStyles();

    const onRedirect = (item: CartItem) => {
        redirect(`/product/${item.product.id}`)
    }
    return <li
        className={classes.listItem}>
        <div style={{ flexDirection: 'row', display: 'flex', paddingTop: 20 }}>
            <div style={{ flexDirection: 'column', width: '17%', cursor: 'pointer' }} onClick={() => onRedirect(item)}>
                <img src={item.product.mainImage} style={{ width: 30 }} />
            </div>
            <Box sx={{ 
                '&:hover': {
                    textDecoration: 'underline'
                },
                flexDirection: 'column', width: '55%', cursor: 'pointer', display: 'flex' }} onClick={()=> onRedirect(item)}>
                <span style={{fontWeight: 'bold'}}>{item.product.brand.title}</span>
                <span>{item.product.name}</span>
            </Box>
            <div style={{ flexDirection: 'column', width: '30%', textAlign: 'right', position: 'relative', cursor: 'pointer' }}>
                <span>{item.product.specialPrice}</span> ₾
                <div style={{}} onClick={() => {
                    onRemove(item)
                }}>
                    <CloseIcon />
                </div>
            </div>
        </div>
    </li>
}

export const FavoritesPopup = (props: any) => {
    const { favorites, redirect, removeItem, translations } = props
    return (
        <div style={{ width: '100%', alignSelf: 'center', position: 'relative', height: 345, borderRadius: 10, }}>
            <div style={{ padding: 0, }}>
                <ul style={{ listStyle: 'none', alignItems: 'flex-start', textAlign: 'left', paddingLeft: 0, paddingTop: 0, }}>
                    <li
                        style={{ color: 'black', fontFamily: 'FiraGo', fontSize: 14, fontWeight: '300', height: 30, padding: 10, marginTop: -15 }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <div style={{ top: 5, position: 'absolute', left: 15 }}>
                                <Heart width={24} height={36} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: 4, paddingLeft: 12, marginLeft: 25, paddingTop: 6 }}>{translations?.my_favorites}</div>
                        </div>
                    </li>
                    <ul style={{ listStyle: 'none', alignItems: 'flex-start', textAlign: 'left', paddingLeft: 10, paddingRight: 10, paddingTop: 5, height: 234 , maxHeight: 234, overflowY: 'auto' }}>
                        {favorites?.map((item, index) => {
                            return <FavoriteItemComponent item={item} redirect={redirect} onRemove={removeItem} key={index} />
                        })}
                    </ul>
                </ul>
            </div>
            <div
                onClick={() => redirect('/favorites')}
                style={{
                    position: 'absolute',
                    color: 'white', fontFamily: 'FiraGo', fontSize: 14, fontWeight: '300',
                    borderBottomLeftRadius: 7,
                    borderBottomRightRadius: 7,
                    width: '100%', height: 40, backgroundColor: COLORS.BG_DARK, bottom: 0, paddingTop: 16
                }}>
                {translations?.go_to_favorites}
            </div>
        </div>
    )
}
const useStyles = makeStyles({
    listItem: { color: 'black', fontFamily: 'FiraGo', fontSize: 12, fontWeight: '300' }

});
