import React, { useCallback, useState, useEffect } from 'react';
import { Grid, Box, Fade } from '@mui/material';
import { ChevronRight } from "../../assets/icons/ChevronRight"
import { COLORS } from '../../constants/colors';
import BreadCrumbsComponent from '../../components/BreadCrumbsComponent'
import { FilterChips } from '../../components/Filter/FIlterChips';
import { CategoriesList } from '../../components/Filter/CategoriesList';
import { Title } from '../../components/Typography/Title';
import { FilterComponent } from '../../components/Filter/FilterComponent';
import { ProductGrid } from '../../components/Product/ProductGrid';
import { GenericBannerComponent } from '../../components/banner/GenericBannerComponent';

export const CatalogContentDesktop = (props) => {
    const {
        breadCrumbList,
        filters,
        filterItem,
        setFilterItem,
        categories,
        cat,
        translations,
        selectedFilters,
        setFilters,
        attributeSet,
        child,
        parent,
        location,
        history,
        queryParams,
        bannerCategory
    } = props

    const [bannerLoaded, setBannerLoaded] = useState(false)
    const [page, setPage] = useState(0)
    
    useEffect(() => {
        let isCancelled = false
        if (bannerCategory?.banner && !isCancelled) {
            const img: any = new Image()
            img.src = bannerCategory.banner
            img.onload = () => {
                setTimeout(() => {
                    setBannerLoaded(true)
                }, 500)
            }
        }

        return () => {
            isCancelled = true
        }
    }, [bannerCategory])

    const RendertCategoriesList = useCallback(() => {
        return <CategoriesList
            breadCrumbs={breadCrumbList}
            categories={categories}
            cat={cat}
            child={child}
            {...props}
            parent={parent}
            location={location}
            history={history}
        />
    }, [breadCrumbList])

    return <Grid container spacing={0} className={"container"}
        style={{ width: 1200, margin: '0px auto', position: 'relative' }}>
            <GenericBannerComponent
                location={`/catalog/${cat}`}
            />
        <Grid item lg={12} className="inner-container" style={{ display: 'flex', flexDirection: 'column', height: 120, justifyContent: 'flex-start' }}>
            <div className={"container"} style={{
                display: 'flex',
                width: 1200,
                paddingTop: 10,
                alignItems: 'flex-start',
                margin: '0px auto'
            }}>
                <BreadCrumbsComponent items={breadCrumbList} />
            </div>
        </Grid>
        
        <Grid container spacing={0} className={window.innerWidth > 1200 ? "container" : ''} style={{ width: window.innerWidth > 1200 ? 1200 : '100%', margin: '0px auto', position: 'relative' }}>
            <Grid item lg={2} sm={12} style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                flex: 1,
                alignSelf: 'flex-start'
            }}>
                <RendertCategoriesList />
                <Title text={translations?.filter}
                    style={{ marginTop: 90 }}
                    fontSize={18} uppercase={true} fontWeight='bold' color={"black"} />
                <FilterComponent
                    {...props}
                    page={page}
                    filterItem={filterItem}
                    setFilters={setFilters}
                    attributeSet={attributeSet}
                    location={location}
                    selectedFilters={selectedFilters}
                />
            </Grid>
            <Grid item lg={10} sm={12} className={window.innerWidth > 1200 ? "inner-container" : ""} style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                flex: 1,
                width: '100%',
                alignSelf: 'flex-start'
            }}>
                {bannerLoaded && <div style={{marginBottom: 30, marginTop:-50}}>
            <Fade
                in={bannerLoaded}
                style={{ transformOrigin: '0 0 0' }}
                {...(bannerLoaded ? { timeout: 1000 } : {})}>
                {bannerLoaded && <img src={bannerCategory?.banner} style={{ width: '100%' }} />}
            </Fade>
        </div>}
                <Grid item lg={3} className="inner-container" ></Grid>
                <Grid item lg={9} sm={12} className="inner-container"
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <FilterChips
                        filters={filters}
                        handleDelete={(item) => {
                            setFilterItem(item)
                        }}
                    />
                </Grid>
                <ProductGrid
                    translations={translations}
                    location={location}
                    onSetPage={setPage}
                    queryParams={queryParams}
                    {...props}
                    cat={cat}
                    child={child}
                    parent={parent}
                    history={history}
                    filters={filters}
                    />
            </Grid>
        </Grid>



        <Box sx={{
            flexDirection: 'column',
            display: 'flex', alignItems: 'center', justifyContent: 'center',
            borderRadius: 20,
            position: 'absolute',
            bottom: 30,
            right: -60,
            width: 40,
            height: 40,
            backgroundColor: COLORS.BEIGE_I,
            cursor: 'pointer'
        }}>
            <div
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }}
                style={{
                    transform: 'rotate(270deg) translateX(0px) translateY(1px)',
                }}>
                <ChevronRight width={15} height={15} />
            </div>
        </Box>
    </Grid>
}