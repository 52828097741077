import { Attribute, AttributeName } from "../attribute/attribute";
import { Resource } from "../resource";

export class BrandLink extends Resource {
    title:   string;
    sort:  string;
    url:  string;
}
export class Brand extends Resource {
    banner: null | string;
    descr:  string;
    filterNames: string[]
    isNews: boolean = false;
    logo:   null | string;
    isNiche: boolean;
    isNew: boolean;
    isLuxury: boolean;
    isExclusive: boolean;
    links: BrandLink
    text:   string;
    title:  string;
    attributeSet: AttributeName[];
    attributes: Attribute[];
    bannerMobile: string;
    collections: any[];
    footerBanner: string
    footerBannerText: string
    footerBannerTitle: string
    footerBannerUrl: string
    youtubeVideoId: string
    youtubeVideoText: string
    youtubeVideoTitle: string
    youtubeVideoUrl: string
}
export class BrandLetter extends Resource {
    count: number
}



