import { ResourceProvider } from "../../providers/resource.provider"
import { CartItem } from "./cart"
import { CartItemSerializer } from "./serializer"

export class CartItemProvider extends ResourceProvider<CartItem> {
    constructor() {
        super("rest_cart", new CartItemSerializer())
    }
}

export class GiftCardCartItemProvider extends ResourceProvider<CartItem> {
    constructor() {
        super("rest_cart_gift_card", new CartItemSerializer())
    }
}

export const giftCardCartItemService = new GiftCardCartItemProvider()
export const cartService = new CartItemProvider()

