import React, { forwardRef, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import useDigitInput from 'react-digit-input';
import { makeStyles } from '@mui/styles';
import { Title } from '../Typography/Title';
import { Box, TextField } from '@mui/material';
import { User } from '../../models/user/user';
import { userAccountActivationService, userPhoneActivationService, userRegistrationService } from '../../models/user/service';
import { ChevronDown } from '../../assets/svg/chevronDown';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BlackButton } from '../Button/BlackButton';

const useStyles = makeStyles({
    input: {
        width: 40,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 4,
        textAlign: 'center',
        fontSize: 20
    },
    phoneInput: {
        width: 150,
        height: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 4,
        textAlign: 'left',
        fontSize: 17
    }
})

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const SMSDialog = (props: any) => {
    const defaultText = "Default text"
    const { open = false,
        setOpen,
        user,
        onSuccess,
        type,
        translations,
        textAccept = "Ok", text = defaultText, title = "" } = props
    const [value, onChange] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [codeActivated, setCodeActivated] = useState(false);
    const [focused, setFocused] = useState(false)
    const [wrongCredentials, setWrongCredentials] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [state, setState] = useState('init');
    const digits = useDigitInput({
        acceptedCharacters: /^[0-9]$/,
        length: 4,
        value,
        onChange,
    });

    const sendPhoneValidation = (phone, user) => {
        const usr = new User()
        usr.phone = phone
        usr.email = user.email
        usr.id = user.id
        userPhoneActivationService.create(usr).then((r: any) => {
            if (r.status === 404) {
                setWrongCredentials(true)
            }
            if (r.phone?.length && r.email?.length) {
                setState("phone")
            }
        })
    }
    const activateAccount = (phone, user, code) => {
        const usr = new User()
        usr.phone = phone
        usr.email = user.email
        usr.code = code
        usr.id = user.id
        userAccountActivationService.create(usr).then((r: any) => {
            if (r.status === 404) {
                setWrongCredentials(true)
            } else {
                
                setState("done")

            }
        })
    }

    useEffect(() => {
        if (state==="done") {
            setTimeout(() =>{
                onSuccess(type)
                handleClose()
                setState("init")
            }, 2000)
        }
    }, [state])
    useEffect(() => {
        if (!user?.phone) {
            setState("init")
        } else {
            setPhoneNumber(user.phone)
        }
    }, [user])

    const classes = useStyles();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <Dialog
        open={open}
        style={{ zIndex: 10000 }}
        TransitionComponent={Transition}
        keepMounted
        onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleClose();
            }
        }}
        aria-describedby="alert-dialog-slide-description"
    >

        <DialogContent style={{ width: 400, height: 220, overflow: 'hidden' }}>
            <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={handleClose}>
                <CloseIcon />
            </div>
            {state === 'phone' && <DialogContentText id="alert-dialog-slide-description" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', height: '100%', paddingBottom: 20 }}>
                <Box sx={{ padding: 2 }}>
                    <Title text={`${translations?.insert_sms_code}`} fontSize={21} uppercase={false} fontWeight='700' color={"#333"} style={{ marginBottom: 15 }} />
                    <Title text={`${translations?.code_sent_to_number} ${phoneNumber}`} fontSize={13} uppercase={false} fontWeight='400' color={"#333"} />
                </Box>
                <div className="input-group" style={{ display: 'flex', flexDirection: 'row' }}>
                    <input inputMode="decimal" autoFocus {...digits[0]}
                        className={classes.input} />
                    <input inputMode="decimal"  {...digits[1]}
                        className={classes.input} />
                    <input inputMode="decimal"  {...digits[2]}
                        className={classes.input} />
                    <input inputMode="decimal"  {...digits[3]}
                        className={classes.input} />

                </div>
                <Box onClick={() => {
                    setState("init")
                }}>
                    <Title text={`${translations?.send_new_code}`} fontSize={13} uppercase={false} fontWeight='400' color={"#333"} />

                </Box>

                <Box style={{ backgroundColor: 'blue', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    {<Title text={`${wrongCredentials ? translations?.wrong_credentials : ''}`}
                        style={{ textAlign: 'center', marginTop: -20 }}
                        textAlign={'center'}
                        fontSize={13} uppercase={false} fontWeight='400' color={"red"} />}

                </Box>
            </DialogContentText>}
            {state === 'done' && <DialogContentText id="alert-dialog-slide-description" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', height: '100%', paddingBottom: 10, paddingTop: 10 }}>
                <Box sx={{ padding: 2, display: 'flex', alignItems: 'center', flexDirection: 'column',}}>
                    <CheckCircleIcon sx={{fontSize: 50, color: 'black'}}/>
                    <Title text={`${translations?.your_account_has_been_activated}`} fontSize={18} uppercase={true} fontWeight='500' color={"#333"} style={{ marginTop: 35 }} />
                </Box>

            </DialogContentText>}
            {state === 'init' && <DialogContentText id="alert-dialog-slide-description" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between', height: '100%', paddingBottom: 10, paddingTop: 10 }}>
                <Box sx={{ padding: 2 }}>
                    <Title text={`${translations?.insert_phone_number}`} fontSize={21} uppercase={false} fontWeight='700' color={"#333"} style={{ marginBottom: 15 }} />
                    <Title text={`${translations?.phone_validation}`} fontSize={13} uppercase={false} fontWeight='400' color={"#333"} />

                </Box>
                <Box style={{ width: 300, height: 50, borderRadius: 8, border: '1px solid #dddddd', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box style={{
                        width: 110,
                        backgroundColor: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <img src={require('../../assets/images/flag.png')} style={{ width: 22 }} />
                        <Box style={{ marginLeft: 4 }}>
                            <ChevronDown />
                        </Box>
                        <span style={{ fontFamily: 'FiraGo', marginLeft: 10, fontSize: 16, color: 'black' }}>+995</span>
                    </Box>
                    <TextField
                        sx={{
                            width: 200,
                            margin: 0,

                            '& .MuiInput-input': {
                                height: 40,
                                fontFamily: 'FiraGo',
                                fontSize: 16
                            },
                            '& .MuiInput-root': {
                                '&:before, :after, :hover:not(.Mui-disabled):before': {
                                    borderBottom: 0,
                                    fontFamily: 'FiraGo'
                                }
                            },
                        }}

                        onChange={(evt) => {
                            setPhoneNumber(evt.target.value)
                        }}
                        onFocus={() => {
                         
                            setFocused(true)
                        }}
                        onBlur={() => {
                            setFocused(false)
                        
                        }}
                        style={{}}
                        value={phoneNumber}
                        variant="standard"
                        label={(focused || phoneNumber.length) ? "" : "--- --- ---"}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        type={"number"} />
                </Box>
                <Box style={{ backgroundColor: 'blue', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    {<Title text={`${phoneError ? translations?.phone_format_error : ''}`}
                        style={{ textAlign: 'center', marginTop: -20 }}
                        textAlign={'center'}
                        fontSize={13} uppercase={false} fontWeight='400' color={"red"} />}

                    {<Title text={`${wrongCredentials ? translations?.wrong_credentials : ''}`}
                        style={{ textAlign: 'center', marginTop: -20 }}
                        textAlign={'center'}
                        fontSize={13} uppercase={false} fontWeight='400' color={"red"} />}
                </Box>
            </DialogContentText>}
        </DialogContent>
        <DialogActions style={{display: 'flex', alignItems: 'center', flexDirection: 'column',}}>

            {state !== 'done' &&
            <BlackButton 
                style={{width:200}}
                title={textAccept}
                onClick={() => {
                
                    if (state === 'init') {
                        if (phoneNumber?.length !== 9) {
                            setPhoneError(true)
                        } else {
                            setWrongCredentials(false)
                            sendPhoneValidation(phoneNumber, user)
                        }
                    } else {
                        setWrongCredentials(false)
                        activateAccount(phoneNumber, user, value)
                    }
                }}>
                
            </BlackButton>}
        </DialogActions>
    </Dialog >
}