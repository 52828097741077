import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { QueryOptions } from "../../helpers/query.options"
import { productPromoPageService } from "../../models/product/service"
import { Box } from "@mui/material"
import { MainPageProductSlider } from "../../models/MainPageBrandBanner/MainPage"
import { ProductDetailsBanner } from "./ProductDetailsBanner"
import { ProductPromoPageTextSectionComponent } from "./ProductPromoPageTextSectionComponent"
import { ProductKeyNotes } from "./ProductKeyNotes"
import { ProductPromoPageTitleAndTextSectionComponent } from "./ProductPromoPageTitleAndTextSectionComponent"
import { ProductDesignComponent } from "./ProductDesignComponent"
import { ProductPromoPageCardColumnSection } from "./ProductPromoPageCardColumnSection"
import { PictureWithVideoBanner } from "./PictureWithVideoBanner"

export const ProductPromoPageSectionsComponent = (props) => {
    const { app, translations, language } = props
    const history = useHistory()
    const { id }: any = useParams()
    const { width } = useWindowDimensions()
    const [sliders, setSliders] = useState<MainPageProductSlider[]>([])
    const [items, setItems] = useState<any[]>([])

    const loadProductPromoPage = (id: string, translations) => {
        const options = new QueryOptions()
        options.lang = language
        productPromoPageService.readList(options, id).then((resp: any) => {
            setItems(resp.results)
        })
    }

    useEffect(() => {
        loadProductPromoPage(id, translations)
    }, [translations])




    const getItemType = (item, index) => {
        switch (item.type) {
            case 'banner':
                return <ProductDetailsBanner key={index} item={item.object} {...props} />
            case 'text':
                return <ProductPromoPageTextSectionComponent key={index} textItem={item.object} {...props} />
            case 'key_notes':
                return <ProductKeyNotes key={index} item={item.object} {...props} />
            case 'title_and_text_section':
                return <ProductPromoPageTitleAndTextSectionComponent key={index} textItem={item.object} {...props} />
            case 'image_carousel':
                return <ProductDesignComponent key={index} itemProp={item.object}  {...props} />
            case 'card_column_sections':
                return <ProductPromoPageCardColumnSection key={index} item={item.object} {...props} />
            case 'image_video':
                return <PictureWithVideoBanner key={index} item={item.object} {...props} />
            default:
                return <h1 key={index}></h1>
        }
    }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingBottom: 10,
                width: "100%",
                paddingTop: 1,
                "@media(max-width:464px)": { paddingTop: 5 },
            }}
        >
            {items.map((item, index)=> {
                return getItemType(item, index)
            })}
        </Box>
    )
}
