import {FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { } from 'react'

export const AddressSelector = (props: any) => {
    const {addresses, address, setAddress, translations} = props
    


    return <FormControl style={{ top: 10, width: '100%' }}>
        <InputLabel >{translations.addresses}</InputLabel>
        <Select
            displayEmpty
            sx={{
                '&:after': {
                    borderColor: '#777',
                }
            }}
            style={{ alignItems: 'flex-start', textAlign: 'left' }}
            value={address}
            label={translations.addresses}
            onChange={(event) => {
                const [address] = addresses.filter(c => c.id === event.target.value)
                setAddress(address)

            }}

        >
            {[ {id: ' ', street: translations.new_address,},...addresses]?.map((item, index) => {
                return <MenuItem value={item.id} key={index} selected={item.id === address?.id}>{item.street}</MenuItem>
            })}

        </Select>
    </FormControl>
}
