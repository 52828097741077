import { Box, Button, DialogTitle, TextField, Typography } from "@mui/material"
import React, { useState } from "react"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import * as Yup from "yup"
import { Form, Formik } from "formik"

export const GiftCardCheckoutFourthStep = (props) => {
    const { language, translations, setStep } = props
    const { width } = useWindowDimensions()
    const [submitted, setSubmitted] = useState(false)


    const getValues = (values) => {
        localStorage.setItem("gift-card-checkout-fourth-step", JSON.stringify(values))
        setStep(c => c + 1)
    }

    const showError = (errors, field) => {
        return errors[field] && submitted
    }

    return (
        <Box
            sx={{
                width: width > 600 ? "500px" : "80%",
                margin: "0 auto",
                border: "1.5px solid gray",
                borderRadius: 2,
                padding: 5,
                marginBottom: 7,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                "@media (max-width:600px)": { padding: 3 },
            }}
        >
            <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"} sx={{ marginBottom: 2 }}>
                {translations?.add_a_personalised_message}
            </DialogTitle>

            <Formik
                initialValues={{ message: "" }}
                onSubmit={(values) => {
                    getValues(values)
                }}
            >
                {({ values, handleSubmit, setFieldValue, handleChange, handleBlur, errors }: any) => (
                    <Form
                        onSubmit={(values) => {
                            handleSubmit(values)
                        }}
                    >
                        <TextField
                            id="outlined-multiline-flexible-textfield"
                            label={translations?.your_message_optional}
                            multiline
                            rows={4}
                            name="message"
                            value={values.mesage}
                            onChange={(event) => {
                                setSubmitted(false)
                                handleChange(event)
                            }}
                            
                            sx={{
                                marginTop: 3,
                                marginBottom: 3,
                                width: "90%",
                                margin: "0 auto",
                                "@media (max-width:600px)": { width: "100%" },
                            }}
                        />
                        {showError(errors, "message") && (
                            <Typography
                                sx={{
                                    color: "red",
                                    textAlign: "left",
                                    marginLeft: 2,
                                    marginBottom: 2,
                                    "@media (max-width:600px)": { marginLeft: 5, marginBottom: 3 },
                                }}
                            >
                                {errors.message}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            onClick={() => setSubmitted(true)}
                            sx={{
                                width: "90%",
                                margin: "0 auto",
                                marginTop: 5,
                                backgroundColor: "black",
                                color: "white",
                                padding: "10px 50px 10px 50px",
                                fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                                textTransform: "none",
                                "@media (max-width:600px)": { width: "100%" },
                                "&:hover": { backgroundColor: "black" },
                            }}
                        >
                            {translations?.where_is_it_going}
                        </Button>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}
