import React, { Fragment, useEffect, useState } from 'react';
import { QueryOptions } from '../../helpers/query.options';
import { Finder } from '../../models/finder/Finder';
import { finderService } from '../../models/finder/service';
import { FinderDialog } from '../Dialogs/FinderDialog';


export const FinderComponent = (props: any) => {
    const { link, open, setOpen, setFinderItem, onCloseDialogEvent } = props
    const [finder, setFinder] = useState<Finder>()
    const [dialogOpen, setDialogOpen] = useState(false)


    const loadFinder = (link: string) => {
        const options = new QueryOptions()
        options.urls = link
        finderService.listWithCounter(options).then(resp => {
            
            if (resp.current && resp.current.length) {
                const [item] = resp.current
                if (item) {
                    setFinder(item)
                }

            }
        })
    }
    useEffect(() => {
        if (link) {
            loadFinder(link)
        }
    }, [link])
    
    useEffect(() => {
        if (open) {
            setDialogOpen(true)
        } else {
            setDialogOpen(false)
        }
    }, [open])

    useEffect(() => {
        if (finder) {
            setFinderItem(finder)
        }
    }, [finder])

    useEffect(() => {
        if (link) {
            loadFinder(link)
        }
    }, [open])


    return <Fragment>
        <FinderDialog
            finder={finder}
            onReload={() => { }}
            {...props}
            open={dialogOpen}
            onCloseDialogEvent={setOpen}
            onCloseDialog={(resp) => {
                setDialogOpen(resp)
                onCloseDialogEvent(resp)
                setOpen(resp)
            }} />

    </Fragment>
}