import { ResourceProvider } from "../../providers/resource.provider"
import { City } from "./city"
import { CitySerializer } from "./serializer"

export class CityProvider extends ResourceProvider<City> {
    constructor() {
        super("rest_city", new CitySerializer())
    }
}

export const cityService = new CityProvider()