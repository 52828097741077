import React, { useState, useEffect, useCallback, useRef, Fragment } from "react";
import { ChevronRight } from "../../assets/icons/ChevronRight";
import { register } from "../../models/user/authActions";
import { connect } from 'react-redux'
import '../../components/styles.scss'
import { BrandItem } from "../../components/Brand/BrandItem";
import { useHistory, useLocation, useParams } from "react-router";
import { QueryOptions } from "../../helpers/query.options";
import { brandService } from "../../models/brand/service";
import { Brand } from "../../models/brand/brands";
import { Box, Button, ButtonGroup, CircularProgress, Divider, Fade, Grid } from "@mui/material";
import { Product } from "../../models/product/product";
import { productService } from "../../models/product/service";
import { COLORS } from "../../constants/colors";
import { Title } from "../../components/Typography/Title";
import Pagination from "../../components/Pagination/Pagination";
import { ProductItem } from "../../components/Product/ProductItem";
import { Attribute, AttributeSet } from "../../models/attribute/attribute";
import { generateParamsFromUrl, Root } from "../../helpers/utilities";
import { environment } from "../../environment/environment";
import { BrandsPageSlider } from "../../components/Carousel/BrandsPageSlider";
import { FilterChips } from "../../components/Filter/FIlterChips";
import { FilterComponentForBrandsPage } from "../../components/Filter/FilterComponentForBrandsPage";
import { SortPopover } from "../../components/Popover/SortPopover";
import { GenericCarousel } from "../../components/Carousel/GenericCarousel";
import { Animated } from "react-animated-css";
import FilterDialogForBrand from "../../components/Dialogs/FilterDialogForBrand";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import SortDialogForBrand from "../../components/Dialogs/SortDialogForBrand";
import { GenericBannerComponent } from "../../components/banner/GenericBannerComponent";


const BrandsPage = (props: any) => {
  const { language, translations, app } = props
  let { tag, id }: any = useParams();
  const sortObj = localStorage.getItem("sort")
  const [sort, setSort] = useState(sortObj ? JSON.parse(sortObj) : { sort: "uploaded", order: "desc" })
  const history = useHistory();
  const [queryParams, setQueryParams] = useState<QueryOptions>()
  const [filters, setFilters] = useState<Attribute[]>([])
  
  const location = useLocation();
  const [currentTag, setCurrentTag] = useState(tag)
  const [filterItem, setFilterItem] = useState<Attribute>()
  const [title, setTitle] = useState("")
  
  const [page, setPage] = useState(1)
  const [brands, setBrands] = useState<Brand[]>([])
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<Product[]>([])
  const [productCount, setProductCount] = useState(0)
  const [init, setInit] = useState(false)
  const [attributeSet, setAttributeSet] = useState<AttributeSet>()
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const listRef: any = useRef()
  const [scroll, setScroll] = useState(0)
  const [showFilterButton, setShowFilterButton] = useState(true)
  const [filterOpen, setFilterOpen] = useState(false)
  const [sortOpen, setSortOpen] = useState(false)
  const [lockLoaded, setLockLoaded] = useState(false)
  const [pageIndex, setPageIndex] = useState(2)

  const { width } = useWindowDimensions()

  useEffect(() => {
    if (init && location?.search) {
      const params = generateParamsFromUrl(location.search)
      const urlParams = location.pathname.split("/")
      setQueryParams(params)
    }
  }, [location.search])

  useEffect(() => {
    if (!location.search && init) {
      setInit(false)
    }
  }, [JSON.stringify(location)])

  

  useEffect(() => {
    if (init) {
      if (!queryParams?.brand && queryParams) {
        const params = generateParamsFromUrl(location.search)
        loadBrands(tag).then(brands => {
          params.brand = brands.current.map(b => b.id).toString();
          setQueryParams(params)
          setBrands(brands.current)
          loadProducts(brands.current, queryParams)
          setInit(true)
        })
      } else {
        if (queryParams.pageIndex) {
          loadBrands(tag).then(brands => {
            queryParams.brand = brands.current.map(b => b.id).toString();
            setBrands(brands.current)
            loadProducts(brands.current, queryParams)
            setInit(true)
          })
        }
      }
    } else {
      if (queryParams?.brand) {
        loadBrands(tag).then(brands => {
          const params = generateParamsFromUrl(location.search)
          params.brand = queryParams.brand
          setQueryParams(params)
          setBrands(brands.current)
          loadProducts(brands.current, queryParams)
          setInit(true)
        })
      } else {
        const params = generateParamsFromUrl(location.search)
        loadBrands(tag).then(brands => {
          params.brand = brands.current.map(b => b.id).toString();
          setQueryParams(params)
          setBrands(brands.current)
          loadProducts(brands.current, queryParams)
          setInit(true)
        })
      }
    }
  }, [JSON.stringify(queryParams), init])

  useEffect(() => {
    setBrands([])
    setProducts([])
    if (tag?.length) {
      loadBrands(tag)
      const params = generateParamsFromUrl(location.search)
      const urlParams = location.pathname.split("/")
      setQueryParams(params)
    }
  }, [tag])

  useEffect(() => {
    if (brands?.length) {

    }
  }, [brands])

  useEffect(() => {
    window.addEventListener("scroll", (e) => { setScroll(window.scrollY) });
    return () => {

      window.removeEventListener("scroll", (e) => console.log(e));
    };

  }, []);

  useEffect(() => {
    if (width < 1200) {
      if (scroll > window.innerHeight - 200) {
        setShowFilterButton(true)
      } else {
        setShowFilterButton(false)
      }
    }
  }, [scroll])

  

  const loadBrands = async (tag: string) => {
    const options = new QueryOptions()
    options.pageSize = 100
    options.sort = "title_eng"
    options.order = "asc"
    options.tag = `${tag}`
    const attrs = await brandService.listWithCounter(options)
    getTagName(attrs.current)
    return attrs
  }



  const getTagName = (brands) => {
    const [brand] = brands
    const [attribute] = brand.attributes.filter(attr => attr.id === currentTag)
    if (attribute) {
      setTitle(attribute.value)
    }
  }


  useEffect(() => {
    if (width < 1200) {
      const handleScroll = () => {
        const scrollHeight = document.documentElement.scrollHeight;
        const scrollTop = document.documentElement.scrollTop;
        const clientHeight = document.documentElement.clientHeight;
        const scrollPercent = (scrollTop + clientHeight) / scrollHeight;
        if (scrollPercent >= 0.8) {
          if (!lockLoaded) {
            loadProductsForMobile(brands, queryParams);
          } else {
            console.log('no response')
          }
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [products, lockLoaded]);

  useEffect(() => {
    setPageIndex(2)
    setLockLoaded(false)
  }, [queryParams])

  const loadProductsForMobile = async (brands, queryParams) => {
    setLockLoaded(true)
    setLoading(true)
    if (queryParams) {
      const options = new QueryOptions()
      options.sort = queryParams.sort
      options.order = queryParams.order
      environment.params.forEach((param: string) => {
        options[param] = queryParams[param]
      });
      if (queryParams?.brand?.length) {
        options.brand = queryParams.brand
      } else {
        options.brand = brands.map(b => b.id).toString()
      }
      if (!options.sort) {
        options.sort = sort.sort
      }
      if (!options.order) {
        options.order = sort.sort
      }
      options.price = queryParams.price
      options.pageIndex = queryParams.pageIndex


      options.pageIndex = pageIndex
      const prods: any = await productService.listWithCounter(options)
      if (prods.current) {
        setPageIndex(pageIndex => {
          return pageIndex + 1
        })
        setTimeout(() => {
          setLockLoaded(false)
        }, 1000)
        setLoading(false)
        setProducts(products => [...products, ...prods.current])
        setProductCount(props.count)
      }
    }
  }

  const loadProducts = (brands, queryParams) => {
    setLoading(true)
    setProducts([])
    if (queryParams) {
      const options = new QueryOptions()
      options.sort = queryParams.sort
      options.order = queryParams.order
      environment.params.forEach((param: string) => {
        options[param] = queryParams[param]
      });
      if (queryParams?.brand?.length) {
        options.brand = queryParams.brand
      } else {
        options.brand = brands.map(b => b.id).toString()
      }
      if (!options.sort) {
        options.sort = sort.sort
      }
      if (!options.order) {
        options.order = sort.sort
      }
      options.price = queryParams.price
      options.pageIndex = queryParams.pageIndex
      if (options.pageIndex > 1) {
        window.scrollTo({
          behavior: 'smooth',
          top: listRef?.current?.offsetTop,
        });
      }
      productService.listWithCounter(options).then(r => {
        if (!r.status) {
          setProducts(r.current)
          setProductCount(r.count)
          setLoading(false)
        }
      })
    }
  }

  const RenderProductList = useCallback(() => {
    return <Box
      style={{
        flexWrap: 'wrap',
        padding: 0, alignItems: 'flex-start', marginTop: 0, display: 'flex', width: '100%'
      }}>
      {products.map((item, index) => {
        return <Box style={{ width: '25%', minWidth: '25%' }} key={index}>
          <ProductItem key={index} item={item} {...props} />
        </Box>
      })}
    </Box>
  }, [products])

  const RenderFilter = useCallback(() => {
    return <FilterComponentForBrandsPage
      {...props}
      attrSet="brand"
      init={init}
      page={page}
      filterItem={filterItem}
      setFilters={(filters) => {
        setFilters(filters)
      }}
      attributeSet={attributeSet}
      queryParams={queryParams}
      location={location}
      onSetFilter={(arr, brands) => {
        if (arr.length) {
          const options = generateParamsFromUrl(location.search)
          environment.params.forEach((param: string) => {
            const params = arr.filter(i => i.name.id === param)
            if (params.length) {
              options[param] = params.map(r => r.id).toString()
            }
          });
          loadProducts([], options)
        } else {
          loadBrands(tag).then(brands => {
            const options = new QueryOptions()
            options.brand = brands.current.map(b => b.id).toString();
            setBrands(brands.current)
            loadProducts(brands.current, options)
            setInit(true)
          })
        }
      }}
      selectedFilters={selectedFilters}
    />
  }, [location.pathname])

  const RenderFilterButton = useCallback(() => {
    return <Animated
      animationIn="fadeInUp"
      animationInDuration={300}
      animationOut="fadeOutDown"
      style={{
        width: width,
        paddingTop: 10,
        height: 120,
        backgroundColor: 'transparent',
        position: 'fixed',
        zIndex: 1001,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        bottom: 0,
        right: 0
      }}
      isVisible={showFilterButton} >
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button onClick={() => setFilterOpen(true)} sx={{
          backgroundColor: COLORS.BEIGE_I,
          width: 130,
          height: 50,
          color: '#444',
          '&:hover': {
            color: '#444',
            backgroundColor: COLORS.BEIGE_I,
          }
        }}>
          {translations?.filter}
        </Button>
        <Button onClick={() => setSortOpen(true)} sx={{
          backgroundColor: COLORS.BEIGE_I,
          width: 130,
          height: 50,
          color: '#444',
          '&:hover': {
            color: '#444',
            backgroundColor: COLORS.BEIGE_I,
          }
        }}>
          {translations?.sort}
        </Button>
      </ButtonGroup>
    </Animated>

  }, [showFilterButton])

  return <div className={"container"}>
    <GenericBannerComponent 
    location={`/brands/${tag}`}
    />
    {width < 1200 && <RenderFilterButton />}
    {width < 1200 && <FilterDialogForBrand
      {...props}
      sort={sort}
      onSetSort={(sort) => {
        setSort(sort)
        localStorage.setItem("sort", JSON.stringify(sort))
        const options = generateParamsFromUrl(location.search)
        options.sort = sort.sort
        options.order = sort.order
        const url = `${location.pathname}`
        history.push(options.toQueryStringWithUrl(url))
        setQueryParams(options)
      }}
      brands={[brands]}

      filterItem={filterItem}
      filters={filters}
      setFilterItem={setFilterItem}
      location={location}
      queryParams={queryParams}
      setFilters={setFilters}
      attributeSet={attributeSet}
      selectedFilters={selectedFilters}
      open={filterOpen}
      onCloseDialog={(resp) => {
        setFilterOpen(resp)
      }}
    />}
    {
      width < 1200 && <SortDialogForBrand
        {...props}
        sort={sort}
        onSetSort={(sort) => {
          setSort(sort)
          localStorage.setItem("sort", JSON.stringify(sort))
          const options = generateParamsFromUrl(location.search)
          options.sort = sort.sort
          options.order = sort.order
          const url = `${location.pathname}`
          history.push(options.toQueryStringWithUrl(url))
          setQueryParams(options)
        }}
        brands={[brands]}

        filterItem={filterItem}
        filters={filters}
        setFilterItem={setFilterItem}
        location={location}
        queryParams={queryParams}
        setFilters={setFilters}
        attributeSet={attributeSet}
        selectedFilters={selectedFilters}
        open={sortOpen}
        onCloseDialog={(resp) => {
          setSortOpen(resp)
        }}
      />
    }
    {<div style={{ width: width > 1200 ? 1200 : '100%', margin: '0px auto', padding: width > 1200 ? 30 : 0, paddingTop: 30 }}>
      {<div style={{ marginBottom: 0 }}>
        <BrandsPageSlider items={brands.filter(brand => brand.banner)} size={width > 1200 ? 'desktop' : 'mobile'} />
      </div>}
    </div>}

    <Grid container style={{ width: width > 1200 ? 1200 : '100%', padding: 0 }} spacing={1}>
      {brands.length > 0 && width > 1200 ? brands?.filter((item, index) => index < 18).map((brand, index) => {
        return <Grid item lg={2} sm={2} style={{ padding: 10 }} key={index}>
          <BrandItem item={brand} onClick={() => { history.push(`brand/${brand.id}`) }} />
        </Grid>
      }) : <GenericCarousel
        length={brands?.length}
        items={brands?.filter((item, index) => index < 18).map((brand, index) => {
          return <Grid item lg={2} sm={2} style={{ padding: 10, marginTop: 20 }} key={index}>
            <BrandItem item={brand}
              fontSize={14}
              onClick={() => { history.push(`brand/${brand.id}`) }} />
          </Grid>
        })}
        onRedirect={(id) => {
          history.push(`/brand/${id}`)
        }}
      />}
      <Grid
        ref={listRef}
        container spacing={0} className="container" style={{ width: width < 1200 ? null : 1200, margin: '0px auto', position: 'relative' }}>
        <Box sx={{
          flexDirection: 'column',
          display: 'flex', alignItems: 'center', justifyContent: 'center',
          borderRadius: 20,
          position: 'absolute',
          bottom: 30,
          right: -60,
          width: 40,
          height: 40,
          backgroundColor: COLORS.BEIGE_I
        }}>
          <div
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
            style={{
              transform: 'rotate(270deg) translateX(0px) translateY(1px)',
            }}>
            <ChevronRight width={15} height={15} />
          </div>
        </Box>

        {width > 1200 ? <Grid container
          spacing={1}
          style={{
            width: 1200,
            padding: 0,
            alignItems: 'flex-start',
            display: 'flex',
            marginTop: 40
          }}>
          <FilterChips
            filters={filters}
            handleDelete={(item) => {
              setFilterItem(item)
            }}
          />

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'space-between',
              width: '100%'
            }}>
            <Title
              uppercase={false}
              fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
              color={'#333'}
              fontSize={14}
              style={{ padding: 10 }}
              fontWeight={'400'}
              text={`${productCount} ${translations.items}`} />

            {translations?.price_increasing &&
              <SortPopover {...props}
                onMouseLeave={(event: any) => {
                  /* setAnchorEl(null); */
                  /* setAnchorEl(null); */
                }}
                sort={sort}
                onSetSort={(sort) => {
                  setSort(sort)
                  localStorage.setItem("sort", JSON.stringify(sort))
                  const options = generateParamsFromUrl(location.search)
                  options.brand = brands.map(b => b.id).toString();
                  options.sort = sort.sort
                  options.order = sort.order
                  const url = `${location.pathname}`
                  history.push(options.toQueryStringWithUrl(url))
                  setQueryParams(options)
                  loadProducts(brands, options)
                }}
              />}
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 20, maxWidth: 1200 }}
          >
            <Root>
              <Divider />
            </Root>
          </Box>
          <Box sx={{
            width: '20%', display: 'flex',
            height: '100%',
            flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'
          }}>
            <Title text={translations?.filter}
              style={{ marginTop: 30 }}
              fontSize={15} uppercase={true} fontWeight='400' color={"black"} />

            <Box sx={{ paddingTop: 3 }}>
              {init && <RenderFilter />}
            </Box>
          </Box>
          {width > 1200 && <Box sx={{ width: '80%' }}>
            {loading ? <div style={{
              height: 500,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <CircularProgress color='inherit' />
            </div> :
              <Box style={{ flex: 1, display: 'flex', width: '100%' }}>
                <RenderProductList />
              </Box>
            }
            
            {Math.ceil(productCount / 24) > 1 && 
              
              <div style={{ height: 120, marginTop: 40 }}>
              <Pagination
                count={productCount}
                pageSize={24}
                setPageIndex={(page) => {
                  setPage(page)
                  const options = generateParamsFromUrl(location.search)
                  options.brand = brands.map(b => b.id).toString();
                  options.pageIndex = page
                  const url = `${location.pathname}`
                  history.push(options.toQueryStringWithUrl(url))
                  setQueryParams(options)
                }}
                pageIndex={queryParams?.pageIndex}
              />
            </div>}
          </Box>}
        </Grid>
          :
          <Box style={{ flex: 1, display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            {/* this */}
            <ButtonGroup style={{
              marginTop: 3,
              marginBottom: 3,
            }} variant="contained" aria-label="outlined primary button group">
              <Button onClick={() => setFilterOpen(true)} sx={{
                backgroundColor: COLORS.BEIGE_I,
                borderColor: 'black !important',
                width: 130,
                height: 50,
                color: '#444',
                '&:hover': {
                  color: '#444',
                  backgroundColor: COLORS.BEIGE_I,
                }
              }}>
                {translations?.filter}
              </Button>
              <Button onClick={() => {
                setFilterOpen(false)
                setSortOpen(true)
              }} sx={{
                backgroundColor: COLORS.BEIGE_I,
                width: 130,
                height: 50,
                color: '#444',
                '&:hover': {
                  color: '#444',
                  backgroundColor: COLORS.BEIGE_I,
                }
              }}>
                {translations?.sort}
              </Button>
            </ButtonGroup>

            <Box
              id="main"
              style={{ padding: 0, alignItems: 'center', marginTop: 40, flexWrap: 'wrap', display: 'flex', flexGrow: 1, width: width }}>
              {products.map((item, index) => {
                return <Grid sm={4} xs={6} key={index} style={{
                  maxWidth: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  width: `${1 / (app?.dimensions?.width / 180) * 100}%`
                }}>
                  <ProductItem key={index} item={item} {...props} />
                </Grid>
              })}
            </Box>
            <div style={{ height: 80 }}></div>
          </Box>
        }
      </Grid>
    </Grid>
  </div >
}


const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    language: state.language,
    translations: state.translations
  }
}

export default connect(mapStateToProps, { register })(BrandsPage)