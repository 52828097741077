import React, { useState } from "react"
import { Box, Button, DialogContent, DialogTitle, TextField, Typography } from "@mui/material"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import * as Yup from "yup"
import { Form, Formik } from "formik"
import { GiftCardFormTabs } from "./GiftCardFormTabs"

export const GiftCardCheckoutFirstStep = (props) => {
    const { translations, language, setStep } = props
    const { width } = useWindowDimensions()
    const [value, setValue] = useState(0)
    const [submitted, setSubmitted] = useState(false)

    const handleChangeVal = (event, newValue) => {
        setValue(newValue)
    }



    const validation = Yup.object().shape({
        friendName: Yup.string().required(translations?.friend_name_is_required),
        yourName: Yup.string().required(translations?.your_name_is_required),
    })

    const getValues = (values) => {
        localStorage.setItem("gift-card-checkout-first-step", JSON.stringify(values))
        setStep(c => c + 1)
    }

    const showError = (errors, field) => {
        return errors[field] && submitted
    }

    return (
        <Box
            sx={{
                width: width > 600 ? "500px" : "80%",
                margin: "0 auto",
                border: "1.5px solid gray",
                borderRadius: 2,
                padding: 2,
            }}
        >
            <DialogTitle fontFamily={language === "geo" ? "FiraGo" : "Roboto"}>
                {translations?.who_is_the_gift_card_for}
            </DialogTitle>
            <DialogContent>
                <GiftCardFormTabs
                    translations={translations}
                    language={language}
                    value={value}
                    handleChange={handleChangeVal}
                />
                {value === 0 ? (
                    <Formik
                        validationSchema={validation}
                        initialValues={{ friendName: "", yourName: "" }}
                        onSubmit={(values) => {
                            getValues(values)
                        }}
                    >
                        {({ values, handleSubmit, handleChange, handleBlur, errors }: any) => (
                            <Form
                                onSubmit={(values) => {
                                    handleSubmit(values)
                                }}
                            >
                                <TextField
                                    label={translations?.friends_name}
                                    fullWidth
                                    margin="normal"
                                    value={values.friendName}
                                    type="text"
                                    name="friendName"
                                    onChange={(event) => {
                                        setSubmitted(false)
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                />
                                {showError(errors, "friendName") && (
                                    <Typography sx={{ color: "red", textAlign: "left" }}>
                                        {errors.friendName}
                                    </Typography>
                                )}
                                <TextField
                                    label={translations?.your_name}
                                    fullWidth
                                    margin="normal"
                                    value={values.yourName}
                                    type="text"
                                    name="yourName"
                                    onChange={(event) => {
                                        setSubmitted(false)
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                />
                                {showError(errors, "yourName") && (
                                    <Typography sx={{ color: "red", textAlign: "left" }}>{errors.yourName}</Typography>
                                )}
                                <div
                                    style={{
                                        textAlign: "center",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                            setSubmitted(true)
                                        }}
                                        sx={{
                                            backgroundColor: "black",
                                            color: "white",
                                            fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                                            textTransform: "none",
                                            "&:hover": { backgroundColor: "black" },
                                        }}
                                    >
                                        {translations?.send_a_digital_card}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div
                        style={{
                            textAlign: "center",
                            marginTop: "40px",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                localStorage.setItem(
                                    "gift-card-checkout-first-step",
                                    JSON.stringify(language === "eng" ? "For Me" : "ჩემთვის")
                                )
                                setStep(c => c + 1)
                            }}
                            sx={{
                                backgroundColor: "black",
                                color: "white",
                                fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                                textTransform: "none",
                                "&:hover": { backgroundColor: "black" },
                            }}
                        >
                            {translations?.send_a_digital_card}
                        </Button>
                    </div>
                )}
            </DialogContent>
        </Box>
    )
}
