import React, { useEffect, useState } from 'react';
import { environment } from '../../environment/environment';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

export const GoogleMapContainer = (props) => {
    const { branches, translations, activeBranch } = props;
    let bounds: any = [];
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [zoom, setZoom] = useState(12);
    const [activeInfoWindowId, setActiveInfoWindowId] = useState(null);

    const onMarkerClick = (branch) => {
        branch.infoOpen = true;
    };

    useEffect(() => {
        if (activeBranch) {
            handleMarker(activeBranch);
        }
    }, [activeBranch]);

    const handleMarker = (activeItem) => {
        setSelectedMarker(activeItem.address.location);
        setZoom(18);
        setActiveInfoWindowId(activeItem.id);
    };

    const handleMarkerClick = (branch) => {
        setSelectedMarker({ lat: branch.address.location.lat, lng: branch.address.location.lng });
        if (zoom !== 18) {
            setZoom(18);
            setActiveInfoWindowId(branch.id);
        } else setZoom(17);
        setActiveInfoWindowId(branch.id);
    };

    const defaultCenter = {
        lat: branches?.length ? branches[0]?.address?.location.lat : 41.111,
        lng: branches?.length ? branches[0]?.address?.location.lng : 44.111,
    };

    return (
        <LoadScript googleMapsApiKey={environment.GooleMapsKey} libraries={['places']}>
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                zoom={zoom}
                center={selectedMarker ? { lat: selectedMarker.lat, lng: selectedMarker.lng } : defaultCenter}
            >
                {branches?.map((branch) => (
                    <Marker
                        key={branch.id}
                        position={{ lat: branch.address.location.lat, lng: branch.address.location.lng }}
                        onClick={() => handleMarkerClick(branch)}
                    >
                        {activeInfoWindowId === branch.id && (
                            <InfoWindow position={{ lat: branch.address.location.lat, lng: branch.address.location.lng }} onCloseClick={() => setActiveInfoWindowId(null)}>
                                <div>
                                    {branch?.address.street}
                                    <br />
                                    <span>
                                        <b>{translations?.phone_}: </b>
                                        {branch?.phone}
                                    </span>
                                    <br />
                                    <span>
                                        <b>{translations?.open_hours}: </b>
                                        {branch?.openTime} - {branch?.closeTime}
                                    </span>
                                </div>
                            </InfoWindow>
                        )}
                    </Marker>
                ))}
            </GoogleMap>
        </LoadScript>
    );
};
