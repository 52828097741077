import { Serializer } from "../serializer";
import { TopHeaderBanner } from "./TopHeaderBanner";


export class TopHeaderBannerSerializer implements Serializer {
    fromJson(json: any) {
        const item = new TopHeaderBanner()
        item.text = json.text
        item.content = json.content
        item.link = json.link
        return item;
    }
    toJson(list: any) {
        return {
        };
    }
}
