import React from "react"
import { Box } from "@mui/material"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"

export const ProductDetailsBanner = (props) => {
    const { item, productPage } = props
    const { width } = useWindowDimensions()
    return (
        <Box
            sx={{
                width: width,
                maxWidth: productPage? 1200 : 'none',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {item?.type == "video" ? 
            <video  
                src={item?.video}
                autoPlay
                loop
                controls
                muted
                controlsList="nodownload"
                style={{
                    width: width > 600 ? width : "100%",
                    objectFit: "cover",
                    zIndex: 2,
                    aspectRatio:width> 600 ? '16/9': "1/1",
                }}
            ></video>
            :
            <img
                src={width > 600 ? item?.image: item?.imageMobile}
                style={{
                    width: "100%",
                    objectFit: "cover",
                    zIndex: 2,
                    aspectRatio: width < 600 && !productPage ? "1/1" : width > 600 && !productPage ? "16/9" : 'auto',
                }}
            /> }
        </Box>
    )
}
