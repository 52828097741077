import React from 'react';
import "react-multi-carousel/lib/styles.css";
import { MobileMainPageProductBannerComponent } from './MobileMainPageProductBannerComponent';
import { Box } from '@mui/material';
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import '../Carousel/carousel.css'


export const MobileMainPageProductBannerCarousel = (props: any) => {
    const { items } = props


    return items ? <div style={{
        position: 'relative',
        marginTop: window.innerWidth > 1200 ? 20 : -80,
        paddingTop: 20,
        height: window.innerWidth > 1200 ? 450 : 350,
        width: window.innerWidth,
        textAlign: 'center',
        border: '1px solid white',
        borderLeftColor: 'white',
        borderRightColor: 'white'
    }}>

        <Swiper
            slidesPerView={1}
            
            breakpoints={{
                440: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
            }}
            spaceBetween={20}
            loop={false}
            pagination={true}
            modules={[Pagination]}
            className="mySwiper">
            {items.map((item, index) => {
                return <SwiperSlide key={index}>
                    <Box style={{ width: 'auto' }} >
                        <MobileMainPageProductBannerComponent
                            kay={index}
                            item={item}
                            {...props}
                        />
                    </Box>
                </SwiperSlide>
            })}
        </Swiper>
    </div> : <></>
}