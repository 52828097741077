import React, { useState, useEffect } from 'react';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { brandSliderService } from '../../models/MainPageBrandBanner/service';
import { QueryOptions } from '../../helpers/query.options';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';
import { Box } from '@mui/material';
import Skeleton from "@mui/material/Skeleton/Skeleton";



export const BrandCarouselComponent = (props: any) => {
    let carousel: any
    const { onRedirect } = props
    const [brands, setBrands] = useState<any[]>([])
    const [loadStatus, setLoadStatus] = useState(true)

    const loadBrandCarousel = () => {
        brandSliderService.list(new QueryOptions()).then((resp: any) => {
            if (resp.results) {
                setBrands(resp.results)
                setLoadStatus(false)
            }
        })
    }


    useEffect(() => {
        loadBrandCarousel()
    }, [])

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 7
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 7
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 564, min: 0 },
            items: 1
        }
    }


    const CustomRightArrow = ({ onClick, ...rest }) => {
        return <button />
    }

    return <>
        <div style={{
            position: 'relative',
            marginTop: 40,
            height: 70,
            width: 1200,
            border: '1px solid black',
            padding: 0,
            borderLeftColor: 'white',
            borderRightColor: 'white'
        }}>

            <div style={{ position: 'absolute', right: -30, zIndex: 100, top: '30%', cursor: 'pointer' }} onClick={() => {
                carousel.next()
            }}>
                <ChevronRight width={30} height={30} />

            </div>
            <div style={{ position: 'absolute', left: -30, zIndex: 100, top: '30%', cursor: 'pointer' }} onClick={() => {
                carousel.previous()
            }}>
                <ChevronLeft width={30} height={30} />

            </div>
            <Box sx={{ overflow: 'hidden', height: '100%' }} >
                <Carousel
                    ref={(el) => (carousel = el)}
                    customRightArrow={<ChevronLeft onClick={() => {
                        carousel.next()
                    }} />}
                    customLeftArrow={<CustomRightArrow onClick={() => {
                        carousel.next()
                    }} />}
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlaySpeed={100000000}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}

                >
                    {loadStatus ? Array(10).fill("").map((index) => {
                        return <Skeleton
                            key={index}
                            style={{
                                width: 120,
                                height: 70,
                            }}></Skeleton>
                    }) : [...brands].map((item, index) => {
                        return <div
                            key={index}
                            onClick={() => onRedirect(item.id)}
                            style={{
                                display: 'inline-block',
                                backgroundImage: `url(${item.logo})`,
                                backgroundSize: 'contain',
                                width: 120,
                                cursor: 'pointer',
                                height: 90,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                transform: "translateY(-10px)",
                            }}></div>
                    })}

                </Carousel>
            </Box>

        </div>

    </>
}

