import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { GiftCardCheckoutComponent } from "../components/giftcardCheckout/GiftCardCheckoutComponent"

const GiftCardCheckoutPage = (props) => {
    const { auth, translations, language, app } = props
    
    return <GiftCardCheckoutComponent {...props} />
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        translations: state.translations,
        language: state.language,
        app: state.app,
    }
}

export default connect(mapStateToProps, {})(GiftCardCheckoutPage)
