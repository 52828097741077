import React, { Fragment, useEffect, useState } from "react"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { infoPageContentService } from "../../models/InfoPage/service"
import { QueryOptions } from "../../helpers/query.options"
import { Accordion, AccordionDetails, AccordionSummary, Box, Fade, Typography } from "@mui/material"
import { OurBranches } from "../InfoPages/OurBranches"
import ContactForm from "../Forms/ContactForm"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { COLORS } from "../../constants/colors"
import { faqSectionService, faqService } from "../../models/faq/service"
import { Faq, FaqSection } from "../../models/faq/faq"
import { CompanyAddressComponent } from "./CompanyAddressComponent"

const Content = (props) => {
    switch (props.page) {
        case "branches":
            return (
                <OurBranches
                    {...props}
                    setSnackBar={props.setSnackBar}
                    app={props.app}
                    setConfirmation={props.setConfirmation}
                />
            )
        case "contact":
            return (
                <>
                    <CompanyAddressComponent {...props} />
                    <ContactForm {...props} />
                </>
            )

        default:
            return props.content ? (
                <>
                    {window.innerWidth < 1200 ? <h4>{props.content?.title}</h4> : <></>}
                    {props.content?.text || props.content?.sections?.length > 0 ? (
                        <Fragment>
                            <Box sx={{ padding: 2, textAlign: "left" }}>
                                {props.content?.display !== "image" && (
                                    <Box
                                        dangerouslySetInnerHTML={{
                                            __html: props.content?.text.replace(/\\n/g, "").replace(/\\r/g, ""),
                                        }}
                                        style={{ fontSize: 14, padding: 20 }}
                                    />
                                )}
                                {props.content?.display !== "text" && (
                                    <img style={{ width: "100%" }} src={props.content?.image} />
                                )}
                            </Box>
                            {props.content?.sections?.length > 0 && (
                                <Fragment>
                                    {props.content?.sections.map((section, index) => {
                                        return (
                                            <Box sx={{ padding: 2 }}>
                                                <h4>{section.title}</h4>
                                                {section.display !== "text" && (
                                                    <img style={{ width: "100%" }} src={section.image} />
                                                )}
                                                {section.display !== "image" && (
                                                    <Box
                                                        key={index}
                                                        dangerouslySetInnerHTML={{
                                                            __html: section?.text
                                                                .replace(/\\n/g, "")
                                                                .replace(/\\r/g, ""),
                                                        }}
                                                        style={{ fontSize: 14, padding: 10 }}
                                                    />
                                                )}
                                            </Box>
                                        )
                                    })}
                                </Fragment>
                            )}
                        </Fragment>
                    ) : (
                        <h4 style={{ textAlign: "center", width: props.width > 600 ? "auto" : props.width }}>
                            {props.translations?.content_not_found}
                        </h4>
                    )}
                </>
            ) : (
                <h4 style={{ textAlign: "center", width: props.width > 600 ? "auto" : props.width }}>
                    {props.translations?.content_not_found}
                </h4>
            )
    }
}

const RenderAccordion = (props) => {
    const { faqs, section } = props
    const matchingFaqs = faqs.filter((faq) => faq.section.id === section.id)
    return (
        <Box sx={{ marginTop: 1.5, padding: 1,  }}>
            {matchingFaqs?.map((item, index) => {
                return (
                    <Accordion
                        key={index}
                        variant="outlined"
                        sx={{
                            border: "none",
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: "background.contrast" }} />}
                            sx={{
                                backgroundColor: COLORS.BODY_BG,
                                borderBottom: "1px solid lightgray",
                                textAlign: "left",
                            }}
                        >
                            <Typography sx={{ fontWeight: "bold" }}>{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {item.text && (
                                <Box>
                                    <Typography
                                        sx={{ textAlign: "left" }}
                                        dangerouslySetInnerHTML={{ __html: item.text }}
                                    />
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </Box>
    )
}

const RenderContent = React.forwardRef(function (props: any, ref) {
    return (
        <div ref={ref} {...props}>
            <Content {...props} />
        </div>
    )
})
const RenderAccordionComponent = React.forwardRef(function (props: any, ref) {
    return (
        <div ref={ref} {...props} style={{marginBottom: '70px'}}>
            <>
                {props.faqs.length > 0 ?
                    props.sections
                        .filter((section) => props.faqs.some((faq) => faq.section.id === section.id))
                        .map((section, index) => {
                            return (
                                <Box key={index} sx={{ marginTop: 3, padding: 1 }}>
                                    <Typography
                                        sx={{
                                            textAlign: props.width > 600 ? "left" : "center",
                                            fontSize: props.width > 600 ? "25px" : "20px",
                                            marginLeft: 1,
                                        }}
                                    >
                                        {section.title}
                                    </Typography>
                                    {props.getAccordionContent(section, props.faqs)}
                                </Box>
                            )
                        }) : <></>}
            </>
        </div>
    )
})

export const HelpContent = (props) => {
    const { language, page, translations } = props
    const [sections, setSections] = useState<FaqSection[]>([])
    const [faqs, setFaqs] = useState<Faq[]>([])
    const [titles, setTitles] = useState<any>()
    const [content, setContent] = useState<any>()
    const [init, setInit] = useState(false)

    const { width } = useWindowDimensions()

    const loadSections = async () => {
        setInit(false)
        const options = new QueryOptions()
        options.lang = language
        const resp = await faqSectionService.list(options)
        setSections(resp.results)
        loadFaqs()
    }

    const loadFaqs = async () => {
        const options = new QueryOptions()
        options.lang = language
        options.pageSize = 40
        const resp = await faqService.list(options)
        setFaqs(resp.results)
        setInit(true)
    }

    const loadInfoPage = async (id): Promise<any> => {
        setInit(false)
        const options = new QueryOptions()
        options.lang = language
        const page = (await infoPageContentService.read(id, options)) as any
        setContent(page)
        setInit(true)
    }

    useEffect(() => {
        if (page === "faq") {
            setTitles(null)
            setContent(null)
            loadSections()
        } else {
            setTitles(null)
            setContent(null)
            loadInfoPage(page)
        }
    }, [page])

    const getAccordionContent = (section, faqs) => {
        return <RenderAccordion {...props} section={section} faqs={faqs} />
    }

    return (
        <Box sx={{ width: width > 600 ? width : "auto" }}>
            {page === "faq" ? (
                <Fade
                    in={init}
                    {...(init && sections && faqs ? { timeout: 600 } : {})}
                    style={{ width: "100%", visibility: init ? "visible" : "hidden" }}
                >
                    <RenderAccordionComponent
                        width={width}
                        sections={sections}
                        getAccordionContent={getAccordionContent}
                        faqs={faqs}
                        {...props}
                    />
                </Fade>
            ) : (
                <Fade
                    in={init}
                    {...(init ? { timeout: 600 } : {})}
                    style={{ width: "100%", visibility: init ? "visible" : "hidden" }}
                >
                    <RenderContent {...props} content={content} width={width} />
                </Fade>
            )}
        </Box>
    )
}
