import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { Faq, FaqSection } from "./faq"


export class FaqSectionSerializer implements Serializer {
    fromJson(json: any) {
        const faq = new FaqSection();
        faq.id = json.pk;
        faq.title = json.title;
        return faq;
    }
    toJson(item: any) {
        return {
            
        }
    }
}

export class FAQSerializer implements Serializer {
    fromJson(json: any) {
        const faq = new Faq();
        faq.question = json.question;
        faq.section = faqSectionSerializer.fromJson(json.section)
        faq.sort = json.sort;
        faq.text = json.text;

        return faq;
    }

    toJson(item: Faq): any {
        return {

        };
    }
}

export const faqSectionSerializer = new FaqSectionSerializer()
export const faqSerializer = new FAQSerializer()