import React, { useCallback, useEffect, useState, } from "react"
import { Box, CircularProgress, Grid } from "@mui/material"
import { register } from "../models/user/authActions"
import { connect } from 'react-redux'
import { useHistory, useLocation, useParams } from "react-router"
import { QueryOptions } from "../helpers/query.options"
import { productService } from "../models/product/service"
import { Product } from "../models/product/product"
import { ProductItem } from "../components/Product/ProductItem"
import { useQuery } from "../helpers/utilities"
import { makeStyles } from "@mui/styles"
import { Category } from "../models/category/category"
import { FinderComponent } from "../components/Finder/FinderComponent"
import { Finder } from "../models/finder/Finder"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
  },
  leftcolumn: {
    padding: 10
  }
})


const ProductLinePage = (props) => {
  const { language, translations, app } = props
  const [options, setOptions] = useState<any>(new QueryOptions())
  const classes = useStyles()
  let query = useQuery()
  const [finder, setFinder] = useState<Finder>()
  const [pageIndex, setPageIndex] = useState(1)
  const [productCount, setProductCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [finderDialogOpen, setFinderDialogOpen] = useState(false)

  let { id }: any = useParams()
  const [products, setProducts] = useState<Product[]>([])
  const [filterItems, setFilterItems] = useState<any>()


  useEffect(() => {
    if (id) {
      loadProducts(id)
    }
  }, [id])

  const loadProducts = (productLine: string) => {
    setLoading(true)
    setProducts([])
    options.product_line = productLine
    options.pageIndex = query.get("pageIndex") ? Number(query.get("pageIndex")) : 1
    options.pageSize = query.get("pageSize") ? Number(query.get("pageSize")) : 24
    productService.listWithCounter(options).then(r => {
      if (!r.status) {
        setProducts(r.current)
        setProductCount(r.count)
        setLoading(false)
      }
    })
  }

  const RenderProductList = useCallback(() => {
    return <Grid container
      spacing={1}
      style={{ padding: 0, alignItems: 'center', marginTop: 40, margin: '0px auto' }}>
      {[...products].map((item, index) => {
        return <Grid item lg={3} sm={2} key={index}>
          <ProductItem key={index} item={item} {...props} />
        </Grid>
      })}
    </Grid>
  }, [products])

  const RenderMobileProductList = useCallback(() => {
    return <Box
      id="main"
      style={{ padding: 0, alignItems: 'center', marginTop: 40, flexWrap: 'wrap', display: 'flex', flexGrow: 1, width: window.innerWidth }}>
      {[...products].map((item, index) => {
        return <Box key={index} style={{
          maxWidth: '50%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          width: `${1 / (app?.dimensions?.width / 180) * 100}%`
        }}>
          <ProductItem key={index} item={item} {...props} />
        </Box>
      })}
    </Box>
  }, [products])


  const RenderContent = useCallback(() => {
    return <Grid container spacing={0} className="container" style={{ width: 
    window.innerWidth > 1200 ? 
    
    1200: window.innerWidth, margin: '0px auto', position: 'relative' }}>
      <Grid item lg={10} className="inner-container" style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        flex: 1,
        alignSelf: 'flex-start'
      }}>
        {loading ? <div style={{
          height: 500, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
        }}>
          <CircularProgress color='inherit' />
        </div> :
          window.innerWidth > 1200 ?
            <RenderProductList /> :
            <RenderMobileProductList />
        }
        <div style={{ height: 120, marginTop: 40 }}>
        </div>
      </Grid>

    </Grid>
  }, [pageIndex, products, filterItems, loading])

  return <div className={classes.root} style={{ position: 'relative', padding: window.innerWidth > 1200 ? 20: 0, paddingBottom: 10 }}>

    <RenderContent />
    {/* {finder && <a onClick={() => {
      setFinderDialogOpen(true)
    }}>
      open me</a>} */}
    <FinderComponent link={"/catalog"}
      open={finderDialogOpen}
      onCloseDialogEvent={(evt) => {
        setFinderDialogOpen(evt)
      }}
      setFinderItem={(item) => {
        setFinder(item)
      }} />
  </div >
}


const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    translations: state.translations,
    language: state.language
  }
}

export default connect(mapStateToProps, { register })(ProductLinePage)