import React, { useState, useEffect } from 'react';
import "react-multi-carousel/lib/styles.css";
import { brandSliderService } from '../../models/MainPageBrandBanner/service';
import { QueryOptions } from '../../helpers/query.options';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";

export const MobileBrandCarouselComponent = (props: any) => {
    const { onRedirect, inputBrands } = props
    const [brands, setBrands] = useState<any[]>([])

    const loadBrandCarousel = () => {
        brandSliderService.list(new QueryOptions()).then((resp: any) => {
            setBrands(resp.results)
        })
    }

    useEffect(() => {
        loadBrandCarousel()
    }, [])


    const items = () => {
        if (inputBrands?.length) {
            return inputBrands
        } else {
            return brands
        }
    }

    return <div style={{
        position: 'relative',
        marginTop: 40,
        height: 60,
        width: window.innerWidth,
        border: '1px solid black',
        padding: 0,
        paddingTop: 5,
        borderLeftColor: 'white',
        borderRightColor: 'white'
    }}>
        <Box sx={{ overflow: 'hidden', height: '100%' }} >
            {(inputBrands?.length || brands?.length > 0) && <Swiper
                slidesPerView={'auto'}
                spaceBetween={50}
                navigation={false}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}

                modules={[Navigation, Autoplay]}
                className="mySwiper"
            >
                {items().map((item, index) => {
                    return <SwiperSlide
                        key={index}
                        onClick={() => onRedirect(item.id)}
                        style={{
                            display: 'inline-block',
                            backgroundImage: `url(${item.logo})`,
                            backgroundSize: 'contain',
                            width: 200,
                            height: 200,
                            maxHeight: 65,
                            maxWidth: 115,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            transform: "translateY(-5px)",
                        }}>
                    </SwiperSlide>
                })}
            </Swiper>}
        </Box>
    </div>
}

