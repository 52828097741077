import React, { Fragment, useState, useEffect } from 'react';
import { Box, Divider, Fade, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { RenderCatText } from '../../Filter/CategoriesList';
import { BrandLink } from '../../../models/brand/brands';
import { useHistory } from 'react-router';


export const BrandCategoryList = (props) => {
    const { brand } = props
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [loadedLogo, setLoadedLogo] = useState(false)
    const [items, setItems] = useState<BrandLink[]>([])
    const history = useHistory()
    useEffect(() => {
        setItems(brand?.links)
    }, [brand])

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        
        
        setSelectedIndex(index);
    };

    return <Fragment>

        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'white', paddingTop: 6.2,  }}>
            <List component="nav" aria-label="secondary mailbox folder">
                {items.map((item, index) => {
                    return <ListItemButton
                        style={{ height: 25, backgroundColor: 'white', padding: 0 }}
                        key={index}
                        selected={selectedIndex === 3}
                        onClick={(event) => {
                            history.push(item.url)
                            handleListItemClick(event, 3)}}
                    >
                        <RenderCatText item={item} {...props} index={index} key={index}/>
                    </ListItemButton>
                })}
            </List>
        </Box>

    </Fragment>
}