import { Branch } from "../branch/branch";
import { Resource } from "../resource"


export class EmbeddedInventory extends Resource {
    sku: string;
    branch: Branch;
    available: boolean;
    isLast: boolean
}
