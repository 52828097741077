
import { connect } from 'react-redux'
import React from "react"
import CloseIcon from '@mui/icons-material/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { Box, Dialog, Grid, Typography } from "@mui/material"

const OrderDetailsDialog = (props: any) => {
    const { order, onCloseDialog, onReload, translations } = props
    return <div>
        <Dialog
            maxWidth='xl'
            style={{ padding: window.innerWidth > 1200 ? 20 : 5 }}
            open={props.onOpen}
            onClose={onCloseDialog}
            aria-labelledby="form-dialog-title"
        >
            <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => {
                onCloseDialog(false)
            }}>
                <CloseIcon />
            </div>
            {order && <Box sx={{ width: window.innerWidth > 900 ? 800 : 'auto', marginTop: 0, padding: 2 }}>
                <Typography variant="subtitle1" component="h5" style={{ fontWeight: 'bold', fontSize: 14 }}>{translations?.date}: {order.created}</Typography>
                <Typography variant="subtitle1" component="h5" style={{ fontWeight: 'bold', fontSize: 14 }}>{translations?.order_id}: {order.id}</Typography>
                <br></br>
                <br></br>

                <Grid container>
                    <Grid item
                        style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', textAlign: 'center', padding: window.innerWidth > 900 ? 0 : 10 }}
                        lg={2}
                        sm={4}
                        xs={12}>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold', fontSize: 13 }}>
                            {translations?.address}
                        </Typography>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: '400' }}>
                            {order?.address.street}
                        </Typography>
                    </Grid>
                    <Grid item
                        style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', padding: window.innerWidth > 900 ? 0 : 10 }}
                        lg={2}
                        sm={4}
                        xs={12}>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold' }}>
                            {translations?.status}
                        </Typography>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: '4' }}>
                            {order?.status}
                        </Typography>
                    </Grid>
                    <Grid item
                        style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', padding: window.innerWidth > 900 ? 0 : 10 }}
                        lg={2}
                        sm={4}
                        xs={12}>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold' }}>
                            {translations?.total}
                        </Typography>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold' }}>
                            {order?.totalToPay} ₾
                        </Typography>
                    </Grid>
                    <Grid item
                        style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', padding: window.innerWidth > 900 ? 0 : 10 }}
                        lg={2}
                        sm={4}
                        xs={12}>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold' }}>
                            {translations?.payment}
                        </Typography>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: '400' }}>
                            {order?.paymentMethod.name}
                        </Typography>
                    </Grid>
                    <Grid item
                        style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', padding: window.innerWidth > 900 ? 0 : 10 }}
                        lg={2}
                        sm={4}
                        xs={12}>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold' }}>
                            {translations?.delivery_method}
                        </Typography>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: '400' }}>
                            {order?.shippingMethod.name}
                        </Typography>
                    </Grid>
                    <Grid item
                        style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', padding: window.innerWidth > 900 ? 0 : 10 }}
                        lg={2}
                        sm={4}
                        xs={12}>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold' }}>
                            {translations?.shipping_cost}
                        </Typography>
                        <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold' }}>
                            {order?.shippingCost} ₾
                        </Typography>
                    </Grid>
                </Grid>
                <br></br>
                <Typography variant="subtitle1" component="h5" style={{ fontWeight: 'bold', fontSize: 14 }}>{translations?.items}</Typography>
                <br></br>
                <Grid container>
                    {order?.items.map((item, index) => {
                        return <Grid item
                            style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', padding: window.innerWidth > 900 ? 0 : 10 }}
                            lg={4}
                            sm={4}
                            xs={12}>
                            <Grid item
                                style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}
                                lg={6}
                                sm={4}
                                xs={12}>
                                <img src={item.product.mainImage} style={{ width: '80%' }} />
                            </Grid>
                            <Grid item
                                style={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}
                                lg={6}
                                sm={4}
                                xs={6}>
                                <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold', fontSize: 13 }}>
                                    {item.product.name}
                                </Typography>
                                <Typography variant="subtitle2" component="h5" style={{ fontWeight: '500', fontSize: 14 }}>
                                    {item.specialPrice} ₾
                                </Typography>
                                <Typography variant="subtitle2" component="h5" style={{ fontWeight: 'bold', fontSize: 13 }}>
                                    X {item.quantity}
                                </Typography>
                            </Grid>
                        </Grid>
                    })}

                </Grid>
            </Box>}
        </Dialog>
    </div>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(OrderDetailsDialog)