import { City } from "../city/city";
import { Resource } from "../resource";


export class GiftCard extends Resource {
    type: string
    qty : number
    amount : number
}

export class GiftCardTemplate extends Resource {
    name: string | undefined
    image: string | undefined
    category: GiftCardTemplateCategory
    
}
export class GiftCardTemplateCategory extends Resource {
    name : string
    sort: number
}


