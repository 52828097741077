import { ResourceProvider } from "../../providers/resource.provider"
import { ShippingMethodSerializer } from "../shipping/serializer"
import { ShippingMethod } from "../shipping/ShippingMethod"


export class ShippingMethodProvider extends ResourceProvider<ShippingMethod> {
    constructor() {
        super("rest_shipping_method_with_city", new ShippingMethodSerializer())
    }
}

export const shippingMethodService = new ShippingMethodProvider()

