import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ProductItem } from '../Product/ProductItem';
import { Title } from '../Typography/Title';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const MobileMainPageProductSliderComponent = (props: any) => {
    const { slider, translations, language } = props
    const {width} = useWindowDimensions()

    let carousel: any

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 564, min: 0 },
            items: 2.5
        }
    }


    const CustomRightArrow = ({ onClick, ...rest }) => {
        return <button />
    }

    return slider ? <div style={{
        position: 'relative',
        marginTop: width < 600 ? -20 : 20,
        paddingTop: width < 600 ? -20 : 20,
        height: 250,
        width: window.innerWidth,
        textAlign: 'center',
        border: '1px solid white',
        borderLeftColor: 'white',
        borderRightColor: 'white'
    }}>
        <div style={{ position: 'relative', right: 0, zIndex: 100, top: 0, width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }} >
            <Title text={` ${slider.title}`} fontSize={25} uppercase={true} fontWeight='700' fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'} color={"#222"} />
        </div>

        {slider?.products?.length && <Swiper
            slidesPerView={window.innerWidth > 300 ? window.innerWidth > 550 ? window.innerWidth > 700 ? window.innerWidth > 1200 ? 5 : 4 : 3 : 2 : 1}
            spaceBetween={30}
            loop={true}
            navigation={window.innerWidth > 1200 ? true : false}
            modules={[Navigation]}
            className="mySwiper"
        >
            {slider?.products?.map((item, index) => {
                return <SwiperSlide key={index}>
                    <ProductItem  item={item} {...props} />
                </SwiperSlide>
            })}
        </Swiper>}
    </div> : <></>
}