import { SnackbarOrigin } from "@mui/material";
import { CartItem } from "../cart/cart";
import { FavoriteItem } from "../favorite/favorite";


export const SET_SNACK_BAR = "SET_SNACK_BAR"
export const SET_CART = "SET_CART"
export const SET_APP_LOADED = "SET_APP_LOADED"
export const SET_FAVORITES = "SET_FAVORITES"
export const SET_CONFIRMATION = "SET_CONFIRMATION"
export const SET_GO_TO_CART_DIALOG = "SET_GO_TO_CART_DIALOG"
export const SET_DIMENSIONS = "SET_DIMENSIONS"
export const SET_SELECTED_QUESTION = "SET_SELECTED_QUESTION"

export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}

export const setSnackBar = (snackBar: State) => {
    return { type: SET_SNACK_BAR, content: { snackBar} } 
}
export const setDimensions = (dimensions: State) => {
    return { type: SET_DIMENSIONS, content: { dimensions} } 
}
export const setGoToCartDialog = (goToCartDialog: boolean) => {
    return { type: SET_GO_TO_CART_DIALOG, content: { goToCartDialog} } 
}
export const setAppLoaded = (loaded: boolean) => {
    return { type: SET_APP_LOADED, content: { loaded} } 
}
export const setCart = (cart: CartItem[]) => {
    return { type: SET_CART, content: { cart} } 
}
export const setFavorites = (favorites: FavoriteItem[]) => {
    return { type: SET_FAVORITES, content: { favorites} } 
}
export const setConfirmation = (confirmation: any) => {
    return { type: SET_CONFIRMATION, content: { confirmation} } 
}

