import React, { useEffect, useState, } from "react"
import { register } from "../../models/user/authActions"
import { connect, Omit } from 'react-redux'
import { useHistory, useLocation, useParams } from "react-router"
import { makeStyles } from "@mui/styles"
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { generateParamsFromUrl, generateParamsFromUrlWithoutPrice, generateUrl } from "../../helpers/utilities"
import { QueryOptions } from "../../helpers/query.options"
import { environment } from "../../environment/environment"
import { attributeSetService } from "../../models/attribute/service"
import { Attribute, AttributeSet } from "../../models/attribute/attribute"
import { categoryService } from "../../models/category/service"
import { Category } from "../../models/category/category"
import FilterDialog from "../../components/Dialogs/FilterDialog"
import { CatalogContentDesktop } from "./CatalogContentDesktop"
import { CatalogContentMobile } from "./CatalogContentMobile"
import { Finder } from "../../models/finder/Finder"
import { productService } from "../../models/product/service"
import { Product } from "../../models/product/product"
import SortDialog from "../../components/Dialogs/SortDialog"
export const onPriceMove$: any = new Subject().pipe(
    debounceTime(1000)
)

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    paper: {
        textAlign: "center",
    },
    leftcolumn: {
        padding: 10
    }
})

const CatalogMainPage = (props) => {
    const { translations, language, app } = props
    let { child, cat, parent }: any = useParams()
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const [finder, setFinder] = useState<Finder>()
    const [selectedFilters, setSelectedFilters] = useState<any[]>([])
    const [queryParams, setQueryParams] = useState<QueryOptions>()
    const [attributeSet, setAttributeSet] = useState<AttributeSet>()
    const [filters, setFilters] = useState<Attribute[]>([])
    const [bannerCat, setBannerCat] = useState<Category>()
    const [filterItem, setFilterItem] = useState<Attribute>()
    const [isVisible, setIsVisible] = useState(false)
    const [categories, setCategories] = useState<Category[]>([])
    const [breadCrumbList, setBreadCrumbList] = useState<any[]>([])
    const [filterOpen, setFilterOpen] = useState(false)
    const [sortOpen, setSortOpen] = useState(false)
    const sortObj = localStorage.getItem("sort")
    const [sort, setSort] = useState(sortObj ? JSON.parse(sortObj) : { sort: "uploaded", order: "desc" })
    const [products, setProducts] = useState<Product[]>([])
    const [productCount, setProductCount] = useState(0)


    useEffect(() => {
        loadCategories()
    }, [])

    useEffect(() => {
        if (categories.length && translations?.home) {
            const arr = []
            arr.push({ name: translations?.home, link: '/' })
            if (cat) {
                const [ct] = categories.filter(c => c.id === cat)
                setBannerCat(ct)
              
                if (ct) {
                    arr.push({ name: ct.title, link: `/catalog/${cat}` })
                }
            }
            if (parent && cat) {
                const [ct] = categories.filter(c => c.id === cat)
                if (ct) {
                    const [pr] = ct.children.filter(c => c.id === parent)
                    setBannerCat(pr)
                    if (pr) {
                        arr.push({ name: pr.title, link: `/catalog/${cat}/${parent}` })
                    }
                }
            }
            if (parent && cat && child) {
                const [ct] = categories.filter(c => c.id === cat)
                if (ct) {
                    const [pr] = ct.children.filter(c => c.id === parent)
                    if (pr) {
                        const [ch] = pr.children.filter(c => c.id === child)
                        setBannerCat(ch)
                        if (ch) {
                            arr.push({ name: ch.title, link: `/catalog/${cat}/${parent}/${child}` })
                        }
                    }
                }
            }
            setBreadCrumbList(arr)
        }
    }, [categories, cat, translations, parent, child])


    useEffect(() => {
        let isCancelled = false
        if (location?.pathname && !isCancelled) {
            setIsVisible(false)
            setTimeout(() => {
                setIsVisible(true)
            }, 200)
            const params = generateParamsFromUrl(location.search)
            const urlParams = location.pathname.split("/")
            let cats: any = []
            if (urlParams.length === 3) {
                cats.push(urlParams[2])
                loadAttributeSet(urlParams[2].replace("-", "_"), 'child')
            }
            if (urlParams.length === 4) {
                cats.push(urlParams[2])
                cats.push(urlParams[3])
                loadAttributeSet(urlParams[3].replace("-", "_"), 'child')
            }
            if (urlParams.length === 5) {
                cats.push(urlParams[2])
                cats.push(urlParams[3])
                cats.push(urlParams[4])
                loadAttributeSet(urlParams[4].replace("-", "_"), 'child')
            }
            if (cats.length) {
                params.a_category = cats.toString()
            }
            /* if (urlParams.length === 4) {
                loadAttributeSet(urlParams[3].replace("-", "_"), 'child')
            } else {
                loadAttributeSet("all", 'cat')
            } */
            setQueryParams(params)
        }

        return () => {
            isCancelled = true
        }
    }, [location])

    const loadCategories = () => {
        const options = new QueryOptions()
        options.lang = language
        options.pageSize = 60
        categoryService.listWithCounter(options).then((r) => {
            setCategories(r.current)
        })

    }

    const appendAttributesToUrl = (params) => {
        const options: any = generateParamsFromUrl(location.search)
        options.init = false
        const url = generateUrl(
            environment.params,
            selectedFilters,
            `${location.pathname}`,
            params.pageIndex,
            params.pageSize,
            params.sort,
            params.order,
            params.search,
            params.min_price,
            params.max_price,
            params.category
        )
        return url
    }

    const loadAttributeSet = (id, level) => {
        const options = new QueryOptions()
        options.lang = language
        attributeSetService.read(id, options).then((r: any) => {
            if (!r.attributes) {
                if (level === 'child') {
                    loadAttributeSet(parent, 'parent')
                } else if (level === 'parent') {
                    loadAttributeSet(cat, 'cat')
                }
            } else {
                const attributes = r.attributes
                attributes.push({ id: "price", value: translations?.price })
                setAttributeSet(r)
            }
        })
    }

    


    return <div className={classes.root} style={{ position: 'relative', padding: window.innerWidth > 1200 ? 20 : 0 }}>
        {app.dimensions.width > 1200 ?
            <CatalogContentDesktop
                bannerCategory={bannerCat}
                {...props}
                filters={filters}
                setFilterItem={setFilterItem}
                breadCrumbList={breadCrumbList}
                filterItem={filterItem}
                categories={categories}
                cat={cat}
                parent={parent}
                queryParams={queryParams}
                location={location}
                history={history}
                child={child}
                appendAttributesToUrl={appendAttributesToUrl}
                translations={translations}
                selectedFilters={selectedFilters}
                setFilters={setFilters}
                attributeSet={attributeSet}
            /> :
            <CatalogContentMobile
                {...props}
                filters={filters}
                setFilterItem={setFilterItem}
                breadCrumbList={breadCrumbList}
                filterItem={filterItem}
                categories={categories}
                cat={cat}
                parent={parent}
                queryParams={queryParams}
                location={location}
                history={history}
                setFilterOpen={setFilterOpen}
                setSortOpen={setSortOpen}
                child={child}
                appendAttributesToUrl={appendAttributesToUrl}
                translations={translations}
                selectedFilters={selectedFilters}
                setFilters={setFilters}
                attributeSet={attributeSet}
            />
        }
        {/* {finder && <a onClick={() => {
            setFinderDialogOpen(true)
        }}>
            Open Finder</a>}
        <FinderComponent link={"/catalog"}
            open={finderDialogOpen}
            onCloseDialogEvent={(evt) => {
                setFinderDialogOpen(evt)
            }}
            setFinderItem={(item) => {
                setFinder(item)
            }} /> */}
        {window.innerWidth < 1200 && translations && <FilterDialog
            {...props}
            sort={sort}
            translations={translations}
            filterItem={filterItem}
            filters={filters}
            setFilterItem={setFilterItem}
            location={location}
            onSetSort={(sort) => {
                setSort(sort)
                localStorage.setItem("sort", JSON.stringify(sort))
                const options = generateParamsFromUrlWithoutPrice(location.search)
                options.sort = sort.sort
                options.order = sort.order
                const url = `${location.pathname}`
                history.push(options.toQueryStringWithUrl(url))
                setQueryParams(options)
                queryParams.order = sort.order
                queryParams.sort = sort.sort
                productService.listWithCounter(queryParams).then((r: any) => {
                    setProducts(r.current)
                    setProductCount(r.count)
                })
            }}
            setFilters={setFilters}
            attributeSet={attributeSet}
            selectedFilters={selectedFilters}
            open={filterOpen}
            onCloseDialog={(resp) => {
                setFilterOpen(resp)
            }}
        />}
        {window.innerWidth < 1200 && translations && <SortDialog
            {...props}
            sort={sort}
            translations={translations}
            filterItem={filterItem}
            filters={filters}
            setFilterItem={setFilterItem}
            location={location}
            onSetSort={(sort) => {
                setSort(sort)
                localStorage.setItem("sort", JSON.stringify(sort))
                const options = generateParamsFromUrlWithoutPrice(location.search)
                options.sort = sort.sort
                options.order = sort.order
                const url = `${location.pathname}`
                history.push(options.toQueryStringWithUrl(url))
                setQueryParams(options)
                queryParams.order = sort.order
                queryParams.sort = sort.sort
                productService.listWithCounter(queryParams).then((r: any) => {
                    setProducts(r.current)
                    setProductCount(r.count)
                })
            }}
            setFilters={setFilters}
            attributeSet={attributeSet}
            selectedFilters={selectedFilters}
            open={sortOpen}
            onCloseDialog={(resp) => {
                setSortOpen(resp)
            }}
        />}
    </div >
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        translations: state.translations,
        language: state.language,
        app: state.app
    }
}

export default connect(mapStateToProps, { register })(CatalogMainPage)