import React, { useState, useEffect } from 'react'
import { COLORS } from "../../constants/colors"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { QueryOptions } from "../../helpers/query.options"
import { MainPageSlide } from "../../models/MainPageBrandBanner/MainPage"
import { mainPageSliderService } from "../../models/MainPageBrandBanner/service"
import { useHistory } from "react-router"
import { MobileMultiCarouselComponent } from "./MobileMultiCarousel"

export const MainPageSliderItem = (props) => {
    const { item } = props
    const history = useHistory();
    return <div style={{ textAlign: 'center', width: 400, height: 400 }}>
        <div style={{ paddingLeft: '25%', width: '100%', height: "100%", margin: 'auto 0', position: 'relative' }}>
            <div style={{
                padding: '0%', width: '50%', margin: 'auto 0', backgroundColor: 'transparent', height: 300,
                backgroundImage: `url(${item.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>

            </div>
            <div style={{
                top: 20, zIndex: -10,
                right: 400 / 2 - 20,
                height: 300,

                width: '40%', backgroundColor: COLORS.BG_BEIGE_GRAY, position: 'absolute'
            }}>
            </div>
            <div style={{
                flexDirection: 'column',
                position: 'relative',
                top: 40,
                bottom: 10, zIndex: 200, alignSelf: 'flex-start', display: 'flex', width: '60%', textAlign: 'center'
            }}>
                <div style={{ alignSelf: 'center', textAlign: 'center', flexDirection: 'row' }}>
                    <div style={{ fontSize: 24, display: 'inline-block', fontWeight: 'bold' }} onClick={() => {
                        history.push(item.url)
                    }}>
                        {item.title}
                    </div>
                    <div style={{ top: 20, display: 'inline-block' }}>
                        <div style={{ top: 5, position: 'absolute' }}>
                            <ArrowForwardIosIcon fontSize={'large'} style={{fontSize: 22}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


export const MobileMainPageSlider = (props) => {
    const [slides, setSlides] = useState<MainPageSlide[]>([])
    const [sldeItems, setSlideItems] = useState<any[]>([])

    const loadBrandCarousel = () => {
        const options = new QueryOptions()
        options.sort = "sort"
        options. order = "asc"
        mainPageSliderService.listWithCounter(options).then((resp: any) => {
            if (resp.current.length) {
                setSlides(resp.current)
            }
        })
    }

    useEffect(()=> {
        loadBrandCarousel()
    }, [])
    
    useEffect(()=> {
        if (slides?.length) {
            slides.forEach(element => {
                if (element.type === 'small') {
                    element.embeddedItems.forEach(item => {
                        setSlideItems(slds => [...slds, item])
                    })
                } else {
                    setSlideItems(slds => [...slds, element])
                }
            });
        }
    }, [slides])

    return <MobileMultiCarouselComponent items={sldeItems} />
}


