import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { GiftCardCheckoutFirstStep } from "./GiftCardCheckoutFirstStep"
import { GiftCardCheckoutSecondStep } from "./GiftCardCheckoutSecondStep"
import { GiftCardCheckoutThirdStep } from "./GiftCardCheckoutThirdStep"
import { GiftCardCheckoutFourthStep } from "./GiftCardCheckoutFourthStep"
import { GiftCardCheckoutFifthStep } from "./GiftCardCheckoutFifthStep"
import { GiftCardCheckoutSummaryComponent } from "./GiftCardCheckoutSummaryComponent"
import { QueryOptions } from "../../helpers/query.options"
import { giftCardTemplateCategoryService, giftCardTemplateService } from "../../models/giftCard/service"
import { GiftCardTemplate, GiftCardTemplateCategory } from "../../models/giftCard/GiftCard"

export const GiftCardCheckoutComponent = (props) => {
    const { translations, language } = props
    const { width } = useWindowDimensions()
    const [step, setStep] = useState(1)
    const [giftCardTemplateCategories, setGiftCardTemplateCategories] = useState<GiftCardTemplateCategory[]>([])
    const [giftCardTemplates, setGiftCardTemplates] = useState<GiftCardTemplate[]>([])

    useEffect(()=> {
        loadCategories()
        loadTemplates()
    }, [])

    const loadCategories = () => {
        const options = new QueryOptions()
        giftCardTemplateCategoryService.list(options).then((r: any)=> {
            setGiftCardTemplateCategories(r.results)

        })
    }
    const loadTemplates = () => {
        const options = new QueryOptions()
        giftCardTemplateService.list(options).then((r: any)=> {
            setGiftCardTemplates(r.results)

        })
    }


    return (
        <Box
            sx={{
                margin: "0 auto",
                width: width,
                maxWidth: 1200,
                paddingTop: 10,
                paddingBottom: 20
            }}
        >
            {step === 1 && <GiftCardCheckoutFirstStep {...props} setStep={setStep} />}
            {step === 2 && <GiftCardCheckoutSecondStep {...props} 
                templates={giftCardTemplates}
                onLoadCategories={() => loadCategories()}
                onReset={(template) => {
                    loadCategories()
                    setGiftCardTemplates([...giftCardTemplates.filter(r => r.id !== template.id)])
                }}
                categories={giftCardTemplateCategories}
                setStep={setStep}
                /> }
            {step === 3 && <GiftCardCheckoutThirdStep {...props} setStep={setStep}/>} 
            {step === 4 && <GiftCardCheckoutFourthStep {...props} setStep={setStep}/> }
            {step === 5 && <GiftCardCheckoutFifthStep {...props} setStep={setStep}/> }
            {step === 6 && <GiftCardCheckoutSummaryComponent {...props}/> }
            
        </Box>
    )
}
