import { Box } from "@mui/material"
import React, { Fragment, useCallback, useEffect, useState } from "react"
import { COLORS } from "../../constants/colors"
import { Title } from "../Typography/Title"
import { Animated } from "react-animated-css";
import { ProductPageDetails } from "../Product/ProductPageDetails";


export const ProductDetailsTab = (props) => {
    const { translations, language, product } = props
    const [currentItem, setCurrentItem] = useState("details")
    const [movement, setMovement] = useState("right")
    const [currentIntex, setCurrentIndex] = useState(0)
    const items = [
        {
            id: "details",
            name: translations?.details
        },
        {
            id: "how_to_use",
            name: translations?.how_to_use
        },
        // {
        //     id: "questions_about_product",
        //     name: translations?.questions_about_product
        // },
    ]
    return <Fragment>
        <Box sx={{ width: '60%', height: 50, display: 'flex', flex: 1 }}>
            {items.map((item, index) => {
                return <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap', borderBottom: `3px solid ${currentItem === item.id ? COLORS.BG_GRAY : COLORS.BG_GRAY}`, alignItems: 'center', padding: 2, justifyContent: 'center', position: 'relative'
                }}
                    onClick={() => {

                        setCurrentIndex(ind => {
                            if (index < ind) {
                                setMovement('left')
                                setTimeout(() => {
                                    setCurrentItem(item.id)
                                }, 300)
                            } else {
                                setMovement('right')
                                setTimeout(() => {
                                    setCurrentItem(item.id)
                                }, 300)
                            }
                            return index
                        })
                    }}
                >
                    <Animated
                        animationIn={movement === 'right' ? "fadeInLeft" : "fadeInRight"}
                        animationOut={movement === 'right' ? "fadeOutRight" : "fadeOutLeft"}
                        isVisible={currentItem === item.id}
                        style={{ position: 'absolute', width: '100%', backgroundColor: currentItem === item.id ? COLORS.BLACK : COLORS.BG_GRAY, height: 3, bottom: -3 }}>

                    </Animated>
                    <Title
                        style={{ cursor: 'pointer' }}
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        text={item.name}
                        fontSize={13}
                        uppercase={true}
                        className="roboto"
                        color={currentItem === item.id ? COLORS.BLACK : COLORS.BG_GRAY_I}
                        fontWeight={currentItem === item.id ? 'bold' : '500'} />
                </Box>
            })
            }


        </Box>
        <Box sx={{ width: '100%', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start', display: 'flex', padding: 2 }}>
            {currentItem === 'details' && <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <div dangerouslySetInnerHTML={{ __html: product?.description.replace(/\\n/g, "").replace(/\\r/g, "") }} style={{ overflowY: 'auto', fontSize: 14, width: '94%', paddingRight: 10, marginTop: 10, marginBottom: 10, textAlign: 'left', maxHeight: 360 }}>
                </div>
            </Box>}
            {currentItem === 'how_to_use' && product?.howToUse && <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <div dangerouslySetInnerHTML={{ __html: product?.howToUse.replace(/\\n/g, "").replace(/\\r/g, "") }} style={{ overflowY: 'auto', fontSize: 14, width: '94%', paddingRight: 10, marginTop: 10, marginBottom: 10, textAlign: 'left', maxHeight: 360 }}>
                </div>


            </Box>}
            {currentItem === 'questions_about_product' && <h1></h1>}
        </Box>

    </Fragment>
}