import { ResourceProvider } from "../../providers/resource.provider"
import { Order } from "./Order"
import { OrderSerializer, TBCInstallmentSerializer } from "./serializer"

export class OrderProvider extends ResourceProvider<Order> {
    constructor() {
        super("rest_order", new OrderSerializer())
    }
}
export class OrderCheckProvider extends ResourceProvider<Order> {
    constructor() {
        super("rest_order_check", new OrderSerializer())
    }
}

export class TBCInstallmentProvider extends ResourceProvider<Order> {
    constructor() {
        super("rest_tbc_installment", new TBCInstallmentSerializer())
    }
}

export const orderService = new OrderProvider()
export const orderCheckService = new OrderCheckProvider()
export const tbcInstallmentService = new TBCInstallmentProvider()

