import { Resource } from "../resource";


export class ProductPromoPage extends Resource {
    object: any;
    sort:   number;
    type:   string;
}

export class ProductPromoPageBanner extends Resource {
    image: string;
    imageMobile: string;
    video: string;
    type: string;
}

export class ProductPromoPageTextSection extends Resource {
    color: string;
    text: string;
}
export class ProductPromoPageTitleAndTextSection extends Resource {
    title: string;
    text: string;
}
export class ProductPromoPageKeyNote extends Resource {
    title?:       string;
    text?:        string;
    columns?:     ProductPromoPageColumn[];
}
export class ProductPromoPageCardColumnSection extends Resource {
    columns?:     ProductPromoPageColumn[];
}
export class ProductPromoPageCarousel extends Resource {
    title?:       string;
    text?:        string;
    buttonLink?: string;
    buttonText?: string;
    images?:      ProductPromoPageSectionImage[];
}

export class ProductPromoPageColumn extends Resource {
    image: string;
    sort:     number;
    text:     string;
    title:    string;
}

export class ProductPromoPageSectionImage extends Resource {
    image: string;
    sort:     number;
}
export class ProductPromoPageImageVideo extends Resource {
    image: string;
    video: string;
    sort:     number;
}
