import React, { useState, } from "react";

import { connect } from 'react-redux'
import { Link } from "react-router-dom";
import './styles.scss'


const BreadCrumbsComponent = (props) => {
    const {items} = props

    return <div className={""} style={{marginTop: 10, display: 'inline-flex'}}>
        {items?.map((item, index)=>{
            return <Link
            to={`${item.link}`}
            key={index} style={{display: 'inline-block', margin: 3, textDecoration: 'none', color: '#333', fontSize: 13}}>
                {item.name}
                {index < items?.length - 1 && <div style={{marginLeft: 5, display: 'inline-block'}}>/</div>}
            </Link>
        })}


    </div >
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {})(BreadCrumbsComponent)