import { Box, FormControl, FormHelperText, TextField, useFormControl } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { COLORS } from '../../constants/colors'

import { QueryOptions } from '../../helpers/query.options'
import { Coupon } from '../../models/Coupon/Coupon'
import { couponService } from '../../models/Coupon/service'
import { BlackButton } from '../Button/BlackButton'
import { ConfirmationDialog } from '../Confirmation/ConfirmationDialog'
import { getCartTotal } from '../../helpers/utilities'

function MyFormHelperText(props) {
  const { text, translations } = props
  const { focused } = useFormControl() || {};

  const helperText = React.useMemo(() => {
    if (focused) {
      return "";
    }

    return text?.length ? text : translations.enter_voucher_and_get_discount;
  }, [focused]);

  return <FormHelperText>{helperText}</FormHelperText>;
}
export const CouponComponent = (props) => {
  const { app,
    language,
    onError,
    onAddMore,
    setCoupon,
    coupon,
    onReloadCart,
    translations } = props
  const [couponName, setCouponName] = useState("")
  const [confirmationOpen, setConfirmationOpen] = useState(false)



  const checkCoupon = (id) => {
    const options = new QueryOptions()
    options.lang = language
    couponService.read(id, options).then((r: Coupon) => {
      
      if (!r.isValid) {
        onError()
      }
      if (r.campaign.type === 'absolute') {
        
        
        if (getCartTotal(app.cart) < r.campaign.value && ! r.campaign.isGiftCard) {
          
          onError()
          return
        }
        if (getCartTotal(app.cart) < r.campaign.value * .9) {
          const diff = getCartTotal(app.cart) - r.campaign.value * .9
          onAddMore(Math.abs(diff))
          return
        }
        
      }
      
      try {
        const total = r.products.reduce((total, item: any) => {
          return total += item.discount * item.quantity;
        }, 0)
        console.log(total);
        if (total) {
          setCoupon(r)
        } else {
          onError()
        }
      } catch {
        
        onError();
      }
    })
  }


  useEffect(() => {


    return () => {
    }
  }, [])

  return <Box style={{ display: 'flex', flexDirection: window.innerWidth > 1200 ? 'row' : 'column', width: window.innerWidth > 1200 ? '80%' : '100%', justifyContent: 'center', alignItems: 'flex-start' }}>
    <ConfirmationDialog
      translations={translations}
      open={confirmationOpen}
      title={translations.coupon}
      text={translations.do_you_really_want_to_cancel_coupon}
      setOpen={() => {
        setConfirmationOpen(false)
      }}
      textAccept={translations["yes"]}
      textCancel={translations["no"]}
      onAgreed={() => {
        setCoupon(null)
        onReloadCart()
        setConfirmationOpen(false)
      }} />
    <Box component="form" noValidate autoComplete="off" sx={{ width: '100%' }}>
      <FormControl sx={{ width: window.innerWidth > 1200 ? '90%' : '100%', flexDirection: 'column', display: 'flex', position: 'relative', justifyContent: 'center' }}>
        {coupon?.isValid && <Box
          onClick={() => {
            setConfirmationOpen(true)
          }}
          sx={{
            position: 'absolute', right: 10, top: 10, fontSize: 12,
            height: 18,
            cursor: 'pointer',
            display: 'flex',
            zIndex: 1000,
            border: '1px solid #333',
            backgroundColor: COLORS.BEIGE,
            padding: 1,
            borderRadius: 1,
            align: 'center',
            justifyContent: 'center',
            paddingTop: 1.4
          }}>
          {translations.cancel}
        </Box>}
        <TextField
          disabled={coupon?.isValid}
          InputLabelProps={{ style: { fontSize: 14, fontFamily: 'FiraGo' } }}
          onChange={(evt) => {
            setCouponName(evt.target.value)
          }}
          id="outlined-basic"
          label={coupon?.isValid ? coupon?.name : translations.introduce_coupon} variant="outlined" sx={{}} />
        {coupon && <MyFormHelperText {...props} text={coupon?.isValid ? `${coupon.campaign.name}  ${coupon.campaign.description} - ${coupon.campaign.value} ${coupon.campaign.type === 'percentage' ? '%' : '₾'}` : ''} />}
      </FormControl>

    </Box>
    <Box component="form" sx={{ width: '100%', padding: 0, marginTop: window.innerWidth < 1200 ? 2 : 0 }}>
      <BlackButton
        title={translations?.send}
        height={55}
        onClick={() => {
          if (couponName?.length) {
            checkCoupon(couponName)
          }
        }}
      />
    </Box>

  </Box>

}

