import React, { Fragment, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Fade, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { setCart, setSnackBar, setConfirmation } from '../../models/application/applicationActions';
import { Transition } from '../../helpers/utilities';
import { makeStyles } from '@mui/styles';
import { COLORS } from '../../constants/colors';
import { Title } from '../Typography/Title';
import '../styles.scss'
import { environment } from '../../environment/environment';
import GiftCardForm from '../GiftCard/GiftCardForm';
import { BlackButton } from '../Button/BlackButton';

const useStyles = makeStyles({
    rootRow: {
        width: '100%',
        padding: 0,
        marginTop: 0,
        height: window.innerHeight,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    }
})

const DesktopGiftCardFormDialog = (props) => {
    const {
        app,
        giftCard,
        createGiftCard,
        beigeLoaded,
        address,
        cities,
        options,
        language,
        translations } = props
    const [open, setOpen] = useState(false);

    const classes = useStyles()
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return <Fragment>
        <Box style={{width: '480px', paddingTop: 3, position: 'relative', display: 'flex', flexDirection: 'row', }}>
            <Box style={{ display: 'flex', flexDirection: 'column', width: '100%',paddingBottom: 30, alignItems: 'center' }}>
                <Title
                    text={`${translations.gift_card_purchase}`}
                    fontSize={18} uppercase={true}
                    fontWeight='600'
                    color={"#444"}
                    style={{ marginBottom: 20, width: '100%', textAlign: 'left' }} />
                <Fade
                    in={beigeLoaded}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(beigeLoaded ? { timeout: 2000 } : {})}
                >
                    {<img src={`${environment.basePath}/assets/catalog/product/beige.jpeg`} style={{ width: 480, borderRadius: 10 }} />}
                </Fade>
              
                <Typography 
                        sx={{
                            fontSize:16, 
                            textTransform:'none',
                            fontWeight: 400,
                            color: '#444',
                            lineHeight: 1.2,
                            fontFamily: "FiraGo",
                            textAlign:'left',
                            marginTop: 2,
                            minHeight: language === 'geo' ? 130 : 100,
                            maxHeight: language === 'geo' ? 130 : 100,
                            height: language === 'geo' ? 130 : 100
                        }} >
                       {translations.gift_card_purchase_text}
                    </Typography>
             
                    <Button
                disableRipple
                onClick={handleClickOpen}
                sx={{
                    width: '100%',
                    height: 50,
                    minHeight: 50,
                    maxHeight: 50,
                    borderRadius: 2,
                    backgroundColor: COLORS.BG_DARK,
                    color: 'white',
                    fontWeight:'bold',
                    textTransform: 'none',
                    "&:hover": {
                    backgroundColor: COLORS.BG_DARK,

                    }
                }}>{translations?.add}</Button>
            </Box>
        </Box>

        <Dialog
            maxWidth='md'
            style={{
                padding: 0, 
                minWidth: window.innerWidth > 1200 ? 400 : '100%',
                overflow: 'hidden',
                minHeight: window.innerHeight
            }}
            sx={{
                '&.MuiDialog-paperScrollPaper': {
                    maxHeight: 1200
                }
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer', zIndex: 1000 }} onClick={handleClose}>
                <CloseIcon />
            </div>
            <Box style={{ 
                width: window.innerWidth > 1200 ? 800 : '100%',
                padding: 30, backgroundColor: COLORS.BODY_BG, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Box style={{ display: 'flex', flexDirection: 'column', width: window.innerWidth > 1200 ? '50%' : '100%', padding: 10, 
                maxWidth: window.innerWidth > 1200 ? '100%' : window.innerWidth }}>
                    <Title
                        text={`${translations.gift_card_purchase}`}
                        fontSize={18} uppercase={true}
                        fontWeight='600'
                        color={"#444"}
                        style={{ marginBottom: 20 }} />
                    <Fade
                        in={beigeLoaded}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(beigeLoaded ? { timeout: 2000 } : {})}
                    >
                        {<img src={`${environment.basePath}/assets/catalog/product/beige.jpeg`} style={{ width: window.innerWidth > 1200 ? '100%' : window.innerWidth - 20 }} />}
                    </Fade>
                    <Title text={`${translations.gift_card_purchase_text}`} fontSize={16} uppercase={false} fontWeight='400' color={"#444"} style={{ marginTop: 20, width: window.innerWidth > 1200 ? '100%' : window.innerWidth - 20 }} />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', width: window.innerWidth > 1200 ? '50%' : '100%', backgroundColor: 'transparent', paddingLeft: window.innerWidth > 1200 ? 20 : 10, paddingTop: 20 }}>
                    <GiftCardForm
                        loc="desktop"
                        cities={cities}
                        translations={translations}
                        {...props}
                        address={address}
                        options={options}
                        product={giftCard}
                        onAddToCart={(values) => {
                            createGiftCard(values, language)
                            handleClose()
                            
                        }}
                    />
                </Box>

            </Box>
        </Dialog>
    </Fragment>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, {
    setCart,
    setSnackBar,
    setConfirmation
})(DesktopGiftCardFormDialog)
