import { Box, Button, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Navigation } from "swiper"
import { Mousewheel } from "swiper"
import { Keyboard } from "swiper"
import { Autoplay } from "swiper"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { useHistory } from "react-router"
import { ProductPromoPageCarousel } from "../../models/product/ProductPromoPage"

export const ProductDesignComponent = (props) => {
    const { itemProp, translations, language, id } = props
    const history = useHistory()
    const [item, setItem] = useState<ProductPromoPageCarousel>()

    const { width } = useWindowDimensions()
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(()=> {
        if (itemProp) {
            setItem(itemProp)
        }
    }, [itemProp])

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex)
    }

    const renderIndicators = () => {
        return (
            <>
                {item?.images?.map((item, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                                width: 25,
                                height: 3.5,
                                borderRadius: 2,
                                backgroundColor: index === activeIndex ? "black" : "lightgray",
                                marginRight: 1,
                                display: "inline-block",
                            }}
                        ></Box>
                    )
                })}
            </>
        )
    }

    return (
        <Box
            sx={{
                width: "100%",
                marginBottom: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: 1200,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "100%",
                    gap: 20,
                    "@media (max-width:464px)": {
                        width: "100%",
                        gap: 2,
                        alignItems: "flex-start",
                    },
                }}
            >
                {width > 1200 && (
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <Typography
                            variant="h5"
                            sx={{
                                textAlign: "left",
                                paddingBottom: 2,
                                fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                            }}
                        >
                            {item?.title}
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: "left",
                                paddingBottom: 2,
                                fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                            }}
                        >
                            {item?.text}
                        </Typography>
                        <Button
                            disableRipple
                            onClick={() => history.push(item?.buttonLink)}
                            sx={{
                                color: "white",
                                backgroundColor: "black",
                                padding: 2,
                                fontSize: 12,
                                paddingLeft: 5,
                                paddingRight: 5,
                                fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                                "&:hover": { backgroundColor: "black" },
                            }}
                        >
                            {item?.buttonText}
                        </Button>
                    </Box>
                )}

                <Box sx={{ width: "auto", position: "relative", "@media (max-width:464px)": { width: "90%" } }}>
                    <Swiper
                        onSlideChange={handleSlideChange}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        cssMode={true}
                        navigation={{
                            prevEl: ".details-prev",
                            nextEl: ".details-next",
                        }}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Mousewheel, Keyboard, Autoplay]}
                        slidesPerView={1}
                        centeredSlides={false}
                        className="mySwiper"
                        style={{
                            width: width > 600 ? "585px" : "100%",
                            height: width > 600 ? "585px" : "auto",
                        }}
                    >
                        {item?.images?.map((imageItem, index) => (
                            <Box sx={{ position: "relative" }}>
                                <SwiperSlide
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <img src={imageItem.image} style={{ width: "100%" }} />
                                </SwiperSlide>
                            </Box>
                        ))}
                    </Swiper>
                    <div>{renderIndicators()}</div>

                    {width > 1200 && (
                        <>
                            <Box
                                sx={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "0 auto",
                                    position: "absolute",
                                    zIndex: 300,
                                    bottom: width > 600 ? 300 : "45%",
                                    right: 540,
                                    "@media (max-width:464px)": { gap: 30 },
                                }}
                            >
                                <button
                                    className="details-prev"
                                    style={{
                                        marginRight: "1rem",
                                        width: 100,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                        gap: 2,
                                    }}
                                >
                                    <ArrowBackIosNewIcon sx={{ color: width > 600 ? "black" : "white" }} />
                                </button>
                            </Box>
                            <Box
                                sx={{
                                    alignItems: "center",
                                    margin: "0 auto",
                                    position: "absolute",
                                    zIndex: 300,
                                    left: 550,
                                    bottom: width > 600 ? 300 : "45%",

                                    "@media (max-width:464px)": { gap: 30 },
                                }}
                            >
                                <button
                                    className="details-next"
                                    style={{
                                        marginRight: "1rem",
                                        width: 100,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: "transparent",
                                        border: "none",
                                        cursor: "pointer",
                                        gap: 2,
                                    }}
                                >
                                    <ArrowForwardIosIcon sx={{ color: width > 600 ? "black" : "white" }} />
                                </button>
                            </Box>
                        </>
                    )}
                    {width < 1200 && (
                        <Box sx={{ paddingTop: 2 }}>
                            <Typography
                                variant="h5"
                                sx={{
                                    textAlign: "left",
                                    paddingBottom: 2,
                                    fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                                }}
                            >
                                {item?.title}
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    paddingBottom: 2,
                                    fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                                }}
                            >
                                {item?.text}
                            </Typography>
                            <Button
                                disableRipple
                                onClick={() => history.push(item.buttonLink)}
                                sx={{
                                    color: "white",
                                    backgroundColor: "black",
                                    width: "100%",
                                    padding: 2,
                                    fontSize: 12,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                                    "&:hover": { backgroundColor: "black" },
                                }}
                            >
                                {item?.buttonText}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
