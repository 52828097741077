import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { EmailSubscription } from "./EmailSubscription";



export class EmailSubscriptionSerializer implements Serializer {
    fromJson(json: any) {
        const item = new EmailSubscription();
        item.email = json.email;
        item.lang = json.lang;
        item.created = json.created;
        
        return item;
    }
    toJson(item: EmailSubscription) {
        return {
            lang: item.lang,
            email: item.email
            
        }
    }
}


export const emailSubscriptionSerializerSerializer = new EmailSubscriptionSerializer()