import { Box, Slider } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { environment } from '../../environment/environment';
import { QueryOptions } from '../../helpers/query.options';
import { generateFiltersFromUrl, generateParamsFromUrl, generateUrl, onPriceMove$ } from '../../helpers/utilities';
import { filterService } from '../../models/filter/service';
import { PriceRange } from '../../models/priceRange/PriceRange';
import { priceRangeService } from '../../models/priceRange/service';
import { FilterAccordionItem } from './FilterAccordionItem';
import { FilterAccordionItemColor } from './FilterAccordionItemColor';
import { FilterItem } from './FilterItem';
import { FilterItemColor } from './FilterItemColor';
import { PriceSlider } from './PriceSlider';

export const FilterComponent = (props: any) => {
    const {
        language,
        location,
        translations,
        attributeSet,
        page,
        setFilters,
        filterItem } = props
    const history = useHistory()
    const [selectedFilters, setSelectedFilters] = useState<any[]>([])
    const [filterItems, setFilterItems] = useState<any>()
    const [cat, setCat] = useState("")
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(24)
    const [sort, setSort] = useState("uploaded")
    const [pageUrl] = useState('catalog')
    const [order, setOrder] = useState("desc")
    const [search, setSearch] = useState("")
    const [url, setUrl] = useState("")
    const [child, setChild] = useState("")
    const [parent, setParent] = useState("")
    const [options, setOptions] = useState<any>(new QueryOptions())
    const [cachedObj, setCachedObject] = useState<any>()
    const [loaded, setLoaded] = useState(false)
    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(1000)
    const [minGlobal, setMinGlobal] = useState(0)
    const [maxGlobal, setMaxGlobal] = useState(1000)

    useEffect(() => {
        if (attributeSet?.attributes) {
            loadFilters(attributeSet, options)
        }
    }, [attributeSet])

    useEffect(() => {
        if (filterItem) {
            handleOnChange(filterItem)
        }
    }, [filterItem])

    const getFilters = async () => {
        const prms = await generateFiltersFromUrl(location, language)
        if (prms) {
            setFilters(prms.current)
            setSelectedFilters(prms.current)
        } else {
            setFilters([])
        }
    }

    useEffect(() => {
        setSelectedFilters([])
        
    }, [location.pathname])

    useEffect(() => {
        if (page> 0){
            reloadQueryOptions(selectedFilters, page, [0, 10000])
        }
    }, [page])

    useEffect(() => {
        getFilters()
        const urlParams = location.pathname.split("/")
        let cats: any = []
        if (urlParams.length === 3) {
            setCat(urlParams[2])
        }
        if (urlParams.length === 4) {
            setCat(urlParams[2])
            setParent(urlParams[3])
        }
        if (urlParams.length === 5) {
            setCat(urlParams[2])
            setParent(urlParams[3])
            setChild(urlParams[4])
        }
    }, [location])

    const loadFilter = (id, options) => {
        const opts = new QueryOptions()
        opts.lang = language
        opts.filter = id

        environment.params.forEach(name => {
            if (options[name]) {
                opts[name] = options[name]
            }
        })
        const params = generateParamsFromUrl(location.search)
        Object.keys(params).forEach(name => {
            if (["filter"].indexOf(name) < 0) {
                opts[name] = params[name]
            }
        })
        const urlParams = location.pathname.split("/")
        let cats: any = []
        if (urlParams.length === 3) {
            cats.push(urlParams[2])
        }
        if (urlParams.length === 4) {
            cats.push(urlParams[2])
            cats.push(urlParams[3])
        }
        if (urlParams.length === 5) {
            cats.push(urlParams[2])
            cats.push(urlParams[3])
            cats.push(urlParams[4])
        }
        if (cats.length) {
            opts.a_category = cats.toString()
        }
        opts.lang = language
        filterService.list(opts).then((r: any) => {
            if (cachedObj?.id !== id || cachedObj?.pathname !== location.pathname) {
                setFilterItems(filt => ({ ...filt, [id]: r.results }))
            }
        })
    }

    const loadFilters = (attributeSet, options) => {
        attributeSet?.attributes.forEach((attr, index) => {
            loadFilter(attr.id, options)
        })
        loadFilter('price', options)
        priceRangeService.listArray(options).then((r: PriceRange) => {
            setMinPrice(r.minPrice)
            setMaxPrice(r.maxPrice)
            setMinGlobal(r.minGlobal)
            setMaxGlobal(r.maxGlobal)

        })
    }

    const handleOnChange = (item: any) => {
        let arr: any = [];
        if (selectedFilters.map(i => String(i.id)).indexOf(item.id) > -1) {
            arr = [...selectedFilters.filter(i => i.id !== item.id)]
        } else {
            arr = [...selectedFilters.filter(i => i.id !== item.id), item]
        }
        if (filterItems && filterItems[item.name.id].length > 1) {
            setCachedObject({ id: item.name.id, items: filterItems[item.name.id], pathname: location.pathname })
        }
        setPageIndex(1)
        setSelectedFilters(arr)
        reloadQueryOptions(arr, 1, [0, 10000])
    }

    const reloadQueryOptions = (selectedFilters: any, pageIndex, min_max_price) => {
        const min_price = min_max_price[0]
        const max_price = min_max_price[1]
        const options: any = generateParamsFromUrl(location.search)
        options.init = false
        setOptions(options)
        const url = generateUrl(
            environment.params,
            selectedFilters,
            `${location.pathname}`,
            pageIndex,
            pageSize,
            sort,
            order,
            search,
            min_price, max_price, ""
        )
        if (url.length) {
            history.push(url);
            setUrl(url);
        } else {
            if (loaded) {
                history.push(`/${pageUrl}`);
            }
        }
    }

    return <Box sx={{marginTop: 3}}>
        {translations?.price && attributeSet?.attributes?.map((item, index) => {
            return item.id !== 'color_family' ? environment.filterType === "accordion" ? <FilterAccordionItem
                selectedFilters={selectedFilters}
                items={selectedFilters}
                key={index}
                onClick={handleOnChange}
                item={item}
                filter={filterItems}
                {...props} /> :
                <FilterItem
                    selectedFilters={selectedFilters}
                    items={selectedFilters}
                    key={index}
                    onClick={handleOnChange}
                    item={item}
                    filter={filterItems}
                    {...props} />
                :
                <FilterAccordionItemColor
                    selectedFilters={selectedFilters}
                    key={index}
                    onClick={handleOnChange}
                    item={item} filter={filterItems} {...props}
                />
        })}
        <Box style={{padding: 10}}>
            <PriceSlider
                min={minPrice}
                max={maxPrice}
                minGlobal={minGlobal}
                maxGlobal={maxGlobal}
                reloadQueryOptions={(arr, index, maxPrice) => {
                    reloadQueryOptions(arr, index, maxPrice)
                }}
            />

        </Box>
    </Box >


}
