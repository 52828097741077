import { GiftCardTemplate } from "../giftCard/GiftCard"
import { Resource } from "../resource"

export class GiftCardSecondStepTabs extends Resource {
    label: string
    category: string
}

export class GiftCardImages extends Resource {
    category: string
    images: string[]
}

export class GiftCardOrder extends Resource {
    recipientName?: string
    recipientEmail?: string
    senderName?: string
    amount: string | number
    lang: string = "geo"
    image: string
    message?: string | undefined
    template: string | GiftCardTemplate
}
