import React from 'react';
import { Box } from "@mui/material"
import { BranchItem } from '../Branch/BranchItem';
import { Title } from '../Typography/Title';
import { COLORS } from '../../constants/colors';


export const InventoryItem = (props: any) => {
    const { item, onSelect, activeBranch, translations } = props
    return <Box sx={{
        width: '96%', display: 'flex', flexDirection: 'column', paddingTop: 1,
        position: 'relative'
    }}>
        { <Box sx={{ position: 'absolute', bottom: 15, right: 15, zIndex: 10 }}>
            <Title text={!item.isLast ? translations.in_stock: translations.in_stock}
                fontSize={12}
                color={item.isLast ? COLORS.GREEN: COLORS.GREEN}
            />
        </Box>}
        <BranchItem item={item.branch}
            selected={item?.branch.id === activeBranch?.id}
            setActiveBranch={(branch) => {
                onSelect(branch)
            }} />
        { }
    </Box>
}