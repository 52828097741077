import { ResourceProvider } from "../../providers/resource.provider"
import { EmailSubscription } from "./EmailSubscription"
import { EmailSubscriptionSerializer } from "./serializer"



export class EmailSubscriptionProvider extends ResourceProvider<EmailSubscription> {
    constructor() {
        super("rest_newsletter_subscription", new EmailSubscriptionSerializer())
    }
}


export const emailSubscriptionService = new EmailSubscriptionProvider()

