import { addToFavorites, loadFavorites } from "../../helpers/utilities";
import { Product } from "../product/product";
import { Resource } from "../resource";


export class FavoriteItem extends Resource {
    product: Product
    sku: string
    lang: string
    price: number
    specialPrice: number

    public save = () => {
        
    }

}
export class Favorites extends Resource {
    items: FavoriteItem[]

    save() {
		localStorage.setItem("favorites", JSON.stringify(this.items))
	}
    updateServer(app, auth) {
        this.items.forEach((item: FavoriteItem) => {
            addToFavorites(item.product, item.sku, app, auth)
        })
	}
    async updateServerWithItems(app, auth, setCart) {
        for (const item of app.cart) {
            const e = await addToFavorites(item.product, item.sku,  app, auth)
        }
        this.loadFromServer(setCart)
        
	}
    updateLocal(items) {
        this.items = items
        this.save()
	}
    async loadFromStorage(setFavorites) {
        let favs: any = await localStorage.getItem("favorites")
        if (favs && favs !== "undefined") {
            setFavorites(favs ? JSON.parse(favs): [])
        }
	}
    loadFromServer(setFavorites) {
        loadFavorites(setFavorites)
	}


}