import { ResourceProvider } from "../../providers/resource.provider"

import { GiftCardImages, GiftCardSecondStepTabs, GiftCardOrder } from "./giftcardOrder"
import { GiftCardImageSerializer, GiftCardSecondStepTabSerializer, GiftCardOrderSerializer } from "./serializer"

export class GiftCardSecondStepTabProvider extends ResourceProvider<GiftCardSecondStepTabs> {
    constructor() {
        super("api_endpoint_placeholder", new GiftCardSecondStepTabSerializer())
    }
}

export class GiftCardImagesProvider extends ResourceProvider<GiftCardImages> {
    constructor() {
        super("api_endpoint_placeholder", new GiftCardImageSerializer())
    }
}

export class OrderGiftCardProvider extends ResourceProvider<GiftCardOrder> {
    constructor() {
        super("rest_gift_card_order", new GiftCardOrderSerializer())
    }
}

export const giftCardTabService = new GiftCardSecondStepTabProvider()
export const giftCardImagesService = new GiftCardImagesProvider()

export const orderGiftCardService = new OrderGiftCardProvider()
