import React, { useCallback, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import { Box, List, ListItemButton } from '@mui/material';
import { RenderCatText } from '../Filter/CategoriesList';
import { Title } from '../Typography/Title';

export const SortPopover = (props) => {
    const { language, translations, sort, onSetSort } = props
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const [items, setItems] = useState<any[]>([])

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        onSetSort(items[index])
        setSelectedIndex(index);
        setAnchorEl(null);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (translations?.sort_newest) {
            setItems(
                [{ title: translations?.sort_newest, sort: "uploaded", order: "desc" },
                { title: translations?.price_increasing, sort: "special_price", order: "asc" },
                { title: translations?.price_decreasing, sort: "special_price", order: "desc" }])
        }
        if (!sort?.title) {
            setItems(
                [{ title: translations?.sort_newest, sort: "uploaded", order: "desc" },
                { title: translations?.price_increasing, sort: "special_price", order: "asc" },
                { title: translations?.price_decreasing, sort: "special_price", order: "desc" }])

            onSetSort({ title: translations?.sort_newest, sort: "uploaded", order: "desc" })
        }
    }, [sort])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const RenderSort = useCallback(() => {
        return <Title
            uppercase={false}
            fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
            color={'#333'}
            fontSize={14}
            style={{ padding: 10, paddingLeft: 2 }}
            fontWeight={'bold'}
            text={`${sort?.title}`} />
    }, [sort])

    return (
        <div
            style={{cursor: 'pointer'}}
            onMouseEnter={(event: any) => {
                /* setAnchorEl(event.currentTarget); */
            }}>
            <Box style={{ display: 'flex', flexDirection: 'row', flex: 1, minWidth: 200, justifyContent: 'flex-start' }}
                onClick={(event: any) => {
                    setAnchorEl(event.currentTarget);
                }}>
                <Title
                    uppercase={false}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'#333'}
                    fontSize={14}
                    style={{ padding: 10, paddingRight: 2 }}
                    fontWeight={'400'}
                    text={`${translations.sort_by}`} />
                <RenderSort />

            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            ><List component="nav" aria-label="secondary mailbox folder">
                    {items.map((item, index) => {
                        return <ListItemButton
                            style={{ height: 25, backgroundColor: 'white' }}
                            key={index}
                            selected={selectedIndex === 3}
                            onClick={(event) => handleListItemClick(event, index)}
                        >
                            <RenderCatText item={item} {...props} index={index} key={index} isButton={false} />
                        </ListItemButton>
                    })}
                </List>

            </Popover>
        </div>
    );
}