


import { ResourceProvider } from "../../providers/resource.provider"
import { Banner, GenericBanner } from "./banner"
import { BannerSerializer, GenericBannerSerializer } from "./serialize"

export class BannerProvider extends ResourceProvider<Banner> {
    constructor() {
        super("rest_banner", new BannerSerializer())
    }
}
export class GenericBannerProvider extends ResourceProvider<GenericBanner> {
    constructor() {
        super("rest_generic_banner", new GenericBannerSerializer())
    }
}

export const genericBannerService = new GenericBannerProvider()
export const bannerService = new BannerProvider()

