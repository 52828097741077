import React, { useState, useEffect } from 'react';
import { Box, Button } from "@mui/material"

import { useHistory } from 'react-router';
import { Title } from '../../Typography/Title';

export const BrandVideoComponent = (props: any) => {
    const { language, brand } = props
    const history = useHistory()

    useEffect(() => {

    }, [])

    return brand ? <Box sx={{
        width: window.innerWidth > 1200 ? 1200 : window.innerWidth,
        height: window.innerWidth > 1200 ? 400 : 300,
        alignItems: 'flex-start',
        flexDirection: window.innerWidth > 1200 ? 'row' : 'column',
        display: 'flex', padding: 0, marginBottom: 6
    }}>
        <div style={{ display: 'flex', width: window.innerWidth > 1200 ? '50%' : '100%', height: window.innerWidth > 1200 ? '100%' : 300, padding: window.innerWidth > 1200 ? 0 : 15 }}>
            <iframe style={{ width: window.innerWidth > 1200 ? '100%' : window.innerWidth - 30, height: '100%', border: 0 }} src={brand.youtubeVideoId} title={brand.youtubeVideoTitle}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" >
            </iframe>
        </div>
        {window.innerWidth > 1200 && <div style={{
            display: 'flex', width: '50%', height: '95%', alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column', padding: 10
        }}>
            <Title text={`${brand?.youtubeVideoTitle}`} fontSize={17} uppercase={true} fontWeight='600' color={"#333"} />

            <p style={{ backgroundColor: 'white' }} dangerouslySetInnerHTML={{ __html: brand.youtubeVideoText }}></p>


        </div>}
    </Box> : <></>
}