import React, { Fragment, useState, useEffect } from 'react';
import { Box, Fade, Slide } from '@mui/material';
import BreadCrumbsComponent from '../../BreadCrumbsComponent'
import { BrandCategoryList } from './BrandCategoryList';
import { AboutTheBrandComponent } from './AboutTheBrandComponent';
import { showAbout, showBestsellers } from '../../../helpers/utilities';
import { BrandVideoComponent } from './BrandVideoComponent';
import { BrandPageBottomSectionComponent } from './BrandPageBottomSectionComponent';
import { BrandProductLineCarousel } from '../../Carousel/BrandProductLineCarousel';
import { MainPageProductSliderComponent } from '../../MainPage/MainPagProductSliderComponent';
import { Link } from 'react-router-dom';
import { Title } from '../../Typography/Title';

export const MobileBrandPageHeaderAll = (props) => {
    const { brand, items, translations, productLines } = props
    const [loaded, setLoaded] = useState(false)
    const [loadedLogo, setLoadedLogo] = useState(false)

    useEffect(() => {
        if (brand?.logo) {
            const img = new Image()
            img.src = brand?.logo
            img.onload = () => {
                setTimeout(() => {
                    setLoadedLogo(true)
                }, 500)
            }
        }
        if (window.innerWidth < 1200) {
            if (brand?.bannerMobile) {
                const img = new Image()
                img.src = brand?.bannerMobile
                img.onload = () => {
                    setLoaded(true)
                }
            }
        } else {
            if (brand?.banner) {
                const img = new Image()
                img.src = brand?.banner
                img.onload = () => {
                    setLoaded(true)
                }
            }
        }
    }, [brand])

    return brand ? <Fragment>
        {brand && <div className={"container"} style={{
            display: 'flex',
            width: window.innerWidth,
            paddingTop: 10,
            alignItems: 'flex-start', margin: '0px auto'
        }}>
            {translations && <BreadCrumbsComponent items={[
                { name: translations?.home, link: '/' },
                { name: translations?.brands, link: '/brands' },
                { name: brand?.title, link: `/brand/${brand?.id}` }
            ]} />}

        </div>}


        <Box style={{ width: window.innerWidth, margin: '0px auto', display: 'flex', flexDirection: 'row', marginTop: 0 }}>
            <Box style={{ width: window.innerWidth, backgroundColor: 'transparent', padding: 0 }}>
                <Box style={{
                    padding: 10,
                    marginTop: 0,
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 60, width: 150, overflow: 'hidden'
                }}>
                    <Slide
                        direction="down"
                        in={loadedLogo}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(loaded ? { timeout: 2000 } : {})}
                    >
                        <Box style={{

                        }}>
                            <Box>
                                {brand && <img src={brand.logo} style={{ width: 120 }} />}
                            </Box>
                        </Box>
                    </Slide>
                </Box>

                <Fade
                    in={loaded}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(loaded ? { timeout: 2000 } : {})}
                >
                    {brand && <img src={window.innerWidth > 1200 ? brand.banner : brand.bannerMobile} style={{ width: '100%' }} />}
                </Fade>
                {brand.isLuxury || brand.isNiche && <Box style={{
                    paddingTop: 0,
                    marginTop: 20,
                    marginBottom: 20,
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    height: 100, width: '100%', overflow: 'hidden'
                }}>
                    <Slide
                        direction="down"
                        in={loadedLogo}
                        style={{ transformOrigin: '0 0 0', width: '100%', marginTop: 0 }}
                        {...(loaded ? { timeout: 2000 } : {})}
                    >
                        <Box style={{

                        }}>
                            <Box>
                                {brand && <img src={brand.logo} style={{ width: 150 }} />}
                            </Box>
                        </Box>
                    </Slide>
                </Box>}

                {showAbout(brand) &&
                    <Box style={{ padding: 20 }}>
                        <AboutTheBrandComponent {...props} />
                    </Box>
                }
                {brand?.isLuxury && brand.youtubeVideoId?.length && <BrandVideoComponent brand={brand} />}
                {productLines?.length > 0 && window.innerWidth > 1200 && <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', maxWidth: 1200, marginTop: 20, marginBottom: 60 }}>
                    {productLines.map((item, index) => {
                        return <Link
                            key={index}
                            style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}
                            to={`/product_line/${item.id}`}
                        ><Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box style={{ marginTop: 14, alignItems: 'flex-start', display: 'flex', flexDirection: 'row' }}>
                                    <img src={item.image} style={{ width: '94%' }} />
                                </Box>
                                <Box style={{ marginTop: 14, alignItems: 'flex-start', display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Title text={`${item.title}`} fontSize={16} uppercase={true} fontWeight='700' color={"black"} />
                                    <Title text={`${item.text}`} fontSize={13} uppercase={true} fontWeight='400' color={"black"} />
                                </Box>
                            </Box>
                        </Link>
                    })}
                </Box>}
                {items?.length > 0 && showBestsellers(brand) && items?.length > 5
                    && <Box style={{ width: '100%', marginBottom: 90 }}>
                        <MainPageProductSliderComponent
                            {...props}
                            width={'100%'}
                            slider={{ title: translations?.bestsellers, products: items.filter((i, index) => i) }} />
                    </Box>}
                {brand?.isLuxury && brand.footerBanner?.length && <BrandPageBottomSectionComponent brand={brand} />}
            </Box>
        </Box>

    </Fragment> : <></>
}