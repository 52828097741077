import { Address } from "../address/address";
import { NameIdItem } from "../common/common";
import { Resource } from "../resource";
import { User } from "../user/user";

export class Branch extends Resource {
  uid: string;
  address: Address;
  openTime: string;
  closeTime: string;
  phone: string;
  workingHours: string
}
