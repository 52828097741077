import { ProductSerializer } from "../product/serializer"
import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { FavoriteItem } from "./favorite"

export class FavoriteItemSerializer implements Serializer {
    fromJson(json: any) {
        const item = new FavoriteItem()
        item.sku = json.sku
        const productSerializer = new ProductSerializer()
        item.product = productSerializer.fromJson(json.product)
        return item
    }
    toJson(item: FavoriteItem) {
        return {
            sku: item.sku,
            product: item.product.id,
            lang: item.lang ? item.lang: 'geo'
        }
    }
}
