import React from 'react'
import { connect } from 'react-redux'
import { MobileHelpComponent } from '../../components/help/MobileHelpComponent'

const MobileHelpPage = (props) => <MobileHelpComponent {...props} />

const mapStateToProps = (state: any) => {
    return {
        app: state.app,
        translations: state.translations,
        language: state.language,
    }
}

export default connect(mapStateToProps, {})(MobileHelpPage)
