import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';
import { Title } from '../Typography/Title';
import { MainPageSliderItem, MobileMainPageBannerItem, MobileMainPageSliderItem } from './MainPageSlider';



export const MobileMultiCarouselComponent = (props: any) => {
    const { items } = props
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false })
    let carousel: any

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
    const CustomDot: any = ({ onClick, ...rest }) => {
        const {
            onMove,
            index,
            active,
            carouselState: { currentSlide, deviceType }
        } = rest;
        const carouselItems = items;

        return (
            <button
                className={active ? "active" : "inactive"}
                onClick={() => onClick()}
            >
                {React.Children.toArray(carouselItems)[index]}
            </button>
        );
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest
        return <button />

    }

    return <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', right: 0, zIndex: 100, top: '44%', cursor: 'pointer' }} onClick={() => {
            carousel.next()
        }}>
            <ChevronRight width={30} height={30} />

        </div>
        <div style={{ position: 'absolute', left: 0, zIndex: 100, top: '44%', cursor: 'pointer' }} onClick={() => {
            carousel.previous()
        }}>
            <ChevronLeft width={30} height={30} />

        </div>
        <Carousel
            ref={(el) => (carousel = el)}
            customRightArrow={<CustomRightArrow onClick={() => {
                carousel.next()
            }} />}
            customLeftArrow={<CustomRightArrow onClick={() => {
                carousel.next()
            }} />}
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={100000000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}

        >
            {items.filter(i => i.imageMobile?.length).map((item, index) => {
                return <div
                    key={index}
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {item.type === 'small' ? <MobileMainPageSliderItem item={item} /> : <MobileMainPageBannerItem item={item} />}
                </div>
            })}
        </Carousel>

    </div>

}
