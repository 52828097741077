import { ResourceProvider } from "../../providers/resource.provider"
import { BOGPayment, PaymentMethod } from "./PaymentMethod"
import { BOGPaymentSerializer, PaymentMethodSerializer } from "./serializer"


export class PaymentMethodProvider extends ResourceProvider<PaymentMethod> {
    constructor() {
        super("rest_payment_method", new PaymentMethodSerializer())
    }
}
export class BOGPaymentProvider extends ResourceProvider<BOGPayment> {
    constructor() {
        super("rest_bog_payment", new BOGPaymentSerializer())
    }
}

export const paymentMethodService = new PaymentMethodProvider()
export const bogPaymentService = new BOGPaymentProvider()

