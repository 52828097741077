import React, { useEffect, useState } from "react";
import { PhoneNumber } from "../General/PhoneNumber";
import { User as UserIcon } from '../../assets/icons/User'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { Facebook } from '../General/Facebook';
import { Instagram } from '../General/Instagram';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { COLORS } from "../../constants/colors";
import { Languages } from "./Languages";
import { Title } from "../Typography/Title";
import { environment } from "../../environment/environment";
import { GraySearchInput } from "../Search/SearchInput";
import { useParams } from "react-router";
import { QueryOptions } from "../../helpers/query.options";
import { infoPageService } from "../../models/InfoPage/service";
import { InfoPage } from "../../models/InfoPage/InfoPage";
import { checkMobileRoute } from "../../helpers/utilities";
import { Youtube } from "../General/Youtube";



export const MobileMenu = (props) => {
    const { onLogout, translations, setState, state, auth, setLanguage, user, menuItems, history, onClose, language, setSnackBar, menuItemLinks } = props
    const {page} = useParams() as any
    const classes = useStyles();
    const [accountMenuItems, setAccountMenuItems] = useState<any[]>([
        {}
    ])

    

    return <Box

        sx={{ display: 'flex', flex: 1, backgroundColor: COLORS.BG_GRAY, flexDirection: 'column', width: window.innerWidth * .8, overflowX: 'hidden' }}
        role="presentation"
    >
        <Box className={classes.section} sx={{
            display: 'flex',
            alignItems: 'flex-start',
            backgroundColor: COLORS.BG_GRAY
        }}>
            <Box sx={{ padding: 1, marginTop: !auth.loggedIn ? 1.7 : 0, paddingTop: !auth.loggedIn ? 1.2 : 0 }}>
                <Title text={translations?.main_menu} fontSize={16} uppercase={true} fontWeight='500' />
            </Box>
            <Box sx={{ padding: 1 }}>
                <CloseIcon onClick={() => {
                    setState({ ...state, left: false })
                }} />
            </Box>

        </Box>
        {user && <Box className={classes.section} sx={{ height: 80 }} 
        onClick={() => {
            history.push('/account')
            setState({ ...state, left: false })
            }}>
            <Box sx={{ padding: 1 }}>
                <div className={classes.avatar}>
                    <UserIcon width={22} height={22} />
                </div>
            </Box>
            <Box sx={{ padding: 1, flex: 1 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Title text={`${user?.firstName} ${user?.lastName}`} fontSize={14} uppercase={true} fontWeight='600' />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Title text={`${user?.email}`} fontSize={12} uppercase={true} fontWeight='300' />
                </div>
            </Box>
        </Box>}
        <Box className={classes.section} sx={{ height: 60, marginTop: 2, padding: 0, minHeight: 60, maxHeight: 60 }}>
            {user && <Box style={{ paddingLeft: 10, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <LogoutIcon sx={{ color: '#555', fontSize: 17 }} />
            </Box>}
            {!user && <Box style={{ paddingLeft: 10, alignItems: 'center', display: 'flex', flexDirection: 'column' }}
                onClick={() => {
                    setState({ ...state, left: false });
                    history.push("/login")
                }}>
                <LoginIcon sx={{ color: '#555', fontSize: 17 }} />
            </Box>}
            {user && <Box style={{ paddingLeft: 0, alignItems: 'center', display: 'flex', flexDirection: 'column' }}
                onClick={onLogout}
            >
                <Title text={translations?.logout} fontSize={12} uppercase={true} fontWeight='400' />
            </Box>}
            {!user && <Box style={{ paddingLeft: 0, alignItems: 'center', display: 'flex', flexDirection: 'column' }}
                onClick={() => {
                    setState({ ...state, left: false });
                    history.push("/login")
                }}>
                <Title text={translations?.login} fontSize={12} uppercase={true} fontWeight='400' />
            </Box>}
            <Box style={{ paddingLeft: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', width: 100 }}>

            </Box>
            <Box style={{ paddingLeft: 0, alignItems: 'center', display: 'flex', flexDirection: 'column', width: 80, justifyContent: 'flex-end', position: 'relative' }}>
                <div style={{ top: -6, position: 'absolute', right: -14 }}>
                    <Languages
                        width={25} height={15} marginRight={5}
                        mode="mobile"
                        setLanguage={setLanguage}
                    />
                </div>
            </Box>
        </Box>
        <Box className={classes.section} sx={{ marginTop: 2, alignItems: 'flex-start', padding: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {menuItems.sort((n1, n2) => { if (n1.sort > n2.sort) { return 1; } if (n1.sort < n2.sort) { return -1; } return 0; }).map((item, index) => {
                    return <Box sx={{ width: '100%' }} key={index}>
                        <Accordion disableGutters sx={{
                            padding: 0, width: '100%', "&.MuiAccordion-root": {
                                boxShadow: '0px 0px rgba(0,0,0,0)'
                            }
                        }}>
                            <AccordionSummary
                                sx={{
                                    boxShadow: '0px 0px rgba(0,0,0,0)',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    height: 30,
                                    minHeight: 30,
                                    maxHeight: 30,
                                    margin: 0,
                                    border: '0px solid black',
                                }}
                                expandIcon={item.children?.length ? <ExpandMoreIcon /> : null}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Title text={item.title} fontSize={14} uppercase={true} fontWeight='600' />
                            </AccordionSummary>
                            {item.id !== 'brandsno' &&
                                item.children?.map((child, index) => {
                                    return <Accordion
                                        key={index}
                                        disableGutters sx={{
                                            padding: 0, width: '97%', paddingLeft: '3%',
                                            "&.MuiAccordion-root": {
                                                boxShadow: '0px 0px rgba(0,0,0,0)'
                                            },
                                            "&.MuiAccordion-root:before": {
                                                backgroundColor: "white"
                                            }
                                        }} >
                                        <AccordionSummary
                                            style={{ boxShadow: '0px 0px rgba(0,0,0,0)' }}
                                            sx={{
                                                border: 0,
                                                boxShadow: '0px 0px red',
                                                backgroundColor: 'transparent', width: '100%', height: 30, minHeight: 30, maxHeight: 30, margin: 0
                                            }}
                                            expandIcon={child.children?.length ? <ExpandMoreIcon /> : null}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Title text={child.title} fontSize={14} uppercase={true} fontWeight='600' onClick={() => {
                                                setState({ ...state, left: false });
                                                history.push(child.url)
                                            }} />
                                        </AccordionSummary>
                                        {
                                            child.children?.map((child, index) => {
                                                return <AccordionDetails sx={{ backgroundColor: 'transparent', height: 22, margin: 0, padding: 0, paddingLeft: '9%' }} key={index}>
                                                    <Title text={child.title} fontSize={14} uppercase={true} fontWeight='600' onClick={() => {
                                                        setState({ ...state, left: false });
                                                        history.push(child.url)
                                                    }} />
                                                </AccordionDetails>
                                            })}
                                    </Accordion>
                                })}
                        </Accordion>
                    </Box>
                })}
            </Box>
        </Box>

        <Box className={classes.section} sx={{ marginTop: 2, alignItems: 'flex-start', padding: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100px' }}>
                {menuItemLinks?.map((item, index) => 
                {
                    return <Box sx={{ width: '100%'}} key={index}>
                        <Box  sx={{
                            padding: 0, width: '100%', "&.MuiAccordion-root": {
                                boxShadow: '0px 0px rgba(0,0,0,0)'
                            }
                        }}>
                            <AccordionSummary
                                sx={{
                                    boxShadow: '0px 0px rgba(0,0,0,0)',
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    height: 30,
                                    minHeight: 30,
                                    maxHeight: 30,
                                    margin: 0,
                                    border: '0px solid black',
                                }}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={() => { 
                                    setState({ ...state, left: false })
                                    history.push(checkMobileRoute(item.id))
                                    }
                                }
                            >
                                <Title text={item.id === 'about_us' ? translations?.about_us : item.id === 'my_profile' ? translations?.my_profile : item.id === 'faq' ? translations?.help : item.title} fontSize={14} uppercase={true} fontWeight='600' />
                            </AccordionSummary>
                            
                        </Box>
                    </Box>
                })}
            </Box>
        </Box>
        <Box className={classes.section} sx={{ marginTop: 2, alignItems: 'flex-start', padding: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <PhoneNumber
                    radius={20}
                    left={10}
                    height={22}
                    iconSize={12}
                    fontSize={12}
                    color={'black'} phoneNumber={environment.company.phoneNumber} />
                <Box sx={{ marginTop: 1.5 }}>
                    <Title text={translations?.subscription} fontSize={12} uppercase={true} fontWeight='500' />

                </Box>
                <Box sx={{ marginTop: 1.5 }}>
                    <GraySearchInput setSnackBar={setSnackBar} {...props} />
                </Box>

            </Box>
        </Box>
        <Box className={classes.section} sx={{ marginTop: 2, alignItems: 'flex-start', padding: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ marginTop: 1.5 }}>
                    <Title text={translations?.social_networks} fontSize={12} uppercase={true} fontWeight='500' />
                </Box>
                <Box sx={{ marginTop: 1.5, display: 'flex', flexDirection: 'row', alignItems: 'space-between' }}>
                    <a href="https://www.facebook.com/VoulezVous.Perfumery" target="_blank" rel="noopener noreferrer"> <Facebook
                        radius={28}
                        color={'black'}
                        iconSize={18}
                        width={50}
                    /></a>
                    <a href="https://www.youtube.com/@voulez-vous3795">

                    <Youtube
                        radius={28}
                        color={'black'}
                        iconSize={18}
                        width={50}
                    />
                    </a>
                    <a href="https://www.instagram.com/voulez_vous_perfumery/" target="_blank" rel="noopener noreferrer">
                        <Instagram
                            radius={28}
                            color={'black'}
                            iconSize={18}
                            width={50}
                        />
                    </a>
                </Box>
            </Box>
        </Box>
        <Box className={classes.section} sx={{
            marginTop: 0, alignItems: 'flex-start', padding: 2,
            paddingBottom: 1,
            backgroundColor: COLORS.BG_GRAY,
 
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ marginTop: 1 }} 
                onClick={() => {
                    history.push('/privacy_policy')
                    setState({ ...state, left: false })
                }}
                >
                    <Title text={translations?.confidentiality} fontSize={13} uppercase={true} fontWeight='300' />
                </Box>
                <Box sx={{ marginTop: 1 }}
                onClick={() => {
                    history.push('/terms_and_rules')
                    setState({ ...state, left: false })
                }}
                >
                    <Title text={translations?.rules_and_terms} fontSize={13} uppercase={true} fontWeight='300' />
                </Box>
                <Box sx={{ marginTop: 1 }}
                onClick={() => {
                    history.push('/info_pages/menu')
                    setState({ ...state, left: false })
                }}
                >
                    <Title text={translations?.site_map} fontSize={13} uppercase={true} fontWeight='300' />
                </Box>
                <Box sx={{ marginTop: 1.5 }}>
                    <Title text={`© ${translations?.copyright}`} fontSize={13} uppercase={false} fontWeight='800' />
                </Box>

            </Box>
        </Box>
    </Box>
}



const useStyles = makeStyles({
    section: {
        backgroundColor: "white",
        borderRadius: 7,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    avatar: {
        marginTop: 0,
        width: 50,
        height: 50,
        borderRadius: 40,
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: COLORS.BEIGE,
        justifyContent: 'center',
        display: 'flex',
    },
    leftcolumn: {
        padding: 10
    }
})