import { Box, Button } from "@mui/material"
import React from "react"
import { useHistory } from "react-router"
import { MainPageNewProductSection } from "../../models/MainPageBrandBanner/MainPage"

interface Props {
    section: MainPageNewProductSection
}

export const NewSectionItem = (props: Props) => {
    const { section } = props
    const history = useHistory()

    return (
        <Box
            sx={{
                display: "flex",
                width: "50%",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: 'space-around',
                '@media (max-width:464px)': {
                    display: "flex",
                    width: "85%",
                    margin: "0 auto",
                    height: "100%",
                    alignItems: "center",
                    flexDirection: "column",
                }
            }}
        >
            {!section?.brand ? (
                <img alt={section?.title} src={section?.brand?.logo} style={{ width: 120 }} />
            ) : (
                <h3 className="uppercase">{section?.title}</h3>
            )}
            <p style={{ backgroundColor: "white" }} dangerouslySetInnerHTML={{ __html: section.text }}></p>
            <Button
                onClick={() => {
                    history.push(section.url)
                }}
                sx={{
                    backgroundColor: "black",
                    color: "white",
                    paddingLeft: 3, paddingRight:3,
                    "&:hover": {
                        color: "white",
                        backgroundColor: "black",
                    },
                    '@media (max-width:464px)': {
                        width: '100%'
                    }
                }}
            >
                {section.buttonText}
            </Button>
        </Box>
    )
}
