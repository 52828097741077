
import React, { useState, useEffect } from "react";
import '../../styles.scss'
import { Box, Button } from "@mui/material";
import '../../../App.css'
import { COLORS } from "../../../constants/colors";
import { Title } from "../../Typography/Title";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Animated } from "react-animated-css";

export const AddToCartButtonGiftCard = (props) => {
    const { simple, setSnackBar, onSubmit, app, auth, translations, onValueChange, language } = props
    const [qtyOpen, setQtyOpen] = useState(false)
    const [qty, setQty] = useState(1)

    useEffect(() => {
        setQtyOpen(false)
    }, [qty])

    return <Button
        sx={{
            color: 'white',
            marginRight: 1,
            backgroundColor: COLORS.BG_DARK,
            '&:hover': {
                color: 'white',
                backgroundColor: COLORS.BG_DARK,
            },
            width: '100%',
            height: 40,
            borderRadius: 10,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between'
        }}
        disableRipple={true}
        disableFocusRipple={false}
        color={"secondary"}

    >
        <Box sx={{ width: '20%', position: 'relative' }} onClick={() => {
            setQtyOpen(true)
        }}>
            <Box sx={{ maxWidth: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: .6 }} >
                <Title text={qty}
                    fontSize={17} uppercase={true}
                    className="roboto"
                    color={"white"}
                    style={{ color: 'white' }}
                    fontWeight='bold'
                    fontFamily='Roboto'
                    onClick={() => {}} />
                <KeyboardArrowDownIcon />
            </Box>

            <Animated animationIn="zoomIn" animationOut="zoomOut"
                style={{
                    backgroundColor: '#333',
                    position: 'absolute',
                    top: -25 * 5 / 2 + 15,
                    left: 4,
                    width: 30,
                    height: 25 * 5,
                    borderRadius: 1,
                    zIndex:1000,
                    display: 'flex',
                    alignItems: 'center', justifyContent: 'center'
                }}
                animationInDuration={400}
                isVisible={qtyOpen}>
                <Box >
                    {[1, 2, 3, 4, 5].map((item, index) => {
                        return <Title
                            key={index}
                            text={item}
                            fontSize={14}
                            uppercase={true}
                            className="roboto"
                            color={"white"}
                            style={{ color: 'white', padding: 3 }}
                            fontWeight='bold'
                            fontFamily='Roboto'
                            onClick={() => {
                                setQty(item)
                                onValueChange(item)
                                setQtyOpen(false)
                            }} />
                    })}
                </Box>

            </Animated>

        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', borderLeftColor: 'white', borderLeft: '1px solid white' }}
            onClick={() => {
                onSubmit(qty)
            }}
        >
            <Title
                uppercase={true}
                fontWeight={'500'}
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                color={'white'}
                fontSize={13}
                text={translations?.add_to_cart} />

        </Box>
    </Button>
}