import { Product } from "../product/product";
import { Resource } from "../resource";

export class  Coupon extends Resource {
    campaign: Campaign;
    id:       string;
    isValid: boolean;
    name:     string;
    products: Product[];
}
export class  LoyaltyCard extends Resource {
    id:       string;
    discount: number;
    products: Product[];
}

export class Campaign extends Resource{
    currentTimeCaption: string;
    description:          string;
    name:                 string;
    isGiftCard: boolean;
    timeLeft:            TimeLeft;
    type:                 string;
    validFrom:           string;
    validTo:             string;
    value:                number;
    freeShipping: boolean;
}

export class TimeLeft extends Resource{
    days:    number;
    hours:   number;
    minutes: number;
}

