import { environment } from "../../environment/environment"
import { isFromGroup } from "../../helpers/utilities"
import { attributeNameSerializer, attributeSerializer } from "../attribute/serializer"
import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { Brand, BrandLetter, BrandLink } from "./brands"

export class BrandSerializer implements Serializer {
    fromJson(json: any) {
        const brand = new Brand()
        brand.id = json.id
        brand.isNew = json.is_new;
        brand.logo = `${environment.imagePath}/assets/brands/logos/${json.logo}.jpg`
        if (json.attribute_set) {
            brand.attributeSet = json.attribute_set.map(a => attributeNameSerializer.fromJson(a))
        }
        if (json.filename) {
            brand.banner = `${environment.imagePath}/assets/brands/${json.filename}.jpg`
        }
        if (json.filename_mobile) {
            brand.bannerMobile = `${environment.imagePath}/assets/brands/${json.filename_mobile}.jpg`
        }
        brand.isExclusive = false
        brand.isLuxury = false
        if (json.attribute_set) {
            brand.filterNames = json.attribute_set.map(p => attributeSerializer.fromJson(p))
        } else {
            brand.filterNames = null
        }
        brand.isNew = false
        brand.isNiche = false
        if (json.attributes) {
            brand.attributes = json.attributes.map(p => attributeSerializer.fromJson(p))
            brand.isExclusive = isFromGroup("tag_only_at_voulez_vous", brand.attributes)
            brand.isNew = isFromGroup("tag_new", brand.attributes)
            brand.isNiche = isFromGroup("tag_niche", brand.attributes)
            brand.isLuxury = isFromGroup("tag_luxury", brand.attributes)
        }
        if (json.links) {
            brand.links = json.links.map(r => brandLinkSerializer.fromJson(r))
        }
        brand.footerBanner = `${environment.imagePath}/assets/brands/${json.footer_banner_filename}.jpg`
        brand.footerBannerText = json.footer_banner_text
        brand.footerBannerTitle = json.footer_banner_title
        brand.youtubeVideoId = json.youtube_video_id
        brand.footerBannerUrl = json.footer_banner_url
        brand.youtubeVideoText = json.youtube_video_text
        brand.youtubeVideoTitle = json.youtube_video_title
        brand.youtubeVideoUrl = json.youtube_video_url
        brand.descr = json.descr
        brand.text = json.text
        brand.title = json.title
        return brand
    }
    toJson(item: Brand) {
        return {
        }
    }
}
export class BrandLinkSerializer extends Resource {
    fromJson(json: any) {
        const item = new BrandLink()
        item.sort = json.sort
        item.title = json.title
        item.url = json.url
        return item
    }
    toJson(item: Brand) {
        return {
        }
    }
}
export class BrandLetterSerializer extends Resource {
    fromJson(json: any) {
        const item = new BrandLetter()
        item.id = json._id
        item.count = json.count
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}


export const brandSerializer = new BrandSerializer()
export const brandLinkSerializer = new BrandLinkSerializer()