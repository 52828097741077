import { SET_LANGAUGE } from "./languageActions";

const initialState = localStorage.getItem("language") ? localStorage.getItem("language") : 'geo'

export const languageReducer = (language = initialState, action: { type: any; content: string }) => {
    switch(action.type) {
        case SET_LANGAUGE:
            language = action.content
            return language
        default: 
            return language
    }
}