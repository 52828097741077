import React, { Fragment, useCallback, useEffect, useState } from "react"
import { Grid, TextField, Button, Box, FormControl, InputLabel, Select, MenuItem, Checkbox } from '@mui/material';
import { Form, Formik, ErrorMessage } from "formik"
import { authenticate, login } from '../../models/user/authActions'
import { Animated } from "react-animated-css";
import * as Yup from "yup";
import { connect } from "react-redux";
import { setSnackBar } from "../../models/application/applicationActions";
import '../../App.css'
import { AddToCartButtonGiftCard } from "../Product/Button/AddToCartButtonGiftCard";
import { Title } from "../Typography/Title";


const GiftCardForm = (props: any) => {
    const { onOpen, onClose, options, translations, item, onAddToCart, auth, address, cities, loc = "mobile" } = props
    const [open, setOpen] = useState(false)
    const [forMe, setForMe] = useState(true)
    const your_name = auth?.loggedIn ? `${auth?.user.firstName} ${auth?.user.lastName}` : ''
    const your_email = auth?.loggedIn ? `${auth?.user.email}` : ''
    const addr = address?.street ? address.street : ""
    const city = address?.city?.uid ? address.city : ""
    const [formValues, setFormValues] = useState({
        your_name,
        recipients_name: '',
        your_email,
        recipients_email: "",
        amount: 0,
        your_message: "",
        qty: 1,
        city,
        address: addr
    })
    const [loaded, setLoaded] = useState(false)
    
    const handleCloseDialog = () => { onClose(false) }
    const handleClose = () => { onClose(false) }

    useEffect(() => {
        setOpen(onOpen)
    }, [onOpen])

    useEffect(() => {
        if (!forMe) {
            setFormValues({
                your_name,
                recipients_name: '',
                your_email,
                recipients_email: "",
                amount: 0,
                your_message: "",
                qty: 1,
                city: "",
                address: ""
            })
        } else {
            setFormValues({
                your_name,
                recipients_name: '',
                your_email,
                recipients_email: "",
                amount: 0,
                your_message: "",
                qty: 1,
                city,
                address: addr
            })
        }

    }, [forMe])

    useEffect(() => {
        if (item) {
            setLoaded(true)
        } else {
            setLoaded(true)
        }
    }, [item])



    const getValues = (values) => {
        setTimeout(() => {
            onAddToCart(values)
        }, 300)
    }

    const validationSchema = () => {
        return Yup.object().shape({
            qty: Yup.number().required(`${translations?.amount} ${translations?.is_required}`).moreThan(
                0,
                `${translations?.amount} ${translations?.is_required}`
            ),
            amount: Yup.number().required(`${translations?.amount} ${translations?.is_required}`).moreThan(
                9,
                `${translations?.amount} ${translations?.is_required}`
            )
        })
    }
    const validationSchemaForMe = () => {
        return Yup.object().shape({
            qty: Yup.number().required(`${translations?.amount} ${translations?.is_required}`).moreThan(
                0,
                `${translations?.amount} ${translations?.is_required}`
            ),
            amount: Yup.number().required(`${translations?.amount} ${translations?.is_required}`).moreThan(
                9,
                `${translations?.amount} ${translations?.is_required}`
            )
        })
    }

   

    const RenderForm = useCallback(() => {
        return <Formik
            validationSchema={forMe ? validationSchemaForMe : validationSchema}
            initialValues={formValues}
            onSubmit={getValues}>
            {({ values, handleSubmit, setFieldValue, validateForm, handleBlur }: any) => (
                <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    animationInDuration={500}
                    isVisible={true} >
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={1} style={window.innerWidth < 1200 ? { padding: 0, marginTop: 25, width: window.innerWidth > 1200 ? '100%' : window.innerWidth } : { padding: 0, marginTop: -15 }}>
                           
                            <Grid item lg={12} sm={6} style={{
                                display: 'flex', flexDirection: 'column', padding: 40,
                                justifyContent: 'center'
                            }}>
                                <Title text={`${translations.select_amount}`} fontSize={16} uppercase={false} fontWeight='600' color={"#444"} style={{ marginBottom: 10 }} />
                                <Box style={{
                                    display: 'flex', flexDirection: 'row', marginBottom: 20, flexWrap: 'wrap', alignItems: 'center',
                                    justifyContent: loc === 'mobile' ? 'space-around' : 'space-even', width: loc === 'mobile' ? '100%' : '110%'
                                }}>
                                    {options.map((option, index) => {
                                        return <Box
                                            onClick={() => {
                                                setFieldValue("amount", Number(option))
                                            }}
                                            style={{
                                                display: 'flex',
                                                borderRadius: 10,
                                                border: '1px solid #aaa',
                                                backgroundColor: values.amount === option ? '#333' : 'white',
                                                width: 60,
                                                cursor: 'pointer',
                                                color: values.amount !== option ? '#333' : 'white',
                                                height: 40,
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                margin: 4,
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                            key={index} >{option} ₾</Box>
                                    })}
                                </Box>
                                <TextField
                                    style={{ width: '100%', marginTop: 10 }}
                                    id="outlined-basic"
                                    label={translations['insert_quantity']}
                                    color="primary"
                                    variant="outlined"
                                    type="number"
                                    name={"amount"}
                                    onChange={(event) => {
                                        setFieldValue("amount", event.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.amount}
                                />
                                <Box style={{ padding: 4, display: 'flex', alignItems: 'center', paddingLeft: 10, marginBottom: 10 }}>
                                    <ErrorMessage name={"amount"} >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Box>
                                <AddToCartButtonGiftCard
                                    onValueChange={(qty) => {
                                        setFieldValue("qty", qty)
                                    }}
                                    onSubmit={(qty) => {
                                        validateForm().then((errors) => {
                                            console.log(errors)
                                            handleSubmit();
                                        })
                                    }}
                                    {...props}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </Animated>)}
        </Formik>
    }, [translations, cities, forMe, formValues])

    return loaded ? <RenderForm /> : <></>

}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { authenticate, login, setSnackBar })(GiftCardForm)