import { Attribute } from "../attribute/attribute";
import { Resource } from "../resource";

export class Finder extends Resource {
    title: string
    steps: FinderStep
    image: string
}

export class FinderStep extends Resource {
    choices:  Attribute[];
    question: string;
    sort:     number;
}

