import React, { useState, useEffect, useCallback, useRef, Fragment } from "react"
import { register } from "../../models/user/authActions"
import { connect } from 'react-redux'
import { ProductItem } from "../../components/Product/ProductItem"
import { useHistory, useLocation, useParams } from "react-router"
import { brandService } from "../../models/brand/service"
import { QueryOptions } from "../../helpers/query.options"
import { productService } from "../../models/product/service"
import { makeStyles } from "@mui/styles"
import { Box, Button, ButtonGroup, CircularProgress, Divider, Grid } from "@mui/material"
import { Brand } from "../../models/brand/brands"
import { ProductLine } from "../../models/product_line/ProductLine"
import { Title } from "../../components/Typography/Title"
import { BrandPageHeader } from "../../components/Brand/BrandPage/BrandPageHeader"
import { SortPopover } from "../../components/Popover/SortPopover"
import { Root, generateParamsFromUrl } from "../../helpers/utilities"
import { MobileBrandPageHeaderAll } from "../../components/Brand/BrandPage/MobileBrandPageHeaderAll"
import { MobileBrandPageHeaderNiche } from "../../components/Brand/BrandPage/MobileBrandPageHeaderNiche"
import { FilterComponentForBrandPage } from "../../components/Filter/FilterComponentForBrandPage"
import { Attribute, AttributeSet } from "../../models/attribute/attribute"
import { environment } from "../../environment/environment"
import { FilterChips } from "../../components/Filter/FIlterChips"
import { Product } from "../../models/product/product"
import Pagination from "../../components/Pagination/Pagination"
import { productLineService } from "../../models/product_line/service"
import { Animated } from "react-animated-css"
import { COLORS } from "../../constants/colors"
import FilterDialogForBrand from "../../components/Dialogs/FilterDialogForBrand"
import SortDialogForBrand from "../../components/Dialogs/SortDialogForBrand"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center"
  },
  leftcolumn: {
    padding: 10
  }
})

const BrandPageWithFilter = (props) => {
  const { translations, language, app } = props
  let { id, tag }: any = useParams();
  const classes = useStyles();
  const listRef: any = useRef()
  const sortObj = localStorage.getItem("sort")
  const [sort, setSort] = useState(sortObj ? JSON.parse(sortObj) : { sort: "uploaded", order: "desc" })
  const [brand, setBrand] = useState<Brand>()
  const [items, setItems] = useState<any[]>([])
  const [init, setInit] = useState(false)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const defaultOptions = new QueryOptions()
  defaultOptions.pageIndex = 1 
  const [queryParams, setQueryParams] = useState<QueryOptions>(defaultOptions)
  const [filterItem, setFilterItem] = useState<Attribute>()
  const [filters, setFilters] = useState<Attribute[]>([])
  const [productLines, setProductLines] = useState<ProductLine[]>([])
  const [attributeSet, setAttributeSet] = useState<AttributeSet>()
  const [noResults, setNoResults] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [lockLoaded, setLockLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [products, setProducts] = useState<Product[]>([])
  const history = useHistory()
  const [filterOpen, setFilterOpen] = useState(false)
  const [showFilterButton, setShowFilterButton] = useState(true)
  const [scroll, setScroll] = useState(0)
  const [sortOpen,setSortOpen] = useState(false)

  const {width} = useWindowDimensions()


  const loadBrand = (id) => {
    const options = new QueryOptions()
    options.lang = language
    brandService.read(id, options).then(r => {
      setBrand(r)
      loadProductLines(r.id)
      setInit(true)
      const params = generateParamsFromUrl(location.search)
      params.brand = r.id
      setQueryParams(params)
    })
  }

  const location = useLocation()

  useEffect(() => {
    window.addEventListener("scroll", (e) => { setScroll(window.scrollY) });
    return () => {

      window.removeEventListener("scroll", (e) => console.log(e));
    };

  }, []);


  useEffect(() => {
    if (window.innerWidth < 1200) {
      if (scroll > window.innerHeight - 200) {
        setShowFilterButton(true)
      } else {
        setShowFilterButton(false)
      }
    }
  }, [scroll])

  useEffect(() => {
    
    if (init && location?.search) {
      const params = generateParamsFromUrl(location.search)
      setQueryParams(params)
    }
  }, [location.search])

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;
      const scrollPercent = (scrollTop + clientHeight) / scrollHeight;

      if (scrollPercent >= 0.8 && brand && window.innerWidth < 1200) {
        if (!lockLoaded) {
          const params = generateParamsFromUrl(location.search)
          params.brand = brand.id
          params.pageIndex = pageIndex
          loadProducts(params)
        } else {
          console.log('no response')
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [items, lockLoaded, brand]);

  const loadProducts = (options) => {
    setLockLoaded(true)
    if (init && window.innerWidth > 1200) {
      window.scrollTo({
        top: listRef?.current?.offsetTop - 50,
        behavior: 'smooth',
      });
    }
    options.lang = language
    options.pageSize = 24
    options.order = sort.order
    options.pageIndex = options.pageIndex
    options.sort = sort.sort === 'uploaded' ? 'uploaded' : sort.sort
    options.brand = options.brand
    if (options.category) {
      options.a_category = options.category
      options.category = null
    }
    productService.listWithCounter(options).then(r => {

      if (r?.current?.length) {
        if (window.innerWidth < 1200) {
          setPageIndex(pageIndex => {
            return pageIndex + 1
          })
        }
        setTimeout(() => {
          setLockLoaded(false)
        }, 1000)
        if (options.pageIndex > 1 && window.innerWidth < 1200) {
          setProducts(products => [...products, ...r.current])
        }
        else {
          setProducts(r.current)
        }
        setCount(r.count)
        setNoResults(false)
      } else {
        setNoResults(true)
      }
    })
  }
  const loadProductLines = (brand: string) => {
    const options = new QueryOptions()
    options.lang = language
    options.brand = `${brand}`
    productLineService.listWithCounter(options).then(r => {
      setProductLines(r.current)
    })
  }

  useEffect(() => {
    if (brand && !products.length && !noResults) {
      setProducts([])
      getBrandsAttrs(brand)
      const params = generateParamsFromUrl(location.search)
      params.brand = brand.id
      setQueryParams(params)
      loadProducts(params)
    }
  }, [brand])

  useEffect(() => {
    if (init && location?.pathname) {
      const params = generateParamsFromUrl(location.search)
      const urlParams = location.pathname.split("/")
      setQueryParams(params)
    }
  }, [location?.pathname])


  const getBrandsAttrs = async (brand) => {
    const params = generateParamsFromUrl(location.search)
    const options: any = new QueryOptions();
    if (!params.brand?.length) {
      options.list = brand.id
    } else {
      options.list = params.brand
    }
  }

  const RenderProductList = useCallback(() => {
    return products ? <Box
      style={{
        flexWrap: 'wrap',
        padding: 0, alignItems: 'flex-start', marginTop: 0, display: 'flex', width: '100%'
      }}>
      {products.map((item, index) => {
        return <Box style={{ width: '25%', minWidth: '25%' }} key={index}>
          <ProductItem key={index} item={item} {...props} />
        </Box>
      })}
    </Box> : <></>
  }, [products, id])

  useEffect(() => {
    if (location.search.indexOf("brand=") > -1) {
      const items = generateParamsFromUrl(location.search)
      loadProducts(items)

    }
  }, [location.search])



  useEffect(() => {
    if (id) {
      setInit(false)
      loadBrand(id)
      setProducts([])
    }
  }, [id])

  const RenderFilterButton = useCallback(() => {
    return <Animated
      animationIn="fadeInUp"
      animationInDuration={300}
      animationOut="fadeOutDown"
      style={{
        width: window.innerWidth,
        paddingTop: 10,
        height: 120,
        backgroundColor: 'transparent',
        position: 'fixed',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        bottom: 0,
        right: 0
      }}
      isVisible={showFilterButton} >
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button onClick={() => setFilterOpen(true)} sx={{
          backgroundColor: COLORS.BEIGE_I,
          width: 130,
          height: 50,
          color: '#444',
          '&:hover': {
            color: '#444',
            backgroundColor: COLORS.BEIGE_I,
          }
        }}>
          {translations?.filter}
        </Button>
        <Button onClick={() => setFilterOpen(true)} sx={{
          backgroundColor: COLORS.BEIGE_I,
          width: 130,
          height: 50,
          color: '#444',
          '&:hover': {
            color: '#444',
            backgroundColor: COLORS.BEIGE_I,
          }
        }}>
          {translations?.sort}
        </Button>
      </ButtonGroup>
    </Animated>

  }, [showFilterButton])

  const RenderFilter = useCallback(() => {
    return <></>
  }, [location.pathname])

  const RenderPagination = useCallback(() => {
    return <Fragment>
      {Math.ceil(count / 24) > 1 && <Pagination
    count={count}
    pageSize={24}
    setPageIndex={(page) => {

      setPage(page)
      const options = generateParamsFromUrl(location.search)
      options.brand = brand.id
      options.pageIndex = page
      const url = `${location.pathname}`
      history.push(options.toQueryStringWithUrl(url))
      setQueryParams(options)
    }}
    pageIndex={queryParams?.pageIndex ? queryParams.pageIndex : 1}
  />}</Fragment>
  }, [queryParams, count])

  return brand ? <div>
    {window.innerWidth < 1200 && <RenderFilterButton />}
    {window.innerWidth < 1200 && translations && 
    <FilterDialogForBrand
      {...props}
      translations={translations}
      sort={sort}
      onSetSort={(sort) => {
        setSort(sort)
        localStorage.setItem("sort", JSON.stringify(sort))
        const options = generateParamsFromUrl(location.search)
        options.brand = brand.id
        options.sort = sort.sort
        options.order = sort.order
        const url = `${location.pathname}`
        history.push(options.toQueryStringWithUrl(url))
        setQueryParams(options)
      }}
      brands={[brand]}
      filterNames={brand.filterNames}
      filterItem={filterItem}
      filters={filters}
      setFilterItem={setFilterItem}
      location={location}
      queryParams={queryParams}
      setFilters={setFilters}
      language={language}
      attributeSet={attributeSet}
      selectedFilters={selectedFilters}
      open={filterOpen}
      onCloseDialog={(resp) => {
        setFilterOpen(resp)
      }}
    />}
      {
      width < 1200 &&  <SortDialogForBrand
      {...props}
      sort={sort}
      onSetSort={(sort) => {
        setSort(sort)
        localStorage.setItem("sort", JSON.stringify(sort))
        const options = generateParamsFromUrl(location.search)
        options.sort = sort.sort
        options.order = sort.order
        const url = `${location.pathname}`
        history.push(options.toQueryStringWithUrl(url))
        setQueryParams(options)
      }}
    

      filterItem={filterItem}
      filters={filters}
      setFilterItem={setFilterItem}
      location={location}
      queryParams={queryParams}
      setFilters={setFilters}
      attributeSet={attributeSet}
      selectedFilters={selectedFilters}
      open={sortOpen}
      onCloseDialog={(resp) => {
        setSortOpen(resp)
      }}
      />
    }
    <div className={"container"} >
      {window.innerWidth > 1200 ? <BrandPageHeader brand={brand} {...props} items={items} productLines={productLines} /> :
        brand.isNiche ?
          <MobileBrandPageHeaderNiche brand={brand} {...props} items={items} productLines={productLines} /> :
          <MobileBrandPageHeaderAll brand={brand} {...props} items={items} productLines={productLines} />
      }
      {window.innerWidth > 1200 ? <Grid container
        spacing={1}
        style={{
          width: 1200,
          padding: 0,
          marginTop: 40,
          display: 'flex',
          alignItems: 'flex-start'
        }}>

        <Box
          ref={listRef}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'space-between',
            width: '100%'
          }}>
          <Title
            uppercase={false}
            fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
            color={'#333'}
            fontSize={14}
            style={{ padding: 10 }}
            fontWeight={'400'}
            text={`${count} ${translations.items}`} />
          {Object.keys(translations).length &&
            <SortPopover
              sort={sort}
              {...props}
              onSetSort={(sort) => {
                setSort(sort)
                localStorage.setItem("sort", JSON.stringify(sort))
                const options = generateParamsFromUrl(location.search)
                options.brand = brand.id
                options.sort = sort.sort
                options.order = sort.order
                const url = `${location.pathname}`
                history.push(options.toQueryStringWithUrl(url))
                setQueryParams(options)
              }}
            />}
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 20, maxWidth: 1200 }}>
          <Root>
            <Divider />
          </Root>

        </Box>
        <Box 
        style={{ 
          width: '20%', 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'flex-start', 
          alignItems: 'flex-start',
   
      }}>
          {init && <><FilterComponentForBrandPage
            {...props}
            attrSet="brand"
            page={page}
            init={init}
            filterNames={brand?.filterNames}
            brands={[brand]}
            filterItem={filterItem}
            setFilters={setFilters}
            attributeSet={attributeSet}
            queryParams={queryParams}
            location={location}
            onSetFilter={(arr, brands) => {
              if (arr.length) {
                const options = generateParamsFromUrl(location.search)
                environment.params.forEach((param: string) => {
                  const params = arr.filter(i => i.name.id === param)
                  if (params.length) {
                    options[param] = params.map(r => r.id).toString()
                  }
                })
                options.brand = brand.id
                loadProducts(options)
              } else {
                history.push(`/brand/${brand.id}`)
                const options = new QueryOptions()
                options.brand = brand.id
                setQueryParams(options)
                loadProducts(options)
              }
            } }
            selectedFilters={selectedFilters} /><SortDialogForBrand
              {...props}
              sort={sort}
              onSetSort={(sort) => {
                setSort(sort)
                localStorage.setItem("sort", JSON.stringify(sort))
                const options = generateParamsFromUrl(location.search)
                options.sort = sort.sort
                options.order = sort.order
                const url = `${location.pathname}`
                history.push(options.toQueryStringWithUrl(url))
                setQueryParams(options)
              } }
             

              filterItem={filterItem}
              filters={filters}
              setFilterItem={setFilterItem}
              location={location}
              queryParams={queryParams}
              setFilters={setFilters}
              attributeSet={attributeSet}
              selectedFilters={selectedFilters}
              open={sortOpen}
              onCloseDialog={(resp) => {
                setSortOpen(resp)
              } } /></>
          }
        </Box>
        <Box style={{ width: '80%' }}>
          <Grid item lg={12} className="inner-container" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            flex: 1,
            alignSelf: 'flex-start'
          }}>
            {loading ? <div style={{
              height: 500,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <CircularProgress color='inherit' />
            </div> :
              <Box style={{ flex: 1, display: 'flex', width: '100%', flexDirection: 'column' }}>
                <FilterChips
                  filters={filters.filter(f => f.name.id !== 'brand')}
                  handleDelete={(item) => {
                    setFilterItem(item)
                  }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <RenderProductList />
                </Box>
              </Box>
            }
            <div style={{ height: 120, marginTop: 40 }}>
              <RenderPagination />
            </div>
          </Grid>
        </Box>

        {/* {sort?.sort?.length > 0 && sort?.order?.length > 0 && <RenderItems />} */}
      </Grid> :
        <Box style={{ flex: 1, display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>

          <ButtonGroup style={{
            marginTop: 3,
            marginBottom: 3,
          }} variant="contained" aria-label="outlined primary button group">
            <Button onClick={() => setFilterOpen(true)} sx={{
              backgroundColor: COLORS.BEIGE_I,
              borderColor: 'black !important',
              width: 130,
              height: 50,
              color: '#444',
              '&:hover': {
                color: '#444',
                backgroundColor: COLORS.BEIGE_I,
              }
            }}>
              {translations?.filter}
            </Button>
            <Button onClick={() => setSortOpen(true)} sx={{
              backgroundColor: COLORS.BEIGE_I,
              width: 130,
              height: 50,
              color: '#444',
              '&:hover': {
                color: '#444',
                backgroundColor: COLORS.BEIGE_I,
              }
            }}>
              {translations?.sort}
            </Button>
          </ButtonGroup>

          <FilterChips
            filters={filters.filter(f => f.name.id !== 'brand')}
            handleDelete={(item) => {
              setFilterItem(item)
            }}
          />
          <Box
            id="main"
            style={{ paddingBottom: 100, alignItems: 'center', marginTop: 0, flexWrap: 'wrap', display: 'flex', flexGrow: 1, width: window.innerWidth }}>

            {products.map((item, index) => {
              return <Box key={index} style={{
                maxWidth: '50%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: `${1 / (app?.dimensions?.width / 180) * 100}%`
              }}>
                <ProductItem key={index} item={item} {...props} />
              </Box>
            })}
          </Box>
        </Box>
      }
    </div >
  </div> : <></>
}


const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    translations: state.translations,
    language: state.language,
    app: state.app
  }
}

export default connect(mapStateToProps, { register })(BrandPageWithFilter)