import React, { useEffect, useState, useRef } from "react"
import { connect } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { setSnackBar } from '../../models/application/applicationActions'
import { Box, Dialog } from '@mui/material'
import "../styles.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "../Carousel/carousel.css"
// import required modules

import { Zoom, Navigation, FreeMode, Thumbs } from "swiper";
const ImageZoomDialog = (props: { onCloseDialog: any; activeIndex: any; items: any; open: any; }) => {
    const {
        onCloseDialog,
        activeIndex,
        items,
        open,
    } = props
    const [image, setImage] = useState<any>()
    const [imageLoaded, setImageLoaded] = useState(false)
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [zoomStatus, setZoomStatus] = useState(null);
    const swiperRef = useRef(null);
    const swiperZoomRef = useRef(null);
    useEffect(() => {
        if (swiperRef.current && activeIndex !== undefined) {
            swiperRef.current.swiper?.slideTo(activeIndex);
        }
    }, [activeIndex]);

    useEffect(() => {
        if (image) {
            const img = new Image()
            img.src = image
            img.onload = () => {
                setImageLoaded(true)
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }
    }, [image])

    return <Dialog
        maxWidth='md'
        style={{
            padding: 0, minWidth: window.innerWidth > 1200 ? 1200 : '100%',
            height: window.innerHeight + 64, marginTop: -32, overflow: 'hidden'
        }}
        open={open}
        onClose={onCloseDialog}
        aria-labelledby="form-dialog-title" >
        <div style={{ position: 'absolute', right: window.innerWidth * .03, top: window.innerWidth > 600 ? 35 : 22, cursor: 'pointer', zIndex: 1000 }} onClick={() => {
            onCloseDialog(false)
            setThumbsSwiper(null)
            setZoomStatus(false)
        }}>
            <CloseIcon fontSize="large" />
        </div>
        {zoomStatus ? <div style={{ position: 'absolute', right: window.innerWidth > 550 ? '50%' : '43%', bottom: window.innerWidth > 550 ? 200 : 260, cursor: 'pointer', zIndex: 22 }} onClick={() => {
            swiperZoomRef.current.zoom.toggle(zoomStatus);
            setZoomStatus(false)
        }}>
            <ZoomOutIcon fontSize="large" />
        </div> : <div style={{ position: 'absolute', right: window.innerWidth > 550 ? '50%' : '43%', bottom: window.innerWidth > 550 ? 200 : 260, cursor: 'pointer', zIndex: 22 }} onClick={() => {
            swiperZoomRef.current.zoom.toggle(zoomStatus);
            setZoomStatus(true)
        }}>
            <ZoomInIcon fontSize="large" />
        </div>}
        <Box sx={{
            padding: window.innerWidth > 600 ? 6 : 2,
            height: window.innerHeight,
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            justifyContent: 'space-around'
        }}>
            <Box sx={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', padding: 0 }}>
                <Swiper
                    ref={swiperRef}
                    onSwiper={(swiper) => {
                        swiperZoomRef.current = swiper;
                    }}
                    slidesPerView={1}
                    spaceBetween={10}
                    navigation={false}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs, Zoom]}
                    className="mySwiper2"
                    zoom={true}
                    onTap={(swiper, event) => {
                        setZoomStatus(!zoomStatus)
                        const slideEl = (event.target as Element).closest('.swiper-slide');
                        if (slideEl && swiper.zoom.enabled) {
                            swiper.zoom.toggle();
                        }
                    }}
                    onDoubleClick={() => {
                        setZoomStatus(!zoomStatus)
                    }}
                >
                    {[...items]?.map((item: { image2000: string }, index: React.Key) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className="swiper-zoom-container">
                                    <img style={{ maxWidth: window.innerWidth - 20, maxHeight: window.innerHeight - 250 }} src={item.image2000} />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>
            <Box sx={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', paddingBottom: 3 }}>
                <Swiper
                    style={{ width: '95%' }}
                    onSwiper={setThumbsSwiper}
                    slidesPerView={3}
                    spaceBetween={30}
                    navigation={true}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper3"
                >
                    {items?.map((item: { image: string }, index: React.Key) => {
                        return <SwiperSlide onClick={() => setZoomStatus(false)} key={index}>
                            <img style={{
                                height: 100,
                                width: 100
                            }} src={item.image} />
                        </SwiperSlide>
                    })}
                </Swiper>

            </Box>
        </Box>
    </Dialog>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations
    }
}

export default connect(mapStateToProps, { setSnackBar })(ImageZoomDialog)