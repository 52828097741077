import { Resource } from "../resource";

export class PaymentMethod extends Resource {
    filename: string;
    name:     string;
    uid: string;
    minValue: number;
    maxValue: number;
}

export class BOGPayment extends Resource{
    status:       string;
    paymentHash: string;
    links:        BOGPaymentLink[];
    orderId:     string;
}

export class BOGPaymentLink extends Resource{
    href:   string;
    rel:    string;
    method: string;
}
