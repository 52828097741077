import React, { useState, useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { cartService } from '../../models/cart/service'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { QuantityControlComponent } from './QuantityControlComponent'
import { CartItem } from '../../models/cart/cart'
import { Title } from "../Typography/Title";

export const MobileProductCheckoutComponent = (props: any) => {
    const { item, redirect, onRemove, onMoveToFavorites, translations, onUpdateCart, onMessage } = props
    const [qty, setQty] = useState(1)
    const [initial, setInitial] = useState(true)
    const classes = useStyles();

    useEffect(() => {
        if (!initial) {
            cartService.create({ ...item, quantity: qty }).then((r: any) => {
                onUpdateCart(r)
            })
        }
    }, [qty, initial])

    useEffect(() => {
        if (item.quantity) {
            setQty(item.quantity)
        }
    }, [item])

    const onRedirect = (item: CartItem) => {
        redirect(`/product/${item.product.id}`)
    }
    return <Box style={{ flexDirection: 'column', display: 'flex', minHeight: 200, backgroundColor: 'white', padding: 4, borderRadius: 10, width: window.innerWidth - 8, paddingBottom: 20 }}>

        <Box className={classes.mainRoot} onClick={() => onRedirect(item)}>
            <Box
                className={classes.column}
                style={{ width: '40%' }} onClick={() => onRedirect(item)}>
                <img src={item?.product?.mainImage} style={{ width: '80%', marginBottom: 10 }} />
                <QuantityControlComponent qty={qty} stock={4}
                    onMessage={onMessage}
                    onChange={(q) => {
                        setInitial(false)
                        setQty(q)
                    }} />
            </Box>
            <Box
                className={classes.column}
                style={{
                    width: '60%',
                    alignItems: 'space-between',
                    justifyContent: ["gift_card", "e_gift_card"].indexOf(item?.product?.uid) < 0 ? 'space-around' : 'flex-start'
                }} onClick={() => onRedirect(item)}>
                <Typography component="h4" variant="subtitle1" style={{ fontWeight: 'bold', fontSize: 17 }}>{["gift_card", "e_gift_card"].indexOf(item?.product?.uid) < 0 ? item?.product?.brand.title : ""}</Typography>
                <Typography component="h4" variant="subtitle1" style={{ fontWeight: '500', fontSize: 16 }}>{item?.product?.name}</Typography>
                <Typography component="h4" variant="subtitle1" style={{ fontWeight: '400', fontSize: 15 }}>{["gift_card", "e_gift_card"].indexOf(item?.product?.uid) < 0 ? item?.product?.uid : ''}</Typography>
            </Box>
        </Box>
        <Box className={classes.icon} onClick={() => onRedirect(item)} style={{ display: 'flex', flexDirection: 'row' }}>
            <Box
                className={classes.column}
                style={{
                    width: '100%'
                }} onClick={() => onRedirect(item)}>
                <Typography component="h4" variant="subtitle2" style={{ fontWeight: '600', fontSize: 18 }}>
                    {item?.specialPrice && item.specialPrice < item.price ? <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', paddingLeft: 15 }}>
                        <Title text={`${item.price * item.quantity}₾`} fontSize={18} uppercase={true} fontWeight='400' color={"black"} style={{ marginRight: 5, textDecorationLine: 'line-through' }} />
                        <Title text={`${item.specialPrice * item.quantity} ₾`} fontSize={18} uppercase={true} fontWeight='400' color={"red"} />
                    </div> : <Title text={`${item.price * item.quantity} ₾`} fontSize={18} uppercase={true} fontWeight='400' fontFamily='Roboto' color={"#000"} className="roboto" />}
                </Typography>
            </Box>
        </Box>
        <Box className={classes.icon} onClick={() => onRedirect(item)}>
            <Box className={classes.row} style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}
                onClick={() => {
                    onMoveToFavorites(item)
                }}>
                <FavoriteBorderIcon />
                <Typography component="h4" variant="subtitle2" style={{ fontWeight: '600', fontSize: 15, marginLeft: 10 }}>
                    {translations?.move_to_favourites}
                </Typography>
            </Box>
        </Box>
        <Box className={classes.icon} onClick={() => onRedirect(item)}>
            <Box className={classes.row} style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}
                onClick={() => {
                    onRemove(item)
                }}>
                <DeleteOutlineIcon sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '&:hover': {
                        color: 'red'
                    }
                }} />
                <Typography component="h4" variant="subtitle2" style={{ fontWeight: '600', fontSize: 15, marginLeft: 10 }}>
                    {translations?.delete}
                </Typography>
            </Box>
        </Box>

    </Box>
}
const useStyles = makeStyles({
    mainRoot: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        cursor: 'pointer',
    },
    row: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
    },
    column: {
        flexDirection: 'column',
        width: '20%',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        flexDirection: 'row',
        width: '100%',
        cursor: 'pointer',
        paddingLeft: 25,
        paddingTop: 7,
    }

});
