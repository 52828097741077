import React, { useState, useEffect } from "react";
import { Box } from "@mui/material"
import { Title } from "../Typography/Title";


export const SearchCategoryComponent = (props: any) => {
    const { item, onClick } = props
    return <Box sx={{ cursor: 'pointer', width: '100%', height: '100%', textAlign: 'left', padding: 1 }} onClick={onClick}>
        <Title text={`${item.title}`} fontSize={15} uppercase={true} fontWeight='400' color={"black"} />
    </Box>
}