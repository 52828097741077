import { Accordion, AccordionSummary, Box } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Title } from '../../Typography/Title';

const RenderAccordionItem = (props) => {
    const { item } = props
    return <Accordion disableGutters sx={{
        padding: 0, width: '96%', "&.MuiAccordion-root": {
            boxShadow: '0px 0px rgba(0,0,0,0)',
        }
    }}>
        <AccordionSummary
            sx={{
                boxShadow: '0px 0px rgba(0,0,0,0)',
                backgroundColor: 'transparent',
                width: '100%',
                height: 40,
                minHeight: 40,
                maxHeight: 40,
                margin: 0,
                padding: 0,
                border: '0px solid black',
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Title text={item.name} fontSize={14} uppercase={true} fontWeight='600' />
        </AccordionSummary>
        {item.content}
    </Accordion>
}

export const ProductDetailsAccordion = (props: any) => {
    const { translations, product, language } = props
    const items = [
        {
            id: "details",
            name: translations?.details,
            content: <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {product?.description && <div dangerouslySetInnerHTML={{ __html: product?.description.replace(/\\n/g, "").replace(/\\r/g, "") }} style={{ overflowY: 'scroll', fontSize: 14, width: '94%', paddingRight: 10, marginTop: 10, marginBottom: 10, textAlign: 'left' }}>
                </div>}
            </Box>
        },
        {
            id: "how_to_use",
            name: translations?.how_to_use,
            content: <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {product?.howToUse && <div dangerouslySetInnerHTML={{ __html: product?.howToUse.replace(/\\n/g, "").replace(/\\r/g, "") }} style={{ overflowY: 'scroll', fontSize: 14, width: '94%', paddingRight: 10, marginTop: 10, marginBottom: 10, textAlign: 'left' }}>
                </div>}
            </Box>
        },
        // {
        //     id: "questions_about_product",
        //     name: translations?.questions_about_product,
        //     content: <Box sx={{ display: 'flex', flexDirection: 'column' }}>

        //     </Box>
        // },
    ]
    return <Box style={{}}>
        {items.map((item, index)=> {
            return <RenderAccordionItem item={item} key={index} />
        }) }
    </Box>
}