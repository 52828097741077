import { ResourceProvider } from "../../providers/resource.provider"
import { SettingsSerializer } from "./serializer"
import { Settings } from "./settings"


export class SettingsProvider extends ResourceProvider<Settings> {
    constructor() {
        super("rest_settings", new SettingsSerializer())
    }
}


export const settingsService = new SettingsProvider()

