import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Fragment } from "react"
import { COLORS } from '../../constants/colors';


export const FinderChoiceComponent = (props) => {
    const { choice, clickedItemIndex, index, onClicked, selected } = props
    const [options, setOption] = useState(-1)
    return <Fragment>
        <Button onClick={() => {
            onClicked(choice)
        }} sx={{
            width: '100%',
            backgroundColor: selected ? 'black' : 'gray',
            color: 'white',
            '&:hover': {
                color: 'white',
                backgroundColor: selected ? 'black' : 'gray',
                opacity: 1
            }
        }}>{choice?.value}
        </Button>
    </Fragment>
}