import React, { useState } from 'react';
import { Box, IconButton } from "@mui/material"
import { makeStyles } from "@mui/styles";
import MenuIcon from '@mui/icons-material/Menu';
import CartDialog from '../Dialogs/CartDialog';
import FavsDialog from '../Dialogs/FavsDialog';
import { connect } from 'react-redux';
import SearchDialog from '../Dialogs/SearchDialog';

const styles = makeStyles({
    root: {
        flexGrow: 1,
    },
    menuButton: {
    },
    title: {
        flexGrow: 1,
    },
})

export const MobileHeader = (props) => {
    const { toggleDrawer, language, categories, products, brands, translations, onRemoveItem, onRemoveFavorite } = props
    
    const classesA = styles();
    const [cartOpen, setCartOpen] = useState(false)
    const [favsOpen, setFavsOpen] = useState(false)

    return <Box sx={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 0, width: '100%',
        flexDirection: 'row', height: '55%',
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '16%',
            alignItems: 'flex-start',
            paddingLeft: 1
        }}>
            <IconButton
                sx={{ padding: 1, alignItems: 'flex-start' }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={toggleDrawer("left", true)}
                edge="start"
                className={classesA.menuButton}
                color="inherit"
                aria-label="menu"
            >
                <MenuIcon style={{ color: 'black', fontSize: 28 }} />
            </IconButton>

        </Box>
        <Box sx={{
            height: 40,
            width: '68%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <SearchDialog {...props}/>
        </Box>
        <Box sx={{
            height: 40,
            width: '8%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
            onClick={() => {
                setFavsOpen(true)
            }}
        >
            <FavsDialog />
        </Box>
        <Box sx={{
            paddingLeft: 1,
            height: 40,
            width: '8%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
            onClick={() => {
                setCartOpen(true)
            }}
        >
            <CartDialog {...props} language={language}/>
        </Box>
    </Box>
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, {})(MobileHeader)