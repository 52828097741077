import { City } from '../city/city'
import { Country } from '../country/country'
import { Resource } from '../resource'
import {Location} from '../location/location'

export class Address extends Resource {
    city:        City;
    country: Country;
    firstName:  string;
    lang: string;
    comment: string;
    lastName:   string;
    personalId: string;
    phone:       string;
    street:      string;
    street2:    string;
    location: Location
}


