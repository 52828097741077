import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useHistory } from 'react-router';
import { COLORS } from '../../constants/colors';
import { Title } from '../Typography/Title';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const GoToCheckoutDialog = (props: any) => {
    const { translations, onDone, open, language } = props
    const history = useHistory()

    return <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onDone}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>
            <Title
                uppercase={true}
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                color={'#333'}
                fontSize={19}
                fontWeight={'bold'}
                text={translations?.checkout} />
            </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
            <Title
                uppercase={false}
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                color={'#333'}
                fontSize={14}
                fontWeight={'500'}
                text={translations?.go_to_chekout_text} />
                
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onDone} sx={{
                backgroundColor: 'gray',
                color: 'white',
                '&:hover': {
                    color: 'white',
                    backgroundColor: COLORS.BG_GRAY_PURPLE,
                }
            }}>
                <Title
                    uppercase={true}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'#fff'}
                    fontSize={14}
                    text={translations?.continue_shopping} />

            </Button>

            <Button
                sx={{
                    color: 'black',
                    border: '1px solid black',
                    "&:hover": {
                        backgroundColor: COLORS.BG_BEIGE,
                        borderColor: `black`,
                        color: 'black'
                    }
                }}
                onClick={() => {
                    onDone()
                    history.push("/checkout")
                }}>
                <Title
                    uppercase={true}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'#333'}
                    fontWeight={500}
                    fontSize={14}
                    text={translations?.go_to_checkout} />

            </Button>
        </DialogActions>
    </Dialog>

}