import React, { forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { COLORS } from '../../constants/colors';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmationDialog = (props: any) => {
    const defaultText = "Default text"
    const { open = false, setOpen, onAgreed,
        textCancel=null,
        translations,
        textAccept = "Ok", text = defaultText, title = "" } = props


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return <Dialog
        open={open}
        style={{zIndex:10000}}
        TransitionComponent={Transition}
        keepMounted
        onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                {text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} sx={{
                backgroundColor: 'gray',
                color: 'white',
                '&:hover': {
                    color: 'white',
                    backgroundColor: COLORS.BG_GRAY_PURPLE,
                }
            }}>{textCancel ? textCancel: translations?.cancel}
            </Button>
            <Button
                onClick={onAgreed}
                sx={{
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                        color: 'black',
                        backgroundColor: 'gray',
                    }
                }}>{textAccept}
            </Button>
        </DialogActions>
    </Dialog>
}