import { ResourceProvider } from '../../providers/resource.provider';
import { InfoPage, InfoPageContent } from './InfoPage';
import { InfoPageSerializer, InfoPageContentSerializer } from './serializer';



export class InfoPageProvider extends ResourceProvider<InfoPage> {
  constructor() {
    super(
      'rest_info_pages',
      new InfoPageSerializer());
  }
}

export class InfoPageContentProvider extends ResourceProvider<InfoPageContent> {
  constructor(
  ) {
    super(
      'rest_info_page',
      new InfoPageContentSerializer());
  }
}

export const infoPageService = new InfoPageProvider()
export const infoPageContentService = new InfoPageContentProvider()