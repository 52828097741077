import Dialog from '@mui/material/Dialog';
import React, { useCallback, useEffect, useState, } from "react";
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import 'react-medium-image-zoom/dist/styles.css'
import '../../App.css'
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { Transition } from "./GalleryDialog"
import { COLORS } from '../../constants/colors';
import { Finder, FinderStep } from '../../models/finder/Finder';
import { Attribute } from '../../models/attribute/attribute';
import { FinderChoiceComponent } from '../Finder/FinderChoiceComponent';


export const FinderDialog = (props) => {
    const { open, onCloseDialog, finder, onCloseDialogEvent } = props
    const { sessionId, setSessionID } = props
    const [index, setIndex] = useState(0)
    const [currentStep, setCurrentStep] = useState<FinderStep>()
    const [selectedChoices, setSelectedChoices] = useState<Attribute[]>([])
    const [image, setImage] = useState<string>()
    const [imgProps, setImageProps] = useState({ width: 400, height: 250, zoomWidth: 500, img: null })

    const RenderContent = useCallback(() => {
        if (index < 1) {
            setCurrentStep(null)
        } else {
            const item = finder.steps[index - 1]
            if (item) {
                setCurrentStep(item)
            }
        }
        return currentStep ? <Grid container style={{}}>
            {currentStep.choices.map((choice, ind) => {
                return <Grid item lg={6} sx={{ backgroundColor: 'white', display: 'inline-block', padding: 1 }} key={ind}>
                    <FinderChoiceComponent
                        index={ind}
                        selected={(selectedChoices[index - 1] && selectedChoices[index - 1] === choice.id)}
                        onClicked={(item) => {
                            const items = [...selectedChoices]
                            items[index -1 ] = item.id
                            setSelectedChoices(items)
                        }
                        }
                        choice={choice} />
                </Grid>
            })}

        </Grid> : <></>
    }, [index, finder, currentStep, selectedChoices])

    return <Dialog
        open={open}
        maxWidth='xl'
        style={{ padding: 10, minWidth: 700 }}
        TransitionComponent={Transition}
        onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                onCloseDialog(false)
                onCloseDialogEvent(false)
            }
        }}
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {currentStep ? currentStep.question : finder?.title}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: 'white', width: 700, height: 400 }}>
            <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => {
                onCloseDialog(false)
            }}>
                <CloseIcon />
            </div>
            <DialogContentText id="alert-dialog-slide-description">
                <RenderContent />
                {/* <Box>
                    {JSON.stringify(selectedChoices)}
                </Box> */}
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {index > 0 && <Button onClick={() => {
                    setIndex(cIndex => {
                        return cIndex - 1
                    })
                }} sx={{
                    backgroundColor: 'gray',
                    color: 'white',
                    '&:hover': {
                        color: 'white',
                        backgroundColor: COLORS.BG_GRAY_PURPLE,
                    }
                }}>PREV
                </Button>}

            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {index < finder?.steps?.length && <Button
                    onClick={() => {
                        setIndex(cIndex => {
                            return cIndex + 1
                        })
                    }}
                    sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': {
                            color: 'black',
                            backgroundColor: 'gray',
                        }
                    }}>NEXT
                </Button>}

            </Box>

        </DialogActions>
    </Dialog>
}