import React from "react"
import { connect } from "react-redux"
import { AboutUsContent } from "../components/aboutus/AboutUsContent"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { AboutUsMenuComponent } from "../components/aboutus/AboutUsMenuComponent"
import { useHistory, useParams } from "react-router"
import { setConfirmation, setSnackBar } from "../models/application/applicationActions"
import { logout } from "../models/user/authActions"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Box } from "@mui/material"

const AboutUsPage = (props) => {
    const { translations, auth, language } = props
    const { width } = useWindowDimensions()
    let { page }: any = useParams()
    const history = useHistory()

    return (
        <>
            {width < 600 && (
                <Box sx={{ display: "flex", justifyItems: "flex-start" }}>
                    <ArrowBackIcon
                        onClick={() => history.goBack()}
                        sx={{ color: "black", fontSize: 35, paddingTop: 4 }}
                    />
                </Box>
            )}
            <div style={{ display: "flex", maxWidth: 1200, margin: "0 auto", width: width }}>
                {width > 600 && <AboutUsMenuComponent language={language} translations={translations} />}
                <AboutUsContent {...props} page={page} auth={auth} />
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        translations: state.translations,
        language: state.language,
    }
}

export default connect(mapStateToProps, { setConfirmation, setSnackBar, logout })(AboutUsPage)
