import { branchSerializer } from "../branch/serializer";
import { Serializer } from "../serializer"
import { EmbeddedInventory } from "./EmbeddedInventory";



export class EmbeddedInventorySerializer implements Serializer {
    fromJson(json: any) {
        const item = new EmbeddedInventory();
        item.id = json.sku  
        item.isLast = json.is_last;
        item.available = json.available
        item.sku = json.sku;
        item.branch = branchSerializer.fromJson(json.branch)
        return item;
    }
    toJson(item: any) {
        return {
            
        }
    }
}


export const embeddedInventorySerializer = new EmbeddedInventorySerializer()
