import React from "react"
import { connect } from "react-redux"
import { Divider, Typography } from "@mui/material"
import { PrivacyPolicyContent } from "../../components/InfoPages/PrivacyPolicyContent"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"

const PrivacyPolicyPage = (props) => {
    const { app, language, translations } = props
    const { width } = useWindowDimensions()

    return (
        <div style={{ display: "flex", maxWidth: 1200, margin: "0 auto", width: width }}>
            {width > 600 && (
                <>
                    <div
                        style={{
                            display: "flex",
                            paddingBottom: 30,
                            flexDirection: "column",
                            alignItems: 'flex-start',
                            width: "30%",
                            height: "100vh",
                            paddingTop: 50,
                        }}
                    >
                        <Typography sx={{ fontWeight: "bold" }}>
                            {(translations.privacy_policy ??= "Privacy Policy")}
                        </Typography>
                    </div>
                    <Divider
                        orientation="vertical"
                        sx={{ width: "1px", backgroundColor: "gray", height: "100vh", marginTop: 5,  marginRight: 3 }}
                    />
                </>
            )}
            <PrivacyPolicyContent language={language} translations={translations} />
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        app: state.app,
        translations: state.translations,
        language: state.language,
    }
}

export default connect(mapStateToProps, {})(PrivacyPolicyPage)
