import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { Box } from "@mui/material";
import { setCart, setSnackBar } from "../models/application/applicationActions";
import { useLocation, useParams } from "react-router";
import { orderCheckService } from "../models/order/service";
import { QueryOptions } from "../helpers/query.options";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Title } from "../components/Typography/Title";
import CancelIcon from '@mui/icons-material/Cancel';
import { loadCart, loadCartItems } from "../helpers/utilities";

const PaymentCallbackPage = (props) => {
    const { translations, setCart, language } = props
    const location = useLocation()
    const [orderId, setOrderId] = useState("")
    const [success, setSuccess] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const checkOrder = (id) => {
        orderCheckService.read(id, new QueryOptions()).then((r) => {
            if(r.id) {
                setSuccess(true)
            } else {
                setSuccess(false)
            }
            loadCartItems(setCart, language)
            setLoaded(true)
        })
    }


    useEffect(() => {
        if (orderId) {
            checkOrder(orderId)
        }

    }, [orderId])
    useEffect(() => {
        try {
            const query = new URLSearchParams(location.search);
            let [params] = location.search.split("?").reverse()
            let [order_id] = params.split("=").reverse()
            if (order_id) {
                setOrderId(order_id)
            }
        } catch (error) {
            console.log(error)
        }

    }, [location])

    return window.innerWidth > 1200 ? <Box style={{
        width: 1200,
        minHeight: window.innerHeight - 300,
        margin: '0px auto',
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    }}>
        {loaded && <Box style={{
            width: 400, height: 200,
            padding: 30,
            boxShadow: '0px 0px 7px rgba(0,0,0,.5)',
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {success && <CheckCircleOutlineIcon style={{
                color: 'green', fontSize: 45,
                marginBottom: 30
            }} />}
            {success && <Title
                text={translations.payment_successful}
                fontSize={17}
                color={"#333"}
                uppercase={false}
                className="roboto"
                textAlign="center"
                style={{textAlign: 'center'}}
                fontWeight='500'
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'} />}
            {!success && <CancelIcon style={{
                color: 'red', fontSize: 45,
                marginBottom: 30
            }} />}
            {!success && <Title
                text={translations.payment_fail}
                fontSize={17}
                color={"#333"}
                uppercase={false}
                className="roboto"
                textAlign="center"
                style={{textAlign: 'center'}}
                fontWeight='500'
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'} />}
        </Box>}
    </Box > : <></>
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        translations: state.translations,
        language: state.language,
        app: state.app
    }
}

export default connect(mapStateToProps, { setCart, setSnackBar })(PaymentCallbackPage)