import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { Attribute, AttributeName, AttributeSet } from "./attribute"


export class AttributeSetSerializer implements Serializer{
    fromJson(json: any) {
        const item = new AttributeSet()
        item.id = json.id
        if (json.attributes) {
            const attributeSerializer = new AttributeSerializer()
            item.attributes = json.attributes.map(a => attributeSerializer.fromJson(a))
        }
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
export class AttributeNameSerializer implements Serializer{
    fromJson(json: any) {
        const item = new AttributeName()
        item.id = json.id
        item.value = json.value
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
export class AttributeSerializer implements Serializer{
    fromJson(json: any) {
        const item = new Attribute()
        const attrNameSerializer = new AttributeNameSerializer()
        item.id = String(json.id)
        if (json.name) {
            item.name = attrNameSerializer.fromJson(json.name)
        }
        item.value = json.value
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}

export const attributeSetSerializer = new AttributeSetSerializer() 
export const attributeSerializer = new AttributeSerializer() 
export const attributeNameSerializer = new AttributeNameSerializer()