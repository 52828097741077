import React, { Fragment, useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, InputBase, List, ListItem, ListSubheader, Paper } from '@mui/material';
import { Animated } from "react-animated-css";
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';
import { setCart, setSnackBar, setConfirmation } from '../../models/application/applicationActions';
import { Transition } from '../../helpers/utilities';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { COLORS } from '../../constants/colors';
import { Title } from '../Typography/Title';
import '../styles.scss'
import { SearchBrandComponent } from '../Brand/SearchBrandComponent';
import { SearchProductComponent } from '../Product/SearchProductComponent';
import { useHistory } from 'react-router';
import { Brand } from '../../models/brand/brands';
import { QueryOptions } from '../../helpers/query.options';
import { brandService } from '../../models/brand/service';
import { productService } from '../../models/product/service';
import { categoryService } from '../../models/category/service';
import { CartItem } from '../../models/cart/cart';
import { Product } from '../../models/product/product';


export const onSearch$: any = new Subject().pipe(
    debounceTime(700)
)


const SearchDialog = (props) => {
    const {
        app,
        setCart,
        translations,
        language } = props
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = useState("")
    const [shown, setShown] = useState(true)
    const [noResults, setNoResults] = useState(false)
    const [brands, setBrands] = useState<Brand[]>([])
    const [products, setProducts] = useState<Product[]>([])
    const history = useHistory()
    let searchRef:any = useRef()

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    useEffect(() => {
        if (open) {
            setTimeout(()=> {
                
                searchRef.current.focus();
            }, 1000)
        }
    }, [open])

    useEffect(() => {
        if (language) {
            onSearch$.subscribe(
                debounced => {
                    setNoResults(false)
                    if (debounced.length > 2) {
                        getSearchResults(debounced, language)
                    } else {
                        setBrands([])
                        setProducts([])
                    }
                }
            );
        }

    }, [language]);

    const getSearchResults = async (search, language) => {
        const options = new QueryOptions()
        options.lang = language
        options.pageSize = 6
        options.search = search
        const results = await Promise.all([
            brandService.listWithCounter(options),
            productService.listWithCounter(options),
            categoryService.listWithCounter(options)
        ]);
        setBrands(results[0].current)
        setProducts(results[1].current)
    }

    useEffect(() => {
        if (search?.length > 2) {
            onSearch$.next(search)
        }
    }, [search])

    const handleClose = () => {
        setOpen(false);
    };

    const onUpdateCart = (item: CartItem) => {
        setCart([...app.cart.filter(ci => ci.sku !== item.sku), item])
    }



    return <Fragment>
        <Box onClick={handleClickOpen} style={{ paddingTop: 3, position: 'relative' }}>
            <Paper
                component="form"
                sx={{
                    p: '2px 4px',
                    borderRadius: 20,
                    height: 26,
                    alignSelf: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    width: '90%',
                    boxShadow: '0px 0px rgba(0,0,0,0)',
                    backgroundColor: 'white',
                    border: '1px solid #444',
                    position: 'relative',
                }}
            >
                <IconButton sx={{ p: '10px' }} aria-label="menu">
                    <SearchIcon />
                </IconButton>
                <InputBase
                    disabled
                    onFocus={() => setShown(true)}
                    sx={{ ml: 1, flex: 1 }}
                    value={search}
                    onChange={(evt) => {
                        setSearch(evt.target.value)
                    }}
                    placeholder={translations?.search}
                    inputProps={{ 'aria-label': 'search google maps' }}
                />
            </Paper>
        </Box>
        <Dialog
            maxWidth='md'
            style={{
                padding: 0, minWidth: window.innerWidth > 1200 ? 1200 : '100%',
                overflow: 'hidden',
                minHeight: window.innerHeight
            }}
            sx={{
                '&.MuiDialog-paperScrollPaper': {
                    maxHeight: 1200
                }
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{ position: 'absolute', right: window.innerWidth * .03, top: 10, cursor: 'pointer' }} onClick={handleClose}>
                <CloseIcon />
            </div>
            <Box style={{ padding: 10, backgroundColor: COLORS.BODY_BG, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Box style={{ width: window.innerWidth, display: 'flex', flexDirection: 'column', height: window.innerHeight, justifyContent: 'flex-start', paddingTop: 40 }}>
                    <Paper
                        component="form"
                        sx={{
                            p: '2px 4px',
                            borderRadius: 20,
                            height: 36,
                            alignSelf: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            width: '90%',
                            boxShadow: '0px 0px rgba(0,0,0,0)',
                            backgroundColor: 'white',
                            border: '1px solid #444',
                            position: 'relative',
                        }}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="menu">
                            <SearchIcon />
                        </IconButton>
                        {open && <InputBase
                            ref={searchRef}
                            autoFocus={true}
                            onFocus={() => setShown(true)}
                            sx={{ ml: 1, flex: 1 }}
                            value={search}
                            onChange={(evt) => {
                                setSearch(evt.target.value)
                            }}
                            placeholder={translations?.search}
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />}
                        <Animated animationIn="fadeIn" animationOut="fadeOut"
                            animationInDuration={500}
                            animationOutDuration={500}
                            isVisible={search.length > 0} >
                            <Box style={{ display: 'flex', alignItems: 'center', marginRight: 5 }} onClick={() => {
                                setSearch("")
                            }}>
                                <CloseIcon />
                            </Box>
                        </Animated>
                    </Paper>
                    <List
                        sx={{
                            paddingBottom: 0,
                            paddingTop: 3,
                            width: '100%',
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            overflowX: 'hidden',
                            maxWidth: window.innerWidth,
                            '& ul': { padding: 0 },
                        }}
                        subheader={<li />}
                    >
                        {[
                            { name: translations.brands, id: 'brands' },
                            { name: translations?.categories, id: 'categories' },
                            { name: translations?.products, id: 'products' }
                        ].map((item, index) => (
                            <li key={index}>
                                <ul>
                                    {item.id === 'brands' && brands?.length > 0 && <ListSubheader sx={{ backgroundColor: '#444', padding: 1 }}>
                                        <Title text={`${item.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"white"} />
                                    </ListSubheader>}
                                    {item.id === 'products' && products?.length > 0 && <ListSubheader sx={{ backgroundColor: '#444', padding: 1 }}>
                                        <Title text={`${item.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"white"} />
                                    </ListSubheader>}
                                    {item.id === 'brands' &&
                                        brands?.length > 0 && <Box sx={{ maxHeight: 230 }}>
                                            {brands.map((brand, ind) => {
                                                return <ListItem key={`item-${brand.id}-`} sx={{ padding: 0 }}>
                                                    <SearchBrandComponent item={brand} key={index} onClick={() => {
                                                        handleClose()
                                                        history.push(`/brand/${brand.id}`)
                                                    }} />
                                                </ListItem>
                                            })}
                                        </Box>
                                    }
                                    {item.id === 'products' &&
                                        products?.length > 0 &&
                                        products.map((item, index) => {
                                            return <SearchProductComponent
                                                item={item}
                                                key={index}
                                                onClick={() => {
                                                    handleClose()
                                                    history.push(`/product/${item.id}`)
                                                }} />
                                        })}
                                </ul>
                            </li>
                        ))}
                    </List>
                </Box>

            </Box>
        </Dialog>
    </Fragment>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, {
    setCart,
    setSnackBar,
    setConfirmation
})(SearchDialog)
