import React, { useState, useEffect, useCallback } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { QuantityControlComponent } from './QuantityControlComponent'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { cartService } from '../../models/cart/service'
import { mode } from '../../helpers/utilities'
import { Title } from '../Typography/Title'
import { CartItem } from '../../models/cart/cart'
import { useHistory } from 'react-router';

export const ProductCheckoutComponentMobile = (props: any) => {
    const { item, redirect, onRemove,
        language,
        onMoveToFavorites, translations, onUpdateCart } = props
    const [qty, setQty] = useState(1)
    const history = useHistory();
    const [value, setValue] = useState("")
    const [initial, setInitial] = useState(true)
    const classes = useStyles();
    const [stock, setStock] = useState(1)
    const [valueType, setValueType] = useState("text")

    useEffect(() => {
        if (item.quantity) {
            setQty(item.quantity)
        }
        if (item) {
            const [simple] = item.product.simpleProducts.filter(s => s.sku === item.sku)
            if (simple) {
                setStock(simple.stock)
                const [color] = simple.attributes.filter(a => a.name.id === 'color_family')
                if (color) {
                    setValueType("color")
                    setValue(`#${color.value}`)
                }
                const [size] = simple.attributes.filter(a => a.name.id === 'size')
                if (size) {
                    setValueType("text")
                    setValue(`${size.name.value}: ${size.value}`)
                }
            } else {
                setStock(5)
            }
        }
    }, [item])

    useEffect(() => {
        if (!initial) {
            cartService.create({ ...item, quantity: qty }).then((r: any) => {
                onUpdateCart(r)
            })
        }
    }, [qty, initial])

    useEffect(() => {
        if (item.quantity) {
            setQty(item.quantity)
        }
    }, [item])

    const redirectToProduct = (item) => {
        if (["e_gift_card_001", "gift_card_001"].indexOf(item.sku) < 0) {
            history.push("/product/" + item.product.id)
        }
    }
    return <Box style={{ flexDirection: 'column', display: 'flex', flex: 1, width: window.innerWidth - 20 }}>
        <Box style={{
            display: 'flex', flexDirection: 'row', width: '100%',
        }}>
            <Box style={{ flexDirection: 'column', width: '30%', cursor: 'pointer', padding: 10 }} onClick={() => redirectToProduct(item)}>
                <img src={item?.product?.mainImage} style={{ width: '100%' }} />
            </Box>
            <Box style={{ flexDirection: 'column', width: '70%', cursor: 'pointer', padding: 10 }} onClick={() => redirectToProduct(item)}>
                <Typography component="h4" variant="subtitle1" style={{ fontWeight: 'bold', fontSize: 19, textAlign: 'left' }}>
                    {item?.product?.brand.title}
                </Typography>
                <Typography component="h4" variant="subtitle2" style={{ fontWeight: '400', fontSize: 15, textAlign: 'left' }}>{item?.product?.name}</Typography>
                <Typography component="h4" variant="subtitle2" style={{ fontWeight: '400', fontSize: 15, textAlign: 'left' }}>
                    {translations?.code}: {item?.product?.uid}</Typography>

            </Box>

        </Box>
        <Box style={{
            display: 'flex', flexDirection: 'row', width: '100%',
        }}>
            <Box style={{
                flexDirection: 'column', width: '30%',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer', padding: 10
            }} >
                <QuantityControlComponent qty={qty} stock={stock} onChange={(q) => {
                    setInitial(false)
                    setQty(q)
                }} />
            </Box>
            <Box style={{ flexDirection: 'column', width: '70%', cursor: 'pointer', padding: 10 }} >
                {valueType !== "text" && value?.length && <Box sx={{
                    width: 30, height: 30,
                    borderRadius: 30,
                    border: "2px solid #444",
                    backgroundColor: value
                }}></Box>}

                {value?.length > 0 && valueType === "text" && <Button

                    sx={{
                        marginTop: 1,
                        color: 'white',
                        marginRight: 1,
                        backgroundColor: '#333',
                        '&:hover': {
                            color: 'white',
                            backgroundColor: '#333',
                        },
                        marginBottom: 1,
                        justifyContent: 'space-around',
                        height: 30,
                        minWidth: 120,
                        border: '1px solid #333',
                        borderRadius: 2,

                    }}
                    disableRipple={true}
                    disableFocusRipple={false}
                    color={"secondary"}
                    onClick={() => {

                    }}
                >
                    <Title
                        uppercase={true}
                        fontWeight={'500'}
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        color={'white'}
                        fontSize={13}
                        style={{ marginRight: 10 }}
                        text={value} />
                </Button>}
            </Box>
        </Box>
        <div className={classes.thirdColumn} style={{ justifyContent: 'flex-start' }}>
            {item?.specialPrice && item.specialPrice < item.price ? <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', paddingLeft: 15 }}>
                <Title text={`${item.price * item.quantity}₾`} fontSize={22} uppercase={true} fontWeight='400' color={"black"} style={{ marginRight: 5, textDecorationLine: 'line-through' }} />
                <Title text={`${item.specialPrice * item.quantity} ₾`} fontSize={22} uppercase={true} fontWeight='400' color={"red"} />
            </div> : <Title text={`${item.price * item.quantity} ₾`} fontSize={22} uppercase={true} fontWeight='400' fontFamily='Roboto' color={"#000"} className="roboto" />}
        </div>
        <Box style={{
            display: 'flex', flexDirection: 'row', width: '100%',
        }}>
            <Box style={{
                flexDirection: 'column', width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer', padding: 10
            }} >
                <div style={{ flexDirection: 'column' }}>
                    <div className={classes.thirdColumn} onClick={() => {
                        onRemove(item)
                    }}>
                        <DeleteOutlineIcon sx={{
                            '&:hover': {
                                color: ''
                            }
                        }} />
                        <Typography component="h4" variant="subtitle2" style={{ fontWeight: '500', fontSize: 14 }}>
                            {translations?.delete}
                        </Typography>
                    </div>
                    <div className={classes.thirdColumn} onClick={() => {
                        onMoveToFavorites(item)
                    }} >
                        <FavoriteBorderIcon />
                        <Typography component="h4" variant="subtitle2" style={{ fontWeight: '500', fontSize: 14, }}>
                            {translations?.move_to_favourites}
                        </Typography>

                    </div>
                </div>

            </Box>

        </Box>


    </Box>
}
const useStyles = makeStyles({
    productName:
        { fontWeight: 'bold', fontSize: 15 },
    listItem:
        { color: 'black', fontFamily: 'FiraGo', fontSize: 12, fontWeight: '300' },
    thirdColumn:
    {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingRight: 20,
        padding: 7,
    }

});
