import { Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { LetterBrandSection } from '../../../components/Brand/LetterBrandSection';


export const BrandListContentDesktop = (props) => {
    const { letters, brandsListRef, history, brands } = props
    return <Fragment>
        <Grid container
            spacing={1}
            style={{
                width: 1200,
                paddingBottom: 10,
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                borderBottomColor: 'black',
                borderBottomWidth: 1
            }}>
            <div className={""} style={{ alignSelf: 'center', padding: 10 }}>
                {letters?.map((letter, index) => {
                    return <span
                        onClick={() => {
                            const el = document.getElementById(`ref_${letter.id}`);
                            brandsListRef.current.scrollTo({
                                top: el.offsetTop - brandsListRef.current.offsetTop,
                                behavior: 'smooth',
                            });
                        }}
                        className="letter" key={index}>{letter.id}</span>
                })}
            </div>
        </Grid>
        <Grid container
            spacing={1}
            style={{ width: 1200, padding: 0, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <div className={"brandsLetters"} style={{ alignSelf: 'center', width: '100%' }} ref={brandsListRef}>
                {letters?.map((letter, index) => {
                    return <LetterBrandSection
                        length={letters.length}
                        key={index}
                        index={index}
                        brands={brands}
                        item={letter}
                        onClick={(brand) => {
                            history.push(`brand/${brand.id}`)
                        }} />
                })}
            </div>
        </Grid>

    </Fragment>

}