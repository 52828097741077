import { ajax } from "rxjs/ajax"
import { map, catchError } from "rxjs/operators"
import { environment } from "../../environment/environment"
import { of } from "rxjs"
import { UserSerializer } from "./serializer"
import { User } from "./user"
import { ResourceProvider } from "../../providers/resource.provider"

const API_BASE_URL = environment.basePath

export const login = (username: string, password: string, token: string) => {
    const url = `${API_BASE_URL}/login_user`
    const userSerializer = new UserSerializer()
    const headers = {
        "Content-Type": "application/json"
    }
    const body = { username, password, token }
    return ajax({ url, method: "POST", headers, body }).pipe(
        map((resp: any) => {
            if (resp.response.message === "OK") {
                return userSerializer.fromJson({...resp.response.user, token: resp.response.token}) as User
            }
        }),
        catchError((error) => {
            return of(error);
        })
    )
};
export const loginFacebook = (token: string) => {
    const url = `${API_BASE_URL}/facebook_login_user`
    const userSerializer = new UserSerializer()
    const headers = {
        "Content-Type": "application/json"
    }
    const body = { token }
    return ajax({ url, method: "POST", headers, body }).pipe(
        map((resp: any) => {
            if (resp.response.message === "OK") {
                return userSerializer.fromJson({...resp.response.user, token: resp.response.token}) as User
            }
        }),
        catchError((error) => {
            return of(error);
        })
    )
};

const getToken = async () => {
    const user: User | null = await JSON.parse(localStorage.getItem("currentUser") ? localStorage.getItem("currentUser") : "{}");
    if (!user?.accessToken) {
        return 'app'
    }
    return user.accessToken;
}

export const logout = async () => {
    const token = await getToken()
    const headers = { "Accept": "*/*", "Content-Type": "application/json" }
    if (token && token.length) {
        headers["Authorization"] = `Bearer ${token}`
    }
    const url = `${API_BASE_URL}/logout_user`
    return ajax({ url, method: "DELETE", headers }).pipe(map((resp: any) => resp),
        catchError((error) => {
            return of(error)
        })
    ).toPromise();
}

export class UserRegistrationProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_user", new UserSerializer())
    }
}
export class UserPhoneActivationProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_app_user_phone_validation", new UserSerializer())
    }
}
export class UserAccountActivationProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_app_user_activation", new UserSerializer())
    }
}


export const userRegistrationService = new UserRegistrationProvider()
export const userPhoneActivationService = new UserPhoneActivationProvider()
export const userAccountActivationService = new UserAccountActivationProvider()


