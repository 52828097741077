import { ResourceProvider } from "../../providers/resource.provider"
import { Filter } from "./filter"
import { FilterSerializer } from "./serializer"

export class FilterProvider extends ResourceProvider<Filter> {
    constructor() {
        super("rest_filter", new FilterSerializer())
    }
}

export const filterService = new FilterProvider()

