import React, { forwardRef, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import * as Yup from "yup";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import { Box, Grid, TextField } from '@mui/material';
import { BlackButton } from '../Button/BlackButton';
import { ErrorMessage, Form, Formik } from 'formik';
import { userCommentService } from '../../models/userComment/service';
import { UserComment } from '../../models/userComment/UserComment';
import { Title } from '../Typography/Title';

const useStyles = makeStyles({
    error: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        paddingLeft: 30,
        alignItems: 'flex-start'
    },
    input: {
        width: 40,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 4,
        textAlign: 'center',
        fontSize: 20
    },
    phoneInput: {
        width: 150,
        height: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 4,
        textAlign: 'left',
        fontSize: 17
    }
})

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const FeedbackDialog = (props: any) => {
    const defaultText = "Default text"
    const { open = false,
        setOpen,
        onSuccess,
        type,
        translations } = props
    const [value, onChange] = useState('');
    const [state, setState] = useState('init');

    useEffect(() => {
        if (open) {

        }
    }, [open])


    const classes = useStyles();
    const handleClose = () => {
        setOpen(false);
    };
    const text_required = `${translations?.text} ${translations?.is_required}`
    const validation = Yup.object().shape({
        text: Yup.string().required(text_required)
    })

    const getValues = (values) => {
        const userComment = new UserComment()
        userComment.type = "recommendation"
        userComment.text = values.text
        userCommentService.create(userComment).then((r) => {
            if (r.text) {
                setOpen(false)
                onSuccess()
            }
        })
    }

    return open ? <Dialog
        open={open}
        style={{ zIndex: 10000 }}
        TransitionComponent={Transition}
        keepMounted
        onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleClose();
            }
        }}
        aria-describedby="alert-dialog-slide-description"
    >

        <DialogContent style={{ width: 400, overflow: 'hidden' }}>
            <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={handleClose}>
                <CloseIcon />
            </div>
            <Box sx={{ padding: 3 }}>
                <Title text={`${translations?.feedback_text}`} fontSize={16} uppercase={false} fontWeight='400' color={"#333"} />

            </Box>
            {<DialogContentText id="alert-dialog-slide-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    width: '100%',
                    paddingBottom: 20
                }}>
                <Formik
                    validationSchema={validation}
                    initialValues={{ text: '' }}
                    onSubmit={getValues}>
                    {({ values, handleSubmit, handleChange, handleBlur }: any) => (
                        <Form onSubmit={(values) => {
                            handleSubmit(values)
                        }} style={{ width: '90%' }}>
                            <Grid container spacing={1}>
                                <Grid item lg={12} sm={12} xs={12} style={{ padding: 0 }}>
                                    <TextField
                                        multiline
                                        rows={3}
                                        style={{ width: '100%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={translations?.text}
                                        variant="outlined"
                                        type="text"
                                        name="text"
                                        
                                        placeholder={translations.your_recommendation}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    <Box className={classes.error} >
                                        <ErrorMessage name="email" >
                                            {msg => <span className="error">{msg}</span>}
                                        </ErrorMessage>
                                    </Box>
                                </Grid>
                                <Grid item lg={12} sm={6} style={{ paddingLeft: 0, width: '100%', paddingTop: 20 }}>
                                    <BlackButton
                                        style={{ width: '100%' }}
                                        type="submit"
                                        title={translations.send}
                                        onClick={() => { }}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContentText>}

        </DialogContent>

    </Dialog > : <></>
}