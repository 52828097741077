import { ResourceProvider } from "../../providers/resource.provider"
import { UserCommentSerializer } from "./serializer"
import { UserComment } from "./UserComment"

export class UserCommentProvider extends ResourceProvider<UserComment> {
    constructor() {
        super("rest_user_comment", new UserCommentSerializer())
    }
}


export const userCommentService = new UserCommentProvider()
