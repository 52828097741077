export interface QueryBuilder {
	toQueryMap: () => Map<string, string>
	toQueryString: () => string
}

export class QueryOptions implements QueryBuilder {
	public id: string | undefined
	public pageSize: number | undefined
	public pageIndex: number | undefined
	public color_family: string | undefined
	public color: string | undefined
	public page: string | undefined
	public fragrance_family: string | undefined
	public fragrance_type: string | undefined
	public age_range: string | undefined
	public benefits: string | undefined
	public ingredient_preferences: string | undefined
	public cellulite_and_strech_marks: string | undefined
	public concerns: string | undefined
	public a_category: string | undefined
	public coverage: string | undefined
	public finish: string | undefined
	public formulation: string | undefined
	public foundation: string | undefined
	public size: string | undefined
	public skin_type: string | undefined
	public sun_protection: string | undefined
	public order: string | undefined
	public ordering: string | undefined
	public lang: string | undefined
	public filter: string | undefined
	public urls: string | undefined
	public employee: string | undefined
	public file: string | undefined
	public sort: string | undefined
	public tags: string | undefined
	public price: string | undefined
	public search: string | undefined
	public type: string | undefined
	public new: boolean | undefined
	public init: boolean | undefined
	public list: boolean
	public managers: boolean
	public branch: string | undefined
	public category: string | undefined
	public level: string | undefined
	public brand: string | undefined
	public min_price : number | undefined
	public max_price : number | undefined
	public tag: string | undefined
	public url: string | undefined
	public birthDate: string | undefined
	public date: string | undefined
	public shipping_method: string | undefined
	public city: string | undefined
	public product_line: string | undefined
	public workingDay: boolean | undefined
	constructor() { }

	toQueryMap() {
		const queryMap = new Map<string, string>()
		if (this.pageSize !== undefined) {
			queryMap.set('pageSize', `${this.pageSize}`)
		}
		if (this.color_family !== undefined) {
			queryMap.set('color_family', `${this.color_family}`)
		}
		if (this.page !== undefined) {
			queryMap.set('page', `${this.page}`)
		}
		if (this.city !== undefined) {
			queryMap.set('city', `${this.city}`)
		}
		if (this.url !== undefined) {
			queryMap.set('url', `${this.url}`)
		}
		if (this.shipping_method !== undefined) {
			queryMap.set('shipping_method', `${this.shipping_method}`)
		}
		if (this.a_category !== undefined) {
			queryMap.set('a_category', `${this.a_category}`)
		}
		if (this.color !== undefined) {
			queryMap.set('color', `${this.color}`)
		}
		if (this.product_line !== undefined) {
			queryMap.set('product_line', `${this.product_line}`)
		}
		if (this.ingredient_preferences !== undefined) {
			queryMap.set('ingredient_preferences', `${this.ingredient_preferences}`)
		}
		if (this.min_price !== undefined) {
			queryMap.set('min_price', `${this.min_price === 0.1 ? 0: this.min_price}`)
		}
		if (this.max_price !== undefined) {
			queryMap.set('max_price', `${this.max_price}`)
		}
		if (this.tag !== undefined) {
			queryMap.set('tag', `${this.tag}`)
		}
		if (this.brand !== undefined) {
			queryMap.set('brand', `${this.brand}`)
		}
		if (this.sun_protection !== undefined) {
			queryMap.set('sun_protection', `${this.sun_protection}`)
		}
		if (this.fragrance_family !== undefined) {
			queryMap.set('fragrance_family', `${this.fragrance_family}`)
		}
		if (this.fragrance_type !== undefined) {
			queryMap.set('fragrance_type', `${this.fragrance_type}`)
		}
		if (this.age_range !== undefined) {
			queryMap.set('age_range', `${this.age_range}`)
		}
		if (this.price !== undefined) {
			queryMap.set('price', `${this.price}`)
		}
		if (this.urls !== undefined) {
			queryMap.set('urls', `${this.urls}`)
		}
		if (this.benefits !== undefined) {
			queryMap.set('benefits', `${this.benefits}`)
		}
		if (this.cellulite_and_strech_marks !== undefined) {
			queryMap.set('cellulite_and_strech_marks', `${this.cellulite_and_strech_marks}`)
		}
		if (this.concerns !== undefined) {
			queryMap.set('concerns', `${this.concerns}`)
		}
		if (this.finish !== undefined) {
			queryMap.set('finish', `${this.finish}`)
		}
		if (this.formulation !== undefined) {
			queryMap.set('formulation', `${this.formulation}`)
		}
		if (this.foundation !== undefined) {
			queryMap.set('foundation', `${this.foundation}`)
		}
		if (this.size !== undefined) {
			queryMap.set('size', `${this.size}`)
		}
		if (this.skin_type !== undefined) {
			queryMap.set('skin_type', `${this.skin_type}`)
		}
		if (this.sun_protection !== undefined) {
			queryMap.set('sun_protection', `${this.sun_protection}`)
		}
		if (this.coverage !== undefined) {
			queryMap.set('coverage', `${this.coverage}`)
		}
		if (this.brand !== undefined) {
			queryMap.set('brand', `${this.brand}`)
		}
		if (this.category !== undefined) {
			queryMap.set('category', `${this.category}`)
		}
		if (this.filter !== undefined) {
			queryMap.set('filter', `${this.filter}`)
		}
		if (this.lang !== undefined) {
			queryMap.set('lang', `${this.lang}`)
		}
		if (this.birthDate !== undefined) {
			queryMap.set('birth_date', `${this.birthDate}`)
		}
		if (this.new !== undefined) {
			queryMap.set('new', `${this.new}`)
		}
		if (this.managers !== undefined) {
			queryMap.set('managers', `${this.managers}`)
		}
		if (this.tags !== undefined) {
			queryMap.set('tags', `${this.tags}`)
		}
		if (this.level !== undefined) {
			queryMap.set('level', `${this.level}`)
		}
		if (this.list !== undefined) {
			queryMap.set('list', `${this.list}`)
		}
		if (this.type !== undefined) {
			queryMap.set('type', `${this.type}`)
		}
		if (this.file !== undefined) {
			queryMap.set('file', `${this.file}`)
		}
		if (this.search !== undefined) {
			queryMap.set('search', `${this.search}`)
		}
		if (this.date !== undefined) {
			queryMap.set('date', `${this.date}`)
		}
		if (this.init !== undefined) {
			queryMap.set('init', `${this.init}`)
		}

		if (this.branch !== undefined) {
			queryMap.set('branch', `${this.branch}`)
		}

		if (this.pageIndex !== undefined) {
			queryMap.set('pageIndex', `${this.pageIndex}`)
		}

		if (this.employee !== undefined) {
			queryMap.set('employee', `${this.employee}`)
		}

		if (this.order) {
			queryMap.set('order', this.order)
		}

		if (this.ordering) {
			queryMap.set('ordering', this.ordering)
		}

		if (this.id) {
			queryMap.set('id', `${this.id}`)
		}

		if (this.sort !== undefined) {
			queryMap.set('sort', `${this.sort}`);
		}
		return queryMap
	}

	toQueryString() {
		let queryString = ''

		this.toQueryMap().forEach((value: string, key: string) => {
			if (value !== 'null') {
				queryString = queryString.concat(`${key}=${value}&`)
			}
		})

		return queryString.substring(0, queryString.length - 1)
	}
	toQueryStringWithUrl(url) {
		let queryString = ''

		this.toQueryMap().forEach((value: string, key: string) => {
			if (value !== 'null') {
				queryString = queryString.concat(`${key}=${value}&`)
			}
		})

		return `${url}?${queryString.substring(0, queryString.length - 1)}`
	}
}