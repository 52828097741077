import { Grid } from "@mui/material"
import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { checkRoute } from "../../helpers/utilities"

export const FooterLinksComponent = (props) => {
    const {item, content} = props
    const history = useHistory()

    return (
        <Grid item lg={2.75} sm={3}>
            <div
                style={{
                    padding: 0,
                }}
            >
                <ul
                    style={{
                        listStyle: "none",
                        alignItems: "flex-start",
                        textAlign: "left",
                    }}
                >
                    <li
                        className="uppercase"
                        style={{
                            color: "white",
                            fontFamily: "FiraGo",
                            fontSize: 15,
                            fontWeight: "500",
                            marginBottom: 20,
                            cursor: 'pointer'
                        }}
                        onClick={() => history.push(checkRoute(item === 'right' ? content[item].list[3].id : content[item].list[0].id))}
                    >
                        {content[item].title}
                    </li>
                    {content[item].list.map((sItem, index) => {
                        return (
                            <li
                                className="underline-on-hover"
                                key={index}
                                style={{
                                    cursor: "pointer",
                                    color: "white",
                                    fontFamily: "FiraGo",
                                    fontWeight: "400",
                                    fontSize: 14,
                                    marginBottom: 14,
                                }}
                                onClick={() => {
                                    if (sItem?.url?.length) {
                                        history.push(sItem.url)
                                    }
                                }}
                            >
                                {sItem.title}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </Grid>
    )
}
