
import { Box, Collapse } from '@mui/material';
import React, { Fragment, useState, useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Title } from '../../Typography/Title';

export const AboutTheBrandComponent = (props) => {
    const { brand, translations,language } = props
    const [shown, setShown] = useState(false)


    useEffect(() => {

    }, [brand])

    return <Fragment>
        <Box 
        sx={{display: 'flex', 
        marginTop: 2,
        cursor: 'pointer',
        alignItems: 'center', justifyContent: 'flex-start', marginBottom: 1}}
        onClick={()=> {
            setShown(s => !s)
        }}>
            <Title text={`${translations?.about_the_brand}`} fontSize={16} uppercase={false} fontWeight='400' color={"#333"} />
            <ArrowBackIosIcon style={{fontSize: 20,marginLeft: 10,transform:  `rotate(${!shown ? -90: 90}deg) translate(${shown ? 4: 4}px)`}}/>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
            <Collapse orientation="vertical" in={shown} collapsedSize={0} style={{textAlign: 'left'}}>
            {brand?.text && <Box dangerouslySetInnerHTML={{ __html: brand?.text.replace(/\\n/g, "").replace(/\\r/g, "") }}  style={{fontSize: 14}}/>}
            </Collapse>
        </Box>
        { }

    </Fragment>
}