import { ResourceProvider } from "../../providers/resource.provider"
import { Coupon, LoyaltyCard } from "./Coupon"
import { CouponSerializer, LoyaltyCardSerializer } from "./serializer"

export class CouponProvider extends ResourceProvider<Coupon> {
    constructor() {
        super("rest_coupon", new CouponSerializer())
    }
}

export const couponService = new CouponProvider()


export class LoyaltyCardProvider extends ResourceProvider<LoyaltyCard> {
    constructor() {
        super("rest_loyalty_card", new LoyaltyCardSerializer())
    }
}

export const loyaltyService = new LoyaltyCardProvider()

