import { environment } from '../../environment/environment'
import { BranchSerializer } from '../branch/serializer'
import {NameIdItemSerializer } from '../common/serializer'
import { countrySerializer } from '../country/serializer'
import { Serializer } from '../serializer'
import { User, UserRole, UserPermissions, PersonalInfo, PreviousPosition,
    EmbeddedPosition, Position, EmbeddedAddress} from './user'


export class UserPermissionsSerializer implements Serializer {
    fromJson(array: any[]) {
        const permissions = new UserPermissions()
        const roles = ["read", "write", "update", "delete", "export"]
        for (var x = 0; x < roles.length; x++) {
            const [role] = array.filter(p => roles[x] === p.name);
            if (role) {
                permissions[roles[x]] = true
                
            } else {
                permissions[roles[x]] = false
            }
        }
        return permissions
    }

    toJson(user: User) {
        const obj: any = {}
        
        return obj
    }
}
export class UserRoleSerializer implements Serializer {
    fromJson(json: any) {
        const userRole = new UserRole()
        userRole.name = json.name
        const serializer = new UserPermissionsSerializer()
        userRole.permissions = serializer.fromJson(json.permissions)
        return userRole
    }

    toJson(user: User) {
        const obj: any = {}
        
        return obj
    }
}

export class UserSerializer implements Serializer {
    fromJson(json: any) {
        const user = new User()
        if (json.id) {
            user.id = json.id
        }
        user.activated = json.activated
        user.message = json.message
        user.accessToken = json.token
        user.lastName = json.last_name
        user.firstName = json.first_name
        user.email = json.email
        user.fullName = `${json.first_name} ${json.last_name}`
        user.phone = json.phone
        user.status = json.status
        return user
    }

    toJson(user: User) {
        const obj: any = {}
        
        obj.id = user.id
        if (user.firstName) {
            obj.first_name = user.firstName
        }
        if (user.lastName) {
            obj.last_name = user.lastName
        }
        if (user.phone) {
            obj.phone = user.phone
        }
        
        if (user.email) {
            obj.email = user.email
        }
        if (user.password) {
            obj.password = user.password
        }
        if (user.code) {
            obj.code = user.code
        }
        return obj
    }
}

export class EmbeddedPositionSerializer implements Serializer {
    fromJson(json: any) {
        const position = new EmbeddedPosition()
        if (json.department) {
            const ds = new NameIdItemSerializer()
            position.department = ds.fromJson(json.department)

        }
        position.id = json.id
        position.name = json.name
        
        return position
    }

    toJson(item: any) {
        return item
    }
}

export class PreviousPositionSerializer implements Serializer {
    fromJson(json: any) {
        const position = new PreviousPosition()
        position.caption = json.caption
        position.dateFrom = new Date(json.date_from * 1000)
        position.dateTo = new Date(json.date_to * 1000)
        position.info = json.info
        if (json.position) {
            const embSerializer = new EmbeddedPositionSerializer()
            position.position = embSerializer.fromJson(json.position)
        }
        return position
    }

    toJson(item: any) {
        return item
    }
}
export class PositionSerializer implements Serializer {
    fromJson(json: any) {
        const position = new Position()
        if (json.current_position) {
            const embSerializer = new EmbeddedPositionSerializer()
            position.currentPosition = embSerializer.fromJson(json.current_position)
        }
        const branchSerializer = new BranchSerializer()
        
        if (json.branch && json.branch.id) {
            position.branch = branchSerializer.fromJson(json.branch)
        }

        position.level = json.level
        if (json.previous_positions) {
            const embSerializer = new PreviousPositionSerializer()
            position.previousPositions = json.previous_positions.map(p => embSerializer.fromJson(p))
        }
        return position
    }

    toJson(item: any) {
        return item
    }
}

export class PersonalInfoSerializer implements Serializer {
    fromJson(json: any) {
        const info = new PersonalInfo()
        const dt = new Date(json.birth_date * 1000)
        info.birthDate = json.birth_date
        info.citizenship = json.citizenship
        info.maritalStatus = json.marital_status
        return info
    }

    toJson(item: any) {
        return item
    }
}
export class EmbeddedAddressSerializer implements Serializer {
    fromJson(json: any) {
        const embedded = new EmbeddedAddress()
        embedded.city = json.city
        if (json.country) {
            embedded.country  = countrySerializer.fromJson(json.country)
        }
        embedded.postalCode = json.postal_code
        embedded.street = json.street
        embedded.street2 = json.street2
        return embedded
    }

    toJson(item: any) {
        return item
    }
}



export const employeeSerializer = new UserSerializer()
export const userSerializer = new UserSerializer()