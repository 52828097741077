import React, { useCallback, useEffect, useState } from "react"
import { Box, Grid, TextField } from '@mui/material';
import { Form, Formik, ErrorMessage } from "formik"
import { connect } from 'react-redux'
import { authenticate, login } from '../../models/user/authActions'
import {
    login as loginUser
} from "../../models/user/service"
import { User } from "../../models/user/user"
import { setCart, setFavorites, setSnackBar } from '../../models/application/applicationActions'
import * as Yup from "yup";
import { SMSDialog } from "../Dialogs/SMSDialog";
import { BlackButton } from "../Button/BlackButton";
import { makeStyles } from "@mui/styles";


const LoginForm = (props: any) => {
    const { onOpen, setSnackBar, onLogin, app, translations } = props
    const [open, setOpen] = useState(false as any)
    const classes = useStyles()
    const [smsDialogType, setSMSDialogType] = useState("")
    const [user, setUser] = useState<User>()
    const [smsDialogOpen, setSmsDialogOpen] = useState(false)
    const [tokenMode, setTokenMode] = useState(false)
    const [wrongCredentials, setWrongCredentials] = useState(false)
    useEffect(() => {
        setOpen(onOpen)
    }, [onOpen])
    const email_required = `${translations?.email} ${translations?.is_required}`
    const password_required = `${translations?.password} ${translations?.is_required}`
    const validation = Yup.object().shape({
        email: Yup.string().required(email_required),
        password: Yup.string().required(password_required)
    })
    const RenderSMSDialog = useCallback(() => {
        return <SMSDialog
            textAccept={translations?.send}
            onSuccess={(type) => {
                let resp = JSON.parse(localStorage.getItem("tempUser"))
                let user = new User()
                user = user.updateUser(resp)
                props.login(user)
                onLogin()
                setSnackBar({ ...app.snackBar, open: true, text: `${translations?.welcome} ${user.firstName}.`, bColor: "rgb(83, 148, 96)" })
            }}
            user={user}
            type={smsDialogType}
            open={smsDialogOpen} {...props}
            setOpen={setSmsDialogOpen}
        />
    }, [smsDialogOpen, smsDialogType, user])


    const getValues = (values) => {
        loginUser(values.email, values.password, values.token).subscribe((resp: User | any) => {
            if (resp?.accessToken) {
                localStorage.setItem("tempUser", JSON.stringify(resp))
                if (resp.activated) {
                    let user = new User()
                    user = user.updateUser(resp)
                    props.login(user)
                    onLogin()
                    setSnackBar({ ...app.snackBar, open: true, text: `${translations?.welcome} ${user.firstName}.` , bColor: "rgb(83, 148, 96)"})
                } else {
                    setUser(resp as User)
                    setSMSDialogType("register")
                    setTimeout(() => {
                        setSmsDialogOpen(true)
                    }, 50)
                }
            }
            if (resp.status === 401) {
                setWrongCredentials(true)
            }
            if (resp.status === 402) {
                setTokenMode(true)
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: resp.response.message, open: true })
                setTimeout(() => {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: resp.response.message, open: false })
                }, 3000)
            }
        })
    }

    return (
        <Formik
            validationSchema={validation}
            initialValues={{ email: '', password: '' }}
            onSubmit={getValues}>
            {({ values, handleSubmit, setFieldValue, handleChange, handleBlur, errors }: any) => (
                <Form onSubmit={(values) => {
                    console.log(errors)
                    handleSubmit(values)
                }}>
                    <RenderSMSDialog />
                    <Grid container spacing={1}>
                        <Grid item lg={12} sm={12} xs={12} style={{ padding: 0 }}>
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="outlined-basic"
                                label={translations?.email}
                                variant="outlined"
                                type="text"
                                name="email"
                                onChange={(event) => {
                                    setWrongCredentials(false)
                                    handleChange(event)
                                }}
                                onBlur={handleBlur}
                                value={values.email}
                            />
                            <Box className={classes.error} >
                                <ErrorMessage name="email" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>
                            </Box>
                        </Grid>
                        <Grid item lg={12} xs={12} style={{ padding: 0 }}>
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="outlined-basic"
                                label={translations?.password}
                                color="primary"
                                variant="outlined"
                                type="password"
                                name="password"
                                onChange={(event) => {
                                    setWrongCredentials(false)
                                    handleChange(event)
                                }}
                                onBlur={handleBlur}
                                value={values.password}
                            />
                            <Box className={classes.error} >
                                <ErrorMessage name="password" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>
                            </Box>
                        </Grid>
                        {tokenMode && <Grid item lg={12} sm={12} xs={12} >
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="outlined-basic"
                                value={values.token}
                                label={'მიღებული 4 ნიშნა კოდი'}
                                variant="outlined"
                                type="number"
                                name="token"
                                onChange={(event) => {
                                 
                                    setFieldValue("token", event.target.value)
                                }}
                                onBlur={handleBlur}
                            />
                        </Grid>}
                        <Grid item lg={12} sm={12} style={{ paddingLeft: 4, width: '100%', paddingTop: 20 }}>
                            <BlackButton              
                                style={{ width: '90%' , maxWidth: 493 }}
                                type="submit"
                                title={translations.login}
                                onClick={() => { }}
                            />
                        </Grid>
                        {wrongCredentials && <span className="error">
                            {translations?.wrong_credentials}</span>}
                    </Grid>

                </Form>
            )}
        </Formik>)

}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}


export default connect(mapStateToProps, { setFavorites, authenticate, login, setSnackBar, setCart })(LoginForm)

const useStyles = makeStyles({
    error: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        paddingLeft: 30,
        alignItems: 'flex-start'
    }
})