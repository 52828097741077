import React, { useCallback, useEffect, useState } from "react"
import { Grid, TextField, Button, Box } from "@mui/material"
import { Form, Formik, ErrorMessage } from "formik"
import { connect } from 'react-redux'
import { authenticate, login } from '../../models/user/authActions'
import '../styles.scss'
import { userRegistrationService } from "../../models/user/service"
import { User } from "../../models/user/user"
import { setSnackBar } from '../../models/application/applicationActions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import * as Yup from "yup";
import { SMSDialog } from "../Dialogs/SMSDialog"
import { BlackButton } from "../Button/BlackButton"
import { makeStyles } from "@mui/styles"

const RegisterForm = (props: any) => {
    const { onOpen, onClose, setSnackBar, dialogMode = false, onLogin, user = null, app, translations } = props
    const [open, setOpen] = useState(false as any)
    const [tokenMode, setTokenMode] = useState(false)
    const [formError, setFormError] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [smsDialogType, setSMSDialogType] = useState("")
    const [smsDialogOpen, setSmsDialogOpen] = useState(false)
    const [lUser, setLUser] = useState<User>()
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [wrongCredentials, setWrongCredentials] = useState(false)
    const handleCloseDialog = () => { onClose(false) }
    const classes = useStyles()
    const handleClose = () => {
        onClose(false)
    }
    const email_required = `${translations?.email} ${translations?.is_required}`
    const password_required = `${translations?.password} ${translations?.is_required}`
    const phone_required = `${translations?.phone} ${translations?.is_required}`
    const password_confirmation_required = `${translations?.password_confirmation} ${translations?.is_required}`
    const email_format_error = `${translations?.wrong_format}: ${translations?.email}`

    const first_name_required = `${translations?.first_name} ${translations?.is_required}`
    const last_name_required = `${translations?.last_name} ${translations?.is_required}`
    let wrong_format_9 = `${translations?.format_error_9}`
    let wrong_format_min_2 = `${translations?.wrong_format_min}`
    if (wrong_format_min_2) {
        wrong_format_min_2 = wrong_format_min_2.replace("%s", "2")
    }
    let wrong_format_min_5 = `${translations?.wrong_format_min}`
    if (wrong_format_min_5) {
        wrong_format_min_5 = wrong_format_min_5.replace("%s", "5")
    }

    const validationRegistration = Yup.object().shape({
        email: Yup.string().required(email_required).email(email_format_error),
        firstName: Yup.string().required(first_name_required).min(3, wrong_format_min_2),
        lastName: Yup.string().required(last_name_required).min(2, wrong_format_min_2),
        phone: Yup.string().required(phone_required).min(9, wrong_format_9).max(9, wrong_format_9),
        password: Yup.string().required(password_required).min(5, wrong_format_min_5),
        confirmpassword: Yup.string().required(password_confirmation_required).min(5, wrong_format_min_5).when("password", {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                translations?.passwords_dont_coincide
            )
        })
    })
    const validationUpdate = Yup.object().shape({
        email: Yup.string().required(email_required).email(email_format_error),
        firstName: Yup.string().required(first_name_required).min(3, wrong_format_min_2),
        lastName: Yup.string().required(last_name_required).min(2, wrong_format_min_2),
        phone: Yup.string().required(phone_required).min(9, wrong_format_9).max(9, wrong_format_9),
    })
    useEffect(() => {
        setOpen(onOpen)
    }, [onOpen])

    const getValues = (values) => {

        setFormError("")
        const usr = new User()
        usr.firstName = values.firstName
        usr.lastName = values.lastName
        usr.phone = values.phone
        usr.email = values.email
        if (!user) {
            usr.password = values.password
            userRegistrationService.create(usr).then((r: any) => {

                if (r.status === "FAIL") {
                    const text = ` - ${usr.email}`
                    setFormError(translations[r.message].replace("%s", text))
                } else {
                    localStorage.setItem("tempUser", JSON.stringify(r))
                    if (r.activated) {
                        let user = new User()
                        user = user.updateUser(r)
                        props.login(user)
                        var now = new Date();
                        onLogin()
                        setSnackBar({ ...app.snackBar, open: true, text: `${translations?.welcome} ${usr.firstName}.`, bColor: "rgb(83, 148, 96)" })
                    } else {
                        setLUser(r as User)
                        setSMSDialogType("register")
                        setTimeout(() => {
                            setSmsDialogOpen(true)
                        }, 50)
                    }
                }
            })
        } else {
            usr.id = user.id
            userRegistrationService.update(usr).then((r: any) => {
                if (r.status === "FAIL") {
                    setFormError(r.message)
                } else {
                    if (r.activated) {
                        let userObj = new User()
                        userObj = userObj.updateUser({ ...r, accessToken: user.accessToken })
                        props.login(userObj)
                        setSnackBar({ ...app.snackBar, open: true, text: `${translations.update_successful}`, bColor: "rgb(83, 148, 96)" })
                    } else {
                        setLUser(r as User)
                        setSMSDialogType("register")
                        setTimeout(() => {
                            setSmsDialogOpen(true)
                        }, 50)
                    }
                }
            })
        }

    }

    const RenderSMSDialog = useCallback(() => {
        return <SMSDialog
            textAccept={translations?.send}
            onSuccess={() => {
              
                let resp = JSON.parse(localStorage.getItem("tempUser"))
                let user = new User()
                user = user.updateUser(resp)
                props.login(user)
                onLogin()
                setSnackBar({ ...app.snackBar, open: true, text: `${translations?.welcome} ${user.firstName}.`, bColor: "rgb(83, 148, 96)" })
            }}
            user={lUser}
            type={smsDialogType}
            open={smsDialogOpen} {...props}
            setOpen={setSmsDialogOpen}
        />
    }, [smsDialogOpen, smsDialogType, lUser])

    return (
        <Formik
            validationSchema={!user ? validationRegistration : validationUpdate}
            initialValues={user ?
                {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    phone: user.phone,
                    password: "",
                    confirmpassword: ""
                } :
                {
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    phone: "",
                    confirmpassword: ""
                }}
            onSubmit={getValues}>
            {({ values, handleSubmit, setFieldValue, handleChange, handleBlur, errors }: any) => (
                <Form onSubmit={(values) => {
                    return handleSubmit(values)
                }}>
                    <RenderSMSDialog />
                    <Grid container spacing={1} style={{ paddingTop: 20 }}>
                        <Grid item lg={6} sm={6} xs={12} style={{ padding: 0 }}>
                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <TextField
                                    style={{ width: '90%', marginTop: 0 }}
                                    id="outlined-basic"
                                    label={translations?.first_name}
                                    variant="outlined"
                                    type="text"
                                    name="firstName"
                                    onChange={(event) => {
                                        setWrongCredentials(false)
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                />

                            </Box>
                            <Box className={classes.error}>
                                <ErrorMessage name="firstName" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>
                            </Box>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12} style={{ padding: 0 }}>
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <TextField
                                    style={{ width: '90%', marginTop: 0 }}
                                    id="outlined-basic"
                                    label={translations?.last_name}
                                    variant="outlined"
                                    type="text"
                                    name="lastName"
                                    onChange={(event) => {
                                        setWrongCredentials(false)
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                />
                            </Box>
                            <Box className={classes.error}>
                                <ErrorMessage name="lastName" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>
                            </Box>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12} style={{ padding: 0 }}>
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <TextField
                                    disabled={user}
                                    style={{ width: '90%', marginTop: 0 }}
                                    id="outlined-basic"
                                    label={translations?.email}
                                    variant="outlined"
                                    type="text"
                                    name="email"
                                    onChange={(event) => {
                                        setWrongCredentials(false)
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                            </Box>
                            <Box className={classes.error}>
                                <ErrorMessage name="email" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>
                            </Box>
                        </Grid>

                        <Grid item lg={6} sm={6} xs={12} style={{ padding: 0 }}>
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <TextField
                                    style={{ width: '90%', marginTop: 0 }}
                                    id="outlined-basic"
                                    label={translations?.phone}
                                    variant="outlined"
                                    type="text"
                                    name="phone"
                                    onChange={(event) => {
                                        setWrongCredentials(false)
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                />
                            </Box>
                            <Box className={classes.error}>
                                <ErrorMessage name="phone" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>
                            </Box>
                        </Grid>
                        {!user && <Grid item lg={6} sm={6} xs={12} style={{ position: 'relative', padding: 0 }}>
                            {values.password?.length > 0 && <div style={{ position: 'absolute', right: 40, top: 17, zIndex: 100 }} onClick={() => { setShowPassword(!showPassword) }}>
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </div>}
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <TextField
                                    style={{ width: '90%', marginTop: 0 }}
                                    id="outlined-basic"
                                    label={translations?.password}
                                    variant="outlined"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    onChange={(event) => {
                                        setWrongCredentials(false)
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />

                            </Box>
                            <Box className={classes.error}>
                                <ErrorMessage name="password" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>
                            </Box>
                        </Grid>}
                        {!user && <Grid item lg={6} sm={6} xs={12} style={{ position: 'relative', padding: 0 }}>
                            {values.confirmpassword?.length > 0 && <div style={{
                                position: 'absolute',
                                right: 40, top: 17, zIndex: 100
                            }}
                                onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}>
                                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </div>}
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <TextField
                                    style={{ width: '90%', marginTop: 0 }}
                                    id="outlined-basic"
                                    label={translations?.password_confirmation}
                                    variant="outlined"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirmpassword"
                                    onChange={(event) => {
                                        setWrongCredentials(false)
                                        handleChange(event)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.confirmpassword}
                                />

                            </Box>
                            <Box className={classes.error}>
                                <ErrorMessage name="confirmpassword" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>

                            </Box>
                        </Grid>}
                        {tokenMode && <Grid item lg={12} sm={12} xs={12}>
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="outlined-basic"
                                value={values.token}
                                label={'მიღებული 4 ნიშნა კოდი'}
                                variant="outlined"
                                type="number"
                                name="token"
                                onChange={(event) => {
                                    
                                    setFieldValue("token", event.target.value)
                                }}
                                onBlur={handleBlur}
                            />
                        </Grid>}
                        {wrongCredentials && <span className="error">
                            {translations?.wrong_credentials} </span>}
                        <Grid item lg={6} sm={6}></Grid>
                        <Grid item lg={6} sm={6} style={{ marginTop: 10, paddingBottom: 80, padding: 10, }} xs={12}>
                            <Box style={{ padding: 10,display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {dialogMode &&
                                    <Button onClick={handleCloseDialog} color="primary" style={{  }}>
                                        {translations?.cancel}
                                    </Button>
                                }
                                <BlackButton
                                    type="submit"
                                    title={!user ? translations?.registration : translations.update}
                                    onClick={() => { }}
                                />
                            </Box>
                        </Grid>
                        {formError?.length > 0 && <Grid item lg={12} sm={6} xs={12} style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', padding: 20 }}>
                            <Stack sx={{ width: '70%', alignSelf: 'center' }} spacing={2}>
                                <Alert severity="error">{formError}</Alert>
                            </Stack>
                        </Grid>}
                    </Grid>
                </Form>
            )}
        </Formik>)

}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { authenticate, login, setSnackBar })(RegisterForm)

const useStyles = makeStyles({
    error: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        paddingLeft: 30,
        alignItems: 'flex-start'
    }
})