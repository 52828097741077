import { ResourceProvider } from "../../providers/resource.provider"
import { Review, ReviewSummary } from "./Review"
import { ReviewSerializer, ReviewSummarySerializer } from "./serializer"



export class ReviewProvider extends ResourceProvider<Review> {
    constructor() {
        super("rest_review", new ReviewSerializer())
    }
}

export class ReviewSummaryProvider extends ResourceProvider<ReviewSummary> {
    constructor() {
        super("rest_review_summary", new ReviewSummarySerializer())
    }
}

export const reviewSummaryService = new ReviewSummaryProvider()
export const reviewService = new ReviewProvider()

