import React  from "react"

import { Facebook } from "../General/Facebook"
import { Youtube } from "../General/Youtube"
import { Instagram } from "../General/Instagram"

export const SocialLinks = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            <a
                style={{
                    maxWidth: "inherit",
                }}
                href="https://www.facebook.com/VoulezVous.Perfumery"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Facebook radius={28} color={"white"} iconSize={18} />
            </a>
            <a
                style={{
                    maxWidth: "inherit",
                }}
                href="https://www.instagram.com/voulez_vous_perfumery/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Instagram radius={28} color={"white"} iconSize={18} />
            </a>
            <a
                style={{
                    maxWidth: "inherit",
                }}
                href="https://www.youtube.com/@voulez-vous3795"
                target="_blank"
                rel="noopener noreferrer"
            >
                <Youtube radius={28} color={"white"} iconSize={18} />
            </a>
        </div>
    )
}