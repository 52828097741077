import React, { useState } from "react";
import { Grid, IconButton, Snackbar } from "@mui/material";
import { connect } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { setSnackBar } from '../models/application/applicationActions'
import RegisterForm from "../components/Forms/RegisterForm";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { Title } from "../components/Typography/Title";
import ForgotPasswordFormDialog from "../components/Dialogs/ForgotPasswordFormDialog";


const RegisterPage = (props) => {
  const { app, translations } = props
  const [registerOpen, setRegisterOpen] = React.useState(false)
  const [passwordRestoreOpen, setPasswordRestoreOpen] = React.useState(false)
  const styles = useStyles()
  const history = useHistory();
  const [snackbar, setSnackbar] = useState<any>({ open: false, vertical: 'bottom', horizontal: 'center', text: '' })
  const { vertical, horizontal, open, text } = snackbar;
  const handleSnackbarClose = () => {
    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
  };
  React.useEffect(() => {
    setSnackbar(app.snackBar)
  }, [app.snackBar])

  return <div className={styles.container} style={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
    <ForgotPasswordFormDialog
      translations={translations}
      onOpen={passwordRestoreOpen} onClose={setPasswordRestoreOpen} />
    <Grid container spacing={1}>
      <Grid item lg={3}></Grid>
      <Grid item lg={6} style={{padding: 20}}>
        <Title text={translations?.registration} fontSize={24} uppercase={true} fontWeight='500' />
        <RegisterForm 
        {...props}
        onLogin={()=> {
          history.push("/")
        }}/>
      </Grid>
      <Grid item lg={3}></Grid>
    </Grid>

    
    <div className={styles.login} style={{ marginTop: 50, marginLeft: 'auto', marginRight: 'auto', alignItems: 'center', justifyContent: 'center'}}>
      
      <span
        style={{ cursor: 'pointer', marginLeft: 40 }}
        onClick={() => {
          setPasswordRestoreOpen(true)
        }}>{translations?.forgot_password}</span>
    </div>


    <div className={styles.login} style={{ marginTop: 0, marginLeft: 'auto', marginRight: 'auto' }}>
      <br />

    </div>
  </div>
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    translations: state.translations
  }
}

export default connect(mapStateToProps, { setSnackBar })(RegisterPage)

const useStyles = makeStyles({
  container: {
    height: '100vh',
    width: '100%',
    background: '#fafafa',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center', textflexDirection: 'column'
  },
  login: {
    flex: 1,
    padding: 20,
    maxWidth: 400,
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'row'
  }

})

