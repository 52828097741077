import { ResourceProvider } from '../../providers/resource.provider';
import { ContactMessage } from './ContactMessage';
import { ContactMessageSerializer } from './serializer';

export class ContactMessageProvider extends ResourceProvider<ContactMessage> {
  constructor(
  ) {
    super(
      'rest_contact_message',
      new ContactMessageSerializer());
  }
}

export const contactMessageService = new ContactMessageProvider();
