import React, { useCallback, useState, useEffect } from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import { COLORS } from '../../constants/colors';
import { CategoriesList } from '../../components/Filter/CategoriesList';
import { MobileProductGrid } from '../../components/Product/MobileProductGrid';
import { Animated } from "react-animated-css";
import { FilterChips } from '../../components/Filter/FIlterChips';
import BreadCrumbsComponent from '../../components/BreadCrumbsComponent'
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

export const CatalogContentMobile = (props) => {
    const {
        breadCrumbList,
        app,
        appendAttributesToUrl,
        categories,
        cat,
        filters,
        setFilterItem,
        translations,
        setFilterOpen,
        child,
        parent,
        location,
        history,
        setQueryParams,
        queryParams,
        setSortOpen
    } = props

    const [showFilterButton, setShowFilterButton] = useState(true)
    const [scroll, setScroll] = useState(0)
    const [showContent, setShowContent] = useState(true)
    const {width} = useWindowDimensions()


    useEffect(() => {
        if (scroll > window.innerHeight - 200) {
            setShowFilterButton(true)
        } else {
            setShowFilterButton(false)
        }
    }, [scroll])

    useEffect(() => {
        window.addEventListener("scroll", (e) => { setScroll(window.scrollY) });
        return () => {
            setShowContent(false)
            window.removeEventListener("scroll", (e) => console.log(e));
        };
        
    }, []);

    const RendertCategoriesList = useCallback(() => {
        return <CategoriesList
            breadCrumbs={breadCrumbList}
            categories={categories}
            cat={cat}
            child={child}
            {...props}
            parent={parent}
            location={location}
            history={history}
        />
    }, [breadCrumbList])

    const RendertFilterButton = useCallback(() => {
        return <Animated
            animationIn="fadeInUp"
            animationInDuration={300}
            animationOut="fadeOutDown"
            style={{
                width: window.innerWidth,
                paddingTop: 10,
                height: 120,
                backgroundColor: 'transparent',
                position: 'fixed',
                zIndex: 10000,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                bottom: 0,
                right: 0
            }}
            isVisible={showFilterButton} >
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button onClick={() => setFilterOpen(true)} sx={{
              backgroundColor: COLORS.BEIGE_I,
              width: 130,
              height: 50,
              color: '#444',
              '&:hover': {
                color: '#444',
                backgroundColor: COLORS.BEIGE_I,
              }
            }}>
              {translations?.filter}
            </Button>
            <Button onClick={() => setFilterOpen(true)} sx={{
              backgroundColor: COLORS.BEIGE_I,
              width: 130,
              height: 50,
              color: '#444',
              '&:hover': {
                color: '#444',
                backgroundColor: COLORS.BEIGE_I,
              }
            }}>
              {translations?.sort}
            </Button>
          </ButtonGroup>
        </Animated>

    }, [showFilterButton])

    return showContent ? <Box style={{ padding: 10, position: 'relative' }}>
      {width < 600 && (
                <Box sx={{ display: "flex", justifyItems: "flex-start" }}>
                    <ArrowBackIcon
                        onClick={() => history.goBack()}
                        sx={{ color: "black", fontSize: 35, paddingTop: 4 }}
                    />
                </Box>
            )}
        <RendertFilterButton />

        <Box style={{ marginBottom: 30 }}>
            <div className={"container"} style={{
                display: 'flex',
                width: 1200,
                paddingTop: 10,
                alignItems: 'flex-start',
                margin: '0px auto'
            }}>
                <BreadCrumbsComponent items={breadCrumbList} />
            </div>
        </Box>
        <Box style={{ marginBottom: 30 }}>
            <RendertCategoriesList />
        </Box>
        <Box style={{ marginBottom: 30 }}>
            <FilterChips
                filters={filters}
                handleDelete={(item) => {
                    setFilterItem(item)
                }}
            />
        </Box>


      <ButtonGroup style={{
        marginTop: 3,
        marginBottom: 3,
      }} variant="contained" aria-label="outlined primary button group">
        <Button onClick={() => setFilterOpen(true)} sx={{
          backgroundColor: COLORS.BEIGE_I,
          width: 130,
          borderColor: 'black !important',
          height: 50,
          color: '#444',
          '&:hover': {
            color: '#444',
            backgroundColor: COLORS.BEIGE_I,
          }
        }}>
          {translations?.filter}
        </Button>
        <Button onClick={() => setSortOpen(true)} sx={{
          backgroundColor: COLORS.BEIGE_I,
          width: 130,
          height: 50,
          color: '#444',
          '&:hover': {
            color: '#444',
            backgroundColor: COLORS.BEIGE_I,
          }
        }}>
          {translations?.sort}
        </Button>
      </ButtonGroup>
        <MobileProductGrid
            {...props}
            app={app}
            location={location}
            onSetPage={(index) => {
                setQueryParams(params => {
                    params.pageIndex = index
                    const url = appendAttributesToUrl(params)
                    history.push(url)
                    return params
                })
            }}
            queryParams={queryParams}
            {...props}
            cat={cat}
            child={child}
            parent={parent}
            history={history} />
    </Box>: <></>
}