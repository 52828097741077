import React, { useState, useEffect } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import { QueryOptions } from '../../helpers/query.options'
import { Order } from '../../models/order/Order'
import { orderService } from '../../models/order/service'
import OrderDetailsDialog from '../Dialogs/OrderDetailsDialog'
import { makeStyles } from '@mui/styles'
import { COLORS } from '../../constants/colors'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Title } from '../Typography/Title'
import { Table as SuperTable, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

export const MyOrdersComponent = (props: any) => {
    const { app, setConfirmation, setSnackBar, translations, language } = props
    const classes = useStyles();
    const [orders, setOrders] = useState<Order[]>([])
    const [order, setOrder] = useState<Order>()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [loadStatus, setLoadStatus] = useState(true)

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: COLORS.BG_BEIGE,
            color: 'black',
            alignItems: 'center',
            textAlign: 'center',
            fontWeight: 'bold'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        marginBottom: 2,
        '&:nth-of-type(odd)': {
            backgroundColor: 'white'
        },
        '&:nth-of-type(even)': {
            backgroundColor: COLORS.BG_BEIGE_LIGHT,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    useEffect(() => {
        loadOrders()
    }, [])

    const loadOrders = () => {
        const options = new QueryOptions()
        options.order = "desc"
        options.sort = "created"
        orderService.listWithCounter(options).then((resp: any) => {
            setOrders(resp.current)
            setLoadStatus(false)
        })
    }

    return <Box sx={{ width: '100%', alignItems: 'flex-start', display: 'flex', padding: 0, flexDirection: 'column', marginTop: -1 }}>
        <OrderDetailsDialog
            {...props}
            order={order}
            onOpen={dialogOpen} onCloseDialog={(resp) => {
                setDialogOpen(resp)
            }} />
        {loadStatus ? <div style={{ position: 'absolute', width: "100%", height: '100%', zIndex: 111111, marginTop: 10 }}>
            <CircularProgress style={{ position: 'fixed', top: '50%', left: window.innerWidth > 1200 ? ' 50%' : '45%', color: "black" }} />
        </div>
            : ''}
        <Box sx={{ paddingTop: 1, width: '100%', marginBottom: 1 }}>
            {orders?.length ? <TableContainer >
                {window.innerWidth > 640 ? <Table sx={{ minWidth: 640 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ borderTopLeftRadius: 20 }}>
                                {translations?.date}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                {translations?.order_id}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                {translations?.address}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                {translations?.total}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                {translations?.status}
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ borderTopRightRadius: 20 }}>

                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.map((order, index) => {
                            return <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row">
                                    {order.created}
                                </StyledTableCell>
                                <StyledTableCell align="right">{order.id}</StyledTableCell>
                                <StyledTableCell align="right">{order.address?.street}</StyledTableCell>
                                <StyledTableCell align="right">{order.totalToPay} ₾</StyledTableCell>
                                <StyledTableCell align="right">{translations[order.status]}</StyledTableCell>
                                <StyledTableCell align="right">
                                    <Button
                                        disableRipple={true}
                                        style={{ height: 30, backgroundColor: 'black', color: 'white', padding: 10, fontSize: 12 }}
                                        disableFocusRipple={false}
                                        onClick={() => {
                                            setOrder(order)
                                            setDialogOpen(true)
                                        }}
                                        color={"secondary"}
                                        type="submit">
                                        {translations?.details}
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        })}
                    </TableBody>
                </Table> : <SuperTable>
                    <Thead>
                        <Tr>
                            <Th >
                                {translations?.date}
                            </Th>
                            <Th >
                                {translations?.order_id}
                            </Th>
                            <Th >
                                {translations?.address}
                            </Th>
                            <Th >
                                {translations?.total}
                            </Th>
                            <Th >
                                {translations?.status}
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody style={{
                       width: '100%'
                        }}>
                        {orders.map((order, index) => {
                            return <Tr key={index} 
                            style={{
                                borderColor: COLORS.BG_BEIGE_GRAY,
                                borderRadius: 3,
                                marginBottom: 12,
                                padding: '15px 10px 15px 10px'
                                }}>
                                <Td component="th" scope="row" style={{marginBottom: 5}}>
                                    {order.created}
                                </Td>
                                <Td style={{maxWidth: '43%', marginBottom: 4}}>{order.id}</Td>
                                <Td style={{marginBottom: 5}}>{order.address.street}</Td>
                                <Td style={{marginBottom: 5}}>{order.totalToPay} ₾</Td>
                                <Td style={{marginBottom: 10}}>{translations[order.status]}</Td>
                                    <Button
                                        disableRipple={true}
                                        style={{ height: 32, backgroundColor: 'black', color: 'white', width: '90%', padding: 10, fontSize: 14 }}
                                        disableFocusRipple={false}
                                        onClick={() => {
                                            setOrder(order)
                                            setDialogOpen(true)
                                        }}
                                        type="submit">
                                        {translations?.details}
                                    </Button>
                            </Tr>
                        })}
                    </Tbody>
                </SuperTable>
                }
            </TableContainer>
                : <Title
                    style={{ marginTop: 20 }}
                    uppercase={true}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'#333'}
                    fontSize={17}
                    fontWeight={'500'}
                    text={loadStatus ? translations?.loading : translations?.you_dont_have_orders_yet} />}
        </Box>
    </Box>
}



const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    td: {
        textAlign: 'center',
        fontSize: 14,
        padding: 5

    },
    th: {
        fontSize: 15
    },
    paper: {
        textAlign: "center",
    },
    leftcolumn: {
        padding: 10
    }
})
