import { Button } from '@mui/material';
import React, {} from 'react';
import { COLORS } from '../../constants/colors';


export const BlackButton = (props) => {
    const {onClick, title, 
        loading=false,
        height=50,
        color=COLORS.BG_DARK,
        style={width: '100%'},
        type="button"} = props
    return <Button
    sx={[{
        '&:hover': {
            color: 'white',
            backgroundColor: !loading  ? color: "gray",
        },
    }, {
        width: style?.width,
        fontWeight: 'bold',
        borderRadius: 2,
        height,
        maxWidth: 400,
        backgroundColor: !loading ? color: "gray",
        color: 'white',
    }]}
    disableRipple={true}
    disableFocusRipple={false}
    color={"secondary"}
    type={type}
    onClick={onClick}
>
    {title}
</Button>
}