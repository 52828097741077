import { ResourceProvider } from "../../providers/resource.provider"
import { Address } from "./address"
import { AddressSerializer } from "./serializer"

export class AddressProvider extends ResourceProvider<Address> {
    constructor() {
        super("rest_address", new AddressSerializer())
    }
}

export const addressService = new AddressProvider()

