

import { environment } from "../../environment/environment"
import { brandSerializer } from "../brand/serialize"
import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { ProductLine } from "./ProductLine"


export class ProductLineSerializer implements Serializer {
    fromJson(json: any) {
        const item = new ProductLine()
        item.image = `${environment.basePath}/assets/product_line/${json.filename}.jpg`
        item.id = json.id
        item.text = json.text
        item.title = json.title
        item.brand = brandSerializer.fromJson(json.brand)
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}


export const productLineSerializer = new ProductLineSerializer()