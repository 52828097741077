import React, { useState } from "react"
import { GiftCardSummaryDetails } from "./GiftCardSummaryDetails"
import { GiftCardSummaryDetailsEditMode } from "./GiftCardSummaryDetailsEditMode"

export const GiftCardCheckoutSummaryComponent = (props) => {
    const { language, translations } = props
    const [mode, setMode] = useState("view")
    const [details, setDetails] = useState<any>()

    return (
        <>
            {mode === "view" ? (
                <GiftCardSummaryDetails
                    {...props}
                    mode={mode}
                    setMode={setMode}
                    details={details}
                    setDetails={setDetails}
                />
            ) : (
                <GiftCardSummaryDetailsEditMode
                    {...props}
                    setMode={setMode}
                    details={details}
                    setDetails={setDetails}
                />
            )}
        </>
    )
}
