import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { authenticationReducer } from '../models/user/authReducer'
import { applicationReducer } from '../models/application/applicationReducer'
import { languageReducer } from "../models/language/languageReducer";
import { translationsReducer } from "../models/translation/translationReducer";

const rootReducer = combineReducers({ 
    auth: authenticationReducer,
    app: applicationReducer,
    language: languageReducer,
    translations: translationsReducer
})

export const store = createStore(rootReducer, applyMiddleware(thunk));
