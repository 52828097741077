import { Badge, Box, Grid } from '@mui/material';
import React from 'react';

export const PopularBrandsContentMobile = (props) => {
  const { brands, history } = props
  return <Box sx={{
    padding: 2,
    margin: 2,
    alignItems: 'center',
    marginTop: 0,
    flexWrap: 'wrap',
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  }}>
    {brands?.filter((item, index) => index < 10).map((brand, index) => {
      return <Box
        key={index}
        style={{
          display: 'flex',
          flexGrow: 1,
          minWidth: window.innerWidth / 2 - 30,
          width: window.innerWidth / 2 - 30,
          borderWidth: 1,
          padding: 5,
          borderRadius: 3,
          cursor: 'pointer'
        }}>
        <div className="brand-item" style={{ width: '100%', position: 'relative' }}>
          {brand.isNew === true && <Box style={{
            position: 'absolute', right: 20, top: 3, borderRadius: 10, zIndex: 1, backgroundColor: 'red'
          }}>
            <Badge color="error" badgeContent={'new'} invisible={false} style={{ zIndex: 1000 }} />
          </Box>}
          <h4 onClick={() => { history.push(`brand/${brand.id}`) }}>
            {brand.title}
          </h4>
        </div>
      </Box>
    })}
  </Box>


}