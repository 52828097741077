import React, { useState, useEffect } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Title } from '../Typography/Title';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';
import { Box, Fade } from '@mui/material';
import { useHistory } from 'react-router';
//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";


export const BrandProductLineCarousel = (props: any) => {
    const { slides, items, language } = props
    const [loaded, setLoaded] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (slides?.length) {
            const [first] = slides
            const img = new Image()
            img.src = first.image
            img.onload = () => {
                setLoaded(true)
            }
        }
    }, [slides])


    return <Fade
        in={loaded}
        style={{ transformOrigin: '0 0 0' }}
        {...(loaded ? { timeout: 1000 } : {})}
    ><div style={{
        position: 'relative',
        marginTop: 10,
        paddingTop: 20,
        height: 'auto',
        minHeight: 250,
        padding: window.innerWidth > 1200 ? 10 : 0,
        width: window.innerWidth,
        textAlign: 'center',
        border: '1px solid white',
        borderLeftColor: 'white',
        borderRightColor: 'white'
    }}>
            <Swiper
                slidesPerView={1}
                breakpoints={{
                    440: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                }}
                spaceBetween={0}
                loop={false}
                pagination={true}
                modules={[Pagination]}
                className="mySwiper">
                {slides.map((item, index) => {
                    return <SwiperSlide>
                        <Box
                            onClick={() => {
                                history.push(`/product_line/${item.id}`)
                            }}
                            key={index}
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}

                        ><Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Box style={{ marginTop: 0, alignItems: 'flex-start', display: 'flex', flexDirection: 'row', width: '90%', padding: 10 }}>
                                    <img src={item.image} style={{ width: '100%' }} />
                                </Box>
                                <Box style={{ marginTop: 14, marginBottom: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', width: '90%', justifyContent: 'space-around', height: 60 }}>
                                    <Title text={`${item.title}`} fontSize={14} uppercase={true} fontWeight='700' color={"black"} textAlign="center" />
                                    <Title text={`${item.text}`} fontSize={13} uppercase={false} fontWeight='400' color={"black"} textAlign="center" />
                                </Box>
                            </Box>
                        </Box>
                    </SwiperSlide>
                })}
            </Swiper>
        </div>
    </Fade>
}