


import { ResourceProvider } from "../../providers/resource.provider"
import { Brand } from "../brand/brands"
import { BrandSerializer } from "../brand/serialize"
import { MainPageNewProductSection, MainPageProductBanner, MainPageProductLineSection, MainPageProductSectionWithBG, MainPageProductSlider, MainPageProductSliderWithBanner, MainPageSlide } from "./MainPage"
import { MainPageNewProductSectionSerializer, MainPageProductBannerSerializer, MainPageProductLineSectionSerializer, MainPageProductSectionWithBGSerializer, MainPageProductSliderSerializer, MainPageProductSliderWithBannerSerializer, MainPageSlideSerializer } from "./serializer"


export class BrandSliderProvider extends ResourceProvider<Brand> {
    constructor() {
        super("rest_brand_slider", new BrandSerializer())
    }
}
export class MainPageNewProductSectionProvider extends ResourceProvider<MainPageNewProductSection> {
    constructor() {
        super("rest_new_product_section", new MainPageNewProductSectionSerializer())
    }
}
export class MainPageProductSliderProvider extends ResourceProvider<MainPageProductSlider> {
    constructor() {
        super("rest_product_slider", new MainPageProductSliderSerializer())
    }
}
export class MainPageSliderProvider extends ResourceProvider<MainPageSlide> {
    constructor() {
        super("rest_main_page_slide", new MainPageSlideSerializer())
    }
}
export class MainPageProductSliderWithBannerProvider extends ResourceProvider<MainPageProductSliderWithBanner> {
    constructor() {
        super("rest_product_slider_with_banner", new MainPageProductSliderWithBannerSerializer())
    }
}
export class MainPageProductLineSectionProvider extends ResourceProvider<MainPageProductLineSection> {
    constructor() {
        super("rest_main_page_product_line_section", new MainPageProductLineSectionSerializer())
    }
}
export class MainPageProductSectionWithBGProvider extends ResourceProvider<MainPageProductSectionWithBG> {
    constructor() {
        super("rest_product_section_with_bg", new MainPageProductSectionWithBGSerializer())
    }
}
export class MainPageProductBannerProvider extends ResourceProvider<MainPageProductBanner> {
    constructor() {
        super("rest_product_banner", new MainPageProductBannerSerializer())
    }
}

export const brandSliderService = new BrandSliderProvider()
export const mainPageNewProductSectionService = new MainPageNewProductSectionProvider()
export const mainPageProductSliderService = new MainPageProductSliderProvider()
export const mainPageSliderService = new MainPageSliderProvider()
export const mainPageProductLineSectionService = new MainPageProductLineSectionProvider()
export const mainPageProductSectionWithBGService = new MainPageProductSectionWithBGProvider()
export const mainPageProductSliderWithBannerService = new MainPageProductSliderWithBannerProvider()
export const mainPageProductBannerService = new MainPageProductBannerProvider()





