
import React, { useState, useEffect } from "react";
import '../../styles.scss'
import { Box, Button, ClickAwayListener } from "@mui/material";
import '../../../App.css'
import { COLORS } from "../../../constants/colors";
import { Title } from "../../Typography/Title";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Animated } from "react-animated-css";
import { intToArray } from "../../../helpers/utilities";

export const AddToCartButton = (props) => {
    const { simple, 
        available=true,
        onOpenAvailability=()=>{},
        setSnackBar, item, app, auth, translations, setAnimate, language, price, addToCart, setCart, onAddedToCart, onSetSimple, setPrice } = props
    const [qtyOpen, setQtyOpen] = useState(false)
    const [qty, setQty] = useState(1)

    useEffect(() => {
        setQtyOpen(false)
    }, [qty])

    useEffect(() => {
        if (simple) {
            setPrice(simple.price)
            setQty(1)
            onSetSimple(simple)
        }
    }, [simple])


    const getRange = (simple) => {
        if (!simple) {
            return [1, 2, 3]
        }
        if (simple?.stock > 5) {
            return [1, 2, 3, 4, 5]
        } else {
            if (simple.stock === 0) {
                return []
            }
            if (simple?.stock === 1) {
                return [1]
            } else {
                return intToArray(simple?.stock - 1)
            }
        }
    }

    return <ClickAwayListener onClickAway={() => {
        setQtyOpen(false)
    }}><Button
        sx={{
            color: 'white',
            marginRight: 1,
            backgroundColor: available ? COLORS.BG_DARK: COLORS.BG_GRAY_I,
            '&:hover': {
                color: 'white',
                backgroundColor: available ? COLORS.BG_DARK: COLORS.BG_GRAY_I,
            },
            width: '100%',
            height: 40,
            borderRadius: 10,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between'
        }}
        disableRipple={true}
        disableFocusRipple={false}
        color={"secondary"}

    >
            <Box sx={{ width: '20%', position: 'relative' }} onClick={() => {
                if (simple && simple.stock > 1) {
                    setQtyOpen(true)
                }
            }}>
                { <Box sx={{ maxWidth: 20, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: .6 }} >
                    <Title text={qty}
                        fontSize={17} 
                        uppercase={true}
                        className="roboto"
                        color={"white"}
                        style={{ color: 'white',marginLeft: simple && simple.stock > 1 ? 0: 10 }}
                        fontWeight='bold'
                        fontFamily='Roboto'
                        onClick={() => { }} />
                    {simple && simple.stock > 1 && <KeyboardArrowDownIcon />}
                </Box>}


                <Animated animationIn="zoomIn" animationOut="zoomOut"
                    style={{
                        backgroundColor: '#333',
                        position: 'absolute',
                        top: -25 * getRange(simple).length / 2 + 15,
                        left: 4,
                        width: 30,
                        height: 25 * getRange(simple).length,
                        borderRadius: 1,
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center', justifyContent: 'center'
                    }}
                    animationInDuration={400}
                    isVisible={qtyOpen}>


                    <Box >
                        {simple && getRange(simple).map((item, index) => {
                            return <Title
                                key={index}
                                text={item}
                                fontSize={14}
                                uppercase={true}
                                className="roboto"
                                color={"white"}
                                style={{ color: 'white', padding: 4 }}
                                fontWeight='bold'
                                fontFamily='Roboto'
                                onClick={() => {
                                    setQty(i => {
                                        if (i === item) {
                                            setQtyOpen(false)
                                            return item
                                        } else {
                                            return item
                                        }
                                    })
                                }} />
                        })}
                    </Box>

                </Animated>


            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%', borderLeftColor: 'white', borderLeft: '1px solid white' }}
                onClick={() => {
                    if (!available) {
                        onOpenAvailability()
                        return;
                    }
                    if (simple) {
                        addToCart(item, simple.sku, simple, app, auth, qty).then((r: any) => {
                            setCart(r)
                            localStorage.setItem("cart", JSON.stringify(r))
                            setSnackBar({ ...app.snackBar, open: true, text: `${item?.name} ${translations?.has_been_added_to_cart}`, bColor: "rgb(83, 148, 96)" })
                            setTimeout(() => {
                                onAddedToCart()
                            }, 200)
                        })
                    } else {
                        setAnimate(true)
                        setTimeout(() => {
                            setAnimate(false)
                        }, 2000)
                    }
                }}
            >
                <Title
                    uppercase={true}
                    fontWeight={'500'}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'white'}
                    fontSize={13}
                    text={translations?.add_to_cart} />

            </Box>
        </Button>
    </ClickAwayListener>
}