import { Serializer } from '../serializer'
import { UserComment } from './UserComment'



export class UserCommentSerializer implements Serializer {
    fromJson(json: any) {
        const item = new UserComment()
        item.text = json.text
        item.type = json.type
        return item
    }

    toJson(item: UserComment) {
        const obj: any = {
            text: item.text,
            type: item.type
        }
        
        return obj
    }
}


export const userCommentSerializer = new UserCommentSerializer()