import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from 'react-router';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';
import { Title } from '../Typography/Title';
import { MainPageSliderItem } from './MainPageSlider';



export const MultiCarouselComponent = (props: any) => {
    const { items } = props
    const history = useHistory()
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false })
    let carousel: any

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
    const CustomDot: any = ({ onClick, ...rest }) => {
        const {
            onMove,
            index,
            active,
            carouselState: { currentSlide, deviceType }
        } = rest;
        const carouselItems = items;

        return (
            <button
                className={active ? "active" : "inactive"}
                onClick={() => onClick()}
            >
                {React.Children.toArray(carouselItems)[index]}
            </button>
        );
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest
        return <button />

    }

    return <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', right: -30, zIndex: 100, top: '40%', cursor: 'pointer' }} onClick={() => {
            carousel.next()
        }}>
            <ChevronRight width={30} height={30} />

        </div>
        <div style={{ position: 'absolute', left: -30, zIndex: 100, top: '40%', cursor: 'pointer' }} onClick={() => {
            carousel.previous()
        }}>
            <ChevronLeft width={30} height={30} />

        </div>
        <Carousel
            ref={(el) => (carousel = el)}
            customRightArrow={<CustomRightArrow onClick={() => {
                carousel.next()
            }} />}
            customLeftArrow={<CustomRightArrow onClick={() => {
                carousel.next()
            }} />}
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={100000000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}

        >
            {items.map((item, index) => {

                return item.type === 'small' ? <div
                    key={index}
                    style={{ width: 1200, height: 400, display: 'flex', flexDirection: 'row' }}>
                    {item.embeddedItems.map((i, index) => {
                        return <MainPageSliderItem item={i} />
                    })}
                </div> :
                    <div style={{ width: '96%', margin: '0px auto', padding: 20, position: 'relative' }}
                        key={index}
                        onClick={() => {
                            history.push(item.url)
                        }}
                    >
                        <div style={{
                            position: 'absolute',
                            top: `${item.textPosition.titlePositionY}%`,
                            left: `${item.textPosition.titlePositionX}%`,
                        }}>
                            <Title text={`${item.title}`} fontSize={item.textPosition.titleSize} uppercase={true} fontWeight='700' color={"black"} />

                        </div>
                        <div style={{
                            position: 'absolute',
                            top: `${item.textPosition.subtitlePositionY}%`,
                            left: `${item.textPosition.subtitlePositionX}%`,
                        }}>
                            <Title text={`${item.subtitle}`} fontSize={item.textPosition.subtitleSize} uppercase={true} fontWeight='700' color={"black"} />

                        </div>
                        {<img src={item.image} style={{ width: '100%' }} />}
                    </div>

            })}
        </Carousel>

    </div>

}
