



import { ResourceProvider } from "../../providers/resource.provider"
import { NameIdItem, PasswordRestore } from "../common/common"
import { NameIdItemSerializer, PasswordRestoreSerializer } from "../common/serializer"




export class ForgotPasswordProvider extends ResourceProvider<NameIdItem> {
    constructor() {
        super("rest_password_restore_token", new NameIdItemSerializer())
    }
}
export class PasswordRestoreProvider extends ResourceProvider<PasswordRestore> {
    constructor() {
        super("rest_password_restore", new PasswordRestoreSerializer())
    }
}

export const forgotPasswordService = new ForgotPasswordProvider()
export const passwordRestoreService = new PasswordRestoreProvider()

