import React from "react"
import {
    Switch,
    useRouteMatch
} from "react-router-dom"
import GuardedRoute from "./GuardedRoute"
import { connect } from 'react-redux'
import MainPage from "./pages/MainPage"
import BrandsPage from "./pages/brand/BrandsPage"
import LoginPage from "./pages/LoginPage"
import RegisterPage from "./pages/RegisterPage"
import ProductPage from "./pages/product/ProductPage"
import { MyCart } from "./pages/Cart"
import CheckoutPage  from "./pages/CheckoutPage"
import MyPagesPage  from "./pages/MyPages/MyPagesPage"
import SearchPage  from "./pages/SearchPage"
import FavoritesPage from "./pages/FavoritesPage"
import InfoPages from "./pages/InfoPages/InfoPages"
import CatalogPage from "./pages/CatalogPage"
import ProductLinePage from "./pages/ProductLinePage"
import CatalogMainPage from "./pages/Catalog/CatalogMainPage"
import MobileProductPage from "./pages/product/MobileProductPage"
import AllBrandsPage from "./pages/brand/AllBrandsPage/AllBrandsPage"
import GiftCardPage from "./pages/GiftCardPage"
import BrandPageWithFilter from "./pages/brand/BrandPageWithFilter"
import PaymentCallbackPage from "./pages/PaymentCallbackPage"
import ContactPage from "./pages/ContactPage"
import AboutUsPage from "./pages/AboutUsPage"
import UserProfilePage from "./pages/UserProfilePage"
import PrivacyPolicyPage from "./pages/InfoPages/PrivacyPolicyPage"
import TermsAndRulesPage from "./pages/InfoPages/TermsAndRulesPage"
import HelpPage from "./pages/HelpPage"
import ProductPromoPage from "./pages/product/ProductPromoPage"
import MobileAboutUsPage from "./pages/InfoPages/MobileAboutUsPage"
import MobileAccountPage from "./pages/InfoPages/MobileAccountPage"
import MobileMyProfilePage from "./pages/InfoPages/MobileMyProfilePage"
import MobileHelpPage from "./pages/InfoPages/MobileHelpPage"
import GiftCardCheckoutPage from "./pages/GiftCardCheckoutPage"



export const canPerformAction = (user, permission, action): boolean => {
    if (user && user.roles) {
        const [model] = user?.roles?.filter(r => r.name === permission)
        if (model) {
            if (model.permissions[action]) {
                return true
            }
        }
    }
    return false
}

export const canAccessPage = (user, roles): boolean => {
    if (user && user.roles) {
        const [model] = user?.roles?.filter(r => roles.indexOf(r.name) > -1)
        if (model) {
            if (model.permissions.read) {
                return true
            } else {
                return false
            }
        }
        else {
            return false
        }
    }
    return false
}

const Routing = (props) => {
    let match = useRouteMatch()
    const { user, loggedIn, app} = props
    
    return app?.loaded ? <Switch>
        <GuardedRoute auth={true} component={MainPage} path="/" exact access={true}/>
        
        <GuardedRoute auth={true} component={MyCart} path="/cart" exact access={true}/>
        <GuardedRoute auth={true} component={LoginPage} path="/login" exact access={true}/>
        <GuardedRoute auth={true} component={RegisterPage} path="/register" exact access={true}/>
        <GuardedRoute auth={loggedIn} component={MyPagesPage} path="/account/:page" exact access={true}/>
        <GuardedRoute auth={true} component={InfoPages} path="/info_pages/:page" exact access={true}/>
        <GuardedRoute auth={true} component={AllBrandsPage} path="/brands" exact access={true}/>
        <GuardedRoute auth={true} component={ContactPage} path="/contact" exact access={true}/>
        <GuardedRoute auth={true} component={BrandsPage} path="/brands/:tag" exact access={true}/>
        <GuardedRoute auth={true} component={BrandPageWithFilter} path="/brands/:tag/:id" exact access={true}/>
        <GuardedRoute auth={loggedIn} component={CheckoutPage} path="/checkout" exact access={true}/>
        <GuardedRoute auth={true} component={BrandPageWithFilter} path="/brand/:id" exact access={true}/>
        <GuardedRoute auth={true} component={window.innerWidth > 1200 ? ProductPage: MobileProductPage} path="/product/:id" exact access={true}/>
        <GuardedRoute auth={true} component={SearchPage} path="/search" exact access={true}/>
        <GuardedRoute auth={true} component={FavoritesPage} path="/favorites" exact access={true}/>
        <GuardedRoute auth={true} component={ProductLinePage} path="/product_line/:id" exact access={true}/>
        {/* <GuardedRoute auth={true} component={CatalogPage} path="/catalog_/:cat/:parent/:child" exact access={true}/>
        <GuardedRoute auth={true} component={CatalogPage} path="/catalog_/:cat/:parent" exact access={true}/>
        <GuardedRoute auth={true} component={CatalogPage} path="/catalog_/:cat" exact access={true}/> */}
        <GuardedRoute auth={true} component={CatalogMainPage} path="/catalog/:cat/:parent/:child" exact access={true}/>
        <GuardedRoute auth={true} component={CatalogMainPage} path="/catalog/:cat/:parent" exact access={true}/>
        <GuardedRoute auth={true} component={CatalogMainPage} path="/catalog/:cat" exact access={true}/>
        <GuardedRoute auth={true} component={GiftCardPage} path="/gift_card" exact access={true}/>
        <GuardedRoute auth={true} component={PaymentCallbackPage} path="/payment_callback" exact access={true}/>
        <GuardedRoute auth={true} component={AboutUsPage} path="/about_us/:page" exact access={true}/>
        <GuardedRoute auth={true} component={UserProfilePage} path="/my_profile/:page" exact access={true}/>
        <GuardedRoute auth={true} component={PrivacyPolicyPage} path="/privacy_policy" exact access={true}/>
        <GuardedRoute auth={true} component={TermsAndRulesPage} path="/terms_and_rules" exact access={true}/>
        <GuardedRoute auth={true} component={HelpPage} path="/help/:page" exact access={true}/>
        <GuardedRoute auth={true} component={ProductPromoPage} path="/product_details/:id" exact access={true}/>
        <GuardedRoute auth={true} component={MobileAboutUsPage} path="/about_us" exact access={true}/>
        <GuardedRoute auth={true} component={MobileAccountPage} path="/account" exact access={true}/>
        <GuardedRoute auth={true} component={MobileMyProfilePage} path="/my_profile" exact access={true}/>
        <GuardedRoute auth={true} component={MobileHelpPage} path="/help" exact access={true}/>
        <GuardedRoute auth={loggedIn} component={GiftCardCheckoutPage} path="/giftcard_checkout" exact access={true}/>
    </Switch>: <></>
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user,
        loggedIn: state?.auth?.loggedIn, 
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps)(Routing)