import { useLocation } from "react-router";
import React, { useEffect, useRef, useState } from "react"

import { QueryOptions } from "./query.options";
import { cartService, giftCardCartItemService } from "../models/cart/service";
import { Cart, CartItem } from "../models/cart/cart";
import { User } from "../models/user/user";
import { FavoriteItem, Favorites } from "../models/favorite/favorite";
import { favoritesService } from "../models/favorite/service";
import { environment } from "../environment/environment";
import { attributeService } from "../models/attribute/service";
import { productService } from "../models/product/service";
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TransitionProps } from "@mui/material/transitions";
import { Slide, styled } from "@mui/material";
import { Brand } from "../models/brand/brands";

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

export const addToCart = (product, sku, simple, app, auth, qty = 1) => new Promise((resolve, reject) => {
  const { cart } = app
  let obj = []
  const cartItem: CartItem = new CartItem()
  cartItem.product = product
  cartItem.sku = sku
  cartItem.price = simple.price
  cartItem.specialPrice = simple.specialPrice
  cartItem.created = new Date().getTime()
  const [found] = cart.filter(e => e.sku === sku)
  if (found) {
    const quantity = qty
    const existingCartItems = cart.filter(e => e.sku !== sku)
    cartItem.quantity = quantity
    obj = [...existingCartItems, {
      product: cartItem.product, sku: cartItem.sku,
      specialPrice: cartItem.specialPrice,
      quantity: cartItem.quantity,
      created: cartItem.created,
      price: cartItem.price
    }]
  } else {
    cartItem.quantity = qty
    obj = [...cart, {
      product: cartItem.product,
      specialPrice: cartItem.specialPrice,
      sku: cartItem.sku,
      quantity: cartItem.quantity,
      created: cartItem.created,
      price: cartItem.price
    }]
  }
  if (auth.loggedIn) {
    cartService.create(cartItem).then((r: any) => {
      resolve(obj)
    })
  } else {
    resolve(obj)
  }
})

export const addToCartGiftCart = (product, sku, price, app, auth, qty = 1, cardOrderId = "") => new Promise((resolve, reject) => {
  const { cart } = app
  let obj = []
  const cartItem: CartItem = new CartItem()
  cartItem.product = product
  cartItem.sku = sku
  cartItem.cardOrderId = cardOrderId
  cartItem.price = price
  cartItem.specialPrice = price
  cartItem.created = new Date().getTime()
  const [found] = cart.filter(e => e.sku === sku)
  if (found) {
    const quantity = qty
    const existingCartItems = cart.filter(e => e.sku !== sku)
    cartItem.quantity = quantity
    obj = [...existingCartItems, {
      specialPrice: cartItem.specialPrice,
      product: cartItem.product, sku: cartItem.sku, quantity: cartItem.quantity, creaetd: cartItem.created, price: cartItem.price
    }]
  } else {
    cartItem.quantity = qty
    obj = [...cart, {
      specialPrice: cartItem.specialPrice,
      product: cartItem.product, sku: cartItem.sku, quantity: cartItem.quantity, created: cartItem.created, price: cartItem.price
    }]
  }
  if (auth.loggedIn) {
    giftCardCartItemService.create(cartItem).then((r: any) => {
      resolve(obj)
    })
  } else {
    resolve(obj)
  }
})

const otherParams = [
  { value: "pageSize", type: "number", default: 24 },
  { value: "pageIndex", type: "number", default: 1 },
  { value: "min_price", type: "number", default: 0.1 },
  { value: "max_price", type: "number", default: 1000 },
  { value: "sort", type: "string", default: localStorage.getItem("sort") ? JSON.parse(localStorage.getItem("sort"))["sort"] : "uploaded" },
  { value: "order", type: "string", default: localStorage.getItem("sort") ? JSON.parse(localStorage.getItem("sort"))["order"] : "desc" },
  { value: "price", type: "string", default: "0,100,200,300,400,500,600" },
  { value: "search", type: "string", default: "" },
  { value: "created_from", type: Date, default: null },
  { value: "created_to", type: Date, default: null },
  { value: "init", type: Boolean, default: false },
];

export const addToFavorites = (product, sku, app, auth) => new Promise((resolve, reject) => {
  const { favorites } = app
  let obj = []
  const favoriteItem: FavoriteItem = new FavoriteItem()
  favoriteItem.product = product
  favoriteItem.sku = sku
  const [found] = favorites.filter(e => e.sku === sku)
  if (found) {
    const existingFavoriteItems = favorites.filter(e => e.sku !== sku)
    obj = [...existingFavoriteItems, favoriteItem]
  } else {
    obj = [...favorites, favoriteItem]
  }
  if (auth.loggedIn) {
    favoritesService.create(favoriteItem).then((r: any) => {
    
      resolve(obj)
    })
  } else {
    resolve(obj)
  }

})
export const removeCartItem = (sku, app, setCart) => {
  const { cart } = app
  const localCart = new Cart()
  localCart.updateLocal([...cart.filter(item => item.sku !== sku)])
  setCart([...cart.filter(item => item.sku !== sku)])
}
export const removeFavoriteItem = (sku, app, setFavorites) => {
  const { favorites } = app
  const localFavorites = new Favorites()
  localFavorites.updateLocal([...favorites.filter(item => item.sku !== sku)])
  setFavorites([...favorites.filter(item => item.sku !== sku)])
}


export const getCartTotal = (items: CartItem[]) => {
  return items?.reduce((total, item) => {
    return total += item.specialPrice * item.quantity;
  }, 0);

}
export const cartIncludesGiftCart = (items: CartItem[]) => {

  return items.filter(item => ["e_gift_card_001", "gift_card_001"].indexOf(item.sku) > -1).length 

}
export const getCartTotalWithStock = (items: CartItem[]) => {

  return items.reduce((total, item) => {
    return total += item.stock ? item.specialPrice * item.quantity : 0;
  }, 0);

}
export const getCartCount = (items: CartItem[]) => {
  return items.reduce((total, item) => {
    return total += item.quantity;
  }, 0);

}


export const loadCart = (setCart, language) => {
  const options = new QueryOptions()
  options.lang = language
  cartService.list(options).then((r: any) => {
    setCart(r.results)
    const cart = new Cart()
    cart.items = r.results
    cart.save()
  })
}
export const loadFavorites = (setFavorites) => {
  const options = new QueryOptions()
  favoritesService.list(options).then((r: any) => {
    setFavorites(r.results)
    const favs = new Favorites()
    favs.items = r.results
    favs.save()
  })
}


export const initUserCartAndFavorites = (setCart, setFavorites, login) => {
  const user = new User()
  user.load().then((currentUser) => {
    const cart = new Cart()
    const favs = new Favorites()
    if (currentUser.accessToken) {
      login(currentUser)
      favs.loadFromServer(setFavorites)
      favs.loadFromStorage(setFavorites)
      cart.loadFromServer(setCart)
      cart.loadFromStorage(setCart)
    } else {
      favs.loadFromStorage(setFavorites)
      cart.loadFromStorage(setCart)
    }
  })
}
export const initCartAndFavs = (app, auth, setCart, setFavorites) => {
  const cart = new Cart()
  const favs = new Favorites()
  cart.updateServerWithItems(app, auth, setCart)
  favs.updateServerWithItems(app, auth, setFavorites)
}

export const generateParamsFromUrl = (search: any) => {
  const query = new URLSearchParams(search);
  const options: any = new QueryOptions();
  environment.params.forEach((param: string) => {
    options[param] = query.get(param);
  });

  otherParams.forEach((param: any) => {
    const paramValue = query.get(param.value)
      ? query.get(param.value)
      : null;
    if (paramValue) {
      options[param.value] =
        param.type === "string" ? String(paramValue) : param.type === Date ? new Date(paramValue) : Number(paramValue);
    }
  });
  return options;
};
export const generateParamsFromUrlWithoutPrice = (search: any) => {
  const query = new URLSearchParams(search);
  const options: any = new QueryOptions();
  environment.params.forEach((param: string) => {
    options[param] = query.get(param);
  });

  otherParams.filter(f => f.value !== 'price').forEach((param: any) => {
    const paramValue = query.get(param.value)
      ? query.get(param.value)
      : param.default;
    if (paramValue) {
      options[param.value] =
        param.type === "string" ? String(paramValue) : param.type === Date ? new Date(paramValue) : Number(paramValue);
    }
  });
  return options;
};
export const generateParamsFromUrlBySet = (search: any, params) => {
  const query = new URLSearchParams(search);
  const options: any = new QueryOptions();
  params.forEach((param: string) => {
    options[param] = query.get(param);
  });

  otherParams.forEach((param: any) => {
    const paramValue = query.get(param.value)
      ? query.get(param.value)
      : param.default;
    if (paramValue) {
      options[param.value] =
        param.type === "string" ? String(paramValue) : param.type === Date ? new Date(paramValue) : Number(paramValue);
    }
  });
  return options;
};
export const generateFiltersFromUrl = async (location: any, language) => {
  const query = new URLSearchParams(location.search);
  const options: any = new QueryOptions();
  options.lang = language
  const list: any = []
  environment.params.forEach((param: string) => {
    if (query.get(param)) {
      const attrs = query.get(param).split(",")
      attrs.forEach((a) => {
        list.push(a)
      })
    }
  });

  if (list.length) {
    options.list = list.toString()
    return await attributeService.listWithCounter(options)
  } else {
    return await null
  }
};

export const generateUrl = (
  params: any,
  selectedFilters: any[],
  pageString: string,
  pageIndex = 1,
  pageSize = 5,
  sort = "",
  order = "",
  search = "",
  min_price = 0,
  max_price = 10000,
  category

) => {
  if (selectedFilters.length) {
    let url = "";
    params.forEach((param: string) => {
      const items = selectedFilters
        .filter((e) => e.name.id === param)
        .map((item) => item.id);
      if (items.length) {
        const prefix = url.length > 3 ? `${url}&` : `${url}`;
        url = `${prefix}${param}=${items.toString()}`;
      }
    });
    url = `${url}&pageIndex=${pageIndex === 0 ? 1 : pageIndex}&pageSize=${pageSize}&min_price=${min_price}&max_price=${max_price}&sort=${sort}&order=${order}&${search.length ? `search=${search}&` : ""}${category.length ? `category=${category}&` : ""}`;
    return `${pageString}?${url}`;
  } else {
    let url = "";
    const prefix = url.length > 3 ? `${url}&` : `${url}`;
    url = `${prefix}${url}pageIndex=${pageIndex === 0 ? 1 : pageIndex}&pageSize=${pageSize}&min_price=${min_price}&max_price=${max_price}&sort=${sort}&order=${order}&${search.length ? `search=${search}&` : ""}${category.length ? `category=${category}&` : ""}`;
    return `${pageString}?${url}`;
  }
};
export const generateUrlForBrand = (
  options: any,
  params: any,
  selectedFilters: any[],
  pageString: string,
  pageIndex = 1,
  pageSize = 5,
  sort = "",
  order = "",
  search = "",
  min_price = 0,
  max_price = 10000,
  category

) => {
  if (selectedFilters.length) {
    let url = "";
    params.forEach((param: string) => {
      const items = selectedFilters
        .filter((e) => e.name.id === param)
        .map((item) => item.id);
      if (items.length) {
        const prefix = url.length > 3 ? `${url}&` : `${url}`;
        url = `${prefix}${param}=${items.toString()}`;
      }
    });
    url = `${url}&pageIndex=${pageIndex === 0 ? 1 : pageIndex}&pageSize=${pageSize}&min_price=${min_price}&max_price=${max_price}&sort=${sort}&order=${order}&${search.length ? `search=${search}&` : ""}${category.length ? `category=${category}&` : ""}`;
    if (options.brand && url.indexOf("brand=") < 0) {
      url = `brand=${options.brand}&${url}`
    }
    return `${pageString}?${url}`;
  } else {
    let url = "";
    const prefix = url.length > 3 ? `${url}&` : `${url}`;
    url = `${prefix}${url}pageIndex=${pageIndex === 0 ? 1 : pageIndex}&pageSize=${pageSize}&min_price=${min_price}&max_price=${max_price}&sort=${sort}&order=${order}&${search.length ? `search=${search}&` : ""}${category.length ? `category=${category}&` : ""}`;
    if (options.brand && url.indexOf("brand=") < 0) {
      url = `brand=${options.brand}&${url}`
    }
    return `${pageString}?${url}`;
  }
};

export const getToken = async () => {
  const user: User | null = await JSON.parse(
    localStorage.getItem("currentUser")
      ? localStorage.getItem("currentUser")
      : "{}"
  );
  if (!user?.accessToken) {
    return "app";
  }
  return user.accessToken;
}

export const loadProducts = async (options: QueryOptions) => {
  return await productService.listWithCounter(options as QueryOptions)

}

export const onPriceMove$: any = new Subject().pipe(
  debounceTime(1000)
)
export const onDimensionsChange$: any = new Subject().pipe(
  debounceTime(300)
)

//scroll
export const isBottom = (el) => {
  return el ? el.getBoundingClientRect().bottom <= window.innerHeight + 200 : false
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2)
  },
}));

export const isFromGroup = (type, attributes) => {
  const [filtered] = attributes.filter(i => i.id === type)
  return filtered ? true : false
}

export const showAbout = (brand: Brand) => {
  if (brand.isLuxury) {
    return false
  }
  return true
}
export const showBestsellers = (brand: Brand) => {
  if (brand.isLuxury) {
    return false
  }
  if (brand.isNiche) {
    return false
  }
  return true
}

export const useDidMountEffect = (func: any, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
}

export function mode(unique, names) {
  let selected = null
  unique.forEach(async name => {
    if (names.filter(a => a === name).length > 1) {
      selected = name
    }
  })
  return selected
}

export const loadCartItems = (setCart, language) => {
  const options = new QueryOptions()
  options.lang = language
  cartService.list(options).then((r: any) => {
    setCart(r.results.map(ci => {
      const [simple] = ci.product.simpleProducts.filter(sp => sp.sku === ci.sku)
      return {
        ...ci,
        specialPrice: ci.specialPrice,
        price: ci.price,
        stock: simple ? simple.stock : ['e_gift_card_001', 'gift_card_001'].indexOf(ci.sku) < 0 ? 0 : 5, loaded: true
      }
    }))
  })
}

export const intToArray = (num: number): number[] => {
  return Array.from({ length: num + 1 }, (_, i) => i + 1);
}

export const limitString = (string, limit) => {
  if (string.length > limit) {
    return string.substring(0, limit - 3) + "...";
  }
  return string;
}

export const checkRoute = (id) => {
  console.log(id)
  switch(id){
    case 'about_us':
      return '/about_us/about_us'
    case 'bonus_card':
      return '/my_profile/bonus_card'
    case 'customer_service':
      return '/help/faq'
  }
}
export const checkMobileRoute = (id) => {
  switch(id){
    case 'about_us':
      return '/about_us'
    case 'my_profile':
      return '/my_profile'
    case 'faq':
      return '/help'
  }
}

export const checkAccountRoute = (string) => {
    if (string.startsWith('/my_pages')) {
      return string.replace('/my_pages', '/account')
    } else {

      return string;
    }
}