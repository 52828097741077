import { Resource } from "../resource"


export class FaqSection extends Resource {
    id: string;
    title: string;
}
export class Faq extends Resource {
   question: string;
   section: FaqSection;
   sort: number;
   text: string;
}