import React, { useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import {  ProductPromoPageTitleAndTextSection } from "../../models/product/ProductPromoPage"

export const ProductPromoPageTitleAndTextSectionComponent = (props) => {
    const {  textItem, language } = props
    const { width } = useWindowDimensions()
    const [item, setItem] = useState<ProductPromoPageTitleAndTextSection>()

    useEffect(()=> {
        if(textItem) {
            setItem(textItem)
        }
    }, [textItem])

    return (
        <Box
                sx={{
                    width: width,
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    alignItems: "center",
                    paddingBottom: 10,
                    paddingTop: 7,
                    "@media(max-width:464px)": {
                        width: width,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: 5,
                        paddingTop: 5
                    },
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                        width: "80%",
                        textAlign: "center",
                        margin: "0 auto",
                    }}
                >
                    {item?.title}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                        width: "80%",
                        textAlign: "center",
                        margin: "0 auto",
                    }}
                >
                    {item?.text}
                </Typography>
            </Box>
    )
}
