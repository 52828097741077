import { Attribute, AttributeSet } from "../attribute/attribute"
import { Brand } from "../brand/brands"
import { Category } from "../category/category"
import { Discount } from "../Discount/Discount"
import { EmbeddedInventory } from "../EmbeddedInventory/EmbeddedInventory"
import { Resource } from "../resource"

export class ProductMedia extends Resource {
  filename: string
  is_main: boolean
  name: string
  sort: number
  isCommon: boolean
  image: string
  image2000: string
  thumbnail: string
}

export class Product extends Resource {
  uid: string
  image2000: string
  name: string
  created: Date
  price: number
  specialPrice: number
  attributeSet: AttributeSet
  attributes: Attribute[]
  simpleProducts: SimpleProduct[]
  brand: Brand
  category: Category
  configurables: Array<Attribute[]>
  description: string
  shortDescription: string
  facet: Attribute[]
  video: string
  media: ProductMedia[]
  mainImage: string
  couponPrice:  number;
  discount:    Discount;
  alsoUseProduct: Product
  howToUse: string
  firstBannerText: string
  secondBannerTitle: string
  secondBannerText: string
  bottomBannerTitle: string
  bottomBannerText: string
  embeddedInventory: EmbeddedInventory[]
  available: boolean
}
export class SimpleProduct extends Resource {
  attributes: Attribute[]
  name: string
  price: number
  specialPrice: number
  sku: string
  stock: number
}

