import { Tab, Tabs } from "@mui/material"
import React from "react"

export const GiftCardFormTabs = (props) => {
    const { value, handleChange, translations, language } = props
    return (
        <Tabs
            value={value}
            onChange={handleChange}
            centered
            sx={{
                ".MuiTabs-indicator": {
                    backgroundColor: "black !important",
                    height: "1.5px",
                },
                ".Mui-selected": {
                    color: "black !important",
                    padding: 0,
                },
            }}
        >
            <Tab
                label={translations?.a_friend}
                disableRipple
                sx={{
                    color: "#000 !important",
                    padding: 0,
                    display: "flex",
                    justifyContent: "center",
                    textTransform: "none",
                    fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                }}
            />
            <Tab
                label={translations?.for_me}
                disableRipple
                sx={{
                    color: "#000 !important",
                    padding: 0,
                    display: "flex",
                    justifyContent: "center",
                    textTransform: "none",
                    fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                }}
            />
        </Tabs>
    )
}
