import { addToCart, loadCart } from "../../helpers/utilities";
import { Product } from "../product/product";
import { Resource } from "../resource";


export class CartItem extends Resource {
    product: Product
    sku: string
    price: number
    specialPrice: number
    priceToPay: number
    discount: number
    quantity: number
    cardOrderId: string
    lang: string
    stock: number
    created: number

    public save = () => {}

}
export class Cart extends Resource {
    items: CartItem[]

    save() {
		localStorage.setItem("cart", JSON.stringify(this.items))
	}
    updateServer(app, auth) {
        this.items.forEach((item: CartItem) => {
            addToCart(item.product, item.sku, item, app, auth)
        })
	}
    async updateServerWithItems(app, auth, setCart) {
        for (const item of app.cart) {
            const e = await addToCart(item.product, item.sku, item, app, auth, item.quantity)
        }
        this.loadFromServer(setCart)
        
	}

    updateLocal(items) {
        this.items = items
        this.save()
	}
    
    async loadFromStorage(setCart) {
        let cart: any = await localStorage.getItem("cart")
        if (cart && cart !== "undefined") {
            setCart(cart ? JSON.parse(cart): [])
        }
	}
    loadFromServer(setCart) {
        loadCart(setCart,localStorage.getItem("language") ? localStorage.getItem("language"): 'geo')
	}

}