import React, { useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"
import { COLORS } from "../../constants/colors"
import { ProductPromoPageTextSection } from "../../models/product/ProductPromoPage"

export const ProductPromoPageTextSectionComponent = (props) => {
    const {  textItem, language } = props
    const { width } = useWindowDimensions()
    const [item, setItem] = useState<ProductPromoPageTextSection>()

    useEffect(()=> {
        if(textItem) {
            setItem(textItem)
        }
    }, [textItem])

    return (
        <Box
                sx={{
                    width: width,
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    padding: 30,
                    marginBottom: 5,
                    backgroundColor: item?.color,
                    "@media(max-width:464px)": {
                        width: width,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 5
                    },
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                        width: "80%",
                        textAlign: "center",
                        margin: "0 auto",
                    }}
                >
                    {item?.text}
                </Typography>
            </Box>
    )
}
