import { Box, Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarRateIcon from '@mui/icons-material/StarRate';
import { Title } from "../Typography/Title";

export const RateItem = (props) => {
    const { rate, translations, count } = props


    useEffect(() => {

    }, [])

    return <Box sx={{ width: 140, display: 'flex', flexDirection: 'row', alignItems: 'space-around', justifyContent: 'center', height: 40 }}>
        {[0, 1, 2, 3, 4].map((item, index) => {
            return <Box
                key={index}
                sx={{ flexDirection: 'column', display: 'flex', width: '20%' }}>
                {rate > item ? <StarRateIcon /> : <StarBorderIcon />}
            </Box>
        })}
    </Box>
} 