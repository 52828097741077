import Dialog from '@mui/material/Dialog';
import React, { useEffect, useRef, useState, } from "react";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import 'react-medium-image-zoom/dist/styles.css'
import '../../App.css'
import { Box } from '@mui/material';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import "../Carousel/carousel.css"
// import required modules

import { Zoom, Navigation, FreeMode, Thumbs } from "swiper";

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const GalleryDialog = (props) => {
    const { open, onCloseDialog, product, items } = props
    const { sessionId, setSessionID } = props
    const [image, setImage] = useState<string>()
    const [index, setIndex] = useState(0)
    const [imgProps, setImageProps] = useState({ width: 400, height: 250, zoomWidth: 500, img: null })
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [zoomStatus, setZoomStatus] = useState(null);
    const swiperRef = useRef(null);
    // const swiperRef = useRef(null);

    useEffect(() => {
        if (product?.media?.length && open) {
            const media = items[index]
            if (media) {
                setImage(media.image2000)
            }
        }
    }, [product, open, index])

    // useEffect(() => {
    //     if (swiperRef.current && activeIndex !== undefined) {
    //         swiperRef.current.swiper.slideTo(activeIndex);
    //     }
    // }, [activeIndex]);

    return <Dialog
        open={open}
        maxWidth='xl'
        style={{ padding: 10, minWidth: 700 }}
        TransitionComponent={Transition}
    >
        <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer', zIndex: 22 }} onClick={() => {
            onCloseDialog(false)
            setThumbsSwiper(null)
            setZoomStatus(false)
        }}>
            <CloseIcon />
        </div>
        {zoomStatus ? <div style={{ position: 'absolute', right: '50%', bottom: 150, cursor: 'pointer', zIndex: 22 }} onClick={() => {
            swiperRef.current.zoom.toggle(zoomStatus);
            setZoomStatus(false)
        }}>
            <ZoomOutIcon fontSize="large" />
        </div> : <div style={{ position: 'absolute', right: '50%', bottom: 150, cursor: 'pointer', zIndex: 22 }} onClick={() => {
            swiperRef.current.zoom.toggle(zoomStatus);
            setZoomStatus(true)
        }}>
            <ZoomInIcon fontSize="large" />
        </div>}
        <div style={{
            width: 800,
            display: 'flex', flex: 1, height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            overflow: 'hidden',
        }}>
            <Box sx={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', padding: 3 }}>
                <Swiper
                    ref={swiperRef}
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    spaceBetween={10}
                    navigation={false}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs, Zoom]}
                    className="mySwiper2"
                    zoom={true}
                    onTap={(swiper, event) => {
                        setZoomStatus(!zoomStatus)
                        const slideEl = (event.target as Element).closest('.swiper-slide');
                        if (slideEl && swiper.zoom.enabled) {
                            swiper.zoom.toggle();
                        }
                    }}
                    onDoubleClick={() => {
                        setZoomStatus(!zoomStatus)
                    }}
                >
                    {items?.map((item, index) => {
                        return <SwiperSlide key={index} >
                            <div className="swiper-zoom-container">
                                <img style={{ maxHeight: window.innerHeight - 350 }} src={item.image2000} />
                            </div>
                        </SwiperSlide>
                    })}
                </Swiper>
            </Box>
            <Box sx={{ width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', paddingBottom: 3 }}>
                <Swiper
                    style={{ width: '95%' }}
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={6}
                    navigation={true}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper3"
                >
                    {items?.map((item, index) => {
                        return <SwiperSlide onClick={() => setZoomStatus(false)} key={index}>
                            <img style={{
                                height: 100,
                                width: 100
                            }} src={item.image} />
                        </SwiperSlide>
                    })}
                </Swiper>

            </Box>
        </div>
    </Dialog>
}