import React, { useState, } from "react";
import '../styles.scss'
import { Badge, Box } from "@mui/material";

export const BrandItem = (props: any) => {
    const { item, onClick, fontSize = 16 } = props
    return <div className="brand-item" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        {item.isNew === true && <Box style={{
            position: 'absolute', right: 20, top: 3, borderRadius: 10, zIndex: 1, backgroundColor: 'red'
        }}>
            <Badge color="error" badgeContent={'new'} invisible={false} style={{ zIndex: 1000 }} />
        </Box>}
        <h4 onClick={onClick} style={{ fontSize }}>
            {item.title}
        </h4>
    </div>
}