import { Product } from "../product/product";
import { Resource } from "../resource";
import { User } from "../user/user";

export class Review extends Resource {
    craated_timestamp: number;
    created:           string;
    product:           Product;
    text:              string;
    user:              User;
    value:             number;
}   


export class ReviewSummary extends Resource {
    average: number;
    count:   number;
    summary: Summary[];
}

export class Summary extends Resource {
    count: number;
}


