import { Box, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../constants/colors';
import { Title } from '../Typography/Title';
import BogIcon from '../../assets/icons/BogIcon';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';


export const ChipComponent = (props) => {
    const { selected, language, onClick, title, id = "", logo, item } = props

    const { width } = useWindowDimensions()

    const stylesWithLogo = {
        "&:hover": {

            backgroundColor: COLORS.BG_BEIGE,
            borderColor: `black`,
            color: 'black'
        },
        backgroundColor: selected ? COLORS.BG_BEIGE : 'white',
        display: 'flex',
        padding: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: width < 600 ? "100%" : 200,
        maxHeight: 14,
        borderRadius: 10,
        margin: 1,
        border: '1px solid #777',
        cursor: 'pointer'

    }

    return <Box sx={logo ?
        stylesWithLogo :
        {
            "&:hover": {

                backgroundColor: COLORS.BG_BEIGE,
                borderColor: `black`,
                color: 'black'
            },
            backgroundColor: selected ? COLORS.BG_BEIGE : 'white',
            display: 'flex',
            padding: 1,
            alignItems: 'center',
            justifyContent: 'space-around',
            minWidth: 120,
            maxHeight: 50,
            borderRadius: 10,
            margin: 1,
            border: '1px solid #777',
            cursor: 'pointer'
        }}
        onClick={onClick}
    >
        {logo ?
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <BogIcon />
                <Typography sx={{width:'100%', color: item?.uid === "by_parts_bog" ? 'rgb(108,18,209)' : 'black', fontFamily: language === 'geo' ? 'FiraGo' : 'Roboto', fontWeight: '500', fontSize: 14, margin: '0 auto' }}>
                    {title}
                </Typography>
            </Box>
            :
            <Title
                uppercase={true}
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                color={'#333'}
                fontSize={13}
                style={{ cursor: 'pointer' }}
                fontWeight={'500'}
                text={title} />
        }
        {id.indexOf("bog") > -1 &&
            <img
                src={"https://api.voulez-vous.ge/assets/bog.png"} style={{
                    borderRadius: 3,
                    marginLeft: 5,
                    width: 30, height: 30
                }} />}
    </Box>
}