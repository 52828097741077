import React, { useState, useEffect } from "react"

import { connect } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog } from "@mui/material"
import { setCart, setSnackBar, setGoToCartDialog, setFavorites } from '../../models/application/applicationActions'
import { ProductPopupItem } from "../Product/ProductPopupItem";
import { QueryOptions } from "../../helpers/query.options";
import { productService } from "../../models/product/service";
import { Product } from "../../models/product/product";
import { MobileProductPopupItem } from "../Product/MobileProductPopupItem";
import "../styles.scss"

const ProductPopup = (props) => {
    const { item,
        setGoToCartDialog,
        onCloseTerms,
        onProductLoad,
        onCloseDialog,
        auth,
        setGalleryOpen,
        setProduct,
        product,
        setFavorites,
        onOpen,
        setCart,
        app,
        language,
        setSnackBar } = props

    useEffect(() => {
        if (onOpen) {
            loadProduct(item.id)
        }
    }, [onOpen, item])

    const loadProduct = (id: string) => {
        const options = new QueryOptions()
        options.lang = language
        productService.read(id, options).then((resp: Product) => {
            setProduct(resp)
        })
    }
    return <div>
        <Dialog
            maxWidth='xl'
            style={{ padding: 0, minWidth: window.innerWidth > 1200 ? 1200 : '100%',
            height: window.innerHeight + 64, marginTop: -32, overflow: 'hidden' }}
             
            
            sx={window.innerWidth > 1200 ? {} : { margin: 0 }}
            open={props.onOpen}
            onClose={onCloseDialog}
            aria-labelledby="form-dialog-title"
        >
            <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => {
                onCloseDialog(false)
            }}>
                <CloseIcon />
            </div>
            <Box sx={{ width: window.innerWidth > 1200 ? 800 : window.innerWidth, marginTop: 4, height: window.innerWidth > 1200 ? 400 : window.innerHeight, overflow: 'hidden' }}>
                {window.innerWidth > 1200 ?
                    <ProductPopupItem
                        {...props}
                        language={language}
                        onAddedToCart={() => {
                            setTimeout(() => {
                                setGoToCartDialog(true)
                            }, 200)
                        }}
                        onClose={() => onCloseDialog(false)}
                        item={product}
                        setFavorites={setFavorites}
                        app={app}
                        auth={auth}
                        setCart={setCart}
                        setSnackBar={setSnackBar}
                        setGalleryOpen={setGalleryOpen} /> :
                    <MobileProductPopupItem
                        {...props}
                        language={language}
                        onAddedToCart={() => {
                            setTimeout(() => {
                                setGoToCartDialog(true)
                            }, 200)
                        }}
                        onClose={() => onCloseDialog(false)}
                        item={product}
                        setFavorites={setFavorites}
                        app={app}
                        auth={auth}
                        setCart={setCart}
                        setSnackBar={setSnackBar}
                        setGalleryOpen={setGalleryOpen} />}
            </Box>
        </Dialog>
    </div>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        language: state.language
    }
}

export default connect(mapStateToProps, { setSnackBar, setCart, setGoToCartDialog, setFavorites })(ProductPopup)