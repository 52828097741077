import { Box } from "@mui/material"
import React, { useEffect, useState } from "react"
import { QueryOptions } from "../../helpers/query.options"
import { infoPageContentService } from "../../models/InfoPage/service"
import Fade from "@mui/material/Fade"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"

const RenderContent = React.forwardRef(function (props: any, ref) {
    return (
        <div ref={ref} {...props}>
            {window.innerWidth < 1200 ? <h4 style={{marginTop: 50}}>{props.content?.title}</h4> : <></>}
            {props.content?.text || props.content?.sections?.length > 0 ? (
                <>
                    <Box sx={{ padding: 2, textAlign: "left" }}>
                        {props.content?.display !== "image" && (
                            <Box
                                dangerouslySetInnerHTML={{
                                    __html: props.content?.text.replace(/\\n/g, "").replace(/\\r/g, ""),
                                }}
                                style={{ fontSize: 14, padding: 20 }}
                            />
                        )}
                        {props.content?.display !== "text" && (
                            <img style={{ width: "100%" }} src={props.content?.image} />
                        )}
                    </Box>

                    {props.content?.sections?.length > 0 && (
                        <>
                            {props.content?.sections?.map((section, index) => {
                                return (
                                    <Box sx={{ padding: 2 }}>
                                        <h4>{section.title}</h4>
                                        {section.display !== "text" && (
                                            <img style={{ width: "100%" }} src={section.image} />
                                        )}
                                        {section.display !== "image" && (
                                            <Box
                                                key={index}
                                                dangerouslySetInnerHTML={{
                                                    __html: section?.text.replace(/\\n/g, "").replace(/\\r/g, ""),
                                                }}
                                                style={{ fontSize: 14, padding: 10 }}
                                            />
                                        )}
                                    </Box>
                                )
                            })}
                        </>
                    )}
                </>
            ) : (
                <h4>{props.translations?.content_not_found}</h4>
            )}
        </div>
    )
})

export const TermsAndRulesContent = (props) => {
    const { translations, language } = props

    const [content, setContent] = useState<any>()
    const [init, setInit] = useState(false)

    const { width } = useWindowDimensions()

    const loadInfoPage = async (id): Promise<any> => {
        const options = new QueryOptions()
        options.lang = language
        const page = (await infoPageContentService.read(id, options)) as any
        setContent(page)
    }

    useEffect(() => {
        loadInfoPage("policy")
    }, [])

    useEffect(() => {
        if (content) {
            setInit(true)
        }
    }, [content])

    return (
        <Box sx={{ width: width > 600 ? width : "auto" }}>
            <Fade in={init} timeout={700} style={{ width: "100%" }}>
                <RenderContent content={content} translations={translations} />
            </Fade>
        </Box>
    )
}
