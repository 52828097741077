import { ResourceProvider } from "../../providers/resource.provider"
import { GiftCard, GiftCardTemplate, GiftCardTemplateCategory } from "./GiftCard"
import { GiftCardSerializer, GiftCardTemplateCategorySerializer, GiftCardTemplateSerializer } from "./serializer"

export class GiftCardProvider extends ResourceProvider<GiftCard> {
    constructor() {
        super("rest_gift_card", new GiftCardSerializer())
    }
}
export class GiftCardTemplateProvider extends ResourceProvider<GiftCardTemplate> {
    constructor() {
        super("rest_gift_card_template", new GiftCardTemplateSerializer())
    }
}
export class GiftCardTemplateCategoryProvider extends ResourceProvider<GiftCardTemplateCategory> {
    constructor() {
        super("rest_gift_card_template_category", new GiftCardTemplateCategorySerializer())
    }
}

export const giftCardService = new GiftCardProvider()
export const giftCardTemplateService = new GiftCardTemplateProvider()
export const giftCardTemplateCategoryService = new GiftCardTemplateCategoryProvider()

