import React, { useCallback, useState } from "react";
import { Box, Grid } from "@mui/material";
import { connect } from 'react-redux'
import { setCart, setFavorites, setSnackBar } from '../models/application/applicationActions'
import { State } from '../models/application/applicationReducer'
import LoginForm from "../components/Forms/LoginForm"
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import ForgotPasswordFormDialog from "../components/Dialogs/ForgotPasswordFormDialog";
import RegisterForm from "../components/Forms/RegisterForm";
import { initCartAndFavs } from "../helpers/utilities";
import { login } from "../models/user/authActions";
import { Title } from "../components/Typography/Title";

import { environment } from "../environment/environment";
import { FacebookButton } from "../components/Button/FacebookButton";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { loginFacebook } from "../models/user/service";
import { User } from "../models/user/user";
import { SMSDialog } from "../components/Dialogs/SMSDialog";

const LoginPage = (props) => {
  const { app, login, setCart, auth, setFavorites, translations, setSnackBar } = props
  const [smsDialogOpen, setSmsDialogOpen] = useState(false)
  const [user, setUser] = useState<User>()
  const [smsDialogType, setSMSDialogType] = useState("")
  const styles = useStyles()
  const history = useHistory();
  const [snackbar, setSnackbar] = useState<State>({ open: false, vertical: 'bottom', horizontal: 'center', text: '' })
  const [passwordRestoreOpen, setPasswordRestoreOpen] = useState(false)
  const handleSnackbarClose = () => {
    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
  };
  React.useEffect(() => {
    setSnackbar(app.snackBar)
  }, [app.snackBar])

  const RenderSMSDialog = useCallback(() => {
    return <SMSDialog
      textAccept={translations?.send}
      onSuccess={(type) => {
        const accessToken = localStorage.getItem("accessToken")
        loginFacebook(accessToken).subscribe((resp: User | any) => {
          if (resp?.accessToken) {
            if (resp.activated) {
              let user = new User()
              user = user.updateUser(resp)
              login(user)
              initCartAndFavs(app, { ...auth, loggedIn: true }, setCart, setFavorites)
              history.push("/")
              setSnackBar({ ...app.snackBar, open: true, text: `${translations?.welcome} ${user.firstName}.`, bColor: "rgb(83, 148, 96)" })
            } else {
              setSMSDialogType("facebook")
              setTimeout(() => {
                setSmsDialogOpen(true)
              }, 50)
            }
          }
        })
      }}
      user={user}
      type={smsDialogType}
      open={smsDialogOpen} {...props}
      setOpen={setSmsDialogOpen}
    />
  }, [smsDialogOpen, smsDialogType, user])

  return <div className={styles.container} style={{ alignItems: 'center', justifyContent: 'center' }}>
    <RenderSMSDialog />
    <ForgotPasswordFormDialog
      translations={translations}
      onOpen={passwordRestoreOpen} onClose={setPasswordRestoreOpen} />
    <Grid container spacing={1} style={{ maxWidth: 1200, margin: '0px auto' }}>
      {window.innerWidth < 1200 ? <><Box sx={{ padding: 0, paddingLeft: 2, width: '100%', marginTop: 5 , marginBottom: 2 }}>
        <Title text={translations?.authentication_facebook}
          style={{ padding: 0 }}
          fontSize={24} uppercase={true} fontWeight='500' />
      </Box>
        <Box style={{ width: '100%', maxWidth: 481, paddingBottom: 0  }}>
          <FacebookLogin
            appId={environment.facebookAPPID}
            autoLoad={true}
            fields="name,email,picture"
            callback={(r: any) => {
              loginFacebook(r.accessToken).subscribe((resp: User | any) => {
                if (resp?.accessToken) {
                  localStorage.setItem("accessToken", r.accessToken)
                  if (resp.activated) {
                    let user = new User()
                    user = user.updateUser(resp)
                    login(user)
                    initCartAndFavs(app, { ...auth, loggedIn: true }, setCart, setFavorites)
                    history.push("/")
                    setSnackBar({ ...app.snackBar, open: true, text: `${translations?.welcome} ${user.firstName}.`, bColor: "rgb(83, 148, 96)" })
                  } else {
                    setUser(resp as User)
                    setSMSDialogType("facebook")
                    setSmsDialogOpen(true)
                  }
                }
              })
            }}
            render={renderProps => ( <Grid item lg={12} sm={12} style={{ width: '100%'}}>
              <FacebookButton title={"Facebook"} onClick={() => {
                renderProps.onClick()
              }} />
            </Grid>)}
          />
        </Box> </> : <Grid lg={6} xs={12} item style={{ paddingTop: 0, padding: 30, display: 'flex', alignItems: 'center', flexDirection: 'column' }}></Grid>}
      <Grid lg={6} xs={12} item style={{ paddingTop: 0, padding: 0, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

      </Grid>
      <Grid lg={4} item style={{ paddingTop: 30, padding: window.innerWidth > 1200 ? 30: 0 }}>
        <Box sx={{ padding: 2 }} style={{ paddingBottom: 26 }}>
          <Title text={translations?.authentication} fontSize={24} uppercase={true} fontWeight='500' />
        </Box>
        <LoginForm
          {...props}
          onLogin={() => {
            initCartAndFavs(app, { ...auth, loggedIn: true }, setCart, setFavorites)
            history.push("/")
          }} />
        <Box
          className={styles.login}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: window.innerWidth > 1200 ?  'left' : 'center',
            flexDirection: 'row',
            justifyContent: window.innerWidth > 1200 ?  'left' : 'center'
          }}>
          <Box sx={{ padding: 2, alignItems: 'center', cursor: 'pointer' }} onClick={() => {
            setPasswordRestoreOpen(true)
          }}>
            <Title text={translations?.forgot_password} fontSize={17} uppercase={false} fontWeight='400' />
          </Box>

        </Box>
      </Grid>
      <Grid lg={8} style={{ padding: window.innerWidth > 1200 ? 30: 0 , paddingTop: 30 }}>
        <Box sx={{ padding: 2 }}>
          <Title text={translations?.registration} fontSize={24} uppercase={true} fontWeight='500' />
        </Box>
        <RegisterForm
          {...props}
          onLogin={() => {
            history.push("/")
          }} />
      </Grid>
      {window.innerWidth > 1200 ? <><Box sx={{ padding: 2, paddingLeft: 6, width: '100%', marginTop: -5 }}>
        <Title text={translations?.authentication_facebook}
          style={{ padding: 0 }}
          fontSize={20} uppercase={true} fontWeight='500' />
      </Box>
        <Box style={{ padding: 0, width: '50%' , maxWidth: 345, marginLeft: 27, paddingBottom: 100 }}>
          <FacebookLogin
            appId={environment.facebookAPPID}
            autoLoad={true}
            fields="name,email,picture"
            callback={(r: any) => {
              loginFacebook(r.accessToken).subscribe((resp: User | any) => {
                if (resp?.accessToken) {
                  localStorage.setItem("accessToken", r.accessToken)
                  if (resp.activated) {
                    let user = new User()
                    user = user.updateUser(resp)
                    login(user)
                    initCartAndFavs(app, { ...auth, loggedIn: true }, setCart, setFavorites)
                    history.push("/")
                    setSnackBar({ ...app.snackBar, open: true, text: `${translations?.welcome} ${user.firstName}.`, bColor: "rgb(83, 148, 96)" })
                  } else {
                    setUser(resp as User)
                    setSMSDialogType("facebook")
                    setSmsDialogOpen(true)
                  }
                }
              })
            }}
            render={renderProps => (<Box style={{ width: '100%' }}>
              <FacebookButton title={"Facebook"} onClick={() => {
                renderProps.onClick()
              }} />
            </Box>)}
          />
        </Box> </> : ' '}
      <Grid lg={3}></Grid>
    </Grid>

    <div className={styles.login} style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>
      <br />

    </div>
  </div>
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    translations: state.translations
  }
}

export default connect(mapStateToProps, { setSnackBar, login, setCart, setFavorites })(LoginPage)

const useStyles = makeStyles({
  container: {
    width: '100%',
    background: '#fafafa',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center', textflexDirection: 'column'
  },
  login: {
    flex: 1,
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'row'
  }
})
