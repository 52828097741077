import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { ShippingMethod } from "./ShippingMethod"

export class ShippingMethodSerializer implements Serializer {
    fromJson(json: any) {
        const method = new ShippingMethod()
        method.id = json.id
        method.price = json.price
        method.name = json.name
        method.description = json.description
        return method
    }
    toJson(order: any) {
        return {
            
        }
    }
}

export const shippingMethodSerializer = new ShippingMethodSerializer