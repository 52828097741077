import React, { useState, useEffect, useCallback } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { QuantityControlComponent } from './QuantityControlComponent'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { cartService } from '../../models/cart/service'
import { Title } from '../Typography/Title'
import { CartItem } from '../../models/cart/cart'
import { useHistory } from 'react-router';
import { SimpleProduct } from '../../models/product/product';


export const ProductFavoritesComponent = (props: any) => {
    const { item, redirect, onRemove, onMoveToCart, translations, onUpdateCart, language, onMessage } = props
    const [qty, setQty] = useState(1)
    const [initial, setInitial] = useState(true)
    const [value, setValue] = useState("")
    const history = useHistory();
    const [stock, setStock] = useState(1)
    const classes = useStyles();
    const [valueType, setValueType] = useState("text")
    const [simple, setSimple] = useState<SimpleProduct>()



    useEffect(() => {
        if (item) {
            const [simple] = item.product.simpleProducts.filter(i => i.sku === item.sku)
            if (simple) {
                setSimple(simple)
            }
        }
    }, [item])



    useEffect(() => {
        if (item.quantity) {
            setQty(item.quantity)
        }
        if (item) {
            const [simple] = item.product.simpleProducts.filter(s => s.sku = item.sku)
            if (simple) {
                setStock(simple.stock)
                const [color] = simple.attributes.filter(a => a.name.id === 'color_family')
                if (color) {
                    setValueType("color")
                    setValue(`#${color.value}`)
                }
                const [size] = simple.attributes.filter(a => a.name.id === 'size')
                if (size) {
                    setValueType("text")
                    setValue(`${size.name.value}: ${size.value}`)
                }
            } else {
                setStock(5)
            }
        }
    }, [item])

    const redirectToProduct = (item) => {
        if (["e_gift_card_001", "gift_card_001"].indexOf(item.sku) < 0) {
            history.push("/product/" + item.product.id)
        }
    }

    return <div style={{ flexDirection: 'row', display: 'flex', height: 140 }}
    >
        <div style={{ flexDirection: 'column', width: '50%', cursor: 'pointer', padding: 10, maxWidth: '142px' }}
            onClick={() => redirectToProduct(item)}
        >
            <img src={item?.product?.mainImage} style={{ width: '90%' }} />
        </div>
        <div
            onClick={() => redirectToProduct(item)}
            style={{ flexDirection: 'column', width: '50%', cursor: 'pointer', padding: 10, alignItems: 'flex-start', display: 'flex' }} >
            {<Typography component="h4" variant="subtitle1" style={{ fontWeight: 'bold', fontSize: 17, textAlign: 'left' }}>
                {item?.product?.brand.title}
            </Typography>}
            <Typography component="h4" variant="subtitle2" style={{ fontWeight: '400', fontSize: 13, textAlign: 'left' }}>{item?.product?.name}</Typography>

            <Typography component="h4" variant="subtitle2" style={{ fontWeight: '400', fontSize: 13, textAlign: 'left' }}>
                {translations?.code}: {item?.product?.uid}</Typography>
            {valueType !== "text" && value?.length && <Box sx={{
                marginTop: 1,
                width: 25, height: 25,
                borderRadius: 20,
                border: "2px solid #444",
                backgroundColor: value
            }}></Box>}
            {value?.length > 0 && valueType === "text" && <Button

                sx={{
                    marginTop: 1,
                    color: 'white',
                    marginRight: 1,
                    backgroundColor: '#333',
                    '&:hover': {
                        color: 'white',
                        backgroundColor: '#333',
                    },
                    marginBottom: 1,
                    justifyContent: 'space-around',
                    height: 30,
                    minWidth: 120,
                    border: '1px solid #333',
                    borderRadius: 2,

                }}
                disableRipple={true}
                disableFocusRipple={false}
                color={"secondary"}
                onClick={() => {

                }}
            >
                <Title
                    uppercase={true}
                    fontWeight={'500'}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'white'}
                    fontSize={13}
                    style={{ marginRight: 10 }}
                    text={value} />


            </Button>}
        </div>
        <div style={{
            flexDirection: 'column', width: '15%',
            paddingTop: 15,
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'flex-start'
        }} >
        </div>
        <div style={{
            flexDirection: 'column', width: '30%',

            display: 'flex',
            cursor: 'pointer', alignItems: 'center', justifyContent: 'space-around'
        }} >
            {simple && <div className={classes.thirdColumn}>
                {simple?.specialPrice && simple.specialPrice < simple.price ? <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <Title text={`${simple.price}₾`} fontSize={22} uppercase={true} fontWeight='400' color={"black"} style={{ marginRight: 5, textDecorationLine: 'line-through' }} />
                    <Title text={`${simple.specialPrice} ₾`} fontSize={22} uppercase={true} fontWeight='400' color={"red"} />
                </div> : <Title text={`${simple.price} ₾`} fontSize={22} uppercase={true} fontWeight='400' fontFamily='Roboto' color={"#000"} className="roboto" />}

            </div>}
            <div className={classes.thirdColumn}>
                <div style={{ flexDirection: 'column' }}>
                    <div className={classes.thirdColumn} onClick={() => {
                        onRemove(item)
                    }}>
                        <DeleteOutlineIcon sx={{
                            '&:hover': {
                                color: 'red'
                            }
                        }} />
                        <Typography component="h4" variant="subtitle2" style={{ fontWeight: '500', fontSize: 14 }}>
                            {translations?.delete}
                        </Typography>
                    </div>
                    <div className={classes.thirdColumn} onClick={() => {
                        onMoveToCart(item)
                    }} >
                        <FavoriteBorderIcon />
                        <Typography component="h4" variant="subtitle2" style={{ fontWeight: '500', fontSize: 14, }}>
                            {translations?.move_to_cart}
                        </Typography>

                    </div>

                </div>

            </div>

        </div>

    </div >
}
const useStyles = makeStyles({
    productName:
        { fontWeight: 'bold', fontSize: 15 },
    listItem:
        { color: 'black', fontFamily: 'FiraGo', fontSize: 12, fontWeight: '300' },
    thirdColumn:
    {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: 20
    }

});
