import { ResourceProvider } from "../../providers/resource.provider"
import { FavoriteItem } from "./favorite"
import { FavoriteItemSerializer } from "./serializer"

export class FavoriteItemProvider extends ResourceProvider<FavoriteItem> {
    constructor() {
        super("rest_favorite", new FavoriteItemSerializer())
    }
}
export const favoritesService = new FavoriteItemProvider()

