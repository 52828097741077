import { Typography, Box } from '@mui/material';
import React, { useState, useEffect } from "react";
import { environment } from '../../environment/environment';
import { useCookies } from 'react-cookie';


export const Languages = (props) => {
    const {chosenLanguage, setLanguage, height=15, width=23, marginRight=10, language, mode="desktop"} = props

    const [cookies, setCookie] = useCookies(['lang']);

    const flagGeo = `${environment.imagePath}/assets/geo.jpg`
    const flagEng = `${environment.imagePath}/assets/eng.jpg`
    
    return <Box style={{ 
        display: 'inline-block', padding: 0, position: 'relative', width: 70, height: 20 }}>
        <Box sx={{ marginTop: -0, position: 'absolute', top: -3, cursor: 'pointer', display: 'flex',
        right: mode === 'desktop' ?  10: 30,
        width: mode === 'desktop' ? 50: 70, justifyContent: 'space-around'
    }}>
            <img src={flagGeo} style={{width, height, marginRight}} onClick={() => {
                setCookie('lang', "geo", { path: '/' });
                localStorage.setItem("language", "geo")
                setLanguage('geo')
                window.location.reload()
            }}/>
            <img src={flagEng} style={{width, height}} onClick={() => {
                setCookie('lang', "eng", { path: '/' });
                localStorage.setItem("language", "eng")
                window.location.reload()
                setLanguage('eng')
            }}/>
            {/* <Typography 
            onClick={() => {
                setLanguage('eng')
            }}
            sx={{ color: 'black', fontSize: 14, display: 'inline-block', fontWeight: chosenLanguage === 'eng' ? 'bold': '400'}}>ENG </Typography> */}
            
            {/* <Typography sx={{ color: 'black', fontSize: 14, display: 'inline-block', marginLeft: .5, marginRight: .5}}> / </Typography> */}
            {/* <Typography 
            onClick={() => {
                setLanguage('geo')
            }}
            sx={{ color: 'black', fontSize: 14, display: 'inline-block', fontWeight: chosenLanguage === 'geo' ? 'bold': '400' }}>GEO</Typography> */}

        </Box>
    </Box>
}