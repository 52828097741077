import React, { useState, useEffect } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ImageZoomDialog from '../Dialogs/ImageZoomDialog';
import { Swiper, SwiperSlide } from "swiper/react";
import "./carousel.css"
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";

export const MobileImageComponent = (props: any) => {
    const { items, onLoaded } = props
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false })
    const [dialogOpen, setDialogOpen] = useState(false)
    const [activeIndex, setActiveIndex] = useState(null);




    return <div style={{ position: 'relative' }}>
        <ImageZoomDialog
            items={items}
            activeIndex={activeIndex}
            open={dialogOpen}
            onCloseDialog={(resp) => {
                setDialogOpen(resp)
            }}
        />
        <Swiper
            onTap={(e) => {
                setDialogOpen(true)
                setActiveIndex(e.clickedIndex)
            }
            }
            slidesPerView={"auto"}
            spaceBetween={0}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            coverflowEffect={{
                rotate: 10,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: false,
            }}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper6"
        >
            {items?.map((item, index) => {
                return <SwiperSlide style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    width: window.innerWidth > 500 ? 300 : 200,
                    height: window.innerWidth > 500 ? 300 : 200,
                }} key={index} >
                    <img src={item.image} style={{
                        display: 'block',
                        width: '100%'
                    }}
                        onLoad={() => {
                            setTimeout(() => {
                                if (onLoaded) {
                                    onLoaded()
                                }
                            }, 400)
                        }}
                    />
                </SwiperSlide>
            })}
        </Swiper>
    </div>
}
