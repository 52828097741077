import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';

export const FilterChips = (props) =>  {
  const {handleDelete, filters} = props
  return filters ? <Stack direction="row" spacing={1} sx={{display: 'inline-block', textAlign: 'left'}}>
      {filters.map((item, index)=> {
        return <Chip
        key={index}
        label={item.name.id !== 'color_family' ? <span><b>{`${item.name.value}:`} </b>{`${item.value}`}</span>: `${item.name.value}`}
        sx={{margin: 1, '&.MuiChip-root ':{
          margin: .4
        }}}
        avatar={item.name.id === 'color_family' ? <Box style={{backgroundColor: `#${item.value}`, width: 20,height: 20, borderRadius: 10}}></Box>:<></>}
        variant="outlined"
        onDelete={() => handleDelete(item)} />
      })}
    </Stack>: <></>
}