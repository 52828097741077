import React, { useCallback, useEffect, useState, } from "react"
import { connect } from 'react-redux'
import { Box, Button, Divider, Fade, Typography } from "@mui/material";
import { environment } from "../environment/environment";
import BreadCrumbsComponent from "../components/BreadCrumbsComponent";
import EGiftCardForm from "../components/GiftCard/EGiftCardForm";
import { Title } from "../components/Typography/Title";
import { QueryOptions } from "../helpers/query.options";
import { Product } from "../models/product/product";
import { productService } from "../models/product/service";
import { addToCartGiftCart, loadCartItems } from "../helpers/utilities";
import { setCart, setSnackBar } from "../models/application/applicationActions";
import GiftCardForm from "../components/GiftCard/GiftCardForm";
import { Address } from "../models/address/address";
import { addressService } from "../models/address/service";
import { cityService } from "../models/city/service";
import { City } from "../models/city/city";
import { GiftCard } from "../models/giftCard/GiftCard";
import { giftCardService } from "../models/giftCard/service";
import EGiftCardFormDialog from "../components/Dialogs/EGiftCardFormDialog";
import GiftCardFormDialog from "../components/Dialogs/GiftCardFormDialog";
import DesktopGiftCardFormDialog from "../components/Dialogs/DesktopGiftCardFormDialog";
import DesktopEGiftCardFormDialog from "../components/Dialogs/DesktopEGiftCardFormDialog";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { useHistory } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { COLORS } from "../constants/colors";



const GiftCardPage = (props) => {
    const { translations, setSnackBar, app, setCart, auth, language } = props
    const [loaded, setLoaded] = useState(false)
    const [initGiftCard, setInitGiftCard] = useState(false)
    const [cities, setCities] = useState<City[]>([])
    const [address, setAddress] = useState<Address>()
    const [beigeLoaded, setBeigeLoaded] = useState(false)
    const [blackLoaded, setBlackLoaded] = useState(false)
    const [eGiftCard, setEGiftCard] = useState<Product>()
    const [giftCard, setGiftCard] = useState<Product>()

    const [options, setOptions] = useState<any[]>(environment.giftCardOptions)
    const [addresses, setAddresses] = useState<Address[]>([])
    const {width} = useWindowDimensions()
    const history = useHistory()

    const loadEGiftCard = () => {
        const options = new QueryOptions()
        productService.read(environment.eGiftCardId, options).then((resp: Product) => {
            setEGiftCard(resp)
        })
    }
    const loadGiftCard = () => {
        const options = new QueryOptions()
        productService.read(environment.giftCardId, options).then((resp: Product) => {
            setGiftCard(resp)
        })
    }

    const loadCities = () => {
        const options = new QueryOptions()
        options.pageSize = 120;
        cityService.listWithCounter(options).then(resp => {
            setCities(resp.current)
        })
    }

    const loadAddresses = () => {
        const options = new QueryOptions()
        addressService.list(options).then((resp: any) => {
            setAddresses(resp.results)

        })
    }

    useEffect(() => {
        if (addresses?.length) {
            const [addr] = addresses
            setAddress(addr)
        }
    }, [addresses])

    useEffect(() => {
        loadEGiftCard()
        loadCities()
        loadGiftCard()
        loadAddresses()
        let img = new Image()
        img.src = `${environment.basePath}/assets/catalog/product/gift_card_banner.jpg`
        img.onload = () => {
            setTimeout(() => {
                setLoaded(true)
            }, 500)
        }
        img = new Image()
        img.src = `${environment.basePath}/assets/catalog/product/beige.jpeg`
        img.onload = () => {
            setTimeout(() => {
                setBeigeLoaded(true)
            }, 500)
        }
        img = new Image()
        img.src = `${environment.basePath}/assets/catalog/product/black.jpeg`
        img.onload = () => {
            setTimeout(() => {
                setBlackLoaded(true)
            }, 500)
        }
    }, [])

    useEffect(() => {
        if (giftCard && beigeLoaded && cities?.length) {
            setInitGiftCard(true)
        }
    }, [giftCard, address, beigeLoaded, cities])


    const createEGiftCard = (values, forUser, language) => {

        const giftCardItem = new GiftCard()
        giftCardItem.amount = values.amount
        giftCardItem.qty = values.qty
        giftCardItem.type = "eCard"
        giftCardService.create(giftCardItem).then((r => {
            const options = new QueryOptions()
            productService.read(environment.eGiftCardId, options).then((gCard: Product) => {
                addToCartGiftCart(gCard, "e_gift_card_001", values.amount, app, auth, values.qty, r.id).then((r: any) => {
                    setCart(r)
                    loadCartItems(setCart, language)
                    localStorage.setItem("cart", JSON.stringify(r))
                    setSnackBar({ ...app.snackBar, open: true, text: `${translations.gift_card} ${translations?.has_been_added_to_cart}`, bColor: "rgb(83, 148, 96)" })
                })
            })
        }))

    }
    const createGiftCard = (values, language) => {
        const giftCardItem = new GiftCard()
        giftCardItem.amount = values.amount
        giftCardItem.qty = values.qty
        giftCardItem.type = "bCard"
        giftCardService.create(giftCardItem).then((r => {
            const options = new QueryOptions()
            productService.read(environment.giftCardId, options).then((gCard: Product) => {
                addToCartGiftCart(gCard, "gift_card_001", values.amount, app, auth, values.qty, r.id).then((r: any) => {
                    setCart(r)
                    loadCartItems(setCart, language)
                    localStorage.setItem("cart", JSON.stringify(r))
                    setSnackBar({ ...app.snackBar, open: true, text: `${translations.gift_card} ${translations?.has_been_added_to_cart}`, bColor: "rgb(83, 148, 96)" })
                })
            })
            
        }))


    }

    const RenderGiftCardForm = useCallback(() => {
        return <Box style={{ display: 'flex', flexDirection: width > 1200 ? 'row' : 'column', marginBottom: 40 }}>
            
            <Box style={{ display: 'flex', flexDirection: 'column', width: width > 1200 ? '40%' : '100%', padding: 10, maxWidth: width > 1200 ? '100%' : width }}>
                <Title
                    text={`${translations.gift_card_purchase}`}
                    fontSize={18} uppercase={true}
                    fontWeight='600'
                    color={"#444"}
                    style={{ marginBottom: 20 }} />
                <Fade
                    in={beigeLoaded}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(beigeLoaded ? { timeout: 2000 } : {})}
                >
                    {<img src={`${environment.basePath}/assets/catalog/product/beige.jpeg`} style={{ width: width > 1200 ? '100%' : width - 20 }} />}
                </Fade>
                <Title text={`${translations.gift_card_purchase_text}`} fontSize={16} uppercase={false} fontWeight='400' color={"#444"} style={{ marginTop: 20, width: width > 1200 ? '100%' : width - 20 }} />
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', width: width > 1200 ? '65%' : '100%', backgroundColor: 'transparent', paddingLeft: width > 1200 ? 20 : 10 }}>
                {<GiftCardForm
                    loc="mobile"
                    cities={cities}
                    translations={translations}
                    {...props}
                    address={address}
                    options={options}
                    product={giftCard}
                    onAddToCart={(values) => {
                        createGiftCard(values, language)
                    }}
                />}
            </Box>
        </Box>
    }, [giftCard, address, beigeLoaded, cities])

    return width > 1200 ? <Box style={{ width: 1050, margin: '0px auto' }}>
        
        <div className={"container"} style={{
            display: 'flex',
            width: 1200,
            paddingTop: 10,
            paddingBottom: 10,
            alignItems: 'flex-start', margin: '0px auto'
        }}>
            {translations && <BreadCrumbsComponent items={[
                { name: translations?.home, link: '/' },
                { name: translations?.gift_card, link: '/gift_card' },
            ]} />}
        </div>
        <Fade
            in={loaded}
            style={{ transformOrigin: '0 0 0' }}
            {...(loaded ? { timeout: 2000 } : {})}
        >
            {
                <Box sx={{position: 'relative'}}>

                    <img src={width > 1200 ? `${environment.basePath}/assets/catalog/product/gift_card_banner.jpg` : `${environment.basePath}/assets/catalog/product/gift_card_banner.jpg`} style={{ width: '100%' }} />
                    <Box sx={{position: 'absolute', bottom: 180, left: 90}}>
                        <Typography sx={{textTransform:'none', fontSize: 25, width: 300, letterSpacing: 1, color: COLORS.BG_DARK,  fontFamily: 'FiraGo', backgroundColor: 'rgb(231,222,209)'}}>{translations?.voulez_vous}</Typography>
                    </Box>
                    <Box sx={{position: 'absolute', bottom: 53, left: 90}}>
                        <Typography sx={{textTransform:'none', fontSize: 25, width: 300, letterSpacing: 1, color: COLORS.BG_DARK,  fontFamily: 'FiraGo', backgroundColor: 'rgb(231,222,209)'}}>{translations?.gift_card_banner_title}</Typography>
                    </Box>
                </Box>
            }
                
        </Fade>
        <Divider style={{ marginTop: 30, marginBottom: 30, color: 'white' }} />
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-between' }}>

            <DesktopEGiftCardFormDialog
                {...props}
                options={options}
                eGiftCard={eGiftCard}
                loaded={loaded}
                blackLoaded={blackLoaded}
                createEGiftCard={createEGiftCard}
            />
            <DesktopGiftCardFormDialog
                {...props}
                cities={cities}
                address={address}
                options={options}
                giftCard={giftCard}
                loaded={loaded}
                beigeLoaded={beigeLoaded}
                createGiftCard={createGiftCard}
            />

        </Box>
        {width < 1200 && <Box>
            {eGiftCard && <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Box style={{ display: 'flex', flexDirection: 'column', width: '40%', padding: 10 }}>
                    <Title text={`${translations.e_gift_card_purchase}`} fontSize={18} uppercase={true} fontWeight='600' color={"#444"} style={{ marginBottom: 20 }} />
                    <Fade
                        in={blackLoaded}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(blackLoaded ? { timeout: 2000 } : {})}
                    >
                        {<img src={width > 1200 ? `${environment.basePath}/assets/catalog/product/black.jpeg` : `${environment.basePath}/assets/catalog/product/gift_card_banner.jpg`} style={{ width: '100%' }} />}
                    </Fade>
                    <Title text={`${translations.e_gift_card_purchase_text}`} fontSize={16} uppercase={false} fontWeight='400' color={"#444"} style={{ marginTop: 20 }} />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', width: '65%', backgroundColor: 'transparent', paddingLeft: 20 }}>
                    <EGiftCardForm translations={translations} {...props} options={options}
                        product={eGiftCard}
                        onAddToCart={(values, forUser) => {
                            if (eGiftCard) {
                                createEGiftCard(values, forUser, language)
                            }
                        }}
                    />
                </Box>
            </Box>}
            <Divider style={{ marginTop: 30, marginBottom: 30 }} />
            {initGiftCard && <RenderGiftCardForm />}
        </Box>}


    </Box> : <Box>
        <div style={{
            display: 'flex',
            paddingTop: 10,
            paddingBottom: 10,
            alignItems: 'flex-start', margin: '0px auto'
        }}>
            {width < 600 && (
                <Box sx={{ display: "flex", justifyItems: "flex-start"}}>
                    <ArrowBackIcon
                        onClick={() => history.goBack()}
                        sx={{ color: "black", fontSize: 35, paddingTop: 4 }}
                    />
                </Box>
            )}
            {translations && <BreadCrumbsComponent items={[
                { name: translations?.home, link: '/' },
                { name: translations?.gift_card, link: '/gift_card' },
            ]} />}
        </div>
        <Fade
            in={loaded}
            style={{ transformOrigin: '0 0 0' }}
            {...(loaded ? { timeout: 2000 } : {})}
        >
            {<Box >

                <img src={`${environment.basePath}/assets/catalog/product/gift_banner_mobile.jpeg`} style={{ width: '100%', position: 'relative' }} />
                { width < 600 ? <Box sx={{position: 'absolute', top: 340, left: 5, zIndex: 300}}>
                        <Typography sx={{textTransform:'none', fontSize: 14,  borderRadius: 4, width: 170, letterSpacing: 1, color: COLORS.BG_DARK,  fontFamily: 'FiraGo', backgroundColor: 'rgb(231,222,209)'}}>{translations?.gift_card_banner_title}</Typography>
                    </Box> : width < 1200 && width > 600 ? <Box sx={{position: 'absolute', top: 512, left: 5, zIndex: 300}}>
                        <Typography sx={{textTransform:'none', fontSize: 25,  borderRadius: 4, width: 390, letterSpacing: 1, color: COLORS.BG_DARK,  fontFamily: 'FiraGo', backgroundColor: 'rgb(231,222,209)'}}>{translations?.gift_card_banner_title}</Typography>
                    </Box> : <></>
                    
                }
            </Box>
                }
        </Fade>

        <Divider style={{ marginTop: 30, marginBottom: 30, color: 'white' }} />
        <EGiftCardFormDialog
            {...props}
            language={language}
            options={options}
            eGiftCard={eGiftCard}
            loaded={loaded}
            blackLoaded={blackLoaded}
            createEGiftCard={createEGiftCard}
        />
        <Divider style={{ marginTop: 30, marginBottom: 30 }} />
        <GiftCardFormDialog
            {...props}
            options={options}
            cities={cities}
            address={address}
            giftCard={giftCard}
            beigeLoaded={beigeLoaded}
            createGiftCard={createGiftCard}
        />
    </Box >
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        translations: state.translations,
        language: state.language,
        app: state.app
    }
}

export default connect(mapStateToProps, { setCart, setSnackBar })(GiftCardPage)