import React from "react"
import { connect } from "react-redux"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { MyProfileMenuComponent } from "../components/myprofile/MyProfileMenuComponent"
import { MyProfileContent } from "../components/myprofile/MyProfileContent"
import { useHistory, useParams } from "react-router"
import { setConfirmation, setSnackBar } from "../models/application/applicationActions"
import { logout } from "../models/user/authActions"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Box } from "@mui/material"

const UserProfilePage = (props) => {
    const { translations, auth, language } = props
    const { width } = useWindowDimensions()
    let { page }: any = useParams()
    const history = useHistory()

    return (
        <>
        {width < 600 && page !== "gift_card" && (
                <Box sx={{ display: "flex", justifyItems: "flex-start" }}>
                    <ArrowBackIcon
                        onClick={() => history.goBack()}
                        sx={{ color: "black", fontSize: 35, paddingTop: 4 }}
                    />
                </Box>
            )}
        
        <div style={{ display: "flex", maxWidth: page === "gift_card" ? 1400 : 1200, margin:  '0 auto', width: width }}>
            {width > 600 && <MyProfileMenuComponent language={language} translations={translations} />}
            <MyProfileContent {...props} page={page} auth={auth} />
        </div>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        translations: state.translations,
        language: state.language,
    }
}

export default connect(mapStateToProps, { setConfirmation, setSnackBar, logout })(UserProfilePage)
