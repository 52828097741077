import { Badge, Typography } from "@mui/material";
import React, { useState, } from "react";
import '../styles.scss'

export const MobileLetterBrandSection = (props: any) => {
    const { item, brands, onClick, width = '100%', showLetter = false, index, length } = props
    return <div style={{
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 10,
        display: 'flex',
        marginBottom: index + 1 === length ? 300 : 0, minHeight: 70
    }} id={`ref_${item.id}`} >

        {brands?.filter(b => b.title.indexOf(item.id) === 0).map((brand, index) => {
            return <li style={{ display: 'flex', flexDirection: "row" }} key={index}>
                <Typography
                    variant={"subtitle2"}
                    component={"div"}
                    className="li"
                    key={index}
                    style={{ cursor: 'pointer', color: 'black' }} onClick={() => {
                        onClick(brand)
                    }}>{brand.title}</Typography>
                {brand.isNew === true &&
                    <Badge color="error" badgeContent={'new'} invisible={false} style={{
                        zIndex: 1000,
                        height: 10,
                        marginLeft: 20, marginTop: 10
                    }} />
                }

            </li>
        })}

    </div>
}