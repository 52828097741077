import { MenuItem, Select } from "@mui/material"
import React, { useEffect, useState } from "react"

export const SecondStepMobileTabs = (props) => {
    const { tabs,  setSelected} = props
    const [selectedValue, setSelectedValue] = useState(tabs[0].name)

    return (
        <Select
            value={selectedValue && selectedValue}
            sx={{
                width: "100%",
                marginBottom: 1,
            }}
          
            MenuProps={{
                PaperProps: {
                    sx: {
                        bgcolor: "background.default",
                        "& .MuiMenuItem-root": {
                            padding: 2,
                        },
                    },
                },
            }}
        >
            {tabs?.map((item, index) => (
                <MenuItem
                    key={index}
                    value={item.name}
                    onClick={() => {
                        setSelected(item)
                        setSelectedValue(item.name)
                    }}
                >
                    {item.name}
                </MenuItem>
            ))}
        </Select>
    )
}
