import { ResourceProvider } from "../../providers/resource.provider"
import { Meta } from "./Meta"
import { MetaSerializer } from "./serializer"


export class MetaProvider extends ResourceProvider<Meta> {
    constructor() {
        super("rest_meta_tags", new MetaSerializer())
    }
}

export const metaService = new MetaProvider()

