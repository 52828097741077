import { Badge, Typography } from "@mui/material";
import React, { useState, } from "react";
import '../styles.scss'

export const LetterBrandSection = (props: any) => {
    const { item, brands, onClick, width = '100%', showLetter = true, index, length } = props
    return <div className="lettersection" style={{ flexDirection: 'row', display: 'flex', marginBottom: index + 1 === length ? 30 : 0 }} id={`ref_${item.id}`} >
        {showLetter && <div style={{ width: '10%', flexDirection: "column", backgroundColor: 'white' }}>
            <h4>{item.id}</h4>
        </div>}
        <div style={{ width, flexDirection: "column" }}>
            <ul style={{ width: '100%', padding: 0, textAlign: "left" }} className="colul" >

                {brands?.filter(b => b.title.toLowerCase().indexOf(item.id.toLowerCase()) === 0).map((brand, index) => {
                    return <li style={{ display: 'flex', flexDirection: "row"}} key={index}><Typography
                        variant={"subtitle2"}
                        component={"li"}
                        className="li underline-on-hover"
                        key={index}
                        style={{ cursor: 'pointer', color: 'black', marginRight: 10 }} onClick={() => {
                            onClick(brand)
                        }}>{brand.title}</Typography>
                        {brand.isNew === true && 
                            <Badge color="error" badgeContent={'new'} invisible={false} style={{ zIndex: 1000, 
                                height: 10,
                                marginLeft: 20, marginTop: 10 }} />
                        }

                    </li>
                })}
            </ul>

        </div>
    </div>
}