import React, { useState, useEffect, useRef } from 'react'
import { Box, IconButton, Link, Typography } from "@mui/material"
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Title } from '../Typography/Title';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export const BranchItemMobile = (props) => {
    const { item, onClicked, language } = props
    const iconSize = 20
    const fontSizeSize = 18
    const linkRef = useRef<any>()
    const RenderRows = (item) => {
        if (item) {
            return [
                { text: item.address.street, icon: <LocationOnOutlinedIcon sx={{ fontSize: iconSize }} />, fontSize: 16, uppercase: true, fontWeight: '500' },
                { text: item.phone, icon: <CallOutlinedIcon sx={{ fontSize: iconSize }} />, fontSize: 16, uppercase: false, fontWeight: '300' },
                { text: item.workingHours, icon: <QueryBuilderIcon sx={{ fontSize: iconSize }} />, fontSize: 16, uppercase: false, fontWeight: '300' }
            ]
        } else {
            return []
        }
    }
    return <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%' }}>
        <Box sx={{
            position: 'absolute',
            borderRadius: 40,
            border: '0px solid #444',
            height: 60, width: 60, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            <IconButton onClick={() => linkRef.current.click()}>
            <Link href={`tel:${item.phone}`} ref={linkRef}></Link>
                <LocalPhoneIcon color={props.color} sx={{
                    color: "rgb(100,173,80)", fontSize: 30
                }} fontSize={'medium'} />
            </IconButton>
        </Box>
        {RenderRows(item).map((item, index) => {
            return <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' ,  width: '87%' }}>
                <Box sx={{ width: '10%', alignItems: 'flex-start', justifyContent: '' }}>{item.icon}</Box>
                <Box sx={{ width: '90%', alignItems: 'flex-start', justifyContent: '', display: 'flex', fontSize: 15 }}>
                    <Title
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        text={item.text}
                        fontSize={item.fontSize}
                        uppercase={item.uppercase}
                        className="roboto"
                        fontWeight={item.fontWeight} />

                </Box>
            </Box>
        })}


    </Box>
}