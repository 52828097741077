import React, { useState, useEffect, useCallback } from 'react'
import { Box, Typography } from '@mui/material'
import { Address } from '../../models/address/address'
import { addressService } from '../../models/address/service'
import { QueryOptions } from '../../helpers/query.options'
import { AddressTableItem } from './AddressTableItem'
import { ConfirmationDialog } from '../Confirmation/ConfirmationDialog'
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AddressDialog from '../Dialogs/AddressDialog'
import { COLORS } from '../../constants/colors'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

export const MyAddressesComponent = (props: any) => {
    const { app, setConfirmation, setSnackBar, translations } = props
    const [addresses, setAddresses] = useState<Address[]>([])
    const [address, setAddress] = useState<Address | null>()
    const [dialogOpen, setDialogOpen] = useState(false)

    const {width} = useWindowDimensions()

    useEffect(() => {
        loadAddresses()
    }, [])

    const loadAddresses = () => {
        const options = new QueryOptions()

        addressService.list(options).then((resp: any) => {
            setAddresses(resp.results)
        })
    }

    const onRemoveItem = (item) => {
        if (item) {
            addressService.delete(item?.id).then(r => {
                setSnackBar({ ...app.snackBar, open: true, text: `მისამართი ${item.street} წაშლილია თქვენი კალათიდან.`, bColor: "rgb(83, 148, 96)" })
                setConfirmation({ ...app.confirmation, open: false })
            })
        }
    }

    return <Box sx={{ width: window.innerWidth > 600 ? window.innerWidth > 600 ? '94%' : '90%' : '85%', alignItems: 'flex-start', display: 'flex', padding: 2, flexDirection: 'column' }}>
        <AddressDialog
            {...props}
            translations={translations}
            onReload={loadAddresses}
            item={address}
            onOpen={dialogOpen} onCloseDialog={(resp) => {
                setDialogOpen(resp)
            }} />
        <ConfirmationDialog
            translations={translations}
            open={app.confirmation.open}
            title={app.confirmation.title}
            text={app.confirmation.text}
            setOpen={() => {
                setConfirmation({ ...app.confirmation, open: false })
            }}
            textAccept={app.confirmation.textAccept}
            onAgreed={() => {
                app.confirmation.onAgreed(app.confirmation)
            }} />
              {width < 600 && <Button variant="outlined"
                sx={{
                    width: '100%',
                    marginBottom: 2,
                    color: 'black',
                    border: '1px solid black',
                }}
                onClick={() => {
                    setAddress(null)
                    setDialogOpen(true)
                }}
                endIcon={<AddIcon />}>
                {translations.add_new_address}
            </Button>
            }
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'space-between', justifyContent: 'space-between', width: window.innerWidth > 600 ? '94%' : '100%' }}>
            <Typography variant="subtitle1" component="h5" style={{ fontWeight: 'bold' }}>{translations?.addresses}</Typography>
          {width > 600 &&  <Button variant="outlined"
                sx={{
                    marginLeft: window.innerWidth > 600 ? 0 : 2,
                    color: 'black',
                    border: '1px solid black',
                    "&:hover": {
                        backgroundColor: COLORS.BG_BEIGE,
                        borderColor: `black`,
                        color: 'black'
                    }
                }}
                onClick={() => {
                    setAddress(null)
                    setDialogOpen(true)
                }}
                endIcon={<AddIcon />}>
                {translations.add_new_address}
            </Button>}

        </div>
        <Box sx={{ paddingTop: 2, width: '100%' }}>
            {addresses.map((address, index) => {
                return <AddressTableItem item={address} key={index} onRemove={(item) => {
                    setConfirmation({
                        open: true,
                        item,
                        type: 'removeItemFromCart',
                        textAccept: "წავშალოთ",
                        text: "ნამდვილად გსურთ მისამართს წაშლა?",
                        title: item.street,
                        onAgreed: () => onRemoveItem(item)
                    })

                }} onEdit={(address) => {
                    setAddress(address)
                    setDialogOpen(true)
                }} />
            })}
        </Box>

    </Box>
}