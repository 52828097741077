import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { Box, Button, Dialog } from '@mui/material'
import { useHistory } from "react-router";
import { QueryOptions } from "../../helpers/query.options";
import { generateFiltersFromUrl, generateParamsFromUrl, generateParamsFromUrlBySet, generateUrlForBrand } from "../../helpers/utilities";
import { filterService } from "../../models/filter/service";
import { priceRangeService } from "../../models/priceRange/service";
import { PriceRange } from "../../models/priceRange/PriceRange";
import { environment } from "../../environment/environment";
import { FilterAccordionItem } from "../Filter/FilterAccordionItem";
import { PriceSlider } from "../Filter/PriceSlider";
import { COLORS } from "../../constants/colors";
import { FilterAccordionItemColor } from "../Filter/FilterAccordionItemColor";
import { FilterChips } from "../Filter/FIlterChips";
import { attributeSetService } from "../../models/attribute/service";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

const FilterDialogForBrand = (props) => {
    const {
        onCloseDialog,
        language,
        queryParams,
        location,
        filters,
        filterNames,
        translations,
        setFilters,
        brands,
        setFilterItem,
        filterItem,
        sort,
        onSetSort } = props
    const history = useHistory()
    const [selectedFilters, setSelectedFilters] = useState<any[]>([])
    const [filterItems, setFilterItems] = useState<any>()
    const [cat, setCat] = useState("")
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(36)
    const [pageUrl] = useState('catalog')
    const [order, setOrder] = useState("desc")
    const [search, setSearch] = useState("")
    const [url, setUrl] = useState("")
    const [child, setChild] = useState("")
    const [parent, setParent] = useState("")
    const [options, setOptions] = useState<any>(new QueryOptions())
    const [cachedObj, setCachedObject] = useState<any>()
    const [loaded, setLoaded] = useState(false)
    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(1000)
    const [minGlobal, setMinGlobal] = useState(0)
    const [maxGlobal, setMaxGlobal] = useState(1000)
    const [attributeSet, setAttributeSet] = useState<any>()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [items, setItems] = useState<any[]>([])

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        onSetSort(items[index])
        setSelectedIndex(index);
        setAnchorEl(null);
    };



    useEffect(() => {
        if (filterItem) {
            handleOnChange(filterItem)
        }
    }, [filterItem])

    useEffect(() => {
        if (queryParams?.brand && props.open) {
            if (filterNames?.length) {
                const [brand] = brands
                loadFilters({ attributes: filterNames }, queryParams)
            } else {
                queryParams.lang = language
                attributeSetService.read("brand", queryParams).then(as => {
                    const { attributes } = as
                    setAttributeSet({ attributes })
                    loadFilters({ attributes: as.attributes }, queryParams)
                })
            }
        }
    }, [JSON.stringify(queryParams), props.open])

    useEffect(() => {
        if (location.search?.length && props.open) {
            const options = generateParamsFromUrl(location.search)
            if (options.brand) {
                const [brand] = brands
                attributeSetService.read("brand", options).then(as => {
                    const options = generateParamsFromUrlBySet(location.search, as.attributes.map(r => r.id))
                    loadFilters(as, options)
                })
            }
        }
    }, [location.search, props.open])

    useEffect(() => {
        if (filterNames?.length) {
            const options = generateParamsFromUrl(location.search)
            loadFilters({ attributes: filterNames }, options)
        } else {
            attributeSetService.read("brand", options).then(as => {
                loadFilters(as, queryParams)
            })
        }

    }, [JSON.stringify(queryParams)])


    const getFilters = async () => {
        const prms = await generateFiltersFromUrl(location, language)

        const [brand] = brands
        prms.brand = brand
        if (prms) {
            setFilters(prms.current)
            setSelectedFilters(prms.current)
        } else {
            setFilters([])
        }
    }

    useEffect(() => {
        getFilters()
    }, [location])

    const loadFilter = (id, options, as) => {
        const opts = new QueryOptions()
        opts.filter = id
        opts.lang = language
        opts.brand = options.brand
        as.forEach(p => {
            if (options[p.id] && options[p.id].length) {
                opts[p.id] = options[p.id]
            }
        })
        opts.min_price = options.min_price
        opts.max_price = options.max_price

        filterService.list(opts).then((r: any) => {
            if (cachedObj?.id !== id) {
                setFilterItems(filt => ({ ...filt, [id]: r.results }))
            }
        })
    }

    const loadFilters = (attributeSet, options) => {
        if (filterNames?.length) {
            filterNames.forEach((attr, index) => {
                if (options.brand) {
                    loadFilter(attr.id, options, attributeSet.attributes)
                }
            })
        } else {
            attributeSet?.attributes.forEach((attr, index) => {
                if (options.brand) {
                    loadFilter(attr.id, options, attributeSet.attributes)
                }
            })
        }
        priceRangeService.listArray(options).then((r: PriceRange) => {
            setMinPrice(r.minPrice)
            setMaxPrice(r.maxPrice)
            setMinGlobal(r.minGlobal)
            setMaxGlobal(r.maxGlobal)
        })
    }

    const handleOnChange = (item: any) => {
        let arr: any = [];
        if (selectedFilters.map(i => String(i.id)).indexOf(item.id) > -1) {
            arr = [...selectedFilters.filter(i => i.id !== item.id)]
        } else {
            arr = [...selectedFilters.filter(i => i.id !== item.id), item]
        }

        if (filterItems && filterItems[item?.name?.id].length > 1) {
            setCachedObject({ id: item.name.id, items: filterItems[item?.name?.id] })
        }
        setPageIndex(1)
        setSelectedFilters(arr)
        reloadQueryOptions(arr, 1, [0, 10000])
    }

    const showFilter = (id, obj) => {
        if (!obj) {
            return true
        }
        if (!obj[id]) {
            return false
        }
        if (obj[id].length) {
            return true
        }
    }

    const getFilterList = () => {
        if (filterNames?.length) {
            return filterNames
        }
        return attributeSet?.attributes ? attributeSet?.attributes : []
    }

    const reloadQueryOptions = (selectedFilters: any, pageIndex, min_max_price) => {
        const min_price = min_max_price[0]
        const max_price = min_max_price[1]
        const options: any = generateParamsFromUrl(location.search)
        options.init = false
        setOptions(options)
        options.brand = brands.map(b => b.id).toString()
        const url = generateUrlForBrand(
            options,
            environment.params,
            selectedFilters,
            `${location.pathname}`,
            pageIndex,
            pageSize,
            sort,
            order,
            search,
            min_price, max_price, ""
        )
        if (url.length) {
            
            history.push(url);
            setUrl(url);
        } else {
            if (loaded) {
                history.push(`/${pageUrl}`);
            }
        }
    }

    return <Dialog
        maxWidth='md'
        style={{
            padding: 0, minWidth: window.innerWidth > 1200 ? 1200 : '100%',
            zIndex: 100000,
            height: window.innerHeight + 64, marginTop: -32, overflow: 'hidden'
        }}
        open={props.open}
        onClose={onCloseDialog}
        aria-labelledby="form-dialog-title" >
        <div style={{ position: 'absolute', right: window.innerWidth * .03, top: 40, cursor: 'pointer' }} onClick={() => {
            onCloseDialog(false)
        }}>
            <CloseIcon />
        </div>
        <Box sx={{
            padding: 2,
            width: window.innerWidth > 1200 ? 800 : window.innerWidth - 32,
            marginTop: 4,
            height: window.innerWidth > 1200 ? 400 : window.innerHeight,
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            
            <Box>
                <Divider style={{ marginBottom: 5, marginTop: 5 }}> <Chip label={translations.filter} /></Divider>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }}>

                <FilterChips
                    filters={filters.filter(f => f.name.id !== 'brand')}
                    handleDelete={(item) => {
                        setFilterItem(item)
                    }}
                />
            </Box>

            <Box sx={{ minHeight: '80%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Box sx={{ minHeight: '80%', overflowY: 'scroll' }}>

                    {translations?.price &&
                        getFilterList().filter(e => showFilter(e.id, filterItems)).map((item, index) => {
                            return item.id !== 'color_family' ? <FilterAccordionItem
                                selectedFilters={selectedFilters}
                                items={selectedFilters}
                                key={index}
                                onClick={handleOnChange}
                                item={item}
                                filter={filterItems}
                                {...props} />
                                :
                                <FilterAccordionItemColor
                                    selectedFilters={selectedFilters}
                                    key={index}
                                    onClick={handleOnChange}
                                    item={item} filter={filterItems} {...props}
                                />
                        })}
                    <Box style={{ padding: 10 }}>
                        <PriceSlider
                            min={minPrice}
                            max={maxPrice}
                            minGlobal={minGlobal}
                            maxGlobal={maxGlobal}
                            reloadQueryOptions={(arr, index, maxPrice) => {
                                reloadQueryOptions(arr, index, maxPrice)
                            }}
                        />

                    </Box>
                </Box>

            </Box>
                <Box style={{ width: '100%', height: 150, justifyContent: 'center', alignItems: 'center', display: 'flex', }}>
                    <Button onClick={() => onCloseDialog(false)} sx={{
                        backgroundColor: 'gray',
                        color: 'white',
                        width: 260,
                        height: 50,
                        position: 'absolute',
                        top: '88%',
                        '&:hover': {
                            color: 'white',
                            backgroundColor: COLORS.BG_GRAY_PURPLE,
                        }
                    }}>
                        {translations?.close}
                    </Button>
                </Box>
        </Box>
    </Dialog>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations
    }
}

export default connect(mapStateToProps, { setSnackBar })(FilterDialogForBrand)