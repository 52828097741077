import { Box, Button } from '@mui/material';
import React, { } from 'react';
import { COLORS } from '../../constants/colors';
import FacebookIcon from '@mui/icons-material/Facebook';

export const FacebookButton = (props) => {
    const { onClick, title, type = "inherit" } = props
    return <Button
        sx={[{
            '&:hover': {
                color: 'white',
                backgroundColor: 'rgb(48,108,220)',
            },
        }, {
            width: '90%',
            fontWeight: 'bold',
            borderRadius: 2,
            height: 50,
            backgroundColor: 'rgb(48,108,220)',
            color: 'white',
            maxWidth: 400
           
        }]}
        disableRipple={true}
        disableFocusRipple={false}
        color={"secondary"}
        type={type}
        onClick={onClick}
    >
        <Box style={{marginTop: 6, marginRight: 10}}>
            <FacebookIcon style={{top: 2}}/>
        </Box>
        {title}
    </Button>
}