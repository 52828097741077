import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material"
import { MainPageProductBanner } from '../../models/MainPageBrandBanner/MainPage';
import { QueryOptions } from '../../helpers/query.options';
import { mainPageProductBannerService } from '../../models/MainPageBrandBanner/service';
import { MainPageProductBannerComponent } from './MainPageProductBannerComponent';
import { MobileMainPageProductBannerCarousel } from './MobileMainPageProductBannerCarousel';
import Skeleton from "@mui/material/Skeleton/Skeleton";

export const MainPageProductBanners = (props: any) => {
    const { language } = props
    const [sections, setSections] = useState<MainPageProductBanner[]>([])
    const [loadStatus, setLoadStatus] = useState(true)
    useEffect(() => {
        loadProductSection()
    }, [])
    const loadProductSection = () => {
        const options = new QueryOptions()
        options.lang = language
        mainPageProductBannerService.list(options).then((r: any) => {
            setSections(r.results)
            setLoadStatus(false)
        })
    }

    return loadStatus ? <Box sx={{ width: 1200, height: window.innerWidth > 1200 ? 570 : 340, alignItems: 'flex-start', display: 'flex', padding: 3 }}>
        <Skeleton style={{ width: 1200, height: window.innerWidth > 1200 ? 570 : 340, }} ></Skeleton>
    </Box> :

        sections ? window.innerWidth > 1200 ? <Box sx={{ width: 1200, height: window.innerWidth > 1200 ? 570 : 340, alignItems: 'flex-start', display: 'flex', padding: 3 }}>
            {sections.map((item, index) => {
                return <MainPageProductBannerComponent
                    {...props}
                    key={index}
                    item={item}
                />
            })}
        </Box> : <Box style={{ width: window.innerWidth, marginTop: 0 }}>
            <MobileMainPageProductBannerCarousel items={sections} />
        </Box> : <></>
}