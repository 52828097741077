import { cartItemSerializer } from "../cart/serializer"
import { Serializer } from "../serializer"
import { Campaign, Coupon, LoyaltyCard, TimeLeft } from "./Coupon"


export class CouponSerializer implements Serializer {
    fromJson(json: any) {
        const coupon = new Coupon()
        coupon.isValid = json.is_valid
        coupon.name = json.name
        coupon.products = json.products.map(item => cartItemSerializer.fromJson(item))
        coupon.id = json.id
        coupon.campaign = campaignSerializer.fromJson(json.campaign)
        return coupon
    }
    toJson(item: Coupon) {
        return {
        }
    }
}
export class LoyaltyCardSerializer implements Serializer {
    fromJson(json: any) {
        const discount = new LoyaltyCard()
        discount.products = json.products.map(item => cartItemSerializer.fromJson(item))
        discount.discount = json.discount;
        return discount
    }
    toJson(item: LoyaltyCard) {
        return {
        }
    }
}

export class CampaignSerializer implements Serializer {
    fromJson(json: any) {
        const campaign = new Campaign()
        campaign.currentTimeCaption = json.current_time_caption
        campaign.description = json.description
        campaign.name = json.name
        campaign.timeLeft = json.time_feft;
        campaign.type = json.type
        campaign.isGiftCard = json.is_gift_card
        campaign.freeShipping = json.free_shipping
        campaign.validFrom = json.valid_from
        campaign.validTo = json.valid_to
        campaign.value = json.value
        return campaign
    }
    toJson(item: Coupon) {
        return {
        }
    }
}
export class TimeLeftSerializer implements Serializer {
    fromJson(json: any) {
        const item = new TimeLeft()
        item.days = json.days
        item.hours = json.hours
        item.minutes = json.minutes
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}

export const couponSerializer = new CouponSerializer()
export const campaignSerializer = new CampaignSerializer()
export const timeLeftSerializer = new TimeLeftSerializer()