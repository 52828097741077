

import React, { useEffect } from 'react';
import { Box } from "@mui/material"
import { Title } from '../Typography/Title';
import { useHistory } from 'react-router';
import "../../App.css"

export const MainPageProductBannerComponent = (props: any) => {
    const { item } = props
    const history = useHistory()
    useEffect(() => {
    }, [])
    return item ? <Box sx={{ width: 1200, alignItems: 'center', display: 'flex', padding: 3, paddingTop: 10, flexDirection: 'column', cursor: 'pointer' }}
        onClick={() => {
            history.push(`/product/${item.product.id}`)
        }}
    >
        <Box style={{width: '100%',height: (1200 - 80)/ 2 / 1.5, overflow: 'hidden'}}>
            <img src={item.image} style={{ width: '100%', height: '100%'}} className="hoverZoom"/>

        </Box>
        <Box style={{ paddingTop: 20 }}>
            <Title text={`${item.product.brand.title}`} fontSize={15} uppercase={true} fontWeight='600' color={"black"} />
        </Box>
        <Box style={{ paddingTop: 10 }}>
            <Title text={`${item.product.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"black"} />
        </Box>
        <Box style={{ paddingTop: 10 }}>
        {item.product?.specialPrice && item.product.specialPrice < item.product.price ? <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <Title text={`${item.product ? item.product.price: item.product.price}₾`} fontSize={22} uppercase={true} fontWeight='400' color={"black"} style={{ marginRight: 5, textDecorationLine: 'line-through' }} />
                    <Title text={`${item.product ? item.product.specialPrice: item.product.specialPrice}₾`} fontSize={22} uppercase={true} fontWeight='400' color={"red"} />
                </div> : <Title text={`${item.product? item.product.price: item.product.price} ₾`} fontSize={22} uppercase={true} fontWeight='400' fontFamily='Roboto' color={"#000"} className="roboto" />}
        </Box>
    </Box> : <></>
}