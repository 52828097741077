import React from "react"
import { Box, Typography } from "@mui/material"
import { useWindowDimensions } from "../../hooks/useWindowDimensions"

export const KeyNoteComponent = (props) => {
    const { language, card } = props
    const {width} = useWindowDimensions()

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: width > 600? "350px" : '90%',
            }}
        >
            <img src={card?.image} />
            <Typography
                sx={{ textAlign: "left", paddingBottom: 2, fontFamily: language === "geo" ? "FiraGo" : "Roboto" }}
            >
               {card?.title}
            </Typography>
            <Typography sx={{ textAlign: "left", fontFamily: language === "geo" ? "FiraGo" : "Roboto" }}>
               {card?.text}
            </Typography>
        </Box>
    )
}
