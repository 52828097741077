

import React, { useState, useEffect } from 'react';
import { Box, Button } from "@mui/material"
import { mainPageProductSectionWithBGService } from "../../models/MainPageBrandBanner/service"
import { QueryOptions } from "../../helpers/query.options";
import { MainPageProductSectionWithBG } from '../../models/MainPageBrandBanner/MainPage';
import { COLORS } from '../../constants/colors';
import { useHistory } from 'react-router';

export const ProductSectionWithBG = (props: any) => {
    const {language} = props
    const [section, setSection] = useState<MainPageProductSectionWithBG>()
    const history = useHistory()
    useEffect(() => {
        loadProductSection()
    }, [])
    const loadProductSection = () => {
        const options = new QueryOptions()
        options.lang = language
        mainPageProductSectionWithBGService.readWithNoId(options).then((section: MainPageProductSectionWithBG) => {
            setSection(section)
        })
    }
    return section ? window.innerWidth > 1200 ? <Box sx={{ width: 1200, height: 340, alignItems: 'flex-start', display: 'flex', padding: 3, paddingTop: 10 }}>
        <div style={{ display: 'flex', width: '100%', height: 280, backgroundColor: COLORS.BG_BEIGE, position: 'relative' }}>
            <div style={{ padding: 0, position: 'absolute', height: 360, width: 360, left: 100, top: -40, border: `2px solid ${COLORS.BG_GRAY_I}` }} >
                <img src={section.image} style={{ width: '100%' }} />
            </div>
            <div style={{ display: 'flex', width: '40%', height: '100%', alignItems: 'center', flexDirection: 'column', padding: 10 }}>
            </div>
            <div style={{ display: 'flex', width: '50%', height: '100%', alignItems: 'center', flexDirection: 'column', padding: 10 }}>
                <p style={{ backgroundColor: 'white' }} ></p>
                <h2 style={{ color: COLORS.DARK_PURPLE }} className="uppercase">{section.title}</h2>
                <h4 style={{ color: COLORS.DARK_PURPLE, marginTop: -5 }} className="uppercase">{section.text}</h4>
                <Button 
                    onClick={() => {
                        history.push(section.url)
                    }}
                sx={[{
                    '&:hover': {
                        color: 'white',
                        backgroundColor: COLORS.BG_GRAY_PURPLE,
                    },
                }, { backgroundColor: COLORS.BG_GRAY_PURPLE, color: 'white', }]}>{section.buttonText}</Button>
            </div>
        </div>

    </Box> : <Box style={{ width: window.innerWidth, height: window.innerWidth + 260, display: 'flex', justifyContent: 'center', paddingTop: 20 }}>
        <Box style={{ border: `2px solid ${COLORS.BG_GRAY_I}`, width: window.innerWidth - 40, height: window.innerWidth - 40 }} >
            <img src={section.image} style={{ width: '100%' }} />
            <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', flexDirection: 'column', padding: 10 }}>
                <p style={{ backgroundColor: 'white' }} ></p>
                <h2 style={{ color: COLORS.DARK_PURPLE }} className="uppercase">{section.title}</h2>
                <h4 style={{ color: COLORS.DARK_PURPLE, marginTop: -5 }} className="uppercase">{section.text}</h4>
                <Button
                    onClick={() => {
                        history.push(section.url)
                    }}
                    sx={[{
                        '&:hover': {
                            color: 'white',
                            backgroundColor: COLORS.BG_GRAY_PURPLE,
                        },
                    }, { backgroundColor: COLORS.BG_GRAY_PURPLE, color: 'white', }]}>{section.buttonText} </Button>
            </div>
        </Box>

    </Box> : <></>
}