import { Box } from "@mui/material"
import React from "react"
import { Title } from "../Typography/Title"
import StarRateIcon from '@mui/icons-material/StarRate';

export const ReviewSummaryComponent = (props) => {
    const { summary, translations } = props
    const getCount = (count) => {
        const [item] = summary?.summary?.filter(s => s.id == count)
        if (item) {
            return item.count
        } else {
            return 0
        }
    }
    const getPercentage = () => {
        const items = summary?.summary?.filter(s => s.id > 3)
        const count = items.reduce((total, item) => {
            return total += item.count
        }, 0);
        return ((count / summary.count) * 100).toFixed(0)
    }

    return summary && summary.summary ?
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingBottom: 4 }}>
            <Title text={`${translations.rating}`}
                style={{ alignSelf: 'left', marginBottom: 20 }}

                fontSize={18} uppercase={true} fontWeight='500' color={"black"} />
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', minWidth: 150 }}>
                    {[1, 2, 3, 4, 5].reverse().map((item, index) => {
                        return <Box
                            key={index}
                            sx={{ width: '100%', borderRadius: 5, height: 20, display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                            <Box sx={{ width: '10%', flexDirection: 'column' }}>{item}</Box>
                            <Box sx={{ width: '80%', borderRadius: 5, height: 15, display: 'flex', flexDirection: 'column', backgroundColor: '#999', position: 'relative' }}>
                                <Box sx={{ width: `${getCount(item) / summary?.count * 100}%`, borderRadius: 5, height: 15, display: 'flex', flexDirection: 'column', backgroundColor: '#444', position: 'absolute', top: 0, left: 0 }}></Box>
                            </Box>
                            <Box sx={{ width: '10%', flexDirection: 'column' }}>{getCount(item)}</Box>
                        </Box>
                    })}
                </Box>
                <Box sx={{ width: window.innerWidth > 1200 ? '10%' : '30%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', paddingBottom: 2, paddingTop: 0, }}>
                    <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                        {summary?.averate && <Title text={summary?.average?.toFixed(0)}
                            style={{ alignSelf: 'left', position: 'absolute', top: -30, right: 15 }}
                            fontSize={22} uppercase={true} fontWeight='500' color={"black"} />}
                        <div style={{ position: 'absolute', top: -30, right: -5 }}>
                            <StarRateIcon fontSize="large" />
                        </div>
                        <Title text={translations?.review_single}
                            style={{ alignSelf: 'left', position: 'absolute', top: -0, right: -20 }}
                            fontSize={15} uppercase={false} fontWeight='500' color={"#444"} />
                    </Box>
                    <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                        <Title text={`${getPercentage()} % `}
                            style={{ alignSelf: 'left', position: 'absolute', top: -25, right: -20 }}
                            fontSize={22} uppercase={true} fontWeight='500' color={"black"} />
                        <Title text={translations?.recommended}
                            style={{ alignSelf: 'left', position: 'absolute', top: 5, right: -70 }}
                            fontSize={15} uppercase={false} fontWeight='500' color={"#444"} />
                    </Box>
                </Box>
            </Box>
        </Box> : <></>
}