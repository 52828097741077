import React, { useCallback, useState } from "react"
import { Box, Stack } from "@mui/material"
import { User } from '../../assets/icons/User';
import { makeStyles } from "@mui/styles";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { environment } from "../../environment/environment";
import { FacebookButton } from "../Button/FacebookButton";
import { loginFacebook } from "../../models/user/service";
import { User as UserModel } from "../../models/user/user";
import { SMSDialog } from "../Dialogs/SMSDialog";
import './user.scss'
import { checkAccountRoute } from "../../helpers/utilities";

export const AccountPopup = (props: any) => {
    const { auth, redirect, onLogout, translations, setSnackBar, app, login, onLogin } = props
    const [smsDialogType, setSMSDialogType] = useState("")
    const [user, setUser] = useState<UserModel>()
    const [smsDialogOpen, setSmsDialogOpen] = useState(false)
    
    const [items] = useState([
        { name: translations?.my_profile, url: '/account/profile' },
        { name: translations?.my_orders, url: '/account/orders' },
        { name: translations?.my_addresses, url: '/account/addresses' },
        { name: translations?.my_loyalty_card, url: '/account/loyalty_card' }
    ])
    const classes = useStyles();
    
    return (
        <Stack sx={{ width: '100%', alignSelf: 'center' }}>
            
            <div style={{ padding: 0, }}>
                <ul style={{ listStyle: 'none', alignItems: 'flex-start', textAlign: 'left', paddingLeft: 10, paddingTop: 5 }}>
                    {auth.loggedIn && <li
                        style={{ color: 'black', fontFamily: 'FiraGo', fontSize: 14, fontWeight: '300' }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <User width={20} height={27} />
                            <div style={{ display: 'flex', flexDirection: 'column', padding: 2, paddingLeft: 8 }}>{auth?.user?.fullName}</div>
                        </div>
                    </li>}
                    {!auth.loggedIn &&
                        <ul style={{ listStyle: 'none', alignItems: 'flex-start', textAlign: 'left', paddingLeft: 0, paddingTop: 5 }}>
                            <li
                                style={{ color: 'black', fontFamily: 'FiraGo', fontSize: 15, fontWeight: '300' }}>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <div className="underline-on-hover" style={{ display: 'flex', flexDirection: 'column', padding: 5, cursor: 'pointer' }} onClick={() => { redirect('/login') }}>
                                        {translations?.login}
                                    </div>
                                </div>
                            </li>
                            <li
                                style={{ color: 'black', fontFamily: 'FiraGo', fontSize: 15, fontWeight: '300' }}>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <div className="underline-on-hover" style={{ display: 'flex', flexDirection: 'column', padding: 5, cursor: 'pointer' }} onClick={() => { redirect('/register') }}>
                                        {translations?.registration}
                                    </div>
                                </div>
                            </li>
                        </ul>}
                    {auth.loggedIn &&
                        <ul style={{ listStyle: 'none', alignItems: 'flex-start', textAlign: 'left', paddingLeft: 10, paddingTop: 5 }}>
                            {items.map((item, index) => {
                                console.log(item)
                                return <li
                                    key={index}
                                    className={classes.listItem}>
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <div className="underline-on-hover" style={{ display: 'flex', flexDirection: 'column', padding: 5, cursor: 'pointer' ,fontSize: 15 }} onClick={() => { redirect(checkAccountRoute(item.url)) }}>
                                            {item.name}
                                        </div>
                                    </div>
                                </li>
                            })}
                            {auth.loggedIn && <li
                                style={{ color: 'black', fontFamily: 'FiraGo', fontSize: 15, fontWeight: '300' }}>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <div className="underline-on-hover" style={{ display: 'flex', flexDirection: 'column', padding: 5, cursor: 'pointer' }} onClick={() => {
                                        onLogout()
                                    }}>{translations?.logout}</div>
                                </div>
                            </li>}
                        </ul>
                    }
                </ul>
            </div>
        </Stack>
    )
}
const useStyles = makeStyles({
    listItem: { color: 'black', fontFamily: 'FiraGo', fontSize: 12, fontWeight: '300' }

});
