import { Serializer } from "../serializer"
import { GiftCardImages, GiftCardSecondStepTabs, GiftCardOrder } from "./giftcardOrder"

export class GiftCardSecondStepTabSerializer implements Serializer {
    fromJson(json: GiftCardSecondStepTabs) {
        const item = new GiftCardSecondStepTabs()
        item.label = json.label
        item.category = json.category
        return item
    }
    toJson(item: any) {
        const obj = {}
        return obj
    }
}

export class GiftCardImageSerializer implements Serializer {
    fromJson(json: GiftCardImages) {
        const item = new GiftCardImages()
        item.category = json.category
        item.images = json.images
        return item
    }
    toJson(item: any) {
        const obj = {}
        return obj
    }
}

export class GiftCardOrderSerializer implements Serializer {
    fromJson(json: any) {
        const item = new GiftCardOrder()
        item.message = json.message
        item.amount = json.amount
        item.recipientEmail = json.recipient_email
        item.recipientName = json.recipient_name
        item.senderName = json.sender_name
        item.id = json.id
        return item
    }
    toJson(item: GiftCardOrder) {
        const obj = {
            recipient_name: item.recipientName,
            recipient_email: item.recipientEmail,
            payment_method: "card_bog",
            sender_name: item.senderName,
            lang: item.lang,
            template: item.template,
            amount: item.amount,
            message: item.message}
        return obj
    }
}
