import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid';
import '../styles.scss'
import { getWindowDimensions } from '../../helpers/utilities';
import { COLORS } from '../../constants/colors';
import { makeStyles } from '@mui/styles';
import { GraySearchInput } from '../Search/SearchInput';
import { PhoneNumber } from '../General/PhoneNumber';
import { environment } from '../../environment/environment';
import HomeIcon from '@mui/icons-material/Home';
import HomeIconOutlined from '@mui/icons-material/HomeOutlined';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import PersonIconOutlined from '@mui/icons-material/PersonOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useHistory, useLocation } from 'react-router';
import { GiftCard } from '../../assets/icons/GiftCard';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import { SocialLinks } from './SocialLinks';
import { FooterLinksComponent } from './FooterLinksComponent';
import { setSnackBar } from '../../models/application/applicationActions';
import { QueryOptions } from '../../helpers/query.options';
import { infoPageService } from '../../models/InfoPage/service';
import { InfoPage } from '../../models/InfoPage/InfoPage';

const Footer = (props) => {
    const { loggedIn, login, LOGOUT, setSnackBar } = props?.auth
    const { translations, language } = props
    const history = useHistory()
    const location = useLocation();
    const [menuItemLinks, setMenuItemLinks] = useState<InfoPage[]>([])
    const classes = useStyles();

    const loadInfoPages = async (): Promise<any> => {
        
        const options = new QueryOptions()
        options.sort = 'position'
        options.order = 'asc'
        options.lang = language
        const pages = await infoPageService.listWithCounter(options)
        setMenuItemLinks([...pages.current].reduce((result, item) => {
            const { id, link, title, section } = item;
            if (section === "about_us") {
              result.left = {
                title: translations?.about_us,
                list: [
                  ...result.left?.list,
                  { id, title, url: link },
                ],
              };
            } else if (section === "my_profile") {
              result.middle = {
                title: translations?.my_profile,
                list: [
                  ...result.middle?.list,
                  { id, title, url: link },
                ],
              };
            } else if (section === "help") {
              result.right = {
                title: translations?.help,
                list: [
                  ...result.right?.list,
                  { id, title, url: link },
                ], 
              };
            }
          
            return result;
          }, { left: { title: "", list: [] }, middle: { title: "", list: [] }, right: { title: "", list: [] } }))
    }

    useEffect(() => {
        loadInfoPages()
    },[translations])





    return getWindowDimensions().width > 1200 ? <footer className="container" style={{ backgroundColor: COLORS.BG_DARK }}>
        <div className="inner-container">
            {menuItemLinks && 
            <Grid container style={{ flex: 1, height: 300 }} spacing={1}>
                {Object.keys(menuItemLinks).map((item, index) => {
                    return <FooterLinksComponent key={index} item={item} content={menuItemLinks}/>
                })}
                <Grid item lg={3.75} sm={3}>
                    <div style={{ padding: 10, textAlign: 'left' }}>
                        <div
                            className="uppercase"
                            style={{
                                marginBottom: 10,
                                color: 'white', fontFamily: 'FiraGo', fontSize: 15, fontWeight: '500', alignSelf: 'flex-start'
                            }}>
                            {translations?.subscription}
                        </div>
                        <GraySearchInput setSnackBar={setSnackBar} {...props} />
                    </div>
                    <div style={{ padding: 10, textAlign: 'left' }}>
                        <div
                            className="uppercase"
                            style={{
                                marginBottom: 5,
                                color: 'white', fontFamily: 'FiraGo', fontSize: 15, fontWeight: '500', alignSelf: 'flex-start'
                            }}>
                            {translations?.contact}
                        </div>
                        <PhoneNumber
                            left={25}
                            radius={28}
                            fontSize={16}
                            iconSize={30}
                            height={30}
                            color={'white'}
                            phoneNumber={environment.company.phoneNumber} />
                    </div>
                </Grid>
            </Grid>}
        </div>
        <div className="inner-container" style={{ paddingBottom: 10 }}>
            <Box  sx={{
                display: 'flex', 
                flexDirection: 'row', 
                borderTop: '1px solid white', 
                width: '94%', 
                justifyContent: 'space-between'}}>

               <div style={{ display:'flex', flexDirection: 'row', gap: 25}}>
                <div style={{ padding: '14px 14px 14px 0px', }}>
                    <li className="underline-on-hover"
                        style={{
                            cursor: 'pointer',
                            color: 'white', fontSize: 12, fontWeight: 'bold'
                        }} onClick={() => {
                            history.push('/privacy_policy')
                        }}>
                        {translations?.confidentiality}
                    </li>
                </div>
                        
                        
                <div style={{  padding: '14px 14px 14px 0px', }}>
                    <li className="underline-on-hover"
                        style={{
                            cursor: 'pointer',
                            color: 'white', fontSize: 12, fontWeight: 'bold'
                        }} onClick={() => {
                            history.push('/terms_and_rules')
                        }}>
                        {translations?.rules_and_terms}
                    </li>
                </div>
                        
                <div style={{   padding: '14px 14px 14px 0px', }}>
                    <li className="underline-on-hover"
                        style={{
                            cursor: 'pointer',
                            color: 'white', fontSize: 12, fontWeight: 'bold'
                        }} onClick={() => {
                            history.push('/info_pages/site_map')
                        }}>
                        {translations?.site_map}
                    </li>
                </div>

                </div>         
                    <SocialLinks />

                    <div style={{padding: '10px 0px 10px 10px'}}>
                        <span style={{ color: 'white', fontSize: 12, fontWeight: 'bold' }}>
                            © {translations?.copyright}
                        </span>
                    </div>
            </Box>
        </div>

    </footer> :
        <React.Fragment>

            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white', zIndex: 1000 }} elevation={0}>
                <Toolbar sx={{ boxShadow: '0px 0px 10px rgba(0,0,0,.2)', }}>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton color="inherit" aria-label="open drawer" onClick={() => {
                        history.push("/")
                    }}>
                        {location?.pathname === "/" ? <HomeIcon sx={{ color: 'black', fontSize: 30 }} /> : <HomeIconOutlined sx={{ color: 'black', fontSize: 30 }} />}

                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton color="inherit" aria-label="open drawer" onClick={() => {
                        history.push("/gift_card")
                    }}>
                        <Box style={{ marginTop: -2 }}>
                            <GiftCard width={24} height={35} />

                        </Box>
                    </IconButton>

                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton color="inherit" aria-label="open drawer" onClick={() => {
                        history.push("/catalog/special_offers")
                    }}>
                        {
                            location.pathname.indexOf("/catalog/") > -1 ?
                                <LoyaltyIcon style={{ color: 'black', fontSize: 30 }} /> :
                                <LoyaltyOutlinedIcon style={{ color: 'black', fontSize: 30 }} />
                        }
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton color="inherit" aria-label="open drawer" onClick={() => {
                        history.push("/account/menu")
                    }}>
                        {
                            location.pathname.indexOf("/account/") > -1 ?
                                <PersonIcon style={{ color: 'black', fontSize: 30 }} /> :
                                <PersonIconOutlined style={{ color: 'black', fontSize: 30 }} />
                        }
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton color="inherit" aria-label="open drawer" onClick={() => {
                        history.push("/contact")
                    }}>
                        {location.pathname !== '/contact' ? <LocalPhoneOutlinedIcon style={{ color: 'black', fontSize: 30 }} /> : <LocalPhoneIcon style={{ color: 'black', fontSize: 30 }} />}
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                </Toolbar>
            </AppBar>
        </React.Fragment>

}

const useStyles = makeStyles({
    listItem: {
        backgroundColor: 'transparent',
        color: 'white',
        fontSize: 15,
        textAlign: 'left',
        fontFamily: 'FiraGo'
    }
})



const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        loggedIn: state?.auth?.loggedIn,
        translations: state.translations,
        language: state.language,
    }
}

export default connect(mapStateToProps, {setSnackBar})(Footer)

