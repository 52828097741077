
import { connect } from 'react-redux'
import React from "react"
import CloseIcon from '@mui/icons-material/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import AddressForm from "../Address/AddressForm"
import { Box, Dialog } from "@mui/material"
import { useWindowDimensions } from '../../hooks/useWindowDimensions';


const AddressDialog = (props) => {
    const { item, onCloseDialog, onReload, translations } = props
    const {width} = useWindowDimensions()
    return <div>

        <Dialog
            maxWidth='xl'
            style={{ padding: width > 600 ? 20 : 5, }}
            open={props.onOpen} onClose={onCloseDialog} aria-labelledby="form-dialog-title" >
            <div style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }} onClick={() => {
                onCloseDialog(false)
            }}>
                <CloseIcon />
            </div>
            <Box sx={{ maxWidth: 800, marginTop: 4}}>
                <AddressForm onClose={onCloseDialog} onReload={onReload} item={item} {...props} translations={translations}/>
            </Box>
        </Dialog>
    </div>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(AddressDialog)