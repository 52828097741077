import React from "react"
import { makeStyles } from "@mui/styles";
import { ShoppingBag } from "../../assets/icons/ShoppingBag";
import { COLORS } from "../../constants/colors";
import { getCartCount, getCartTotal } from "../../helpers/utilities";
import { CartItemComponent } from "../Product/CartItemComponent";
import { Box } from "@mui/material";


export const CartPopup = (props: any) => {
    const { cart, redirect, removeItem, translations } = props
    return (
        <div style={{ width: '100%', alignSelf: 'center', position: 'relative', height: 345, borderRadius: 10 }}>
            <div style={{ padding: 0, }}>
                <ul style={{ listStyle: 'none', alignItems: 'flex-start', textAlign: 'left', paddingLeft: 0, paddingTop: 0,  }}>
                    <li
                        style={{ color: 'black', fontFamily: 'FiraGo', fontSize: 14, fontWeight: '300', height: 30, padding: 10, marginTop: -15 }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <div style={{ top: 5, position: 'absolute', left: 15 }}>
                                <ShoppingBag width={28} height={40} onRemove={removeItem}/>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: 4, paddingLeft: 12, marginLeft: 25, paddingTop: 6 }}>
                                {translations.my_cart}</div>
                            
                        </div>
                    </li>
                    <ul style={{ listStyle: 'none', alignItems: 'flex-start', textAlign: 'left', paddingLeft: 10, paddingRight: 10, paddingTop: 5, height: 180 , maxHeight: 180, overflowY: 'auto' }}>
                        {cart?.sort((n1,n2) => {if (n1.created > n2.created) {return -1;}if (n1.created < n2.created) {return 1;}return 0;}).map((item, index) => {
                            return <CartItemComponent item={item} redirect={redirect} onRemove={removeItem} key={index}/>
                        })}
                    </ul>
                </ul>
            </div>
            <div style={{
                position: 'absolute',
                color: 'black', fontFamily: 'FiraGo', fontSize: 14, fontWeight: '300',
                borderBottomLeftRadius: 7,
                borderBottomRightRadius: 7,
                width: '100%', height: 40, bottom: 50, paddingTop: 16
            }}>
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ flexDirection: 'column', width: '50%' }}>
                        {getCartCount(cart)} {translations?.products}
                    </div>
                    <div style={{ flexDirection: 'column', width: '50%' }}>
                        {getCartTotal(cart)} ₾
                    </div>
                </div>
            </div>
            <Box
                onClick={() => redirect('/checkout')}
                sx={{
                    position: 'absolute',
                    cursor: 'pointer',
                    color: 'white', 
                    fontFamily: 'FiraGo', 
                    fontSize: 14, 
                    fontWeight: '300',
                    borderBottomLeftRadius: 7,
                    borderBottomRightRadius: 7,
                    width: '100%', 
                    height: 40, 
                    '&:hover': {
                        backgroundColor: "#e2030f",
                    },
                    backgroundColor: "#cf112c",
                    bottom: 0,
                    paddingTop: 1.6
                }}>
                {translations?.go_to_cart}

            </Box>
        </div>
    )
}
const useStyles = makeStyles({
    listItem: { color: 'black', fontFamily: 'FiraGo', fontSize: 12, fontWeight: '300' }

});
