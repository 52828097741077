import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from "@mui/material"
import { mainPageNewProductSectionService } from "../../models/MainPageBrandBanner/service"
import { QueryOptions } from "../../helpers/query.options";
import { MainPageNewProductSection } from '../../models/MainPageBrandBanner/MainPage';
import { useHistory } from 'react-router';
import Skeleton from "@mui/material/Skeleton/Skeleton";
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { NewSectionItem } from './NewSectionItem';

export const MainPageNewProductSectionComponent = (props: any) => {
    const { language } = props
    const history = useHistory()
    const [section, setSection] = useState<MainPageNewProductSection>()
    const [loadStatus, setLoadStatus] = useState(true)
    const {width} = useWindowDimensions()
    useEffect(() => {
        loadNewProductSection()
    }, [])
    const loadNewProductSection = () => {
        const options = new QueryOptions()
        options.lang = language
        mainPageNewProductSectionService.list(options).then((resp: any) => {
            const [item] = resp.results
            if (item) {
                setSection(item)
            }
            setLoadStatus(true)
        })
    }
    return section ? <Box sx={{
        width: width > 1200 ? 1200 : width,
        height: width > 1200 ? 400 : 'auto',
        alignItems: 'flex-start',
        flexDirection: width > 1200 ? 'row' : 'column',
        display: 'flex', padding: 0
    }}>
        <div style={{ display: 'flex', width: width > 1200 ? '50%' : width - 30, height: width > 1200 ? '100%' : 300, padding: width > 1200 ? 0 : 15 }}>
            {section.type === 'video' ? <iframe style={{ width: width > 1200 ? '100%' : width - 30, height: '100%', border: 0 }} src={section?.videoLink} title={section?.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" >
            </iframe> :
                <img src={section.image} style={{ width: '100%' }} onClick={() => {
                    if (width < 1200) {
                        history.push(section.url)
                    }
                }} />
            }
        </div>
       <NewSectionItem section={section}/> 
    </Box> : loadStatus ? <Box sx={{
        width: width > 1200 ? 1200 : 0,
        height: width > 1200 ? 400 : 0,
        alignItems: 'flex-start',
        flexDirection: width > 1200 ? 'row' : 'column',
        display: 'flex', padding: 3
    }}>
        {width > 1200 && <Skeleton height={width > 1200 ? 400 : 0} width={width > 1200 ? '50%' : width - 30}
            style={{ display: 'flex', padding: width > 1200 ? 0 : 15  , minHeight : 400}}
        />}
        {width > 1200 && <div style={{ display: 'flex', width: '50%', height: '100%', alignItems: 'center', flexDirection: 'column', paddingTop: 120 }}>
            <Box sx={{ width: 300 }}>
                <Skeleton />
                <Skeleton style={{ marginTop: 100, width: 550 }} animation="wave" />
                <Skeleton style={{ width: 520 }} animation="wave" />
                <Skeleton style={{ width: 550 }} animation="wave" />
                <Skeleton animation={false} style={{ width: 510 }} />
            </Box>
        </div>}
    </Box> : <></>
}