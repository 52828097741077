
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box } from '@mui/material';
import React from "react";


export const Instagram = (props) => {
    const {iconSize, radius, color, width=70} = props
    return <Box style={{ display: 'inline-block', padding: 6, position: 'relative', width, height: 20 }}>
        <Box style={{
            width: radius, height: radius,
            position: 'absolute',
            left: 0,
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: 30, border: `1px solid ${props.color}`, display: 'inline-flex'
        }}>
            <InstagramIcon color={color} sx={{
                color, fontSize: iconSize
            }} fontSize={'medium'} />
        </Box>
        
    </Box>
}