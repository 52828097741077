import React, { Fragment, useState, useEffect } from 'react';
import { Box, Fade } from '@mui/material';
import BreadCrumbsComponent from '../../BreadCrumbsComponent'
import { showBestsellers } from '../../../helpers/utilities';
import { BrandPageBottomSectionComponent } from './BrandPageBottomSectionComponent';
import { BrandProductLineCarousel } from '../../Carousel/BrandProductLineCarousel';
import { MainPageProductSliderComponent } from '../../MainPage/MainPagProductSliderComponent';

export const MobileBrandPageHeaderNiche = (props) => {
    const { brand, items, translations, productLines } = props
    const [loaded, setLoaded] = useState(false)
    const [loadedLogo, setLoadedLogo] = useState(false)

    useEffect(() => {
        if (brand?.logo) {
            const img = new Image()
            img.src = brand?.logo
            img.onload = () => {
                setTimeout(() => {
                    setLoadedLogo(true)
                }, 500)
            }
        }
        if (window.innerWidth < 1200) {
            if (brand?.bannerMobile) {
                const img = new Image()
                img.src = brand?.bannerMobile
                img.onload = () => {
                    setLoaded(true)
                }
            }
        } else {
            if (brand?.banner) {
                const img = new Image()
                img.src = brand?.banner
                img.onload = () => {
                    setLoaded(true)
                }
            }
        }
    }, [brand])

    return brand ? <Fragment>
        {brand && <div className={"container"} style={{
            display: 'flex',
            width: window.innerWidth,
            paddingTop: 10,
            alignItems: 'flex-start', margin: '0px auto'
        }}>
            {translations && <BreadCrumbsComponent items={[
                { name: translations?.home, link: '/' },
                { name: translations?.brands, link: '/brands' },
                { name: brand?.title, link: `/brand/${brand?.id}` }
            ]} />}

        </div>}


        <Box style={{ width: 1200, margin: '0px auto', display: 'flex', flexDirection: 'row', marginTop: brand.isLuxury || brand.isNiche ? 10 : 0 }}>
            <Box style={{ width: window.innerWidth, backgroundColor: 'transparent', padding: 0 }}>
                {brand?.bannerMobile && <Fade
                    in={loaded}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(loaded ? { timeout: 2000 } : {})}
                >
                    {brand && <img src={brand.bannerMobile} style={{ width: '100%' }} />}
                </Fade>}
                {brand?.bannerMobile && <Fade
                    in={loaded}
                    style={{ transformOrigin: '0 0 0', marginTop: 10 }}
                    {...(loaded ? { timeout: 2000 } : {})}
                >
                    {brand && <img src={brand?.logo} style={{ width: '40%' }} />}
                </Fade>}
                {productLines?.length > 0 && <BrandProductLineCarousel
                    slides={productLines} />}
                {items?.length > 0 && showBestsellers(brand) && items?.length > 5
                    && <Box style={{ width: '100%', marginBottom: 90 }}>
                        <MainPageProductSliderComponent
                            {...props}
                            width={'100%'}
                            slider={{ title: translations?.bestsellers, products: items.filter((i, index) => i) }} />
                    </Box>}
                {brand?.isLuxury && brand.footerBanner?.length && <BrandPageBottomSectionComponent brand={brand} />}
            </Box>
        </Box>

    </Fragment> : <></>
}