import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, {  } from 'react'
import { CartItem } from '../../models/cart/cart'

export const QuantityControlComponent = (props: any) => {
    const { qty = 1, onChange, stock, onMessage } = props
    const classes = useStyles();


    return <div style={{ 
        flexDirection: 'row', 
        display: 'flex', 
        borderRadius: 20, 
        width: 90, 
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid black',
        height: 35}}>
        <div
            style={{ width: '30%', fontSize: 18, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative'}}
            onClick={() => {
                if (qty > 1) {
                    onChange(qty - 1)
                }
            }}><div style={{position: 'absolute', top: -11}}>-</div></div>
        <div
            style={{ width: '30%', fontSize: 20, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
        >{qty}</div>
        <div
            style={{ width: '30%', fontSize: 18, alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative'}}
            onClick={() => {
                if (qty < stock) {
                    onChange(qty + 1)
                } else {
                    onMessage(stock)
                }
            }}><div style={{position: 'absolute', top: -11}}>+</div>

        </div>
    </div>
}
const useStyles = makeStyles({


});
