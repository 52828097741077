import React, { useCallback, useState, useEffect, useRef } from "react";
import { Box, Grid, IconButton, Link, Tab, Tabs, Typography } from "@mui/material";
import { connect } from 'react-redux'
import { setCart, setFavorites, setSnackBar } from '../models/application/applicationActions'
import { State } from '../models/application/applicationReducer'
import LoginForm from "../components/Forms/LoginForm"
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { login } from "../models/user/authActions";
import { Branch } from "../models/branch/branch";
import { QueryOptions } from "../helpers/query.options";
import { branchService } from "../models/branch/service";
import { GoogleMapContainerMobile } from "../components/map/GoogleMapMobile";
import { BranchItemMobile } from "../components/Branch/BranchItemMobile";
import { Title } from "../components/Typography/Title";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { useWindowDimensions } from "../hooks/useWindowDimensions";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0, width: window.innerWidth }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ContactPage = (props) => {
    const { app, login, setCart, auth, setFavorites, translations, setSnackBar, language } = props
    const styles = useStyles()
    const history = useHistory();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [branches, setBranches] = useState<Branch[]>([])
    const [branch, setBranch] = useState<Branch | null>()

    const { width } = useWindowDimensions()


    useEffect(() => {
        loadBranches()
    }, [])

    const loadBranches = () => {
        const options = new QueryOptions()
        options.pageSize = 40
        branchService.listWithCounter(options).then((resp: any) => {
            setBranches(resp.current)
        })
    }
    const linkRef = useRef<any>()

    return <Box sx={{ width: '100%', paddingBottom: 10, alignItems: 'center', justifyContent: 'center' }}>

        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%', paddingTop: 6 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Box sx={{ width: '90%', alignItems: 'flex-start', justifyContent: '', display: 'flex', fontSize: 15, padding: 1.2 }}>
                    <Title
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        text={translations.address}
                        fontSize={20}
                        uppercase={false}
                        className="roboto"
                        fontWeight={500} />

                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Box sx={{ width: '10%', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: 15 }}>
                    <NotListedLocationIcon sx={{ fontSize: 16 }} />
                    {/* location */}
                </Box>
                <Box sx={{ width: '70%', maxHeight: 21, alignItems: 'flex-start', justifyContent: '', display: 'flex', fontSize: 15 }}>
                    <Title
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        text={translations.find_address}
                        fontSize={18}
                        uppercase={false}
                        className="roboto"
                        fontWeight={400} />

                </Box>
            </Box>
            <Box sx={{
                position: 'absolute',
                borderRadius: 40,
                top: 70,
                border: '0px solid #444',
                height: 60, width: 60, right: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}><a onClick={() => { history.push('/info_pages/branches') }}>
                    <NotListedLocationIcon color={props.color} sx={{
                        color: "rgb(100,173,80)", fontSize: 30
                    }} fontSize={'medium'} />
                </a>
            </Box>
            <Box sx={{ padding: 2, borderBottom: 1, borderColor: 'divider' }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%', paddingTop: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Box sx={{ width: '90%', alignItems: 'flex-start', justifyContent: '', display: 'flex', fontSize: 15, padding: 1.2 }}>
                                    <Title
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        text={translations?.hotline}
                        fontSize={20}
                        uppercase={false}
                        className="roboto"
                        fontWeight={500} />

                
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Box sx={{ width: '10%', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: 15 }}>
                    <CallOutlinedIcon sx={{ fontSize: 16 }} />
                </Box>
                <Box sx={{ width: '90%', alignItems: 'center', justifyContent: 'space-between', display: 'flex', fontSize: 15,marginRight: 3 }}>
                    <Title
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        text={translations.hotline_number}
                        fontSize={18}
                        uppercase={false}
                        className="roboto"
                        fontWeight={400} />

                <IconButton onClick={() => linkRef.current.click()}>
                    <Link href={`tel:${translations?.hotline_number}`} ref={linkRef}></Link>
                        <LocalPhoneIcon color={props.color} sx={{
                            color: "rgb(100,173,80)", fontSize: 30
                        }} fontSize={'medium'} />
                </IconButton>
                </Box>
            </Box>
        </Box>
        <Box sx={{
            position: 'absolute',
            borderRadius: 40,
            top:width > 1200 ? 230 :160,
            border: '0px solid #444',
            height: 60, width: 60, right: 20, display: 'flex', alignItems: 'center', justifyContent: 'center',
           
        }}>
<LocalPhoneIcon color={props.color} sx={{
                color: "rgb(100,173,80)", fontSize: 30
            }} fontSize={'medium'} />
        </Box>


        <Box sx={{ padding: 2, borderBottom: 1, borderColor: 'divider' }} />
        <div></div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} />
        <Box sx={{ width: '90%', alignItems: 'flex-start', justifyContent: '', display: 'flex', fontSize: 15, padding: 1.2 }}>
            <Title
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                text={translations.our_branches}
                fontSize={20}
                uppercase={false}
                className="roboto"
                fontWeight={500} />

        </Box>

        <Grid container sx={{ padding: 1 }}>
            {branches.map((item, index) => {
                return <Grid item xs={12} sx={{ padding: 1 }}>
                    <BranchItemMobile
                        key={index}
                        language={language}
                        item={item} onClicked={() => {

                        }} />
                </Grid>
            })}

        </Grid>


    </Box>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, { setSnackBar, login, setCart, setFavorites })(ContactPage)

const useStyles = makeStyles({
    container: {
        width: '100%',
        background: '#fafafa',
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center', textflexDirection: 'column'
    },
    login: {
        flex: 1,
        display: 'flex',
        alignSelf: 'center',
        flexDirection: 'row'
    }
})
