import { Box } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { CheckoutTimeline } from '../assets/svg/CheckoutTimeline'
import { CheckoutTimelineMobile } from '../assets/svg/CheckoutTimelineMobile'
import { CheckoutFirstStepComponent } from '../components/Checkout/CheckoutFIrstStepComponent'
import { CheckoutFourthStepComponent } from '../components/Checkout/CheckoutFourthStepComponent'
import { CheckoutSecondStepComponent } from '../components/Checkout/CheckoutSecondStepComponent'
import { CheckoutThirdStepComponent } from '../components/Checkout/CheckoutThirdStepComponent'
import { loadCart, loadCartItems } from '../helpers/utilities'
import { Address } from '../models/address/address'
import { setCart, setConfirmation, setFavorites, setSnackBar } from '../models/application/applicationActions'
import { CartItem } from '../models/cart/cart'
import { Coupon, LoyaltyCard } from '../models/Coupon/Coupon'
import { PaymentMethod } from '../models/payment/PaymentMethod'

import { ShippingMethod } from '../models/shipping/ShippingMethod'
import HolidayPopup from '../components/Checkout/HolidayPopup'



const CheckoutPage = (props: any) => {
    const { app, setCart, translations, language, setSnackBar, auth } = props
    const [step, setStep] = useState(1)
    const [address, setAddress] = useState<Address>()
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>()
    const [shippingMethod, setShippingMethod] = useState<ShippingMethod>()
    const [coupon, setCoupon] = useState<Coupon>()
    const [loyaltyCard, setLoyaltyCard] = useState<LoyaltyCard>()
    const [discount, setDiscount] = useState(0)

    const [open, setOpen] = useState(false)

    useEffect(() => {
        const currentDate = new Date();
        const targetDate = new Date('2024-01-03'); 
        
        
        if (currentDate > targetDate) {
          setOpen(false);
        } else {
          setTimeout(() => setOpen(true), 500)
        }
      }, []);



    useEffect(() => {
        if (step === 2 || step === 1) {
            setLoyaltyCard(null)
            setCoupon(null)
        }
    }, [step])

    useEffect(() => {
        if (coupon?.isValid) {
            setLoyaltyCard(null)
            setSnackBar({ ...app.snackBar, open: true, text: `${translations.coupon} ${coupon?.name} ${translations.is_active}.`, bColor: "rgb(83, 148, 96)" })
        }
    }, [coupon])

    useEffect(() => {
        if (loyaltyCard?.discount) {
            setCoupon(null)
            setSnackBar({ ...app.snackBar, open: true, text: `${translations.loyalty_card_discount} ${translations.is_active}.`, bColor: "rgb(83, 148, 96)" })
        }
    }, [loyaltyCard])


    const onUpdateCart = (item: CartItem) => {
        setCart([...app.cart.filter(ci => ci.sku !== item.sku), { ...item, stock: 1 }])
        loadCartItems(setCart, language)
    }

    return <Box sx={{ maxWidth: window.innerWidth > 1200 ? 1200 : window.innerWidth, margin: '0px auto', padding: window.innerWidth > 1200 ? 3 : 0 }}>
        {window.innerWidth > 1200 ?
            <CheckoutTimeline index={step} onSetStep={setStep} address={address} /> :
            <Box style={{ padding: 20, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <CheckoutTimelineMobile
                    address={address}
                    width={window.innerWidth} height={50}
                    index={step} onSetStep={setStep} />
            </Box>

        }
        {step === 1 && <CheckoutFirstStepComponent
            {...props}
            app={app}
            onUpdateCart={onUpdateCart}
            translations={translations}
            setStep={setStep} />}
        {step === 2 && <CheckoutSecondStepComponent
            {...props}
            app={app}
            step={step}
            onUpdateCart={onUpdateCart}
            translations={translations}
            globalAddress={address}
            onSetAddress={(address) => {
                setAddress(address)
                setStep(3)
            }}
            setStep={(step) => {
                if (address) {
                    setStep(step)
                }
            }}
        />}
        {step === 3 && <CheckoutThirdStepComponent
            {...props}
            onReloadCart={() => {
                loadCartItems(setCart, language)
            }}
            setSnackBar={setSnackBar}
            coupon={coupon}
            loyaltyCard={loyaltyCard}
            setCoupon={setCoupon}
            setLoyaltyCard={setLoyaltyCard}
            setCart={setCart}
            onSetDiscount={setDiscount}
            app={app}
            address={address}
            translations={translations}
            setStep={(step) => {
                if (address) {
                    setStep(step)
                }
            }}
            onSetShippingMethod={(method) => {
                if (method) {
                    setShippingMethod(method)}}
                }
            onSetPaymentMethod={setPaymentMethod}
        />}
        {step === 4 && 
        <>
            <HolidayPopup app={app} translations={translations} language={language} open={open} setOpen={setOpen}/>
            <CheckoutFourthStepComponent
                {...props}
                onReloadCart={() => {
                    loadCartItems(setCart, language)
                } }
                setFavorites={setFavorites}
                auth={auth}
                discount={discount}
                onUpdateCart={onUpdateCart}
                loyaltyCard={loyaltyCard}
                shippingMethod={shippingMethod}
                paymentMethod={paymentMethod}
                translations={translations}
                address={address}
                coupon={coupon}
                setStep={(step) => {
                    if (address) {
                        setStep(step)
                    }
                } } />
        </>

        }
    </Box>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, { setSnackBar, setCart, setConfirmation, setFavorites })(CheckoutPage)