import { Serializer } from "../serializer"
import { NameIdItem, PasswordRestore } from "./common"

export class NameIdItemSerializer implements Serializer {
    fromJson(json: any) {
        const nid = new NameIdItem()
        nid.name = json.name
        nid.id = json.id
        return nid
    }

    toJson(item: NameIdItem) {
        const obj: any = {}   
        if (item.id) {
            obj.email =  item.id
        }     
        return obj
    }
}
export class PasswordRestoreSerializer implements Serializer {
    fromJson(json: any) {
        const pr = new PasswordRestore()
        pr.email = json.email
        pr.token = json.token
        
        
        return pr
    }

    toJson(item: PasswordRestore) {    
        return {
            email: item.email,
            token: item.token,
            password: item.password
        }
    }
}

export const nameIdItemSerializer = new NameIdItemSerializer()