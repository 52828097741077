import { Resource } from "../resource";

export class AttributeSet extends Resource{
    attributes: Attribute[];
}
export class AttributeName extends Resource {
    value: string
}
export class Attribute extends Resource {
    value: string
    name: AttributeName
    selected: boolean

}

