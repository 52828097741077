import React, { useState, useEffect } from 'react'
import { Fragment } from "react"
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { GenericBanner } from '../../models/banner/banner'
import { QueryOptions } from '../../helpers/query.options'
import { genericBannerService } from '../../models/banner/service'
import { Fade } from '@mui/material'


export const GenericBannerComponent = (props) => {
    const { location } = props
    const [genericBanner, setGenericBanner] = useState<GenericBanner>()
    const [loaded, setLoaded] = useState(false)
    const { width } = useWindowDimensions()

    const loadGenericBanner = (link) => {
        const options = new QueryOptions()
        options.page = link
        genericBannerService.readWithNoId(options).then(r => {
            console.log(r)
            setGenericBanner(r)
        })
    }

    useEffect(() => {
        if (location) {
            loadGenericBanner(location)
        }

    }, [location])

    useEffect(() => {
        if (genericBanner) {
            if (window.innerWidth < 1200) {
                if (genericBanner?.image) {
                    const img = new Image()
                    img.src = genericBanner?.image
                    img.onload = () => {
                        setLoaded(true)
                    }
                }
            } else {
                if (genericBanner?.imageMobile) {
                    const img = new Image()
                    img.src = genericBanner?.imageMobile
                    img.onload = () => {
                        setLoaded(true)
                    }
                }
            }
        }
    }, [genericBanner])

    return <Fragment>
        {width > 1200 && genericBanner?.image && <Fade
            in={loaded}
            style={{ transformOrigin: '0 0 0' }}
            {...(loaded ? { timeout: 2000 } : {})}
        ><div
            style={{ cursor: 'pointer' }}
            onClick={() => {
                window.location.href = genericBanner.link
            }}>
                {genericBanner && <img src={genericBanner.image} style={{ width: '100%' }} />}
            </div>
        </Fade>}
        {width < 1200 && genericBanner?.imageMobile && <Fade
            in={loaded}
            style={{ transformOrigin: '0 0 0' }}
            {...(loaded ? { timeout: 2000 } : {})}
        >
            <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    window.location.href = genericBanner.link
                }}>
                {genericBanner && <img src={genericBanner.imageMobile} style={{ width: '100%' }} />}

            </div>
        </Fade>}
    </Fragment>
}