import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { Divider, Grid } from "@mui/material"
import { QueryOptions } from "../../helpers/query.options"
import { infoPageService } from "../../models/InfoPage/service"

export const AboutUsMenuComponent = (props) => {
    const { language, translations, setPage } = props
    const [content, setContent] = useState<any>()

    const history = useHistory()
    const location = useLocation()

    const loadInfoPages = async (): Promise<any> => {
        const options = new QueryOptions()
        options.sort = 'position'
        options.order = 'asc'
        options.lang = language
        
        const pages = await infoPageService.listWithCounter(options)
      
       setContent(pages.current.reduce((result, item) => {
          const { id, link, title, section } = item
      
          const transformedItem = { id, title, url: link }
          if (section === "about_us") {
            result.left.push(transformedItem)
          } else if (section === "my_profile") {
            result.middle.push(transformedItem)
          } else if (section === "help") {
            result.right.push(transformedItem)
          }
          return result
        }, { left: [], middle: [], right: [] }))
      }

    useEffect(() => {
        loadInfoPages()
    },[translations])

    return (
        <>
            <div
                style={{
                    display: "flex",
                    paddingBottom: 30,
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "30%",
                    height: "100vh",
                    paddingTop: 50,
                }}
            >
                {content &&
                    content.left.map((item, index) => {
                    
                        return (
                            <Grid item lg={3} sm={3}>
                                <div>
                                    <ul
                                        style={{
                                            listStyle: "none",
                                            alignItems: "flex-start",
                                            textAlign: "left",
                                        }}
                                    >
                                                <li
                                                    className="underline-on-hover"
                                                    key={item.id}
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "black",
                                                        fontFamily: "FiraGo",
                                                        fontWeight: item.url === location.pathname ? "bold" : "400",
                                                        fontSize: 14,
                                                        marginBottom: 14,
                                                    }}
                                                    onClick={() => {
                                                        if (item?.url?.length) {
                                                            history.push(item.url)
                                                        }
                                                    }}
                                                >
                                                    {item.title}
                                                </li>
                                    </ul>
                                </div>
                            </Grid>
                        )
                    })}
            </div>
            <Divider
                orientation="vertical"
                sx={{ width: "1px", backgroundColor: "gray", height: "100vh", marginTop: 5, marginRight: 3 }}
            />
        </>
    )
}
