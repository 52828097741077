import React, { useState } from 'react'
import '../../../src/App.css'

export const CheckoutTimelineMobile = (props) => {
    const { width, height, index, onSetStep, address } = props
    const [hoveredIndex, setHoveredIndex] = useState(0)
    return <svg width={width} height={height} version="1.1" viewBox="0 0 80.83344 12.8745" xmlns="http://www.w3.org/2000/svg">
        <path
            transform="translate(0,-0.6)"
            d="M 1.5889631,7.1382387 81.255164,6.6197647"
            fill="none"
            stroke="#cbcbcb"
            strokeWidth="0.239197"
            id="line" />
        <path
            onMouseEnter={() => {
                setHoveredIndex(1)
            }}
            onMouseLeave={() => {
                setHoveredIndex(0)
            }}
            onClick={() => {
                onSetStep(1)
            }}
            style={{
                cursor: 'pointer',
            }}
            fill={index === 1 ? "#92be5c" : "#dbd3c9"}
            stroke={index === 1 ? "#92be5c" : "#dbd3c9"}
            strokeLinecap="round"
            strokeLinejoin="bevel"
            strokeWidth="0"
            d="M 5.1694191,1.0005259 A 5.1035339,5.103485 0 0 1 10.392003,5.9209506 5.1035339,5.103485 0 0 1 5.5327087,11.20041 5.1035339,5.103485 0 0 1 0.19709936,6.4028126 5.1035339,5.103485 0 0 1 4.9322652,1.0117458" />
        <path
            onClick={() => {
                onSetStep(2)
            }}
            onMouseLeave={() => {
                setHoveredIndex(0)
            }}
            style={{
                cursor: 'pointer',
            }}
            onMouseEnter={() => {
                setHoveredIndex(2)
            }}
            fill={index === 2 ? "#92be5c" : "#dbd3c9"}
            stroke={index === 2 ? "#92be5c" : "#dbd3c9"}
            strokeLinecap="round"
            strokeLinejoin="bevel"
            strokeWidth="0"
            d="M 29.870723,1.0004782 A 5.1035327,5.1035327 0 0 1 35.093305,5.9209487 5.1035327,5.1035327 0 0 1 30.234012,11.200458 5.1035327,5.1035327 0 0 1 24.898404,6.4028152 5.1035327,5.1035327 0 0 1 29.633568,1.0116981" />
        <path
            onMouseEnter={() => {
                setHoveredIndex(3)
            }}
            onMouseLeave={() => {
                setHoveredIndex(0)
            }}
            style={{
                cursor: 'pointer',
            }}
            onClick={() => {
                if (address) {
                    onSetStep(3)
                }
            }}
            fill={index === 3 ? "#92be5c" : "#dbd3c9"}
            stroke={index === 3 ? "#92be5c" : "#dbd3c9"}
            strokeLinecap="round"
            strokeLinejoin="bevel"
            strokeWidth="0"
            d="M 54.572024,1.0004782 A 5.1035329,5.1035329 0 0 1 59.794607,5.920949 5.1035329,5.1035329 0 0 1 54.935314,11.200458 5.1035329,5.1035329 0 0 1 49.599705,6.4028155 5.1035329,5.1035329 0 0 1 54.334989,1.0116981" />
        <path
            onMouseEnter={(e) => {
                e.preventDefault()
                setHoveredIndex(4)
            }}
            onMouseLeave={(e) => {
                e.preventDefault()
                setHoveredIndex(0)
            }}
            style={{
                cursor: 'pointer',
            }}
            onClick={() => {
                
            }}
            fill={index === 4 ? "#92be5c" : "#dbd3c9"}
            stroke={index === 4 ? "#92be5c" : "#dbd3c9"}
            strokeLinecap="round"
            strokeLinejoin="bevel"
            strokeWidth="0"
            d="m 79.273235,1.0005193 a 5.1034925,5.1034925 0 0 1 5.222529,4.920432 5.1034925,5.1034925 0 0 1 -4.859239,5.2794667 5.1034925,5.1034925 0 0 1 -5.335519,-4.797604 5.1034925,5.1034925 0 0 1 4.735201,-5.3910749" />
        <g fill="#f9f9f9"
            stroke-width=".10532">
            <path
                d="M 31.747648,8.7484488 H 28.238374 V 8.020852 q 0.365533,-0.3133215 0.731066,-0.6266431 0.369053,-0.3133215 0.685845,-0.623173 0.668411,-0.6475277 0.915592,-1.0269893 0.247164,-0.3829483 0.247164,-0.8250812 0,-0.4038499 -0.268066,-0.6301459 -0.264579,-0.2297656 -0.741525,-0.2297656 -0.316808,0 -0.685827,0.1114353 -0.36902,0.1114353 -0.720641,0.3411789 h -0.03485 V 3.7805849 q 0.247181,-0.1218725 0.65797,-0.2228096 0.414292,-0.100998 0.800727,-0.100998 0.797241,0 1.249798,0.3864177 0.452576,0.3829483 0.452576,1.040918 0,0.2959231 -0.0766,0.5535293 -0.07306,0.2541367 -0.219307,0.483919 -0.135835,0.2158369 -0.320294,0.4247177 -0.181023,0.2088984 -0.442133,0.4630346 -0.372506,0.365533 -0.769383,0.7101815 -0.396877,0.3411792 -0.741525,0.6336153 h 2.788633 z"
                id="2" />
            <path
                d="M 6.7141102,8.7393388 H 3.869226 V 8.2028104 H 4.96344 V 4.6801236 H 3.869226 V 4.2000848 q 0.2223708,0 0.4765211,-0.035326 Q 4.5998803,4.1260035 4.7304817,4.0553525 4.892849,3.9671111 4.9846149,3.8329989 5.0799208,3.695271 5.0940517,3.4658871 H 5.6411501 V 8.202785 h 1.073039 z" />
            <path
                d="m 56.162368,4.7348737 q 0,0.2570081 -0.08096,0.468249 -0.08096,0.2112581 -0.228853,0.3661715 -0.144292,0.1549034 -0.348542,0.2570083 -0.204206,0.09857 -0.454163,0.1407926 v 0.021113 q 0.623145,0.077459 0.94706,0.3978355 0.323895,0.3168615 0.323895,0.827369 0,0.3379821 -0.116049,0.6196367 -0.112626,0.2816543 -0.345035,0.4858436 -0.232361,0.2042069 -0.587954,0.3168615 -0.355595,0.1126946 -0.841439,0.1126946 -0.38375,0 -0.725258,-0.059915 Q 53.367087,8.628618 53.067821,8.4702576 V 7.8717251 q 0.306302,0.1619503 0.661896,0.2499733 0.359102,0.088016 0.686523,0.088016 0.323914,0 0.559785,-0.070469 0.235869,-0.070472 0.387291,-0.2006805 0.154903,-0.1302386 0.225327,-0.3203872 0.07395,-0.1901204 0.07395,-0.4259907 0,-0.2394132 -0.09499,-0.4084129 -0.09158,-0.1725278 -0.264047,-0.281657 -0.172526,-0.1126943 -0.422499,-0.1654744 -0.24643,-0.05278 -0.5598,-0.05278 H 53.853015 V 5.7522341 h 0.468233 q 0.285197,0 0.506998,-0.070472 0.221803,-0.070472 0.369681,-0.2006806 0.151309,-0.1302386 0.228836,-0.3098269 0.07737,-0.17956 0.07737,-0.3943096 0,-0.1830688 -0.06333,-0.3274218 -0.06333,-0.1443615 -0.17956,-0.2429392 -0.11622,-0.1021386 -0.27813,-0.1549171 -0.161903,-0.05278 -0.359119,-0.05278 -0.376714,0 -0.665404,0.1196829 -0.28518,0.1161143 -0.545714,0.3063012 L 53.088984,3.9847953 q 0.13385,-0.1091256 0.295725,-0.2042064 0.165498,-0.095001 0.359119,-0.1654745 0.193629,-0.073892 0.411921,-0.1161142 0.221784,-0.042223 0.46825,-0.042223 0.38024,0 0.66542,0.095001 0.28869,0.095003 0.48232,0.2675684 0.193627,0.1689988 0.292214,0.4048872 0.09859,0.232362 0.09859,0.5105079 z" />
            <path
                d="M 81.310641,7.5677166 H 80.543903 V 8.7484485 H 79.910336 V 7.5677166 H 77.480442 V 6.9953497 l 2.386671,-3.538573 h 0.676767 v 3.5133725 h 0.766746 z M 79.910336,6.9701492 v -1.281534 q 0,-0.2015866 0.0038,-0.4211812 0.0069,-0.223182 0.014,-0.4319614 0.0069,-0.2124018 0.014,-0.3959807 0.01054,-0.1835963 0.014,-0.3059937 h -0.03238 q -0.02523,0.072056 -0.06477,0.1620025 -0.03608,0.086345 -0.08278,0.1763861 -0.04323,0.086345 -0.08995,0.1691936 -0.04692,0.082848 -0.08628,0.1404631 L 78.12047,6.9703137 Z" />

        </g>
    </svg>

}