import React from "react"
import { connect } from "react-redux"
import { useWindowDimensions } from "../hooks/useWindowDimensions"
import { HelpMenuComponent } from "../components/help/HelpMenuComponent"
import { HelpContent } from "../components/help/HelpContent"
import { useHistory, useParams } from "react-router"
import { setConfirmation, setSnackBar } from "../models/application/applicationActions"
import { logout } from "../models/user/authActions"
import { Box } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"


const HelpPage = (props) => {
    const { translations, auth, language } = props
    const { width } = useWindowDimensions()
    let { page }: any = useParams()
    const history = useHistory()

    return (
        <>
        {width < 600 && (
            <Box sx={{ display: "flex", justifyItems: "flex-start" }}>
                <ArrowBackIcon
                    onClick={() => history.goBack()}
                    sx={{ color: "black", fontSize: 35, paddingTop: 4 }}
                />
            </Box>
        )}
        <div style={{ display: "flex", maxWidth: 1200, margin: "0 auto", width: width }}>
            {width > 600 && (
                <HelpMenuComponent language={language} translations={translations} page={page} {...props} />
            )}
            <HelpContent {...props} page={page} auth={auth} />
        </div>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        translations: state.translations,
        language: state.language,
        selectedQuestion: state.app.selectedQuestion,
    }
}

export default connect(mapStateToProps, { setConfirmation, setSnackBar, logout })(HelpPage)
