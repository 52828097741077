import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"
import { setSnackBar } from "../../models/application/applicationActions"
import { Box, Button, Dialog, ListItemButton, Grid } from "@mui/material"
import { useHistory } from "react-router"
import { QueryOptions } from "../../helpers/query.options"
import { generateParamsFromUrl, generateUrlForBrand } from "../../helpers/utilities"
import { environment } from "../../environment/environment"
import { PriceSlider } from "../Filter/PriceSlider"
import { COLORS } from "../../constants/colors"
import { RenderCatText } from "../Filter/CategoriesList"
import Divider from "@mui/material/Divider"
import Chip from "@mui/material/Chip"

const SortDialogForBrand = (props) => {
    const { onCloseDialog, location, translations, brands, filterItem, sort, onSetSort } = props
    const history = useHistory()
    const [selectedFilters, setSelectedFilters] = useState<any[]>([])
    const [filterItems, setFilterItems] = useState<any>()
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(36)
    const [pageUrl] = useState("catalog")
    const [order, setOrder] = useState("desc")
    const [search, setSearch] = useState("")
    const [url, setUrl] = useState("")
    const [options, setOptions] = useState<any>(new QueryOptions())
    const [cachedObj, setCachedObject] = useState<any>()
    const [loaded, setLoaded] = useState(false)
    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(1000)
    const [minGlobal, setMinGlobal] = useState(0)
    const [maxGlobal, setMaxGlobal] = useState(1000)
    const [attributeSet, setAttributeSet] = useState<any>()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [items, setItems] = useState<any[]>([])

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        onSetSort(items[index])
        setSelectedIndex(index)
        setAnchorEl(null)
    }

    useEffect(() => {
        if (translations?.sort_newest) {
            setItems([
                { title: translations?.sort_newest, sort: "uploaded", order: "desc" },
                { title: translations?.price_increasing, sort: "special_price", order: "asc" },
                { title: translations?.price_decreasing, sort: "special_price", order: "desc" },
            ])
        }
        if (!sort?.title) {
            setItems([
                { title: translations?.sort_newest, sort: "uploaded", order: "desc" },
                { title: translations?.price_increasing, sort: "special_price", order: "asc" },
                { title: translations?.price_decreasing, sort: "special_price", order: "desc" },
            ])

            onSetSort({ title: translations?.sort_newest, sort: "uploaded", order: "desc" })
        }
    }, [sort, translations])

    useEffect(() => {}, [attributeSet, props.open])

    useEffect(() => {
        if (filterItem) {
            handleOnChange(filterItem)
        }
    }, [filterItem])

    const handleOnChange = (item: any) => {
        let arr: any = []
        if (selectedFilters.map((i) => String(i.id)).indexOf(item.id) > -1) {
            arr = [...selectedFilters.filter((i) => i.id !== item.id)]
        } else {
            arr = [...selectedFilters.filter((i) => i.id !== item.id), item]
        }

        if (filterItems && filterItems[item?.name?.id].length > 1) {
            setCachedObject({ id: item.name.id, items: filterItems[item?.name?.id] })
        }
        setPageIndex(1)
        setSelectedFilters(arr)
        reloadQueryOptions(arr, 1, [0, 10000])
    }

    const reloadQueryOptions = (selectedFilters: any, pageIndex, min_max_price) => {
        const min_price = min_max_price[0]
        const max_price = min_max_price[1]
        const options: any = generateParamsFromUrl(location.search)
        options.init = false
        setOptions(options)
        options.brand = brands.map((b) => b.id).toString()
        const url = generateUrlForBrand(
            options,
            environment.params,
            selectedFilters,
            `${location.pathname}`,
            pageIndex,
            pageSize,
            sort,
            order,
            search,
            min_price,
            max_price,
            ""
        )
        if (url.length) {
            history.push(url)
            setUrl(url)
        } else {
            if (loaded) {
                history.push(`/${pageUrl}`)
            }
        }
    }

    return (
        <Dialog
            maxWidth="md"
            style={{
                padding: 0,
                minWidth: window.innerWidth > 1200 ? 1200 : "100%",
                zIndex: 100000,
                height: window.innerHeight + 64,
                marginTop: -32,
                overflow: "hidden",
            }}
            open={props.open}
            onClose={onCloseDialog}
            aria-labelledby="form-dialog-title"
        >
            <div
                style={{ position: "absolute", right: window.innerWidth * 0.03, top: 40, cursor: "pointer" }}
                onClick={() => {
                    onCloseDialog(false)
                }}
            >
                <CloseIcon />
            </div>
            <Box
                sx={{
                    padding: 2,
                    width: window.innerWidth > 1200 ? 800 : window.innerWidth - 32,
                    marginTop: 4,
                    height: window.innerWidth > 1200 ? 400 : window.innerHeight,
                    display: "flex",
                    overflow: "hidden",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Box>
                    <Divider style={{ marginBottom: 5, marginTop: 5 }}>
                        {" "}
                        <Chip label={translations.sort_by} />
                    </Divider>
                    <Grid
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: window.innerWidth > 400 ? "row" : "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {items.map((item, index) => {
                            return (
                                <ListItemButton
                                    style={{
                                        margin: 4,
                                        whiteSpace: "nowrap",
                                        borderRadius: "4px",
                                        border: "1px solid #000",
                                        width: window.innerWidth > 400 ? "" : "250px",
                                        display: "flex",
                                        justifyContent: "space-around",
                                    }}
                                    key={index}
                                    selected={selectedIndex === index}
                                    onClick={(event) => handleListItemClick(event, index)}
                                >
                                    <RenderCatText item={item} {...props} index={index} key={index} isButton={false} />
                                </ListItemButton>
                            )
                        })}
                    </Grid>
                </Box>

                <Box
                    sx={{ minHeight: "70%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}
                >
                    <Box sx={{ minHeight: "75%", overflowY: "scroll" }}>
                        <Box style={{ padding: 10 }}>
                            <PriceSlider
                                min={minPrice}
                                max={maxPrice}
                                minGlobal={minGlobal}
                                maxGlobal={maxGlobal}
                                reloadQueryOptions={(arr, index, maxPrice) => {
                                    reloadQueryOptions(arr, index, maxPrice)
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        style={{
                            width: "100%",
                            height: 150,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <Button
                            onClick={() => onCloseDialog(false)}
                            sx={{
                                backgroundColor: "gray",
                                color: "white",
                                width: 260,
                                height: 50,
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: COLORS.BG_GRAY_PURPLE,
                                },
                            }}
                        >
                            {translations?.close}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations,
    }
}

export default connect(mapStateToProps, { setSnackBar })(SortDialogForBrand)
