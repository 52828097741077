import { ContactMessage } from './ContactMessage';
import { Serializer } from '../serializer';


export class ContactMessageSerializer implements Serializer {
    fromJson(json: any) {
        const cm = new ContactMessage();
        cm.firstName = json.first_name
        return cm;
    }

    toJson(cm: ContactMessage) {
        return {
            first_name: cm.firstName,
            last_name: cm.lastName,
            email: cm.email,
            phone: cm.phone,
            subject: cm.subject,
            message: cm.message
        };
    }
}