import React, { Fragment } from 'react'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Divider } from '@mui/material'
import { connect } from 'react-redux'
import { setCart, setConfirmation, setFavorites, setSnackBar } from '../../models/application/applicationActions'
import { addToCart, loadFavorites, removeCartItem, Transition } from '../../helpers/utilities'
import { makeStyles } from '@mui/styles'
import { Heart } from '../../assets/icons/Heart'
import { MobileFavoriteItem } from '../Product/MobileFavoriteItem'
import { favoritesService } from '../../models/favorite/service'
import { COLORS } from '../../constants/colors'
import '../styles.scss'
import { Title } from '../Typography/Title'
import { CartItem } from '../../models/cart/cart'

const useStyles = makeStyles({
    rootRow: {
        width: '100%',
        padding: 0,
        marginTop: 20,
        height: window.innerHeight,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
    }
})

const FavsDialog = (props) => {
    const {
        app,
        setCart,
        translations,
        setFavorites,
        setConfirmation,
        language,
        setSnackBar,
        auth } = props
    const [open, setOpen] = React.useState(false);
    const classes = useStyles()
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onUpdateCart = (item: CartItem) => {
        setCart([...app.cart.filter(ci => ci.sku !== item.sku), item])
    }

    const onMoveToCart = (confirmation) => {
        const { item } = confirmation
        const [simple] = item.product.simpleProducts.filter(i => i.sku === item.sku)
        addToCart(item.product, simple.sku, simple, app, auth, 1).then((r: any) => {
            setCart(r)
            localStorage.setItem("cart", JSON.stringify(r))
            setSnackBar({ ...app.snackBar, open: true, text: `${item?.name} ${translations?.has_been_added_to_cart}`, bColor: "rgb(83, 148, 96)" })
            setTimeout(() => {
                onRemoveFavorite(confirmation)
            }, 1200)
        })
    }

    const onRemoveFavorite = (confirmation) => {
        if (auth.loggedIn) {
            favoritesService.delete(confirmation.item.sku).then(() => {
                loadFavorites(setFavorites)
                setConfirmation({ ...confirmation, open: false })
                setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted_from_your_favorites}`, bColor: "rgb(83, 148, 96)" })
            })
        } else {
            removeCartItem(confirmation.item.sku, app, setFavorites)
            setConfirmation({ ...confirmation, open: false })
            setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted_from_your_favorites}`, bColor: "rgb(83, 148, 96)" })
        }
    }


    return <Fragment>
        <Box onClick={handleClickOpen} style={{ paddingTop: 3, position: 'relative' }}>
            {app.favorites?.length > 0 && <div style={{ width: 17, height: 17, position: 'absolute', backgroundColor: COLORS.RED, borderRadius: 20, right: -5, top: 0 }}>
                <span style={{ fontSize: 12, top: 1, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>{app?.favorites?.length}</span>
            </div>}
            <Heart width={23} height={36} />
        </Box>
        <Dialog
            maxWidth='md'
            style={{
                padding: 0, minWidth: window.innerWidth > 1200 ? 1200 : '100%',
                overflow: 'hidden'
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <div style={{ position: 'absolute', right: window.innerWidth * .03, top: 10, cursor: 'pointer' }} onClick={handleClose}>
                <CloseIcon />
            </div>
            <Box style={{ padding: 10, backgroundColor: COLORS.BODY_BG, display: 'flex', flexDirection: 'row', height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <Heart width={23} height={36} />
                <Title
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    text={translations?.my_favorites}
                    fontSize={17}
                    style={{ backgroundColor: COLORS.BODY_BG, marginLeft: 10 }}
                    uppercase={true}
                    className="roboto"
                    fontWeight={500} />
            </Box>
            <Box className={classes.rootRow} style={{ overflowY: 'scroll' }}>
                {!app?.favorites?.length && <Box style={{
                    width: window.innerWidth, paddingTop: 40,
                    justifyContent: 'center', alignItems: 'center', display: 'flex'
                }}>
                    {translations?.no_items_in_favs}
                </Box>}
                {app.favorites && app?.favorites.sort((n1, n2) => { if (n1.created > n2.created) { return -1; } if (n1.created < n2.created) { return 1; } return 0; }).map((item, index) => {
                    return <Box key={index} sx={{
                        width: '100%',
                        height: window.innerWidth > 400 ? '200px' : ' 170px',
                        minHeight: window.innerWidth > 400 ? '200px' : ' 170px',
                        borderRadius: 2,
                        marginTop: 2,
                    }}>
                        <MobileFavoriteItem
                            onUpdateCart={onUpdateCart}
                            item={item}
                            translations={translations}
                            redirect={() => { }}
                            key={index}
                            onMoveToCart={(item) => {
                                setConfirmation({
                                    open: true,
                                    item,
                                    type: 'modeItemToCart',
                                    textAccept: `${translations?.move}`,
                                    text: `${translations?.do_you_really_want_to_move_to_cart}`,
                                    title: item.product.name,
                                    onAgreed: onMoveToCart
                                })
                            }}
                            onRemove={(item) => {
                                setConfirmation({
                                    open: true,
                                    item,
                                    type: 'removeItemFromFavorites',
                                    textAccept: `${translations?.delete}`,
                                    text: `${translations?.do_you_really_want_to_delete_from_favorites}`,
                                    title: item.product.name,
                                    onAgreed: onRemoveFavorite
                                })
                            }}
                        />
                        <Divider component="div" flexItem sx={{ color: 'red', marginTop: window.innerWidth > 400 ? 7 : 4 }} />
                    </Box>
                })}
            </Box>
        </Dialog>
    </Fragment>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, {
    setCart,
    setSnackBar,
    setConfirmation,
    setFavorites,
})(FavsDialog)
