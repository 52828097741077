import { Box, FormControl, FormHelperText, TextField, useFormControl } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { COLORS } from '../../constants/colors'
import { QueryOptions } from '../../helpers/query.options'
import { loyaltyService } from '../../models/Coupon/service'
import { BlackButton } from '../Button/BlackButton'
import { ConfirmationDialog } from '../Confirmation/ConfirmationDialog'

function MyFormHelperText(props) {
  const { text, translations } = props
  const { focused } = useFormControl() || {};

  const helperText = React.useMemo(() => {
    if (focused) {
      return "";
    }

    return text?.length ? text : translations.enter_voucher_and_get_discount;
  }, [focused]);

  return <FormHelperText>{helperText}</FormHelperText>;
}
export const LoyaltyCardComponent = (props) => {
  const { 
    language,
    onError,
    loyaltyCard,
    setLoyaltyCard,
    onReloadCart,
    translations } = props
  const [couponName, setCouponName] = useState("")
  const [confirmationOpen, setConfirmationOpen] = useState(false)



  const checkCard = (id) => {
    const options = new QueryOptions()
    options.lang = language
    loyaltyService.read(id, options).then((r: any) => {
      if (r?.status === 404) {
        onError("discount_not_compatible")
        return
      } else {
        if (r?.products?.length) {
          setLoyaltyCard({ ...r, id })
        } else {
          onError()
        }
      }
    })
  }

  useEffect(() => {


    return () => {
    }
  }, [])

  return <Box style={{ display: 'flex', flexDirection: window.innerWidth > 1200 ? 'row' : 'column', width: window.innerWidth > 1200 ? '80%' : '100%', justifyContent: 'center', alignItems: 'flex-start' }}>
    <ConfirmationDialog
      translations={translations}
      open={confirmationOpen}
      title={translations.coupon}
      text={translations.do_you_want_to_cancel_loyalty_card}
      setOpen={() => {
        setConfirmationOpen(false)
      }}
      textAccept={translations.yes}
      textCancel={translations.no}
      onAgreed={() => {
        setLoyaltyCard(null)
        onReloadCart()
        setConfirmationOpen(false)
      }} />
    <Box component="form" noValidate autoComplete="off" sx={{ width: '100%' }}>
      <FormControl sx={{ width: window.innerWidth > 1200 ? '90%' : '100%', flexDirection: 'column', display: 'flex', position: 'relative', justifyContent: 'center' }}>
        {loyaltyCard?.discount && <Box
          onClick={() => {
            setConfirmationOpen(true)
          }}
          sx={{
            position: 'absolute', right: 10, top: 10, fontSize: 12,
            height: 18,
            cursor: 'pointer',
            display: 'flex',
            zIndex: 1000,
            border: '1px solid #333',
            backgroundColor: COLORS.BEIGE,
            padding: 1,
            borderRadius: 1,
            align: 'center',
            justifyContent: 'center',
            paddingTop: 1.4
          }}>
          {translations.cancel}
        </Box>}
        <TextField
          disabled={loyaltyCard?.discount}
          InputLabelProps={{ style: { fontSize: 14, fontFamily: 'FiraGo' } }}
          onChange={(evt) => {
            setCouponName(evt.target.value)
          }}
          id="outlined-basic"
          label={loyaltyCard?.discount ? `${translations.discount} ${loyaltyCard?.discount} %` : `${translations.introduce} ${translations.owners_id}`} variant="outlined" sx={{}} />
        {loyaltyCard && <MyFormHelperText {...props} text={loyaltyCard?.discount ? `${translations.loyalty_card_discount} ${loyaltyCard?.discount} %` : translations.get_loyalty_card_discount} />}
      </FormControl>

    </Box>
    <Box component="form" sx={{ width: '100%', padding: 0, marginTop: window.innerWidth < 1200 ? 2 : 0 }}>
      <BlackButton
        title={translations?.send}
        height={55}
        onClick={() => {
          if (couponName?.length) {
            checkCard(couponName)
          }
        }}
      />
    </Box>
  </Box>

}

