import { Box, Typography } from '@mui/material'
import React from 'react'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

export const DeliveryWarningComponent = (props) => {
    const {setOpen, translations} = props
    const {width} = useWindowDimensions()
    return (
        <Box
            sx={{
                height: width < 600 ? "150px" : "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#CD201F",
            }}
        >
            <Typography
                sx={{
                    paddingTop: width < 600 ? 2 : 0,
                    color: "#e8e8e8",
                    cursor: "pointer",
                    width: width < 600 ? "90%" : "auto",
                    "&:hover": {
                        color: "white",
                    },
                }}
                onClick={() => setOpen(true)}
            >
                {translations?.holiday_delivery_info}
            </Typography>
        </Box>
    )
}