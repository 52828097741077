import { Box, Button } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import { ShoppingBag } from "../../assets/icons/ShoppingBag";
import { COLORS } from "../../constants/colors";
import { Title } from "../Typography/Title";

export const AlsoUseComponent = (props) => {
    const { alsoUserdProduct, language, translations } = props
    return <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', padding: 1 }}>
        <Box sx={{ width: '15%', }}>
            <img src={alsoUserdProduct.mainImage} style={{ width: '100%', borderRadius: 0 }} />
        </Box>
        <Box sx={{ width: '35%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Title text={alsoUserdProduct?.brand?.title} fontSize={20} uppercase={true} fontWeight='700' fontFamily="Roboto" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Title text={alsoUserdProduct?.name} fontSize={14} uppercase={true}
                    className="roboto"
                    fontWeight='500' fontFamily='Roboto' />
            </div>
        </Box>
        <Box sx={{ width: '50%', backgroundColor: 'white', alignItems: 'center', justifyContent: 'flex-end', display: 'flex'}}>
            <Button
                sx={{
                    color: 'white',
                    backgroundColor: "#444",
                    '&:hover': {
                        color: 'white',
                        backgroundColor: "#444",
                    },
                    width: 190,
                    height: 40,
                    borderRadius: 10,
                    display: 'flex', justifyContent: 'space-around'
                }}
                disableRipple={true}
                disableFocusRipple={false}
                color={"secondary"}
                onClick={() => {
                    
                }}
            >
                <ShoppingBag width={28} height={40} color={"white"}/>
                <Title
                    uppercase={true}
                    fontWeight={'500'}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'#fff'}
                    fontSize={12}
                    text={translations?.add_to_cart} />

            </Button>
        </Box>
    </Box>
}