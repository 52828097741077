import { Box } from '@mui/material'
import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import { SearchInput } from '../components/Search/SearchInput'
import { QueryOptions } from '../helpers/query.options'
import { Brand } from '../models/brand/brands'
import { brandService } from '../models/brand/service'
import { Category } from '../models/category/category'
import { categoryService } from '../models/category/service'
import { Product } from '../models/product/product'
import { productService } from '../models/product/service'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import { Title } from '../components/Typography/Title'
import { SearchBrandComponent } from '../components/Brand/SearchBrandComponent'
import { SearchCategoryComponent } from '../components/Category/SearchCategoryComponent'
import { SearchProductComponent } from '../components/Product/SearchProductComponent'
import { onSearch$ } from '../components/Dialogs/SearchDialog'

const SearchPage = (props: any) => {
    const { language, translations } = props
    const history = useHistory()
    const [noResults, setNoResults] = useState(false)
    const [brands, setBrands] = useState<Brand[]>([])
    const [products, setProducts] = useState<Product[]>([])
    const [categories, setCategories] = useState<Category[]>([])

    useEffect(() => {
        onSearch$.subscribe(
            debounced => {
                setNoResults(false)
                if (debounced.length > 2) {
                    getSearchResults(debounced)
                } else {
                    setBrands([])
                    setProducts([])
                }
            }
        );

    }, []);

    const getSearchResults = async (search) => {
     
        const options = new QueryOptions()
        options.lang = language
        options.pageSize = 6
        options.search = search
        const results = await Promise.all([
            brandService.listWithCounter(options),
            productService.listWithCounter(options),
            categoryService.listWithCounter(options)
        ]);
        setBrands(results[0].current)
        setProducts(results[1].current)
        setCategories(results[2].current)
        if (!results[0].current?.length && !results[1].current?.length) {
            setNoResults(true)
        } else {
            setNoResults(false)
        }
    }

    return <Box sx={{ alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 1, position: 'relative' }}>
        <Box sx={{ position: 'absolute', height: 40, width: '100%', top: 10, zIndex: 1000, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SearchInput
                {...props}
                onRedirect={(url: string) => {
                    history.push(url)
                }}
                noResults={noResults}
                onchangeValue={(value) => {
                    if (value.length > 2) {
                        onSearch$.next(value)
                    } else {
                        setBrands([])
                        setProducts([])
                        setCategories([])
                    }
                }}
                categories={categories}
                products={products}
                brands={brands}
            />
        </Box>
        <Box sx={{
            marginTop: 4,
            maxHeight: window.innerHeight - 160, overflow: 'scroll', display: 'flex', flexDirection: 'column', paddingTop: 4, width: '100%', padding: 2
        }}>
            <List
                sx={{
                    paddingBottom: 4,
                    width: '100%',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    '& ul': { padding: 0 },
                }}
                subheader={<li />}
            >
                {[
                    { name: translations.brands, id: 'brands' },
                    { name: translations?.categories, id: 'categories' },
                    { name: translations?.products, id: 'products' }
                ].map((item, index) => (
                    <li key={index}>
                        <ul>
                            {item.id === 'brands' && brands?.length > 0 && <ListSubheader sx={{ backgroundColor: '#444' }}>
                                <Title text={`${item.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"white"} />
                            </ListSubheader>}
                            {item.id === 'categories' && categories?.length > 0 && <ListSubheader sx={{ backgroundColor: '#444' }}>
                                <Title text={`${item.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"white"} />
                            </ListSubheader>}
                            {item.id === 'products' && products?.length > 0 && <ListSubheader sx={{ backgroundColor: '#444' }}>
                                <Title text={`${item.name}`} fontSize={15} uppercase={true} fontWeight='400' color={"white"} />
                            </ListSubheader>}
                            {item.id === 'brands' &&
                                brands?.length > 0 && <Box sx={{}}>
                                    {brands.map((brand, ind) => {
                                        return <ListItem key={`item-${brand.id}-`} sx={{ padding: 0 }}>
                                            <SearchBrandComponent item={brand} key={index} onClick={() => {
                                            }} />
                                        </ListItem>
                                    })}
                                </Box>
                            }
                            {item.id === 'categories' &&
                                categories.map((category, index) => {
                                    return <SearchCategoryComponent item={category} key={index} onClick={() => {
                                        
                                    }} />
                                })
                            }
                            {item.id === 'products' &&
                                products?.length > 0 &&
                                products.map((item, index) => {
                                    return <SearchProductComponent
                                        item={item}
                                        key={index}
                                        onClick={() => {
                                        }} />
                                })}
                        </ul>
                    </li>
                ))}
            </List>

        </Box>
    </Box>




}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        loggedIn: state?.auth?.loggedIn,
        translations: state.translations
    }
}

export default connect(mapStateToProps, {})(SearchPage)