import React, { useState, useEffect, Fragment } from "react";
import { AppBar, Toolbar, Drawer, Box, ClickAwayListener } from '@mui/material';
import { connect } from 'react-redux'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useHistory, useLocation } from "react-router-dom";
import '../styles.scss'
import { Animated } from "react-animated-css";
import { Grid, ThemeProvider } from "@mui/material";
import { setCart, setConfirmation, setFavorites, setSnackBar, setGoToCartDialog } from "../../models/application/applicationActions";
import { categoryService } from "../../models/category/service";
import { QueryOptions } from "../../helpers/query.options";
import { Category } from "../../models/category/category";
import { RenderMenu } from "./Menu";
import { getWindowDimensions, initCartAndFavs, loadCart, loadCartItems, loadFavorites, removeCartItem, removeFavoriteItem } from "../../helpers/utilities";
import { COLORS } from "../../constants/colors";
import { theme } from "../../styles";
import { PhoneNumber } from "../General/PhoneNumber";
import { SearchInput } from "../Search/SearchInput";
import { HeaderIcons } from "./HeaderIcons";
import { Logo } from "../../assets/icons/Logo";
import { Languages } from "./Languages";
import { environment } from "../../environment/environment";
import { cartService } from "../../models/cart/service";
import { ConfirmationDialog } from "../Confirmation/ConfirmationDialog";
import { SnackbarComponent } from "../Snackbar/Snackbar";
import { favoritesService } from "../../models/favorite/service";
import { brandLetterService, brandService } from "../../models/brand/service";
import { Brand, BrandLetter } from "../../models/brand/brands";
import { setLanguage } from "../../models/language/languageActions";
import { MobileMenu } from "./MobileMenu";
import { GoToCheckoutDialog } from "../Dialogs/GoToCheckoutDialog";
import { MobileHeader } from "./MobileHeader";
import { login } from "../../models/user/authActions";
import { Title } from "../Typography/Title";
import { FeedbackDialog } from "../Dialogs/FeedbackDialog";
import { useCookies } from 'react-cookie';
import { metaService } from "../../models/meta/service";
import { TopHeaderBannerComponent } from "../TopHeaderBanner/TopHeaderBanner";
import { InfoPage } from "../../models/InfoPage/InfoPage";
import { infoPageService } from "../../models/InfoPage/service";


type Anchor = "top" | "left" | "bottom" | "right";

const Bar = (props: any) => {
  const { auth, app, logoutUser, setCart, setConfirmation,
    login,
    setSnackBar, setFavorites, language, setLanguage, translations, setGoToCartDialog } = props
  let user;

  const [letters, setLetters] = useState<BrandLetter[]>([])
  const [brands, setBrands] = useState<Brand[]>([])
  const [activeMenuItem, setActiveMenuItem] = useState<any>({})
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [openLogin, setOpenLogin] = React.useState(false);
  const [headerBannerShown, setHeaderBannerShown] = React.useState(false);
  const [hoverItemId, setHoverItemId] = React.useState('');
  const [snackbar, setSnackbar] = useState<any>({ open: false, text: '' })
  const [scroll, setScroll] = useState(0)
  const history = useHistory();
  const [y, setY] = useState(window.scrollY);
  const [menuItems, setMenuItems] = useState<Category[]>([])
  const { open } = snackbar;
  const location = useLocation()
  const [cookies, setCookie] = useCookies(['lang']);
  const [menuItemLinks, setMenuItemLinks] = useState<InfoPage[]>([])

  const loadInfoPages = async (): Promise<any> => {
      let sec = ['about_us', 'my_profile', 'faq']
      let order = ['my_profile', 'help',]
      const options = new QueryOptions()
      options.sort = 'position'
      options.order = 'asc'
      options.lang = language
      const pages = await infoPageService.listWithCounter(options)
      setMenuItemLinks([...pages.current].filter(item => sec.includes(item.id)).sort((a, b) => order.indexOf(a.section) - order.indexOf(b.section)))
  }

  useEffect(() => {
      loadInfoPages()
  },[])


  const loadCategory = (language) => {
    const options = new QueryOptions()
    options.sort = "sort"
    options.order = "desc"
    options.lang = language
    categoryService.listWithCounter(options).then(r => {
      setMenuItems(r.current)
    })
  }

  const loadMeta = async (url) => {
    const options = new QueryOptions()
    options.lang = language
    options.url = url
    const meta = await metaService.list(options)
    const [md] = meta.results
    if (md) {
      document.title = md.title === "Main page" ? "Voulez Vous" :  md.title
    } else {
      loadMeta("/")
    }
  }

  useEffect(() => {
    loadMeta(location?.pathname)
  }, [location?.pathname])
  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, [location?.pathname])

  useEffect(() => {
    return history.listen((location) => {
      setHoverItemId("")
    })
  }, [history])

  useEffect(() => {
    console.log(language)
    setCookie('lang', language, { path: '/' });
  }, [language])

  const loadBrands = (language) => {
    const options = new QueryOptions()
    options.pageSize = 100
    options.lang = language
    options.sort = "title_eng"
    options.order = "asc"
    brandService.listWithCounter(options).then(r => {
      setBrands(r.current)
    })
  }



  useEffect(() => {

    setActiveMenuItem(localStorage.getItem("activeMenuItem"))

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

  }, []);

  useEffect(() => {
    setActiveMenuItem(localStorage.getItem("activeMenuItem"))
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [language]);

  useEffect(() => {
    if (language) {
      loadCategory(language)
      loadBrands(language)
      loadLetters(language)
    }
  }, [language]);

  useEffect(() => {
    if (auth.loggedIn) {
      setOpenLogin(false)
    }
  }, [auth])

  const loadLetters = (language) => {
    const options = new QueryOptions()
    options.pageSize = 100
    options.lang = language
    options.sort = "_id"
    options.order = "asc"
    brandLetterService.list(options).then(r => {
      setLetters(r.results)
    })
  }


  useEffect(() => {
    setSnackbar(app.snackBar)
  }, [app.snackBar])

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  function redirect(url: string) {
    history.push(url);
  }

  useEffect(() => {
    window.addEventListener("scroll", (e) => { setScroll(window.scrollY) });

    return () => { // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => console.log(e));
    };
  }, [y]);


  const onRemoveItem = (confirmation) => {

    if (auth.loggedIn) {
      cartService.delete(confirmation.item.sku).then(() => {
        loadCartItems(setCart, language)
        setConfirmation({ ...confirmation, open: false })
        setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted}`, bColor: "rgb(83, 148, 96)" })
      })
    } else {
      removeCartItem(confirmation.item.sku, app, setCart)
      setConfirmation({ ...confirmation, open: false })
      setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted}`, bColor: "rgb(83, 148, 96)" })
    }
  }
  const onRemoveFavorite = (confirmation) => {
    if (auth.loggedIn) {
      favoritesService.delete(confirmation.item.sku).then(() => {
        loadFavorites(setFavorites)
        setConfirmation({ ...confirmation, open: false })
        setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted_from_your_favorites}`, bColor: "rgb(83, 148, 96)" })
      })
    } else {
      removeFavoriteItem(confirmation.item.sku, app, setFavorites)
      setConfirmation({ ...confirmation, open: false })
      setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted_from_your_favorites}`, bColor: "rgb(83, 148, 96)" })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <GoToCheckoutDialog
        {...props}
        open={app?.goToCartDialog}
        translations={translations}
        onDone={() => {
          setGoToCartDialog(false)
        }} />
      <SnackbarComponent
        open={app.snackBar.open}
        setOpen={(open) => setSnackBar({ ...app.snackBar, open, bColor: "rgb(83, 148, 96)" })}
        bColor={app.snackBar.bColor}
        text={app.snackBar.text} />
      <ConfirmationDialog
        translations={translations}
        open={app.confirmation.open}
        title={app.confirmation.title}
        text={app.confirmation.text}
        setOpen={() => {
          setConfirmation({ ...app.confirmation, open: false })
        }}
        textAccept={app.confirmation.textAccept}
        onAgreed={() => {
          app.confirmation.onAgreed(app.confirmation)
          setConfirmation({ ...app.confirmation, open: false })
        }} />
      {windowDimensions.width > 1200 && <TopHeaderBannerComponent {...props}
        onShown={setHeaderBannerShown} />}
      {<AppBar position="static" sx={{
        '& .MuiToolbar-root': {
          boxShadow: '0px 0px 4px rgba(0,0,0,0)'
        },
        backgroundColor: 'white',
      }} color="primary" elevation={0}>
        {windowDimensions.width < 1200 && <Box style={{ height: 70 }}></Box>}

        <Toolbar
          sx={{
            padding: 0,
            alignItems: 'flex-start',
            width: '100%',
            boxShadow: '0px 0px 10px rgba(0,0,0,.2)',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            position: windowDimensions.width > 1200 ? 'relative' : 'fixed',
            zIndex: 1001,
          }}
          className="container"
          variant="dense">
          <FeedbackDialog
            {...props}
            onSuccess={() => {
              setSnackBar({ ...app.snackBar, open: true, text: translations.comment_sent, bColor: "rgb(83, 148, 96)" })
            }}
            setOpen={setFeedbackDialogOpen}
            translations={translations}
            open={feedbackDialogOpen} />

          {windowDimensions.width < 1200 &&
            <Fragment>
              {headerBannerShown && <Box sx={{ padding: 1 }}>
                <TopHeaderBannerComponent {...props}
                  onShown={setHeaderBannerShown}
                />
              </Box>}
              <Box sx={{
                display: 'flex', alignItems: 'center', paddingLeft: 1, width: '100%', flexDirection: 'column',
                height: '45%', backgroundColor: COLORS.BG_BEIGE
              }}>
                <Box sx={{ width: '100%', alignItems: 'center', display: 'flex' }} onClick={() => { history.push("/") }}>
                  <Logo width={'100%'} height={40} />
                </Box>
              </Box>
            </Fragment>

          }
          {windowDimensions.width < 1200 &&

            <MobileHeader
              {...props}
              toggleDrawer={toggleDrawer}
              onRemoveItem={onRemoveItem}
              onRemoveFavorite={onRemoveFavorite}
              setConfirmation={setConfirmation}
            />
          }
          {windowDimensions.width > 1200 && <Grid container style={{
            backgroundColor: COLORS.BG_BEIGE, width: '100%'
          }}>
            {environment.beta && <Box style={{
              position: 'absolute',
              top: 35,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 200,
              backgroundColor: COLORS.BG_DARK,
              height: 20
            }}
              onClick={() => {
                setFeedbackDialogOpen(true)
              }}
            >
              <Title text={`${translations?.beta_version} ${environment.version}`} fontSize={12} uppercase={false} fontWeight='400' color={"#fff"} />

            </Box>}
            <Grid item lg={12} sm={12} style={{ width: 1200, margin: '0px auto' }} className="beige-header">
              <Grid item lg={4} sm={12} style={{ paddingLeft:"20px", width: '50%', maxHeight: 30, display: 'flex', alignItems: 'flex-start' }}>
                <PhoneNumber
                  radius={20}
                  left={10}
                  height={22}
                  iconSize={12}
                  fontSize={12}
                  color={'black'} phoneNumber={environment.company.phoneNumber} />
              </Grid>
              <Grid item lg={4} sm={12} style={{ width: '50%', maxHeight: 30, display: 'flex', alignItems: 'center' }}>
                {environment.beta && <Box sx={{ 
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex', flexDirection: 'row', cursor: 'pointer' }} onClick={() => {
                  setFeedbackDialogOpen(true)
                }}>
                  <Title text={`${translations?.feedback_text}`} fontSize={14} uppercase={false} fontWeight='400' color={"#444"} />
                  <SupportAgentIcon style={{ color: '#444' }} />

                </Box>}
              </Grid>
              <Grid item lg={4} sm={12} style={{
                width: '50%', maxHeight: 30, display: 'flex', alignItems: 'center',
                paddingTop: 10,
                justifyContent: 'flex-end'
              }}>
                <Languages chosenLanguage={language} setLanguage={(lang) => {
                  setLanguage(lang)
                }} />
              </Grid>
            </Grid>
          </Grid>}

          <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {<MobileMenu
              {...props}
              setLanguage={setLanguage}
              history={history}
              menuItems={menuItems}
              user={auth.user}
              menuItemLinks={menuItemLinks}
              onLogout={() => {
                setConfirmation({
                  open: true, item: null,
                  textAccept: "გამოსვლა",
                  type: 'logout', title: "Voulez-vous", text: translations?.do_you_want_to_sign_out, onAgreed: () => {
                    setTimeout(() => {
                      setState({ ...state, left: false })
                    }, 100)
                    setSnackBar({ ...app.snackBar, open: true, text: translations?.you_signed_out, bColor: "rgb(83, 148, 96)" })
                    logoutUser()
                  }
                })
              }}
              onClose={() => { }}
              translations={translations}
              state={state}
              setState={setState}
              redirect={redirect} />}
          </Drawer>
          {windowDimensions.width > 1200 &&
            <Grid container spacing={0} className="inner-container" style={{}}  >
              <Grid container spacing={0} style={{ width: '100%', maxWidth: 1200, height: 80 }}>
                <Grid item xs={4} lg={4} sm={4} style={{ padding: 20 }}>
                  <ClickAwayListener onClickAway={() => {

                  }}>
                    <SearchInput
                      {...props}
                      onRedirect={(url: string) => {
                        history.push(url)
                      }}
                    />
                  </ClickAwayListener>
                </Grid>
                <Grid item xs={4} sm={4} lg={4} style={{ alignItems: 'center', paddingTop: 5 }}>
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push("/")
                    }}>
                    <Logo />
                  </Box>
                </Grid>
                <Grid item xs={4} lg={4} sm={4} style={{ padding: 20 , paddingLeft: 20}}>
                  <HeaderIcons
                    onLogin={() => {
                      initCartAndFavs(app, { ...auth, loggedIn: true }, setCart, setFavorites)
                      history.push("/")
                    }}
                    login={login}
                    setSnackBar={setSnackBar}
                    translations={translations}
                    removeItem={(item) => {
                      setConfirmation({
                        open: true,
                        item,
                        type: 'removeItemFromCart',
                        textAccept: `${translations?.delete}`,
                        text: `${translations?.do_you_really_want_to_delete_from_cart}`,
                        title: item.product.name, onAgreed: onRemoveItem
                      })
                    }}
                    removeFavorite={(item) => {
                      setConfirmation({
                        open: true,
                        item,
                        type: 'removeItemFromFavorites',
                        textAccept: `${translations?.delete}`,
                        text: `${translations?.do_you_really_want_to_delete_from_favorites}`,
                        title: item.product.name,
                        onAgreed: onRemoveFavorite
                      })
                    }}
                    color={'black'} counter={2} onRedirect={(url: string) => {
                      history.push(url)
                    }}
                    app={app}
                    onLogout={() => {
                      setConfirmation({
                        open: true, item: null,
                        textAccept: translations.logout,
                        type: 'logout', title: "Voulez-vous", text: translations?.do_you_want_to_sign_out, onAgreed: () => {
                          setSnackBar({ ...app.snackBar, open: true, text: translations?.you_signed_out, bColor: "rgb(83, 148, 96)" })
                          logoutUser()
                        }
                      })
                    }}
                    auth={auth}
                  />
                </Grid>
              </Grid>
              <Grid item lg={12} sm={12} style={{ backgroundColor: COLORS.BG_DARK, alignItems: 'center', width: '100%', zIndex: 1000, marginBottom: -10 , minHeight: 26}}>
                <ul className="ul" style={{ width: 1200, alignItems: 'center' }}
                  onMouseLeave={e => {
                    setHoverItemId("")
                  }}>
                  {menuItems.sort((n1, n2) => { if (n1.sort > n2.sort) { return 1; } if (n1.sort < n2.sort) { return -1; } return 0; }).map((item, index) => {
                    return <RenderMenu
                      language={language}
                      brands={brands}
                      letters={letters}
                      key={index}
                      activeMenuItem={activeMenuItem}
                      hoverItemId={hoverItemId}
                      setHoverItemId={setHoverItemId}
                      index={index}
                      item={item} />
                  })}
                </ul>
              </Grid>
            </Grid>
          }
        </Toolbar>

      </AppBar>}


      {windowDimensions.width > 1200 && scroll > 140 + (headerBannerShown ? 50 : 0) &&
        <AppBar position="sticky" sx={{ backgroundColor: 'white', position: 'fixed', maxHeight: 2, padding: 0, top: -7 }}>
          <Animated animationIn="slideInDown" animationOut="fadeOutDown"
            animationInDuration={500}
            isVisible={windowDimensions.width > 1200 && scroll > (140 + (headerBannerShown ? 50 : 0))} >
            <Toolbar
              className="container"
              variant="dense">
              {windowDimensions.width > 1200 &&
                <Grid container spacing={0} className="inner-container" style={{}}  >
                  <Grid item lg={12} sm={12} style={{
                    backgroundColor: COLORS.BG_DARK,
                    alignItems: 'center',
                    width: '100%',
                    zIndex: 10000
                  }}>
                    <ul className="ul" style={{ width: 1200, alignItems: 'center' }} onMouseLeave={e => {
                      setHoverItemId("")
                    }}>
                      {menuItems.map((item, index) => {
                        return <RenderMenu
                          language={language}
                          brands={brands}
                          letters={letters}
                          key={index}
                          activeMenuItem={activeMenuItem}
                          hoverItemId={hoverItemId}
                          setHoverItemId={setHoverItemId}
                          index={index}
                          item={item} />
                      })}
                    </ul>
                  </Grid>
                </Grid>
              }
            </Toolbar>
          </Animated>
        </AppBar>

      }

    </ThemeProvider >
  );
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    language: state.language,
    translations: state.translations
  }
}

export default connect(mapStateToProps, { setSnackBar, login, setCart, setConfirmation, setFavorites, setLanguage, setGoToCartDialog })(Bar)