import React, { useCallback, useEffect, useState } from "react"
import '../../App.css'
import * as Yup from "yup";
import { Box, Grid, TextField } from '@mui/material';
import { Form, Formik, ErrorMessage } from "formik"
import { authenticate, login } from '../../models/user/authActions'
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import { setSnackBar } from "../../models/application/applicationActions";
import { BlackButton } from "../Button/BlackButton";
import { contactMessageService } from "../../models/ContactMessage/service";
import { ContactMessage } from "../../models/ContactMessage/ContactMessage";

const ContactForm = (props: any) => {
    const { onOpen,
        onClose,
        setSnackBar,
        auth,
        translations, item, app, onReload, mode = "edit" } = props
    const [open, setOpen] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleCloseDialog = () => { onClose(false) }
    const handleClose = () => {
        onClose(false)
    }

    const validation = Yup.object().shape({
        firstName: Yup.string().required(`${translations.first_name} ${translations.is_required}`),
        lastName: Yup.string().required(`${translations.last_name} ${translations.is_required}`),
        email: Yup.string().required(`${translations.email} ${translations.is_required}`).email(translations.email_format_error),
        message: Yup.string().required(`${translations.message} ${translations.is_required}`),
        subject: Yup.string().required(`${translations.subject} ${translations.is_required}`),
        phone: Yup.string().required(`${translations.phone} ${translations.is_required}`)
    })

    useEffect(() => {
        setOpen(onOpen)
    }, [onOpen])

    useEffect(() => {
        setLoaded(true)
    }, [])

    const getValues = (values) => {
        
        const cm = new ContactMessage()
        cm.firstName = values.firstName
        cm.lastName = values.lastName
        cm.subject = values.subject
        cm.message = values.message
        cm.phone = values.phone
        cm.email = values.email
        setLoading(true)
        contactMessageService.create(cm).then((resp) => {
            setLoaded(false)
            setTimeout(()=> {
                setLoaded(true)
                
            }, 400)
            setLoading(false)
            setSnackBar({ ...app.snackBar, open: true, text: translations.form_sent_successfully, bColor: "rgb(83, 148, 96)" })
        })
    }

    const RenderFormik = useCallback(()=> {
        return loaded ? <Formik
        validationSchema={validation}
        initialValues={{
            phone: auth.loggedIn ? auth.user?.phone : '',
            firstName: auth.loggedIn ? auth.user.firstName : '',
            lastName: auth.loggedIn ? auth.user.lastName : '',
            email: auth.loggedIn ? auth.user.email : '',
            subject: "",
            message: ""

        }}
        onSubmit={getValues}>
        {({ values,
            validateForm,
            handleSubmit, setFieldValue, handleChange, handleBlur }: any) => (<Animated animationIn="fadeIn" animationOut="fadeOutDown"
                animationInDuration={500}
                isVisible={loaded} >
                <Form onSubmit={handleSubmit}>
                    <Grid container spacing={1} style={{ padding: window.innerWidth > 1200 ? 20 : 0, marginBottom: window.innerWidth > 1200 ? 30:100 }}>
                        <Grid item lg={6} sm={6} xs={12} style={{ paddingRight: 0 }}>
                            <TextField
                                style={{ width: '90%', marginTop: window.innerWidth > 1200 ? 10 : 20 }}
                                id="outlined-basic"
                                label={translations.first_name}
                                color="primary"
                                variant="outlined"
                                type="text"
                                name="firstName"
                                onChange={(event) => {
                                    setFieldValue("firstName", event.target.value)
                                }}
                                onBlur={handleBlur}
                                value={values.firstName}
                            />
                            <Box sx={{
                                padding: .6,
                                paddingLeft: 3,
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                display: 'flex'
                            }}>
                                <ErrorMessage name="firstName" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>

                            </Box>
                        </Grid>
                        <Grid item lg={6}
                            xs={12}
                            sm={6} >
                            <TextField
                                style={{ width: '90%', marginTop: 10, }}
                                id="outlined-basic"
                                label={translations.last_name}
                                color="primary"
                                variant="outlined"
                                type="text"
                                name="lastName"
                                onChange={(event) => {
                                    setFieldValue("lastName", event.target.value)
                                }}
                                onBlur={handleBlur}
                                value={values.lastName}
                            />
                            <Box sx={{
                                padding: .6,
                                paddingLeft: 3,
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                display: 'flex'
                            }}>
                                <ErrorMessage name="lastName" >
                                    {msg => <span className="error" style={{}}>{msg}</span>}
                                </ErrorMessage>

                            </Box>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="outlined-basic"
                                label={translations.email}
                                variant="outlined"
                                type="text"
                                name="email"
                                onChange={(event) => {
                                    setFieldValue("email", event.target.value)
                                }}
                                onBlur={handleBlur}
                                value={values.email}
                            />
                            <Box sx={{
                                padding: .6,
                                paddingLeft: 3,
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                display: 'flex'
                            }}>
                                <ErrorMessage name="email" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>

                            </Box>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="outlined-basic"
                                label={translations.phone}
                                color="primary"
                                variant="outlined"
                                type="text"
                                name="phone"
                                onChange={(event) => {
                                    setFieldValue("phone", event.target.value)
                                }}
                                onBlur={handleBlur}
                                value={values.phone}
                            />
                            <Box sx={{
                                padding: .6,
                                paddingLeft: 3,
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                display: 'flex'
                            }}>
                                <ErrorMessage name="phone" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>
                            </Box>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}>
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="outlined-basic"
                                label={translations.subject}
                                variant="outlined"
                                type="text"
                                name="subject"
                                onChange={(event) => {
                                    setFieldValue("subject", event.target.value)
                                }}
                                onBlur={handleBlur}
                                value={values.subject}
                            />
                            <Box sx={{
                                padding: .6,
                                paddingLeft: 3,
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                display: 'flex'
                            }}>
                                <ErrorMessage name="subject" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>
                            </Box>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={12}></Grid>

                        <Grid item lg={12} sm={6} xs={12}>
                            <TextField
                                multiline
                                style={{ width: window.innerWidth > 1200 ? '95%' : '90%', marginTop: 10, height: 120, minHeight: 120, marginBottom: 30, }}
                                id="outlined-basic"
                                label={translations.message}
                                color="primary"
                                variant="outlined"
                                type="text"
                                name="message"
                                onChange={(event) => {
                                    setFieldValue("message", event.target.value)
                                }}
                                rows={5}
                                onBlur={handleBlur}
                                value={values.personalId}
                            />
                            <Box sx={{
                                padding: .6,
                                paddingLeft: 3,
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                display: 'flex'
                            }}>
                                <ErrorMessage name="message" >
                                    {msg => <span className="error">{msg}</span>}
                                </ErrorMessage>

                            </Box>
                        </Grid>

                        <Grid item lg={6} sm={6} style={{ paddingLeft: 5, width: '100%' }}></Grid>
                        <Grid item lg={6} sm={6}
                            style={{ paddingLeft: 5, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                            {true && <BlackButton
                                style={{ width: '90%' }}
                                loading={loading}
                                title={translations.save}
                                onClick={() => {
                                    if (!loading) {
                                        validateForm().then((errors) => {
                                            console.log(errors)
                                            handleSubmit();
                                        })

                                    }
                                }}
                            />}

                        </Grid>

                    </Grid>

                </Form>

            </Animated>)}
    </Formik>: <></>
    }, [loaded, loading, translations])

    return RenderFormik()

}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
    }
}

export default connect(mapStateToProps, { authenticate, login, setSnackBar })(ContactForm)