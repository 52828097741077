
import { Typography, Box, Tooltip, IconButton } from '@mui/material';
import React, { useState, useEffect, useCallback } from "react";
import { GiftCard } from '../../assets/icons/GiftCard';
import { Heart } from '../../assets/icons/Heart';
import { ShoppingBag } from '../../assets/icons/ShoppingBag';
import { User } from '../../assets/icons/User';
import { COLORS } from '../../constants/colors';
import { AccountPopup } from '../User/AccountPopup';
import { useComponentVisible } from '../../helpers/utilities';
import { Animated } from "react-animated-css";
import { CartPopup } from '../User/CartPopup';
import { FavoritesPopup } from '../User/FavoritesPopup';
import { Title } from '../Typography/Title';
import { useHistory } from 'react-router';
import './header.scss'


export const HeaderIcons = (props) => {
    const { onRedirect, auth, onLogout, app, removeItem, removeFavorite, translations, setSnackBar, login, onLogin } = props
    const [userAccounMenuVisible, setUserAccounMenuVisible] = useState(false)
    const [cartVisible, setCartVisible] = useState(false)
    const history = useHistory()
    const [favsVisible, setFavsVisible] = useState(false)

    useEffect(() => {
        setIsComponentVisible(userAccounMenuVisible)
    }, [userAccounMenuVisible])

    useEffect(() => {
        setIsComponentVisible(cartVisible)
    }, [cartVisible])

    useEffect(() => {
        setIsComponentVisible(favsVisible)
    }, [favsVisible])

    const RenderCartPopup = useCallback(() => {
        return <CartPopup
            {...props}

            removeItem={(item) => {
                setCartVisible(true)
                removeItem(item)
            }}
            redirect={(url) => {
                onRedirect(url)
            }}
            cart={app?.cart} />
    }, [app?.cart, translations])

    const RenderFavoritesPopup = useCallback(() => {
        return <FavoritesPopup
            translations={translations}
            removeItem={(item) => {
                setFavsVisible(true)
                removeFavorite(item)
            }}
            redirect={(url) => {
                onRedirect(url)
            }}
            favorites={app?.favorites} />
    }, [app?.favorites, translations])

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(userAccounMenuVisible);
    return <Box  className="header-icons" style={{marginLeft: 28}}>
        <div style={{ position: 'relative', width: 120, display: 'inline-block'}}
            onMouseEnter={(e) => {
                setUserAccounMenuVisible(true)
            }}
            onMouseLeave={(e) => {
                setUserAccounMenuVisible(false)
            }}>
            <Box style={{ color: 'red', display: 'flex', flexDirection: 'row', width: 120 , cursor: 'pointer'}}>
                <User width={24} height={32} />
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 10 }}>
                    <Title text={`${auth.loggedIn ? auth.user.firstName : translations?.login}`} fontSize={15} uppercase={true} fontWeight='400' color={"#444"} />
                </Box>
            </Box>
            <div ref={ref}>
                {userAccounMenuVisible &&
                    <div style={{
                        width: 260,
                        top: 30,
                        borderRadius: 5,
                        left: -40,
                        height: 340,
                        backgroundColor: 'white',
                        position: 'absolute',
                        zIndex: 100000,
                        boxShadow: '0px 0px 7px rgba(0,0,0,.5)'
                    }}>
                        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={userAccounMenuVisible}>
                            <AccountPopup
                                onLogin={onLogin}
                                app={app}
                                login={login}
                                setSnackbar={setSnackBar}
                                {...props}
                                translations={translations}
                                auth={auth}
                                visible={isComponentVisible}
                                onLogout={() => {
                                    onLogout()
                                }}
                                redirect={(url) => {
                                    setUserAccounMenuVisible(false)
                                    onRedirect(url)
                                }} />
                        </Animated>
                    </div>
                }
            </div>
        </div>
        <div style={{ position: 'relative', width: 55, display: 'inline-block', cursor: 'pointer', top: -1 }} onClick={() => {
            history.push("/gift_card")
        }}>
            <GiftCard width={24} height={35} />
        </div>

        <div style={{ position: 'relative', width: 50, display: 'inline-block', cursor: 'pointer' }}
            onMouseEnter={(e) => {
                if (app?.favorites?.length) {
                    setFavsVisible(true)
                }
            }}
            onClick={()=> {
                if (!app?.favorites?.length) {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: translations.your_fav_list_is_empty, open: true })
                }
            }}
            onMouseLeave={(e) => {
                setFavsVisible(false)
            }}>
            {app.favorites?.length > 0 && <div style={{ width: 17, height: 17, position: 'absolute', backgroundColor: COLORS.RED, borderRadius: 20, right: 2, top: 5, zIndex: 10 }}>
                <span style={{ fontSize: 12, top: 1, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>{app?.favorites?.length}</span>
            </div>}
            <IconButton
            disableRipple
            onClick={() => onRedirect("/favorites")}
            >

            <Heart width={25} height={35} />
            </IconButton>
            <div ref={ref}>
                {favsVisible &&
                    <div style={{
                        width: 260,
                        top: 34,
                        borderRadius: 5,
                        left: window.innerWidth> 1470  ? -40: -153,
                        height: 340,
                        backgroundColor: 'white',
                        position: 'absolute',
                        zIndex: 100000,
                        boxShadow: '0px 0px 7px rgba(0,0,0,.5)'
                    }}>
                        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={favsVisible}>
                            <RenderFavoritesPopup />
                        </Animated>
                    </div>
                }
            </div>
        </div>
        <div style={{ position: 'relative', width: 45, display: 'inline-block', marginLeft: 10, cursor: 'pointer' }}
            onMouseEnter={(e) => {
                if (app?.cart?.length) {
                    setCartVisible(true)
                }
            }}
            onClick={()=> {
                if (!app?.cart?.length) {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: translations.your_cart_is_empty, open: true })
                }
            }}
            onMouseLeave={(e) => {
                setCartVisible(false)
            }}>
            {app?.cart?.length > 0 && <div style={{ width: 17, height: 17, position: 'absolute', backgroundColor: COLORS.RED, borderRadius: 20, right: 5, top: 5, zIndex: 10 }}>
                <span style={{ fontSize: 12, top: 1, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>{app?.cart?.length}</span>
            </div>}
            <IconButton
            disableRipple
             onClick={() =>  onRedirect("/checkout")}
            >

                <ShoppingBag width={28} height={35} />
            </IconButton>
            <div ref={ref}>
                {isComponentVisible && cartVisible &&
                    <div style={{
                        width: 260,
                        top: 34,
                        borderRadius: 5,
                        left: window.innerWidth> 1470  ? -100: -213,
                        height: 340,
                        backgroundColor: 'white',
                        position: 'absolute',
                        zIndex: 100000,
                        boxShadow: '0px 0px 7px rgba(0,0,0,.5)'
                    }}>
                        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isComponentVisible}>
                            <RenderCartPopup />
                        </Animated>
                    </div>
                }
            </div>
        </div>
    </Box>
}
export const HeaderIconsMobile = (props) => {
    return <Box style={{ display: 'inline-block', padding: 6, position: 'relative', width: '100%', height: 25 }}>
        <div style={{ position: 'relative', width: 30, display: 'inline-block', top: -4 }}>
            <User width={24} height={32} />
        </div>
        <div style={{ position: 'relative', width: 30, display: 'inline-block', top: -7 }}>
            <GiftCard width={24} height={35} />
        </div>
        <div style={{ position: 'relative', width: 35, display: 'inline-block', top: -4 }}>
            {props.counter && <div style={{ width: 17, height: 17, position: 'absolute', backgroundColor: COLORS.RED, borderRadius: 20, right: 2, top: -3 }}>
                <span style={{ fontSize: 12, top: 1, position: 'absolute', left: 5 }}>{props.counter}</span>
            </div>}
            <Heart width={24} height={36} />
        </div>
        <div style={{ position: 'relative', width: 40, display: 'inline-block', top: -2 }}>
            {props.counter && <div style={{ width: 17, height: 17, position: 'absolute', backgroundColor: COLORS.RED, borderRadius: 20, right: 5, top: -1 }}>
                <span style={{ fontSize: 12, top: 1, position: 'absolute', left: 5 }}>{props.counter}</span>
            </div>}
            <ShoppingBag width={28} height={40} />
        </div>
    </Box>
}