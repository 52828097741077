import { Box } from "@mui/material"
import React from "react"
import { RateItem } from "../Review/RateItem"
import { Title } from "../Typography/Title"
import { AlsoUseComponent } from "./AlsoUseComponent"


export const ProductDetailsLowerRow = (props) => {
  const { reviewSummary, translations, setShowReviewDialog, auth, product } = props
  return <Box style={{ marginTop: 30, padding: 0, width: '100%', position: 'relative', backgroundColor: 'transparent ', display: 'flex' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '10%', }}></Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '30%', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'space-around', justifyContent: 'space-around', padding: 1 }} >
        {reviewSummary?.average > 0 && <div style={{ marginTop: 0 }}>
          <RateItem rate={reviewSummary.average} translations={translations} count={reviewSummary?.count} />
        </div>}
        {reviewSummary?.average > 0 && <Title text={`${reviewSummary?.count} ${translations?.comments}`}
          style={{ whiteSpace: 'nowrap', padding: 0, marginLeft: 10 }}
          fontSize={15} uppercase={false} fontWeight='500' color={"black"} />}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'space-around', justifyContent: 'space-around', padding: 1 }} >
        {auth?.loggedIn && <div style={{ marginTop: -10, flex: 1, display: 'flex', cursor: 'pointer' }}
          onClick={() => {
            setShowReviewDialog(true)
          }}
        >
          <Title text={`${translations?.review_product}`}
            style={{ alignSelf: 'left', whiteSpace: 'nowrap' }}
            fontSize={15} uppercase={false} fontWeight='500' color={"black"} />
        </div>}
      </Box>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '5%', alignItems: 'center', position: 'relative' }}></Box>
    {product.alsoUseProduct && <Box sx={{ display: 'flex', flexDirection: 'column', width: '45%', alignItems: 'center', borderTop: '1px #444 solid', position: 'relative' }}>
      <Title text={`${translations?.also_use}`}
        style={{ position: 'absolute', top: -14, left: -4, backgroundColor: 'white', paddingRight: 20, paddingLeft: 10 }}
        fontSize={14} uppercase={true} fontWeight='500' color={"black"} />
      <AlsoUseComponent alsoUserdProduct={product.alsoUseProduct} {...props} />
    </Box>}
  </Box>
}