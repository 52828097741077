import { environment } from '../../environment/environment'

import { TranslationSerializer } from './serializer'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { Translation } from './translation'



interface SharedObject {
  	[id: string]: any
}

export class TranslationService {
	sharedObj: SharedObject = {}
    searchTranslation = {
		eng: 'Search',
		geo: 'ძებნა'
    }

    constructor() {
		this.sharedObj.lang = 'geo'
    }

    public list(lang: string): Observable<Translation[]> {
        const endpoint = `translations`
		const url = `${environment.basePath}/${endpoint}`
		return ajax({url, method:'GET'}).pipe(map((data: any) => { 
			return data.response[lang]}))
	}
	
	getTranslations(lang: string) {
		const promise = new Promise((resolve, reject) => {
			if (this.sharedObj[lang] ) {
				resolve(this.sharedObj[lang])
			} else {
				this.list(lang).subscribe((resp: any) => {
					this.sharedObj.lang = resp;
					resolve(this.sharedObj.lang)
				})
			}
		})

		return promise
	}

	getTranslation(translations: any, value: any) {
		const [filtered] = translations.filter((translation: Translation) => translation.key === value)

		if (filtered !== undefined) {
			return filtered.value
		} else {
			return ''
		}
	}
}