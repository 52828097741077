import { Tab, Tabs } from "@mui/material"
import React, { useEffect } from "react"

export const SecondStepDesktopTabs = (props) => {
    const { value, handleChange, tabs, setSelected, language } = props

    useEffect(()=> {
        if (tabs.length) {
            const [selected] = tabs
            setSelected(selected.id)
        }
    }, [tabs])

    return (
        <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
                "& .MuiTabs-flexContainer": {
                    gap: 1,
                },
                ".MuiTabs-indicator": {
                    backgroundColor: "black !important",
                },
            }}
        >
            {tabs?.map((item, index) => (
                <Tab
                    key={index}
                    label={item.name}
                    onClick={() => {
                        setSelected(item)
                    }}
                    disableRipple
                    sx={{
                        color: "#000 !important",
                        padding: 0,
                        borderBottom: "1px solid white",
                        display: "flex",
                        justifyContent: "center",
                        textTransform: "none",
                        fontFamily: language === "geo" ? "FiraGo" : "Roboto",
                    }}
                />
            ))}
        </Tabs>
    )
}
