import { register } from "../../models/user/authActions"
import { connect } from 'react-redux'
import { Box, Fade } from "@mui/material"
import { Product, SimpleProduct } from "../../models/product/product"
import { useHistory, useParams } from "react-router"
import { productService, similarProductsService } from "../../models/product/service"
import { setCart, setFavorites, setSnackBar, setGoToCartDialog } from "../../models/application/applicationActions"
import { MainPageProductSlider } from "../../models/MainPageBrandBanner/MainPage"
import { ReviewComponent } from "../../components/Review/ReviewComponent"
import { Review, ReviewSummary } from "../../models/review/Review"
import { reviewSummaryService } from "../../models/review/service"
import React, { useState, useEffect, Fragment, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { QueryOptions } from "../../helpers/query.options";
import '../../App.css'
import { addToCart } from "../../helpers/utilities";
import { AddToFavsButton } from "../../components/Product/Button/AddToFavsButton"
import { AddToCartButton } from "../../components/Product/Button/AddToCartButton"
import { Configurables } from "../../components/Product/Configurables"
import { Title } from "../../components/Typography/Title"
import { MobileImageComponent } from "../../components/Carousel/MobileImageCarousel"
import { ProductDetailsAccordion } from "../../components/Product/Accordion/ProductDetailsAccordion"
import { ProductDetailsLowerRow } from "../../components/Product/ProductDetailsLowerRow"
import ReviewDialog from "../../components/Dialogs/ReviewDialog"
import { CustomButton } from "../../components/Product/Button/CustomButton"
import { StockAvailabilityDialog } from "../../components/Dialogs/StockAvailabilityDialog"
import { COLORS } from "../../constants/colors"

const useStyles = makeStyles({
  container: {
    overflow: 'hidden',
    marginTop: 0,
    flexDirection: 'row',
    position: "relative",
    width: '100%',
    flex: 1,
    height: '100%',
    display: 'flex',
    borderRadius: 5,
  },
  column: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  leftColumn: {
    width: '50%',
    backgroundColor: 'blue'
  },
  tag: {
    alignItems: 'center',
    display: 'flex',
    verticalAlign: 'middle',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    position: "absolute",
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: 'bold',
    left: 10,
    top: -10,
    color: 'white',
    borderRadius: 30,
    width: 40,
    height: 40
  },
  info: {
    marginTop: -10,
    flexDirection: 'row'
  },
  price: {
    color: 'red',
    textDecoration: 'line-through'
  },
  specialPrice: {
    marginLeft: 10
  },
  image: {
    marginBottom: -5,
    top: 0,
    zIndex: 100,
    left: 0,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
  },
  leftcolumn: {
    padding: 10
  }
})

const ProductPage = (props): any => {
  const { setCart, language, translations, app, setSnackBar } = props
  let { id }: any = useParams();
  const [currentImage, setCurrentImage] = useState(0)
  const [imageCarouselLoaded, setImageCarouselLoaded] = useState(false)
  const [price, setPrice] = useState(0)
  const [reviewItems, setReviewItems] = useState<Review[]>([])
  const [product, setProduct] = useState<Product>()
  const [item, setItem] = useState<Product>()
  const history = useHistory()
  const [init, setInit] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [animate, setAnimate] = useState(true)
  const [simple, setSimple] = useState<SimpleProduct>()
  const [similarProducts, setSimilarProducts] = useState<SimpleProduct[]>([])
  const [image, setImage] = useState<any>()
  const [expanded, setExpanded] = React.useState<string | false>('');
  const [sliders, setSliders] = useState<MainPageProductSlider[]>([])
  const [isSimilarVisible, setIsSimilarVisible] = useState(false)
  const [reviewSummary, setReviewSummary] = useState<ReviewSummary>()
  const [imageLoaded, setImageLoaded] = useState(false)
  const [showReviewDialog, setShowReviewDialog] = useState(false)
  const [stockAvailabilityOpen, setStockAvailabilityOpen] = useState(false)

  useEffect(() => {
    loadProduct(id)
    const subscribe = history.listen((location, action) => {
      const id = location.pathname.split("/")[2]
      loadProduct(id)
    });
    return () => subscribe()
  }, [])

  useEffect(() => {
    if (simple) {
      setAnimate(false)
      setTimeout(() => {
        setCurrentImage(0)
      }, 10)
    }
  }, [simple])

  useEffect(() => {
    if (image) {
      const img = new Image()
      img.src = image
      img.onload = () => {
        setImageLoaded(true)
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
      img.onerror = () => {
        setImage("https://via.placeholder.com/250x250")
        img.src = "https://via.placeholder.com/250x250"
      }
    }
  }, [image])


  const loadSimilarProducts = (productId, translations) => {
    const options = new QueryOptions()
    options.lang = language
    similarProductsService.readListWithCounter(options, productId).then((resp: any) => {
      if (!resp.status) {
        setIsSimilarVisible(true)
        setSliders([{ id: "none", title: translations?.similar_products, products: resp.current }])
      }
    })
  }

  useEffect(() => {
    if (simple?.id) {
      const [media] = product.media.filter(m => m.filename === `${simple?.id}.jpg`)
      const [attr] = simple?.attributes.filter(a => a.name.id === getConfigurableId())
      if (media) {
        setCurrentImage(product.media.map(m => m.filename).indexOf(media.filename))
      }
    }
  }, [simple, product?.media])


  useEffect(() => {
    if (product?.id) {
      setPrice(product?.specialPrice)
    }
    if (product?.media?.length) {
      const [item] = product.media
      if (item) {
        const img: any = new Image()
        img.src = item.thumbnail
        img.onload = () => {
          setLoaded(true)
          setInit(false)
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
        img.onerror = () => {
          img.src = "https://via.placeholder.com/250x250"
        }
      }
    }
  }, [product])


  const loadProduct = (id: string) => {
    const options = new QueryOptions()
    options.lang = language
    productService.read(id, options).then((resp: Product) => {
      setProduct(resp)
      setSimilarProducts([])
      setSliders([])
      loadSimilarProducts(resp.id, translations)
      loadReviewSummary(resp?.id)
    })
  }

  const getConfigurableId = () => {
    if (product.configurables?.length) {
      const [config]: any = product.configurables
      return config.name.id
    }
  }

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const RenderReviews = useCallback(() => {
    return <ReviewComponent id={id} {...props} onLoaded={setReviewItems} />
  }, [reviewSummary])


  useEffect(() => {
    if (product?.mainImage) {
      setImage(product.mainImage)
      loadReviewSummary(product.id)
    }
    if (product) {
      setPrice(product?.specialPrice)
    }
    if (product && !product?.configurables?.length) {
      const [simple] = product.simpleProducts.filter(s => s.stock)
      if (simple) {
        setSimple(simple)
      } else {
        const [simple] = product.simpleProducts
        setSimple(simple)
      }
      
    }
  }, [product])

  const loadReviewSummary = (id) => {
    const options = new QueryOptions()
    options.id = id
    reviewSummaryService.read(id, options).then((r: any) => {
      setReviewSummary(r)
    })
  }

  const filteredImages = (simple) => {
    if (product) {
      if (simple) {
        return [...product.media.filter(m => m.filename.indexOf(simple.sku) > -1 || m.isCommon)]
      } else {
        const commonImages = [...product.media.filter(m => m.isCommon)]
        if (!commonImages.length) {
          return product.media
        } else {
          return commonImages
        }
      }
    }
    return []
  }

  return loaded ? <Box sx={{ display: 'flex', justifyContent: 'space-around', padding: 2, flexDirection: 'column', paddingTop: 4 }}>
    <div style={{ display: 'flex', flexDirection: 'column', padding: 0, backgroundColor: 'white' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Title text={product?.brand?.title} fontSize={25} uppercase={true} fontWeight='700' fontFamily="Roboto" />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Title text={product?.name} fontSize={18} uppercase={true}
          className="roboto"
          fontWeight='500' fontFamily='Roboto' />
      </div>
    </div>
    <Fragment>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, paddingLeft: 5 }}>
        {product?.specialPrice && product.specialPrice < product.price ? <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
          <Title text={`${simple ? simple?.price : product.price}₾`} fontSize={22} uppercase={true} fontWeight='400' color={"black"} style={{ marginRight: 5, textDecorationLine: 'line-through' }} />
          <Title text={`${simple ? simple?.specialPrice : product.specialPrice}₾`} fontSize={22} uppercase={true} fontWeight='400' color={"red"} />
        </div> : <Title text={`${simple ? simple.price : product.price} ₾`} fontSize={22} uppercase={true} fontWeight='400' fontFamily='Roboto' color={"#000"} className="roboto" />}
      </div>
    </Fragment>
    <Fade
      in={imageCarouselLoaded}
      style={{ transformOrigin: '0 0 0' }}
      {...(loaded ? { timeout: 2000 } : {})}
    >
      <Box style={{ marginTop: 20, marginBottom: 20 }}>
        <MobileImageComponent items={filteredImages(simple)} onLoaded={() => {
          setImageCarouselLoaded(true)
        }} />
      </Box>

    </Fade>
    <ReviewDialog
      onDone={() => {
        loadReviewSummary(product.id)
      }}
      product={product}
      onReload={() => { }}
      item={item}
      {...props}
      translations={translations}
      open={showReviewDialog}
      onCloseDialog={(resp) => {
        setShowReviewDialog(false)
      }} />
    <Box style={{
      margin: 'auto',
      width: '0%'
    }}>
      <ProductDetailsLowerRow {...props}
        setShowReviewDialog={setShowReviewDialog}
        reviewSummary={reviewSummary}
        product={product} />
    </Box>
    <Configurables item={product} simple={simple} setSimple={setSimple} animate={animate}
      translations={translations}
      language={language} />
    <StockAvailabilityDialog
      {...props}
      selectedItems={product.embeddedInventory.filter(i => i.sku === simple?.sku && i.available)}
      item={product}
      simple={simple}
      items={product?.embeddedInventory}
      open={stockAvailabilityOpen}
      setOpen={(b: boolean) => {
        setStockAvailabilityOpen(false)
      }} />

    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, alignItems: 'center' }}>
      {/* {!product.available && <Title
        fontSize={13}
        color={COLORS.RED}
        text={product?.embeddedInventory.filter(i => i.sku === simple?.sku && i.available).length > 0 ? translations.not_available_online : translations.out_of_stock} />} */}
      {simple && product?.embeddedInventory.filter(i => i.sku === simple?.sku && i.available).length > 0 && <Box style={{ width: '86%', alignItems: 'center', padding: 10 }}>
        <CustomButton
          {...props}
          fontSize={12}
          text={translations.check_availability}
          language={language} onClick={() => {
            setStockAvailabilityOpen(true)
          }} />
      </Box>}
      <Box style={{ width: '86%', alignItems: 'center', padding: 10 }}>
        <AddToCartButton
          {...props}
          available={simple?.stock}
          onOpenAvailability={() => {
            const inShops = product?.embeddedInventory.filter(i => i.sku === simple?.sku && i.available).length > 0
            setTimeout(() => {
              setSnackBar({ ...app.snackBar, open: true, text: inShops ? translations.not_available_online : translations.out_of_stock, bColor: COLORS.RED })
              if (product?.embeddedInventory.filter(i => i.sku === simple?.sku && i.available).length > 0) {
                setStockAvailabilityOpen(true)
              }
            }, 700)
          }}
          item={product}
          onAddedToCart={() => { }}
          onSetSimple={() => { }}
          simple={simple}
          addToCart={addToCart}
          setPrice={setPrice}
          setSimple={setSimple}
          setCart={setCart} />
      </Box>
      <Box style={{ width: '86%', alignItems: 'center', padding: 10 }}>
        <AddToFavsButton
          item={product}
          {...props} simple={simple} />
      </Box>
    </div>
    {translations?.details && product && <ProductDetailsAccordion product={product} translations={translations} language={language} />}
    {(reviewItems?.length > 0 || init) && <RenderReviews />}
    <Box sx={{ height: 120 }}></Box>
  </Box> : <></>
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    language: state.language,
    translations: state.translations
  }
}

export default connect(mapStateToProps, { register, setCart, setFavorites, setSnackBar, setGoToCartDialog })(ProductPage)











