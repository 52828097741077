import React, { useEffect, useState } from "react"
import { Grid, TextField, Box, Typography, Button } from '@mui/material';
import { Form, Formik } from "formik"
import { validationSchemas } from "../../helpers/validations"
import { authenticate, login } from '../../models/user/authActions'
import { Animated } from "react-animated-css";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { cityService } from "../../models/city/service";
import { QueryOptions } from "../../helpers/query.options";
import { City } from "../../models/city/city";
import { addressService } from "../../models/address/service";
import { Address } from "../../models/address/address";
import { connect } from "react-redux";
import { setSnackBar } from "../../models/application/applicationActions";
import '../../App.css'
import { BlackButton } from "../Button/BlackButton";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import * as Yup from "yup"
import { COLORS } from "../../constants/colors";



const AddressForm = (props: any) => {
    const { onOpen, onClose, setSnackBar,
        address,
        language,
        onValuesChange,
        cartIncludesGiftCart=false,
        translations, item, app, onReload, mode = "edit" } = props
    const [open, setOpen] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [cities, setCities] = useState<City[]>([])
    const [submitted, setSubmitted] = useState(false)
    const {width} = useWindowDimensions()
    const handleCloseDialog = () => { onClose(false) }
    const handleClose = () => {
        onClose(false)
    }

    useEffect(() => {
        setOpen(onOpen)
    }, [onOpen])

    useEffect(() => {
        loadCities()
    }, [])

    useEffect(() => {
        if (item) {
            setLoaded(true)
        } else {
            setLoaded(true)
        }
    }, [item])

    const getValues = (values) => {

        const address = new Address()
        address.city = values.city
        address.lang = language
        address.firstName = values.firstName
        address.lastName = values.lastName
        address.personalId = values.personalId
        address.phone = values.phone
        address.street = values.street
        address.comment = values.comment
        
        if (!item) {
            addressService.create(address).then((resp) => {
                onReload(resp)
                onClose(false)
                setSnackBar({ ...app.snackBar, open: true, text: translations.address_added_successfully, bColor: "rgb(83, 148, 96)" })
            })
        } else {
            address.id = item.id
            addressService.update(address).then((resp) => {
                onReload(address)
                onClose(false)
                if (mode === 'edit') {
                    setSnackBar({ ...app.snackBar, open: true, text: translations.address_updated_successfully, bColor: "rgb(83, 148, 96)" })
                }
            })
        }

    }
    const loadCities = () => {
        const options = new QueryOptions()
        options.pageSize = 120;
        cityService.listWithCounter(options).then(resp => {
            setCities(resp.current)
        })
    }

    const showError = (errors, field) => {
        return errors[field]
    }

    const addressWithDetailsValidation = Yup.object().shape({
            firstName: Yup.string().required(translations?.name_is_required),
            lastName: Yup.string().required(translations?.lastname_is_required),
            comment: Yup.string().required(translations?.email_is_required).email(translations?.invalid_email_format),
            personalId: Yup.string().required(translations?.personal_id_is_required),
            phone: Yup.string().required(translations?.phone_number_is_required),
            street: Yup.string().required(translations?.address_is_required),
            city: Yup.object().shape({
                uid: Yup.string().required(translations?.city_is_required), 
                name: Yup.string().required(translations?.city_is_required)
              })
    })

    const addressValidation = Yup.object().shape({
        firstName: Yup.string().required(translations?.name_is_required),
        lastName: Yup.string().required(translations?.lastname_is_required),
        personalId: Yup.string().required(translations?.personal_id_is_required),
        phone: Yup.string().required(translations?.phone_number_is_required),
        street: Yup.string().required(translations?.address_is_required),
        city: Yup.object().shape({
            uid: Yup.string().required(translations?.city_is_required), 
            name: Yup.string().required(translations?.city_is_required)
          }) 
    })
    


    return loaded ? <Formik
        validationSchema={cartIncludesGiftCart ? addressWithDetailsValidation : addressValidation}
        initialValues={{
            id: item?.id ? item.id : null,
            personalId: item?.personalId ? item.personalId : '001001001',
            phone: item?.phone ? item.phone : '',
            firstName: item?.firstName ? item.firstName : '',
            comment: item?.comment ? item.comment : "",
            lastName: item?.lastName ? item.lastName : '',
            city: item?.city ? item.city : '',
            street: item?.street ? item.street : '',
        }}
        onSubmit={getValues}>
        {({ values, handleSubmit, setFieldValue, validateForm, handleBlur, errors }: any) => (<Animated animationIn="fadeIn" animationOut="fadeOutDown"
            animationInDuration={500}
            isVisible={loaded} >
            <Form onSubmit={handleSubmit}>
                <Grid container  style={{ padding: window.innerWidth > 1200 ? 20 : 0, }}>
                    <Grid item lg={6} sm={6} xs={12}>
                        <TextField
                            style={{ width: '90%', marginTop: 10, marginBottom: 10 }}
                            id="outlined-basic"
                            label={translations.address}
                            variant="outlined"
                            type="text"
                            name="street"
                            onChange={(event) => {
                                setFieldValue("street", event.target.value)
                                setSubmitted(false)
                                validateForm().then((errors) => {
                                    onValuesChange({...values, street: event.target.value})
                                })
                            }}
                            onBlur={handleBlur}
                            value={values.street}
                        />
                       {submitted && showError(errors, "street") && 
                       <Box>
                             <Typography 
                                        sx={{
                                            fontSize: 14,
                                            color: 'darkred',
                                            display:'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '100%',
                                            paddingLeft: 2.5
                                        }}
                                    >
                                        {errors?.street}
                                    </Typography>
                       </Box>}
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12} style={{ paddingRight: 0 }}>
                        <FormControl style={{ top: 10, width: '90%' }}>
                            <InputLabel >{translations.city}</InputLabel>
                            <Select
                                style={{ alignItems: 'flex-start', textAlign: 'left', marginBottom: 10 }}
                                value={values?.city?.uid}
                                label={translations.city}
                                onChange={(event) => {
                                    const [city] = cities.filter(c => c.uid === event.target.value)
                                    setFieldValue("city", city)
                                    setSubmitted(false)
                                    validateForm().then((errors) => {
                                        handleSubmit()
                                        onValuesChange({...values, city: city})
                                    })
                                }}

                            >
                                {cities?.map((item, index) => {
                                    return <MenuItem value={item.uid} key={index}>{item.name}</MenuItem>
                                })}

                            </Select>
                        </FormControl>

                        {submitted && showError(errors, "city") && (
                                <Box>
                                    <Typography 
                                        sx={{
                                            fontSize: 14,
                                            paddingTop: 1,
                                            color: 'darkred',
                                            display:'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '100%',
                                            paddingLeft: 2.5
                                        }}
                                    >
                                        {errors?.city?.name}
                                    </Typography>
                                </Box>
                            )}
                    </Grid>
                    <Grid item lg={12} sm={6} xs={12} style={{ marginTop: window.innerWidth > 1200 ? 0 : 10 }}>
                        <TextField
                            style={{ width: window.innerWidth > 1200 ? '95%' : '90%', marginTop: 10, marginBottom: 10 }}
                            id="outlined-basic"
                            label={!cartIncludesGiftCart ? translations.details: translations.gift_card_text_in_address_form }
                            variant="outlined"
                            type="text"
                            name="comment"
                            onChange={(event) => {
                                setFieldValue("comment", event.target.value)
                                setSubmitted(false)
                                validateForm().then((errors) => {
                                    onValuesChange({...values, comment: event.target.value})
                                })
                            }}
                            onBlur={handleBlur}
                            value={values.comment}
                        />
                        {submitted && showError(errors, "comment") && 
                       <Box>
                             <Typography 
                                        sx={{
                                            fontSize: 14,
                                            color: 'darkred',
                                            display:'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '100%',
                                            paddingLeft: 2.5
                                        }}
                                    >
                                        {errors?.comment}
                                    </Typography>
                       </Box>}
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12} style={{ paddingRight: 0 }}>
                        <TextField
                            style={{ width: '90%', marginTop: 10, marginBottom: 10 }}
                            id="outlined-basic"
                            label={translations.first_name}
                            color="primary"
                            variant="outlined"
                            type="text"
                            name="firstName"
                            onChange={(event) => {
                                setFieldValue("firstName", event.target.value)
                                setSubmitted(false)
                                validateForm().then((errors) => {
                                    onValuesChange({...values, firstName: event.target.value})
                                })
                            }}
                            onBlur={handleBlur}
                            value={values.firstName}
                        />
                       {submitted && showError(errors, "firstName") && 
                       <Box>
                             <Typography 
                                        sx={{
                                            fontSize: 14,
                                            color: 'darkred',
                                            display:'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '100%',
                                            paddingLeft: 2.5
                                        }}
                                    >
                                        {errors?.firstName}
                                    </Typography>
                       </Box>}
                    </Grid>
                    <Grid item lg={6}
                        xs={12}
                        sm={6} >
                        <TextField
                            style={{ width: '90%', marginTop: 10,  marginBottom: 10}}
                            id="outlined-basic"
                            label={translations.last_name}
                            color="primary"
                            variant="outlined"
                            type="text"
                            name="lastName"
                            onChange={(event) => {
                                setFieldValue("lastName", event.target.value)
                                setSubmitted(false)
                                validateForm().then((errors) => {
                                    onValuesChange({...values, lastName: event.target.value})
                                })
                            }}
                            onBlur={handleBlur}
                            value={values.lastName}
                        />
                        {submitted && showError(errors, "lastName") && 
                       <Box>
                             <Typography 
                                        sx={{
                                            fontSize: 14,
                                            color: 'darkred',
                                            display:'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '100%',
                                            paddingLeft: 2.5
                                        }}
                                    >
                                        {errors?.lastName}
                                    </Typography>
                       </Box>}
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12} >
                        <TextField
                            style={{ width: '90%', marginTop: 10, marginBottom: 10 }}
                            id="outlined-basic"
                            label={translations.personal_id}
                            color="primary"
                            variant="outlined"
                            type="text"
                            name="personalId"
                            onChange={(event) => {
                                setFieldValue("personalId", event.target.value)
                                setSubmitted(false)
                                validateForm().then((errors) => {
                                    onValuesChange({...values, personalId: event.target.value})
                                })
                            }}
                            onBlur={handleBlur}
                            value={values.personalId}
                        />
                        {submitted && showError(errors, "personalId") && 
                       <Box>
                             <Typography 
                                        sx={{
                                            fontSize: 14,
                                            color: 'darkred',
                                            display:'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '100%',
                                            paddingLeft: 2.5
                                        }}
                                    >
                                        {errors?.personalId}
                                    </Typography>
                       </Box>}
                    </Grid>
                    <Grid item lg={6} sm={6} xs={12} >
                        <TextField
                            style={{ width: '90%', marginTop: 10, marginBottom: 10 }}
                            id="outlined-basic"
                            label={translations.phone}
                            color="primary"
                            variant="outlined"
                            type="text"
                            name="phone"
                            onChange={(event) => {
                                setFieldValue("phone", event.target.value)
                                setSubmitted(false)
                                validateForm().then((errors) => {
                                    onValuesChange({...values, phone: event.target.value})
                                })
                            }}
                            onBlur={handleBlur}
                            value={values.phone}
                        />
                       {submitted && showError(errors, "phone") && 
                       <Box>
                             <Typography 
                                        sx={{
                                            fontSize: 14,
                                            color: 'darkred',
                                            display:'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            width: '100%',
                                            paddingLeft: 2.5
                                        }}
                                    >
                                        {errors?.phone}
                                    </Typography>
                       </Box>}
                    </Grid>
                    <Grid item lg={6} sm={6} style={{ paddingLeft: 5, width: '100%' }}></Grid>
                    <Grid item lg={6} sm={6}
                        style={{ paddingLeft: width > 600 ? 5: 0, width: '100%',  display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>
                       
                       {!address && <Button
                       sx={{
                        width: '90%',
                        height: 50,
                        backgroundColor: COLORS.BG_DARK,
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: 2,
                        "&:hover": {
                            backgroundColor:COLORS.BG_DARK,
                            color: 'white'
                        }
                       }}
                       type="submit"
                       onClick={() => validateForm().then((errors) => {
                        setSubmitted(true)
                    })}
                       >
                            {translations?.save}
                        </Button>}
                    </Grid>

                </Grid>

            </Form>

        </Animated>)}
    </Formik> : <></>

}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
    }
}

export default connect(mapStateToProps, { authenticate, login, setSnackBar })(AddressForm)