import React, { useState, useEffect, useCallback } from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { User as UserIcon } from '../../assets/icons/User'
import { COLORS } from '../../constants/colors'
import { connect } from 'react-redux'
import Inventory2Icon from '@mui/icons-material/Inventory2';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory, useParams } from 'react-router'
import { Animated } from "react-animated-css";
import { setCart, setConfirmation, setSnackBar } from '../../models/application/applicationActions'
import { logout as logoutFromServer } from '../../models/user/service';
import { logout } from '../../models/user/authActions'
import { User } from '../../models/user/user'
import { MyAddressesComponent } from '../../components/Address/MyAddressesComponent'
import { MyOrdersComponent } from '../../components/Order/MyOrdersComponent'
import RegisterForm from '../../components/Forms/RegisterForm'
import BreadCrumbsComponent from "../../components/BreadCrumbsComponent"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

const MenuItem = ({ item, onClick, page }) => {
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        if (page === item.location) {
            setVisible(false)
            setTimeout(() => {
                setVisible(true)
            }, 300)
        }
    }, [page])

    return <div
        onClick={onClick}
        style={{
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
        }}>
        <Animated animationIn="zoomIn" animationOut="fadeOut" isVisible={visible}>
            <div style={{
                marginTop: 0,
                width: 30,
                height: 30,
                borderRadius: 40,
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: page === item.location ? 'rgb(220,220,220)' : 'transparent',
                justifyContent: 'center',
                display: 'flex',
            }}>
                {item.icon}
            </div>
        </Animated>
        <div style={{
            flexDirection: 'column',
            marginLeft: 20,
            display: 'flex',
        }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <Typography variant="subtitle1" style={{ display: 'flex', flexDirection: 'column', fontSize: 13 }}>{item.title}</Typography>
            </div>
        </div>
    </div>
}

const MyPagesPage = (props: any) => {
    const { user, setConfirmation, setSnackBar, logout, app, translations, language, setCart } = props
    let { page }: any = useParams();
    const history = useHistory()
    const [menuItems, setMenuItems] = useState<any>([])

    useEffect(() => {
        if (translations) {
            setMenuItems([
                { title: translations?.personal_info, icon: <UserIcon width={15} height={15} />, location: 'profile' },
                { title: translations?.my_orders, icon: <Inventory2Icon sx={{ color: 'black', fontSize: 15 }} />, location: 'orders' },
                { title: translations?.my_addresses, icon: <LocationOnIcon sx={{ color: 'black', fontSize: 18 }} />, location: 'addresses' },
                { title: translations?.my_loyalty_card, icon: <LoyaltyIcon sx={{ color: 'black', fontSize: 18 }} />, location: 'bonus_card' },
                { title: translations?.gift_card, icon: <CardGiftcardIcon sx={{ color: 'black', fontSize: 16 }} />, location: 'gift_card' },
                { title: translations?.unicard, icon: <CreditCardIcon sx={{ color: 'black', fontSize: 16 }} />, location: 'unicard' },
                { title: translations?.logout, icon: <LogoutIcon sx={{ color: 'black', fontSize: 16 }} />, location: 'logout' }
            ])
        }
    }, [translations])

    const onLogout = () => {
        setConfirmation({
            open: true, item: null,
            textAccept: translations?.logout,
            type: 'logout', title: "Voulez-vous", text: translations?.do_you_want_to_sign_out, onAgreed: () => {
                setSnackBar({ ...app.snackBar, open: true, text: translations?.you_signed_out, bColor: "rgb(83, 148, 96)" })
                logoutFromServer().then(r => {
                    setCart([])
                    localStorage.removeItem("cart")
                    logout()
                    const user = new User();
                    user.delete()
                })
            }
        })
    }


    const RenderContent = useCallback(() => {
        switch (page) {
            case 'addresses':
                return <MyAddressesComponent
                    {...props}
                    setSnackBar={setSnackBar}
                    translations={translations}
                    app={app}
                    setConfirmation={setConfirmation} />
            case 'profile':
                return <RegisterForm
                    {...props}
                    user={user}
                    setSnackBar={setSnackBar}
                    app={app}
                    setConfirmation={setConfirmation} />
            case 'orders':
                return <MyOrdersComponent {...props} />
            default:
                return <></>

        }
    }, [page, app, user, translations])

    const Menu = (props: any) => {
        const {width} = useWindowDimensions()
        return <>
       {width < 600 && <ArrowBackIcon onClick={() => { history.goBack() }} sx={{ color: 'black', fontSize: 35, padding: -2 }} />}
            <div style={{
                display: 'flex',
                marginBottom: 20,
                width: '30%',
                flexDirection: 'row',
                paddingLeft: 10,
                paddingRight: 20,
                paddingTop: 10,
                position: 'relative'
            }}>
               
                <div style={{
                    marginTop: 0,
                    width: 50,
                    minWidth: 50,
                    height: 50,
                    borderRadius: 15,
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: COLORS.BEIGE,
                    justifyContent: 'center',
                    display: 'flex',
                }}>
                    <UserIcon width={22} height={22} />
                
                </div>
                <div style={{
                    flexDirection: 'column',
                    marginLeft: 20,
                    display: 'flex',
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <Typography variant="subtitle1" style={{ display: 'flex', flexDirection: 'column', marginRight: 5 }}>{user?.firstName}</Typography>
                        <Typography variant="subtitle1" style={{ display: 'flex', flexDirection: 'column' }}>{user?.lastName}</Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography variant="subtitle2">{user?.email}</Typography>
                    </div>
                </div>
               {width > 1200 && <Divider
                    orientation="vertical"
                    sx={{ width: "1px", backgroundColor: "gray", height: "60vh", marginTop: 5, marginRight: 3, position: 'absolute', left: 300, top: -30 }}
                />}
            </div>
            {menuItems.map((item, index) => {
                return <MenuItem item={item}
                    key={index}
                    page={page}
                    onClick={() => {
                        if (item.location !== 'logout') {
                            history.push(`/account/${item.location}`)
                        } else {
                            onLogout()
                        }
                    }} />
            })}
            
        </>
    }


    return <Box sx={{ width: page !== 'menu' ? '100%' : 'auto', display: 'flex', padding: window.innerWidth > 1200 ? 5 : 0, paddingBottom: window.innerWidth > 1200 ? '' : '99%' }}>
        <Grid container sx={{ width: 1200, margin: '0px auto', borderRadius: 15 }}>
            {window.innerWidth < 1200 && <div style={{ marginBottom: 0,marginTop: 20, marginLeft: 5, width: '100%', display: 'grid' }}>
                {page !== 'menu' && <ArrowBackIcon onClick={() => { history.goBack() }} sx={{ color: 'black', fontSize: 35, padding: -2 }} />}
              
            </div>}

            <div style={{
                display: 'inline-flex', paddingBottom: 30,
                borderRadius: 15,
                flexDirection: 'column',
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                width: page !== "menu" ? '' : '100%',
                height: page !== "menu" ? '' : '100%',
                marginRight: 10,
                marginLeft: 10,
            }}>
                {window.innerWidth > 1200 && page !== "menu" &&
                    <Menu />
                    
                }
                {page === "menu" &&
                    <Menu />
                }
            </div>
            {page !== "menu" && <div style={{
                margin: window.innerWidth > 1200 ? 0 : 10,
                width: window.innerWidth > 1200 ? '70%' : '100%',
                borderRadius: 15,
               
                padding: window.innerWidth > 1200 ? 20 : 10
            }}>
                <RenderContent />
            </div>
            }
        </Grid>
    </Box>
}
const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, { setConfirmation, setSnackBar, logout, setCart })(MyPagesPage)