import { Resource } from '../resource';

export class ContactMessage extends Resource {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    subject: {value: string, geo: string, eng: string};
    message: string;
    file: File;
}
