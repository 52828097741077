import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { BOGPayment, BOGPaymentLink, PaymentMethod } from "./PaymentMethod"

export class PaymentMethodSerializer implements Serializer {
    fromJson(json: any) {
        const method = new PaymentMethod()
        method.filename = json.filename
        method.id = json.id
        method.uid = json.uid
        method.name = json.name
        method.maxValue = json.max_value
        method.minValue = json.min_value
        return method
    }
    toJson(order: any) {
        return {
            
        }
    }
}
export class BOGPaymentSerializer implements Serializer {
    fromJson(json: any) {
        const payment = new BOGPayment()
        payment.status = json.status
        payment.paymentHash =  json.payment_hash
        payment.links = json.links.map(l => bogLinkSerializer.fromJson(l))
        payment.orderId = json.order_id
        return payment
    }
    toJson(item: BOGPayment) {
        return {
            order_id: item.id
            
        }
    }
}
export class BOGPaymentLinkSerializer implements Serializer {
    fromJson(json: any) {
        const method = new BOGPaymentLink()
        method.href = json.href
        method.rel = json.rel
        method.method = json.method
        return method
    }
    toJson(item: any) {
        return {
            
        }
    }
}


export const bogLinkSerializer = new BOGPaymentLinkSerializer()
export const paymentMethodSerializer = new PaymentMethodSerializer()
