
import React, { useState, useEffect, Fragment } from "react";
import '../styles.scss'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { Title } from "../Typography/Title";
import { addToCart } from "../../helpers/utilities";
import { COLORS } from "../../constants/colors";
import { SimpleProduct } from "../../models/product/product";
import { QueryOptions } from "../../helpers/query.options";
import { reviewSummaryService } from "../../models/review/service";
import { ReviewSummary } from "../../models/review/Review";
import { Configurables } from "./Configurables";
import { AddToCartButton } from "./Button/AddToCartButton";
import { AddToFavsButton } from "./Button/AddToFavsButton";
import '../../App.css'
import { CustomButton } from "./Button/CustomButton";
import { StockAvailabilityDialog } from "../Dialogs/StockAvailabilityDialog";

export const ProductDetailsRightColumn = (props) => {
    const { item,
        app,
        translations,
        setSnackBar,
        setCart,
        onClose, 
        onAddedToCart, 
        language,
        showLink = true,
        onSetSimple,
        source = "product" } = props
    const classes = useStyles();
    const history = useHistory();
    const [price, setPrice] = useState(item?.specialPrice ? item.specialPrice : item?.price ? item.price : 0)
    const [stockAvailabilityOpen, setStockAvailabilityOpen] = useState(false)
    const [simple, setSimple] = useState<SimpleProduct>()
    const [image, setImage] = useState<any>()
    const [qty, setQty] = useState(1)
    const [qtyOpen, setQtyOpen] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)
    const [animate, setAnimate] = useState(false)
    const [reviewSummary, setReviewSummary] = useState<ReviewSummary>()

    useEffect(() => {
        if (item?.mainImage) {
            setImage(item.mainImage)
            loadReviewSummary(item.id)
        }
        if (item) {
            setPrice(item?.specialPrice ? item.specialPrice : item?.price ? item.price : 0)
        }
        if (item && !item?.configurables?.length) {
            const [simple] = item.simpleProducts.filter(s => s.stock)
            if (simple) {
                setSimple(simple)
            } else {
                const [simple] = item.simpleProducts
                setSimple(simple)
            }
        }
    }, [item])

    useEffect(() => {
        if (simple) {
            setPrice(simple?.specialPrice)
            onSetSimple(simple)
        }
    }, [simple])

    useEffect(() => {
        setQtyOpen(false)
    }, [qty])

    useEffect(() => {
        if (image) {
            const img = new Image()
            img.src = item.thumbnail
            img.onload = () => {
                setImageLoaded(true)
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }
    }, [image])

    const loadReviewSummary = (id) => {
        const options = new QueryOptions()
        options.id = id
        reviewSummaryService.read(id, options).then((r: any) => {
            setReviewSummary(r)
        })
    }

    return <Box sx={{ display: 'flex', justifyContent: 'space-around', padding: 2, flexDirection: 'column', height: 370 }}>
        <div style={{ display: 'flex', flexDirection: 'column', padding: 0, backgroundColor: 'white' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Title text={item?.brand?.title} fontSize={25} uppercase={true} fontWeight='700' fontFamily="Roboto" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Title text={item?.name} fontSize={18} uppercase={true}
                    className="roboto"
                    fontWeight='500' fontFamily='Roboto' />
            </div>
        </div>
        <Fragment>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, paddingLeft: 5 }}>
                {item?.specialPrice && item.specialPrice < item.price ? <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <Title text={`${simple ? simple?.price : item.price}₾`} fontSize={22} uppercase={true} fontWeight='400' color={"black"} style={{ marginRight: 5, textDecorationLine: 'line-through' }} />
                    <Title text={`${simple ? simple?.specialPrice : item.specialPrice}₾`} fontSize={22} uppercase={true} fontWeight='400' color={"red"} />
                </div> : <Title text={`${simple ? simple?.price : item.price} ₾`} fontSize={22} uppercase={true} fontWeight='400' fontFamily='Roboto' color={"#000"} className="roboto" />}
            </div>
        </Fragment>
        {source === 'popup' && item?.shortDescription && <div dangerouslySetInnerHTML={{ __html: item?.shortDescription.replace(/\\n/g, "").replace(/\\r/g, "") }}
            style={{
                maxHeight: 70, overflowY: 'scroll',
                textAlign: 'left',
                fontSize: 13,
                width: '94%',
                paddingRight: 10,
                overflow: 'hidden',
                marginTop: 10,
                marginBottom: 10
            }}>
        </div>}
        {showLink && <div style={{ position: 'absolute', right: 50, top: 25 }} onClick={() => {
            onClose()
            history.push("/product/" + item.id)
        }}>
            <Title
                underline={true}
                uppercase={true}
                fontWeight={'500'}
                fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                color={COLORS.BLUE_LINK}
                style={{ marginTop: 10, cursor: 'pointer' }}
                fontSize={12}
                text={translations?.details} />
        </div>}

        <Configurables
            item={item}
            simple={simple}
            setSimple={setSimple} animate={animate}
            translations={translations}
            language={language} />
        {simple && <StockAvailabilityDialog
            selectedItems={item.embeddedInventory?.filter(i => i.sku === simple?.sku && i.available)}
            {...props}
            item={item}
            simple={simple}
            items={item.embeddedInventory.filter(i => i.sku === simple?.sku && i.available)}
            open={stockAvailabilityOpen}
            setOpen={(b: boolean) => {
                setStockAvailabilityOpen(false)
            }} />}

        <Box>
            {/* {!item.available && <Title 
            fontSize={13}
            color={COLORS.RED}
            text={item.embeddedInventory.filter(i => i.sku === simple?.sku && i.available).length > 0 ? translations.not_available_online: translations.out_of_stock} />} */}
            {simple  && item.embeddedInventory.filter(i => i.sku === simple?.sku && i.available).length > 0 && <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center', justifyContent: 'flex-start' }}>
                <Box style={{ width: '44%', alignItems: 'center', paddingRight: 10 }}>
                    <CustomButton 
                        {...props}
                        fontSize={12}
                        text={translations.check_availability}
                        language={language} onClick={() => {
                            setStockAvailabilityOpen(true)
                        }} />
                </Box>

            </div>}
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, alignItems: 'center', justifyContent: 'flex-start' }}>
                {<Box style={{ width: '44%', alignItems: 'center', paddingRight: 10 }}>
                    <AddToCartButton
                        {...props}
                        onOpenAvailability={() => {
                            const inShops = item?.embeddedInventory.filter(i => i.sku === simple?.sku && i.available).length > 0
                            setTimeout(() => {
                                setSnackBar({ ...app.snackBar, open: true, text: inShops ? translations.not_available_online:translations.out_of_stock, bColor: COLORS.RED })
                                if (item?.embeddedInventory.filter(i => i.sku === simple?.sku && i.available).length > 0) {
                                  setStockAvailabilityOpen(true)
                                }
                              }, 700)
                          }}
                        available={simple?.stock > 0}
                        onAddedToCart={onAddedToCart}
                        simple={simple}
                        addToCart={addToCart}
                        setPrice={setPrice}
                        setSimple={setSimple}
                        setCart={setCart} />
                </Box>}
                <Box style={{ width: '44%', alignItems: 'center', padding: 10 }}>
                    <AddToFavsButton {...props} simple={simple} />
                </Box>
            </div>
        </Box>
    </Box>

}

const useStyles = makeStyles({
    container: {
        overflow: 'hidden',
        marginTop: 0,
        flexDirection: 'row',
        position: "relative",
        width: '100%',
        flex: 1,
        height: '100%',
        display: 'flex',
        borderRadius: 5,
    },
    column: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    leftColumn: {
        width: '50%',
        backgroundColor: 'blue'
    },
    tag: {
        alignItems: 'center',
        display: 'flex',
        verticalAlign: 'middle',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        position: "absolute",
        textAlign: 'center',
        fontSize: '13px',
        fontWeight: 'bold',
        left: 10,
        top: -10,
        color: 'white',
        borderRadius: 30,
        width: 40,
        height: 40
    },
    info: {
        marginTop: -10,
        flexDirection: 'row'
    },
    price: {
        color: 'red',
        textDecoration: 'line-through'
    },
    specialPrice: {
        marginLeft: 10
    },
    image: {
        marginBottom: -5,
        top: 0,
        zIndex: 100,
        left: 0,
    }
});
