import { environment } from '../../environment/environment'
import { Serializer } from '../serializer'
import { News } from './news'



export class NewsSerializer implements Serializer {
    fromJson(json: any) {
        const news = new News()
        news.title = json.title
        news.description = json.description
        news.created = json.created
        news.id = json.id
        if (json.filename) {
            news.image = `${environment.imagePath}/${environment.imagesPath}/news/${json.filename}.jpg` 
        }
        return news
    }

    toJson(item: any) {
        const obj: any = {}
        return obj
    }
}
