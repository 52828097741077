import { citySerializer, CitySerializer } from '../city/serializer'
import { countrySerializer } from '../country/serializer'
import { locationSerializer } from '../location/serializer'
import { Serializer } from '../serializer'
import { EmbeddedAddress } from '../user/user'
import { Address } from './address'

export class AddressSerializer implements Serializer {
    fromJson(json: any) {
        const address = new Address()
        
        address.id = json.id
        address.firstName = json.first_name
        address.lastName = json.last_name
        if (json.city) {
            address.city = citySerializer.fromJson(json.city)
        }
        if (json.country) {
            address.country = countrySerializer.fromJson(json.country)
        }
        
        address.personalId = json.personal_id
        address.phone = json.phone
        address.street = json.street
        address.comment = json.comment
        address.street2 = json.street2
        return address
    }

    toJson(item: Address) {
        const obj: any = {
            id: item.id,
            first_name: item.firstName,
            last_name: item.lastName,
            city: item.city.uid,
            lang: item.lang,
            comment: item.comment,
            personal_id: item.personalId,
            phone: item.phone,
            street: item.street,
            street2: item.street2
        }
        return obj
    }
}
export class BranchAddressSerializer implements Serializer {
    fromJson(json: any) {
        const address = new Address()
        if (json.city) {
            address.city = citySerializer.fromJson(json.city)
        }
        if (json.country) {
            address.country = countrySerializer.fromJson(json.country)
        }
        if (json.location) {
            address.location = locationSerializer.fromJson(json.location)
        }
        address.phone = json.phone
        address.street = json.street
        address.street2 = json.street2
        return address
    }

    toJson(item: Address) {
        const obj: any = {
            id: item.id,
            first_name: item.firstName,
            last_name: item.lastName,
            city: item.city.uid,
            personal_id: item.personalId,
            phone: item.phone,
            street: item.street,
            street2: item.street2
        }
        return obj
    }
}
export class SimpleAddressSerializer implements Serializer {
    fromJson(json: any) {
        const address = new EmbeddedAddress()
        address.city = json.city
        address.postalCode = json.postal_code
        address.street = json.street
        address.street2 = json.street2
        return address
    }

    toJson(item: any) {
        const obj: any = {}
        return obj
    }
}

export const branchAddressSerializer = new BranchAddressSerializer()
export const addressSerializer = new AddressSerializer()
