import React from "react"
import { connect } from "react-redux"
import { MobileAboutUsComponent } from "../../components/aboutus/MobileAboutUsComponent"

const MobileAboutUsPage = (props) => <MobileAboutUsComponent {...props} />

const mapStateToProps = (state: any) => {
    return {
        app: state.app,
        translations: state.translations,
        language: state.language,
    }
}

export default connect(mapStateToProps, {})(MobileAboutUsPage)
