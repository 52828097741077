import { attributeNameSerializer, AttributeNameSerializer } from "../attribute/serializer"
import { Resource } from "../resource"
import { Serializer } from "../serializer"
import { Filter } from "./filter"

export class FilterSerializer implements Serializer {
    fromJson(json: any) {
        const item = new Filter()
        item.id = String(json.id)
        item.name = attributeNameSerializer.fromJson(json.name)
        item.count = json.count
        item.value = json.value
        return item
    }
    toJson(item: any) {
        return {
        }
    }
}
