import React, { useState } from 'react'
import '../../../src/App.css'
import { COLORS } from '../../constants/colors'

export const CheckoutTimeline = (props) => {
    const { width, height, index, onSetStep, address } = props
    const [hoveredIndex, setHoveredIndex] = useState(0)
    return <svg width="793.7" height="40" version="1.1" viewBox="0 0 210 10.583" xmlns="http://www.w3.org/2000/svg">
        <path d="m6.8854 4.7363 197.36-0.51173" fill="none" stroke="#cbcbcb" strokeWidth=".32912" />
        <path
            onMouseEnter={() => {
                setHoveredIndex(1)
            }}
            onMouseLeave={() => {
                setHoveredIndex(0)
            }}
            onClick={() => {
                onSetStep(1)
            }}
            style={{
                cursor: 'pointer',
            }}
            fill={index === 1 ? COLORS.GREEN : "#dbd3c9"}
            stroke={index === 1 ? COLORS.GREEN : "#dbd3c9"}
            strokeLinecap="round" 
            strokeLinejoin="bevel" 
            strokeWidth="0"
            d="m4.377 0.16048a4.3212 4.3212 0 0 1 4.422 4.1662 4.3212 4.3212 0 0 1-4.1144 4.4702 4.3212 4.3212 0 0 1-4.5177-4.0622 4.3212 4.3212 0 0 1 4.0093-4.5647" />
        <path
            onClick={() => {
                onSetStep(2)
            }}
            onMouseLeave={() => {
                setHoveredIndex(0)
            }}
            style={{
                cursor: 'pointer',
            }}
            onMouseEnter={() => {
                setHoveredIndex(2)
            }}
            fill={index === 2 ? COLORS.GREEN : "#dbd3c9"}
            stroke={index === 2 ? COLORS.GREEN : "#dbd3c9"}
            strokeLinecap="round"
            strokeLinejoin="bevel"
            strokeWidth="0"
            d="m76.177 0.16048a4.3212 4.3212 0 0 1 4.422 4.1662 4.3212 4.3212 0 0 1-4.1144 4.4702 4.3212 4.3212 0 0 1-4.5177-4.0622 4.3212 4.3212 0 0 1 4.0093-4.5647" />
        <path
            onMouseEnter={() => {
                setHoveredIndex(3)
            }}
            onMouseLeave={() => {
                setHoveredIndex(0)
            }}
            style={{
                cursor: 'pointer',
            }}
            onClick={() => {
                if (address) {
                    onSetStep(3)
                }
            }}
            fill={index === 3 ? COLORS.GREEN : "#dbd3c9"}
            stroke={index === 3 ? COLORS.GREEN : "#dbd3c9"}
            strokeLinecap="round"
            strokeLinejoin="bevel"
            strokeWidth="0"
            d="m140.26 0.16048a4.3212 4.3212 0 0 1 4.422 4.1662 4.3212 4.3212 0 0 1-4.1144 4.4702 4.3212 4.3212 0 0 1-4.5177-4.0622 4.3212 4.3212 0 0 1 4.0094-4.5647" />
        <path
            onMouseEnter={(e) => {
                e.preventDefault()
                setHoveredIndex(4)
            }}
            onMouseLeave={(e) => {
                e.preventDefault()
                setHoveredIndex(0)
            }}
            style={{
                cursor: 'pointer',
            }}
            onClick={() => {
                
            }}
            fill={index === 4 ? COLORS.GREEN : "#dbd3c9"}
            stroke={index === 4 ? COLORS.GREEN : "#dbd3c9"}
            strokeLinecap="round"
            strokeLinejoin="bevel"
            strokeWidth="0"
            d="m204.75 0.16048a4.3212 4.3212 0 0 1 4.422 4.1662 4.3212 4.3212 0 0 1-4.1144 4.4702 4.3212 4.3212 0 0 1-4.5177-4.0622 4.3212 4.3212 0 0 1 4.0094-4.5647" />
        <g fill="#f9f9f9"
            strokeWidth=".10532">
            <path d="m205.95 5.3177h-0.43814v0.67469h-0.36203v-0.67469h-1.3885v-0.32706l1.3638-2.022h0.38672v2.0076h0.43814zm-0.80017-0.34146v-0.73229q0-0.11519 2e-3 -0.24067 4e-3 -0.12753 8e-3 -0.24683 4e-3 -0.12137 8e-3 -0.22627 6e-3 -0.10491 8e-3 -0.17485h-0.0185q-0.0144 0.041174-0.037 0.092571-0.0206 0.049339-0.0473 0.10079-0.0247 0.049339-0.0514 0.09668-0.0268 0.047341-0.0493 0.080263l-0.84543 1.2507z" />
            <path d="m141.22 3.6813q0 0.15016-0.0473 0.27358-0.0473 0.12343-0.13371 0.21394-0.0843 0.090504-0.20364 0.15016-0.11931 0.057591-0.26535 0.08226v0.012335q0.36408 0.045256 0.55333 0.23244 0.18924 0.18513 0.18924 0.4834 0 0.19747-0.0678 0.36203-0.0658 0.16456-0.20159 0.28386-0.13576 0.11931-0.34352 0.18513-0.20776 0.065843-0.49162 0.065843-0.22421 0-0.42374-0.035006-0.19747-0.035006-0.37232-0.12753v-0.3497q0.17896 0.094621 0.38672 0.14605 0.20981 0.051424 0.40111 0.051424 0.18925 0 0.32706-0.041174t0.22628-0.11725q0.0905-0.076093 0.13165-0.18719 0.0432-0.11108 0.0432-0.24889 0-0.13988-0.0555-0.23862-0.0535-0.1008-0.15427-0.16456-0.1008-0.065843-0.24685-0.09668-0.14398-0.030837-0.32707-0.030837h-0.27357v-0.31061h0.27357q0.16663 0 0.29622-0.041174t0.21599-0.11725q0.0884-0.076093 0.1337-0.18102 0.0452-0.10491 0.0452-0.23038 0-0.10696-0.037-0.1913-0.037-0.084345-0.10491-0.14194-0.0679-0.059676-0.1625-0.090512-0.0946-0.030837-0.20982-0.030837-0.2201 0-0.38877 0.069926-0.16662 0.067841-0.31884 0.17896l-0.18924-0.25712q0.0782-0.063758 0.17278-0.11931 0.0967-0.055506 0.20982-0.09668 0.11313-0.043172 0.24067-0.067841 0.12958-0.024669 0.27358-0.024669 0.22216 0 0.38878 0.055506 0.16867 0.055506 0.2818 0.15633 0.11313 0.098739 0.17073 0.23656 0.0576 0.13576 0.0576 0.29827z" />
            <path d="m77.317 6.0438h-2.0735v-0.42991q0.21598-0.18513 0.43196-0.37026 0.21806-0.18513 0.40524-0.36821 0.39494-0.3826 0.54099-0.60681 0.14604-0.22627 0.14604-0.48751 0-0.23862-0.15839-0.37233-0.15633-0.13576-0.43814-0.13576-0.18719 0-0.40523 0.065843t-0.4258 0.20159h-0.02059v-0.43197q0.14605-0.07201 0.38877-0.13165 0.24479-0.059676 0.47312-0.059676 0.47106 0 0.73846 0.22832 0.26741 0.22627 0.26741 0.61504 0 0.17485-0.04526 0.32706-0.04317 0.15016-0.12958 0.28593-0.08026 0.12753-0.18925 0.25095-0.10696 0.12343-0.26124 0.27359-0.2201 0.21598-0.4546 0.41962-0.2345 0.20159-0.43814 0.37438h1.6477z" />
            <path d="m5.3095 6.017h-1.6579v-0.31267h0.63767v-2.0529h-0.63767v-0.27975q0.12959 0 0.2777-0.020587 0.1481-0.022585 0.22421-0.063758 0.094622-0.051424 0.1481-0.12958 0.055541-0.080263 0.063776-0.21394h0.31883v2.7605h0.62533z" />
        </g>
    </svg>

}