import React, { useState, useEffect } from 'react'
import {  Box, Fab, Fade, Grid,  useScrollTrigger } from '@mui/material'
import { COLORS } from '../../constants/colors'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { setConfirmation, setSnackBar } from '../../models/application/applicationActions'
import { logout } from '../../models/user/authActions'
import { infoPageService, infoPageContentService } from '../../models/InfoPage/service'
import { QueryOptions } from '../../helpers/query.options'
import { InfoPage, InfoPageContent } from '../../models/InfoPage/InfoPage'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { SiteMapComponent } from './SiteMapComponent'

function ScrollTop(props: { children: any; }) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });

    const handleClick = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: "fixed", bottom: window.innerWidth > 1200 ? 26 : 70, right: 26 }}
            >
                {children}
            </Box>
        </Fade>
    );
}



const InfoPages = (props: any) => {
    const { setConfirmation, setSnackBar, app, translations, language } = props
    let { page }: any = useParams();
    const history = useHistory()
    const [menuItems, setMenuItems] = useState<InfoPage[]>([])
    const [content, setContent] = useState<InfoPageContent>()
    const [selectedItem, setSelectedItem] = useState(null);
    const [accountMenuItems, setAccountMenuItems] = useState<any[]>([
        {}
    ])
  



    useEffect(() => {
        loadInfoPages()
    }, [])

    useEffect(() => {
        if (page) {
            loadInfoPage(page)
        }
    }, [page])

    const loadInfoPages = async (): Promise<any> => {
        const options = new QueryOptions()
        options.sort = 'position'
        options.order = 'asc'
        options.lang = language
        const pages = await infoPageService.listWithCounter(options)
        setMenuItems([...pages.current])

    }
    const loadInfoPage = async (id): Promise<any> => {
        if (page !== 'menu') {
            const options = new QueryOptions()
            options.lang = language;
            const page = await infoPageContentService.read(id, options) as any
            setContent(page)
        }
    }


    return <Box sx={{ width: page !== 'menu' ? '100%' : 'auto', display: 'flex', padding: window.innerWidth > 1200 ? 5 : 0, paddingBottom: window.innerWidth > 1200 ? '' : '99%', backgroundColor: COLORS.BODY_BG }}>
        <Grid container sx={{ width: 1200, margin: '0px auto', borderRadius: 20 }}>
            {window.innerWidth < 1200 && <div style={{ marginBottom: 20, marginTop: 20, marginLeft: 5, width: '100%', display: 'grid' }}>
                {page !== 'menu' && <ArrowBackIcon onClick={() => { history.push('/info_pages/menu') }} sx={{ color: 'black', fontSize: 35, padding: -2 }} />}
            </div>}
            {window.innerWidth > 1200 && page !== "menu" &&
                <SiteMapComponent menuItems={menuItems} {...props}/>
            }
            {page === "menu" &&
                <SiteMapComponent menuItems={menuItems} {...props}/>
            }
        </Grid>
        <ScrollTop {...props}>
            <Fab size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
            </Fab>
        </ScrollTop>
    </Box >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user,
        app: state.app,
        translations: state.translations,
        language: state.language
    }
}

export default connect(mapStateToProps, { setConfirmation, setSnackBar, logout })(InfoPages)