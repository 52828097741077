import { Grid } from '@mui/material';
import React from 'react';
import { BrandItem } from '../../../components/Brand/BrandItem';

export const PopularBrandsContentDesktop = (props) => {
    const {brands, history} = props
    return <Grid container style={{ width: 1200, padding: 0 }} spacing={1}>
    {brands?.filter((item, index) => index < 18).map((brand, index) => {
      return <Grid item lg={2} sm={2} style={{ padding: 10 }} key={index}>
        <BrandItem item={brand} onClick={() => { history.push(`brand/${brand.id}`) }} />
      </Grid>
    })
    }
  </Grid>
}

