import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { COLORS } from '../../constants/colors';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HolidayPopup(props) {
    const {app, translations, language, open, setOpen} = props
    const { width } = useWindowDimensions()


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-descriptionn"
        sx={{padding: 1}}
      >
        <DialogTitle sx={{fontWeight:'bold', fontFamily: language === 'geo' ? 'FiraGo' : 'Roboto', paddingTop: 3, textAlign: width < 600 ? 'center' :'start'}}>{translations?.information}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-descriptionn" sx={{fontFamily: language === 'geo' ? 'FiraGo' : 'Roboto', color: 'black', textAlign: width < 600 ? 'center' :'start'}}>
            {translations?.holiday_delivery_info}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{paddingBottom: 2}}>
          <Button onClick={() => {
            handleClose()
            localStorage.setItem("holiday-popper", JSON.stringify(true))
          }}
          disableRipple
          fullWidth={width < 600}
          sx={{
            backgroundColor: COLORS.BG_DARK,
            color: 'white',
            fontFamily: language === 'geo' ? 'FiraGo' : 'Roboto',
            textTransform: 'none',
            borderRadius: 2,
            padding: 1,
            "&:hover": {
                backgroundColor: COLORS.BG_DARK,
                color: 'white',
            }
          }}
          >{translations?.popper_ok}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}