import { Box, Divider, Grid, Typography } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import { COLORS } from '../../constants/colors'
import { addToFavorites, getCartTotalWithStock, getToken, loadCart, loadCartItems, removeCartItem } from '../../helpers/utilities'
import { BOGPayment } from '../../models/payment/PaymentMethod'
import { Title } from '../Typography/Title'
import { bogPaymentService } from '../../models/payment/service'
import { Animated } from "react-animated-css";
import { Order } from '../../models/order/Order'
import { orderService, tbcInstallmentService } from '../../models/order/service'
import { environment } from '../../environment/environment'
import { BlackButton } from '../Button/BlackButton'
import { cartService } from '../../models/cart/service'
import { ProductCheckoutComponent } from '../Product/ProductCheckoutComponent'
import { ProductCheckoutComponentMobile } from '../Product/ProductCheckoutComponentMobile'
import { Settings } from '../../models/settings/settings'
import { settingsService } from '../../models/settings/service'
import { QueryOptions } from '../../helpers/query.options'
declare var BOG: any

export const CheckoutFourthStepComponent = (props) => {
    const { app, language, translations, address, setStep,
        onReloadCart,
        paymentMethod,
        shippingMethod,
        auth,
        discount,
        onUpdateCart,
        setSnackBar,
        setFavorites,
        setConfirmation,
        setCart, coupon, loyaltyCard } = props


    const [settings, setSettings] = useState<Settings>()
    const [loaded, setLoaded] = useState(false)
    const [animateShippingError, setAnimateShippingError] = useState(false)
    const [animatePaymentError, setAnimatePaymentError] = useState(false)

    useEffect(() => {

        setLoaded(true)
        loadCartItems(setCart, language)
        return () => {
        }
    }, [])

    useEffect(() => {
        settingsService.readWithNoId(new QueryOptions()).then((resp)=>{
            setSettings(resp)
        }).catch(()=> {
            
        })
    }, [])


    const openBOGModal = async (amount, orderId, bnpl) => {

        const token = await getToken();
        BOG.Calculator.open({
            bnpl,
            amount,
            onClose: () => {

            },
            onRequest: (selected, successCb, closeCb) => {
                const {
                    amount, month, discount_code
                } = selected;

                fetch(`${environment.basePath}/rest_bog_installment`, {
                    headers: new Headers({
                        'Authorization': `Bearer ${token}`
                    }),
                    method: 'POST',
                    body: JSON.stringify({ ...selected, order_id: orderId })
                })
                    .then(response => response.json())
                    .then(data => successCb(data.order_id))
                    .catch(err => closeCb());
            },
            onComplete: () => {
                
            }
        })
    }

    const createTBCInstallment = (id) => {
        const order = new Order()
        order.id = id
        tbcInstallmentService.create(order).then((r: any) => {
            window.location.href = r.location
        })
    }
    const createBOGPayment = (id) => {
        const payment = new BOGPayment()
        payment.id = id
        bogPaymentService.create(payment).then(r => {
            if (r.status === "CREATED") {
                const [link] = r.links.filter(l => l.method === "REDIRECT")
                if (link) {
                    localStorage.setItem("url", link.href)
                    window.location.href = link.href
                }
            }
        })
    }

    const onRemoveItem = (confirmation) => {
        if (auth.loggedIn) {
            cartService.delete(confirmation.item.sku).then(() => {
                loadCartItems(setCart, language)
                setConfirmation({ ...confirmation, open: false })
                setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted}`, bColor: "rgb(83, 148, 96)" })
            })
        } else {
            removeCartItem(confirmation.item.sku, app, setCart)
            setConfirmation({ ...confirmation, open: false })
            setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations?.has_been_deleted}`, bColor: "rgb(83, 148, 96)" })
        }
    }

    const onMoveToFavorites = (confirmation) => {
        addToFavorites(confirmation.item.product, confirmation.item.sku, app, auth).then(r => {
            setSnackBar({ ...app.snackBar, open: true, text: `${confirmation.item.product.name} ${translations.has_been_added_to_favs}.`, bColor: "rgb(83, 148, 96)" })
            setFavorites(r)
            if (auth.loggedIn) {
                cartService.delete(confirmation.item.sku).then(() => {
                    loadCartItems(setCart, language)
                    setConfirmation({ ...confirmation, open: false })
                })
            } else {
                removeCartItem(confirmation.item.sku, app, setCart)
                setConfirmation({ ...confirmation, open: false })
            }
        })
    }


    const registerOrder = () => {
        if (!shippingMethod) {
            setAnimateShippingError(true)
            setTimeout(() => {
                setAnimateShippingError(false)
            }, 5000)
            return
        }
        if (!paymentMethod) {
            setAnimatePaymentError(true)
            setTimeout(() => {
                setAnimatePaymentError(false)
            }, 5000)
            return
        }
        const order = new Order()
        order.lang = language
        order.address = address
        order.shippingMethod = shippingMethod
        order.paymentMethod = paymentMethod
        if (coupon?.name) {
            order.coupon = coupon.name
        }
        if (loyaltyCard) {
            order.loyaltyCard =loyaltyCard.id;
        }
        orderService.create(order).then(r => {
            if (r.paymentMethod.uid === "installment_bog") {
                openBOGModal(r.totalToPay, r.id, false)
            }
            if (r.paymentMethod.uid === "by_parts_bog") {
                openBOGModal(r.totalToPay, r.id, true)
            }
            if (r.paymentMethod.uid === "gift_card") {
                window.location.href = "/payment_callback?order_id=" + r.id
            }
            if (r.paymentMethod.uid === "card_bog") {
                setTimeout(() => {
                    createBOGPayment(r.id)
                }, 40)
            }
            if (r.paymentMethod.uid === "installment_tbc") {
                createTBCInstallment(r.id)
            }
        })
    }

    const getShippingPrice = (shippingMethod, settings, cartItems, coupon) => {
        var cost = 0;
        if (shippingMethod) {
            cost = shippingMethod.price;
        }
        const total = getCartTotalWithStock(cartItems)
        
        if (settings && settings.freeShippingMin) {
            if (total >= Number(settings.freeShippingMin)) {
                cost = 0
            }
        }
        if (coupon?.campaign?.freeShipping) {
            cost = 0;
        }
        return cost
    }

    const RenderTotal = useCallback(() => {

        return <Typography component="h4" variant="subtitle2" style={{ fontWeight: '500', fontSize: 17, }}>
            {Number(0 + getCartTotalWithStock(app.cart)).toFixed(2)} ₾
        </Typography>
    }, [app.cart, shippingMethod])

    const RenderDiscount = useCallback(() => {
        return <Typography component="h4" variant="subtitle2" style={{ fontWeight: '500', fontSize: 17, }}>
            {discount.toFixed(2)} ₾
        </Typography>
    }, [discount])
    const RenderShippingCost = useCallback(() => {
        var cost = shippingMethod.price
        if (settings && settings.freeShippingMin) {
            
            if ( getCartTotalWithStock(app.cart) > Number(settings.freeShippingMin)) {
                cost = 0;
            }
        }
        if (coupon?.campaign?.freeShipping) {
            cost = 0;
        }
        return <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 17, }}>
            {cost.toFixed(2)} ₾
        </Typography>
    }, [shippingMethod,app.cart, settings, coupon])

    const RenderYouPay = useCallback(() => {
        return <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 17, }}>
            {Number(getCartTotalWithStock(app.cart) + getShippingPrice(shippingMethod, settings, app.cart, coupon) - discount).toFixed(2)} ₾
        </Typography>
    }, [discount, shippingMethod, settings, coupon])


    return <Animated animationIn="slideInLeft" animationOut="fadeOutRight"
        animationInDuration={500}
        isVisible={loaded} >
        {/* {shippingMethod} */}
        <Grid container style={{ padding: window.innerWidth > 1200 ? 30 : 10 }}>
            <Grid item lg={9} sm={12}>
                {loaded && app?.cart?.sort((n1, n2) => { if (n1.created > n2.created) { return -1; } if (n1.created < n2.created) { return 1; } return 0; }).map((item, index) => {
                    return item.loaded ? <Box key={index} sx={{
                        width: '100%',
                        borderRadius: 2,
                        marginTop: 2,
                        boxShadow: '0px 0px 4px rgba(0,0,0,.5)',
                    }}>
                        {window.innerWidth > 1200 ? <ProductCheckoutComponent
                            onUpdateCart={onUpdateCart}
                            item={item}
                            onMessage={(qty) => {
                                setSnackBar({ ...app.snackBar, open: true, text: `${translations.only_available} ${qty} ${translations.items}`, bColor: "rgb(83, 148, 96)" })
                            }}
                            translations={translations}
                            redirect={() => { }}
                            onRemove={(item) => {
                                setConfirmation({
                                    open: true,
                                    item,
                                    type: 'removeItemFromCart',
                                    textAccept: `${translations?.delete}`,
                                    text: `${translations?.do_you_really_want_to_delete_from_cart}`,
                                    title: item.product.name,
                                    onAgreed: onRemoveItem
                                })
                            }}
                            onMoveToFavorites={(item) => {
                                setConfirmation({
                                    open: true,
                                    item,
                                    type: 'removeItemFromCart',
                                    textAccept: `${translations?.move}`,
                                    text: `${translations?.do_you_want_to_move_to_favorites}`,
                                    title: item.product.name,
                                    onAgreed: onMoveToFavorites
                                })
                            }}
                            key={index} /> :
                            <ProductCheckoutComponentMobile
                                {...props}
                                language={language}
                                onUpdateCart={onUpdateCart}
                                item={item}
                                translations={translations}
                                redirect={() => { }}
                                onRemove={(item) => {
                                    setConfirmation({
                                        open: true,
                                        item,
                                        type: 'removeItemFromCart',
                                        textAccept: `${translations?.delete}`,
                                        text: `${translations?.do_you_really_want_to_delete_from_cart}`,
                                        title: item.product.name,
                                        onAgreed: onRemoveItem
                                    })
                                }}
                                onMoveToFavorites={(item) => {
                                    setConfirmation({
                                        open: true,
                                        item,
                                        type: 'removeItemFromCart',
                                        textAccept: `${translations?.move}`,
                                        text: `${translations?.do_you_want_to_move_to_favorites}`,
                                        title: item.product.name,
                                        onAgreed: onMoveToFavorites
                                    })
                                }}
                                key={index} />
                        }
                    </Box> : <></>
                })}
            </Grid>

            <Grid item lg={3} xs={12} style={{ padding: 10, paddingTop: 0, paddingBottom: 80, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{

                    width: window.innerWidth > 1200 ? '100%' : '90%',
                    borderRadius: 2,
                    flex: 1,
                    marginTop: 2,
                    paddingTop: 1.2,
                    boxShadow: '0px 0px 4px rgba(0,0,0,.5)',
                }}>
                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', paddingLeft: 15 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', alignItems: 'flex-start' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: 'bold', fontSize: 17 }}>
                                {translations?.address}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <div style={{ flexDirection: 'column', alignItems: 'flex-start', display: 'flex', paddingLeft: 15, paddingRight: 15 }}>
                            <Typography
                                sx={{ textAlign: 'left' }}
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{
                                    fontWeight: '400', fontSize: 16,
                                    textTransform: 'uppercase'
                                }}>
                                {address.street}, {address.city.name}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', paddingLeft: 15, paddingRight: 15 }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '400', fontSize: 16 }}>
                                {address.phone}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'flex-start', display: 'flex', paddingLeft: 15, paddingRight: 15 }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '400', fontSize: 16 }}>
                                {address.firstName} {address.lastName}
                            </Typography>
                        </div>
                    </div>
                    <Divider component="div" flexItem sx={{ marginBottom: 1 }} />
                    <div style={{
                        marginTop: 10,
                        flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 15, paddingRight: 15, paddingBottom: 8
                    }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '500', fontSize: 17 }}>
                                {translations?.sum}
                            </Typography>
                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <RenderTotal />
                        </div>
                    </div>
                    {discount > 0 && <> <Divider component="div" flexItem sx={{ marginBottom: 1 }} /><div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 15, }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '500', fontSize: 17 }}>
                                {translations?.discount}
                            </Typography>
                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex', paddingLeft: 15, paddingRight: 15 }}>
                            <RenderDiscount />
                        </div>
                    </div></>}
                    {loyaltyCard?.discount > 0 && <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 15, paddingBottom: 8 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '600', fontSize: 14, textAlign: 'initial' }}>
                                {translations?.loyalty_card_discount}
                            </Typography>
                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex', paddingLeft: 15, paddingRight: 15 }}>
                            <Typography component="h4" variant="subtitle2" style={{ fontWeight: '500', fontSize: 17, }}>
                                {`${loyaltyCard.discount}%`}
                            </Typography>
                        </div>
                    </div>}
                    {coupon?.name && <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 15, paddingRight: 20, paddingBottom: 7 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '600', fontSize: 14, textAlign: 'initial' }}>
                                {translations?.coupon_discount}
                            </Typography>

                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 14 }}>
                                {coupon.campaign.type == "absolute" ?
                                    ` -${coupon.campaign.value}₾`
                                    :
                                    `-${coupon.campaign.value}%`}
                            </Typography>
                        </div>
                    </div>}


                    {shippingMethod && <><Divider component="div" flexItem sx={{ marginBottom: 1 }} /><div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 15, paddingRight: 15 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '500', fontSize: 17 }}>
                                {translations?.shipping_cost}
                            </Typography>

                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <RenderShippingCost />
                        </div>
                    </div></>}

                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', marginTop: 0, padding: 15, paddingTop: 5 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '600', fontSize: 14 }}>
                                {shippingMethod?.name} {shippingMethod?.description}
                            </Typography>
                        </div>
                    </div>
                    <Divider component="div" flexItem sx={{ marginBottom: 1 }} />
                    {shippingMethod && <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', paddingLeft: 15, paddingRight: 15 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '500', fontSize: 17 }}>
                                {translations?.payment}
                            </Typography>

                        </div>

                    </div>}

                    <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', marginTop: 0, padding: 15, paddingTop: 5 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '600', fontSize: 14 }}>
                                {paymentMethod.name}
                            </Typography>
                        </div>
                    </div>
                    <Divider component="div" flexItem sx={{ marginBottom: 1 }} />
                    <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex', marginTop: 20, padding: 20, paddingBottom: 5, paddingTop: 5 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '500', fontSize: 15 }}>
                                {translations?.you_have_items_in_your_cart ? translations?.you_have_items_in_your_cart.replace("%s", app.cart?.length) : ''}
                            </Typography>
                        </div>

                    </div>
                    {/* <Divider component="div" flexItem sx={{ marginBottom: 1 }} /> */}
                    <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 15, paddingRight: 15, paddingBottom: 20 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: 'bold', fontSize: 17, textTransform: 'uppercase' }}>
                                {translations?.you_pay}
                            </Typography>

                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <RenderYouPay />
                        </div>
                    </div>
                </Box>
                <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex', padding: window.innerWidth > 1200 ? 20 : 0, width: '90%', marginTop: 20 }}>
                    <BlackButton
                        title={translations.complete_order}
                        color={COLORS.GREEN}
                        onClick={() => {
                            registerOrder()
                        }}
                    />

                </div>
                {animateShippingError && <Title
                    uppercase={true}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={COLORS.RED}
                    fontSize={14}
                    fontWeight={'400'}
                    text={`${translations?.shipping} ${translations?.is_required}`} />}
                {animatePaymentError && <Title
                    uppercase={true}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={COLORS.RED}
                    fontSize={14}
                    fontWeight={'400'}
                    text={`${translations?.payment} ${translations?.is_required}`} />}
            </Grid>
        </Grid >
    </Animated>
}